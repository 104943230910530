import {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';

import {
  Button,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  PlusOne as PlusOneIcon,
  SelectAll as SelectAllIcon,
} from '@mui/icons-material';

import {
  fetchFederalPositions,
  fetchPositionTerms,
} from 'store/actions/contactDetails';
import { fetchRootList } from 'store/actions/contacts';
import {
  fetchBoundariesTop,
  fetchBoundariesTree,
  fetchBoundaryDetail,
} from 'store/actions/global';
import { fetchCustomFields } from 'store/actions/customFields';
import { customFieldsSelectors } from 'store/selectors/customFields';
import { globalSelectors } from 'store/selectors/global';

import {
  boundaryTypes,
  colors,
  complexListTypes,
  filterByCategories,
  filtersTypes,
} from 'utils/constants';
import {
  capitalizeFirstLetter,
} from 'utils/helpers';
import {
  Bold,
  StyledFieldButton,
} from 'globalStyles';
import FilterModal from '../FilterModal';
import { renderFilterCardValue } from '../helpers';

const ButtonLabel = styled.span`
  padding-top: 10px;
  font-size: 16px;
`;

const FilterButton = styled(Button)`
  margin-top: 10px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  margin-right: 30px;
  margin-bottom: 30px;
  border: solid 1px ${colors.mercury};
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.gallery};
  }
`;

const FilterContent = styled.p`
  padding: 0 10px;
  text-align: center;
`;

const FilterInformation = styled.div`
  display: flex;

  span {
    padding-left: 5px;
    font-size: 18px;
    line-height: 24px;
  }
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 10px;
`;

const StepThree = ({
  condition,
  customFieldId,
  filterIncludes,
  filterName,
  filterValue,
  filterValues,
  filters,
  handleFilterIncludes,
  liveActionCenters,
  liveSignupsSource,
  pickedActionCenters,
  pickedSignupsSource,
  rootList,
  setCondition,
  setCustomFieldId,
  setFilterName,
  setFilterValue,
  setFilterValues,
  setFilters,
  setLiveActionCenters,
  setLiveSignupsSource,
  setPickedActionCenters,
  setPickedSignupsSource,
}) => {
  const dispatch = useDispatch();
  const { organizationUuid } = useParams();
  const {
    boundariesTop,
    customFieldsList,
  } = useSelector(state => ({
    boundariesTop: globalSelectors.getBoundariesTop(state).children,
    customFieldsList: customFieldsSelectors.getCustomFieldsListData(state),
  }));

  useEffect(() => {
    if (Object.keys(rootList).length === 0) {
      dispatch(fetchRootList(organizationUuid))
        .then(data => dispatch(fetchCustomFields(data.uuid)));
    } else {
      dispatch(fetchCustomFields(rootList.uuid));
    }

    if (boundariesTop.length === 0) {
      dispatch(fetchBoundariesTop());
    }
  }, []);

  const [
    customFieldType,
    setCustomFieldType,
  ] = useState('');
  const [
    isFilterAdding,
    setFilterAdding,
  ] = useState(false);
  const [
    category,
    setCategory,
  ] = useState('');
  const [
    position,
    setPosition,
  ] = useState('');
  const [
    term,
    setTerm,
  ] = useState({});
  const [
    usState,
    setUsState,
  ] = useState('');
  const [
    filteredByOption,
    setFilteredByOption,
  ] = useState(null);
  const [
    editingFilterIndex,
    setEditingFilterIndex,
  ] = useState(null);
  const initialLocationFilterData = {
    boundariesList: [],
    boundaryState: '',
    boundaryType: '',
    editedGeojsonUrls: [],
  };
  const [
    locationFilterData,
    setLocationFilterData,
  ] = useState(initialLocationFilterData);

  const handleDialogClose = () => {
    setCondition('');
    setFilterName('');
    setFilterValue('');
    setFilterValues([]);
    setFilterAdding(false);
    setUsState('');
    setTerm({});
    setCategory('');
    setPosition('');
    setCustomFieldId(null);
    setFilteredByOption(null);
    setEditingFilterIndex(null);
    setLocationFilterData(initialLocationFilterData);
    setPickedActionCenters([]);
    setPickedSignupsSource([]);
  };

  const {
    actionCenterFilterType,
    customFieldFilterType,
    grassTopRelationshipFilterType,
    hasExternalIdFilterType,
    isAdvocateFilterType,
    isTextableListFilterType,
    locationFilterType,
    signupSourceListFilterType,
  } = filtersTypes;

  const {
    actionCenterList,
    advocateList,
    fieldList,
    geoList,
    hasIdList,
    signupsList,
    textableList,
  } = complexListTypes;

  const handlePickFilterType = filterNameType => {
    switch (filterNameType) {
      case grassTopRelationshipFilterType:
        return fieldList;
      case locationFilterType:
        return geoList;
      case isTextableListFilterType:
        return textableList;
      case hasExternalIdFilterType:
        return hasIdList;
      case isAdvocateFilterType:
        return advocateList;
      case actionCenterFilterType:
        return actionCenterList;
      case signupSourceListFilterType:
        return signupsList;
      default:
        return fieldList;
    }
  };

  const handleAddFilter = () => {
    setFilters(oldArray => {
      const newArray = oldArray;
      const newFilter = {
        boundaries: locationFilterData.boundariesList,
        fieldName: customFieldId,
        filterCondition: condition,
        ...(filterName === grassTopRelationshipFilterType ?
          {
            filterDetails: {
              category,
              position: {
                uuid: position,
              },
              state: usState,
            },
          } :
          {}),
        isBooleanField: (
            filterName === grassTopRelationshipFilterType ||
            filterName === isTextableListFilterType ||
            filterName === isAdvocateFilterType ||
            filterName === hasExternalIdFilterType
        ),
        isChoiceField: Boolean(customFieldType),
        isLocationField: filterName === locationFilterType,
        isOfficialField: filterName === grassTopRelationshipFilterType,
        name: filterName,
        officialName: term.name,
        type: handlePickFilterType(filterName),
        value: filterValue,
        values: filterValues,
        ...(filterName === actionCenterFilterType ?
          { actionCenters: pickedActionCenters } :
          {}),
        ...(filterName === signupSourceListFilterType ?
          {
            actionCenters: pickedActionCenters,
            signups: pickedSignupsSource,
          } :
          {}
        ),
      };

      newArray[editingFilterIndex ?? newArray.length] = newFilter;

      return newArray;
    });
    handleDialogClose();
  };

  const autocompleteOptions = [
    ...filterByCategories,
    ...customFieldsList.map(({
      fieldType,
      label,
    }) => ({
      fieldType,
      name: label,
      type: customFieldFilterType,
    })),
  ];

  const handleFilterRemove = (index, event) => {
    event.stopPropagation();

    setFilters(
      filters.slice(0, index).concat(filters.slice(index + 1, filters.length))
    );
  };

  const handleEditFilter = index => {
    const filter = filters[index];
    const autoCompleteOption = autocompleteOptions.find(option => option.name === filter.name);

    setEditingFilterIndex(index);
    setFilterName(autoCompleteOption?.name || '');
    setFilteredByOption(autoCompleteOption);
    setCustomFieldType(autoCompleteOption?.fieldType || '');
    setCondition(filter?.filterCondition || '');
    setCustomFieldId(filter.fieldName);
    setPickedActionCenters(filter.actionCenters || []);
    setPickedSignupsSource(filter.signups || []);

    if (filter?.name === grassTopRelationshipFilterType) {
      const { filterDetails } = filter;

      setCategory(capitalizeFirstLetter(filterDetails.category));

      if (filterDetails.category.toUpperCase() === 'FEDERAL') {
        dispatch(fetchFederalPositions({ category: 'federal' }))
          .then(() => setPosition(filterDetails.position.uuid));
        dispatch(fetchPositionTerms({ position: filterDetails.position.uuid }))
          .then(() => {
            setTerm({
              name: filter.officialName,
              uuid: filter.value,
            });
            setFilterValue(filter.value);
          });
      } else {
        dispatch(fetchFederalPositions({
          category: filterDetails.category.toLowerCase(),
          state: filterDetails.state,
        }))
          .then(() => setPosition(filterDetails.position.uuid));

        setUsState(filterDetails.state);
        dispatch(fetchPositionTerms({
          position: filterDetails.position.uuid,
          state: filterDetails.state,
        }))
          .then(() => {
            setTerm({
              name: filter.officialName,
              uuid: filter.value,
            });
            setFilterValue(filter.value);
          });
      }
    }

    if (filter.value) {
      setFilterValue(filter.value);
    }

    if (filter.values) {
      setFilterValues(filter.values);
    }

    if (filter?.name === locationFilterType) {
      if (filter.boundaries[0].boundaryType === 'state') {
        const feature = boundariesTop.find(({ uuid }) => uuid === filter.boundaries[0].uuid);

        setLocationFilterData(prevValue => ({
          ...prevValue,
          boundaryState: feature,
        }));
      } else {
        dispatch(fetchBoundariesTree(filter.boundaries[0].parent));
        dispatch(fetchBoundaryDetail(filter.boundaries[0].parent))
          .then(data => setLocationFilterData(prevValue => ({
            ...prevValue,
            boundaryState: data,
          })));
      }
      setLocationFilterData(prevValue => ({
        ...prevValue,
        boundariesList: filter.boundaries,
        boundaryType: boundaryTypes.find(
          boundaryTreeType => boundaryTreeType.type === filter.boundaries[0].boundaryType
        ),
        editedGeojsonUrls: filter.boundaries.map(editedBoundary => editedBoundary.geojsonUrl),
      }));
    }
    setFilterAdding(true);
  };

  const handleChangeFilerType = pickedFilter => {
    setCondition('');
    setFilterName(pickedFilter?.name || '');
    setCustomFieldType(pickedFilter?.fieldType || '');
    setFilteredByOption(pickedFilter);
    setUsState('');
    setPosition('');
    setTerm({});
    setFilterValue('');
    setFilterValues([]);
    setLocationFilterData(initialLocationFilterData);
    setPickedActionCenters([]);
    setPickedSignupsSource([]);
  };

  return (
    <>
      <FiltersWrapper>
        {!!filters.length && filters.map(({
          actionCenters,
          filterCondition,
          isBooleanField,
          isOfficialField,
          name,
          officialName,
          signups,
          value,
          values,
          uuid,
        }, index) => (
          <FilterContainer
            key={uuid ? `${uuid}-${value}` : `${name}-${value || filterCondition}`}
            onClick={() => handleEditFilter(index)}
          >
            <FilterInformation>
              <span>{name}</span>
            </FilterInformation>
            <FilterContent>
              {isBooleanField ? 'Contact' : 'Contact\'s'}
              {' '}
              {isBooleanField ? '' : name}
              {' '}
              {filterCondition}
              {' '}
              <Bold>
                {isOfficialField ?
                  officialName :
                  renderFilterCardValue(value, values, actionCenters, signups)
                }
              </Bold>
            </FilterContent>
            <FilterButton
              onClick={event => handleFilterRemove(index, event)}
              size="small"
              variant="outlined"
            >
              Remove Filter
            </FilterButton>
          </FilterContainer>
        ))}
        <StyledFieldButton
          onClick={() => setFilterAdding(true)}
          type="button"
          size="250"
        >
          <AddIcon />
          Add Filter
        </StyledFieldButton>
      </FiltersWrapper>
      {filters.length > 1 && (
        <>
          <StyledTypography variant="h6">Include contacts that have:</StyledTypography>
          <FiltersWrapper>
            <StyledFieldButton
              checked={filterIncludes === 'OR'}
              data-field="OR"
              onClick={handleFilterIncludes}
              type="button"
            >
              <PlusOneIcon fontSize="large" />
              <ButtonLabel>At Least One Filter</ButtonLabel>
            </StyledFieldButton>
            <StyledFieldButton
              checked={filterIncludes === 'AND'}
              data-field="AND"
              onClick={handleFilterIncludes}
              type="button"
            >
              <SelectAllIcon fontSize="large" />
              <ButtonLabel>All Filters</ButtonLabel>
            </StyledFieldButton>
          </FiltersWrapper>
        </>
      )}
      <FilterModal
        autocompleteOptions={autocompleteOptions}
        category={category}
        condition={condition}
        customFieldId={customFieldId}
        customFieldType={customFieldType}
        editingFilterIndex={editingFilterIndex}
        filterName={filterName}
        filteredByOption={filteredByOption}
        filterValue={filterValue}
        filterValues={filterValues}
        handleChangeFilerType={handleChangeFilerType}
        handleAddFilter={handleAddFilter}
        handleDialogClose={handleDialogClose}
        isFilterAdding={isFilterAdding}
        liveActionCenters={liveActionCenters}
        liveSignupsSource={liveSignupsSource}
        locationFilterData={locationFilterData}
        pickedActionCenters={pickedActionCenters}
        pickedSignupsSource={pickedSignupsSource}
        position={position}
        setCategory={setCategory}
        setCondition={setCondition}
        setCustomFieldId={setCustomFieldId}
        setFilterValue={setFilterValue}
        setFilterValues={setFilterValues}
        setLiveActionCenters={setLiveActionCenters}
        setLiveSignupsSource={setLiveSignupsSource}
        setLocationFilterData={setLocationFilterData}
        setPickedActionCenters={setPickedActionCenters}
        setPickedSignupsSource={setPickedSignupsSource}
        setPosition={setPosition}
        setTerm={setTerm}
        setUsState={setUsState}
        term={term}
        usState={usState}
      />
    </>
  );
};

StepThree.defaultProps = {
  customFieldId: null,
};

StepThree.propTypes = {
  condition: PropTypes.string.isRequired,
  customFieldId: PropTypes.string,
  filterIncludes: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
  filterValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    actionCenters: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      uuid: PropTypes.string,
    })),
    boundaries: PropTypes.arrayOf(PropTypes.shape({
      boundaryType: PropTypes.string,
      parent: PropTypes.string,
      uuid: PropTypes.string,
    })),
    fieldName: PropTypes.string,
    filterCondition: PropTypes.string,
    filterDetails: PropTypes.oneOfType([
      PropTypes.shape({
        category: PropTypes.string,
        position: PropTypes.shape({
          uuid: PropTypes.string,
        }),
        state: PropTypes.string,
      }),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
    name: PropTypes.string,
    officialName: PropTypes.string,
    signups: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      uuid: PropTypes.string,
    })),
    value: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  handleFilterIncludes: PropTypes.func.isRequired,
  liveActionCenters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  liveSignupsSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pickedActionCenters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pickedSignupsSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rootList: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  setCondition: PropTypes.func.isRequired,
  setCustomFieldId: PropTypes.func.isRequired,
  setFilterName: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  setFilterValues: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  setLiveActionCenters: PropTypes.func.isRequired,
  setLiveSignupsSource: PropTypes.func.isRequired,
  setPickedActionCenters: PropTypes.func.isRequired,
  setPickedSignupsSource: PropTypes.func.isRequired,
};

export default StepThree;
