import { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { campaignsSelectors } from 'store/selectors/campaigns';
import {
  fetchTargetGroup,
} from 'store/actions/campaigns';

import {
  Button,
  Grid,
} from '@mui/material';
import {
  PeopleAlt as PeopleAltIcon,
} from '@mui/icons-material';

import {
  Backdrop,
  BreadcrumbsNavigation,
  Container,
  DescriptionBar,
  TargetsTable,
} from 'common/components';

const TargetGroupDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    organizationUuid,
    targetGroupUuid,
  } = useParams();

  const {
    isDataFetching,
    targetGroupDetails,
  } = useSelector(state => ({
    isDataFetching: campaignsSelectors.isDataFetching(state),
    targetGroupDetails: campaignsSelectors.getTargetGroupDetails(state),
  }));

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/campaigns`,
      name: 'Advocacy',
    },
    {
      link: `/${organizationUuid}/target-groups`,
      name: 'Target Groups',
    },
    {
      link: '',
      name: targetGroupDetails.name,
    },
  ];

  const {
    name,
    targets,
  } = targetGroupDetails;

  useEffect(() => {
    dispatch(fetchTargetGroup(targetGroupUuid));
  }, []);

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container marginBottom="40">
        <Grid container>
          <DescriptionBar
            name={name}
            icon={PeopleAltIcon}
            goBackLink={`/${organizationUuid}/target-groups`}
          >
            <Button onClick={() => history.push(`/${organizationUuid}/create-target-group/${targetGroupUuid}`)}>Edit</Button>
          </DescriptionBar>
        </Grid>
        <TargetsTable
          targets={targets}
          hasStatistics={false}
          isDataFetching={isDataFetching}
        />
      </Container>
      <Backdrop isOpen={isDataFetching} />
    </>
  );
};

TargetGroupDetails.propTypes = {
  location: PropTypes.shape({
    listProps: PropTypes.shape({
      listId: PropTypes.string,
    }),
  }).isRequired,
};

export default TargetGroupDetails;
