import {
  ADD_CONTACTS_TO_LIST_FAILURE,
  ADD_CONTACTS_TO_LIST_REQUEST,
  ADD_CONTACTS_TO_LIST_SUCCESS,
  DELETE_ALL_LIST_CONTACTS_FAILURE,
  DELETE_ALL_LIST_CONTACTS_REQUEST,
  DELETE_ALL_LIST_CONTACTS_SUCCESS,
  DELETE_CONTACTS_FAILURE,
  DELETE_CONTACTS_REQUEST,
  DELETE_CONTACTS_SUCCESS,
  DELETE_LIST_CONTACT_FAILURE,
  DELETE_LIST_CONTACT_REQUEST,
  DELETE_LIST_CONTACT_SUCCESS,
  DELETE_LIST_CONTACTS_FAILURE,
  DELETE_LIST_CONTACTS_REQUEST,
  DELETE_LIST_CONTACTS_SUCCESS,
  DELETE_LIST_FAILURE,
  DELETE_LIST_GROUP_FAILURE,
  DELETE_LIST_GROUP_REQUEST,
  DELETE_LIST_GROUP_SUCCESS,
  DELETE_LIST_REQUEST,
  DELETE_LIST_SUCCESS,
  EXPORT_LIST_FAILURE,
  EXPORT_LIST_REQUEST,
  EXPORT_LIST_SUCCESS,
  FETCH_ALL_LIST_CONTACTS_FAILURE,
  FETCH_ALL_LIST_CONTACTS_IN_PROGRESS,
  FETCH_ALL_LIST_CONTACTS_REQUEST,
  FETCH_ALL_LIST_CONTACTS_SUCCESS,
  FETCH_CHILDREN_LISTS_FAILURE,
  FETCH_CHILDREN_LISTS_REQUEST,
  FETCH_CHILDREN_LISTS_SUCCESS,
  FETCH_CONTACT_FAILURE,
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_LIST_CONTACTS_LOCATIONS_FAILURE,
  FETCH_LIST_CONTACTS_LOCATIONS_REQUEST,
  FETCH_LIST_CONTACTS_LOCATIONS_SUCCESS,
  FETCH_LIST_FAILURE,
  FETCH_LIST_GROUPS_FAILURE,
  FETCH_LIST_GROUPS_REQUEST,
  FETCH_LIST_GROUPS_SUCCESS,
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_ROOT_LIST_FAILURE,
  FETCH_ROOT_LIST_REQUEST,
  FETCH_ROOT_LIST_SUCCESS,
  GET_CONTACTS_LIST_FLAT_FILE_CONFIG_FAILURE,
  GET_CONTACTS_LIST_FLAT_FILE_CONFIG_REQUEST,
  GET_CONTACTS_LIST_FLAT_FILE_CONFIG_SUCCESS,
  POST_CONTACT_FAILURE,
  POST_CONTACT_REQUEST,
  POST_CONTACT_SUCCESS,
  POST_LIST_FAILURE,
  POST_LIST_GROUP_FAILURE,
  POST_LIST_GROUP_REQUEST,
  POST_LIST_GROUP_SUCCESS,
  POST_LIST_REQUEST,
  POST_LIST_SUCCESS,
  POST_MERGE_CONTACTS_FAILURE,
  POST_MERGE_CONTACTS_REQUEST,
  POST_MERGE_CONTACTS_SUCCESS,
  UPDATE_CONTACTS_LIST_FAILURE,
  UPDATE_CONTACTS_LIST_REQUEST,
  UPDATE_CONTACTS_LIST_SUCCESS,
} from '../actions/contacts';

const initialState = {
  contactDetails: {},
  contacts: {
    results: [],
  },
  errors: null,
  flatFileConfig: {},
  inProgressFetchedContactsCount: 0,
  isChildrenListsFetching: false,
  isContactFetching: false,
  isExportSuccess: false,
  isFetching: false,
  isListContactsFetching: false,
  isListFetching: false,
  isPending: false,
  list: {},
  listContacts: {},
  listContactsLocations: [],
  listContactsLocationsCount: 0,
  listGroups: {},
  lists: {
    children: [],
    root: {},
  },
  totalListContactsCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONTACTS_TO_LIST_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case ADD_CONTACTS_TO_LIST_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case ADD_CONTACTS_TO_LIST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case DELETE_ALL_LIST_CONTACTS_REQUEST:
      return {
        ...state,
        errors: null,
        isListContactsFetching: true,
      };
    case DELETE_ALL_LIST_CONTACTS_SUCCESS:
      return {
        ...state,
        isListContactsFetching: false,
        listContacts: [],
      };
    case DELETE_ALL_LIST_CONTACTS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isListContactsFetching: false,
        listContacts: [...state.listContacts],
      };
    case DELETE_CONTACTS_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_CONTACTS_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case DELETE_CONTACTS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case DELETE_LIST_CONTACT_REQUEST:
      return {
        ...state,
        errors: null,
        isListContactsFetching: true,
      };
    case DELETE_LIST_CONTACT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listContacts: state.listContacts.filter(item => item !== action.payload),
      };
    case DELETE_LIST_CONTACT_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
        listContacts: [],
      };
    case DELETE_LIST_CONTACTS_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_LIST_CONTACTS_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case DELETE_LIST_CONTACTS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case DELETE_LIST_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_LIST_SUCCESS:
      return {
        ...state,
        isPending: false,
        lists: {
          ...state.lists,
          children: {
            ...state.lists.children,
            [action.payload.organizationId]: state.lists.children[action.payload.organizationId]
              .filter(({ uuid }) => uuid !== action.payload.listId),
          },
        },
      };
    case DELETE_LIST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_ALL_LIST_CONTACTS_REQUEST:
      return {
        ...state,
        errors: null,
        inProgressFetchedContactsCount: 0,
        isListContactsFetching: true,
        totalListContactsCount: 0,
      };
    case FETCH_ALL_LIST_CONTACTS_IN_PROGRESS:
      return {
        ...state,
        inProgressFetchedContactsCount:
          state.inProgressFetchedContactsCount + action.payload.fetchedCount,
        totalListContactsCount: action.payload.allCount,
      };
    case FETCH_ALL_LIST_CONTACTS_SUCCESS:
      return {
        ...state,
        isListContactsFetching: false,
        listContacts: {
          ...state.listContacts,
          [action.payload.listId]: action.payload.data,
        },
      };
    case FETCH_ALL_LIST_CONTACTS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isListContactsFetching: false,
        listContacts: {
          ...state.listContacts,
          [action.payload.listId]: [],
        },
      };
    case FETCH_LIST_CONTACTS_LOCATIONS_REQUEST:
      return {
        ...state,
        errors: null,
        totalListContactsCount: 0,
      };
    case FETCH_LIST_CONTACTS_LOCATIONS_SUCCESS:
      return {
        ...state,
        listContactsLocations: [
          ...state.listContactsLocations,
          ...(
            action.payload?.results
            ?.filter(
              location => (
                !state.listContactsLocations
                  .map(stateLocation => stateLocation.contact)
                  .includes(location.contact)
              )
            ) || []
          ),
        ],
        listContactsLocationsCount: action.payload?.count,
      };
    case FETCH_LIST_CONTACTS_LOCATIONS_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    case FETCH_CHILDREN_LISTS_REQUEST:
      return {
        ...state,
        errors: null,
        isChildrenListsFetching: true,
      };
    case FETCH_CHILDREN_LISTS_SUCCESS:
      return {
        ...state,
        isChildrenListsFetching: false,
        lists: {
          ...state.lists,
          children: {
            ...state.lists.children,
            [action.payload.organizationId]: action.payload.data,
          },
        },
      };
    case FETCH_CHILDREN_LISTS_FAILURE:
      return {
        ...state,
        errors: action.payload.data,
        isChildrenListsFetching: false,
        lists: {
          ...state.lists,
          children: {
            ...state.lists.children,
            [action.payload.organizationId]: [],
          },
        },
      };
    case FETCH_CONTACT_REQUEST:
      return {
        ...state,
        errors: null,
        isContactFetching: true,
      };
    case FETCH_CONTACT_SUCCESS:
      return {
        ...state,
        contactDetails: action.payload,
        isContactFetching: false,
      };
    case FETCH_CONTACT_FAILURE:
      return {
        ...state,
        contactDetails: {},
        errors: action.payload,
        isContactFetching: false,
      };
    case EXPORT_LIST_REQUEST:
      return {
        ...state,
        errors: null,
        isContactListsFetching: true,
      };
    case EXPORT_LIST_SUCCESS:
      return {
        ...state,
        isExportSuccess: true,
        isFetching: false,
      };
    case EXPORT_LIST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isExportSuccess: false,
        isFetching: false,
      };
    case FETCH_LIST_REQUEST:
      return {
        ...state,
        errors: null,
        isListFetching: true,
      };
    case FETCH_LIST_SUCCESS:
      return {
        ...state,
        isListFetching: false,
        list: action.payload,
      };
    case FETCH_LIST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isListFetching: false,
        list: {},
      };
    case FETCH_ROOT_LIST_REQUEST:
      return {
        ...state,
        errors: null,
        isFetching: true,
      };
    case FETCH_ROOT_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lists: {
          ...state.lists,
          root: {
            ...state.lists.root,
            [action.payload.organizationId]: action.payload.data,
          },
        },
      };
    case FETCH_ROOT_LIST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
        organizationRootList: {},
      };
    case POST_CONTACT_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case POST_CONTACT_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case POST_CONTACT_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case UPDATE_CONTACTS_LIST_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case GET_CONTACTS_LIST_FLAT_FILE_CONFIG_FAILURE:
      return {
        ...state,
        errors: action.payload,
        flatFileConfig: {},
        isPending: false,
      };
    case GET_CONTACTS_LIST_FLAT_FILE_CONFIG_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case GET_CONTACTS_LIST_FLAT_FILE_CONFIG_SUCCESS:
      return {
        ...state,
        flatFileConfig: action.payload,
        isPending: false,
      };
    case UPDATE_CONTACTS_LIST_SUCCESS: {
      const isCurrentListDetailsUpdated = !!(state.list.uuid && action.payload?.data?.uuid) &&
        state.list.uuid === action.payload.data.uuid;
      const hasListsData = Object.keys(state.lists.root).length > 0;

      return {
        ...state,
        isPending: false,
        list: isCurrentListDetailsUpdated ?
          {
            ...state.list,
            ...action.payload.data,
          } :
          { ...state.list },
        lists: hasListsData ?
          {
            ...state.lists,
            children: {
              ...state.lists.children,
              [action.payload.organizationId]: state.lists.children[action.payload.organizationId]
                .map(contact => {
                  const finded = [action.payload.data].find(
                    object => object.uuid === contact.uuid
                  );

                  if (finded) {
                    return ({
                      ...contact,
                      ...finded,
                    });
                  }

                  return contact;
                }),
            },
          } :
          { ...state.lists },
      };
    }
    case UPDATE_CONTACTS_LIST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case POST_LIST_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_LIST_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case POST_LIST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case POST_MERGE_CONTACTS_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case POST_MERGE_CONTACTS_SUCCESS:
      return {
        ...state,
        isPending: false,
        listContacts: {
          ...state.listContacts,
          [action.payload.listId]: state.listContacts[action.payload.listId]
            .filter(({ uuid }) => !action.payload.duplicates.some(
              mergedContact => mergedContact === uuid
            )),
        },
      };
    case POST_MERGE_CONTACTS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_LIST_GROUPS_REQUEST:
      return {
        ...state,
        errors: null,
        isFetching: true,
      };
    case FETCH_LIST_GROUPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listGroups: {
          ...state.listGroups,
          [action.payload.organizationUuid]: {
            ...action.payload.organizationUuid,
            [action.payload.rootListId]: action.payload.data,
          },
        },
      };
    case FETCH_LIST_GROUPS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };
    case POST_LIST_GROUP_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case POST_LIST_GROUP_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case POST_LIST_GROUP_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case DELETE_LIST_GROUP_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_LIST_GROUP_SUCCESS: {
      const {
        organizationUuid,
        rootListId,
        listGroupUuid,
      } = action.payload;

      return {
        ...state,
        isPending: false,
        listGroups: {
          ...state.listGroups,
          [organizationUuid]: {
            ...state.listGroups[organizationUuid],
            [rootListId]: state.listGroups[organizationUuid][rootListId]
              .filter(item => item.uuid !== listGroupUuid),
          },
        },
      };
    }
    case DELETE_LIST_GROUP_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_CONTACTS_REQUEST:
      return {
        ...state,
        errors: null,
        isFetching: true,
      };
    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
        isFetching: false,
      };
    case FETCH_CONTACTS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};
