import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Button,
  MenuItem,
  Select,
} from '@mui/material';
import { GroupWork as GroupWorkIcon } from '@mui/icons-material';

import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';
import { postMergeContacts } from 'store/actions/contacts';
import { contactSelectors } from 'store/selectors/contacts';

import { Dialog } from 'common/components';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const MergeContactsDialog = ({
  selectedRows,
  uncheckAllContacts,
}) => {
  const dispatch = useDispatch();
  const {
    organizationUuid,
    listUuid,
  } = useParams();
  const {
    isPending,
  } = useSelector(state => ({
    isPending: contactSelectors.isPendingList(state),
  }));

  const [
    winner,
    setWinner,
  ] = useState('');
  const [
    isMergeContactDialogOpen,
    setMergeContactDialogOpen,
  ] = useState(false);

  const handleMergeContacts = () => {
    const params = {
      duplicates: selectedRows.map(({ uuid }) => uuid).filter(uuid => uuid !== winner),
      winner,
    };

    dispatch(postMergeContacts(organizationUuid, listUuid, params))
      .then(({ detail }) => dispatch(setSnackbarSuccess({ message: detail })))
      .catch(() => dispatch(setSnackbarError()))
      .finally(() => uncheckAllContacts());
  };

  const renderAddress = locations => {
    if (!locations) {
      return null;
    }

    const {
      city,
      streetAddress,
      streetAddress2,
      state,
      zipCode,
    } = locations[0];

    return `, ${streetAddress} ${streetAddress2} ${zipCode} ${city} ${state}`;
  };

  return (
    <>
      <Button
        disabled={selectedRows.length < 2 || selectedRows.length > 20}
        onClick={() => setMergeContactDialogOpen(true)}
        startIcon={<GroupWorkIcon />}
      >
        Merge Contacts
      </Button>
      <Dialog
        handleConfirm={handleMergeContacts}
        handleDialogClose={() => {
          setMergeContactDialogOpen(false);
          setWinner('');
        }}
        isDialogOpen={isMergeContactDialogOpen}
        subtitle="Select the contact to keep and merge others into"
        title="Merge Contacts"
        disabledButton={isPending || !winner}
      >
        <StyledSelect
          onChange={event => setWinner(event.target.value)}
          value={winner}
        >
          {selectedRows.map(contact => (
            <MenuItem
              key={contact.uuid}
              value={contact.uuid}
            >
              {`${contact.firstName} ${contact.lastName}`}
              {renderAddress(contact.locations)}
            </MenuItem>
          ))}
        </StyledSelect>
      </Dialog>
    </>
  );
};

MergeContactsDialog.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string,
  })).isRequired,
  uncheckAllContacts: PropTypes.func.isRequired,
};

export default MergeContactsDialog;
