import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import { logout } from 'store/actions/auth';
import { organizationSelectors } from 'store/selectors/organizations';

import { colors } from 'utils/constants';
import { disableLink } from 'utils/helpers';

const StyledButton = styled(Button)`
  display: block;
  padding: 0;
  color: ${colors.black};
  font-size: 16px;
  text-transform: none;
`;

const StyledIconButton = styled(IconButton)`
  color: ${colors.white};
`;

const DropdownMenu = ({
  organizationUuid,
  links,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    organization: {
      flags,
    },
  } = useSelector(state => ({
    organization: organizationSelectors.getOrganization(state),
  }));

  const [
    anchorElement,
    setAnchorElement,
  ] = useState(null);
  const isOpened = Boolean(anchorElement);

  const handleClose = () => {
    setAnchorElement(null);
  };
  const handleLogout = async () => {
    handleClose();
    await dispatch(logout());
    window.location = '/login';
  };
  const handleMenu = event => {
    setAnchorElement(event.currentTarget);
  };
  const handleProfileOpen = () => {
    handleClose();
    history.push({
      pathname: '/account',
      state: {
        goBackLink: pathname,
      },
    });
  };

  return (
    <>
      <StyledIconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
      >
        <MoreVertIcon />
      </StyledIconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElement}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        keepMounted
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={isOpened}
        onClose={handleClose}
      >
        {!!organizationUuid && (
          <StyledButton
            component={RouterLink}
            to={`/${organizationUuid}/dashboard`}
          >
            <MenuItem onClick={handleClose}>Dashboard</MenuItem>
          </StyledButton>
        )}
        {!!links.length && !!organizationUuid && (
          links.map(({
            href,
            name,
          }) => (
            <StyledButton
              component={RouterLink}
              disabled={disableLink(href, flags)}
              key={name}
              to={`/${organizationUuid}/${href}`}
            >
              <MenuItem onClick={handleClose}>{name}</MenuItem>
            </StyledButton>
          ))
        )}
        <MenuItem onClick={handleProfileOpen}>Profile</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

DropdownMenu.defaultProps = {
  links: [],
  organizationUuid: null,
};

DropdownMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  organizationUuid: PropTypes.string,
};

export default DropdownMenu;
