import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Grid,
  TextField,
} from '@mui/material';

import CardField from 'common/components/CardField';

const CardFieldWrapper = styled.div`
  margin-top: 30px;
`;

const StyledGrid = styled(Grid)`
  flex: 0 0 100%;
`;

const StepOne = ({
  errorMessage,
  handleSetFormValues,
  nameValue,
}) => (
  <CardFieldWrapper>
    <CardField
      flexwrap
      name="Contact Form Name"
      required
      descriptionText="The name of this Contact Form will not be displayed to customers on the form itself and will only be the internal name of the identity"
    >
      <StyledGrid>
        <TextField
          fullWidth
          label="Contact Form Name"
          error={!!errorMessage?.name && !!errorMessage?.name[0]}
          helperText={errorMessage?.name && errorMessage?.name[0]}
          name="name"
          onChange={handleSetFormValues}
          required
          type="text"
          value={nameValue}
        />
      </StyledGrid>
    </CardField>
  </CardFieldWrapper>
);

StepOne.defaultProps = {
  errorMessage: null,
};

StepOne.propTypes = {
  errorMessage: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
  }),
  handleSetFormValues: PropTypes.func.isRequired,
  nameValue: PropTypes.string.isRequired,
};

export default StepOne;
