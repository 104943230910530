import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Link as RouterLink,
  useParams,
} from 'react-router-dom';

import {
  Button,
  Card,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';

import { DetailsButton } from 'common/components';

const ActionsCountWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  padding-top: 15px 0 0 0;

  & > * {
    margin-right: 8px;
  }
`;

const StyledCard = styled(Card)`
  overflow-x: auto;
  padding: 32px 26px;

  &:not(:last-of-type) {
    border-bottom: 0;
  }
`;

const StyledGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

const StyledName = styled.div`
  display: flex;
  align-items: center;
`;

const MeetingItem = ({
  handleRemoveMeeting,
  occurredOn,
  targets,
  title,
  uuid,
}) => {
  const {
    organizationUuid,
  } = useParams();

  return (
    <StyledCard
      variant="outlined"
      square
    >
      <StyledGrid
        container
        item
        wrap="nowrap"
      >
        <StyledName>
          <Link
            component={RouterLink}
            to={`/${organizationUuid}/meetings/${uuid}/edit`}
          >
            <Typography variant="h5">{title}</Typography>
          </Link>
        </StyledName>
        <ActionsCountWrapper>
          <DetailsButton
            name="Edit"
            to={`/${organizationUuid}/meetings/${uuid}/edit`}
          />
          <Button
            onClick={() => handleRemoveMeeting(uuid)}
            size="small"
            startIcon={<DeleteOutlineIcon />}
            variant="outlined"
          >
            Delete
          </Button>
        </ActionsCountWrapper>
      </StyledGrid>
      <p>
        {'Met on '}
        {occurredOn}
        {' with '}
        {targets.map(target => target.officialName).join(', ')}
      </p>
    </StyledCard>
  );
};

MeetingItem.propTypes = {
  handleRemoveMeeting: PropTypes.func.isRequired,
  occurredOn: PropTypes.string.isRequired,
  targets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  title: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default MeetingItem;
