import { useDispatch } from 'react-redux';
import {
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  Field,
  Form,
} from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import styled from 'styled-components';

import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import {
  fetchCsrfToken,
  login,
} from 'store/actions/auth';

import { colors } from 'utils/constants';
import { stripTrailingSlash } from 'utils/helpers';
import { required } from 'utils/validators';

const ErrorMessage = styled.p`
  color: ${colors.error};
  text-align: center;
`;

const LoginTypography = styled(Typography)`
  margin-top: 10px;
  color: ${colors.osloGray};
`;

const LogoMark = styled.img`
  max-height: 60px;
  margin-bottom: 15px;
`;

const MarginGrid = styled(Grid)`
  margin-top: 5px;
`;

const StyledLink = styled(Link)`
  color: ${colors.primary};
  font-weight: bold;
`;

const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 50px 30px;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  width: 280px;
  height: 50px;
  margin: 30px 0 25px;
  font-weight: bold;
  text-transform: uppercase;
`;

const Wrapper = styled(Grid)`
  max-width: 500px;
  height: 100%;
`;

const SubmitButtonWrapper = styled.div`
  position: relative;
`;

const ButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const EMAIL_LABEL = 'Email';
const FORGOT_PASSWORD_TEXT = 'Forgot password?';
const NOT_CUSTOMER_TEXT = 'Not yet a customer?';
const PASSWORD_LABEL = 'Password';
const DEMO_TEXT = 'Request a demo!';

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    organizationUuid,
    inviteUuid,
  } = useParams();

  const handleReload = () => {
    const referrerUrl = stripTrailingSlash(document?.referrer);

    if (organizationUuid && inviteUuid) {
      window.location = `/join?organization=${organizationUuid}&invite=${inviteUuid}`;
    } else if (location?.state?.pathname) {
      window.location = location?.state?.pathname;
    } else if (
      referrerUrl &&
      referrerUrl !== window.location.href &&
      referrerUrl !== window.location.origin &&
      referrerUrl.includes(window.location.origin)
    ) {
      window.location = document.referrer;
    } else {
      window.location = '/organizations';
    }
  };

  const onSubmit = async values => {
    try {
      await dispatch(login(values));
      await dispatch(fetchCsrfToken());

      return handleReload();
    } catch (error) {
      if (error?.nonFieldErrors?.length) {
        return { [FORM_ERROR]: error?.nonFieldErrors[0] };
      }

      return error;
    }
  };

  return (
    <Wrapper
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
    >
      <StyledPaper variant="outlined">
        <LogoMark
          alt="Muster logomark"
          src="/muster_logomark_blue.png"
        />
        <Form
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            submitting,
            submitError,
            pristine,
          }) => (
            <form onSubmit={handleSubmit}>
              <MarginGrid
                container
                item
                xs={12}
              >
                <Field
                  name="email"
                  validate={required}
                >
                  {({
                    input,
                    meta,
                  }) => (
                    <TextField
                      {...input}
                      error={Boolean(meta.touched && (meta.error || meta.submitError))}
                      fullWidth
                      helperText={meta.touched && (meta.error || meta.submitError)}
                      label={EMAIL_LABEL}
                      type="text"
                      variant="standard"
                    />
                  )}
                </Field>
              </MarginGrid>
              <MarginGrid
                container
                item
                xs={12}
              >
                <Field
                  name="password"
                  validate={required}
                >
                  {({
                    input,
                    meta,
                  }) => (
                    <TextField
                      {...input}
                      error={Boolean(meta.touched && (meta.error || meta.submitError))}
                      fullWidth
                      helperText={meta.touched && (meta.error || meta.submitError)}
                      label={PASSWORD_LABEL}
                      type="password"
                      variant="standard"
                    />
                  )}
                </Field>
              </MarginGrid>
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
              <Grid
                container
                item
                justifyContent="center"
                xs={12}
              >
                <SubmitButtonWrapper>
                  <SubmitButton
                    color="secondary"
                    disableElevation
                    disabled={submitting || pristine}
                    type="submit"
                    variant="contained"
                  >
                    Sign In
                  </SubmitButton>
                  {submitting && <ButtonCircularProgress size={24} />}
                </SubmitButtonWrapper>
              </Grid>
            </form>
          )}
        />
        <Grid
          container
          item
          justifyContent="center"
          xs
        >
          <Link
            href="/password-reset"
            variant="body2"
          >
            {FORGOT_PASSWORD_TEXT}
          </Link>
        </Grid>
        <LoginTypography variant="subtitle2">
          {NOT_CUSTOMER_TEXT}
          {' '}
          <StyledLink
            href="https://www.muster.com/demo-request?utm_campaign=Demo%20Request&utm_source=app&utm_medium=login"
          >
            {DEMO_TEXT}
          </StyledLink>
        </LoginTypography>
      </StyledPaper>
    </Wrapper>
  );
};

export default Login;
