import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';

import { colors } from 'utils/constants';

const CustomFieldWrapper = styled(Grid)`
  width: 570px;
  margin-top: 20px;
  padding: 10px;
  background-color: ${colors.wildSand};
`;

const CustomFieldSelect = styled(TextField)`
  width: 80%;
  margin-top: 15px;
  padding-bottom: 15px;
`;

const AddButton = styled(Button)`
  width: 150px;
  margin-right: 10px;
`;

const CustomFieldsSelect = ({
  customFieldsList,
  handleAddCustomField,
}) => {
  const [
    selectedCustomField,
    setSelectedCustomField,
  ] = useState('');

  const onAddCustomField = () => {
    handleAddCustomField(selectedCustomField);
    setSelectedCustomField('');
  };

  return (
    <CustomFieldWrapper container>
      <Grid
        item
        xs={9}
      >
        <CustomFieldSelect
          label="Custom Fields"
          onChange={event => setSelectedCustomField(event.target.value)}
          select
          placeholder="Select Custom Field"
          value={selectedCustomField}
        >
          {customFieldsList.map(element => (
            <MenuItem
              key={element.uuid}
              value={element}
            >
              {element.label}
            </MenuItem>
          ))}
        </CustomFieldSelect>
      </Grid>
      <Grid
        container
        item
        xs={3}
        alignContent="center"
        alignItems="center"
        justifyContent="flex-end"
      >
        <AddButton
          onClick={onAddCustomField}
          disabled={!selectedCustomField}
          variant="outlined"
          type="button"
        >
          Add
        </AddButton>
      </Grid>
    </CustomFieldWrapper>
  );
};

CustomFieldsSelect.propTypes = {
  customFieldsList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  })).isRequired,
  handleAddCustomField: PropTypes.func.isRequired,
};

export default CustomFieldsSelect;
