import axios from 'axios';

import request from 'utils/request';

const PREFIX = '[AUTH]';

export const LOGIN_REQUEST = `${PREFIX} LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${PREFIX} LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${PREFIX} LOGIN_FAILURE`;

const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

const loginFailure = () => ({
  type: LOGIN_FAILURE,
});

export const login = payload => dispatch => {
  dispatch(loginRequest());

  return request
    .post('/v1/users/login/', payload)
    .then(({ data }) => {
      dispatch(loginSuccess());

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(loginFailure());

      throw data;
    });
};

export const LOGOUT_REQUEST = `${PREFIX} LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${PREFIX} LOGOUT_SUCCESS`;
export const LOGOUT_FAILURE = `${PREFIX} LOGOUT_FAILURE`;

const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
});

const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

const logoutFailure = () => ({
  type: LOGOUT_FAILURE,
});

export const logout = () => dispatch => {
  dispatch(logoutRequest());

  return request
    .get('/v1/users/logout/')
    .then(({ data }) => {
      dispatch(logoutSuccess());

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(logoutFailure());

      throw data;
    });
};

export const FETCH_USER_REQUEST = `${PREFIX} FETCH_USER_REQUEST`;
export const FETCH_USER_SUCCESS = `${PREFIX} FETCH_USER_SUCCESS`;
export const FETCH_USER_FAILURE = `${PREFIX} FETCH_USER_FAILURE`;

const fetchUserRequest = () => ({
  type: FETCH_USER_REQUEST,
});

const fetchUserSuccess = payload => ({
  payload,
  type: FETCH_USER_SUCCESS,
});

const fetchUserFailure = payload => ({
  payload,
  type: FETCH_USER_FAILURE,
});

export const fetchUser = () => dispatch => {
  dispatch(fetchUserRequest());

  return request
    .get('/v1/users/profile/')
    .then(({ data }) => {
      dispatch(fetchUserSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchUserFailure());

      throw data;
    });
};

export const UPDATE_PASSWORD_REQUEST = `${PREFIX} UPDATE_PASSWORD_REQUEST`;
export const UPDATE_PASSWORD_SUCCESS = `${PREFIX} UPDATE_PASSWORD_SUCCESS`;
export const UPDATE_PASSWORD_FAILURE = `${PREFIX} UPDATE_PASSWORD_FAILURE`;

const updatePasswordRequest = () => ({
  type: UPDATE_PASSWORD_REQUEST,
});

const updatePasswordSuccess = payload => ({
  payload,
  type: UPDATE_PASSWORD_SUCCESS,
});

const updatePasswordFailure = payload => ({
  payload,
  type: UPDATE_PASSWORD_FAILURE,
});

export const updatePassword = params => dispatch => {
  dispatch(updatePasswordRequest());

  return request
    .post('/v1/users/users/update_password/', params)
    .then(({ data }) => {
      dispatch(updatePasswordSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(updatePasswordFailure());

      throw data;
    });
};

export const FETCH_USER_ORGANIZATIONS_REQUSET = `${PREFIX} FETCH_USER_ORGANIZATIONS_REQUSET`;
export const FETCH_USER_ORGANIZATIONS_SUCCESS = `${PREFIX} FETCH_USER_ORGANIZATIONS_SUCCESS`;
export const FETCH_USER_ORGANIZATIONS_FAILURE = `${PREFIX} FETCH_USER_ORGANIZATIONS_FAILURE`;

const fetchUserOrganizationsReuqest = () => ({
  type: FETCH_USER_ORGANIZATIONS_REQUSET,
});

const fetchUserOrganizationsSuccess = payload => ({
  payload,
  type: FETCH_USER_ORGANIZATIONS_SUCCESS,
});

const fetchUserOrganizationsFailure = payload => ({
  payload,
  type: FETCH_USER_ORGANIZATIONS_FAILURE,
});

export const fetchUserOrganizations = userUuid => dispatch => {
  dispatch(fetchUserOrganizationsReuqest());

  return request
    .get(`/v1/accounts/organization_users/?user=${userUuid}`)
    .then(({ data }) => {
      dispatch(fetchUserOrganizationsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchUserOrganizationsFailure());

      throw data;
    });
};

export const REGISTER_REQUEST = `${PREFIX} REGISTER_REQUEST`;
export const REGISTER_SUCCESS = `${PREFIX} REGISTER_SUCCESS`;
export const REGISTER_FAILURE = `${PREFIX} REGISTER_FAILURE`;

const registerRequest = () => ({
  type: REGISTER_REQUEST,
});

const registerSuccess = () => ({
  type: REGISTER_SUCCESS,
});

const registerFailure = () => ({
  type: REGISTER_FAILURE,
});

export const register = payload => dispatch => {
  dispatch(registerRequest());

  return request
    .post('/v1/users/users/register/', payload)
    .then(({ data }) => {
      dispatch(registerSuccess());

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(registerFailure());

      throw data;
    });
};

export const PASSWORD_RESET_REQUEST = `${PREFIX} PASSWORD_RESET_REQUEST`;
export const PASSWORD_RESET_SUCCESS = `${PREFIX} PASSWORD_RESET_SUCCESS`;
export const PASSWORD_RESET_FAILURE = `${PREFIX} PASSWORD_RESET_FAILURE`;

const passwordResetRequest = () => ({
  type: PASSWORD_RESET_REQUEST,
});

const passwordResetSuccess = () => ({
  type: PASSWORD_RESET_SUCCESS,
});

const passwordResetFailure = () => ({
  type: PASSWORD_RESET_FAILURE,
});

export const passwordReset = payload => dispatch => {
  dispatch(passwordResetRequest());

  return request
    .post('/v1/users/reset-password/', payload)
    .then(({ data }) => {
      dispatch(passwordResetSuccess());

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(passwordResetFailure());

      throw data;
    });
};

export const CONFIRM_PASSWORD_RESET_REQUEST = `${PREFIX} CONFIRM_PASSWORD_RESET_REQUEST`;
export const CONFIRM_PASSWORD_RESET_SUCCESS = `${PREFIX} CONFIRM_PASSWORD_RESET_SUCCESS`;
export const CONFIRM_PASSWORD_RESET_FAILURE = `${PREFIX} CONFIRM_PASSWORD_RESET_FAILURE`;

const confirmPasswordResetRequest = () => ({
  type: CONFIRM_PASSWORD_RESET_REQUEST,
});

const confirmPasswordResetSuccess = () => ({
  type: CONFIRM_PASSWORD_RESET_SUCCESS,
});

const confirmPasswordResetFailure = () => ({
  type: CONFIRM_PASSWORD_RESET_FAILURE,
});

export const confirmPasswordReset = payload => dispatch => {
  dispatch(confirmPasswordResetRequest());

  return request
    .post('/v1/users/confirm-password-reset/', payload)
    .then(({ data }) => {
      dispatch(confirmPasswordResetSuccess());

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(confirmPasswordResetFailure());

      throw data;
    });
};

export const PATCH_USER_REQUEST = `${PREFIX} PATCH_USER_REQUEST`;
export const PATCH_USER_SUCCESS = `${PREFIX} PATCH_USER_SUCCESS`;
export const PATCH_USER_FAILURE = `${PREFIX} PATCH_USER_FAILURE`;

const patchUserRequest = () => ({
  type: PATCH_USER_REQUEST,
});

const patchUserSuccess = payload => ({
  payload,
  type: PATCH_USER_SUCCESS,
});

const patchUserFailure = payload => ({
  payload,
  type: PATCH_USER_FAILURE,
});

export const patchUser = params => dispatch => {
  dispatch(patchUserRequest());

  return request
    .patch('/v1/users/profile/', params)
    .then(({ data }) => {
      dispatch(patchUserSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchUserFailure(data));

      throw data;
    });
};

export const FETCH_CSRF_TOKEN_REQUSET = `${PREFIX} FETCH_CSRF_TOKEN_REQUSET`;
export const FETCH_CSRF_TOKEN_SUCCESS = `${PREFIX} FETCH_CSRF_TOKEN_SUCCESS`;
export const FETCH_CSRF_TOKEN_FAILURE = `${PREFIX} FETCH_CSRF_TOKEN_FAILURE`;

const fetchCsrfTokenReuqest = () => ({
  type: FETCH_CSRF_TOKEN_REQUSET,
});

const fetchCsrfTokenSuccess = payload => ({
  payload,
  type: FETCH_CSRF_TOKEN_SUCCESS,
});

const fetchCsrfTokenFailure = payload => ({
  payload,
  type: FETCH_CSRF_TOKEN_FAILURE,
});

export const fetchCsrfToken = () => dispatch => {
  dispatch(fetchCsrfTokenReuqest());

  return request
    .get('/v1/users/get-csrf-token/')
    .then(({
      data,
      headers,
    }) => {
      if (headers['x-csrftoken']) {
        localStorage.setItem('x-csrftoken', headers['x-csrftoken']);
        axios.defaults.headers.common['X-CSRFToken'] = headers['x-csrftoken'];
      }
      dispatch(fetchCsrfTokenSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchCsrfTokenFailure());

      throw data;
    });
};

export const FETCH_AUTH_STATUS_REQUSET = `${PREFIX} FETCH_AUTH_STATUS_REQUSET`;
export const FETCH_AUTH_STATUS_SUCCESS = `${PREFIX} FETCH_AUTH_STATUS_SUCCESS`;
export const FETCH_AUTH_STATUS_FAILURE = `${PREFIX} FETCH_AUTH_STATUS_FAILURE`;

const fetchAuthStatusReuqest = () => ({
  type: FETCH_AUTH_STATUS_REQUSET,
});

const fetchAuthStatusSuccess = payload => ({
  payload,
  type: FETCH_AUTH_STATUS_SUCCESS,
});

const fetchAuthStatusFailure = payload => ({
  payload,
  type: FETCH_AUTH_STATUS_FAILURE,
});

export const fetchAuthStatus = () => dispatch => {
  dispatch(fetchAuthStatusReuqest());

  return request
    .get('/v1/users/auth-status/')
    .then(({ data }) => {
      dispatch(fetchAuthStatusSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchAuthStatusFailure());

      throw data;
    });
};
