import {
  DELETE_MEETING_FAILURE,
  DELETE_MEETING_REQUEST,
  DELETE_MEETING_SUCCESS,
  FETCH_ALL_MEETINGS_FAILURE,
  FETCH_ALL_MEETINGS_REQUEST,
  FETCH_ALL_MEETINGS_SUCCESS,
  FETCH_MEETING_FAILURE,
  FETCH_MEETING_REQUEST,
  FETCH_MEETING_SUCCESS,
  FETCH_MEETINGS_FAILURE,
  FETCH_MEETINGS_REQUEST,
  FETCH_MEETINGS_SUCCESS,
  PATCH_MEETING_FAILURE,
  PATCH_MEETING_REQUEST,
  PATCH_MEETING_SUCCESS,
  POST_MEETING_FAILURE,
  POST_MEETING_REQUEST,
  POST_MEETING_SUCCESS,
} from '../actions/meetings';

const initialState = {
  errors: null,
  isFetching: false,
  isPending: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_MEETINGS_REQUEST:
    case FETCH_MEETING_REQUEST:
    case FETCH_MEETINGS_REQUEST:
      return {
        ...state,
        errors: null,
        isFetching: true,
      };
    case FETCH_ALL_MEETINGS_SUCCESS:
    case FETCH_MEETING_SUCCESS:
    case FETCH_MEETINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_ALL_MEETINGS_FAILURE:
    case FETCH_MEETING_FAILURE:
    case FETCH_MEETINGS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };
    case DELETE_MEETING_REQUEST:
    case PATCH_MEETING_REQUEST:
    case POST_MEETING_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_MEETING_SUCCESS:
    case PATCH_MEETING_SUCCESS:
    case POST_MEETING_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case DELETE_MEETING_FAILURE:
    case PATCH_MEETING_FAILURE:
    case POST_MEETING_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    default:
      return state;
  }
};
