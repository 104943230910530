import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { set } from 'lodash';
import dayjs from 'dayjs';

import {
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import {
  fetchChildrenLists,
  fetchRootList,
} from 'store/actions/contacts';
import {
  clearActionAlert,
  clearActionCenter,
  fetchActionAlert,
  fetchActionCenter,
  patchActionAlert,
  postActionAlert,
  postCircleBack,
  postSendActionAlert,
  postSendPreviewActionAlert,
} from 'store/actions/campaigns';
import {
  fetchAllTemplates,
  postTemplate,
} from 'store/actions/templates';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';
import { contactSelectors } from 'store/selectors/contacts';
import { campaignsSelectors } from 'store/selectors/campaigns';
import { organizationSelectors } from 'store/selectors/organizations';
import { fetchEmailDomains } from 'store/actions/organizations';

import {
  Backdrop,
  CancelCreatorButton,
  CardField,
  Container,
  DescriptionBar,
  Dialog,
  Stepper,
  SuccessDialog,
} from 'common/components';
import {
  colors,
} from 'utils/constants';
import actionAlertTemplate1 from 'utils/template1.json';
import actionAlertTemplate2 from 'utils/template2.json';
import {
  findPaths,
  formatNumber,
  handleFillInTemplate1,
  handleFillInTemplate2,
  handleMergeCorrectTime,
  isEmail,
  isGeneralError,
  renderErrorMessage,
  scrollToTop,
  showFirstErrorMessage,
  useQuery,
} from 'utils/helpers';
import {
  ErrorTypography,
  StepOneContainer,
  StepThreeContainer,
  StepTwoContainer,
} from 'globalStyles';

import StepOne from 'routes/CreateEmailActionAlert/components/StepOne';
import StepTwo from 'routes/CreateEmailActionAlert/components/StepTwo';
import StepThree from 'routes/CreateEmailActionAlert/components/StepThree';
import {
  initialTimezone,
  timezones,
} from 'utils/timezones';

const { REACT_APP_MOUSEFLOW } = process.env;

const ButtonCreateWrapper = styled.div`
  position: relative;
`;

const ButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const NextButtonWrapper = styled.div`
  padding-left: 10px;
`;

const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90px;
  margin-top: 50px;
  background-color: ${colors.white};
`;

const CreateEmailActionAlert = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const isCircleBackActionAlert = history.location.pathname.includes('/circle-back/') || query.get('circle-back');
  const isEditActionAlert = history.location.pathname.includes('/edit/');
  const isDuplicateActionAlert = history.location.pathname.includes('/duplicate/');

  const {
    actionCenterUuid,
    actionAlertUuid,
    campaignUuid,
    organizationUuid,
  } = useParams();

  const {
    childrenLists,
    emailDomainData,
    isActionAlertFetching,
    isPending,
    organization,
    rootList,
  } = useSelector(state => ({
    childrenLists: contactSelectors.getChildrenLists(state)[organizationUuid] || [],
    emailDomainData: organizationSelectors.getEmailDomainData(state),
    isActionAlertFetching: campaignsSelectors.isActionAlertFetching(state),
    isPending: campaignsSelectors.isPending(state),
    organization: organizationSelectors.getOrganization(state),
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  const listsOptions = useMemo(() => [
    rootList,
    ...childrenLists,
  ], [
    rootList,
    childrenLists,
  ]);

  const [
    selectedDate,
    setSelectedDate,
  ] = useState(dayjs());
  const [
    selectedTime,
    setSelectedTime,
  ] = useState(dayjs());
  const [
    previewEmails,
    setPreviewEmails,
  ] = useState('');
  const [
    previewEmailsError,
    setPreviewEmailsError,
  ] = useState(null);
  const [
    template,
    setTemplate,
  ] = useState({});
  const [
    emailError,
    setEmailError,
  ] = useState(false);
  const [
    countRecipients,
    setCountRecipients,
  ] = useState(0);
  const [
    step,
    setStep,
  ] = useState(0);
  const [
    errorMessage,
    setErrorMessage,
  ] = useState(null);
  const [
    isScheduled,
    setIsScheduled,
  ] = useState(false);
  const [
    isSuccessDialogOpen,
    setIsSuccessDialogOpen,
  ] = useState(false);
  const [
    buttonTextColor,
    setButtonTextColor,
  ] = useState(colors.white);
  const [
    buttonBackgroundColor,
    setButtonBackgroundColor,
  ] = useState(colors.black);
  const [
    selectedTimeZone,
    setSelectedTimeZone,
  ] = useState(initialTimezone(organization.timezone));
  const [
    formValues,
    setFormValues,
  ] = useState({
    actionCenterValue: null,
    listsValue: [],
    nameValue: '',
    senderEmailValue: '',
    senderNameValue: '',
    subjectValue: '',
  });
  const [
    actionAlertDetails,
    setActionAlertDetails,
  ] = useState({});

  const [
    isTemplateNameModalOpen,
    setIsTemplateNameModalOpen,
  ] = useState(false);
  const [
    templateNameValue,
    setTemplateNameValue,
  ] = useState('');
  const [
    dialogError,
    setDialogError,
  ] = useState('');
  const [
    templatesList,
    setTemplatesList,
  ] = useState([]);
  const [
    isLoading,
    setIsLoading,
  ] = useState(true);

  const emailEditorRef = useRef(null);

  useEffect(() => {
    if (Object.keys(rootList).length === 0) {
      dispatch(fetchRootList(organizationUuid))
        .then(data => dispatch(fetchChildrenLists(data.uuid, organizationUuid)));
    } else {
      dispatch(fetchChildrenLists(rootList.uuid, organizationUuid));
    }

    if (actionCenterUuid) {
      dispatch(fetchActionCenter(actionCenterUuid))
       .then(data => setFormValues(prevValues => ({
        ...prevValues,
        actionCenterValue: data,
      })));
    }
  }, [
    actionCenterUuid,
    dispatch,
    organizationUuid,
  ]);

  useEffect(() => {
    dispatch(fetchEmailDomains(organizationUuid));
  }, []);

  useEffect(() => {
    if (REACT_APP_MOUSEFLOW) {
      // eslint-disable-next-line no-underscore-dangle
      window._mfq = window._mfq || [];

      const mf = document.createElement('script');

      mf.type = 'text/javascript';
      mf.defer = true;
      mf.src = `//cdn.mouseflow.com/projects/${REACT_APP_MOUSEFLOW}.js`;
      document.getElementsByTagName('head')[0].appendChild(mf);
    }
  }, []);

  const patchEmailActionAlertByHtml = () => {
    emailEditorRef.current.editor.exportHtml(async data => {
      const {
        design,
        html,
      } = data;

      const actionAlertData = await dispatch(patchActionAlert(actionAlertDetails.uuid, {
        config: design,
        message: html,
      }));

      setActionAlertDetails(prevValue => ({
        ...prevValue,
        ...actionAlertData,
      }));

      setStep(prevStep => prevStep + 1);
    });
  };

  const postNewEmailActionAlert = async () => {
    const params = {
      actionCenter: formValues.actionCenterValue.uuid,
      contactLists: formValues.listsValue?.map(list => list.uuid),
      fromEmail: formValues.senderEmailValue,
      fromName: formValues.senderNameValue,
      name: formValues.nameValue,
      organization: organizationUuid,
      subject: formValues.subjectValue,
    };

    try {
      if (
        actionAlertDetails.uuid ||
        isEditActionAlert
      ) {
        const actionAlertData = await dispatch(patchActionAlert(
          actionAlertDetails.uuid || actionAlertUuid,
          params
        ));

        setCountRecipients(actionAlertData?.countRecipients);

        setActionAlertDetails(prevValue => ({
          ...prevValue,
          ...actionAlertData,
        }));
        setErrorMessage(null);
      } else {
        const actionAlertData = await dispatch(isCircleBackActionAlert ?
          postCircleBack(actionAlertUuid, params) :
          postActionAlert(params));

        setCountRecipients(actionAlertData?.countRecipients);

        setActionAlertDetails(prevValue => ({
          ...prevValue,
          ...actionAlertData,
        }));

        setErrorMessage(null);
      }
      setStep(prevStep => prevStep + 1);
    } catch (error) {
      setErrorMessage(error);
    } finally {
      scrollToTop();
    }
  };

  const sendEmailActionAlert = async () => {
    const params = {};

    if (isScheduled) {
      params.sendTime = handleMergeCorrectTime(selectedDate, selectedTime);
      params.sendAtTimezone = selectedTimeZone;
    }

    try {
      await dispatch(postSendActionAlert(actionAlertDetails.uuid, params));

      setIsSuccessDialogOpen(true);
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const handleSendPreview = async () => {
    const contactLists = previewEmails.split(', ');

    try {
      await dispatch(postSendPreviewActionAlert(actionAlertDetails.uuid, { emails: contactLists }));

      dispatch(setSnackbarSuccess({ message: 'An email will be sent to the provided address.' }));
      setPreviewEmailsError(null);
    } catch (error) {
      dispatch(setSnackbarError());
      setPreviewEmailsError(error);
    }
  };

  const onEditorLoad = async () => {
    if (Object.keys(organization).length !== 0) {
      dispatch(clearActionCenter());
      dispatch(clearActionAlert());
      setActionAlertDetails({});

      let templateData = {};
      let hasNoTemplate = true;

      const template1 = JSON.parse(JSON.stringify(actionAlertTemplate1));
      const template2 = JSON.parse(JSON.stringify(actionAlertTemplate2));

      handleFillInTemplate1(template1, organization);
      handleFillInTemplate2(template2, organization);

      const allTemplates = await dispatch(fetchAllTemplates({
        organization: organizationUuid,
        type: 'action_alert',
      }));

      setTemplatesList([
        {
          body: template1,
          name: 'template1',
        },
        {
          body: template2,
          name: 'template2',
        },
        ...allTemplates,
      ]);

      if (actionAlertUuid) {
        const {
          recipients,
          config,
          fromEmail,
          fromName,
          subject,
          name,
        } = await dispatch(fetchActionAlert(actionAlertUuid));

        setFormValues(prevValues => ({
          ...prevValues,
          listsValue: recipients,
          nameValue: name,
          senderEmailValue: fromEmail,
          senderNameValue: fromName || '',
          subjectValue: subject,
        }));

        hasNoTemplate = Object.keys(config).length === 0;

        if (hasNoTemplate) {
          templateData = {
            body: template1,
            name: 'template1',
          };
        } else {
          templateData = {
            body: config,
            name: '',
          };
        }
      } else {
        templateData = {
          body: template1,
          name: 'template1',
        };
      }

      setTemplate(templateData);

      if (emailEditorRef.current?.editor) {
        emailEditorRef.current.editor.loadDesign(templateData.body);
      }
      setIsLoading(false);
    }
  };

  const steps = [
    'Setup',
    'Design',
    'Preview/Send',
  ];

  const handleChangeTemplate = value => {
    const newTemplate = templatesList.find(templateItem => templateItem.name === value);

    emailEditorRef.current.editor.loadDesign(newTemplate.body);
    setTemplate(newTemplate);
  };

  const handleChangeTemplateButton = (type, value) => {
    emailEditorRef.current.editor.exportHtml(async data => {
      const { design } = data;

      const [pathToHref] = findPaths(design, '--{LANDING_PAGE}--');

      if (type === 'background') {
        const pathToBackground = `${pathToHref.slice(0, -16)}buttonColors.backgroundColor`;

        set(design, pathToBackground, value);

        setButtonBackgroundColor(value);
      }

      if (type === 'text') {
        const pathToText = `${pathToHref.slice(0, -16)}buttonColors.color`;

        set(design, pathToText, value);

        setButtonTextColor(value);
      }

      emailEditorRef.current.editor.loadDesign(design);
    });
  };

  const handleSaveAsTemplate = () => {
    emailEditorRef.current.editor.exportHtml(async data => {
      const {
        design,
        html,
      } = data;
      const params = {
        body: design,
        message: html,
        name: templateNameValue,
        organization: organizationUuid,
        type: 'action_alert',
      };

      try {
        const newTemplate = await dispatch(postTemplate(params));

        setTemplatesList(prevValues => [
          ...prevValues,
          newTemplate,
        ]);
        setIsTemplateNameModalOpen(false);
        setTemplate(newTemplate);
        setTemplateNameValue('');
      } catch (error) {
        setDialogError(error);
      }
    });
  };

  const disableNextButton = () => {
    if (step === 0) {
      return !(formValues.nameValue && formValues.senderNameValue &&
        formValues.senderEmailValue && formValues.subjectValue && formValues.listsValue);
    }

    if (step === 2 && isScheduled) {
      return !(selectedTime && selectedDate);
    }

    if (isPending) {
      return true;
    }

    return false;
  };

  const handleSave = () => {
    if (step === 0) {
      if (!isEmail(formValues.senderEmailValue)) {
        setEmailError(true);
      } else {
        postNewEmailActionAlert();
      }
    } else if (step === 1) {
      patchEmailActionAlertByHtml();
    } else if (step === 2) {
      sendEmailActionAlert();
    }
  };

  const title = () => {
    if (isDuplicateActionAlert) {
      return 'Duplicate';
    }

    if (isCircleBackActionAlert) {
      return 'Circle Back';
    }

    if (isEditActionAlert) {
      return 'Edit';
    }

    return 'Create New';
  };

  return (
    <>
      <Container>
        <Grid container>
          <DescriptionBar
            icon={FilterListIcon}
            name={`${title()} Action Alert`}
          >
            <CancelCreatorButton redirectUrl={
              (isEditActionAlert || isDuplicateActionAlert) ?
                `/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}/action-alerts/${actionAlertUuid}` :
                `/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}?tab=0`
              }
            />
          </DescriptionBar>
        </Grid>
        <Stepper
          step={step}
          steps={steps}
          neutralcolors
        />
        {errorMessage && isGeneralError(errorMessage, ['subject']) && (
          <CardField name="Error">
            <ErrorTypography>
              {renderErrorMessage(errorMessage)}
            </ErrorTypography>
          </CardField>
        )}
        <StepOneContainer step={step}>
          <StepOne
            emailDomainData={emailDomainData?.[0]}
            emailError={emailError}
            errorMessage={errorMessage}
            isCircleBackActionAlert={!!isCircleBackActionAlert}
            lists={listsOptions}
            listsValue={formValues.listsValue}
            nameValue={formValues.nameValue}
            senderEmailValue={formValues.senderEmailValue}
            senderNameValue={formValues.senderNameValue}
            setEmailError={setEmailError}
            setFormValues={setFormValues}
            subjectValue={formValues.subjectValue}
          />
        </StepOneContainer>
        <StepTwoContainer step={step}>
          <StepTwo
            buttonTextColor={buttonTextColor}
            buttonBackgroundColor={buttonBackgroundColor}
            emailEditorRef={emailEditorRef}
            isDuplicated={isDuplicateActionAlert}
            isOrganizationFetched={Object.keys(organization).length !== 0}
            handleChangeTemplate={handleChangeTemplate}
            handleChangeTemplateButton={handleChangeTemplateButton}
            handleOpenSaveAsTemplateModal={() => setIsTemplateNameModalOpen(true)}
            onLoad={onEditorLoad}
            template={template}
            templatesList={templatesList}
          />
        </StepTwoContainer>
        <StepThreeContainer step={step}>
          <StepThree
            isCircleBackActionAlert={!!isCircleBackActionAlert}
            isScheduled={isScheduled}
            message={actionAlertDetails?.message}
            onSendPreview={handleSendPreview}
            previewEmails={previewEmails}
            previewEmailsError={previewEmailsError}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            selectedTimeZone={selectedTimeZone}
            setPreviewEmails={setPreviewEmails}
            setSelectedDate={setSelectedDate}
            setSelectedTime={setSelectedTime}
            setSelectedTimeZone={setSelectedTimeZone}
            setIsScheduled={setIsScheduled}
            timezones={timezones}
          />
        </StepThreeContainer>
      </Container>
      <StepperWrapper>
        <Grid
          container
          direction="row"
          item
          justifyContent="space-between"
          lg={10}
          xs={11}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-start"
            xs={2}
          >
            <Button
              disabled={step === 0}
              onClick={() => setStep(prevStep => prevStep - 1)}
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-end"
            xs={4}
          >
            <NextButtonWrapper>
              <ButtonCreateWrapper>
                <Button
                  color="secondary"
                  disabled={disableNextButton() || isPending}
                  onClick={handleSave}
                  variant="contained"
                  type="button"
                >
                  {step !== 2 ? 'Save and Continue' : `Send to ${formatNumber(countRecipients)} recipients`}
                </Button>
                {isPending && <ButtonCircularProgress size={24} />}
              </ButtonCreateWrapper>
            </NextButtonWrapper>
          </Grid>
        </Grid>
      </StepperWrapper>
      <SuccessDialog
        isCreateNewDisabled
        isDialogOpen={isSuccessDialogOpen}
        name="Action Alert"
        redirectTo={actionCenterUuid ?
          `/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}?tab=0` :
          `/${organizationUuid}/dashboard`
        }
        thankYouMessage="Thank you for sending this Action Alert"
      />
      <Dialog
        dialogError={dialogError && showFirstErrorMessage(dialogError)}
        handleConfirm={handleSaveAsTemplate}
        handleDialogClose={() => {
          setIsTemplateNameModalOpen(false);
          setTemplateNameValue('');
        }}
        isDialogOpen={isTemplateNameModalOpen}
        title="Template Name"
        disabledButton={isPending}
      >
        <TextField
          fullWidth
          placeholder="Add Template Name"
          onChange={event => setTemplateNameValue(event.target.value)}
          type="text"
          value={templateNameValue}
        />
      </Dialog>
      <Backdrop isOpen={isActionAlertFetching || isLoading} />
    </>
  );
};

export default CreateEmailActionAlert;
