import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';

import PhoneView from 'common/components/PhoneView';

import {
  actionTypes,
  colors,
} from 'utils/constants';

const StyledBarButton = styled(Button)`
  margin-top: 8px;
`;

const StyledInfoGrid = styled(Grid)`
  flex-direction: column;
  align-items: flex-start;
  padding-left: 50px;
`;

const StyledTypography = styled(Typography)`
  max-width: 400px;
  color: ${colors.osloGray};
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const LandingPageWrapper = styled.div`
  margin: 20px 0;
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  justify-content: center;
`;

const DescriptionBarInfo = ({
  children,
  contactLists,
  createdAt,
  createdBy,
  detailsText,
  handleCopyLink,
  href,
  isButtonVisible,
  landingPageLink,
  link,
  message,
  sendAt,
  subject,
  type,
}) => {
  const [
    isMessageOpen,
    setMessageOpen,
  ] = useState(false);

  const handleMessageClose = () => setMessageOpen(false);
  const handleMessageOpen = () => setMessageOpen(true);

  return (
    <StyledInfoGrid
      container
      item
      justifyContent="space-between"
    >
      {subject && (
        <StyledTypography variant="subtitle1">
          Subject:
          &nbsp;
          {subject}
        </StyledTypography>
      )}
      {!!contactLists.length && (
        <StyledTypography variant="subtitle1">
          Lists:
          &nbsp;
          {contactLists.map(({ name }) => name).join(', ')}
        </StyledTypography>
      )}
      {sendAt && (
        <StyledTypography variant="subtitle1">
          Send at:
          &nbsp;
          {new Date(sendAt).toLocaleString('us-US')}
        </StyledTypography>
      )}
      {createdAt && (
        <StyledTypography variant="subtitle1">
          Active since:
          &nbsp;
          {new Date(createdAt).toLocaleString('us-US')}
        </StyledTypography>
      )}
      {!!landingPageLink && (
        <LandingPageWrapper>
          <StyledButton
            color="secondary"
            disableElevation
            variant="contained"
            onClick={handleCopyLink}
          >
            Copy Landing Page Link
          </StyledButton>
          Or
          &nbsp;
          <a
            href={landingPageLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Go To Landing Page
          </a>
        </LandingPageWrapper>
      )}
      {createdBy && (
        <StyledTypography variant="subtitle1">
          Created by:
          &nbsp;
          {`${createdBy.firstName} ${createdBy.lastName}`}
        </StyledTypography>
      )}
      {isButtonVisible && (
        href ? (
          <StyledBarButton
            component={Link}
            size="small"
            startIcon={<SettingsIcon />}
            variant="outlined"
            to={href}
          >
            <Typography variant="subtitle2">
              {detailsText}
            </Typography>
          </StyledBarButton>
        ) : (
          <>
            <StyledBarButton
              disabled={!message}
              onClick={handleMessageOpen}
              size="small"
              startIcon={<SettingsIcon />}
              variant="outlined"
            >
              <Typography variant="subtitle2">Preview message</Typography>
            </StyledBarButton>
            <Dialog
              aria-labelledby="max-width-dialog-message"
              maxWidth="lg"
              open={isMessageOpen}
              onClose={handleMessageClose}
            >
              <DialogTitle id="max-width-dialog-message">
                {subject}
              </DialogTitle>
              {type === actionTypes.text ? (
                <StyledDialogContent>
                  <PhoneView
                    link={link}
                    text={message}
                  />
                </StyledDialogContent>
              ) : (
                <DialogContent dangerouslySetInnerHTML={{ __html: message }} />
              )}
              <DialogActions>
                <Button onClick={handleMessageClose}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      )}
      {!!children.length && <StyledTypography variant="subtitle2">{children}</StyledTypography>}
    </StyledInfoGrid>
  );
};

DescriptionBarInfo.defaultProps = {
  children: '',
  contactLists: [],
  createdAt: '',
  createdBy: null,
  detailsText: 'View details',
  handleCopyLink: () => {},
  href: null,
  isButtonVisible: false,
  landingPageLink: null,
  link: null,
  message: '',
  sendAt: '',
  subject: '',
  type: null,
};

DescriptionBarInfo.propTypes = {
  children: PropTypes.node,
  contactLists: PropTypes.arrayOf(PropTypes.shape({})),
  createdAt: PropTypes.string,
  createdBy: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  detailsText: PropTypes.string,
  handleCopyLink: PropTypes.func,
  href: PropTypes.string,
  isButtonVisible: PropTypes.bool,
  landingPageLink: PropTypes.string,
  link: PropTypes.string,
  message: PropTypes.string,
  sendAt: PropTypes.string,
  subject: PropTypes.string,
  type: PropTypes.string,
};

export default DescriptionBarInfo;
