import { CircularProgressWrapper } from 'globalStyles';

import { CircularProgress as MaterialCircularProgress } from '@mui/material';

const CircularProgress = () => (
  <CircularProgressWrapper>
    <MaterialCircularProgress />
  </CircularProgressWrapper>
);

export default CircularProgress;
