import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import {
  Autocomplete,
  TextField,
} from '@mui/material';

const StyledAutocomplete = styled(Autocomplete)`
  padding-bottom: 10px;
`;

const ActionCenterDetails = ({
  liveActionCenters = [],
  pickedActionCenters = [],
  setPickedActionCenters,
}) => {
  const [
    pickedCampaign,
    setPickedCampaign,
  ] = useState(null);
  const [
    campaigns,
    setCampaigns,
  ] = useState([]);
  const [
    actionCenters,
    setActionCenters,
  ] = useState(liveActionCenters);

  useEffect(() => {
    if (liveActionCenters.length) {
      setCampaigns(() => {
        const uniqueCampaigns = [];

        liveActionCenters.forEach(item => {
          if (!uniqueCampaigns.some(campaign => campaign.campaignUuid === item.campaignUuid)) {
            uniqueCampaigns.push({
              campaignUuid: item.campaignUuid,
              name: item.campaignName,
            });
          }
        });

        return uniqueCampaigns;
      });
    }
  }, [liveActionCenters]);

  const handlePickCampaigns = (_, pickedOption) => {
    setActionCenters(
      pickedOption ? liveActionCenters.filter(
        item => pickedOption.campaignUuid === item.campaignUuid
      ) : liveActionCenters
    );
    setPickedCampaign(pickedOption);
  };

  return (
    <>
      <StyledAutocomplete
        label="Campaigns"
        getOptionLabel={option => option.name}
        fullWidth
        onChange={handlePickCampaigns}
        options={campaigns}
        renderInput={params => (
          <TextField
            {...params}
            label="Campaigns (optional)"
            variant="standard"
          />
        )}
        value={pickedCampaign}
      />
      <StyledAutocomplete
        label="Action Centers"
        getOptionLabel={option => option.name}
        fullWidth
        multiple
        onChange={(_, pickedOption) => {
          setPickedActionCenters(pickedOption);
        }}
        options={actionCenters}
        renderInput={params => (
          <TextField
            {...params}
            label="Action Centers"
            variant="standard"
          />
        )}
        value={pickedActionCenters}
      />
    </>
  );
};

ActionCenterDetails.propTypes = {
  liveActionCenters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pickedActionCenters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setPickedActionCenters: PropTypes.func.isRequired,
};

export default ActionCenterDetails;
