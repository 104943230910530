import styled from 'styled-components';
import { colors } from 'utils/constants';
import { Field } from 'react-final-form';
import {
  Button,
  Grid,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';

import mediaQueries from 'utils/mediaQueries';

const {
  large,
  medium,
  small,
} = mediaQueries;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Mono = styled.span`
  font-family: monospace;
`;

export const CircularProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 200px);
`;

export const FormWrapper = styled.div`
  width: 50%;

  &:nth-of-type(2n+1) {
    padding-right: 40px;
  }

  &:last-of-type {
    margin-bottom: 60px;
  }
`;

export const OptionImage = styled.img`
  padding-bottom: 20px;
`;

export const SettingsWrapper = styled.div`
  padding-top: 10px;
`;

export const StyledField = styled(Field)`
  width: ${props => (props.unsetwidth ? 'unset' : '100%')};
  max-width: 300px;
  height: 40px;
  box-shadow: rgb(224 223 224 / 60%) 0 1px 3px 0 inset;
  border: 1px solid ${colors.mercury};
  border-radius: 5px;
  padding: 10px;
`;

export const StyledFieldButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${props => props.size || '200'}px;
  height: ${props => props.size || '200'}px;
  margin-right: 30px;
  margin-bottom: 30px;
  border: solid ${props => (props.checked ? colors.midnightBlue : colors.mercury)};
  border-width: ${props => (props.checked ? '3px' : '1px')};
  border-radius: 5px;
  background-color: ${props => (props.checked ? colors.white : colors.gallery)};
  cursor: pointer;
`;

export const StyledHighlightOffIcon = styled(HighlightOffIcon)`
  color: ${colors.warning};
`;

export const WarningCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)`
  color: ${colors.warning};
`;

export const TakeActionButton = styled(Button)`
  width: 250px;
  height: 52px;
  margin-top: 40px;
  margin-bottom: 50px;
  background-color: ${colors.midnightBlue};
  color: ${colors.white};

  &:hover {
    background-color: ${colors.midnightBlue};
  }
`;

export const SendMessageButton = styled(Button)`
  width: 357px;
  height: 60px;
  background-color: ${props => (props.buttoncolor || colors.gold)};
  color: ${props => (props.textcolor || colors.black)};

  /* stylelint-disable-next-line selector-class-pattern */
  &.Mui-disabled {
    color: ${props => (props.textcolor || colors.white)};
  }

  &:hover {
    background-color: ${props => (props.buttoncolor || colors.gold)};
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 30px;

  @media ${small} {
    flex-direction: column;
  }
`;

export const StatisticsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;

  @media ${large} {
    justify-content: space-between;
  }

  @media ${medium} {
    justify-content: center;
  }

  & > div:not(:last-of-type) {
    margin-right: 40px;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  margin-top: 30px;
  padding-top: 5px;
`;

export const Asterisk = styled.span`
  &::after {
    content: " *";
    color: ${colors.warning};
  }
`;

export const DisabledListItemText = styled(ListItemText)`
  color: ${props => (props.isdisabled ? colors.boulder : colors.black)};
  cursor: ${props => (props.isdisabled ? 'not-allowed' : 'pointer')};
`;

export const StepContainer = styled.div`
  margin-top: 10px;
`;

export const StepOneContainer = styled(StepContainer)`
  display: ${props => ((props.step === 0) ? 'block' : 'none')};
`;

export const StepTwoContainer = styled(StepContainer)`
  display: ${props => ((props.step === 1) ? 'block' : 'none')};
`;

export const StepThreeContainer = styled(StepContainer)`
  display: ${props => ((props.step === 2) ? 'block' : 'none')};
`;

export const StepFourContainer = styled(StepContainer)`
  display: ${props => ((props.step === 3) ? 'block' : 'none')};
`;

export const DetailsWrapper = styled(Grid)`
  width: 100%;
  padding-top: 10px;
`;

export const StyledCode = styled.code`
  white-space: break-spaces;
`;

export const ErrorTypography = styled(Typography)`
  color: ${colors.error};
`;

export const CardWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 24px;
  background-color: ${({ theme }) => (theme.colors.white)};
`;
