import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import { CardField } from 'common/components';
import { colors } from 'utils/constants';
import { showFirstErrorMessage } from 'utils/helpers';

const CardFieldWrapper = styled.div`
  margin-top: 30px;
`;

const SendPreviewWrapper = styled.div`
  width: 100%;
  margin: 30px 0;
  padding: 28px;
  background-color: ${colors.backgroundColor};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 30px;
`;

const StyledGrid = styled(Grid)`
  flex: 0 0 100%;
`;

const StyledDatePicker = styled(DatePicker)`
  margin-right: 30px;
`;

const StyledTimePicker = styled(TimePicker)`
  margin-right: 30px;
`;

const StepThree = ({
  isScheduled,
  message,
  onSendPreview,
  previewEmails,
  previewEmailsError,
  selectedDate,
  selectedTime,
  selectedTimeZone,
  setPreviewEmails,
  setSelectedDate,
  setSelectedTime,
  setSelectedTimeZone,
  setIsScheduled,
  timezones,
}) => {
  const [
    sendPreview,
    setSendPreview,
  ] = useState(true);

  return (
    <>
      <CardFieldWrapper>
        <CardField
          name="Email Broadcast Preview"
          flexwrap
        >
          <Grid container>
            <SendPreviewWrapper>
              <StyledFormControlLabel
                control={(
                  <Checkbox
                    checked={sendPreview}
                    onChange={() => setSendPreview(prevValue => !prevValue)}
                  />
                )}
                label={(
                  <>
                    <Typography>Send a preview of Broadcast</Typography>
                    <Typography variant="body2">
                      You can enter a single email address or list
                      of comma-separated email addresses
                      to send a preview of the Broadcast to.
                    </Typography>
                  </>
                )}
              />
              {
                sendPreview && (
                  <Grid
                    alignItems="center"
                    container
                  >
                    <Grid
                      item
                      xs={9}
                    >
                      <TextField
                        autoFocus
                        error={!!previewEmailsError}
                        helperText={previewEmailsError && showFirstErrorMessage(previewEmailsError)}
                        fullWidth
                        label="Email"
                        onChange={event => setPreviewEmails(event.target.value)}
                        type="text"
                        value={previewEmails}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      justifyContent="flex-end"
                      xs={3}
                    >
                      <Button
                        color="secondary"
                        onClick={onSendPreview}
                        variant="contained"
                      >
                        Send Preview
                      </Button>
                    </Grid>
                  </Grid>
                )
              }
            </SendPreviewWrapper>
            <StyledGrid>
              {message && (
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: message,
                  }}
                />
              )}
            </StyledGrid>
          </Grid>
        </CardField>
      </CardFieldWrapper>
      <CardFieldWrapper>
        <CardField
          flexwrap
          name="Time Schedule"
        >
          <Grid container>
            <Typography gutterBottom>
              You can choose to send the Broadcast right away (default) or choose
              a time in the future to schedule the email. If scheduling for a time in the future,
              you will be able to choose a day, hour and timezone.
            </Typography>
            <RadioGroup>
              <FormControlLabel
                checked={!isScheduled}
                control={<Radio />}
                onChange={() => setIsScheduled(false)}
                label="Send right away"
              />
              <FormControlLabel
                checked={isScheduled}
                control={<Radio />}
                onChange={() => setIsScheduled(true)}
                label="Send at a scheduled time"
              />
            </RadioGroup>
          </Grid>
          <StyledGrid
            container
            id="broadcast-schedule-picker"
          >
            { isScheduled && (
              <>
                <StyledDatePicker
                  id="date-picker-dialog"
                  label="Date picker dialog"
                  value={selectedDate}
                  onChange={date => setSelectedDate(date)}
                  minDate={dayjs()}
                />
                <StyledTimePicker
                  id="time-picker"
                  label="Time picker"
                  value={selectedTime}
                  onChange={date => setSelectedTime(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
                <Select
                  value={selectedTimeZone}
                  onChange={event => setSelectedTimeZone(event.target.value)}
                >
                  {timezones.map(timezone => (
                    <MenuItem
                      key={timezone}
                      value={timezone}
                    >
                      {timezone}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )
            }
          </StyledGrid>
        </CardField>
      </CardFieldWrapper>
    </>
  );
};

StepThree.defaultProps = {
  message: '',
  previewEmailsError: null,
};

StepThree.propTypes = {
  isScheduled: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onSendPreview: PropTypes.func.isRequired,
  previewEmails: PropTypes.string.isRequired,
  previewEmailsError: PropTypes.shape({}),
  selectedDate: PropTypes.shape({
    $d: PropTypes.instanceOf(Date),
  }).isRequired,
  selectedTime: PropTypes.shape({
    $d: PropTypes.instanceOf(Date),
  }).isRequired,
  selectedTimeZone: PropTypes.string.isRequired,
  setIsScheduled: PropTypes.func.isRequired,
  setPreviewEmails: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  setSelectedTime: PropTypes.func.isRequired,
  setSelectedTimeZone: PropTypes.func.isRequired,
  timezones: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StepThree;
