import { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import {
  fetchUserOrganizations,
  patchUser,
  updatePassword,
} from 'store/actions/auth';
import { authSelectors } from 'store/selectors/auth';

import { Container } from 'common/components';

import ContactInfoForm from 'routes/AccountDetails/components/ContactInfoForm';
import OrganizationsList from 'routes/AccountDetails/components/OrganizationsList';
import UpdatePasswordForm from 'routes/AccountDetails/components/UpdatePasswordForm';

const ContactInfoFormWrapper = styled.div`
  width: 50%;
  padding-top: 60px;

  &:first-of-type {
    padding-right: 40px;
  }
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 20px;
`;

const ACCOUNT_SETTINGS_SUBTITLE = 'Update your account information and manage your organizations.';
const ACCOUNT_SETTINGS_TITLE = 'Account Settings';
const CONTACT_INFO_TITLE = 'Contact Info';
const GO_BACK_BUTTON_TEXT = 'Go Back';
const UPDATE_PASSWORD_TITLE = 'Update Password';
const YOUR_ORGANIZATIONS_TITLE = 'Your Organizations';

const AccountDetails = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isUserDataFetching,
    user: {
      email,
      firstName,
      lastName,
      uuid,
    },
    userOrganizations,
  } = useSelector(state => ({
    isUserDataFetching: authSelectors.isFetching(state),
    user: authSelectors.getUser(state),
    userOrganizations: authSelectors.getUserOrganizations(state),
  }));

  useEffect(() => {
    if (userOrganizations.length === 0 && uuid) {
      dispatch(fetchUserOrganizations(uuid));
    }
  }, [uuid]);

  const onPasswordSubmit = async ({
    newPassword,
    oldPassword,
  }) => {
    const params = {
      newPassword,
      oldPassword,
    };

    try {
      await dispatch(updatePassword(params));

      return 'success';
    } catch (error) {
      return error;
    }
  };

  const onSubmit = async values => {
    try {
      await dispatch(patchUser(values));

      return 'success';
    } catch (error) {
      return error;
    }
  };

  const initialValues = {
    email,
    firstName,
    lastName,
  };

  return (
    <Container marginTop="40">
      <Grid
        container
        justifyContent="space-between"
      >
        <Typography variant="h4">{ACCOUNT_SETTINGS_TITLE}</Typography>
        <Button
          color="primary"
          onClick={() => history.push(location?.state?.goBackLink || '/organizations')}
          startIcon={<ArrowBackIcon />}
        >
          {GO_BACK_BUTTON_TEXT}
        </Button>
      </Grid>
      <Typography variant="subtitle1">{ACCOUNT_SETTINGS_SUBTITLE}</Typography>
      <Grid container>
        <ContactInfoFormWrapper>
          <Typography variant="h6">{CONTACT_INFO_TITLE}</Typography>
          <StyledDivider />
          <ContactInfoForm
            initialValues={initialValues}
            onSubmit={onSubmit}
          />
        </ContactInfoFormWrapper>
        <ContactInfoFormWrapper>
          <Typography variant="h6">{UPDATE_PASSWORD_TITLE}</Typography>
          <StyledDivider />
          <UpdatePasswordForm onSubmit={onPasswordSubmit} />
        </ContactInfoFormWrapper>
      </Grid>
      <Grid item>
        <Typography variant="h6">{YOUR_ORGANIZATIONS_TITLE}</Typography>
        <StyledDivider />
        <OrganizationsList
          isUserDataFetching={isUserDataFetching}
          organizations={userOrganizations}
        />
      </Grid>
    </Container>
  );
};

AccountDetails.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      goBackLink: PropTypes.string,
    }),
  }).isRequired,
};

export default AccountDetails;
