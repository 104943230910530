import axios from 'axios';
import {
  toCamelCase,
  toSnakeCase,
} from 'case-converter';

class Request {
  constructor() {
    this.initConfig();
  }

  initConfig() {
    const baseURL = process.env.REACT_APP_BACKEND_URL;

    axios.defaults.baseURL = baseURL;
    axios.defaults.headers.common['X-CSRFToken'] = localStorage.getItem('x-csrftoken');

    axios.defaults.withCredentials = true;
    axios.interceptors.request.use(async config => {
      const regExp = /(?=.*?(imports))(?=.*?(start))/;
      const match = config.url.match(regExp);

      if (match) {
        return {
          ...config,
          data: config.data,
          params: config.params,
        };
      }

      const isExternalRequest = !config.baseURL.includes(process.env.REACT_APP_BACKEND_URL);

      if (isExternalRequest) {
        return {
          ...config,
          headers: {
            ...config.headers,
            common: {},
          },
        };
      }

      const isPublicEndpoint = config.url.includes('/public/act/');

      if (isPublicEndpoint) {
        return {
          ...config,
          data: config.data ? toSnakeCase(config.data) : config.data,
          headers: {
            ...config.headers,
            common: {},
          },
          params: config.params ? toSnakeCase(config.params) : config.params,
        };
      }

      return {
        ...config,
        data: config.data ? toSnakeCase(config.data) : config.data,
        params: config.params ? toSnakeCase(config.params) : config.params,
      };
    });

    axios.interceptors.response.use(
      response => ({
        ...response,
        data: toCamelCase(response.data),
      }),
      error => Promise.reject(this.parseError(error))
    );
  }

  parseError(error) {
    const { response = {} } = error;

    return {
      ...error,
      response: {
        ...response,
        data: {
          ...(typeof response.data === 'string' ?
            { message: response.data } :
            toCamelCase(response.data)),
          _error: response.errors || [],
        },
      },
    };
  }

  get(...args) {
    return axios.get(...args);
  }

  post(...args) {
    return axios.post(...args);
  }

  options(...args) {
    return axios.options(...args);
  }

  patch(...args) {
    return axios.patch(...args);
  }

  put(...args) {
    return axios.put(...args);
  }

  delete(...args) {
    return axios.delete(...args);
  }
}

export default new Request();
