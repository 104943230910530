import {
  useCallback,
  useState,
 } from 'react';
 import {
  useParams,
} from 'react-router-dom';

import {
  ActionCenterSelectDialog,
  CreateMessageButton,
} from 'common/components';

import TopCard from 'common/containers/TopCard';

const MessagesTopCard = () => {
  const { organizationUuid } = useParams();

  const [
    newAlertType,
    setNewAlertType,
  ] = useState('email');
  const [
    isAlertDialogOpen,
    setIsAlertDialogOpen,
  ] = useState(false);

  const handleOpenAlerts = (alertType = 'email') => {
    setNewAlertType(alertType);
    setIsAlertDialogOpen(true);
  };

  const topCardData = useCallback(
    () => ({
        restActions: (
          <CreateMessageButton
            alertDialogHandler={handleOpenAlerts}
          />
        ),
        title: 'All messages',
      }),
    []
  );

  return (
    <>
      <TopCard {...topCardData()} />
      <ActionCenterSelectDialog
        organizationUuid={organizationUuid}
        newAlertType={newAlertType}
        isAlertDialogOpen={isAlertDialogOpen}
        setIsAlertDialogOpen={setIsAlertDialogOpen}
      />
    </>
  );
};

export default MessagesTopCard;
