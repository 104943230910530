import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useConfirm } from 'material-ui-confirm';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

import { deleteOfficial } from 'store/actions/contactDetails';

const StyledTableCell = styled(TableCell)`
  width: 50%;
  padding-left: 0;
`;

const OfficialsTable = ({
  contactId,
  listId,
  officials,
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const handleOfficialDelete = officialId => {
    confirm({
      confirmationText: 'Delete',
      description: 'This action will delete selected official',
      title: 'Delete official?',
    })
      .then(() => dispatch(deleteOfficial(listId, contactId, officialId)));
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <TableCell>Description</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {officials.map(({
            description,
            official,
            uuid,
          }) => (
            <TableRow key={uuid}>
              <StyledTableCell>
                {official.firstName}
                {' '}
                {official.middleName}
                {' '}
                {official.lastName}
              </StyledTableCell>
              <TableCell>{description}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleOfficialDelete(uuid)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OfficialsTable.defaultProps = {
  officials: [],
};

OfficialsTable.propTypes = {
  contactId: PropTypes.string.isRequired,
  listId: PropTypes.string.isRequired,
  officials: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
};

export default OfficialsTable;
