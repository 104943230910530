import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import AccountDetails from 'routes/AccountDetails/containers';
import ActionAlertDetails from 'routes/ActionAlertDetails/containers';
import ActionCenterDetails from 'routes/ActionCenterDetails/containers';
import BroadcastDetails from 'routes/BroadcastDetails/containers';
import CampaignDetails from 'routes/CampaignDetails/containers';
import Campaigns from 'routes/Campaigns/containers';
import ContactDetails from 'routes/ContactDetails/containers';
import CreateActionCenter from 'routes/CreateActionCenter/containers';
import CreateContact from 'routes/CreateContact/containers';
import CreateEmailBroadcast from 'routes/CreateEmailBroadcast/containers';
import CreateEmailActionAlert from 'routes/CreateEmailActionAlert/containers';
import CreateTargetGroup from 'routes/CreateTargetGroup/containers';
import CreateTemplate from 'routes/CreateTemplate/containers';
import CreateTextActionAlert from 'routes/CreateTextActionAlert/containers';
import CreateTextBroadcast from 'routes/CreateTextBroadcast/containers';
import CreateFilteredList from 'routes/CreateFilteredList/containers';
import CreateMeeting from 'routes/CreateMeeting/containers';
import CreateOrganization from 'routes/CreateOrganization/containers';
import CreateSignup from 'routes/CreateSignup/containers';
import CustomFields from 'routes/CustomFields/containers';
import ConfirmPasswordReset from 'routes/ConfirmPasswordReset/containers';
import Dashboard from 'routes/Dashboard/containers';
import EditContact from 'routes/EditContact/containers';
import JoinAdmin from 'routes/JoinAdmin/containers';
import ListDetails from 'routes/ListDetails/containers';
import Lists from 'routes/Lists/containers';
import Login from 'routes/Login/containers';
import MapList from 'routes/MapList/containers';
import Meetings from 'routes/Meetings/containers';
import Messages from 'routes/Messages/containers';
import Organizations from 'routes/Organizations/containers';
import TargetGroupsList from 'routes/TargetGroupsList/containers';
import TargetGroupDetails from 'routes/TargetsGroupDetails/containers';
import Templates from 'routes/Templates/containers';
import TemplateDetails from 'routes/TemplateDetails/containers';
import TextActionAlertDetails from 'routes/TextActionAlertDetails/containers';
import TextBroadcastDetails from 'routes/TextBroadcastDetails/containers';
import PasswordReset from 'routes/PasswordReset/containers';
import Register from 'routes/Register/containers';
import Settings from 'routes/Settings/containers';
import Signups from 'routes/Signups/containers';
import SignupDetails from 'routes/SignupDetails/containers';

import {
  nonAuthenticatedRoutes,
  nonOrganizationRoutes,
} from 'utils/constants';

const Routes = () => (
  <Switch>
    <Route
      component={AccountDetails}
      path={nonOrganizationRoutes.accountRoute}
    />
    <Route
      component={ActionAlertDetails}
      path="/:organizationUuid/campaigns/:campaignUuid/action-centers/:actionCenterUuid/action-alerts/:actionAlertUuid"
    />
    <Route
      component={TextActionAlertDetails}
      path="/:organizationUuid/campaigns/:campaignUuid/action-centers/:actionCenterUuid/text-action-alerts/:actionAlertUuid"
    />
    <Route
      component={ActionCenterDetails}
      path="/:organizationUuid/campaigns/:campaignUuid/action-centers/:actionCenterUuid"
    />
    <Route
      component={CampaignDetails}
      path="/:organizationUuid/campaigns/:campaignUuid"
    />
    <Route
      component={Campaigns}
      path="/:organizationUuid/campaigns"
    />
    <Route
      component={CreateMeeting}
      path={[
        '/:organizationUuid/meetings/new',
        '/:organizationUuid/meetings/:meetingUuid/edit',
      ]}
    />
    <Route
      component={Meetings}
      path="/:organizationUuid/meetings"
    />
    <Route
      component={ContactDetails}
      path="/:organizationUuid/contacts/lists/:listUuid/contact/:contactUuid"
      exact
    />
    <Route
      component={EditContact}
      path="/:organizationUuid/contacts/lists/:listUuid/contact/:contactUuid/edit"
    />
    <Route
      component={JoinAdmin}
      path={nonAuthenticatedRoutes.joinRoute}
    />
    <Route
      component={MapList}
      path="/:organizationUuid/contacts/lists/:listUuid/map-list"
    />
    <Route
      component={CreateContact}
      path="/:organizationUuid/contacts/lists/:listUuid/create-contact"
    />
    <Route
      component={CreateFilteredList}
      path="/:organizationUuid/contacts/lists/create-filtered-list/:filteredListUuid?"
    />
    <Route
      component={ListDetails}
      path="/:organizationUuid/contacts/lists/:listUuid"
    />
    <Route
      component={Lists}
      path="/:organizationUuid/contacts/lists"
      exact
    />
    <Route
      component={Organizations}
      path={nonOrganizationRoutes.organizationsRoute}
    />
    <Route
      component={CreateOrganization}
      path={nonOrganizationRoutes.createOrganizationRoute}
    />
    <Route
      component={CustomFields}
      path="/:organizationUuid/custom-fields"
    />
    <Route
      component={ConfirmPasswordReset}
      path={nonAuthenticatedRoutes.confirmPasswordResetRoute}
    />
    <Route
      component={Dashboard}
      path="/:organizationUuid/dashboard"
    />
    <Route
      component={BroadcastDetails}
      path="/:organizationUuid/broadcasts/:broadcastUuid"
    />
    <Route
      component={TextBroadcastDetails}
      path="/:organizationUuid/text-broadcasts/:broadcastUuid"
    />
    <Route
      component={Messages}
      path="/:organizationUuid/broadcasts"
    />
    <Route
      component={CreateEmailBroadcast}
      path="/:organizationUuid/:editedBroadcastUuid?/create-email-broadcast/:duplicatedUuid?"
    />
    <Route
      component={CreateTextBroadcast}
      path="/:organizationUuid/:editedBroadcastUuid?/create-text-broadcast/:duplicatedUuid?"
    />
    <Route
      component={CreateActionCenter}
      path="/:organizationUuid/:actionCenterUuid?/create-action-center/:campaignUuid?"
    />
    <Route
      component={TargetGroupDetails}
      path="/:organizationUuid/target-groups/:targetGroupUuid"
    />
    <Route
      component={CreateTargetGroup}
      path="/:organizationUuid/create-target-group/:targetGroupUuid?"
    />
    <Route
      component={TargetGroupsList}
      path="/:organizationUuid/target-groups"
    />
    <Route
      component={Settings}
      path="/:organizationUuid/settings"
    />
    <Route
      component={CreateEmailActionAlert}
      path={[
          '/:organizationUuid/:campaignUuid?/:actionCenterUuid?/create-email-action-alert/edit/:actionAlertUuid?',
          '/:organizationUuid/:campaignUuid?/:actionCenterUuid?/create-email-action-alert/duplicate/:actionAlertUuid?',
          '/:organizationUuid/:campaignUuid?/:actionCenterUuid?/create-email-action-alert/circle-back/:actionAlertUuid?',
          '/:organizationUuid/:campaignUuid?/:actionCenterUuid?/create-email-action-alert',
        ]}
    />
    <Route
      component={CreateTextActionAlert}
      path={[
          '/:organizationUuid/:campaignUuid?/:actionCenterUuid?/create-text-action-alert/edit/:actionAlertUuid?',
          '/:organizationUuid/:campaignUuid?/:actionCenterUuid?/create-text-action-alert/duplicate/:actionAlertUuid?',
          '/:organizationUuid/:campaignUuid?/:actionCenterUuid?/create-text-action-alert',
        ]}
    />
    <Route
      component={Login}
      path="/login/:organizationUuid?/:inviteUuid?"
    />
    <Route
      component={Register}
      path="/register/:organizationUuid?/:inviteUuid?"
    />
    <Route
      component={PasswordReset}
      path={nonAuthenticatedRoutes.passwordResetRoute}
    />
    <Route
      component={TemplateDetails}
      path="/:organizationUuid/:templateUuid/templates"
    />
    <Route
      component={Templates}
      path="/:organizationUuid/templates/:type?"
    />
    <Route
      component={CreateTemplate}
      path="/:organizationUuid/create-template/:type/:templateUuid?"
    />
    <Route
      component={SignupDetails}
      path="/:organizationUuid/signups/:signupUuid"
    />
    <Route
      component={Signups}
      path="/:organizationUuid/signups"
    />
    <Route
      component={CreateSignup}
      path="/:organizationUuid/create-signup/:signupUuid?"
    />
    <Redirect
      from="/"
      to={nonOrganizationRoutes.organizationsRoute}
    />
  </Switch>
);

export default Routes;
