import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Link as RouterLink,
  useParams,
} from 'react-router-dom';

import {
  Card,
  Chip,
  Grid,
  Link,
  Typography,
} from '@mui/material';

import { DetailsButton } from 'common/components';
import { colors } from 'utils/constants';
import { nameOfPublishStatus } from 'utils/helpers';
import { Bold } from 'globalStyles';

const ActionsCountWrapper = styled.div`
  display: flex;
  padding: 15px 0 0;

  p {
    padding-right: 15px;
  }
`;

const CountTypography = styled(Typography)`
  color: ${colors.osloGray};
  font-size: 28px;
`;

const CreationInfoTypography = styled(Typography)`
  padding: 10px 10px 0 0;
  font-style: italic;
  font-size: 12px;
`;

const CreationInfoWrapper = styled.div`
  display: flex;
  color: ${colors.osloGray};
`;

const StatisticsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const StyledCard = styled(Card)`
  overflow-x: auto;
  padding: 32px 26px;

  &:not(:last-of-type) {
    border-bottom: 0;
  }
`;

const StyledChip = styled(Chip)`
  margin-right: 15px;
  background-color: ${props => (props.islive ? colors.secondary : colors.warning)};
  color: ${colors.white};
`;

const StyledCountCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 80px;
`;

const StyledGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

const StyledName = styled.div`
  display: flex;
  align-items: center;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled.a`
  display: block;
  margin-top: 10px;
`;

const StyledButton = styled(DetailsButton)`
  width: 132px;
`;

const { REACT_APP_LANDING_PAGE_URL } = process.env;

const ActionCenterItem = ({
  actionCenter: {
    advocateCount,
    createdAt,
    createdBy,
    messageCount,
    mode,
    name,
    publishStatus,
    targetCount,
    totalActionsCount,
    phoneCallCount,
    uuid,
  },
}) => {
  const {
    campaignUuid,
    organizationUuid,
  } = useParams();

  const actionName = mode === 'act' ? 'Total action' : 'Signature';

  return (
    <StyledCard
      variant="outlined"
      square
    >
      <StyledGrid
        container
        item
        wrap="nowrap"
      >
        <StyledName>
          <StyledChip
            islive={publishStatus === 2 ? 1 : 0}
            label={nameOfPublishStatus(publishStatus)}
            size="small"
          />
          <Link
            component={RouterLink}
            to={`/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${uuid}?tab=0`}
          >
            <Typography variant="h5">{name}</Typography>
          </Link>
        </StyledName>
        <DetailsWrapper>
          <StyledButton to={`/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${uuid}?tab=0`} />
          {publishStatus === 2 && (
            <StyledLink
              href={`${REACT_APP_LANDING_PAGE_URL}/${uuid}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Go To Landing Page
            </StyledLink>
          )}
        </DetailsWrapper>
      </StyledGrid>
      <CreationInfoWrapper>
        <CreationInfoTypography variant="subtitle2">
          Active since
          {' '}
          {new Date(createdAt).toLocaleDateString('us-US')}
        </CreationInfoTypography>
        <CreationInfoTypography variant="subtitle2">
          Created by
          {' '}
          {createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : 'N/A'}
        </CreationInfoTypography>
      </CreationInfoWrapper>
      <ActionsCountWrapper>
        <Typography>
          <Bold>{advocateCount || 0}</Bold>
          {' '}
          {advocateCount === 1 ? 'Advocate' : 'Advocates'}
        </Typography>
        <Typography>
          <Bold>{targetCount}</Bold>
          {' '}
          {targetCount === 1 ? 'Target ' : 'Targets '}
        </Typography>
      </ActionsCountWrapper>
      <StatisticsWrapper>
        <StyledCountCard variant="outlined">
          <CountTypography>{totalActionsCount}</CountTypography>
          <Typography>{totalActionsCount === 1 ? actionName : `${actionName}s`}</Typography>
        </StyledCountCard>
        {mode === 'act' && (
        <StyledCountCard variant="outlined">
          <CountTypography>{phoneCallCount || 0}</CountTypography>
          <Typography>{phoneCallCount === 1 ? 'Phone call' : 'Phone calls'}</Typography>
        </StyledCountCard>
        )}
        {mode === 'act' && (
        <StyledCountCard variant="outlined">
          <CountTypography>{messageCount || 0}</CountTypography>
          <Typography>{messageCount === 1 ? 'Email' : 'Emails'}</Typography>
        </StyledCountCard>
        )}
      </StatisticsWrapper>
    </StyledCard>
  );
};

ActionCenterItem.propTypes = {
  actionCenter: PropTypes.shape({
    advocateCount: PropTypes.number,
    createdAt: PropTypes.string,
    createdBy: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    messageCount: PropTypes.number,
    mode: PropTypes.string,
    name: PropTypes.string,
    phoneCallCount: PropTypes.number,
    publishStatus: PropTypes.number,
    targetCount: PropTypes.number,
    targets: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        middleName: PropTypes.string,
      })
    ),
    totalActionsCount: PropTypes.number,
    uuid: PropTypes.string,
  }).isRequired,
};

export default ActionCenterItem;
