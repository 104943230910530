import {
  useEffect,
  useState,
} from 'react';
import {
  Field,
  Form,
} from 'react-final-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import { patchOrganization } from 'store/actions/organizations';
import { setSnackbarSuccess } from 'store/actions/global';

import {
  ColorPicker,
  ImageUploader,
} from 'common/components';

import {
  CircularProgressWrapper,
  FormWrapper,
  SettingsWrapper,
} from 'globalStyles';
import { colors } from 'utils/constants';
import { requiredLinkedin } from 'utils/validators';

const ColorsTypography = styled(Typography)`
  min-height: 90px;
  padding-bottom: 20px;
`;

const PickerTitle = styled(Typography)`
  margin-right: 15px;
  color: ${colors.tableHeaderGray};
`;

const PickerWrapper = styled(Grid)`
  width: 33%;
`;

const StyledButton = styled(Button)`
  margin-bottom: 40px;
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 20px;
`;

const StyledField = styled(Field)`
  width: 50%;
  padding-top: 20px;
`;

const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;

const UrlsTypography = styled(Typography)`
  min-height: 72px;
  padding-bottom: 20px;
`;

const COLORS_TITLE = 'Colors';
const COLORS_SUBTITLE = 'Choose colors that represent your organization. Your primary color is being used right now on your Contact Form and as we develop more widgets, we will find more uses for your branding!';
const FACEBOOK_LABEL = 'Facebook';
const LOGO_TITLE = 'Logo';
const LOGO_SUBTITLE = 'Your logo will be used in all outgoing communications. Muster uses square thumbnails so be sure to shoot for something close to square. The ideal size for your logo is 120px x 120px.';
const SOCIAL_MEDIA_SUBTITLE = 'Your Social Media URLs are used in all outgoing communications (Call to Action and Email Broadcasts) to link to your organization\'s social media.';
const SOCIAL_MEDIA_TITLE = 'Social Media URLs';
const SOCIAL_MEDIA_UPDATE_SUCCESS_MESSAGE = 'Social Media URLs were changed succesfully';
const TWITTER_LABEL = 'Twitter';
const LINKEDIN_LABEL = 'Linkedin';
const UPDATE_BUTTON_TEXT = 'Update';
const COLORS_UPDATE_SUCCESS_MESSAGE = 'Organization colors has been successfully changed';
const LOGO_UPDATE_SUCCESS_MESSAGE = 'Organization logo has been successfully changed';

const BrandingTab = ({
  canEditOrganization,
  config,
  initialValues,
  logo,
  onSubmit,
  organizationUuid,
}) => {
  const dispatch = useDispatch();
  const identity = value => (value);

  const [
    pickerColorBackgroundColor,
    setPickerColorBackgroundColor,
  ] = useState(colors.dodgerBlue);
  const [
    pickerColorButtonColor,
    setPickerColorButtonColor,
  ] = useState(colors.gold);
  const [
    pickerColorTextColor,
    setPickerColorTextColor,
  ] = useState(colors.black);
  const [
    logoImage,
    setLogoImage,
  ] = useState([]);

  useEffect(() => {
    if (!config) {
      return;
    }

    if (config.backgroundColor) {
      setPickerColorBackgroundColor(config?.backgroundColor);
    }

    if (config.buttonColor) {
      setPickerColorButtonColor(config?.buttonColor);
    }

    if (config.textColor) {
      setPickerColorTextColor(config?.textColor);
    }
  }, [config]);

  const handlePickerPrimaryChange = ({ hex }) => setPickerColorBackgroundColor(hex);
  const handlePickerSecondaryChange = ({ hex }) => setPickerColorButtonColor(hex);
  const handlePickerThirdChange = ({ hex }) => setPickerColorTextColor(hex);

  const handleUpdateColors = () => {
    const params = {
      config: {
        backgroundColor: pickerColorBackgroundColor,
        buttonColor: pickerColorButtonColor,
        textColor: pickerColorTextColor,
      },
    };

    dispatch(patchOrganization(organizationUuid, params))
      .then(() => dispatch(setSnackbarSuccess({ message: COLORS_UPDATE_SUCCESS_MESSAGE })));
  };

  const handleSaveLogoImage = () => {
    const logoData = new FormData();

    logoData.append('logo', logoImage[0]?.file);

    dispatch(patchOrganization(organizationUuid, logoData))
      .then(() => dispatch(setSnackbarSuccess({ message: LOGO_UPDATE_SUCCESS_MESSAGE })));
  };

  if (Object.values(initialValues).includes(undefined)) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  return (
    <SettingsWrapper>
      <Grid container>
        <FormWrapper>
          <Typography variant="h6">{SOCIAL_MEDIA_TITLE}</Typography>
          <StyledDivider />
          <UrlsTypography variant="subtitle2">{SOCIAL_MEDIA_SUBTITLE}</UrlsTypography>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({
              form,
              handleSubmit,
              pristine,
              submitting,
            }) => (
              <form onSubmit={async event => {
                const response = await handleSubmit(event);

                if (response === 'success') {
                  dispatch(setSnackbarSuccess({ message: SOCIAL_MEDIA_UPDATE_SUCCESS_MESSAGE }));
                  form.restart();
                }
              }}
              >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    alignContent="space-between"
                    container
                    item
                    xs={12}
                    md={6}
                  >
                    <StyledField
                      name="facebookUsername"
                      parse={identity}
                    >
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          error={Boolean(meta.touched && (meta.error || meta.submitError))}
                          fullWidth
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          label={FACEBOOK_LABEL}
                          type="text"
                          variant="standard"
                        />
                      )}
                    </StyledField>
                  </Grid>
                  <Grid
                    alignContent="space-between"
                    container
                    item
                    xs={12}
                    md={6}
                  >
                    <StyledField
                      name="twitterUsername"
                      parse={identity}
                    >
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          error={Boolean(meta.touched && (meta.error || meta.submitError))}
                          fullWidth
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          label={TWITTER_LABEL}
                          type="text"
                          variant="standard"
                        />
                      )}
                    </StyledField>
                  </Grid>
                  <Grid
                    alignContent="space-between"
                    container
                    item
                    xs={12}
                    md={6}
                  >
                    <StyledField
                      name="linkedinPage"
                      parse={identity}
                      validate={requiredLinkedin}
                    >
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          error={Boolean(meta.touched && (meta.error || meta.submitError))}
                          fullWidth
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          label={LINKEDIN_LABEL}
                          type="text"
                          variant="standard"
                        />
                      )}
                    </StyledField>
                  </Grid>
                </Grid>
                <StyledGrid
                  container
                  item
                  justifyContent="flex-end"
                  xs={12}
                >
                  <StyledButton
                    color="secondary"
                    disabled={submitting || pristine}
                    disableElevation
                    type="submit"
                    variant="contained"
                  >
                    {UPDATE_BUTTON_TEXT}
                  </StyledButton>
                </StyledGrid>
              </form>
            )}
          />
        </FormWrapper>
        <FormWrapper>
          <Typography variant="h6">{COLORS_TITLE}</Typography>
          <StyledDivider />
          <ColorsTypography variant="subtitle2">{COLORS_SUBTITLE}</ColorsTypography>
          <Grid container>
            <PickerWrapper
              alignItems="center"
              container
            >
              <PickerTitle variant="subtitle2">Background Color</PickerTitle>
              <ColorPicker
                handlePickerChange={handlePickerPrimaryChange}
                pickerColor={pickerColorBackgroundColor}
              />
            </PickerWrapper>
            <PickerWrapper
              alignItems="center"
              container
            >
              <PickerTitle variant="subtitle2">Button Color</PickerTitle>
              <ColorPicker
                handlePickerChange={handlePickerSecondaryChange}
                pickerColor={pickerColorButtonColor}
              />
            </PickerWrapper>
            <PickerWrapper
              alignItems="center"
              container
            >
              <PickerTitle variant="subtitle2">Text Color</PickerTitle>
              <ColorPicker
                handlePickerChange={handlePickerThirdChange}
                pickerColor={pickerColorTextColor}
              />
            </PickerWrapper>
            <StyledGrid
              container
              item
              justifyContent="flex-end"
              xs={12}
            >
              <Button
                color="secondary"
                disableElevation
                type="submit"
                variant="contained"
                onClick={handleUpdateColors}
              >
                {UPDATE_BUTTON_TEXT}
              </Button>
            </StyledGrid>
          </Grid>
        </FormWrapper>
        <FormWrapper>
          <Typography variant="h6">{LOGO_TITLE}</Typography>
          <StyledDivider />
          <ColorsTypography variant="subtitle2">{LOGO_SUBTITLE}</ColorsTypography>
          <ImageUploader
            defaultImageScr={logo}
            disabled={!canEditOrganization && !!logo}
            images={logoImage}
            onSave={handleSaveLogoImage}
            setImages={setLogoImage}
          />
        </FormWrapper>
      </Grid>
    </SettingsWrapper>
  );
};

BrandingTab.defaultProps = {
  config: {},
  logo: null,
};

BrandingTab.propTypes = {
  canEditOrganization: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    backgroundColor: PropTypes.string,
    buttonColor: PropTypes.string,
    textColor: PropTypes.string,
  }),
  initialValues: PropTypes.shape({
    facebookUsername: PropTypes.string,
    twitterUsername: PropTypes.string,
  }).isRequired,
  logo: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  organizationUuid: PropTypes.string.isRequired,
};

export default BrandingTab;
