import {
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MaterialTable, { MTableCell } from '@material-table/core';
import {
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';

import { colors } from 'themes';

import { TableIcon } from 'common/components';
import { isInPreviousDays } from 'utils/helpers';
import { actionTakenMessageStatus } from 'utils/constants';
import ActionTakenModal from '../ActionTakenModal';
import ActionDetailButton from '../ActionDetailButton';

const {
  approvedMessage,
  flaggedMessage,
  pendingMessage,
  quarantinedMessage,
  rejectedMessage,
} = actionTakenMessageStatus;

const ActionsTakenFiltersWrapper = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 90px;
  border-bottom: 0;
  background-color: ${colors.grayBackground};
`;

const ActionsTakenSelect = styled(TextField)`
  width: 240px;
  margin-left: 24px;
`;

const ActionsTakenTable = ({
  actionCenterActions,
  isActionCenterActionsFetching,
  mode,
  showMessageApproval,
}) => {
  const [
    activeActionsTaken,
    setActiveActionsTaken,
  ] = useState([]);
  const [
    contactChannel,
    setContactChannel,
  ] = useState('All');
  const [
    datePeriod,
    setDatePeriod,
  ] = useState('All');
  const [
    statusFilter,
    setStatusFilter,
  ] = useState('All');
  const [
    action,
    setAction,
  ] = useState(null);

  const baseActionsTakenColumns = [
    {
      field: 'firstName',
      title: 'First name',
    },
    {
      field: 'lastName',
      title: 'Last name',
    },
    {
      field: 'email',
      title: 'Email',
    },
    {
      field: 'createdAt',
      title: 'Date',
      type: 'date',
    },
    {
      cellStyle: { fontSize: '26px' },
      field: 'type',
      render: rowData => (rowData.type === 'email' ? '📧' : '☎️'),
      title: 'Channel',
    },
  ];

  const conditionalColumns = [
    {
      field: 'isCustomized',
      render: rowData => (
        <TableIcon
          boolean={!!rowData.isCustomized}
          negativeIcon={null}
        />
      ),
      title: 'Custom?',
    },
    {
      field: 'messageStatus',
      render: rowData => (
        <ActionDetailButton
          rowData={rowData}
          setAction={setAction}
          showMessageApproval={showMessageApproval}
        />
      ),
      title: showMessageApproval ? 'Status' : '',
    },
  ];

  const actionsTakenColumns = mode !== 'petition' ?
    [
      ...baseActionsTakenColumns,
      ...conditionalColumns,
    ] : baseActionsTakenColumns;

  useEffect(() => {
    let filteredActionsTaken = actionCenterActions;

    if (contactChannel !== 'All') {
      filteredActionsTaken = filteredActionsTaken.filter(
        ({ type }) => (
          type.toLowerCase() === contactChannel.toLowerCase()
        )
      );
    }

    if (statusFilter === rejectedMessage) {
      filteredActionsTaken = filteredActionsTaken.filter(
        ({ messageStatus }) => (messageStatus === rejectedMessage ||
          messageStatus === quarantinedMessage)
      );
    } else if (statusFilter === pendingMessage) {
        filteredActionsTaken = filteredActionsTaken.filter(
          ({ messageStatus }) => (messageStatus === pendingMessage ||
            messageStatus === flaggedMessage)
        );
    } else if (statusFilter !== 'All') {
      filteredActionsTaken = filteredActionsTaken.filter(
        ({ messageStatus }) => (messageStatus === statusFilter)
      );
    }

    if (datePeriod !== 'All') {
      filteredActionsTaken = filteredActionsTaken.filter(
        ({ createdAt }) => {
          if (datePeriod.toLowerCase() === 'last week') {
            return isInPreviousDays(createdAt, 7);
          }

          if (datePeriod.toLowerCase() === 'last month') {
            return isInPreviousDays(createdAt, 30);
          }

          return createdAt;
        }
      );
    }

    setActiveActionsTaken(filteredActionsTaken);
  }, [
    actionCenterActions,
    contactChannel,
    datePeriod,
    statusFilter,
  ]);

  /*
   * Updates the status of related TargetEmails
   *
   * When a customer approves or rejects a message, the API
   * runs the same approval (or rejection) against identical
   * target emails. These are identified and returned in the
   * response payload by UUID (including the original), so we
   * can update all identical messages in one fell swoop.
   */
  const updateActions = (status, targetEmailUuids) => {
    const updateStatus = actionItem => {
      if (targetEmailUuids.some(element => element === actionItem.uuid)) {
        // eslint-disable-next-line no-param-reassign
        actionItem.messageStatus = status;
      }

      return actionItem;
    };

    setActiveActionsTaken(activeActionsTaken.map(updateStatus));
  };

  const handleContactChannelChange = event => setContactChannel(event.target.value);
  const handleDatePeriodChange = event => setDatePeriod(event.target.value);
  const handleStatusFilterChange = event => setStatusFilter(event.target.value);

  return (
    <>
      <ActionsTakenFiltersWrapper
        elevation={0}
        variant="outlined"
        square
      >
        <ActionsTakenSelect
          onChange={event => handleContactChannelChange(event)}
          label="Communication channel"
          select
          value={contactChannel}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="email">Email</MenuItem>
          <MenuItem value="phone">Phone call</MenuItem>
        </ActionsTakenSelect>
        <ActionsTakenSelect
          onChange={event => handleDatePeriodChange(event)}
          label="Date"
          select
          value={datePeriod}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Last week">Last week</MenuItem>
          <MenuItem value="Last month">Last month</MenuItem>
        </ActionsTakenSelect>
        {showMessageApproval && (
          <ActionsTakenSelect
            onChange={event => handleStatusFilterChange(event)}
            label="Message Status"
            select
            value={statusFilter}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value={pendingMessage}>Pending</MenuItem>
            <MenuItem value={approvedMessage}>Approved</MenuItem>
            <MenuItem value={rejectedMessage}>Rejected</MenuItem>
          </ActionsTakenSelect>
        )}
      </ActionsTakenFiltersWrapper>
      <MaterialTable
        columns={actionsTakenColumns}
        components={{
          Cell: props => {
            /* eslint-disable react/prop-types */
            const {
              columnDef: {
                field,
              },
              value,
            } = props;

            if (field === 'date') {
              return (
                <MTableCell
                  {...props}
                  value={new Date(value).toLocaleDateString('us-US')}
                />
              );
            }

            return (
              <MTableCell
                {...props}
              />
            );
            /* eslint-enable react/prop-types */
          },
          Container: props => (
            <Paper
              {...props}
              elevation={0}
              square
              variant="outlined"
            />
          ),
        }}
        data={activeActionsTaken}
        isLoading={isActionCenterActionsFetching}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: colors.tableHeaderGray,
            color: colors.white,
          },
          selection: false,
          showTitle: false,
        }}
      />
      <ActionTakenModal
        action={action}
        setAction={setAction}
        showMessageApproval={showMessageApproval}
        updateActions={updateActions}
      />
    </>
  );
};

ActionsTakenTable.propTypes = {
  actionCenterActions: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    messageStatus: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  isActionCenterActionsFetching: PropTypes.bool.isRequired,
  mode: PropTypes.string,
  showMessageApproval: PropTypes.bool.isRequired,
};

ActionsTakenTable.defaultProps = {
  mode: 'act',
};

export default ActionsTakenTable;
