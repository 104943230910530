import {
  Link as RouterLink,
  useHistory,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isProductRoute } from 'utils/helpers';

import { MenuItem } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';

import { organizationSelectors } from 'store/selectors/organizations';

import {
  DropdownButton,
  NavigationDropdown,
} from '../NavigationDropdowns.styles';

const BroadcastDropdown = ({ organizationUuid }) => {
  const { location } = useHistory();

  const {
    organization: {
      flags,
    },
  } = useSelector(state => ({
    organization: organizationSelectors.getOrganization(state),
  }));

  return (
    <NavigationDropdown
      name="Broadcasts"
      isactualroute={isProductRoute(location, 'broadcasts')}
      disabled={!flags?.showBroadcasts}
      color="inherit"
      startIcon={null}
      endIcon={<KeyboardArrowDownIcon />}
      variant="text"
    >
      {handleSettingsClose => (
        <>
          <DropdownButton
            component={RouterLink}
            to={`/${organizationUuid}/broadcasts`}
          >
            <MenuItem onClick={handleSettingsClose}>All Messages</MenuItem>
          </DropdownButton>
          <DropdownButton
            component={RouterLink}
            to={`/${organizationUuid}/templates/broadcast`}
          >
            <MenuItem onClick={handleSettingsClose}>Email Templates</MenuItem>
          </DropdownButton>
        </>
      )}
    </NavigationDropdown>
  );
};

BroadcastDropdown.propTypes = {
  organizationUuid: PropTypes.string.isRequired,
};

export default BroadcastDropdown;
