import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const SuccessDialog = ({ isDialogOpen }) => (
  <Dialog
    aria-labelledby="password-dialog-title"
    fullWidth
    open={isDialogOpen}
  >
    <DialogTitle id="password-dialog-title">Success</DialogTitle>
    <DialogContent>
      <DialogContentText>
        The password has been changed. You may now Sign in.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        color="secondary"
        component={Link}
        variant="outlined"
        to="/login"
      >
        Sign in
      </Button>
    </DialogActions>
  </Dialog>
);

SuccessDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
};

export default SuccessDialog;
