import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import { useConfirm } from 'material-ui-confirm';

import {
  Button,
  Card,
  CircularProgress,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Comment as CommentIcon,
  Delete as DeleteIcon,
  PinDrop as PinDropIcon,
} from '@mui/icons-material';

import { campaignsSelectors } from 'store/selectors/campaigns';
import {
  deleteCampaign,
  exportCampaign,
  exportMessagesReportForCampaign,
  fetchAdvocates,
  fetchCampaign,
  fetchCampaignTargets,
} from 'store/actions/campaigns';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';

import {
  Backdrop,
  BreadcrumbsNavigation,
  ButtonMenu,
  Container,
  DescriptionBar,
  DescriptionBarInfo,
  Popover,
  StatisticCard,
  StatisticListCard,
  TabPanel,
  Tabs,
  TargetsTable,
} from 'common/components';

import { showFirstErrorMessage } from 'utils/helpers';
import {
  CircularProgressWrapper,
  DetailsWrapper,
  StatisticsWrapper,
} from 'globalStyles';

import ActionCenterItem from '../components/ActionCenterItem';
import AdvocatesTable from '../components/AdvocatesTable';
import RenameCampaignDialog from '../components/RenameCampaignDialog';

const CampaignTableWrapper = styled.div`
  width: 100%;
  margin: 64px 0 82px;
`;

const ContactsMediaWrapper = styled.div`
  display: flex;
`;

const StatusCardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-bottom: 0;
`;

const StyledSelect = styled(Select)`
  width: 130px;
  margin: 0 30px 24px 0;
`;

const StyledSelectLabel = styled(InputLabel)`
  width: 130px;
  margin: 24px 30px 0 0;
`;

const StyledPaper = styled(Paper)`
  border-bottom: 0;
`;

const StyledTypography = styled(Typography)`
  padding: 20px 0 20px 19px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExportButton = styled(Button)`
  margin: 50px 30px;
`;

const StyledImg = styled.img`
  width: 22px;
`;

const CampaignDetails = () => {
  const dispatch = useDispatch();
  const {
    campaignUuid,
    organizationUuid,
  } = useParams();
  const history = useHistory();
  const confirm = useConfirm();
  const firstFetch = useRef({
    advocates: false,
    targets: false,
  });

  const {
    advocatesList,
    campaignDetails,
    campaignTargets,
    isAdvocatesFetching,
    isCampaignFetching,
    isCampaignTargetsFetching,
    isPending,
  } = useSelector(state => ({
    advocatesList: campaignsSelectors.getAdvocates(state),
    campaignDetails: campaignsSelectors.getCampaignDetails(state),
    campaignTargets: campaignsSelectors.getCampaignTargets(state),
    isAdvocatesFetching: campaignsSelectors.isAdvocatesFetching(state),
    isCampaignFetching: campaignsSelectors.isCampaignFetching(state),
    isCampaignTargetsFetching: campaignsSelectors.isCampaignTargetsFetching(state),
    isPending: campaignsSelectors.isPending(state),
  }));

  const [
    activeActionCenters,
    setActiveActionCenters,
  ] = useState(null);
  const [
    status,
    setStatus,
  ] = useState(0);
  const [
    tabIndex,
    setTabIndex,
  ] = useState('actionCenters');

  const {
    actionCenters = [],
    canDelete,
    createdAt,
    createdBy,
    name,
    statistics,
  } = campaignDetails;

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/campaigns`,
      name: 'Advocacy',
    },
    {
      link: '',
      name,
    },
  ];

  useEffect(() => {
    let filteredActionCenters = actionCenters;

    if (status) {
      filteredActionCenters = filteredActionCenters.filter(
        ({ publishStatus }) => (status === publishStatus)
      );
    }

    setActiveActionCenters(filteredActionCenters);
  }, [
    campaignDetails,
    status,
  ]);

  useEffect(() => {
    dispatch(fetchCampaign(campaignUuid));
  }, [
    dispatch,
    campaignUuid,
  ]);

  const handleChangeTab = newValue => {
    setTabIndex(newValue);

    if (newValue === 'targets' && !firstFetch.current.targets) {
      dispatch(fetchCampaignTargets(campaignUuid))
        .then(() => { firstFetch.current.targets = true; });
    }

    if (newValue === 'advocates' && !firstFetch.current.advocates) {
      dispatch(fetchAdvocates(campaignUuid))
        .then(() => { firstFetch.current.advocates = true; });
    }
  };

  const campaignTabs = [
    {
      label: 'Action Centers',
      value: 'actionCenters',
    },
    {
      label: 'Targets',
      value: 'targets',
    },
    {
      label: 'Advocates',
      value: 'advocates',
    },
    {
      label: 'Analytics',
      value: 'analytics',
    },
  ];

  const DescriptionInfo = (
    <DescriptionBarInfo
      createdAt={createdAt}
      createdBy={createdBy}
    />
  );

  const handleDeleteCampaign = () => {
    confirm({
      confirmationText: 'Delete',
      description: 'You won`t be able to undo that',
      title: 'Delete the Campaign?',
    })
      .then(() => dispatch(deleteCampaign(campaignUuid)))
      .then(() => dispatch(setSnackbarSuccess({ message: 'Campaign has been removed' })))
      .then(() => history.push(`/${organizationUuid}/campaigns/`))
      .catch(error => {
        if (error) {
          dispatch(setSnackbarError({
            message: showFirstErrorMessage(error),
          }));
        }
      });
  };

  const handleExport = () => {
    dispatch(exportCampaign(campaignUuid))
      .then(({ detail }) => dispatch(setSnackbarSuccess({ message: detail })))
      .catch(() => dispatch(setSnackbarError()));
  };

  const handleMessagesExport = () => {
    dispatch(exportMessagesReportForCampaign(campaignUuid))
      .then(({ detail }) => dispatch(setSnackbarSuccess({ message: detail })))
      .catch(() => dispatch(setSnackbarError()));
  };

  const settingsButton = () => (
    <ButtonMenu>
      {handleSettingsClose => (
        <>
          <Popover
            shouldDisplayPopover={!canDelete}
            text="Stop this campaign's action centers before you can delete it."
          >
            <MenuItem
              disabled={!canDelete}
              onClick={handleDeleteCampaign}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete Campaign" />
            </MenuItem>
          </Popover>
          <RenameCampaignDialog
            campaignName={name}
            handleSettingsClose={handleSettingsClose}
            isPending={isPending}
          />
        </>
      )}
    </ButtonMenu>
  );

  const renderActionCenters = () => (
    activeActionCenters?.length ? (
      activeActionCenters.map(actionCenter => (
        <ActionCenterItem
          actionCenter={actionCenter}
          key={actionCenter.uuid}
        />
      ))
    ) : (
      <Card
        variant="outlined"
        square
      >
        <StyledTypography>No Action Centers for selected status</StyledTypography>
      </Card>
    )
  );

  if (isCampaignFetching) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container>
        <Grid container>
          <DescriptionBar
            backButtonLink={`/${organizationUuid}/campaigns`}
            descriptionBarInfo={DescriptionInfo}
            hasBackButton
            icon={PinDropIcon}
            isHighBar
            name={name}
            settingsButton={settingsButton}
          >
            <Button
              disableElevation
              color="secondary"
              component={Link}
              startIcon={<AddIcon />}
              to={`/${organizationUuid}/create-action-center/${campaignUuid}`}
              variant="contained"
            >
              New Action Center
            </Button>
          </DescriptionBar>
        </Grid>
        {actionCenters?.length ? (
          <>
            <DetailsWrapper>
              <StatisticsWrapper>
                <StatisticCard
                  count={statistics?.actionCenters?.totalCount}
                  isPrimaryColor={false}
                  text="Action Centers"
                />
                <StatisticCard
                  count={statistics?.advocates?.totalCount}
                  text="Advocates"
                />
                <ContactsMediaWrapper>
                  <StatisticCard
                    count={statistics?.actions?.totalCount}
                    text="Total actions"
                  />
                  {!!statistics?.phoneCalls?.totalCount && (
                  <StatisticListCard
                    count={statistics?.phoneCalls?.totalCount}
                    hasPrimaryColor={false}
                    text="Phone calls"
                  />
                  )}
                  {!!statistics?.emails?.totalCount && (
                  <StatisticListCard
                    count={statistics?.emails?.totalCount}
                    hasPrimaryColor={false}
                    text="Emails"
                  />
                  )}
                  {!!statistics?.signatures?.totalCount && (
                  <StatisticListCard
                    count={statistics?.signatures?.totalCount}
                    hasPrimaryColor={false}
                    text="Signatures"
                  />
                  )}
                </ContactsMediaWrapper>
              </StatisticsWrapper>
            </DetailsWrapper>
            <CampaignTableWrapper>
              <StyledPaper
                square
                variant="outlined"
              >
                <Tabs
                  handleSetTab={handleChangeTab}
                  tabs={campaignTabs}
                  tabIndex={tabIndex}
                />
              </StyledPaper>
              <TabPanel
                index={campaignTabs[0].value}
                value={tabIndex}
              >
                <StatusCardWrapper
                  square
                  variant="outlined"
                >
                  <StyledSelectLabel
                    id="action-center-status-label"
                    shrink
                  >
                    Status
                  </StyledSelectLabel>
                  <StyledSelect
                    labelId="action-center-status-label"
                    onChange={event => setStatus(event.target.value)}
                    value={status}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    <MenuItem value={1}>Draft</MenuItem>
                    <MenuItem value={2}>Live</MenuItem>
                    <MenuItem value={3}>Stopped</MenuItem>
                  </StyledSelect>
                </StatusCardWrapper>
                {actionCenters?.length ?
                  renderActionCenters() : (
                    <Card
                      variant="outlined"
                      square
                    >
                      <StyledTypography>No actions alerts for this campaign</StyledTypography>
                    </Card>
                  )
                }
              </TabPanel>
              <TabPanel
                index={campaignTabs[1].value}
                value={tabIndex}
              >
                <TargetsTable
                  targets={campaignTargets}
                  isDataFetching={isCampaignTargetsFetching}
                />
              </TabPanel>
              <TabPanel
                index={campaignTabs[2].value}
                value={tabIndex}
              >
                <AdvocatesTable
                  advocatesList={advocatesList}
                  isDataFetching={isAdvocatesFetching}
                />
              </TabPanel>
              <TabPanel
                index={campaignTabs[3].value}
                value={tabIndex}
              >
                <Card
                  variant="outlined"
                  square
                >
                  <ExportButton
                    color="secondary"
                    disabled={isPending}
                    disableElevation
                    onClick={handleExport}
                    startIcon={(
                      <StyledImg
                        alt="excel icon"
                        src="/excel_icon.svg"
                      />
                    )}
                    variant="contained"
                  >
                    Comprehensive Excel Report
                  </ExportButton>
                  <ExportButton
                    color="secondary"
                    disabled={isPending}
                    disableElevation
                    onClick={handleMessagesExport}
                    startIcon={<CommentIcon />}
                    variant="contained"
                  >
                    Action Messages PDF Report
                  </ExportButton>
                </Card>
              </TabPanel>
            </CampaignTableWrapper>
          </>
          ) : (
            <ButtonWrapper>
              <Button
                disableElevation
                color="secondary"
                component={Link}
                startIcon={<AddIcon />}
                to={`/${organizationUuid}/create-action-center/${campaignUuid}`}
                variant="contained"
              >
                start first action center
              </Button>
            </ButtonWrapper>
          )}
        <Backdrop isOpen={isPending} />
      </Container>
    </>
  );
};

export default CampaignDetails;
