import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import { debounce } from 'lodash';
import {
  Field,
  Form,
} from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import {
  ArrowDropDown as ArrowDropDownIcon,
  FormatListBulleted as FormatListBulletedIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import mediaQueries from 'utils/mediaQueries';

import {
  deleteListGroup,
  fetchChildrenLists,
  fetchListGroups,
  fetchRootList,
  getContactsListFlatFileConfig,
  postListGroup,
  updateContactsList,
} from 'store/actions/contacts';
import { contactSelectors } from 'store/selectors/contacts';
import { organizationSelectors } from 'store/selectors/organizations';
import { useConfirm } from 'material-ui-confirm';

import {
  Container,
  DescriptionBar,
  Dialog,
  FlatFileButton,
  TabPanel,
} from 'common/components';
import {
  CircularProgressWrapper,
  TabsWrapper,
} from 'globalStyles';

import NewList from 'routes/Lists/components/NewList';
import Groups from 'routes/Lists/components/Groups';
import Lists from 'routes/Lists/components/Lists';
import ListCard from 'routes/Lists/components/ListCard';

const { small } = mediaQueries;

const ListsWrapper = styled.div`
  width: 100%;
`;

const MenuButton = styled(Button)`
  height: 48px;

  &:first-child {
    margin-right: 20px;
  }

  @media ${small} {
    margin-top: 20px;
  }
`;

const StyledNewList = styled(NewList)`
  margin-top: 20px;
`;

const StyledTextField = styled(TextField)`
  padding: 0 16px 7px;
`;

const StyledMenuButton = styled(MenuButton)`
  margin-left: 10px;
`;

const StyledListCard = styled(ListCard)`
  width: 100%;
  margin-top: 20px;
`;

const OptionsWrapper = styled.div`
  margin-top: 20px;
`;

const ADD_NEW_LIST_GROUP_DIALOG_TITLE = 'Enter a name and save the new group';
const ADD_TO_GROUP_DIALOG_TITLE = 'Select a group and add a list to it';

const ListsContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizationUuid } = useParams();
  const confirm = useConfirm();
  const flatfileRef = useRef(null);

  const {
    childrenLists,
    isChildrenListsFetching,
    isPending,
    organizationGroups,
    organization: {
      flags,
    },
    rootList,
  } = useSelector(state => ({
    childrenLists: contactSelectors.getChildrenLists(state)[organizationUuid] || [],
    isChildrenListsFetching: contactSelectors.isChildrenListsFetching(state),
    isPending: contactSelectors.isPendingList(state),
    organization: organizationSelectors.getOrganization(state),
    organizationGroups: contactSelectors.getListGroups(state)[organizationUuid] || {},
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  const [
    tabIndex,
    setTabIndex,
  ] = useState(0);
  const [
    anchorElement,
    setAnchorElement,
  ] = useState(null);
  const [
    showNewList,
    setShowNewList,
  ] = useState(false);
  const [
    searchPhrase,
    setSearchPhrase,
  ] = useState('');
  const [
    newListGroup,
    setNewListGroup,
  ] = useState('');
  const [
    isNewListGroupDialogOpen,
    setIsNewListGroupDialogOpen,
  ] = useState(false);
  const [
    importListUidd,
    setImportListUidd,
  ] = useState('');
  const [
    addToGroupData,
    setAddToGroupData,
  ] = useState({
    isModalOpen: false,
    listUuid: '',
    selectedGroup: '',
  });
  const [
    groupsData,
    setGroupsData,
  ] = useState({
    groups: {},
    nonGeocoded: {},
    nonGrouped: [],
  });

  const groups = organizationGroups[rootList.uuid] || [];

  useEffect(() => {
    if (Object.keys(rootList)) {
      dispatch(fetchRootList(organizationUuid)).then(data => {
        if (childrenLists.length === 0) {
          dispatch(fetchChildrenLists(data.uuid, organizationUuid));
          dispatch(fetchListGroups(data.uuid, organizationUuid));
          dispatch(getContactsListFlatFileConfig(data.uuid));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (flags && !flags.showLists) {
      history.push(`/${organizationUuid}/dashboard`);
    }
  }, [
    history,
    flags,
    organizationUuid,
  ]);

  const groupUuidLists = childrenLists.reduce((acc, list) => {
    if (list.listGroups) {
      acc.push(...list.listGroups);
    }

    return acc;
  }, []);

  useEffect(() => {
    if (!isPending) {
      const groupsInitialArray = groups?.reduce((acc, group) => {
        acc.groups[group.uuid] = {
          fields: [],
          name: group.name,
        };

        return acc;
      }, {
        groups: {},
        nonGeocoded: {},
        nonGrouped: [],
      });
      const groupsOrderedData = childrenLists.reduce((acc, list) => {
        if (list.type === 'IsGeocodedList') {
          acc.nonGeocoded = list;
        } else if (list.listGroups?.length) {
          const groupUuid = list.listGroups[0];

          acc.groups[groupUuid] = {
            ...acc.groups[groupUuid],
            fields: [
              ...(acc.groups[groupUuid]?.fields || []),
              list,
            ],
          };
        } else {
          acc.nonGrouped = [
            ...acc.nonGrouped,
            list,
          ];
        }

        return acc;
      }, groupsInitialArray);

      setGroupsData(groupsOrderedData);
    }
  }, [
    groups.length,
    childrenLists.length,
    isPending,
    groupUuidLists.length,
  ]);

  const handleChange = (_, newValue) => setTabIndex(newValue);
  const handleClick = event => setAnchorElement(event.currentTarget);
  const handleClose = () => setAnchorElement(null);

  const handleOpenNewList = () => {
    setShowNewList(true);
    handleClose();
  };

  const handleCancelNewList = refetch => {
    setShowNewList(false);

    if (refetch) {
      dispatch(fetchChildrenLists(rootList.uuid, organizationUuid));
    }
  };

  const handleAddGroupDialogClose = () => {
    setNewListGroup('');
    setIsNewListGroupDialogOpen(false);
  };

  const handleAddGroup = async () => {
    await dispatch(postListGroup(rootList.uuid, { name: newListGroup }));
    await dispatch(fetchListGroups(rootList.uuid, organizationUuid));
    handleAddGroupDialogClose();
  };

  const handleOpenAddToGroupModal = (listUuid, listGroups) => {
    setAddToGroupData(prevValue => ({
      ...prevValue,
      isModalOpen: true,
      listUuid,
      selectedGroup: listGroups || '',
    }));
  };

  const handleAddToGroupDialogClose = async () => {
    setAddToGroupData(prevValue => ({
      ...prevValue,
      isModalOpen: false,
      selectedGroup: '',
    }));
  };

  const handleAddToGroup = async () => {
    await dispatch(updateContactsList(
      addToGroupData.listUuid,
      organizationUuid,
      { listGroups: [addToGroupData.selectedGroup] }
    ));
    handleAddToGroupDialogClose();
  };

  const handleDeleteGroup = async (listGroupUuid, groupData) => confirm({
    confirmationText: 'Delete',
    description: 'This will not delete any of the contact lists.',
    title: 'Delete Group?',
  })
    .then(() => {
      Promise.all(groupData.fields.map(list => dispatch(updateContactsList(
        list.uuid,
        organizationUuid,
        { listGroups: [] }
      ))));
    })
    .then(() => {
      dispatch(deleteListGroup(
        rootList.uuid,
        listGroupUuid,
        organizationUuid
      ));
    });

  const handleFilterList = (lists, listType) => lists.filter(
    list => (list.type === listType || !listType) &&
    list?.name?.toLowerCase().includes((searchPhrase || '').toLowerCase())
  );

  const handleShowImportListView = listUuid => {
    setImportListUidd(listUuid);
  };

  useEffect(() => {
    if (importListUidd && flatfileRef?.current) {
      flatfileRef.current.click();
    }
  }, [importListUidd]);

  const onSubmit = values => {
    setSearchPhrase(values?.searchPhrase || '');
  };

  const debouncedSubmit = debounce(submit => submit(), 500);

  return (
    <Container>
      <Grid container>
        <DescriptionBar
          icon={FormatListBulletedIcon}
          name="Contact Lists"
        />
      </Grid>
      <TabsWrapper>
        <Paper variant="outlined">
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              md={8}
              xs={12}
            >
              <Tabs
                indicatorColor="primary"
                onChange={handleChange}
                textColor="primary"
                value={tabIndex}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="All" />
                <Tab
                  disabled={!childrenLists.find(list => list.type === 'StaticList')}
                  label="Lists"
                />
                <Tab
                  disabled={!childrenLists.find(list => list.type === 'ComplexList')}
                  label="Filtered Lists"
                />
              </Tabs>
            </Grid>
            <Grid
              item
              md
              xs={12}
            >
              <Form
                onSubmit={onSubmit}
                render={({
                  handleSubmit,
                  form: { submit },
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name="searchPhrase">
                      {({ input }) => (
                        <StyledTextField
                          {...input}
                          type="search"
                          margin="dense"
                          fullWidth
                          placeholder="Search"
                          variant="standard"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                    <OnChange name="searchPhrase">{() => debouncedSubmit(submit)}</OnChange>
                  </form>
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </TabsWrapper>
      <OptionsWrapper>
        <MenuButton
          aria-controls="add-list-menu"
          aria-haspopup="true"
          color="secondary"
          disableElevation
          endIcon={<ArrowDropDownIcon />}
          onClick={handleClick}
          variant="contained"
        >
          Add List
        </MenuButton>
        <Menu
          anchorEl={anchorElement}
          id="add-list-menu"
          keepMounted
          onClose={handleClose}
          open={Boolean(anchorElement)}
        >
          <MenuItem onClick={handleOpenNewList}>List</MenuItem>
          <MenuItem
            onClick={() => history.push(`/${organizationUuid}/contacts/lists/create-filtered-list`)}
          >
            Filtered List
          </MenuItem>
        </Menu>
        <StyledMenuButton
          onClick={() => setIsNewListGroupDialogOpen(true)}
          color="secondary"
          disableElevation
          variant="contained"
        >
          Add Group
        </StyledMenuButton>
      </OptionsWrapper>
      {(isChildrenListsFetching || isPending) ? (
        <CircularProgressWrapper>
          <CircularProgress
            color="primary"
            size={60}
          />
        </CircularProgressWrapper>
      ) : (
        <ListsWrapper>
          {
            showNewList && (
              <StyledNewList
                list={rootList}
                onClose={handleCancelNewList}
                organizationUuid={organizationUuid}
              />
            )
          }
          <TabPanel
            index={0}
            value={tabIndex}
          >
            <Groups
              groups={groupsData.groups}
              isAddToGroupButonDisabled={!groups.length}
              onDeleteGroup={handleDeleteGroup}
              onOpenAddToGroupModal={handleOpenAddToGroupModal}
              onShowImportListView={handleShowImportListView}
              searchPhrase={searchPhrase}
            />
            {!!Object.keys(rootList).length && (
              <StyledListCard
                countAll={rootList.count}
                onShowImportListView={handleShowImportListView}
                list={rootList}
              />
            )}
            {!!Object.keys(groupsData.nonGeocoded).length && (
              <StyledListCard
                countAll={groupsData.nonGeocoded.count}
                onShowImportListView={handleShowImportListView}
                list={groupsData.nonGeocoded}
              />
            )}
            <Lists
              isAddToGroupButonDisabled={!groups.length}
              onShowImportListView={handleShowImportListView}
              lists={handleFilterList(groupsData.nonGrouped)}
              onOpenAddToGroupModal={handleOpenAddToGroupModal}
            />
          </TabPanel>
          <TabPanel
            index={1}
            value={tabIndex}
          >
            <Groups
              groups={groupsData.groups}
              isAddToGroupButonDisabled={!groups.length}
              listType="StaticList"
              onDeleteGroup={handleDeleteGroup}
              onOpenAddToGroupModal={handleOpenAddToGroupModal}
              onShowImportListView={handleShowImportListView}
              searchPhrase={searchPhrase}
            />
            <Lists
              isAddToGroupButonDisabled={!groups.length}
              lists={handleFilterList(groupsData.nonGrouped, 'StaticList')}
              onOpenAddToGroupModal={handleOpenAddToGroupModal}
              onShowImportListView={handleShowImportListView}
            />
          </TabPanel>
          <TabPanel
            index={2}
            value={tabIndex}
          >
            <Groups
              groups={groupsData.groups}
              isAddToGroupButonDisabled={!groups.length}
              listType="ComplexList"
              onDeleteGroup={handleDeleteGroup}
              onOpenAddToGroupModal={handleOpenAddToGroupModal}
              onShowImportListView={handleShowImportListView}
              searchPhrase={searchPhrase}
            />
            <Lists
              isAddToGroupButonDisabled={!groups.length}
              lists={handleFilterList(groupsData.nonGrouped, 'ComplexList')}
              onOpenAddToGroupModal={handleOpenAddToGroupModal}
              onShowImportListView={handleShowImportListView}
            />
          </TabPanel>
        </ListsWrapper>
      )}
      <FlatFileButton
        hide
        isLoading={false}
        onExit={() => setImportListUidd('')}
        flatfileRef={flatfileRef}
        listUuid={importListUidd}
      />
      <Dialog
        handleConfirm={handleAddGroup}
        handleDialogClose={handleAddGroupDialogClose}
        isDialogOpen={isNewListGroupDialogOpen}
        title={ADD_NEW_LIST_GROUP_DIALOG_TITLE}
        disabledButton={!newListGroup || isPending}
      >
        <TextField
          fullWidth
          placeholder="group list name"
          onChange={event => setNewListGroup(event.target.value)}
          type="text"
          value={newListGroup}
        />
      </Dialog>
      <Dialog
        handleConfirm={handleAddToGroup}
        handleDialogClose={handleAddToGroupDialogClose}
        isDialogOpen={addToGroupData.isModalOpen}
        title={ADD_TO_GROUP_DIALOG_TITLE}
        disabledButton={!addToGroupData.selectedGroup || isPending}
      >
        <Select
          fullWidth
          value={addToGroupData.selectedGroup}
          onChange={event => setAddToGroupData(prevValue => ({
            ...prevValue,
            selectedGroup: event.target.value,
          }))}
        >
          <MenuItem value="" />
          {groups?.map(group => (
            <MenuItem
              key={group.uuid}
              value={group.uuid}
            >
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </Dialog>
    </Container>
  );
};

export default ListsContainer;
