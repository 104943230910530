import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Field,
  Form,
} from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import { CircularProgressWrapper } from 'globalStyles';
import { useQuery } from 'utils/helpers';
import {
  fetchInvitationDetails,
  postJoinAdmin,
} from 'store/actions/organizations';
import { organizationSelectors } from 'store/selectors/organizations';

import { SuccessDialog } from 'common/components';
import { TermsField } from 'common/components/Form';
import { required } from 'utils/validators';
import { colors } from 'utils/constants';
import { authSelectors } from 'store/selectors/auth';

const LogoMark = styled.img`
  max-height: 60px;
  margin-bottom: 15px;
`;

const MarginGrid = styled(Grid)`
  margin-top: 5px;
`;

const StyledPaper = styled(Paper)`
  padding: 50px 30px;
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: 280px;
  height: 50px;
  margin: 30px 0 0;
  font-weight: bold;
  text-transform: uppercase;
`;

const TitleWrapper = styled(Grid)`
  margin-bottom: 20px;
`;

const Wrapper = styled(Grid)`
  max-width: 500px;
  height: 100%;
`;

const ErrorMessage = styled.p`
  color: ${colors.error};
  text-align: center;
`;

const JOIN_TITLE = 'Join';
const JOIN_SUBTITLE = 'You have been invited to join your team on Muster.';
const NOT_FOUND_TITLE = 'Not found';
const NOT_FOUND_SUBTITLE = 'If someone sent you this invitation in an email it might have expired or they might have deleted it.';
const CLICK_TO_JOIN = 'Please click the button below to join the oganization.';
const JOIN = 'Join';
const ALREADY_IN_MUSTER = 'You probably already have a Muster account. Please click the button below to log in to the application';
const LOGIN = 'Login';
const PASSWORD_LABEL = 'Password';
const FIRST_NAME_LABEL = 'First Name';
const LAST_NAME_LABEL = 'Last Name';

const JoinAdmin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const query = useQuery();
  const organizationUuid = query.get('organization');
  const inviteUuid = query.get('invite');

  const {
    invitationDetails,
    isAuthenticated,
    isOrganizationFetching,
  } = useSelector(state => ({
    invitationDetails: organizationSelectors.getInvitationDetails(state),
    isAuthenticated: authSelectors.getIsAuthenticated(state),
    isOrganizationFetching: organizationSelectors.isOrganizationFetching(state),
  }));

  const {
    shouldLogin,
    organization,
  } = invitationDetails;

  const [
    successMessage,
    setSuccessMessage,
  ] = useState('');

  useEffect(() => {
    dispatch(fetchInvitationDetails(organizationUuid, inviteUuid));
  }, [
    dispatch,
    organizationUuid,
    inviteUuid,
  ]);

  const handleConfirmJoin = async () => {
    try {
      const { message } = await dispatch(postJoinAdmin(organizationUuid, inviteUuid));

      return setSuccessMessage(message);
    } catch (error) {
      return error;
    }
  };

  const onSubmit = async values => {
    try {
      const { message } = await dispatch(postJoinAdmin(organizationUuid, inviteUuid, values));

      return setSuccessMessage(message);
    } catch (error) {
      if (error?.nonFieldErrors?.length) {
        return { [FORM_ERROR]: error?.nonFieldErrors[0] };
      }

      return error;
    }
  };

  const renderJoinWithLoggin = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Typography variant="subtitle2">{ALREADY_IN_MUSTER}</Typography>
          <StyledButton
            color="secondary"
            disableElevation
            onClick={() => history.push(`/login/${organizationUuid}/${inviteUuid}`)}
            variant="contained"
          >
            {LOGIN}
          </StyledButton>
        </>
      );
    }

    return (
      <>
        <Typography variant="subtitle2">{CLICK_TO_JOIN}</Typography>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
        >
          <StyledButton
            color="secondary"
            disableElevation
            onClick={handleConfirmJoin}
            type="submit"
            variant="contained"
          >
            {JOIN}
          </StyledButton>
        </Grid>
      </>
    );
  };

  const renderRegisterAndJoin = () => (
    <Form
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        submitError,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
          <MarginGrid
            container
            item
            xs={12}
          >
            <Field
              name="firstName"
              validate={required}
            >
              {({
                input,
                meta,
              }) => (
                <TextField
                  {...input}
                  error={meta.touched && (meta.error || meta.submitError)}
                  fullWidth
                  helperText={meta.touched && (meta.error || meta.submitError)}
                  label={FIRST_NAME_LABEL}
                  type="text"
                  placeholder="First Name"
                />
              )}
            </Field>
          </MarginGrid>
          <MarginGrid>
            <Field
              name="lastName"
              validate={required}
            >
              {({
                input,
                meta,
              }) => (
                <TextField
                  {...input}
                  error={meta.touched && (meta.error || meta.submitError)}
                  fullWidth
                  helperText={meta.touched && (meta.error || meta.submitError)}
                  label={LAST_NAME_LABEL}
                  type="text"
                  placeholder="Last Name"
                />
              )}
            </Field>
          </MarginGrid>
          <MarginGrid
            container
            item
            xs={12}
          >
            <Field
              name="newPassword1"
              validate={required}
            >
              {({
                input,
                meta,
              }) => (
                <TextField
                  {...input}
                  error={meta.touched && (meta.error || meta.submitError)}
                  fullWidth
                  helperText={meta.touched && (meta.error || meta.submitError)}
                  label={PASSWORD_LABEL}
                  type="password"
                />
              )}
            </Field>
          </MarginGrid>
          <MarginGrid
            container
            item
            xs={12}
          >
            <Field
              name="newPassword2"
              validate={required}
            >
              {({
                input,
                meta,
              }) => (
                <TextField
                  {...input}
                  error={meta.touched && (meta.error || meta.submitError)}
                  fullWidth
                  helperText={meta.touched && (meta.error || meta.submitError)}
                  label={PASSWORD_LABEL}
                  type="password"
                />
              )}
            </Field>
          </MarginGrid>
          <TermsField />
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
          >
            <StyledButton
              color="secondary"
              disableElevation
              disabled={submitting || pristine}
              type="submit"
              variant="contained"
            >
              {JOIN}
            </StyledButton>
          </Grid>
        </form>
      )}
    />
  );

  const renderNotFound = () => (
    <TitleWrapper
      container
      direction="column"
      alignItems="center"
    >
      <Typography variant="subtitle1">{NOT_FOUND_TITLE}</Typography>
      <Typography variant="subtitle2">{NOT_FOUND_SUBTITLE}</Typography>
    </TitleWrapper>
  );

  if (isOrganizationFetching) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  return (
    <Wrapper
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
    >
      <StyledPaper variant="outlined">
        <LogoMark
          alt="Muster logomark"
          src="/muster_logomark_blue.png"
        />
        {organization ? (
          <>
            <TitleWrapper
              container
              direction="column"
              alignItems="center"
            >
              <Typography variant="subtitle1">{`${JOIN_TITLE} ${organization}`}</Typography>
              <Typography variant="subtitle2">{JOIN_SUBTITLE}</Typography>
            </TitleWrapper>
            {shouldLogin ?
              renderJoinWithLoggin() :
              renderRegisterAndJoin()
            }
          </>
        ) : (
          renderNotFound()
        )}
      </StyledPaper>
      <SuccessDialog
        isDialogOpen={!!successMessage}
        redirectTo={isAuthenticated ? '/organizations' : '/login'}
        thankYouMessage={successMessage}
      />
    </Wrapper>
  );
};

export default JoinAdmin;
