import {
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { differenceBy } from 'lodash';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import {
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import {
  fetchSignup,
  patchSignup,
  postSignup,
} from 'store/actions/signups';
import { fetchRootList } from 'store/actions/contacts';
import { fetchCustomFields } from 'store/actions/customFields';
import { customFieldsSelectors } from 'store/selectors/customFields';
import { contactSelectors } from 'store/selectors/contacts';
import { organizationSelectors } from 'store/selectors/organizations';
import { signupsSelectors } from 'store/selectors/signups';
import { setSnackbarError } from 'store/actions/global';

import {
  Backdrop,
  CancelCreatorButton,
  CardField,
  Container,
  DescriptionBar,
  Stepper,
  SuccessDialog,
} from 'common/components';
import { colors } from 'utils/constants';
import {
  isValidURL,
  renderErrorMessage,
  scrollToTop,
  youtubeParser,
} from 'utils/helpers';
import {
  ErrorTypography,
  StepOneContainer,
  StepThreeContainer,
  StepTwoContainer,
} from 'globalStyles';

import StepOne from '../components/StepOne';
import StepTwo from '../components/StepTwo';
import StepThree from '../components/StepThree';

const ButtonCreateWrapper = styled.div`
  position: relative;
  padding-left: 10px;
`;

const ButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const NextButtonWrapper = styled.div`
  display: flex;
  padding-left: 10px;
`;

const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90px;
  margin-top: 50px;
  background-color: ${colors.white};
`;

const CreateSignup = () => {
  const dispatch = useDispatch();
  const {
    signupUuid,
    organizationUuid,
  } = useParams();

  const {
    customFieldsList,
    isFetching,
    isPending,
    organization,
    rootList,
  } = useSelector(state => ({
    customFieldsList: customFieldsSelectors.getCustomFieldsListData(state),
    isFetching: signupsSelectors.isFetching(state),
    isPending: signupsSelectors.isPending(state),
    organization: organizationSelectors.getOrganization(state),
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  const [
    step,
    setStep,
  ] = useState(0);
  const [
    errorMessage,
    setErrorMessage,
  ] = useState(null);
  const [
    isSuccessDialogOpen,
    setIsSuccessDialogOpen,
  ] = useState(false);
  const [
    images,
    setImages,
  ] = useState([]);
  const [
    logoImage,
    setLogoImage,
  ] = useState([]);
  const [
    customFields,
    setCustomFields,
  ] = useState([]);

  const sampleMarkup = '<p style="text-align:center;">Thank you for your support, we will be in touch!</p>';

  const [
    editorState,
    setEditorStateChange,
  ] = useState(EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML(sampleMarkup)
    )
  ));
  const [
    descriptionBodyState,
    setDescriptionBodyState,
  ] = useState(null);
  const [
    action,
    setAction,
  ] = useState('');
  const [
    isUrl,
    setIsUrl,
  ] = useState(true);
  const [
    signupData,
    setSignupData,
  ] = useState({});

  const [
    formValues,
    setFormValues,
  ] = useState({
    backgroundColor: colors.dodgerBlue,
    buttonColor: colors.dodgerBlue,
    descriptionHeadlineText: '',
    googleAnalyticsId: '',
    headlineText: '',
    isDonateButton: false,
    isFacebookPixel: false,
    isGoogleAnalytics: false,
    linkDonate: '',
    name: '',
    pixelId: '',
    secondHeadlineText: '',
    socialMedia: true,
    textColor: colors.black,
    videoUrl: '',
    vimeoCode: '',
  });

  useEffect(() => {
    if (Object.keys(rootList).length === 0) {
      dispatch(fetchRootList(organizationUuid))
        .then(data => dispatch(fetchCustomFields(data.uuid)));
    } else {
      dispatch(fetchCustomFields(rootList.uuid));
    }

    if (signupUuid) {
      dispatch(fetchSignup(signupUuid))
        .then(data => {
          if (data.thankYouMessage) {
            let thankYouHtml = data.thankYouMessage;
            // Checking if the wrong html is saved, the problem described here:: https://github.com/jpuri/react-draft-wysiwyg/issues/609#issuecomment-1282192676
            const regExp = /^<img/;
            const isInvalidThankYouMessage = data.thankYouMessage.match(regExp);

            if (isInvalidThankYouMessage) {
              thankYouHtml = `<p>${thankYouHtml.replace(/\/>/, '/></p>')}`;
            }

            const blocksFromHtml = htmlToDraft(thankYouHtml);
            const {
              contentBlocks,
              entityMap,
            } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

            setEditorStateChange(EditorState.createWithContent(contentState));
          }

          setSignupData(data);
          setFormValues(prevValues => ({
            ...prevValues,
            backgroundColor: data.headlineConfig.backgroundColor || colors.dodgerBlue,
            buttonColor: data.headlineConfig.buttonColor || colors.dodgerBlue,
            descriptionHeadlineText: data.headlineConfig.descriptionHeadlineText || '',
            googleAnalyticsId: data.googleAnalyticsId,
            headlineText: data.headlineConfig.headlineText || '',
            isDonateButton: data.isDonateButton,
            isFacebookPixel: data.facebookPixelEnabled,
            isGoogleAnalytics: data.googleAnalyticsEnabled,
            linkDonate: data.linkDonate || '',
            name: data.name,
            pixelId: data.facebookPixelId,
            secondHeadlineText: data.headlineConfig.secondHeadlineText || '',
            socialMedia: data.socialShareButtonEnabled,
            textColor: data.headlineConfig.textColor || colors.black,
            videoUrl: data.headlineConfig.youtubeId ? `https://www.youtube.com/watch?v=${data.headlineConfig.youtubeId}` : '',
            vimeoCode: data.headlineConfig.vimeoCode || '',
          }));
          setCustomFields(data.customFields);

          if (data.headlineConfig.descriptionBody) {
            const blocksFromHtml = htmlToDraft(data.headlineConfig.descriptionBody);
            const {
              contentBlocks,
              entityMap,
            } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

            setDescriptionBodyState(EditorState.createWithContent(contentState));
          }
        });
    }
  }, []);

  const showDialog = actionTaken => {
    setAction(actionTaken);
    setIsSuccessDialogOpen(true);
  };

  const handleAddCustomField = selectedCustomField => {
    setCustomFields(prevCustomFields => [
      ...prevCustomFields,
      selectedCustomField,
    ]);
  };

  const handleRemoveCustomField = customFieldUuid => {
    setCustomFields(
      prevCustomFields => prevCustomFields.filter(field => field.uuid !== customFieldUuid)
    );
  };

  useEffect(() => {
    setErrorMessage(null);
    scrollToTop();
  }, [step]);

  useEffect(() => {
    const { config } = organization;

    if (!config || signupUuid) {
      return;
    }

    if (config.backgroundColor) {
      setFormValues(prevValues => ({
        ...prevValues,
        backgroundColor: config.backgroundColor,
      }));
    }

    if (config.buttonColor) {
      setFormValues(prevValues => ({
        ...prevValues,
        buttonColor: config?.buttonColor,
      }));
    }

    if (config.textColor) {
      setFormValues(prevValues => ({
        ...prevValues,
        textColor: config.textColor,
      }));
    }
  }, [organization]);

  const handleSaveSignup = async () => {
    const params = {
      name: formValues.name,
      organization: organizationUuid,
    };

    try {
      if (!signupData?.uuid) {
        const data = await dispatch(postSignup(params));

        setSignupData(data);
        setStep(prevStep => prevStep + 1);
      } else {
        await dispatch(patchSignup(signupData.uuid, params));

        setStep(prevStep => prevStep + 1);
      }
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const youtubeId = youtubeParser(formValues.videoUrl);

  const handleSaveSignupContent = async () => {
    const headlineConfig = {
      backgroundColor: formValues.backgroundColor,
      buttonColor: formValues.buttonColor,
      descriptionBody: descriptionBodyState ? draftToHtml(convertToRaw(descriptionBodyState.getCurrentContent())) : '',
      descriptionHeadlineText: formValues.descriptionHeadlineText,
      facebookPixelEnabled: formValues.isFacebookPixel,
      googleAnalyticsEnabled: formValues.isGoogleAnalytics,
      headlineText: formValues.headlineText,
      secondHeadlineText: formValues.secondHeadlineText,
      textColor: formValues.textColor,
      vimeoCode: formValues.vimeoCode,
      youtubeId,
    };

    const logo = new FormData();
    const signupPageImage = new FormData();

    logo.append('logo', logoImage[0]?.file);
    signupPageImage.append('signup_page_image', images[0]);

    const params = {
      customFields: customFields.map(customField => customField.uuid),
      headlineConfig,
      socialShareButtonEnabled: formValues.socialMedia,
    };

    if (formValues.pixelId && formValues.isFacebookPixel) {
      params.facebookPixelId = formValues.pixelId;
    }

    if (formValues.googleAnalyticsId && formValues.isGoogleAnalytics) {
      params.googleAnalyticsId = formValues.googleAnalyticsId;
    }

    try {
      await dispatch(patchSignup(signupData.uuid, params));

      if (logoImage[0]) {
        await dispatch(patchSignup(signupData.uuid, logo));
      }

      if (images[0]) {
        await dispatch(patchSignup(signupData.uuid, signupPageImage));
      }

      setStep(prevStep => prevStep + 1);
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const isValidDonate = () => {
    if (formValues.isDonateButton) {
      if (!isValidURL(formValues.linkDonate)) {
        setIsUrl(false);

        return false;
      }
    }

    return true;
  };

  const handleEditorError = () => {
    dispatch(setSnackbarError({
      message: 'Something was wrong with your thank you message. Please overwrite the thank you again',
    }));

    setEditorStateChange(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(sampleMarkup)
        )
      )
    );
  };

  const saveLastStep = async isPublish => {
    const params = {
      isDonateButton: formValues.isDonateButton,
      publishStatus: isPublish ? 2 : 1,
      thankYouMessage: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    };

    if (formValues.isDonateButton) {
      params.linkDonate = formValues.linkDonate;
    }

    try {
      await dispatch(patchSignup(signupData.uuid, params));

      showDialog(isPublish ? 'published' : 'saved');
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const handlePublishActionCenter = async () => {
    if (!isValidDonate()) return;

    saveLastStep(true);
  };

  const handleSaveAsDraft = () => {
    if (!isValidDonate()) return;

    saveLastStep(false);
  };

  const steps = [
    'Configure',
    'Form Interface',
    'Advanced Settings/Publish',
  ];

  const disableNextButton = () => {
    if (step === 0) {
      return !formValues.name;
    }

    if (step === 1) {
      return !formValues.headlineText;
    }

    if (step === 2 && formValues.isDonateButton && !isUrl) {
      return true;
    }

    return false;
  };

  const handleSave = () => {
    if (step === 0) {
      handleSaveSignup();
    } else if (step === 1) {
      handleSaveSignupContent();
    } else if (step === 2) {
      handlePublishActionCenter();
    }
  };

  const handleSetFormValues = event => {
    setFormValues(prevValues => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSetBooleanFormValues = event => {
    setFormValues(prevValues => ({
      ...prevValues,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleSetColor = (color, name) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: color,
    }));
  };

  const handleChangeIsDonate = value => {
    setFormValues(prevValues => ({
      ...prevValues,
      isDonateButton: value,
    }));
  };

  const filteredCustomFieldsList = differenceBy(customFieldsList, customFields, 'uuid');
  const pageName = () => {
    if (isFetching) {
      return '';
    }

    return signupUuid ? `Edit ${signupData?.name} Contact Form` : 'Create Contact Form';
  };

  return (
    <>
      <Container>
        <Grid container>
          <DescriptionBar
            icon={FilterListIcon}
            name={pageName()}
          >
            <CancelCreatorButton redirectUrl={
              signupUuid ?
                `/${organizationUuid}/signups/${signupUuid}?tab=0` :
                `/${organizationUuid}/signups/`
              }
            />
          </DescriptionBar>
        </Grid>
        <Stepper
          step={step}
          steps={steps}
          neutralcolors
        />
        {errorMessage && (
          <CardField name="Error">
            <ErrorTypography>
              {renderErrorMessage(errorMessage)}
            </ErrorTypography>
          </CardField>
        )}
        <StepOneContainer step={step}>
          <StepOne
            errorMessage={errorMessage}
            handleSetFormValues={handleSetFormValues}
            nameValue={formValues.name}
            setErrorMessage={setErrorMessage}
          />
        </StepOneContainer>
        <StepTwoContainer step={step}>
          <StepTwo
            backgroundColor={formValues.backgroundColor}
            buttonColor={formValues.buttonColor}
            customFields={customFields}
            customFieldsList={filteredCustomFieldsList}
            defaultLandingPageImage={signupData?.landingPageImage}
            defaultLogo={signupData.logo}
            logoImage={logoImage}
            descriptionHeadlineText={formValues.descriptionHeadlineText}
            descriptionBodyState={descriptionBodyState}
            googleAnalyticsId={formValues.googleAnalyticsId}
            handleAddCustomField={handleAddCustomField}
            handleRemoveCustomField={handleRemoveCustomField}
            handleSetColor={handleSetColor}
            handleSetFormValues={handleSetFormValues}
            handleSetBooleanFormValues={handleSetBooleanFormValues}
            headlineText={formValues.headlineText}
            isFacebookPixel={formValues.isFacebookPixel}
            isGoogleAnalytics={formValues.isGoogleAnalytics}
            pixelId={formValues.pixelId}
            secondHeadlineText={formValues.secondHeadlineText}
            setImages={setImages}
            setDescriptionBodyState={setDescriptionBodyState}
            setLogoImage={setLogoImage}
            socialMedia={formValues.socialMedia}
            textColor={formValues.textColor}
            videoUrl={formValues.videoUrl}
            vimeoCode={formValues.vimeoCode}
            youtubeId={youtubeId}
          />
        </StepTwoContainer>
        <StepThreeContainer step={step}>
          <StepThree
            editorState={editorState}
            isDonateButton={formValues.isDonateButton}
            isUrl={isUrl}
            handleChangeIsDonate={handleChangeIsDonate}
            handleEditorError={handleEditorError}
            handleSetFormValues={handleSetFormValues}
            linkDonate={formValues.linkDonate}
            setEditorStateChange={setEditorStateChange}
            setIsUrl={setIsUrl}
          />
        </StepThreeContainer>
      </Container>
      <StepperWrapper>
        <Grid
          container
          direction="row"
          item
          justifyContent="space-between"
          lg={10}
          xs={11}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-start"
            xs={2}
          >
            <Button
              disabled={step === 0}
              onClick={() => setStep(prevStep => prevStep - 1)}
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-end"
            xs={4}
          >
            <NextButtonWrapper>
              {
                step === 2 && (
                  <ButtonCreateWrapper>
                    <Button
                      disabled={disableNextButton() || isPending}
                      onClick={handleSaveAsDraft}
                      variant="contained"
                      type="button"
                    >
                      Save as Draft
                    </Button>
                    {isPending && <ButtonCircularProgress size={24} />}
                  </ButtonCreateWrapper>
                )
              }
              <ButtonCreateWrapper>
                <Button
                  color="secondary"
                  disabled={disableNextButton() || isPending}
                  onClick={handleSave}
                  variant="contained"
                  type="button"
                >
                  {step !== 2 ? 'Save and Continue' : 'Publish'}
                </Button>
                {isPending && <ButtonCircularProgress size={24} />}
              </ButtonCreateWrapper>
            </NextButtonWrapper>
          </Grid>
        </Grid>
      </StepperWrapper>
      <SuccessDialog
        isDialogOpen={isSuccessDialogOpen}
        thankYouMessage={action === 'published' ? 'Your contact form is now ready.' : 'Your Contact Form has been saved as a draft '}
        redirectTo={`/${organizationUuid}/signups/${signupData.uuid}?tab=0`}
      />
      <Backdrop isOpen={isFetching || isPending} />
    </>
  );
};

export default CreateSignup;
