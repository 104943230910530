import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  CircularProgress,
  MenuItem,
} from '@mui/material';

import {
  addOfficial,
  fetchFederalPositions,
  fetchPositionTerms,
} from 'store/actions/contactDetails';
import { contactDetailsSelectors } from 'store/selectors/contactDetails';

import {
  OfficialsDetails,
  UserDialog,
} from 'common/components';

import {
  administrativeLevels,
  usStates,
} from 'utils/constants';
import { CircularProgressWrapper } from 'globalStyles';

const OfficialsForm = ({
  contactId,
  handleDialogClose,
  isDialogOpen,
  listId,
}) => {
  const dispatch = useDispatch();
  const {
    federalPositions,
    isPositionsFetching,
    isTermsFetching,
    positionTerms,
  } = useSelector(state => ({
    federalPositions: contactDetailsSelectors.getFederalPositions(state),
    isPositionsFetching: contactDetailsSelectors.isPositionsFetching(state),
    isTermsFetching: contactDetailsSelectors.isTermsFetching(state),
    positionTerms: contactDetailsSelectors.getPositionTerms(state),
  }));

  const [
    category,
    setCategory,
  ] = useState('');
  const [
    position,
    setPosition,
  ] = useState('');
  const [
    term,
    setTerm,
  ] = useState({});
  const [
    state,
    setState,
  ] = useState('');
  const [
    description,
    setDescription,
  ] = useState('');

  const handleCategoryChange = event => {
    setCategory(event.target.value);

    if (event.target.value === 'Federal') {
      dispatch(fetchFederalPositions({ category: 'federal' }));
    }
  };

  const handleDialogUpdate = () => dispatch(addOfficial(
    listId,
    contactId,
    {
      description,
      official: term.uuid,
    }
  ));

  const handleOfficialsDialogClose = () => {
    setCategory('');
    setPosition('');
    setState('');
    setTerm({});
    setDescription('');
    handleDialogClose();
  };

  const handleNonFederalPositionChange = event => {
    dispatch(fetchPositionTerms({
      position: event.target.value,
      state,
    }));
    setPosition(event.target.value);
  };

  const handlePositionStateChange = event => {
    if (category === 'Federal') {
      if (event.target.value !== position) {
        dispatch(fetchPositionTerms({ position: event.target.value }));

        setPosition(event.target.value);
      }
    } else {
      dispatch(fetchFederalPositions({
        category: category === 'Local' ? 'local' : 'state',
        state: event.target.value,
      }));

      setState(event.target.value);
    }
  };

  const renderPositions = () => (
    isPositionsFetching ? (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    ) : (
      federalPositions.map(({
        title,
        uuid,
      }) => (
        <MenuItem
          key={uuid}
          value={uuid}
        >
          {title}
        </MenuItem>
      ))
    )
  );

  const renderStates = () => (
    usStates.map(({
      abbreviation,
      name,
    }) => (
      <MenuItem
        key={abbreviation}
        value={abbreviation}
      >
        {name}
      </MenuItem>
    ))
  );

  return (
    <UserDialog
      description={description}
      handleDialogClose={handleOfficialsDialogClose}
      handleDataUpdate={handleDialogUpdate}
      isDialogOpen={isDialogOpen}
      isOfficialsDialog
      title="Adding a grass tops information"
    >
      <OfficialsDetails
        administrativeLevels={administrativeLevels}
        category={category}
        description={description}
        handleCategoryChange={handleCategoryChange}
        handleNonFederalPositionChange={handleNonFederalPositionChange}
        handlePositionStateChange={handlePositionStateChange}
        isTermsFetching={isTermsFetching}
        position={position}
        positionTerms={positionTerms}
        renderPositions={renderPositions}
        renderStates={renderStates}
        setDescription={setDescription}
        setPosition={setPosition}
        setState={setState}
        setTerm={setTerm}
        state={state}
        term={term}
      />
    </UserDialog>
  );
};

OfficialsForm.defaultProps = {
  contactId: '',
  listId: '',
};

OfficialsForm.propTypes = {
  contactId: PropTypes.string,
  handleDialogClose: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  listId: PropTypes.string,
};

export default OfficialsForm;
