import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';

import { CircularProgressWrapper } from 'globalStyles';
import { fullOfficialName } from 'utils/helpers';

const StyledTextField = styled(TextField)`
  width: 100%;
  padding-bottom: 15px;
`;

const OfficialsDetails = ({
  administrativeLevels,
  category,
  description,
  handleCategoryChange,
  handleNonFederalPositionChange,
  handlePositionStateChange,
  isFilteredListAdding,
  isTermsFetching,
  onlyPositions,
  position,
  positionTerms,
  renderPositions,
  renderStates,
  setDescription,
  setFilterValue,
  setPosition,
  setState,
  setTerm,
  state,
  term,
}) => (
  <>
    <StyledTextField
      label="Category"
      onChange={event => {
        setState('');
        setPosition('');
        setTerm({});
        setFilterValue('');
        handleCategoryChange(event);
      }}
      select
      value={category}
      variant="standard"
    >
      {administrativeLevels.map(element => (
        <MenuItem
          key={element.key}
          value={element.value}
        >
          {element.value}
        </MenuItem>
      ))}
    </StyledTextField>
    {category && (
      <StyledTextField
        select
        label={category === 'Federal' ? 'Position' : 'State'}
        value={category === 'Federal' ? position : state}
        onChange={event => {
          setPosition('');
          setTerm({});
          setFilterValue('');
          handlePositionStateChange(event);
        }}
        variant="standard"
      >
        {category === 'Federal' ? renderPositions() : renderStates()}
      </StyledTextField>
    )}
    {state && category !== 'Federal' && (
      <StyledTextField
        disabled={!renderPositions().length}
        label="Position"
        onChange={event => handleNonFederalPositionChange(event)}
        select
        value={position}
      >
        {renderPositions()}
      </StyledTextField>
    )}
    {position && !onlyPositions && (
      <StyledTextField
        label="Term"
        onChange={event => {
          setTerm(event.target.value);
          setFilterValue(event.target.value.uuid);
        }}
        select
        value={Object.keys(term).length ? term : ''}
        SelectProps={{
          renderValue: option => option.name,
        }}
        variant="standard"
      >
        {isTermsFetching ? (
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        ) : (
          positionTerms.map(({
            uuid,
            office,
            official,
          }) => {
            const name = fullOfficialName(official, office?.title);

            return (
              <MenuItem
                key={uuid}
                value={{
                  name,
                  uuid: official.uuid,
                }}
              >
                {name}
              </MenuItem>
            );
          })
        )}
      </StyledTextField>
    )}
    {!isFilteredListAdding && term?.uuid && !onlyPositions && (
      <StyledTextField
        defaultValue={description}
        label="Description"
        multiline
        onChange={event => setDescription(event.target.value)}
        placeholder="Tell us how they know each other"
        variant="standard"
      />
    )}
  </>
);

OfficialsDetails.defaultProps = {
  administrativeLevels: [],
  category: '',
  description: '',
  handleCategoryChange: () => {},
  handleNonFederalPositionChange: () => {},
  handlePositionStateChange: () => {},
  isFilteredListAdding: false,
  isTermsFetching: false,
  onlyPositions: false,
  position: '',
  positionTerms: [],
  renderPositions: () => {},
  renderStates: () => {},
  setDescription: () => {},
  setFilterValue: () => {},
  setPosition: () => {},
  setState: () => {},
  setTerm: () => {},
  state: '',
  term: {},
};

OfficialsDetails.propTypes = {
  administrativeLevels: PropTypes.arrayOf(PropTypes.shape({})),
  category: PropTypes.string,
  description: PropTypes.string,
  handleCategoryChange: PropTypes.func,
  handleNonFederalPositionChange: PropTypes.func,
  handlePositionStateChange: PropTypes.func,
  isFilteredListAdding: PropTypes.bool,
  isTermsFetching: PropTypes.bool,
  onlyPositions: PropTypes.bool,
  position: PropTypes.string,
  positionTerms: PropTypes.arrayOf(PropTypes.shape({})),
  renderPositions: PropTypes.func,
  renderStates: PropTypes.func,
  setDescription: PropTypes.func,
  setFilterValue: PropTypes.func,
  setPosition: PropTypes.func,
  setState: PropTypes.func,
  setTerm: PropTypes.func,
  state: PropTypes.string,
  term: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string,
  }),
};

export default OfficialsDetails;
