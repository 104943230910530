import PropTypes from 'prop-types';
import styled from 'styled-components';

const PhoneWrapper = styled.div`
  float: right;
  width: 480px;
  padding: 0;
  padding-bottom: 25px;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: helvetica, arial, sans-serif;
  line-height: 20px;
  vertical-align: baseline;
`;
const Phone = styled.div`
  position: relative;
  overflow: hidden;
  width: 480px;
  margin: 0;
  padding: 0;
  background: #fff;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 18px;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const PhoneHeader = styled.div`
  width: 100%;
  height: 95px;
  margin: 0;
  border: 1px solid #ebebeb;
  padding: 0;
  background: #f6f6f7;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const StatusBar = styled.div`
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const ConnectionWrapper = styled.div`
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Bars = styled.div`
  float: left;
  margin: 0 5px 0 9px;
  border: 0;
  padding: 0;
  padding-top: 11px;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const BarsAmount = styled.div`
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Bar = styled.div`
  float: left;
  width: 7px;
  height: 7px;
  margin: 0;
  margin-right: 2px;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 0;
  background: #000;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const BarEmpty = styled.div`
  float: left;
  width: 7px;
  height: 7px;
  margin: 0;
  margin-right: 2px;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 0;
  background: 0 0;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Network = styled.div`
  float: left;
  margin: 0;
  border: 0;
  padding: 0;
  padding-top: 5px;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Connection = styled.div`
  float: left;
  margin: 0;
  margin-left: 11px;
  border: 0;
  padding: 0;
  padding-top: 5px;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Time = styled.div`
  position: absolute;
  top: 5px;
  width: 100%;
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 700;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Juice = styled.div`
  position: absolute;
  top: 1px;
  left: 3px;
  z-index: 1100;
  width: 23px;
  height: 12px;
  margin: 0;
  border: 0;
  border-bottom: 1px solid #222;
  padding: 0;
  background: #000;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Battery = styled.div`
  position: relative;
  top: 1px;
  float: right;
  overflow: hidden;
  width: 38px;
  height: 16px;
  margin: 0;
  margin-left: 5px;
  border: 0;
  padding: 0;
  background-color: #fff;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Icons = styled.div`
  float: right;
  margin: 0;
  margin-right: 8px;
  border: 0;
  padding: 0;
  padding-top: 5px;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Percent = styled.div`
  float: right;
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Clear = styled.div`
  clear: both;
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const TitleBar = styled.div`
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const HeaderButtons = styled.img`
  position: absolute;
  top: 47px;
  left: 0;
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const ClientName = styled.div`
  position: absolute;
  top: 53px;
  width: 100%;
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 700;
  font-size: 25px;
  font-family: inherit;
  line-height: 20px;
  text-align: center;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;
const NameWrap = styled.div`
  position: relative;
  left: 0;
  display: inline-block;
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 700;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  text-align: center;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;
const Body = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 515px;
  max-height: 1201px;
  margin: 0;
  border: 0;
  border: 1px solid #ebebeb;
  border-top: 0;
  padding: 0;
  padding-top: 20px;
  background: #fff;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;
const TextMessage = styled.div`
  top: 0;
  clear: both;
  display: block;
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
`;
const Bubble = styled.div`
  position: relative;
  overflow-wrap: break-word;
  display: inline-block;
  min-width: 14px;
  max-width: 310px;
  min-height: 26px;
  margin: 0;
  margin-left: 24px;
  border: 0;
  border-radius: 20px 20px 20px 10px;
  padding: 12px 18px;
  background: #e5e5ea;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 24px;
  font-family: inherit;
  line-height: 26px;
  text-shadow: 1px 1px 0 #f3f3f4;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Corner = styled.div`
  position: absolute;
  bottom: -1px;
  left: -9px;
  overflow-wrap: break-word;
  width: 36px;
  height: 12px;
  margin: 0;
  border: 0;
  padding: 0;
  background: url(/bubble_corner_grey.png) no-repeat;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 26px;
  text-shadow: 1px 1px 0 #f3f3f4;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Footer = styled.div`
  position: relative;
  width: 100%;
  height: 65px;
  min-height: 65px;
  margin: 0;
  border: 0;
  border: 1px solid #ebebeb;
  border-top: 0;
  padding-top: 12px;
  background: #f7f7f8;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const Camera = styled.div`
  position: absolute;
  bottom: 17px;
  left: 20px;
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;
const SendButton = styled.div`
  position: absolute;
  right: 20px;
  bottom: 9px;
  margin: 0;
  border: 0;
  padding: 0;
  color: #000;
  font-style: inherit;
  font-weight: 400;
  font-size: 100%;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const MessageArea = styled.div`
  overflow: hidden;
  width: 292px;
  height: 29px;
  box-sizing: content-box;
  margin: 0;
  margin-left: 75px;
  border: 1px solid #d4d4d7;
  border-radius: 5px;
  padding: 9px 12px 0;
  background: #fafafa;
  color: #c0c0c6;
  font-style: inherit;
  font-weight: 400;
  font-size: 20px;
  font-family: inherit;
  line-height: 20px;
  user-select: none;
  cursor: default;
  vertical-align: baseline;
`;

const StyledLink = styled.a`
  display: block;
  padding-top: 5px;
`;

const PhoneView = ({
  link,
  text,
}) => (
  <PhoneWrapper>
    <Phone>
      <PhoneHeader>
        <StatusBar>
          <ConnectionWrapper>
            <Bars>
              <BarsAmount>
                <Bar />
                <Bar />
                <Bar />
                <BarEmpty />
                <BarEmpty />
              </BarsAmount>
            </Bars>
            <Network>Sprint</Network>
            <Connection>LTE</Connection>
          </ConnectionWrapper>
          <Icons>
            <Battery>
              <img
                alt="battery"
                src="/icon_battery.png"
              />
              <Juice />
            </Battery>
            <Percent>75%</Percent>
          </Icons>
          <Time>10:19 AM</Time>
          <Clear />
        </StatusBar>
        <TitleBar>
          <HeaderButtons
            alt="header buttons"
            src="/headerButtons.png"
          />
          <ClientName>
            <NameWrap
              className="nameWrap"
              id="name"
            >
              Muster
            </NameWrap>
          </ClientName>
        </TitleBar>
      </PhoneHeader>
      <Body>
        <TextMessage>
          <Bubble>
            <span>{text}</span>
            {link && (
              <StyledLink>{link}</StyledLink>
            )}
            <Corner />
          </Bubble>
        </TextMessage>
      </Body>
      <Footer>
        <Camera>
          <img
            alt="camera"
            src="/icon_camera.png"
          />
        </Camera>
        <MessageArea>Message</MessageArea>
        <SendButton>
          <img
            alt="mic"
            src="/icon_mic.png"
            className="icon-mic"
          />
        </SendButton>
      </Footer>
    </Phone>
  </PhoneWrapper>
);

PhoneView.defaultProps = {
  link: null,
  text: 'Broadcast Message',
};

PhoneView.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
};

export default PhoneView;
