import {
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@mui/icons-material';

import { StyledHighlightOffIcon } from 'globalStyles';
import PropTypes from 'prop-types';

const TableIcon = ({
  boolean,
  positiveIcon,
  negativeIcon,
}) => (boolean ?
  positiveIcon :
  negativeIcon
);

TableIcon.defaultProps = {
  negativeIcon: <StyledHighlightOffIcon />,
  positiveIcon: <CheckCircleOutlineIcon color="secondary" />,
};

TableIcon.propTypes = {
  boolean: PropTypes.bool.isRequired,
  negativeIcon: PropTypes.node,
  positiveIcon: PropTypes.node,
};

export default TableIcon;
