import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import {
  colors,
  maxNonGeocodedTargets,
} from 'utils/constants';

import {
  fetchTargetGroups,
  postTargetGroup,
} from 'store/actions/campaigns';
import { campaignsSelectors } from 'store/selectors/campaigns';
import {
  setSnackbarSuccess,
} from 'store/actions/global';

import CardField from 'common/components/CardField';
import Dialog from 'common/components/Dialog';
import TargetsSelection from 'common/containers/TargetsSelection';

import { showFirstErrorMessage } from 'utils/helpers';
import { targetsType } from 'common/typings';

const CardFieldWrapper = styled.div`
  margin-top: 30px;
`;

const ExplanationWrapper = styled.div`
  margin: 40px auto 10px;
  padding: 15px;
  background-color: ${colors.lightGold};
`;

const StyledGrid = styled(Grid)`
  flex: 0 0 100%;
`;

const StyledFormControl = styled(FormControl)`
  flex-direction: row;
`;

const StepOne = ({
  actionMode,
  campaignValue,
  communicationChannel,
  errorMessage,
  isCampaign,
  isCurrentStep,
  isGeocoded,
  nameValue,
  organizationUuid,
  setActionMode,
  setCommunicationChannel,
  setCampaignValue,
  setErrorMessage,
  setIsGeocoded,
  setMessageTabIndex,
  setNameValue,
  setTargets,
  showGeocodingSelection,
  showPetitions,
  showPhoneActionCenter,
  targets,
}) => {
  const dispatch = useDispatch();
  const { actionCenterUuid } = useParams();

  const {
    isPending,
  } = useSelector(state => ({
    isPending: campaignsSelectors.isPending(state),
  }));

  const [
    targetsForGroup,
    setTargetsForGroup,
  ] = useState([]);
  const [
    targetListName,
    setTargetListName,
  ] = useState('');
  const [
    isDialogOpen,
    setIsDialogOpen,
  ] = useState(false);

  const [
    dialogError,
    setDialogError,
  ] = useState('');

  const handleCommunicationChange = event => {
    setCommunicationChannel({
      ...communicationChannel,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSaveSelectedTargetsToTargetList = data => {
    setTargetsForGroup(data);
    setIsDialogOpen(true);
  };

  // Ensure geocoding is ON after changing modes IF non-geocoding
  // is unavailable via feature-flag. This ensures someone cannot
  // create a "back door" non-geocoded action center without the
  // feature flag.
  const checkGeocodingSafety = mode => {
    if (mode === 'act' && !showGeocodingSelection) {
      setIsGeocoded(true);
    }
  };

  const handleSaveTargetGroup = () => {
    const params = {
      name: targetListName,
      organization: organizationUuid,
      targets: targetsForGroup.map(target => ({ term: target.term || target.uuid })),
    };

    dispatch(postTargetGroup(params))
      .then(() => {
        setIsDialogOpen(false);
        setTargetListName('');
        dispatch(fetchTargetGroups({ organization: organizationUuid }));
        dispatch(setSnackbarSuccess({ message: 'Targets Group Saved' }));
      })
      .catch(error => setDialogError(error));
  };

  const {
    email,
    phone,
    // twitter,
    // usmail,
  } = communicationChannel;

  return (
    <>
      {!isCampaign && (
        <CardFieldWrapper>
          <CardField
            name="Campaign Name"
            required
          >
            <TextField
              fullWidth
              disabled={isCampaign || actionCenterUuid}
              label="Campaign Name"
              onChange={event => setCampaignValue(event.target.value)}
              required
              type="text"
              value={campaignValue}
            />
          </CardField>
        </CardFieldWrapper>
      )}
      <CardFieldWrapper>
        <CardField
          flexwrap
          name="Action Center Name"
          required
          descriptionText="The name of this Action Center will not be displayed to customers on the landing page and will only be the internal name of the identity"
        >
          <StyledGrid>
            <TextField
              fullWidth
              label="Action Center Name"
              onChange={event => {
                setNameValue(event.target.value);
                setErrorMessage(null);
              }}
              error={!!errorMessage?.slug && !!errorMessage?.slug[0]}
              helperText={errorMessage?.slug && errorMessage?.slug[0]}
              required
              type="text"
              value={nameValue}
              variant="standard"
            />
          </StyledGrid>
        </CardField>
      </CardFieldWrapper>
      {showPetitions && (
      <CardFieldWrapper>
        <CardField name="Action or Petition Mode">
          <StyledGrid>
            <RadioGroup>
              <FormControlLabel
                checked={actionMode === 'act'}
                control={<Radio />}
                onChange={() => {
                  setActionMode('act');
                  checkGeocodingSafety('act');
                }}
                label="Send Messages"
              />
              <FormControlLabel
                checked={actionMode === 'petition'}
                control={<Radio />}
                onChange={() => {
                  setActionMode('petition');
                  setMessageTabIndex('rotation');
                }}
                label="Petition-only"
              />
            </RadioGroup>
          </StyledGrid>
        </CardField>
      </CardFieldWrapper>
      )}
      {actionMode === 'act' && (
      <CardFieldWrapper>
        <CardField name="Communication Channels">
          <StyledFormControl>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={email}
                  onChange={handleCommunicationChange}
                />
              )}
              label="Email"
              name="email"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={phone}
                  onChange={handleCommunicationChange}
                />
              )}
              disabled={!showPhoneActionCenter}
              label="Phone"
              name="phone"
            />
          </StyledFormControl>
        </CardField>
      </CardFieldWrapper>
      )}
      <CardFieldWrapper>
        <CardField
          name="Targets"
          flexwrap
          required
        >
          {isCurrentStep && (
            <TargetsSelection
              setTargets={setTargets}
              targets={targets}
              handleSaveSelectedTargetsToTargetList={handleSaveSelectedTargetsToTargetList}
              isCongressGroupSensitive={!isGeocoded && actionMode === 'act'}
              phoneActionCenter={communicationChannel.phone}
              organizationUuid={organizationUuid}
            />
          )}
        </CardField>
      </CardFieldWrapper>

      {(showGeocodingSelection || actionMode !== 'act') && (
        <CardFieldWrapper>
          <CardField name="Advocate Target Matching">
            <StyledGrid>
              <RadioGroup>
                <FormControlLabel
                  checked={isGeocoded}
                  control={<Radio />}
                  onChange={() => setIsGeocoded(true)}
                  label="Geotargeted (match targets by advocate address)"
                />
                <FormControlLabel
                  checked={!isGeocoded}
                  control={<Radio />}
                  onChange={() => {
                    setIsGeocoded(false);
                  }}
                  label="Non-geotargeted (advocates contact all targets)"
                />
              </RadioGroup>
              {!isGeocoded && actionMode === 'act' && (
                <ExplanationWrapper>
                  <Typography
                    variant="caption"
                    component="p"
                    display="block"
                    gutterBottom
                  >
                    <strong>
                      Non-geotargeted action centers are limited to
                      {' '}
                      {maxNonGeocodedTargets}
                      {' '}
                      targets and may not include Members of Congress.
                      (Petition mode action centers are exempt from these limits.)
                    </strong>
                  </Typography>
                </ExplanationWrapper>
              )}
            </StyledGrid>
          </CardField>
        </CardFieldWrapper>
      )}
      <Dialog
        dialogError={dialogError && showFirstErrorMessage(dialogError)}
        isDialogOpen={isDialogOpen}
        disabledButton={isPending || !targetListName}
        handleConfirm={handleSaveTargetGroup}
        handleDialogClose={() => setIsDialogOpen(false)}
        title="Add name of targets list"
      >
        <TextField
          fullWidth
          label="name"
          onChange={event => setTargetListName(event.target.value)}
          type="text"
          value={targetListName}
        />
      </Dialog>
    </>
  );
};

StepOne.defaultProps = {
  errorMessage: null,
  showGeocodingSelection: true,
  showPetitions: false,
  showPhoneActionCenter: false,
};

StepOne.propTypes = {
  actionMode: PropTypes.string.isRequired,
  campaignValue: PropTypes.string.isRequired,
  communicationChannel: PropTypes.shape({
    email: PropTypes.bool,
    phone: PropTypes.bool,
    twitter: PropTypes.bool,
    usmail: PropTypes.bool,
  }).isRequired,
  errorMessage: PropTypes.shape({
    slug: PropTypes.arrayOf(PropTypes.string),
  }),
  isCampaign: PropTypes.bool.isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  isGeocoded: PropTypes.bool.isRequired,
  nameValue: PropTypes.string.isRequired,
  organizationUuid: PropTypes.string.isRequired,
  setActionMode: PropTypes.func.isRequired,
  setCampaignValue: PropTypes.func.isRequired,
  setCommunicationChannel: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setIsGeocoded: PropTypes.func.isRequired,
  setMessageTabIndex: PropTypes.func.isRequired,
  setNameValue: PropTypes.func.isRequired,
  setTargets: PropTypes.func.isRequired,
  showGeocodingSelection: PropTypes.bool,
  showPetitions: PropTypes.bool,
  showPhoneActionCenter: PropTypes.bool,
  targets: targetsType.isRequired,
};

export default StepOne;
