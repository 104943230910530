import { Fragment } from 'react';
import PropTypes from 'prop-types';
import ImageUploading from 'react-images-uploading';
import styled from 'styled-components';

import {
  Button,
  Grid,
} from '@mui/material';
import { imageType } from 'common/typings';

const FixedWidthButton = styled(Button)`
  width: 105px;

  &:first-of-type {
    margin-bottom: 15px;
  }
`;

const LogoButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const StyledImg = styled.img`
  padding-right: 15px;
`;

const CHOOSE_LOGO_MESSAGE = 'Choose Logo';
const THUMBNAIL_WIDTH = '120';
const SAVE_IMAGES_TEXT = 'Save Image';

const ImageUploader = ({
  defaultImageScr,
  disabled,
  images,
  onSave,
  setImages,
  showSaveButton,
}) => {
  const onChange = imageList => {
    setImages(imageList);
  };

  return (
    <ImageUploading
      dataURLKey="data_url"
      onChange={onChange}
      singleImage
      value={images}
    >
      {({
        imageList,
        onImageUpload,
        onImageUpdate,
      }) => (
        <Grid
          container
          justifyContent="flex-end"
        >
          {defaultImageScr && !imageList.length && (
            <StyledImg
              alt="Logo"
              src={defaultImageScr}
              width={THUMBNAIL_WIDTH}
            />
          )}
          {imageList.map((image, index) => (
            <Fragment key={image.data_url}>
              <img
                alt="Logo"
                src={image.data_url}
                width={THUMBNAIL_WIDTH}
              />
              <LogoButtonsWrapper>
                <FixedWidthButton
                  color="secondary"
                  disableElevation
                  disabled={disabled}
                  onClick={() => onImageUpdate(index)}
                  variant="contained"
                >
                  {CHOOSE_LOGO_MESSAGE}
                </FixedWidthButton>
                {showSaveButton && (
                  <FixedWidthButton
                    color="secondary"
                    disableElevation
                    disabled={disabled}
                    onClick={onSave}
                    variant="contained"
                  >
                    {SAVE_IMAGES_TEXT}
                  </FixedWidthButton>
                )}
              </LogoButtonsWrapper>
            </Fragment>
          ))}
          {imageList.length === 0 && (
            <Button
              color="secondary"
              disableElevation
              disabled={disabled}
              onClick={onImageUpload}
              variant="contained"
            >
              {CHOOSE_LOGO_MESSAGE}
            </Button>
          )}
        </Grid>
      )}
    </ImageUploading>
  );
};

ImageUploader.defaultProps = {
  defaultImageScr: null,
  disabled: false,
  onSave: () => {},
  showSaveButton: true,
};

ImageUploader.propTypes = {
  defaultImageScr: PropTypes.string,
  disabled: PropTypes.bool,
  images: PropTypes.arrayOf(imageType).isRequired,
  onSave: PropTypes.func,
  setImages: PropTypes.func.isRequired,
  showSaveButton: PropTypes.bool,
};

export default ImageUploader;
