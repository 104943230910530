import request from 'utils/request';

const PREFIX = '[SIGNUPS]';

export const FETCH_SIGNUPS_REQUEST = `${PREFIX} FETCH_SIGNUPS_REQUEST`;
export const FETCH_SIGNUPS_SUCCESS = `${PREFIX} FETCH_SIGNUPS_SUCCESS`;
export const FETCH_SIGNUPS_FAILURE = `${PREFIX} FETCH_SIGNUPS_FAILURE`;

const fetchSignupsRequest = () => ({
  type: FETCH_SIGNUPS_REQUEST,
});

const fetchSignupsSuccess = payload => ({
  payload,
  type: FETCH_SIGNUPS_SUCCESS,
});

const fetchSignupsFailure = payload => ({
  payload,
  type: FETCH_SIGNUPS_FAILURE,
});

export const fetchSignups = organizationUuid => dispatch => {
  dispatch(fetchSignupsRequest());

  return request
    .get(`/v1/signup-forms/forms/?organization=${organizationUuid}`)
    .then(({ data }) => {
      dispatch(fetchSignupsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchSignupsFailure(data));

      throw data;
    });
};

export const FETCH_ALL_SIGNUPS_REQUEST = `${PREFIX} FETCH_ALL_SIGNUPS_REQUEST`;
export const FETCH_ALL_LIST_CONTACTS_IN_PROGRESS = `${PREFIX} FETCH_ALL_LIST_CONTACTS_IN_PROGRESS`;
export const FETCH_ALL_SIGNUPS_SUCCESS = `${PREFIX} FETCH_ALL_SIGNUPS_SUCCESS`;
export const FETCH_ALL_SIGNUPS_FAILURE = `${PREFIX} FETCH_ALL_SIGNUPS_FAILURE`;

const fetchAllSignupsRequest = () => ({
  type: FETCH_ALL_SIGNUPS_REQUEST,
});

const fetchAllSignupsInProgress = payload => ({
  payload,
  type: FETCH_ALL_LIST_CONTACTS_IN_PROGRESS,
});

const fetchAllSignupsSuccess = payload => ({
  payload,
  type: FETCH_ALL_SIGNUPS_SUCCESS,
});

const fetchAllSignupsFailure = payload => ({
  payload,
  type: FETCH_ALL_SIGNUPS_FAILURE,
});

export const fetchAllSignups = organizationUuid => dispatch => {
  dispatch(fetchAllSignupsRequest());
  const contacts = [];

  const fetchAllSignupsWithPagination = next => request
    .get(next || `/v1/signup-forms/forms/?organization=${organizationUuid}`)
    .then(({ data }) => {
      contacts.push(...data.results);

      dispatch(fetchAllSignupsInProgress({
        allCount: data.count,
        fetchedCount: data.results.length,
      }));

      if (!data.next) {
        return contacts;
      }

      return fetchAllSignupsWithPagination(data.next);
    });

  return fetchAllSignupsWithPagination()
    .then(data => {
      dispatch(fetchAllSignupsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchAllSignupsFailure(data));

      throw data;
    });
};

export const FETCH_SIGNUP_REQUEST = `${PREFIX} FETCH_SIGNUP_REQUEST`;
export const FETCH_SIGNUP_SUCCESS = `${PREFIX} FETCH_SIGNUP_SUCCESS`;
export const FETCH_SIGNUP_FAILURE = `${PREFIX} FETCH_SIGNUP_FAILURE`;

const fetchSignupRequest = () => ({
  type: FETCH_SIGNUP_REQUEST,
});

const fetchSignupSuccess = payload => ({
  payload,
  type: FETCH_SIGNUP_SUCCESS,
});

const fetchSignupFailure = payload => ({
  payload,
  type: FETCH_SIGNUP_FAILURE,
});

export const fetchSignup = signupUuid => dispatch => {
  dispatch(fetchSignupRequest());

  return request
    .get(`/v1/signup-forms/forms/${signupUuid}/`)
    .then(({ data }) => {
      dispatch(fetchSignupSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchSignupFailure(data));

      throw data;
    });
};

export const DELETE_SIGNUP_REQUEST = `${PREFIX} DELETE_SIGNUP_REQUEST`;
export const DELETE_SIGNUP_SUCCESS = `${PREFIX} DELETE_SIGNUP_SUCCESS`;
export const DELETE_SIGNUP_FAILURE = `${PREFIX} DELETE_SIGNUP_FAILURE`;

const deleteSignupRequest = () => ({
  type: DELETE_SIGNUP_REQUEST,
});

const deleteSignupSuccess = payload => ({
  payload,
  type: DELETE_SIGNUP_SUCCESS,
});

const deleteSignupFailure = payload => ({
  payload,
  type: DELETE_SIGNUP_FAILURE,
});

export const deleteSignup = signupUuid => dispatch => {
  dispatch(deleteSignupRequest());

  return request
    .delete(`/v1/signup-forms/forms/${signupUuid}/`)
    .then(({ data }) => {
      dispatch(deleteSignupSuccess(data));
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteSignupFailure(data));

      throw data;
    });
};

export const POST_SIGNUP_REQUEST = `${PREFIX} POST_SIGNUP_REQUEST`;
export const POST_SIGNUP_SUCCESS = `${PREFIX} POST_SIGNUP_SUCCESS`;
export const POST_SIGNUP_FAILURE = `${PREFIX} POST_SIGNUP_FAILURE`;

const postSignupRequest = () => ({
  type: POST_SIGNUP_REQUEST,
});

const postSignupSuccess = payload => ({
  payload,
  type: POST_SIGNUP_SUCCESS,
});

const postSignupFailure = payload => ({
  payload,
  type: POST_SIGNUP_FAILURE,
});

export const postSignup = params => dispatch => {
  dispatch(postSignupRequest());

  return request
    .post('/v1/signup-forms/forms/', params)
    .then(({ data }) => {
      dispatch(postSignupSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postSignupFailure(data));

      throw data;
    });
};

export const PATCH_SIGNUP_REQUEST = `${PREFIX} PATCH_SIGNUP_REQUEST`;
export const PATCH_SIGNUP_SUCCESS = `${PREFIX} PATCH_SIGNUP_SUCCESS`;
export const PATCH_SIGNUP_FAILURE = `${PREFIX} PATCH_SIGNUP_FAILURE`;

const patchSignupRequest = () => ({
  type: PATCH_SIGNUP_REQUEST,
});

const patchSignupSuccess = payload => ({
  payload,
  type: PATCH_SIGNUP_SUCCESS,
});

const patchSignupFailure = payload => ({
  payload,
  type: PATCH_SIGNUP_FAILURE,
});

export const patchSignup = (signupUuid, params) => dispatch => {
  dispatch(patchSignupRequest());

  return request
    .patch(`/v1/signup-forms/forms/${signupUuid}/`, params)
    .then(({ data }) => {
      dispatch(patchSignupSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchSignupFailure(data));

      throw data;
    });
};
