import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

import {
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import { FilterList as FilterListIcon } from '@mui/icons-material';

import {
  fetchTextActionAlert,
  patchTextActionAlert,
  postSendPreviewTextActionAlert,
  postSendTextActionAlert,
  postTextActionAlert,
} from 'store/actions/campaigns';
import {
  fetchChildrenLists,
  fetchRootList,
} from 'store/actions/contacts';
import { setSnackbarSuccess } from 'store/actions/global';
import { campaignsSelectors } from 'store/selectors/campaigns';
import { contactSelectors } from 'store/selectors/contacts';
import { organizationSelectors } from 'store/selectors/organizations';

import {
  CancelCreatorButton,
  CardField,
  Container,
  DescriptionBar,
  Stepper,
  SuccessDialog,
} from 'common/components';
import {
  colors,
} from 'utils/constants';
import {
  formatNumber,
  handleMergeCorrectTime,
  renderErrorMessage,
  scrollToTop,
} from 'utils/helpers';
import {
  CircularProgressWrapper,
  ErrorTypography,
  StepOneContainer,
  StepTwoContainer,
} from 'globalStyles';

import StepOne from 'routes/CreateTextActionAlert/components/StepOne';
import StepTwo from 'routes/CreateTextActionAlert/components/StepTwo';
import {
  initialTimezone,
  timezones,
} from 'utils/timezones';

const ButtonCreateWrapper = styled.div`
  position: relative;
`;

const ButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const NextButtonWrapper = styled.div`
  padding-left: 10px;
`;

const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90px;
  margin-top: 50px;
  background-color: ${colors.white};
`;

const CreateTextActionAlert = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    actionCenterUuid,
    actionAlertUuid,
    campaignUuid,
    organizationUuid,
  } = useParams();
  const isEditActionAlert = history.location.pathname.includes('/edit/');
  const isDuplicateActionAlert = history.location.pathname.includes('/duplicate/');

  const {
    childrenLists,
    isActionAlertFetching,
    isPending,
    organization,
    rootList,
  } = useSelector(state => ({
    childrenLists: contactSelectors.getChildrenLists(state)[organizationUuid] || [],
    isActionAlertFetching: campaignsSelectors.isActionAlertFetching(state),
    isPending: campaignsSelectors.isPending(state),
    organization: organizationSelectors.getOrganization(state),
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  const listsOptions = useMemo(() => [
    rootList,
    ...childrenLists,
  ], [
    rootList,
    childrenLists,
  ]);

  useEffect(() => {
    if (Object.keys(rootList).length === 0 || childrenLists.length === 0) {
      dispatch(fetchRootList(organizationUuid))
        .then(data => dispatch(fetchChildrenLists(data.uuid, organizationUuid)));
    }
  }, [
    dispatch,
    organizationUuid,
  ]);

  const [
    actionAlertDetails,
    setActionAlertDetails,
  ] = useState({});
  const [
    selectedDate,
    setSelectedDate,
  ] = useState(dayjs());
  const [
    selectedTime,
    setSelectedTime,
  ] = useState(dayjs());
  const [
    previewPhone,
    setPreviewPhone,
  ] = useState('');
  const [
    countRecipients,
    setCountRecipients,
  ] = useState(0);
  const [
    step,
    setStep,
  ] = useState(0);
  const [
    errorMessage,
    setErrorMessage,
  ] = useState(null);
  const [
    isScheduled,
    setIsScheduled,
  ] = useState(false);
  const [
    successDialogMessage,
    setSuccessDialogMessage,
  ] = useState('');
  const [
    selectedTimeZone,
    setSelectedTimeZone,
  ] = useState(initialTimezone(organization.timezone));
  const [
    formValues,
    setFormValues,
  ] = useState({
    listsValue: [],
    messageValue: '',
    nameValue: '',
  });

  useEffect(() => {
    setActionAlertDetails({});

    if (isEditActionAlert || isDuplicateActionAlert) {
      dispatch(fetchTextActionAlert(actionAlertUuid))
        .then(data => {
          setFormValues(prevValues => ({
            ...prevValues,
            listsValue: data.contactLists,
            messageValue: data.message,
            nameValue: data.name,
          }));
        });
    }
  }, [
    dispatch,
    actionAlertUuid,
  ]);

  const postNewActionAlert = async () => {
    const params = {
      actionCenter: actionCenterUuid,
      contactLists: formValues.listsValue?.map(list => list.uuid),
      message: formValues.messageValue,
      name: formValues.nameValue,
      organization: organizationUuid,
    };

    try {
      if (
        actionAlertDetails.uuid ||
        isEditActionAlert
      ) {
        const actionAlertData = await dispatch(patchTextActionAlert(
          actionAlertDetails.uuid || actionAlertUuid,
          params
        ));

        setCountRecipients(actionAlertData?.countRecipients);

        setActionAlertDetails(prevValue => ({
          ...prevValue,
          ...actionAlertData,
        }));

        setErrorMessage(null);
      } else {
        const actionAlertData = await dispatch(postTextActionAlert(params));

        setCountRecipients(actionAlertData?.countRecipients);

        setActionAlertDetails(prevValue => ({
          ...prevValue,
          ...actionAlertData,
        }));

        setErrorMessage(null);
      }
      setStep(prevStep => prevStep + 1);
    } catch (error) {
      setErrorMessage(error);
    } finally {
      scrollToTop();
    }
  };

  const sendTextActionAlert = async () => {
    const params = {};

    if (isScheduled) {
      params.sendTime = handleMergeCorrectTime(selectedDate, selectedTime);
      params.sendAtTimezone = selectedTimeZone;
    }

    try {
      const message = await dispatch(postSendTextActionAlert(actionAlertDetails.uuid, params));

      setSuccessDialogMessage(message || 'Thank you for sending this Text Action Alert');
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const handleSendPreview = async () => {
    try {
      await dispatch(postSendPreviewTextActionAlert(
        actionAlertDetails.uuid,
        { phoneNumbers: [previewPhone] }
      ));

      dispatch(setSnackbarSuccess({ message: 'A text message will be sent to the provided number.' }));
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const steps = [
    'Setup & Write Text',
    'Send',
  ];

  const disableNextButton = () => {
    if (step === 0) {
      return !(formValues.nameValue &&
          formValues.messageValue && formValues.listsValue);
    }

    if (step === 1 && isScheduled) {
      return !(selectedTime && selectedDate);
    }

    if (isPending) {
      return true;
    }

    return false;
  };

  const handleSave = () => {
    if (step === 0) {
      postNewActionAlert();
    } else if (step === 1) {
      sendTextActionAlert();
    }
  };

  const handleGoBack = () => {
    setStep(prevStep => prevStep - 1);
    scrollToTop();
  };

  if (isActionAlertFetching) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  const title = () => {
    if (isDuplicateActionAlert) {
      return 'Duplicate';
    }

    if (isEditActionAlert) {
      return 'Edit';
    }

    return 'Create New';
  };

  return (
    <>
      <Container>
        <Grid container>
          <DescriptionBar
            icon={FilterListIcon}
            name={`${title()} Text Action Alert`}
          >
            <CancelCreatorButton redirectUrl={
              (isEditActionAlert || isDuplicateActionAlert) ?
                `/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}/text-action-alerts/${actionAlertUuid}` :
                `/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}?tab=0`
              }
            />
          </DescriptionBar>
        </Grid>
        <Stepper
          step={step}
          steps={steps}
          neutralcolors
        />
        {errorMessage && (
          <CardField name="Error">
            <ErrorTypography>
              {renderErrorMessage(errorMessage)}
            </ErrorTypography>
          </CardField>
        )}
        <StepOneContainer step={step}>
          <StepOne
            actionCenterUuid={actionCenterUuid}
            lists={listsOptions}
            listsValue={formValues.listsValue}
            messageValue={formValues.messageValue}
            nameValue={formValues.nameValue}
            setFormValues={setFormValues}
          />
        </StepOneContainer>
        <StepTwoContainer step={step}>
          <StepTwo
            isScheduled={isScheduled}
            onSendPreview={handleSendPreview}
            previewPhone={previewPhone}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            selectedTimeZone={selectedTimeZone}
            setPreviewPhone={setPreviewPhone}
            setSelectedDate={setSelectedDate}
            setSelectedTime={setSelectedTime}
            setSelectedTimeZone={setSelectedTimeZone}
            setIsScheduled={setIsScheduled}
            timezones={timezones}
          />
        </StepTwoContainer>
      </Container>
      <StepperWrapper>
        <Grid
          container
          direction="row"
          item
          justifyContent="space-between"
          lg={10}
          xs={11}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-start"
            xs={2}
          >
            <Button
              disabled={step === 0}
              onClick={handleGoBack}
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-end"
            xs={4}
          >
            <NextButtonWrapper>
              <ButtonCreateWrapper>
                <Button
                  color="secondary"
                  disabled={disableNextButton() || isPending}
                  onClick={handleSave}
                  variant="contained"
                  type="button"
                >
                  {step !== 1 ? 'Save and Continue' : `Send to ${formatNumber(countRecipients)} recipients`}
                </Button>
                {isPending && <ButtonCircularProgress size={24} />}
              </ButtonCreateWrapper>
            </NextButtonWrapper>
          </Grid>
        </Grid>
      </StepperWrapper>
      <SuccessDialog
        isCreateNewDisabled
        isDialogOpen={!!successDialogMessage}
        name="Text Action Alert"
        redirectTo={`/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}?tab=0`}
        thankYouMessage={successDialogMessage}
      />
    </>
  );
};

export default CreateTextActionAlert;
