import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Tabs as MaterialTabs,
  Tab,
} from '@mui/material';

import { tabsType } from 'common/typings';
import { colors } from 'utils/constants';

const StyledTabs = styled(MaterialTabs)`
  color: ${colors.tableHeaderGray};
`;

const Tabs = ({
  className,
  handleSetTab,
  tabIndex,
  tabs,
}) => (
  <StyledTabs
    className={className}
    onChange={(_, newValue) => handleSetTab(newValue)}
    TabIndicatorProps={{
        style: {
          backgroundColor: colors.secondary,
        },
      }}
    textColor="inherit"
    value={tabIndex}
  >
    {tabs.map(tab => (
      <Tab
        disabled={tab.disabled}
        label={tab.label}
        key={tab.value}
        value={tab.value}
      />
    ))}
  </StyledTabs>
  );

Tabs.defaultProps = {
  className: '',
};

Tabs.propTypes = {
  className: PropTypes.string,
  handleSetTab: PropTypes.func.isRequired,
  tabIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  tabs: tabsType.isRequired,
};

export default Tabs;

