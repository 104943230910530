import PropTypes, { arrayOf } from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useConfirm } from 'material-ui-confirm';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import { deleteLocation } from 'store/actions/contactDetails';

import { handleZipcodeFormat } from 'utils/helpers';
import { StyledHighlightOffIcon } from 'globalStyles';

const StyledTableCell = styled(TableCell)`
  padding-left: 0;
`;

const LocationsTable = ({
  contactId,
  listId,
  locations,
  onEditClick,
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const handleLocationDelete = locationId => {
    confirm({
      confirmationText: 'Delete',
      description: 'This action will delete selected location',
      title: 'Delete selected location?',
    })
      .then(() => dispatch(deleteLocation(listId, contactId, locationId)));
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Street address</StyledTableCell>
            <TableCell>Street address 2</TableCell>
            <TableCell>City</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Zip code</TableCell>
            <TableCell>Geocoded</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {locations.map(location => (
            <TableRow key={location.uuid}>
              <StyledTableCell>{location.streetAddress}</StyledTableCell>
              <TableCell>{location.streetAddress2}</TableCell>
              <TableCell>{location.city}</TableCell>
              <TableCell>{location.state}</TableCell>
              <TableCell>{handleZipcodeFormat(location.zipCode)}</TableCell>
              <TableCell>{location.point ? <CheckCircleOutlineIcon color="secondary" /> : <StyledHighlightOffIcon />}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="edit"
                  onClick={() => onEditClick(location)}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleLocationDelete(location.uuid)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

LocationsTable.defaultProps = {
  locations: [],
};

LocationsTable.propTypes = {
  contactId: PropTypes.string.isRequired,
  listId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      point: PropTypes.shape({
        coordinates: arrayOf(PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ])),
        type: PropTypes.string,
      }),
      state: PropTypes.string,
      streetAddress: PropTypes.string,
      streetAddress2: PropTypes.string,
      zipCode: PropTypes.string,
    })
  ),
  onEditClick: PropTypes.func.isRequired,
};

export default LocationsTable;
