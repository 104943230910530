import request from 'utils/request';
import fetchAllPagedData from 'utils/dataFetching';

const PREFIX = '[CAMPAIGNS]';

export const FETCH_CAMPAIGNS_REQUEST = `${PREFIX} FETCH_CAMPAIGNS_REQUEST`;
export const FETCH_CAMPAIGNS_SUCCESS = `${PREFIX} FETCH_CAMPAIGNS_SUCCESS`;
export const FETCH_CAMPAIGNS_FAILURE = `${PREFIX} FETCH_CAMPAIGNS_FAILURE`;

const fetchCampaignsRequest = () => ({
  type: FETCH_CAMPAIGNS_REQUEST,
});

const fetchCampaignsSuccess = payload => ({
  payload,
  type: FETCH_CAMPAIGNS_SUCCESS,
});

const fetchCampaignsFailure = payload => ({
  payload,
  type: FETCH_CAMPAIGNS_FAILURE,
});

export const fetchCampaigns = params => dispatch => {
  dispatch(fetchCampaignsRequest());

  return request
    .get('/v1/campaigns/campaigns/', { params })
    .then(({ data }) => {
      dispatch(fetchCampaignsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchCampaignsFailure(data));

      throw data;
    });
};

export const fetchSsrCampaigns = queryParams => () => request
  .get(`/v1/campaigns/campaigns/${queryParams}`)
  .then(({ data }) => data)
  .catch(({ response: { data } = {} }) => {
    throw data;
  });

export const POST_CAMPAIGN_REQUEST = `${PREFIX} POST_CAMPAIGN_REQUEST`;
export const POST_CAMPAIGN_SUCCESS = `${PREFIX} POST_CAMPAIGN_SUCCESS`;
export const POST_CAMPAIGN_FAILURE = `${PREFIX} POST_CAMPAIGN_FAILURE`;

const postCampaignRequest = () => ({
  type: POST_CAMPAIGN_REQUEST,
});

const postCampaignSuccess = payload => ({
  payload,
  type: POST_CAMPAIGN_SUCCESS,
});

const postCampaignFailure = payload => ({
  payload,
  type: POST_CAMPAIGN_FAILURE,
});

export const postCampaign = params => dispatch => {
  dispatch(postCampaignRequest());

  return request
    .post('/v1/campaigns/campaigns/', params)
    .then(({ data }) => {
      dispatch(postCampaignSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postCampaignFailure(data));

      throw data;
    });
};

export const FETCH_CAMPAIGN_REQUEST = `${PREFIX} FETCH_CAMPAIGN_REQUEST`;
export const FETCH_CAMPAIGN_SUCCESS = `${PREFIX} FETCH_CAMPAIGN_SUCCESS`;
export const FETCH_CAMPAIGN_FAILURE = `${PREFIX} FETCH_CAMPAIGN_FAILURE`;

const fetchCampaignRequest = () => ({
  type: FETCH_CAMPAIGN_REQUEST,
});

const fetchCampaignSuccess = payload => ({
  payload,
  type: FETCH_CAMPAIGN_SUCCESS,
});

const fetchCampaignFailure = payload => ({
  payload,
  type: FETCH_CAMPAIGN_FAILURE,
});

export const fetchCampaign = uuid => dispatch => {
  dispatch(fetchCampaignRequest());

  return request
    .get(`/v1/campaigns/campaigns/${uuid}/`)
    .then(({ data }) => {
      dispatch(fetchCampaignSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchCampaignFailure(data));

      throw data;
    });
};

export const FETCH_ACTION_ALERTS_REQUEST = `${PREFIX} FETCH_ACTION_ALERTS_REQUEST`;
export const FETCH_ACTION_ALERTS_SUCCESS = `${PREFIX} FETCH_ACTION_ALERTS_SUCCESS`;
export const FETCH_ACTION_ALERTS_FAILURE = `${PREFIX} FETCH_ACTION_ALERTS_FAILURE`;

const fetchActionAlertsRequest = () => ({
  type: FETCH_ACTION_ALERTS_REQUEST,
});

const fetchActionAlertsSuccess = payload => ({
  payload,
  type: FETCH_ACTION_ALERTS_SUCCESS,
});

const fetchActionAlertsFailure = payload => ({
  payload,
  type: FETCH_ACTION_ALERTS_FAILURE,
});

export const fetchActionAlerts = params => dispatch => {
  dispatch(fetchActionAlertsRequest());

  return request
    .get('/v1/campaigns/action_alerts/', { params })
    .then(({ data }) => {
      dispatch(fetchActionAlertsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchActionAlertsFailure(data));

      throw data;
    });
};

export const POST_ACTION_ALERT_REQUEST = `${PREFIX} POST_ACTION_ALERT_REQUEST`;
export const POST_ACTION_ALERT_SUCCESS = `${PREFIX} POST_ACTION_ALERT_SUCCESS`;
export const POST_ACTION_ALERT_FAILURE = `${PREFIX} POST_ACTION_ALERT_FAILURE`;

const postActionAlertRequest = () => ({
  type: POST_ACTION_ALERT_REQUEST,
});

const postActionAlertSuccess = payload => ({
  payload,
  type: POST_ACTION_ALERT_SUCCESS,
});

const postActionAlertFailure = payload => ({
  payload,
  type: POST_ACTION_ALERT_FAILURE,
});

export const postActionAlert = params => dispatch => {
  dispatch(postActionAlertRequest());

  return request
    .post('/v1/campaigns/action_alerts/', params)
    .then(({ data }) => {
      dispatch(postActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postActionAlertFailure(data));

      throw data;
    });
};

export const FETCH_ACTION_ALERT_REQUEST = `${PREFIX} FETCH_ACTION_ALERT_REQUEST`;
export const FETCH_ACTION_ALERT_SUCCESS = `${PREFIX} FETCH_ACTION_ALERT_SUCCESS`;
export const FETCH_ACTION_ALERT_FAILURE = `${PREFIX} FETCH_ACTION_ALERT_FAILURE`;

const fetchActionAlertRequest = () => ({
  type: FETCH_ACTION_ALERT_REQUEST,
});

const fetchActionAlertSuccess = payload => ({
  payload,
  type: FETCH_ACTION_ALERT_SUCCESS,
});

const fetchActionAlertFailure = payload => ({
  payload,
  type: FETCH_ACTION_ALERT_FAILURE,
});

export const fetchActionAlert = uuid => dispatch => {
  dispatch(fetchActionAlertRequest());

  return request
    .get(`/v1/campaigns/action_alerts/${uuid}/`)
    .then(({ data }) => {
      dispatch(fetchActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchActionAlertFailure(data));

      throw data;
    });
};

export const DELETE_ACTION_ALERT_REQUEST = `${PREFIX} DELETE_ACTION_ALERT_REQUEST`;
export const DELETE_ACTION_ALERT_SUCCESS = `${PREFIX} DELETE_ACTION_ALERT_SUCCESS`;
export const DELETE_ACTION_ALERT_FAILURE = `${PREFIX} DELETE_ACTION_ALERT_FAILURE`;

const deleteActionAlertRequest = () => ({
  type: DELETE_ACTION_ALERT_REQUEST,
});

const deleteActionAlertSuccess = payload => ({
  payload,
  type: DELETE_ACTION_ALERT_SUCCESS,
});

const deleteActionAlertFailure = payload => ({
  payload,
  type: DELETE_ACTION_ALERT_FAILURE,
});

export const deleteActionAlert = uuid => dispatch => {
  dispatch(deleteActionAlertRequest());

  return request
    .delete(`/v1/campaigns/action_alerts/${uuid}/`)
    .then(({ data }) => {
      dispatch(deleteActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteActionAlertFailure(data));

      throw data;
    });
};

export const PATCH_ACTION_ALERT_REQUEST = `${PREFIX} PATCH_ACTION_ALERT_REQUEST`;
export const PATCH_ACTION_ALERT_SUCCESS = `${PREFIX} PATCH_ACTION_ALERT_SUCCESS`;
export const PATCH_ACTION_ALERT_FAILURE = `${PREFIX} PATCH_ACTION_ALERT_FAILURE`;

const patchActionAlertRequest = () => ({
  type: PATCH_ACTION_ALERT_REQUEST,
});

const patchActionAlertSuccess = payload => ({
  payload,
  type: PATCH_ACTION_ALERT_SUCCESS,
});

const patchActionAlertFailure = payload => ({
  payload,
  type: PATCH_ACTION_ALERT_FAILURE,
});

export const patchActionAlert = (uuid, params) => dispatch => {
  dispatch(patchActionAlertRequest());

  return request
    .patch(`/v1/campaigns/action_alerts/${uuid}/`, params)
    .then(({ data }) => {
      dispatch(patchActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchActionAlertFailure(data));

      throw data;
    });
};

export const FETCH_ACTION_CENTERS_REQUEST = `${PREFIX} FETCH_ACTION_CENTERS_REQUEST`;
export const FETCH_ACTION_CENTERS_SUCCESS = `${PREFIX} FETCH_ACTION_CENTERS_SUCCESS`;
export const FETCH_ACTION_CENTERS_FAILURE = `${PREFIX} FETCH_ACTION_CENTERS_FAILURE`;

const fetchActionCentersRequest = () => ({
  type: FETCH_ACTION_CENTERS_REQUEST,
});

const fetchActionCentersSuccess = payload => ({
  payload,
  type: FETCH_ACTION_CENTERS_SUCCESS,
});

const fetchActionCentersFailure = payload => ({
  payload,
  type: FETCH_ACTION_CENTERS_FAILURE,
});

export const fetchActionCenters = params => dispatch => {
  dispatch(fetchActionCentersRequest());

  return request
    .get('/v1/campaigns/action_centers/', { params })
    .then(({ data }) => {
      dispatch(fetchActionCentersSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchActionCentersFailure(data));

      throw data;
    });
};

export const POST_ACTION_CENTER_REQUEST = `${PREFIX} POST_ACTION_CENTER_REQUEST`;
export const POST_ACTION_CENTER_SUCCESS = `${PREFIX} POST_ACTION_CENTER_SUCCESS`;
export const POST_ACTION_CENTER_FAILURE = `${PREFIX} POST_ACTION_CENTER_FAILURE`;

const postActionCenterRequest = () => ({
  type: POST_ACTION_CENTER_REQUEST,
});

const postActionCenterSuccess = payload => ({
  payload,
  type: POST_ACTION_CENTER_SUCCESS,
});

const postActionCenterFailure = payload => ({
  payload,
  type: POST_ACTION_CENTER_FAILURE,
});

export const postActionCenter = params => dispatch => {
  dispatch(postActionCenterRequest());

  return request
    .post('/v1/campaigns/action_centers/', params)
    .then(({ data }) => {
      dispatch(postActionCenterSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postActionCenterFailure(data));

      throw data;
    });
};

export const FETCH_ACTION_CENTER_REQUEST = `${PREFIX} FETCH_ACTION_CENTER_REQUEST`;
export const FETCH_ACTION_CENTER_SUCCESS = `${PREFIX} FETCH_ACTION_CENTER_SUCCESS`;
export const FETCH_ACTION_CENTER_FAILURE = `${PREFIX} FETCH_ACTION_CENTER_FAILURE`;

const fetchActionCenterRequest = () => ({
  type: FETCH_ACTION_CENTER_REQUEST,
});

const fetchActionCenterSuccess = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_SUCCESS,
});

const fetchActionCenterFailure = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_FAILURE,
});

export const fetchActionCenter = uuid => dispatch => {
  dispatch(fetchActionCenterRequest());

  return request
    .get(`/v1/campaigns/action_centers/${uuid}/`)
    .then(({ data }) => {
      dispatch(fetchActionCenterSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchActionCenterFailure(data));

      throw data;
    });
};

export const PATCH_ACTION_CENTER_REQUEST = `${PREFIX} PATCH_ACTION_CENTER_REQUEST`;
export const PATCH_ACTION_CENTER_SUCCESS = `${PREFIX} PATCH_ACTION_CENTER_SUCCESS`;
export const PATCH_ACTION_CENTER_FAILURE = `${PREFIX} PATCH_ACTION_CENTER_FAILURE`;

const patchActionCenterRequest = () => ({
  type: PATCH_ACTION_CENTER_REQUEST,
});

const patchActionCenterSuccess = payload => ({
  payload,
  type: PATCH_ACTION_CENTER_SUCCESS,
});

const patchActionCenterFailure = payload => ({
  payload,
  type: PATCH_ACTION_CENTER_FAILURE,
});

export const patchActionCenter = (uuid, params) => dispatch => {
  dispatch(patchActionCenterRequest());

  return request
    .patch(`/v1/campaigns/action_centers/${uuid}/`, params)
    .then(({ data }) => {
      dispatch(patchActionCenterSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchActionCenterFailure(data));

      throw data;
    });
};

export const COPY_ACTION_CENTER_REQUEST = `${PREFIX} COPY_ACTION_CENTER_REQUEST`;
export const COPY_ACTION_CENTER_SUCCESS = `${PREFIX} COPY_ACTION_CENTER_SUCCESS`;
export const COPY_ACTION_CENTER_FAILURE = `${PREFIX} COPY_ACTION_CENTER_FAILURE`;

const copyActionCenterRequest = () => ({
  type: COPY_ACTION_CENTER_REQUEST,
});

const copyActionCenterSuccess = payload => ({
  payload,
  type: COPY_ACTION_CENTER_SUCCESS,
});

const copyActionCenterFailure = payload => ({
  payload,
  type: COPY_ACTION_CENTER_FAILURE,
});

export const copyActionCenter = (uuid, params) => discopy => {
  discopy(copyActionCenterRequest());

  return request
  .post(`/v1/campaigns/action_centers/${uuid}/duplicate/`, params)
  .then(({ data }) => {
    discopy(copyActionCenterSuccess(data));

    return data;
  })
  .catch(({ response: { data } = {} }) => {
    discopy(copyActionCenterFailure(data));

    throw data;
  });
};

export const FETCH_MATCHED_TARGETS_REQUEST = `${PREFIX} FETCH_MATCHED_TARGETS_REQUEST`;
export const FETCH_MATCHED_TARGETS_SUCCESS = `${PREFIX} FETCH_MATCHED_TARGETS_SUCCESS`;
export const FETCH_MATCHED_TARGETS_FAILURE = `${PREFIX} FETCH_MATCHED_TARGETS_FAILURE`;

const fetchMatchedTargetsRequest = () => ({
  type: FETCH_MATCHED_TARGETS_REQUEST,
});

const fetchMatchedTargetsSuccess = payload => ({
  payload,
  type: FETCH_MATCHED_TARGETS_SUCCESS,
});

const fetchMatchedTargetsFailure = payload => ({
  payload,
  type: FETCH_MATCHED_TARGETS_FAILURE,
});

export const fetchMatchedTargets = (uuid, params) => dispatch => {
  dispatch(fetchMatchedTargetsRequest());

  return request
    .get(`/v1/campaigns/action_centers/${uuid}/match_targets/`, { params })
    .then(({ data }) => {
      dispatch(fetchMatchedTargetsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchMatchedTargetsFailure(data));

      throw data;
    });
};

export const POST_SEND_MESSAGE_REQUEST = `${PREFIX} POST_SEND_MESSAGE_REQUEST`;
export const POST_SEND_MESSAGE_SUCCESS = `${PREFIX} POST_SEND_MESSAGE_SUCCESS`;
export const POST_SEND_MESSAGE_FAILURE = `${PREFIX} POST_SEND_MESSAGE_FAILURE`;

const postSendMessageRequest = () => ({
  type: POST_SEND_MESSAGE_REQUEST,
});

const postSendMessageSuccess = payload => ({
  payload,
  type: POST_SEND_MESSAGE_SUCCESS,
});

const postSendMessageFailure = payload => ({
  payload,
  type: POST_SEND_MESSAGE_FAILURE,
});

export const postSendMessage = (uuid, params) => dispatch => {
  dispatch(postSendMessageRequest());

  return request
    .post(`/v1/campaigns/action_centers/${uuid}/send_message/`, params)
    .then(({ data }) => {
      dispatch(postSendMessageSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postSendMessageFailure(data));

      throw data;
    });
};

export const POST_SEND_ACTION_ALERT_REQUEST = `${PREFIX} POST_SEND_ACTION_ALERT_REQUEST`;
export const POST_SEND_ACTION_ALERT_SUCCESS = `${PREFIX} POST_SEND_ACTION_ALERT_SUCCESS`;
export const POST_SEND_ACTION_ALERT_FAILURE = `${PREFIX} POST_SEND_ACTION_ALERT_FAILURE`;

const postSendActionAlertRequest = () => ({
  type: POST_SEND_ACTION_ALERT_REQUEST,
});

const postSendActionAlertSuccess = payload => ({
  payload,
  type: POST_SEND_ACTION_ALERT_SUCCESS,
});

const postSendActionAlertFailure = payload => ({
  payload,
  type: POST_SEND_ACTION_ALERT_FAILURE,
});

export const postSendActionAlert = (uuid, params) => dispatch => {
  dispatch(postSendActionAlertRequest());

  return request
    .post(`/v1/campaigns/action_alerts/${uuid}/send/`, params)
    .then(({ data }) => {
      dispatch(postSendActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postSendActionAlertFailure(data));

      throw data;
    });
};

export const POST_SEND_PREVIEW_ACTION_ALERT_REQUEST = `${PREFIX} POST_SEND_PREVIEW_ACTION_ALERT_REQUEST`;
export const POST_SEND_PREVIEW_ACTION_ALERT_SUCCESS = `${PREFIX} POST_SEND_PREVIEW_ACTION_ALERT_SUCCESS`;
export const POST_SEND_PREVIEW_ACTION_ALERT_FAILURE = `${PREFIX} POST_SEND_PREVIEW_ACTION_ALERT_FAILURE`;

const postSendPreviewActionAlertRequest = () => ({
  type: POST_SEND_PREVIEW_ACTION_ALERT_REQUEST,
});

const postSendPreviewActionAlertSuccess = payload => ({
  payload,
  type: POST_SEND_PREVIEW_ACTION_ALERT_SUCCESS,
});

const postSendPreviewActionAlertFailure = payload => ({
  payload,
  type: POST_SEND_PREVIEW_ACTION_ALERT_FAILURE,
});

export const postSendPreviewActionAlert = (uuid, params) => dispatch => {
  dispatch(postSendPreviewActionAlertRequest());

  return request
    .post(`/v1/campaigns/action_alerts/${uuid}/send_test/`, params)
    .then(({ data }) => {
      dispatch(postSendPreviewActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postSendPreviewActionAlertFailure(data));

      throw data;
    });
};
export const CLEAR_ACTION_CENTER_DETAILS = `${PREFIX} CLEAR_ACTION_CENTER_DETAILS`;

export const clearActionCenter = () => ({
  type: CLEAR_ACTION_CENTER_DETAILS,
});

export const CLEAR_ACTION_ALERT_DETAILS = `${PREFIX} CLEAR_ACTION_ALERT_DETAILS`;

export const clearActionAlert = () => ({
  type: CLEAR_ACTION_ALERT_DETAILS,
});

export const PATCH_TARGET_REQUEST = `${PREFIX} PATCH_TARGET_REQUEST`;
export const PATCH_TARGET_SUCCESS = `${PREFIX} PATCH_TARGET_SUCCESS`;
export const PATCH_TARGET_FAILURE = `${PREFIX} PATCH_TARGET_FAILURE`;

const patchTargetRequest = () => ({
  type: PATCH_TARGET_REQUEST,
});

const patchTargetSuccess = payload => ({
  payload,
  type: PATCH_TARGET_SUCCESS,
});

const patchTargetFailure = payload => ({
  payload,
  type: PATCH_TARGET_FAILURE,
});

export const patchTarget = (uuid, params) => dispatch => {
  dispatch(patchTargetRequest());

  return request
    .patch(`/v1/campaigns/targets/${uuid}/`, params)
    .then(({ data }) => {
      dispatch(patchTargetSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchTargetFailure(data));

      throw data;
    });
};

export const POST_TARGET_GROUP_REQUEST = `${PREFIX} POST_TARGET_GROUP_REQUEST`;
export const POST_TARGET_GROUP_SUCCESS = `${PREFIX} POST_TARGET_GROUP_SUCCESS`;
export const POST_TARGET_GROUP_FAILURE = `${PREFIX} POST_TARGET_GROUP_FAILURE`;

const postTargetGroupRequest = () => ({
  type: POST_TARGET_GROUP_REQUEST,
});

const postTargetGroupSuccess = payload => ({
  payload,
  type: POST_TARGET_GROUP_SUCCESS,
});

const postTargetGroupFailure = payload => ({
  payload,
  type: POST_TARGET_GROUP_FAILURE,
});

export const postTargetGroup = params => dispatch => {
  dispatch(postTargetGroupRequest());

  return request
    .post('/v1/campaigns/target_groups/', params)
    .then(({ data }) => {
      dispatch(postTargetGroupSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postTargetGroupFailure(data));

      throw data;
    });
};

export const PATCH_TARGET_GROUP_REQUEST = `${PREFIX} PATCH_TARGET_GROUP_REQUEST`;
export const PATCH_TARGET_GROUP_SUCCESS = `${PREFIX} PATCH_TARGET_GROUP_SUCCESS`;
export const PATCH_TARGET_GROUP_FAILURE = `${PREFIX} PATCH_TARGET_GROUP_FAILURE`;

const patchTargetGroupRequest = () => ({
  type: PATCH_TARGET_GROUP_REQUEST,
});

const patchTargetGroupSuccess = payload => ({
  payload,
  type: PATCH_TARGET_GROUP_SUCCESS,
});

const patchTargetGroupFailure = payload => ({
  payload,
  type: PATCH_TARGET_GROUP_FAILURE,
});

export const patchTargetGroup = (targetGroupId, params) => dispatch => {
  dispatch(patchTargetGroupRequest());

  return request
    .patch(`/v1/campaigns/target_groups/${targetGroupId}/`, params)
    .then(({ data }) => {
      dispatch(patchTargetGroupSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchTargetGroupFailure(data));

      throw data;
    });
};

export const DELETE_TARGET_GROUP_REQUEST = `${PREFIX} DELETE_TARGET_GROUP_REQUEST`;
export const DELETE_TARGET_GROUP_SUCCESS = `${PREFIX} DELETE_TARGET_GROUP_SUCCESS`;
export const DELETE_TARGET_GROUP_FAILURE = `${PREFIX} DELETE_TARGET_GROUP_FAILURE`;

const deleteTargetGroupRequest = () => ({
  type: DELETE_TARGET_GROUP_REQUEST,
});

const deleteTargetGroupSuccess = payload => ({
  payload,
  type: DELETE_TARGET_GROUP_SUCCESS,
});

const deleteTargetGroupFailure = payload => ({
  payload,
  type: DELETE_TARGET_GROUP_FAILURE,
});

export const deleteTargetGroup = uuid => dispatch => {
  dispatch(deleteTargetGroupRequest());

  return request
    .delete(`/v1/campaigns/target_groups/${uuid}/`)
    .then(({ data }) => {
      dispatch(deleteTargetGroupSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteTargetGroupFailure(data));

      throw data;
    });
};

export const FETCH_CAMPAIGN_TARGETS_REQUEST = `${PREFIX} FETCH_CAMPAIGN_TARGETS_REQUEST`;
export const FETCH_CAMPAIGN_TARGETS_SUCCESS = `${PREFIX} FETCH_CAMPAIGN_TARGETS_SUCCESS`;
export const FETCH_CAMPAIGN_TARGETS_FAILURE = `${PREFIX} FETCH_CAMPAIGN_TARGETS_FAILURE`;

const fetchCampaignTargetsRequest = () => ({
  type: FETCH_CAMPAIGN_TARGETS_REQUEST,
});

const fetchCampaignTargetsSuccess = payload => ({
  payload,
  type: FETCH_CAMPAIGN_TARGETS_SUCCESS,
});

const fetchCampaignTargetsFailure = payload => ({
  payload,
  type: FETCH_CAMPAIGN_TARGETS_FAILURE,
});

export const fetchCampaignTargets = campaignUuid => dispatch => {
  dispatch(fetchCampaignTargetsRequest());

  return request
    .get(`/v1/campaigns/campaigns/${campaignUuid}/targets/`)
    .then(({ data }) => {
      dispatch(fetchCampaignTargetsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchCampaignTargetsFailure(data));

      throw data;
    });
};

export const FETCH_TARGET_GROUPS_REQUEST = `${PREFIX} FETCH_TARGET_GROUPS_REQUEST`;
export const FETCH_TARGET_GROUPS_SUCCESS = `${PREFIX} FETCH_TARGET_GROUPS_SUCCESS`;
export const FETCH_TARGET_GROUPS_FAILURE = `${PREFIX} FETCH_TARGET_GROUPS_FAILURE`;

const fetchTargetGroupsRequest = () => ({
  type: FETCH_TARGET_GROUPS_REQUEST,
});

const fetchTargetGroupsSuccess = payload => ({
  payload,
  type: FETCH_TARGET_GROUPS_SUCCESS,
});

const fetchTargetGroupsFailure = payload => ({
  payload,
  type: FETCH_TARGET_GROUPS_FAILURE,
});

export const fetchTargetGroups = params => dispatch => {
  dispatch(fetchTargetGroupsRequest());

  return request
    .get('/v1/campaigns/target_groups/', { params })
    .then(({ data }) => {
      dispatch(fetchTargetGroupsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchTargetGroupsFailure(data));

      throw data;
    });
  };

  export const FETCH_TARGET_GROUP_TARGETS_REQUEST = `${PREFIX} FETCH_TARGET_GROUP_TARGETS_REQUEST`;
  export const FETCH_TARGET_GROUP_TARGETS_SUCCESS = `${PREFIX} FETCH_TARGET_GROUP_TARGETS_SUCCESS`;
  export const FETCH_TARGET_GROUP_TARGETS_FAILURE = `${PREFIX} FETCH_TARGET_GROUP_TARGETS_FAILURE`;

  const fetchTargetGroupTargetsRequest = () => ({
    type: FETCH_TARGET_GROUP_TARGETS_REQUEST,
  });

  const fetchTargetGroupTargetsSuccess = payload => ({
    payload,
    type: FETCH_TARGET_GROUP_TARGETS_SUCCESS,
  });

  const fetchTargetGroupTargetsFailure = payload => ({
    payload,
    type: FETCH_TARGET_GROUP_TARGETS_FAILURE,
  });

  export const fetchTargetGroupTargets = targetGroupUuid => dispatch => {
    dispatch(fetchTargetGroupTargetsRequest());

    return request
      .get(`/v1/campaigns/target_groups/${targetGroupUuid}/targets/`)
      .then(({ data }) => {
        dispatch(fetchTargetGroupTargetsSuccess(data));

        return data;
      })
      .catch(({ response: { data } = {} }) => {
        dispatch(fetchTargetGroupTargetsFailure(data));

        throw data;
      });
    };

  export const FETCH_TARGET_GROUP_REQUEST = `${PREFIX} FETCH_TARGET_GROUP_REQUEST`;
  export const FETCH_TARGET_GROUP_SUCCESS = `${PREFIX} FETCH_TARGET_GROUP_SUCCESS`;
  export const FETCH_TARGET_GROUP_FAILURE = `${PREFIX} FETCH_TARGET_GROUP_FAILURE`;

  const fetchTargetGroupRequest = () => ({
    type: FETCH_TARGET_GROUP_REQUEST,
  });

  const fetchTargetGroupSuccess = payload => ({
    payload,
    type: FETCH_TARGET_GROUP_SUCCESS,
  });

  const fetchTargetGroupFailure = payload => ({
    payload,
    type: FETCH_TARGET_GROUP_FAILURE,
  });

  export const fetchTargetGroup = targetGroupUuid => dispatch => {
    dispatch(fetchTargetGroupRequest());

    return request
      .get(`/v1/campaigns/target_groups/${targetGroupUuid}/`)
      .then(({ data }) => {
        dispatch(fetchTargetGroupSuccess(data));

        return data;
      })
      .catch(({ response: { data } = {} }) => {
        dispatch(fetchTargetGroupFailure(data));

        throw data;
      });
    };

export const EXPORT_ACTION_CENTER_REQUEST = `${PREFIX} EXPORT_ACTION_CENTER_REQUEST`;
export const EXPORT_ACTION_CENTER_SUCCESS = `${PREFIX} EXPORT_ACTION_CENTER_SUCCESS`;
export const EXPORT_ACTION_CENTER_FAILURE = `${PREFIX} EXPORT_ACTION_CENTER_FAILURE`;

const exportActionCenterRequest = () => ({
  type: EXPORT_ACTION_CENTER_REQUEST,
});

const exportActionCenterSuccess = payload => ({
  payload,
  type: EXPORT_ACTION_CENTER_SUCCESS,
});

const exportActionCenterFailure = payload => ({
  payload,
  type: EXPORT_ACTION_CENTER_FAILURE,
});

export const exportActionCenter = uuid => dispatch => {
  dispatch(exportActionCenterRequest());

  return request
    .post(`/v1/campaigns/action_centers/${uuid}/excel-report/`)
    .then(({ data }) => {
      dispatch(exportActionCenterSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(exportActionCenterFailure(data));

      throw data;
    });
};

export const FETCH_ACTION_CENTER_TARGETS_REQUEST = `${PREFIX} FETCH_ACTION_CENTER_TARGETS_REQUEST`;
export const FETCH_ACTION_CENTER_TARGETS_SUCCESS = `${PREFIX} FETCH_ACTION_CENTER_TARGETS_SUCCESS`;
export const FETCH_ACTION_CENTER_TARGETS_FAILURE = `${PREFIX} FETCH_ACTION_CENTER_TARGETS_FAILURE`;

const fetchActionCenterTargetsRequest = () => ({
  type: FETCH_ACTION_CENTER_TARGETS_REQUEST,
});

const fetchActionCenterTargetsSuccess = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_TARGETS_SUCCESS,
});

const fetchActionCenterTargetsFailure = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_TARGETS_FAILURE,
});

export const fetchActionCenterTargets = actionCenterUuid => dispatch => {
  dispatch(fetchActionCenterTargetsRequest());

  return request
    .get(`/v1/campaigns/action_centers/${actionCenterUuid}/targets/`)
    .then(({ data }) => {
      dispatch(fetchActionCenterTargetsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchActionCenterTargetsFailure(data));

      throw data;
    });
};

export const EXPORT_CAMPAIGN_REQUEST = `${PREFIX} EXPORT_CAMPAIGN_REQUEST`;
export const EXPORT_CAMPAIGN_SUCCESS = `${PREFIX} EXPORT_CAMPAIGN_SUCCESS`;
export const EXPORT_CAMPAIGN_FAILURE = `${PREFIX} EXPORT_CAMPAIGN_FAILURE`;

const exportCampaignRequest = () => ({
  type: EXPORT_CAMPAIGN_REQUEST,
});

const exportCampaignSuccess = payload => ({
  payload,
  type: EXPORT_CAMPAIGN_SUCCESS,
});

const exportCampaignFailure = payload => ({
  payload,
  type: EXPORT_CAMPAIGN_FAILURE,
});

export const exportCampaign = campaignUuid => dispatch => {
  dispatch(exportCampaignRequest());

  return request
    .post(`/v1/campaigns/campaigns/${campaignUuid}/excel-report/`)
    .then(({ data }) => {
      dispatch(exportCampaignSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(exportCampaignFailure(data));

      throw data;
    });
};

export const FETCH_ACTION_CENTER_ACTIONS_REQUEST = `${PREFIX} FETCH_ACTION_CENTER_ACTIONS_REQUEST`;
export const FETCH_ACTION_CENTER_ACTIONS_SUCCESS = `${PREFIX} FETCH_ACTION_CENTER_ACTIONS_SUCCESS`;
export const FETCH_ACTION_CENTER_ACTIONS_FAILURE = `${PREFIX} FETCH_ACTION_CENTER_ACTIONS_FAILURE`;

const fetchActionCenterActionsRequest = () => ({
  type: FETCH_ACTION_CENTER_ACTIONS_REQUEST,
});

const fetchActionCenterActionsSuccess = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_ACTIONS_SUCCESS,
});

const fetchActionCenterActionsFailure = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_ACTIONS_FAILURE,
});

export const fetchActionCenterActions = uuid => dispatch => {
  dispatch(fetchActionCenterActionsRequest());

  return request
    .get(`/v1/campaigns/action_centers/${uuid}/actions/`)
    .then(({ data }) => {
      dispatch(fetchActionCenterActionsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchActionCenterActionsFailure(data));

      throw data;
    });
};

export const FETCH_ACTION_CENTER_ACTION_DETAIL_REQUEST = `${PREFIX} FETCH_ACTION_CENTER_ACTION_DETAIL_REQUEST`;
export const FETCH_ACTION_CENTER_ACTION_DETAIL_SUCCESS = `${PREFIX} FETCH_ACTION_CENTER_ACTION_DETAIL_SUCCESS`;
export const FETCH_ACTION_CENTER_ACTION_DETAIL_FAILURE = `${PREFIX} FETCH_ACTION_CENTER_ACTION_DETAIL_FAILURE`;

const fetchActionCenterActionDetailRequest = () => ({
  type: FETCH_ACTION_CENTER_ACTION_DETAIL_REQUEST,
});

const fetchActionCenterActionDetailSuccess = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_ACTION_DETAIL_SUCCESS,
});

const fetchActionCenterActionDetailFailure = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_ACTION_DETAIL_FAILURE,
});

export const fetchActionCenterActionDetail = (actionCenterUuid, action) => dispatch => {
  dispatch(fetchActionCenterActionDetailRequest());

  return request
    .get(`/v1/campaigns/action_centers/${actionCenterUuid}/actions/${action.type}/${action.uuid}/`)
    .then(({ data }) => {
      dispatch(fetchActionCenterActionDetailSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchActionCenterActionDetailFailure(data));

      throw data;
    });
};

export const PATCH_ACTION_CENTER_ACTION_DETAIL_REQUEST = `${PREFIX} PATCH_ACTION_CENTER_ACTION_DETAIL_REQUEST`;
export const PATCH_ACTION_CENTER_ACTION_DETAIL_SUCCESS = `${PREFIX} PATCH_ACTION_CENTER_ACTION_DETAIL_SUCCESS`;
export const PATCH_ACTION_CENTER_ACTION_DETAIL_FAILURE = `${PREFIX} PATCH_ACTION_CENTER_ACTION_DETAIL_FAILURE`;

const patchActionCenterActionDetailRequest = () => ({
  type: PATCH_ACTION_CENTER_ACTION_DETAIL_REQUEST,
});

const patchActionCenterActionDetailSuccess = payload => ({
  payload,
  type: PATCH_ACTION_CENTER_ACTION_DETAIL_SUCCESS,
});

const patchActionCenterActionDetailFailure = payload => ({
  payload,
  type: PATCH_ACTION_CENTER_ACTION_DETAIL_FAILURE,
});

export const patchActionCenterActionDetail = (actionCenterUuid, action, params) => dispatch => {
  dispatch(patchActionCenterActionDetailRequest());

  return request
    .patch(`/v1/campaigns/action_centers/${actionCenterUuid}/actions/${action.type}/${action.uuid}/`, params)
    .then(({ data }) => {
      dispatch(patchActionCenterActionDetailSuccess({
        ...data,
        uuid: action.uuid,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchActionCenterActionDetailFailure(data));

      throw data;
    });
};

export const FETCH_ACTION_CENTER_SIGNUPS_REQUEST = `${PREFIX} FETCH_ACTION_CENTER_SIGNUPS_REQUEST`;
export const FETCH_ACTION_CENTER_SIGNUPS_SUCCESS = `${PREFIX} FETCH_ACTION_CENTER_SIGNUPS_SUCCESS`;
export const FETCH_ACTION_CENTER_SIGNUPS_FAILURE = `${PREFIX} FETCH_ACTION_CENTER_SIGNUPS_FAILURE`;

const fetchActionCenterSignupsRequest = () => ({
  type: FETCH_ACTION_CENTER_SIGNUPS_REQUEST,
});

const fetchActionCenterSignupsSuccess = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_SIGNUPS_SUCCESS,
});

const fetchActionCenterSignupsFailure = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_SIGNUPS_FAILURE,
});

export const fetchActionCenterSignups = uuid => dispatch => {
  dispatch(fetchActionCenterSignupsRequest());

  return request
    .get(`/v1/campaigns/action_centers/${uuid}/signups/`)
    .then(({ data }) => {
      dispatch(fetchActionCenterSignupsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchActionCenterSignupsFailure(data));

      throw data;
    });
};

export const FETCH_ADVOCATES_REQUEST = `${PREFIX} FETCH_ADVOCATES_REQUEST`;
export const FETCH_ADVOCATES_SUCCESS = `${PREFIX} FETCH_ADVOCATES_SUCCESS`;
export const FETCH_ADVOCATES_FAILURE = `${PREFIX} FETCH_ADVOCATES_FAILURE`;

const fetchAdvocatesRequest = () => ({
  type: FETCH_ADVOCATES_REQUEST,
});

const fetchAdvocatesSuccess = payload => ({
  payload,
  type: FETCH_ADVOCATES_SUCCESS,
});

const fetchAdvocatesFailure = payload => ({
  payload,
  type: FETCH_ADVOCATES_FAILURE,
});

export const fetchAdvocates = uuid => dispatch => {
  dispatch(fetchAdvocatesRequest());
  const advocates = [];

  const fetchAdvocatesWithPagination = next => request
    .get(next || `/v1/campaigns/campaigns/${uuid}/advocates/`)
    .then(({ data }) => {
      advocates.push(...data.results);

      if (!data.next) {
        return advocates;
      }

      return fetchAdvocatesWithPagination(data.next);
    });

  return fetchAdvocatesWithPagination()
    .then(data => {
      dispatch(fetchAdvocatesSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchAdvocatesFailure(data));

      throw data;
    });
};

export const EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_REQUEST = `${PREFIX} EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_REQUEST`;
export const EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_SUCCESS = `${PREFIX} EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_SUCCESS`;
export const EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_FAILURE = `${PREFIX} EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_FAILURE`;

const exportMessagesReportForActionCenterRequest = () => ({
  type: EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_REQUEST,
});

const exportMessagesReportForActionCenterSuccess = payload => ({
  payload,
  type: EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_SUCCESS,
});

const exportMessagesReportForActionCenterFailure = payload => ({
  payload,
  type: EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_FAILURE,
});

export const exportMessagesReportForActionCenter = uuid => dispatch => {
  dispatch(exportMessagesReportForActionCenterRequest());

  return request
    .post(`/v1/campaigns/action_centers/${uuid}/messages-report/`)
    .then(({ data }) => {
      dispatch(exportMessagesReportForActionCenterSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(exportMessagesReportForActionCenterFailure(data));

      throw data;
    });
};

export const EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_REQUEST = `${PREFIX} EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_REQUEST`;
export const EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_SUCCESS = `${PREFIX} EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_SUCCESS`;
export const EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_FAILURE = `${PREFIX} EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_FAILURE`;

const exportMessagesReportForCampaignRequest = () => ({
  type: EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_REQUEST,
});

const exportMessagesReportForCampaignSuccess = payload => ({
  payload,
  type: EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_SUCCESS,
});

const exportMessagesReportForCampaignFailure = payload => ({
  payload,
  type: EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_FAILURE,
});

export const exportMessagesReportForCampaign = uuid => dispatch => {
  dispatch(exportMessagesReportForCampaignRequest());

  return request
    .post(`/v1/campaigns/campaigns/${uuid}/messages-report/`)
    .then(({ data }) => {
      dispatch(exportMessagesReportForCampaignSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(exportMessagesReportForCampaignFailure(data));

      throw data;
    });
};

export const STOP_ACTION_ALERT_REQUEST = `${PREFIX} STOP_ACTION_ALERT_REQUEST`;
export const STOP_ACTION_ALERT_SUCCESS = `${PREFIX} STOP_ACTION_ALERT_SUCCESS`;
export const STOP_ACTION_ALERT_FAILURE = `${PREFIX} STOP_ACTION_ALERT_FAILURE`;

const stopActionAlertRequest = () => ({
  type: STOP_ACTION_ALERT_REQUEST,
});

const stopActionAlertSuccess = payload => ({
  payload,
  type: STOP_ACTION_ALERT_SUCCESS,
});

const stopActionAlertFailure = payload => ({
  payload,
  type: STOP_ACTION_ALERT_FAILURE,
});

export const stopActionAlert = uuid => dispatch => {
  dispatch(stopActionAlertRequest());

  return request
    .post(`/v1/campaigns/action_alerts/${uuid}/cancel/`)
    .then(({ data }) => {
      dispatch(stopActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(stopActionAlertFailure(data));

      throw data;
    });
};

export const POST_CIRCLE_BACK_REQUEST = `${PREFIX} POST_CIRCLE_BACK_REQUEST`;
export const POST_CIRCLE_BACK_SUCCESS = `${PREFIX} POST_CIRCLE_BACK_SUCCESS`;
export const POST_CIRCLE_BACK_FAILURE = `${PREFIX} POST_CIRCLE_BACK_FAILURE`;

const postCircleBackRequest = () => ({
  type: POST_CIRCLE_BACK_REQUEST,
});

const postCircleBackSuccess = payload => ({
  payload,
  type: POST_CIRCLE_BACK_SUCCESS,
});

const postCircleBackFailure = payload => ({
  payload,
  type: POST_CIRCLE_BACK_FAILURE,
});

export const postCircleBack = (uuid, params) => dispatch => {
  dispatch(postCircleBackRequest());

  return request
    .post(`/v1/campaigns/action_alerts/${uuid}/circle_back/`, params)
    .then(({ data }) => {
      dispatch(postCircleBackSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postCircleBackFailure(data));

      throw data;
    });
};

export const FETCH_ACTION_ALERT_RECIPIENTS_REQUEST = `${PREFIX} FETCH_ACTION_ALERT_RECIPIENTS_REQUEST`;
export const FETCH_ACTION_ALERT_RECIPIENTS_SUCCESS = `${PREFIX} FETCH_ACTION_ALERT_RECIPIENTS_SUCCESS`;
export const FETCH_ACTION_ALERT_RECIPIENTS_FAILURE = `${PREFIX} FETCH_ACTION_ALERT_RECIPIENTS_FAILURE`;

const fetchActionAlertRecipientsRequest = () => ({
  type: FETCH_ACTION_ALERT_RECIPIENTS_REQUEST,
});

const fetchActionAlertRecipientsSuccess = payload => ({
  payload,
  type: FETCH_ACTION_ALERT_RECIPIENTS_SUCCESS,
});

const fetchActionAlertRecipientsFailure = payload => ({
  payload,
  type: FETCH_ACTION_ALERT_RECIPIENTS_FAILURE,
});

export const fetchActionAlertRecipients = actionAlertUuid => fetchAllPagedData(
  `/v1/campaigns/action_alerts/${actionAlertUuid}/recipients/`,
  fetchActionAlertRecipientsRequest,
  fetchActionAlertRecipientsSuccess,
  fetchActionAlertRecipientsFailure
);

export const FETCH_ACTION_ALERT_LINKS_REQUEST = `${PREFIX} FETCH_ACTION_ALERT_LINKS_REQUEST`;
export const FETCH_ACTION_ALERT_LINKS_SUCCESS = `${PREFIX} FETCH_ACTION_ALERT_LINKS_SUCCESS`;
export const FETCH_ACTION_ALERT_LINKS_FAILURE = `${PREFIX} FETCH_ACTION_ALERT_LINKS_FAILURE`;

const fetchActionAlertLinksRequest = () => ({
  type: FETCH_ACTION_ALERT_LINKS_REQUEST,
});

const fetchActionAlertLinksSuccess = payload => ({
  payload,
  type: FETCH_ACTION_ALERT_LINKS_SUCCESS,
});

const fetchActionAlertLinksFailure = payload => ({
  payload,
  type: FETCH_ACTION_ALERT_LINKS_FAILURE,
});

export const fetchActionAlertLinks = actionAlertUuid => dispatch => {
  dispatch(fetchActionAlertLinksRequest());

  return request
    .get(`/v1/campaigns/action_alerts/${actionAlertUuid}/link_clicks/`)
    .then(({ data }) => {
      dispatch(fetchActionAlertLinksSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchActionAlertLinksFailure(data));

      throw data;
    });
};

export const PATCH_CAMPAIGN_REQUEST = `${PREFIX} PATCH_CAMPAIGN_REQUEST`;
export const PATCH_CAMPAIGN_SUCCESS = `${PREFIX} PATCH_CAMPAIGN_SUCCESS`;
export const PATCH_CAMPAIGN_FAILURE = `${PREFIX} PATCH_CAMPAIGN_FAILURE`;

const patchCampaignRequest = () => ({
  type: PATCH_CAMPAIGN_REQUEST,
});

const patchCampaignSuccess = payload => ({
  payload,
  type: PATCH_CAMPAIGN_SUCCESS,
});

const patchCampaignFailure = payload => ({
  payload,
  type: PATCH_CAMPAIGN_FAILURE,
});

export const patchCampaign = (uuid, params) => dispatch => {
  dispatch(patchCampaignRequest());

  return request
    .patch(`/v1/campaigns/campaigns/${uuid}/`, params)
    .then(({ data }) => {
      dispatch(patchCampaignSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchCampaignFailure(data));

      throw data;
    });
};

export const DELETE_ACTION_CENTER_REQUEST = `${PREFIX} DELETE_ACTION_CENTER_REQUEST`;
export const DELETE_ACTION_CENTER_SUCCESS = `${PREFIX} DELETE_ACTION_CENTER_SUCCESS`;
export const DELETE_ACTION_CENTER_FAILURE = `${PREFIX} DELETE_ACTION_CENTER_FAILURE`;

const deleteActionCenterRequest = () => ({
  type: DELETE_ACTION_CENTER_REQUEST,
});

const deleteActionCenterSuccess = payload => ({
  payload,
  type: DELETE_ACTION_CENTER_SUCCESS,
});

const deleteActionCenterFailure = payload => ({
  payload,
  type: DELETE_ACTION_CENTER_FAILURE,
});

export const deleteActionCenter = uuid => dispatch => {
  dispatch(deleteActionCenterRequest());

  return request
    .delete(`/v1/campaigns/action_centers/${uuid}/`)
    .then(({ data }) => {
      dispatch(deleteActionCenterSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteActionCenterFailure(data));

      throw data;
    });
};

export const DELETE_CAMPAIGN_REQUEST = `${PREFIX} DELETE_CAMPAIGN_REQUEST`;
export const DELETE_CAMPAIGN_SUCCESS = `${PREFIX} DELETE_CAMPAIGN_SUCCESS`;
export const DELETE_CAMPAIGN_FAILURE = `${PREFIX} DELETE_CAMPAIGN_FAILURE`;

const deleteCampaignRequest = () => ({
  type: DELETE_CAMPAIGN_REQUEST,
});

const deleteCampaignSuccess = payload => ({
  payload,
  type: DELETE_CAMPAIGN_SUCCESS,
});

const deleteCampaignFailure = payload => ({
  payload,
  type: DELETE_CAMPAIGN_FAILURE,
});

export const deleteCampaign = uuid => dispatch => {
  dispatch(deleteCampaignRequest());

  return request
    .delete(`/v1/campaigns/campaigns/${uuid}/`)
    .then(({ data }) => {
      dispatch(deleteCampaignSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteCampaignFailure(data));

      throw data;
    });
};

export const POST_TEXT_ACTION_ALERT_REQUEST = `${PREFIX} POST_TEXT_ACTION_ALERT_REQUEST`;
export const POST_TEXT_ACTION_ALERT_SUCCESS = `${PREFIX} POST_TEXT_ACTION_ALERT_SUCCESS`;
export const POST_TEXT_ACTION_ALERT_FAILURE = `${PREFIX} POST_TEXT_ACTION_ALERT_FAILURE`;

const postTextActionAlertRequest = () => ({
  type: POST_TEXT_ACTION_ALERT_REQUEST,
});

const postTextActionAlertSuccess = payload => ({
  payload,
  type: POST_TEXT_ACTION_ALERT_SUCCESS,
});

const postTextActionAlertFailure = payload => ({
  payload,
  type: POST_TEXT_ACTION_ALERT_FAILURE,
});

export const postTextActionAlert = params => dispatch => {
  dispatch(postTextActionAlertRequest());

  return request
    .post('/v1/campaigns/text_alerts/', params)
    .then(({ data }) => {
      dispatch(postTextActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postTextActionAlertFailure(data));

      throw data;
    });
};

export const PATCH_TEXT_ACTION_ALERT_REQUEST = `${PREFIX} PATCH_TEXT_ACTION_ALERT_REQUEST`;
export const PATCH_TEXT_ACTION_ALERT_SUCCESS = `${PREFIX} PATCH_TEXT_ACTION_ALERT_SUCCESS`;
export const PATCH_TEXT_ACTION_ALERT_FAILURE = `${PREFIX} PATCH_TEXT_ACTION_ALERT_FAILURE`;

const patchTextActionAlertRequest = () => ({
  type: PATCH_TEXT_ACTION_ALERT_REQUEST,
});

const patchTextActionAlertSuccess = payload => ({
  payload,
  type: PATCH_TEXT_ACTION_ALERT_SUCCESS,
});

const patchTextActionAlertFailure = payload => ({
  payload,
  type: PATCH_TEXT_ACTION_ALERT_FAILURE,
});

export const patchTextActionAlert = (uuid, params) => dispatch => {
  dispatch(patchTextActionAlertRequest());

  return request
    .patch(`/v1/campaigns/text_alerts/${uuid}/`, params)
    .then(({ data }) => {
      dispatch(patchTextActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchTextActionAlertFailure(data));

      throw data;
    });
};

export const POST_SEND_PREVIEW_TEXT_ACTION_ALERT_REQUEST = `${PREFIX} POST_SEND_PREVIEW_TEXT_ACTION_ALERT_REQUEST`;
export const POST_SEND_PREVIEW_TEXT_ACTION_ALERT_SUCCESS = `${PREFIX} POST_SEND_PREVIEW_TEXT_ACTION_ALERT_SUCCESS`;
export const POST_SEND_PREVIEW_TEXT_ACTION_ALERT_FAILURE = `${PREFIX} POST_SEND_PREVIEW_TEXT_ACTION_ALERT_FAILURE`;

const postSendPreviewTextActionAlertRequest = () => ({
  type: POST_SEND_PREVIEW_TEXT_ACTION_ALERT_REQUEST,
});

const postSendPreviewTextActionAlertSuccess = payload => ({
  payload,
  type: POST_SEND_PREVIEW_TEXT_ACTION_ALERT_SUCCESS,
});

const postSendPreviewTextActionAlertFailure = payload => ({
  payload,
  type: POST_SEND_PREVIEW_TEXT_ACTION_ALERT_FAILURE,
});

export const postSendPreviewTextActionAlert = (uuid, params) => dispatch => {
  dispatch(postSendPreviewTextActionAlertRequest());

  return request
    .post(`/v1/campaigns/text_alerts/${uuid}/send_test/`, params)
    .then(({ data }) => {
      dispatch(postSendPreviewTextActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postSendPreviewTextActionAlertFailure(data));

      throw data;
    });
};

export const POST_SEND_TEXT_MESSAGE_REQUEST = `${PREFIX} POST_SEND_TEXT_MESSAGE_REQUEST`;
export const POST_SEND_TEXT_MESSAGE_SUCCESS = `${PREFIX} POST_SEND_TEXT_MESSAGE_SUCCESS`;
export const POST_SEND_TEXT_MESSAGE_FAILURE = `${PREFIX} POST_SEND_TEXT_MESSAGE_FAILURE`;

const postSendTextActionAlertRequest = () => ({
  type: POST_SEND_TEXT_MESSAGE_REQUEST,
});

const postSendTextActionAlertSuccess = payload => ({
  payload,
  type: POST_SEND_TEXT_MESSAGE_SUCCESS,
});

const postSendTextActionAlertFailure = payload => ({
  payload,
  type: POST_SEND_TEXT_MESSAGE_FAILURE,
});

export const postSendTextActionAlert = (uuid, params) => dispatch => {
  dispatch(postSendTextActionAlertRequest());

  return request
    .post(`/v1/campaigns/text_alerts/${uuid}/send/`, params)
    .then(({ data }) => {
      dispatch(postSendTextActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postSendTextActionAlertFailure(data));

      throw data;
    });
};

export const FETCH_TEXT_ACTION_ALERT_REQUEST = `${PREFIX} FETCH_TEXT_ACTION_ALERT_REQUEST`;
export const FETCH_TEXT_ACTION_ALERT_SUCCESS = `${PREFIX} FETCH_TEXT_ACTION_ALERT_SUCCESS`;
export const FETCH_TEXT_ACTION_ALERT_FAILURE = `${PREFIX} FETCH_TEXT_ACTION_ALERT_FAILURE`;

const fetchTextActionAlertRequest = () => ({
  type: FETCH_TEXT_ACTION_ALERT_REQUEST,
});

const fetchTextActionAlertSuccess = payload => ({
  payload,
  type: FETCH_TEXT_ACTION_ALERT_SUCCESS,
});

const fetchTextActionAlertFailure = payload => ({
  payload,
  type: FETCH_TEXT_ACTION_ALERT_FAILURE,
});

export const fetchTextActionAlert = uuid => dispatch => {
  dispatch(fetchTextActionAlertRequest());

  return request
    .get(`/v1/campaigns/text_alerts/${uuid}/`)
    .then(({ data }) => {
      dispatch(fetchTextActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchTextActionAlertFailure(data));

      throw data;
    });
};

export const STOP_TEXT_ACTION_ALERT_REQUEST = `${PREFIX} STOP_TEXT_ACTION_ALERT_REQUEST`;
export const STOP_TEXT_ACTION_ALERT_SUCCESS = `${PREFIX} STOP_TEXT_ACTION_ALERT_SUCCESS`;
export const STOP_TEXT_ACTION_ALERT_FAILURE = `${PREFIX} STOP_TEXT_ACTION_ALERT_FAILURE`;

const stopTextActionAlertRequest = () => ({
  type: STOP_TEXT_ACTION_ALERT_REQUEST,
});

const stopTextActionAlertSuccess = payload => ({
  payload,
  type: STOP_TEXT_ACTION_ALERT_SUCCESS,
});

const stopTextActionAlertFailure = payload => ({
  payload,
  type: STOP_TEXT_ACTION_ALERT_FAILURE,
});

export const stopTextActionAlert = uuid => dispatch => {
  dispatch(stopTextActionAlertRequest());

  return request
    .post(`/v1/campaigns/text_alerts/${uuid}/cancel/`)
    .then(({ data }) => {
      dispatch(stopTextActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(stopTextActionAlertFailure(data));

      throw data;
    });
};

export const FETCH_TEXT_ACTION_ALERT_RECIPIENTS_REQUEST = `${PREFIX} FETCH_TEXT_ACTION_ALERT_RECIPIENTS_REQUEST`;
export const FETCH_TEXT_ACTION_ALERT_RECIPIENTS_SUCCESS = `${PREFIX} FETCH_TEXT_ACTION_ALERT_RECIPIENTS_SUCCESS`;
export const FETCH_TEXT_ACTION_ALERT_RECIPIENTS_FAILURE = `${PREFIX} FETCH_TEXT_ACTION_ALERT_RECIPIENTS_FAILURE`;

const fetchTextActionAlertRecipientsRequest = () => ({
  type: FETCH_TEXT_ACTION_ALERT_RECIPIENTS_REQUEST,
});

const fetchTextActionAlertRecipientsSuccess = payload => ({
  payload,
  type: FETCH_TEXT_ACTION_ALERT_RECIPIENTS_SUCCESS,
});

const fetchTextActionAlertRecipientsFailure = payload => ({
  payload,
  type: FETCH_TEXT_ACTION_ALERT_RECIPIENTS_FAILURE,
});

export const fetchTextActionAlertRecipients = actionAlertUuid => fetchAllPagedData(
  `/v1/campaigns/text_alerts/${actionAlertUuid}/recipients/`,
  fetchTextActionAlertRecipientsRequest,
  fetchTextActionAlertRecipientsSuccess,
  fetchTextActionAlertRecipientsFailure
);

export const DELETE_TEXT_ACTION_ALERT_REQUEST = `${PREFIX} DELETE_TEXT_ACTION_ALERT_REQUEST`;
export const DELETE_TEXT_ACTION_ALERT_SUCCESS = `${PREFIX} DELETE_TEXT_ACTION_ALERT_SUCCESS`;
export const DELETE_TEXT_ACTION_ALERT_FAILURE = `${PREFIX} DELETE_TEXT_ACTION_ALERT_FAILURE`;

const deleteTextActionAlertRequest = () => ({
  type: DELETE_TEXT_ACTION_ALERT_REQUEST,
});

const deleteTextActionAlertSuccess = payload => ({
  payload,
  type: DELETE_TEXT_ACTION_ALERT_SUCCESS,
});

const deleteTextActionAlertFailure = payload => ({
  payload,
  type: DELETE_TEXT_ACTION_ALERT_FAILURE,
});

export const deleteTextActionAlert = uuid => dispatch => {
  dispatch(deleteTextActionAlertRequest());

  return request
    .delete(`/v1/campaigns/text_alerts/${uuid}/`)
    .then(({ data }) => {
      dispatch(deleteTextActionAlertSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteTextActionAlertFailure(data));

      throw data;
    });
};

export const FETCH_ALL_ACTION_ALERTS_REQUEST = `${PREFIX} FETCH_ALL_ACTION_ALERTS_REQUEST`;
export const FETCH_ALL_ACTION_ALERTS_SUCCESS = `${PREFIX} FETCH_ALL_ACTION_ALERTS_SUCCESS`;
export const FETCH_ALL_ACTION_ALERTS_FAILURE = `${PREFIX} FETCH_ALL_ACTION_ALERTS_FAILURE`;

const fetchAllActionAlertsRequest = () => ({
  type: FETCH_ALL_ACTION_ALERTS_REQUEST,
});

const fetchAllActionAlertsSuccess = payload => ({
  payload,
  type: FETCH_ALL_ACTION_ALERTS_SUCCESS,
});

const fetchAllActionAlertsFailure = payload => ({
  payload,
  type: FETCH_ALL_ACTION_ALERTS_FAILURE,
});

export const fetchAllActionAlerts = params => dispatch => {
  dispatch(fetchAllActionAlertsRequest());

  return request
    .get('/v1/campaigns/alerts/all/', { params })
    .then(({ data }) => {
      dispatch(fetchAllActionAlertsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchAllActionAlertsFailure(data));

      throw data;
    });
};
