import request from '../../utils/request';

const PREFIX = '[CUSTOM_FIELD]';

export const POST_CUSTOM_FIELD_REQUEST = `${PREFIX} POST_CUSTOM_FIELD_REQUEST`;
export const POST_CUSTOM_FIELD_SUCCESS = `${PREFIX} POST_CUSTOM_FIELD_SUCCESS`;
export const POST_CUSTOM_FIELD_FAILURE = `${PREFIX} POST_CUSTOM_FIELD_FAILURE`;

const postCustomFieldRequest = () => ({
  type: POST_CUSTOM_FIELD_REQUEST,
});

const postCustomFieldSuccess = payload => ({
  payload,
  type: POST_CUSTOM_FIELD_SUCCESS,
});

const postCustomFieldFailure = payload => ({
  payload,
  type: POST_CUSTOM_FIELD_FAILURE,
});

export const postCustomField = (id, params) => dispatch => {
  dispatch(postCustomFieldRequest());

  return request
    .post(`/v1/contacts/lists/${id}/custom_fields/`, params)
    .then(({ data }) => {
      dispatch(postCustomFieldSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postCustomFieldFailure(data));

      throw data;
    });
};

export const FETCH_CUSTOM_FIELDS_REQUEST = `${PREFIX} FETCH_CUSTOM_FIELDS_REQUEST`;
export const FETCH_CUSTOM_FIELDS_SUCCESS = `${PREFIX} FETCH_CUSTOM_FIELDS_SUCCESS`;
export const FETCH_CUSTOM_FIELDS_FAILURE = `${PREFIX} FETCH_CUSTOM_FIELDS_FAILURE`;

const fetchCustomFieldsRequest = () => ({
  type: FETCH_CUSTOM_FIELDS_REQUEST,
});

const fetchCustomFieldsSuccess = payload => ({
  payload,
  type: FETCH_CUSTOM_FIELDS_SUCCESS,
});

const fetchCustomFieldsFailure = payload => ({
  payload,
  type: FETCH_CUSTOM_FIELDS_FAILURE,
});

export const fetchCustomFields = id => dispatch => {
  dispatch(fetchCustomFieldsRequest());

  return request
    .get(`/v1/contacts/lists/${id}/custom_fields/`)
    .then(({ data }) => {
      dispatch(fetchCustomFieldsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchCustomFieldsFailure(data));

      throw data;
    });
};

export const DELETE_CUSTOM_FIELD_REQUEST = `${PREFIX} DELETE_CUSTOM_FIELD_REQUEST`;
export const DELETE_CUSTOM_FIELD_SUCCESS = `${PREFIX} DELETE_CUSTOM_FIELD_SUCCESS`;
export const DELETE_CUSTOM_FIELD_FAILURE = `${PREFIX} DELETE_CUSTOM_FIELD_FAILURE`;

const deleteCustomFieldRequest = () => ({
  type: DELETE_CUSTOM_FIELD_REQUEST,
});

const deleteCustomFieldSuccess = payload => ({
  payload,
  type: DELETE_CUSTOM_FIELD_SUCCESS,
});

const deleteCustomFieldFailure = payload => ({
  payload,
  type: DELETE_CUSTOM_FIELD_FAILURE,
});

export const deleteCustomField = (listUuid, id) => dispatch => {
  dispatch(deleteCustomFieldRequest());

  return request
    .delete(`/v1/contacts/lists/${listUuid}/custom_fields/${id}/`)
    .then(() => {
      dispatch(deleteCustomFieldSuccess(id));
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteCustomFieldFailure(data));

      throw data;
    });
};

export const FETCH_CUSTOM_FIELD_REQUEST = `${PREFIX} FETCH_CUSTOM_FIELD_REQUEST`;
export const FETCH_CUSTOM_FIELD_SUCCESS = `${PREFIX} FETCH_CUSTOM_FIELD_SUCCESS`;
export const FETCH_CUSTOM_FIELD_FAILURE = `${PREFIX} FETCH_CUSTOM_FIELD_FAILURE`;

const fetchCustomFieldRequest = () => ({
  type: FETCH_CUSTOM_FIELD_REQUEST,
});

const fetchCustomFieldSuccess = payload => ({
  payload,
  type: FETCH_CUSTOM_FIELD_SUCCESS,
});

const fetchCustomFieldFailure = payload => ({
  payload,
  type: FETCH_CUSTOM_FIELD_FAILURE,
});

export const fetchCustomField = (listUuid, customFieldUuid) => dispatch => {
  dispatch(fetchCustomFieldRequest());

  return request
    .get(`/v1/contacts/lists/${listUuid}/custom_fields/${customFieldUuid}/`)
    .then(({ data }) => {
      dispatch(fetchCustomFieldSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchCustomFieldFailure(data));

      throw data;
    });
};

export const PATCH_CUSTOM_FIELD_REQUEST = `${PREFIX} PATCH_CUSTOM_FIELD_REQUEST`;
export const PATCH_CUSTOM_FIELD_SUCCESS = `${PREFIX} PATCH_CUSTOM_FIELD_SUCCESS`;
export const PATCH_CUSTOM_FIELD_FAILURE = `${PREFIX} PATCH_CUSTOM_FIELD_FAILURE`;

const patchCustomFieldRequest = () => ({
  type: PATCH_CUSTOM_FIELD_REQUEST,
});

const patchCustomFieldSuccess = payload => ({
  payload,
  type: PATCH_CUSTOM_FIELD_SUCCESS,
});

const patchCustomFieldFailure = payload => ({
  payload,
  type: PATCH_CUSTOM_FIELD_FAILURE,
});

export const patchCustomField = (listUuid, customFieldUuid, params) => dispatch => {
  dispatch(patchCustomFieldRequest());

  return request
    .put(`/v1/contacts/lists/${listUuid}/custom_fields/${customFieldUuid}/`, params)
    .then(({ data }) => {
      dispatch(patchCustomFieldSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchCustomFieldFailure(data));

      throw data;
    });
};
