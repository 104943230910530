import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  SentimentDissatisfiedOutlined as DissatisfiedIcon,
  WarningOutlined as ErrorIcon,
} from '@mui/icons-material';

import CardField from 'common/components/CardField';
import { contactListType } from 'common/typings';
import { isEmail } from 'utils/helpers';

const CardFieldWrapper = styled.div`
  margin-top: 30px;
`;

const IconWrapper = styled.div`
  width: 44px;
  margin-top: 20px;
`;

const emailStates = {
  missing: 'missing',
  none: 'none',
  verified: 'verified',
  warning: 'warning',
};

const StepOne = ({
  emailDomainData,
  emailError,
  errorMessage,
  isCircleBackActionAlert,
  lists,
  listsValue,
  nameValue,
  senderEmailValue,
  senderNameValue,
  setEmailError,
  setFormValues,
  subjectValue,
}) => {
  const [
    validateDomainText,
    setValidateDomainText,
  ] = useState('');
  const [
    emailState,
    setEmailState,
  ] = useState(emailStates.none);
  const {
    domain,
    valid,
  } = emailDomainData;

  const handleValidateDomain = email => {
    const domainName = email.split('@')[1];

    if (!domain || !valid) {
      setValidateDomainText('Unless you add and verify a sending domain, this email will be sent from a "mustermail.com" email address. Please see the "Email" tab in your account settings.');
      setEmailState(emailStates.missing);
    } else if (domainName.toLowerCase() !== domain.toLowerCase()) {
      setValidateDomainText(`"${domainName}" is not your sending domain! Please use an address at "${domain}" or your email will be sent from a "mustermail.com" address.`);
      setEmailState(emailStates.warning);
    } else {
      setValidateDomainText('');
      setEmailState(emailStates.verified);
    }
  };

  const handleBlur = email => {
    if (!isEmail(email)) {
      setEmailError(true);
    } else {
      handleValidateDomain(email);
    }
  };

  const handleFormValuesChange = (field, event) => {
    const { value } = event.target;

    setFormValues(prevValues => ({
      ...prevValues,
      [field]: value,
    }));
  };

  return (
    <>
      <CardFieldWrapper>
        <CardField name="Name of Email Action Alert">
          <TextField
            fullWidth
            label="Name of Email Action Alert"
            onChange={event => handleFormValuesChange('nameValue', event)}
            type="text"
            value={nameValue}
            variant="standard"
          />
        </CardField>
      </CardFieldWrapper>
      <CardFieldWrapper>
        <CardField
          name="Sender"
          topAlign
        >
          <TextField
            fullWidth
            label="Name"
            onChange={event => handleFormValuesChange('senderNameValue', event)}
            type="text"
            value={senderNameValue}
            variant="standard"
          />
          <TextField
            fullWidth
            label="Email"
            onChange={event => {
              handleFormValuesChange('senderEmailValue', event);
            }}
            onFocus={() => {
              setEmailError(false);
              setValidateDomainText('');
              setEmailState(emailStates.none);
            }}
            onBlur={event => handleBlur(event.target.value)}
            type="email"
            value={senderEmailValue}
            error={emailError}
            helperText={(emailError && 'Not a valid email address!') || validateDomainText}
            variant="standard"
          />
          <IconWrapper>
            {emailState === emailStates.warning && <ErrorIcon />}
            {emailState === emailStates.missing && <DissatisfiedIcon />}
            {emailState === emailStates.verified && <CheckCircleOutlineIcon color="secondary" />}
          </IconWrapper>
        </CardField>
      </CardFieldWrapper>
      <CardFieldWrapper>
        <CardField name="Contacts">
          <Autocomplete
            disabled={isCircleBackActionAlert}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) => (value ? option.name === value.name : true)}
            fullWidth
            multiple
            onChange={(_, list) => setFormValues(prevValues => ({
              ...prevValues,
              listsValue: list,
            }))}
            options={lists}
            renderInput={params => (
              <TextField
                {...params}
                label="Contacts"
                variant="standard"
              />
            )}
            value={listsValue}
          />
        </CardField>
      </CardFieldWrapper>
      <CardFieldWrapper>
        <CardField name="Subject">
          <TextField
            error={errorMessage && !!errorMessage.subject}
            fullWidth
            helperText={errorMessage?.subject && errorMessage.subject[0]}
            label="Subject"
            onChange={event => handleFormValuesChange('subjectValue', event)}
            type="text"
            value={subjectValue}
            variant="standard"
          />
        </CardField>
      </CardFieldWrapper>
    </>
  );
};

StepOne.defaultProps = {
  emailDomainData: {},
  errorMessage: null,
};

StepOne.propTypes = {
  emailDomainData: PropTypes.shape({
    domain: PropTypes.string,
    valid: PropTypes.bool,
  }),
  emailError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.shape({
    subject: PropTypes.arrayOf(PropTypes.string),
  }),
  isCircleBackActionAlert: PropTypes.bool.isRequired,
  lists: PropTypes.arrayOf(contactListType).isRequired,
  listsValue: PropTypes.arrayOf(contactListType).isRequired,
  nameValue: PropTypes.string.isRequired,
  senderEmailValue: PropTypes.string.isRequired,
  senderNameValue: PropTypes.string.isRequired,
  setEmailError: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired,
  subjectValue: PropTypes.string.isRequired,
};

export default StepOne;
