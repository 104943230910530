import PropTypes from 'prop-types';

import { StatisticCard } from 'common/components';
import { StatisticsWrapper } from 'globalStyles';

const ACTION_CENTERS_TEXT = 'Action Centers';
const ADVOCATES_TEXT = 'Advocates';
const ADVOCACY_ALERTS_TEXT = 'Advocacy Alerts';
const BROADCASTS_TEXT = 'Broadcasts';
const CAMPAIGNS_TEXT = 'Campaigns';

const StatisticCards = ({
  actionCenterCount,
  advocacyAlertsCount,
  advocatesCount,
  broadcastCount,
  campaignCount,
}) => (
  <StatisticsWrapper>
    <StatisticCard
      count={campaignCount}
      isPrimaryColor={false}
      text={CAMPAIGNS_TEXT}
    />
    <StatisticCard
      count={actionCenterCount}
      text={ACTION_CENTERS_TEXT}
    />
    <StatisticCard
      count={advocacyAlertsCount}
      text={ADVOCACY_ALERTS_TEXT}
    />
    <StatisticCard
      count={broadcastCount}
      text={BROADCASTS_TEXT}
    />
    <StatisticCard
      count={advocatesCount}
      text={ADVOCATES_TEXT}
    />
  </StatisticsWrapper>
);

StatisticCards.defaultProps = {
  actionCenterCount: 0,
  advocacyAlertsCount: 0,
  advocatesCount: 0,
  broadcastCount: 0,
  campaignCount: 0,
};

StatisticCards.propTypes = {
  actionCenterCount: PropTypes.number,
  advocacyAlertsCount: PropTypes.number,
  advocatesCount: PropTypes.number,
  broadcastCount: PropTypes.number,
  campaignCount: PropTypes.number,
};

export default StatisticCards;
