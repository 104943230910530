import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Link as RouterLink,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  deleteTemplate,
  fetchAllTemplates,
} from 'store/actions/templates';
import {
  setSnackbarSuccess,
} from 'store/actions/global';
import { templatesSelectors } from 'store/selectors/templates';

import { useConfirm } from 'material-ui-confirm';
import {
  Grid,
  Link,
} from '@mui/material';
import { Drafts as DraftsIcon } from '@mui/icons-material';

import {
  Backdrop,
  BreadcrumbsNavigation,
  Container,
  DescriptionBar,
  LinkButton,
} from 'common/components';
import TemplateItem from '../components/TemplateItem';

const Templates = () => {
  const dispatch = useDispatch();

  const {
    organizationUuid,
    type,
  } = useParams();

  const {
    isFetching,
  } = useSelector(state => ({
    isFetching: templatesSelectors.isFetching(state),
  }));

  const breadcrumbs = [
    {
      link: type === 'broadcast' ? `/${organizationUuid}/broadcasts` : `/${organizationUuid}/campaigns`,
      name: type === 'broadcast' ? 'Broadcasts' : 'Advocacy',
    },
    {
      link: '',
      name: 'Email Templates',
    },
  ];

  const [
    customTemplates,
    setCustomTemplates,
  ] = useState([]);

  useEffect(() => {
    dispatch(fetchAllTemplates({
      organization: organizationUuid,
      type,
    }))
      .then(data => setCustomTemplates(data || []));
  }, [type]);

  const confirm = useConfirm();

  const handleRemoveTemplate = templateUuid => {
    confirm({
      confirmationText: 'Remove',
      title: 'Remove Template?',
    })
      .then(() => dispatch(deleteTemplate(templateUuid)))
      .then(() => dispatch(fetchAllTemplates({
        organization: organizationUuid,
        type,
      })))
      .then(data => setCustomTemplates(data || []))
      .then(() => dispatch(setSnackbarSuccess()));
  };

  const templatesHeadline = useMemo(() => (type === 'broadcast' ? 'Broadcast Templates' : 'Action Alert Templates'), [type]);
  const templateType = useMemo(() => (type === 'broadcast' ? 'broadcast' : 'action alert'), [type]);

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container marginBottom="40">
        <Grid container>
          <DescriptionBar
            name={templatesHeadline}
            icon={DraftsIcon}
          >
            <LinkButton
              to={`/${organizationUuid}/create-template/${type}`}
              name="New Template"
              variant="newItem"
            />
          </DescriptionBar>
        </Grid>
        {!isFetching &&
        !customTemplates?.length ? (
          <Link
            component={RouterLink}
            color="inherit"
            to={`/${organizationUuid}/create-template/${type}`}
          >
            <span>{`Create your first ${templateType} email template.`}</span>
          </Link>
        ) : customTemplates?.map(targetGroup => (
          <TemplateItem
            handleRemoveTemplate={handleRemoveTemplate}
            name={targetGroup.name}
            uuid={targetGroup.uuid}
            key={targetGroup.uuid}
          />
          ))
        }
      </Container>
      <Backdrop isOpen={isFetching} />
    </>
  );
};

Templates.propTypes = {
  location: PropTypes.shape({
    listProps: PropTypes.shape({
      listId: PropTypes.string,
    }),
  }).isRequired,
};

export default Templates;
