import { Field } from 'react-final-form';
import styled from 'styled-components';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
} from '@mui/material';

import { required } from 'utils/validators';

const TermsGrid = styled(Grid)`
  margin-top: 15px;
`;

const { REACT_APP_TERMS_OF_SERVICE_LINK } = process.env;

export const TermsField = () => (
  <TermsGrid
    container
    item
    xs={12}
  >
    <Field
      name="accept_terms"
      type="checkbox"
      validate={required}
    >
      {({
        input,
        meta,
      }) => (
        <FormControl
          required
          error={Boolean(meta.touched && (meta.error || meta.submitError))}
        >
          <FormControlLabel
            control={(
              <Checkbox
                {...input}
                color="primary"
              />
              )}
            label={(
              <div>
                I have read and agree to the
                {' '}
                <a
                  href={REACT_APP_TERMS_OF_SERVICE_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Muster Terms of Service
                </a>
              </div>
              )}
          />
          {(meta.touched && (meta.error || meta.submitError)) && (
            <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
            )}
        </FormControl>
      )}
    </Field>
  </TermsGrid>
);
