import { actionTypes } from 'utils/constants';

/*
* Returns the front-end URL for accessing either an email action alert
* or a text action alert.
 */
export const alertUrl = (type, organizationUuid, campaign, actionCenter, uuid) => {
  const {
    email,
  } = actionTypes;

  if (type === email) {
    return `/${organizationUuid}/campaigns/${campaign}/action-centers/${actionCenter}/action-alerts/${uuid}?backUrl=/${organizationUuid}/broadcasts`;
  }

  return `/${organizationUuid}/campaigns/${campaign}/action-centers/${actionCenter}/text-action-alerts/${uuid}?backUrl=/${organizationUuid}/broadcasts`;
};

export const broadcastUrl = (type, organizationUuid, uuid) => {
  const {
    email,
  } = actionTypes;

  return type === email ? `/${organizationUuid}/broadcasts/${uuid}` : `/${organizationUuid}/text-broadcasts/${uuid}`;
};
