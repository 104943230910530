import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';

import { colors } from 'utils/constants';

const StyledCard = styled(Card)`
  position: relative;
  min-width: 140px;
  max-width: 270px;
  height: 170px;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0;
  text-align: center;

  &:last-child {
    padding-bottom: 0;
  }
`;
const StyledCount = styled(Typography)`
  color: ${props => (props.isprimary ? colors.primary : colors.black)};
  font-size: ${props => (props.isprimary ? '36px' : '28px')};
  line-height: 43px;
  letter-spacing: 0;
`;

const LastWeekStatistic = styled(Typography)`
  font-size: 12px;
`;

const LastMonthStatistic = styled(Typography)`
  font-size: 12px;
`;

const StatisticValue = styled(Typography)`
  color: ${props => (props.negative ? colors.error : colors.secondary)};
  font-size: 12px;
`;

const StatisticListCard = ({
  count,
  hasDaysStatistic,
  hasDisabledElevation,
  hasPrimaryColor,
  lastMonthValue,
  lastWeekValue,
  negative,
  text,
}) => {
  const handleIsNegativeValue = value => (value < 0 ? 1 : 0);

  return (
    <StyledCard variant={hasDisabledElevation ? 'outlined' : 'elevation'}>
      <StyledCardContent>
        <StyledCount isprimary={hasPrimaryColor ? 1 : 0}>
          {count}
        </StyledCount>
        <Typography variant="subtitle1">{text}</Typography>
        {hasDaysStatistic && (
          <>
            <Grid
              container
              justifyContent="center"
            >
              <LastWeekStatistic variant="subtitle2">
                Last 7 days:
              </LastWeekStatistic>
              <StatisticValue negative={negative ? 1 : handleIsNegativeValue(lastWeekValue)}>
                +
                {lastWeekValue}
              </StatisticValue>
            </Grid>
            <Grid
              container
              justifyContent="center"
            >
              <LastMonthStatistic variant="subtitle2">
                Last 30 days:
              </LastMonthStatistic>
              <StatisticValue negative={negative ? 1 : handleIsNegativeValue(lastMonthValue)}>
                +
                {lastMonthValue}
              </StatisticValue>
            </Grid>
          </>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

StatisticListCard.defaultProps = {
  count: 0,
  hasDaysStatistic: false,
  hasDisabledElevation: false,
  hasPrimaryColor: false,
  lastMonthValue: 0,
  lastWeekValue: 0,
  negative: false,
  text: '',
};

StatisticListCard.propTypes = {
  count: PropTypes.number,
  hasDaysStatistic: PropTypes.bool,
  hasDisabledElevation: PropTypes.bool,
  hasPrimaryColor: PropTypes.bool,
  lastMonthValue: PropTypes.number,
  lastWeekValue: PropTypes.number,
  negative: PropTypes.bool,
  text: PropTypes.string,
};

export default StatisticListCard;
