import styled from 'styled-components';

import { Button } from '@mui/material';

import ButtonMenu from 'common/components/ButtonMenu';

import { colors } from 'utils/constants';

export const DropdownButton = styled(Button)`
  display: block;
  padding: 0;
  color: ${colors.black};
  font-weight: 500;
  font-size: 14px;
  text-transform: none;
`;

export const NavigationDropdown = styled(ButtonMenu)`
  font-size: 18px;
  text-decoration: ${props => (props.isactualroute ? 'underline' : 'none')};
  text-underline-offset: 4px;
  text-transform: none;
  text-decoration-thickness: 2px;

  &:hover {
    background-color: inherit;
    color: inherit;
  }
`;
