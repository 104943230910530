import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useConfirm } from 'material-ui-confirm';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import { deleteEmail } from 'store/actions/contactDetails';
import { StyledHighlightOffIcon } from 'globalStyles';

const StyledTableCell = styled(TableCell)`
  width: 50%;
  padding-left: 0;
`;

const EmailsTable = ({
  contactId,
  emails,
  listId,
  onEditClick,
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const handleEmailDelete = emailId => {
    confirm({
      confirmationText: 'Delete',
      description: 'This action will delete selected e-mail',
      title: 'Delete selected e-mail?',
    })
      .then(() => dispatch(deleteEmail(listId, contactId, emailId)));
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Email</StyledTableCell>
            <TableCell>Subscribed</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {emails.map(email => (
            <TableRow key={email.uuid}>
              <StyledTableCell>{email.email}</StyledTableCell>
              <TableCell>{email.isSubscribed === false ? <StyledHighlightOffIcon /> : <CheckCircleOutlineIcon color="secondary" />}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="edit"
                  onClick={() => onEditClick(email)}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleEmailDelete(email.uuid)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

EmailsTable.defaultProps = {
  emails: [],
};

EmailsTable.propTypes = {
  contactId: PropTypes.string.isRequired,
  emails: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
  listId: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default EmailsTable;
