import {
  Link as RouterLink,
  useHistory,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isProductRoute } from 'utils/helpers';

import { MenuItem } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';

import { organizationSelectors } from 'store/selectors/organizations';

import {
  DropdownButton,
  NavigationDropdown,
} from '../NavigationDropdowns.styles';

const ListsDropdown = ({ organizationUuid }) => {
  const { location } = useHistory();

  const {
    organization: {
      flags,
    },
  } = useSelector(state => ({
    organization: organizationSelectors.getOrganization(state),
  }));

  return (
    <NavigationDropdown
      name="Lists"
      isactualroute={isProductRoute(location, 'lists')}
      disabled={!flags?.showLists}
      color="inherit"
      startIcon={null}
      endIcon={<KeyboardArrowDownIcon />}
      variant="text"
    >
      {handleSettingsClose => (
        <>
          <DropdownButton
            component={RouterLink}
            to={`/${organizationUuid}/contacts/lists`}
          >
            <MenuItem onClick={handleSettingsClose}>Contacts</MenuItem>
          </DropdownButton>
          <DropdownButton
            component={RouterLink}
            disabled={!flags?.signUpForms}
            to={`/${organizationUuid}/signups`}
          >
            <MenuItem onClick={handleSettingsClose}>Forms</MenuItem>
          </DropdownButton>
          <DropdownButton
            component={RouterLink}
            to={`/${organizationUuid}/custom-fields`}
          >
            <MenuItem onClick={handleSettingsClose}>Custom Fields</MenuItem>
          </DropdownButton>
        </>
      )}
    </NavigationDropdown>
  );
};

ListsDropdown.propTypes = {
  organizationUuid: PropTypes.string.isRequired,
};

export default ListsDropdown;
