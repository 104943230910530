import PropTypes from 'prop-types';
import styled from 'styled-components';

const MapWrapper = styled.div`
  flex-grow: 1;
  height: 100%;
  min-height: ${({ $isFullPage }) => ($isFullPage ? '80vh' : '0')};
`;

const MapBoxContainer = ({
  isFullPage,
  mapContainer,
}) => (
  <MapWrapper
    $isFullPage={isFullPage}
    ref={mapContainer}
  />
);

MapBoxContainer.defaultProps = {
  isFullPage: false,
  mapContainer: null,
};

MapBoxContainer.propTypes = {
  isFullPage: PropTypes.bool,
  mapContainer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default MapBoxContainer;
