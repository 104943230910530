import {
  useMemo,
  useState,
} from 'react';
import {
  Field,
  Form,
} from 'react-final-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  deleteInvitation,
  fetchInvitations,
  postRemindInvitation,
} from 'store/actions/organizations';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';
import { organizationSelectors } from 'store/selectors/organizations';

import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  MailOutline as MailOutlineIcon,
  PersonAddAlt as PersonAddAltIcon,
} from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';

import { required } from 'utils/validators';
import {
  SettingsWrapper,
} from 'globalStyles';

import TeamTable from 'routes/Settings/components/TeamTable';

const StyledField = styled(Field)`
  width: 50%;
  margin: 20px 0;
`;

const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;

const INVITE_ADMIN_SUCCESS_MESSAGE = 'Your invitation has been sent.';
const INVITE_ADMIN = 'Send invitation';
const INVITE_INFO_TITLE = 'Invite a team member to join you on Muster';
const TEAM_MANAGEMENT = 'Team members and invitations';
const CONFIRM_REMIND_INVITATION = 'This will send a new reminder email with the same invitation link as previously sent.';
const CONFIRM_DELETE_INVITATION = 'Any previous invitations to this email will no longer work. If you want to resend, simply send a reminder instead.';

const AdminTab = ({
  onInvite,
  logo,
}) => {
  const dispatch = useDispatch();
  const { organizationUuid } = useParams();
  const confirm = useConfirm();

  const [
    isInviteModalOpen,
    SetIsInviteModalOpen,
  ] = useState(false);

  const {
    invitedMembers,
    teamMembers,
  } = useSelector(state => ({
    invitedMembers: organizationSelectors.getInvitedMembers(state),
    teamMembers: organizationSelectors.getTeamMembers(state),
  }));

  const handleRemoveInvitation = invitedMember => {
    confirm({
      confirmationText: 'Remove',
      description: CONFIRM_DELETE_INVITATION,
      title: 'Remove the Invitation?',
    })
      .then(() => dispatch(deleteInvitation(organizationUuid, invitedMember.uuid)))
      .then(() => dispatch(fetchInvitations(organizationUuid)));
  };

  const handleRemindInvitation = invitedMember => {
    confirm({
      confirmationText: 'Remind',
      description: CONFIRM_REMIND_INVITATION,
      title: 'Remind the Invitation?',
    })
      .then(() => dispatch(postRemindInvitation(organizationUuid, invitedMember.uuid)))
      .then(() => dispatch(fetchInvitations(organizationUuid)))
      .catch(error => {
        if (error) {
          dispatch(setSnackbarError({
            message: error.message,
          }));
        }
      });
  };

  const members = useMemo(() => [
    ...teamMembers,
    ...invitedMembers?.results?.map(invitedMember => ({
      ...invitedMember,
      isInvited: true,
    })) || [],
  ], [
    invitedMembers?.results,
    teamMembers,
  ]);

  return (
    <SettingsWrapper>
      <Grid container>
        <Grid
          item
          xs={6}
        >
          <Typography variant="h6">{TEAM_MANAGEMENT}</Typography>
          <Typography variant="body2">
            Current team members and pending invitations are listed below.
            If a team member still has not accepted your invitation
            you can send them a reminder with the email links below.
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={6}
          sx={{
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            color="primary"
            disableElevation
            onClick={() => SetIsInviteModalOpen(true)}
            startIcon={<PersonAddAltIcon />}
            variant="contained"
          >
            Invite
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <TeamTable
          handleRemoveInvitation={handleRemoveInvitation}
          handleRemindInvitation={handleRemindInvitation}
          members={members}
          logo={logo}
        />
      </Grid>
      <Dialog
        fullWidth
        open={isInviteModalOpen}
        onClose={() => SetIsInviteModalOpen(false)}
      >
        <DialogTitle>Invite</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={onInvite}
            render={({
              form,
              handleSubmit,
              pristine,
              submitting,
            }) => (
              <form onSubmit={async event => {
                const response = await handleSubmit(event);

                if (response === 'success') {
                  dispatch(setSnackbarSuccess({ message: INVITE_ADMIN_SUCCESS_MESSAGE }));

                  form.restart();
                  SetIsInviteModalOpen(false);
                }
              }}
              >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    alignContent="space-between"
                    container
                    item
                    xs={12}
                  >
                    <Typography variant="body2">{INVITE_INFO_TITLE}</Typography>
                  </Grid>
                  <Grid
                    alignContent="space-between"
                    container
                    item
                    xs={12}
                  >
                    <StyledField
                      name="email"
                      validate={required}
                    >
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          error={Boolean(meta.touched && (meta.error || meta.submitError))}
                          fullWidth
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          label="Email"
                          type="email"
                          variant="outlined"
                        />
                      )}
                    </StyledField>
                  </Grid>
                </Grid>
                <Grid
                  alignContent="space-between"
                  container
                  item
                  xs={12}
                >
                  <Field
                    name="is_admin"
                    type="checkbox"
                  >
                    {({ input }) => (
                      <FormControlLabel
                        control={(
                          <Checkbox
                            {...input}
                            color="primary"
                          />
                        )}
                        label="Grant admin role (access account settings, invite team members)"
                      />
                    )}
                  </Field>
                </Grid>
                <StyledGrid
                  container
                  item
                  justifyContent="flex-end"
                  xs={12}
                >
                  <Button
                    color="primary"
                    disabled={submitting || pristine}
                    disableElevation
                    type="submit"
                    variant="contained"
                    startIcon={<MailOutlineIcon />}
                  >
                    {INVITE_ADMIN}
                  </Button>
                </StyledGrid>
              </form>
            )}
          />
        </DialogContent>
      </Dialog>
    </SettingsWrapper>
  );
};

AdminTab.defaultProps = {
  logo: null,
};

AdminTab.propTypes = {
  logo: PropTypes.string,
  onInvite: PropTypes.func.isRequired,
};

export default AdminTab;
