import { useEffect } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Card,
  CircularProgress,
  Grid,
} from '@mui/material';
import { GroupAdd as GroupAddIcon } from '@mui/icons-material';

import {
  fetchAllListContact,
  fetchChildrenLists,
  fetchRootList,
  postContact,
} from 'store/actions/contacts';
import { fetchCustomFields } from 'store/actions/customFields';
import { contactSelectors } from 'store/selectors/contacts';
import { customFieldsSelectors } from 'store/selectors/customFields';

import {
  ContactForm,
  Container,
  DescriptionBar,
} from 'common/components';
import { CircularProgressWrapper } from 'globalStyles';
import { handleOnlyNumbers } from 'utils/helpers';

const CreateContactWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
`;

const StyledCard = styled(Card)`
  max-width: 800px;
  margin-bottom: 20px;
  padding: 12px;
`;

const CreateContact = ({ location }) => {
  const dispatch = useDispatch();
  const {
    listUuid,
    organizationUuid,
  } = useParams();
  const history = useHistory();
  const {
    customFieldsList,
    isFetchingCustomFields,
    rootList,
  } = useSelector(state => ({
    customFieldsList: customFieldsSelectors.getCustomFieldsListData(state),
    isFetchingCustomFields: customFieldsSelectors.isFetchingCustomFieldsListData(state),
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  useEffect(() => {
    if (Object.keys(rootList).length === 0) {
      dispatch(fetchRootList(organizationUuid))
        .then(data => dispatch(fetchCustomFields(data.uuid)));
    } else {
      dispatch(fetchCustomFields(rootList.uuid));
    }
  }, []);

  const onSubmit = async values => {
    const params = { ...values };

    if (values.customFields && Object.keys(values.customFields).length !== 0) {
      params.customFields = Object.entries(values.customFields).map(customField => ({
        customField: customField[0],
        value: customField[1],
      }));
    }

    if (params.locations && params.locations[0]?.zipCode) {
      params.locations[0].zipCode = handleOnlyNumbers(params.locations[0].zipCode);
    }

    try {
      await dispatch(postContact(listUuid, params));
      await dispatch(fetchChildrenLists(rootList.uuid, organizationUuid));
      await dispatch(fetchAllListContact(listUuid));

      return history.push(location?.state?.goBackLink || `/${organizationUuid}/contacts/lists`);
    } catch (error) {
      return error;
    }
  };

  if (isFetchingCustomFields) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  return (
    <Container>
      <Grid container>
        <DescriptionBar
          icon={GroupAddIcon}
          name="Create Contact"
        />
      </Grid>
      <CreateContactWrapper>
        <StyledCard variant="outlined">
          <ContactForm
            cancelHref={`/${organizationUuid}/contacts/lists`}
            customFieldsList={customFieldsList}
            onSubmit={onSubmit}
          />
        </StyledCard>
      </CreateContactWrapper>
    </Container>
  );
};

CreateContact.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      goBackLink: PropTypes.string,
    }),
  }).isRequired,
};

export default CreateContact;
