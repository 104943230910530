import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TwitterPicker } from 'react-color';

import { colors } from 'utils/constants';

const StyledCover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledSwatch = styled.div`
  display: inline-block;
  box-shadow: ${colors.osloGray} 0 1px 1px;
  border-radius: 50%;
  background: none;
  cursor: pointer;
`;

const StyledSwatchColor = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

const StyledTwitterPicker = styled(TwitterPicker)`
  & > div:nth-of-type(1) {
    display: none;
  }

  & > div:nth-of-type(2) {
    display: none;
  }

  [title~="#fff"] {
    border: 1px solid grey;
  }
`;

const StyledPopover = styled.div`
  position: absolute;
  z-index: 2;
  margin-top: 140px;
`;

const ColorPicker = ({
  handlePickerChange,
  pickerColor,
}) => {
  const [
    isColorPickerVisible,
    setIsColorPickerVisible,
  ] = useState(false);

  const handlePickerClose = () => setIsColorPickerVisible(false);
  const handleSwatchClick = () => setIsColorPickerVisible(prevValue => !prevValue);

  return (
    <>
      <StyledSwatch onClick={handleSwatchClick}>
        <StyledSwatchColor style={{ background: pickerColor }} />
      </StyledSwatch>
      {isColorPickerVisible ? (
        <StyledPopover>
          <StyledCover onClick={handlePickerClose} />
          <StyledTwitterPicker
            color={pickerColor}
            onChange={handlePickerChange}
            colors={[
              '#FF6900',
              '#FCB900',
              '#00D084',
              '#D1E72B',
              '#8ED1FC',
              '#0693E3',
              '#ABB8C3',
              '#EB144C',
              '#F78DA7',
              '#9900EF',
              '#fff',
              '#000',
            ]}
            width="320px"
          />
        </StyledPopover>
      ) : null}
    </>
  );
};

ColorPicker.propTypes = {
  handlePickerChange: PropTypes.func.isRequired,
  pickerColor: PropTypes.string.isRequired,
};

export default ColorPicker;
