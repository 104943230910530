import PropTypes from 'prop-types';

import MaterialTable, { MTableCell } from '@material-table/core';
import { Paper } from '@mui/material';

import {
  actionCenterSignupsColumns,
  colors,
} from 'utils/constants';

const ActionCenterSignupsTable = ({
  isDataFetching,
  signupsList,
}) => (
  <MaterialTable
    columns={actionCenterSignupsColumns}
    components={{
      Cell: props => {
        /* eslint-disable react/prop-types */
        const {
          columnDef: {
            field,
          },
          value,
        } = props;

        if (field === 'date') {
          return (
            <MTableCell
              {...props}
              value={new Date(value).toLocaleDateString('us-US')}
            />
          );
        }

        return (
          <MTableCell
            {...props}
          />
        );
        /* eslint-enable react/prop-types */
      },
      Container: props => (
        <Paper
          {...props}
          elevation={0}
          square
          variant="outlined"
        />
      ),
    }}
    data={signupsList}
    isLoading={isDataFetching}
    options={{
      actionsColumnIndex: -1,
      headerStyle: {
        backgroundColor: colors.tableHeaderGray,
        color: colors.white,
      },
      selection: false,
      showTitle: false,
    }}
  />
);

ActionCenterSignupsTable.propTypes = {
  isDataFetching: PropTypes.bool.isRequired,
  signupsList: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  })).isRequired,
};

export default ActionCenterSignupsTable;
