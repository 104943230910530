import {
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';

import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';

import {
  fetchFederalPositions,
  fetchPositionTerms,
} from 'store/actions/contactDetails';
import {
  fetchBoundary,
} from 'store/actions/global';
import { contactDetailsSelectors } from 'store/selectors/contactDetails';
import { customFieldsSelectors } from 'store/selectors/customFields';
import { organizationSelectors } from 'store/selectors/organizations';

import {
  Dialog,
  LocationDetails,
  OfficialsDetails,
} from 'common/components';

import {
  administrativeLevels,
  fieldTypes,
  filterByCategories,
  filteredListConditions,
  filtersTypes,
  negativeFilterConditions,
  positiveFilterConditions,
  usStates,
} from 'utils/constants';
import {
  addBoundaryToMap,
} from 'utils/helpers';
import {
  CircularProgressWrapper,
} from 'globalStyles';
import ActionCenterDetails from '../ActionCenterDetails';
import SignupDetails from '../SignupDetails';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  padding-bottom: 10px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  padding-bottom: 10px;
`;

const FilterModal = ({
  autocompleteOptions,
  category,
  condition,
  customFieldId,
  customFieldType,
  editingFilterIndex,
  filterName,
  filteredByOption,
  filterValue,
  filterValues,
  handleChangeFilerType,
  handleAddFilter,
  handleDialogClose,
  isFilterAdding,
  liveActionCenters,
  liveSignupsSource,
  locationFilterData,
  pickedActionCenters,
  pickedSignupsSource,
  position,
  setCategory,
  setCondition,
  setCustomFieldId,
  setFilterValue,
  setFilterValues,
  setLocationFilterData,
  setPickedActionCenters,
  setPickedSignupsSource,
  setPosition,
  setTerm,
  setUsState,
  term,
  usState,
}) => {
  const dispatch = useDispatch();
  const {
    customFieldsList,
    federalPositions,
    isPositionsFetching,
    isTermsFetching,
    organization: {
      flags,
    },
    positionTerms,
  } = useSelector(state => ({
    customFieldsList: customFieldsSelectors.getCustomFieldsListData(state),
    federalPositions: contactDetailsSelectors.getFederalPositions(state),
    isPositionsFetching: contactDetailsSelectors.isPositionsFetching(state),
    isTermsFetching: contactDetailsSelectors.isTermsFetching(state),
    organization: organizationSelectors.getOrganization(state),
    positionTerms: contactDetailsSelectors.getPositionTerms(state),
  }));

  const mapContainer = useRef(null);
  const map = useRef(null);

  const handleCategoryChange = event => {
    setCategory(event.target.value);

    if (event.target.value === 'Federal') {
      dispatch(fetchFederalPositions({ category: 'federal' }));
    }
  };

  const handleNonFederalPositionChange = event => {
    dispatch(fetchPositionTerms({
      position: event.target.value,
      state: usState,
    }));
    setPosition(event.target.value);
    setTerm({});
    setFilterValue('');
  };

  const handlePositionStateChange = event => {
    if (category === 'Federal') {
      if (event.target.value !== position) {
        dispatch(fetchPositionTerms({ position: event.target.value }));

        setPosition(event.target.value);
      }
    } else {
      dispatch(fetchFederalPositions({
        category: category === 'Local' ? 'local' : 'state',
        state: event.target.value,
      }));

      setUsState(event.target.value);
    }
  };

  const {
    actionCenterFilterType,
    customFieldFilterType,
    grassTopRelationshipFilterType,
    hasExternalIdFilterType,
    isAdvocateFilterType,
    isTextableListFilterType,
    signupSourceListFilterType,
    locationFilterType,
  } = filtersTypes;

  const {
    contains,
    includesAll,
    includesAny,
    matches,
  } = positiveFilterConditions;

  const {
    doesNotContain,
    doesNotIncludeAll,
    doesNotIncludeAny,
    doesNotMatch,
  } = negativeFilterConditions;

  const {
    textField,
    singleChoiceField,
    multipleChoiceField,
  } = fieldTypes;

  const renderPositions = () => (
    isPositionsFetching ? (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    ) : (
      federalPositions.map(({
        title,
        uuid,
      }) => (
        <MenuItem
          key={uuid}
          value={uuid}
        >
          {title}
        </MenuItem>
      ))
    )
  );

  const renderStates = () => (
    usStates.map(({
      abbreviation,
      name,
    }) => (
      <MenuItem
        key={abbreviation}
        value={abbreviation}
      >
        {name}
      </MenuItem>
    ))
  );

  const handleSelectConditions = fieldType => {
    switch (fieldType) {
      case textField:
        return [
          matches,
          doesNotMatch,
          contains,
          doesNotContain,
        ];
      case singleChoiceField:
        return [
          matches,
          doesNotMatch,
        ];
      case multipleChoiceField:
        return [
          includesAll,
          doesNotIncludeAll,
          includesAny,
          doesNotIncludeAny,
        ];
      default:
        return [];
      }
  };

  const listConditions = [
    ...filteredListConditions,
    ...customFieldsList.map(({
      label,
      fieldType,
    }) => ({
      attribute: label,
      conditions: handleSelectConditions(fieldType),
    })),
  ];

  const handleGetOptionLabel = option => {
    if (option.type === customFieldFilterType) {
      return option.fieldType === textField ?
        `${option.name} (Text Field)` :
        `${option.name} (${option.fieldType === multipleChoiceField ? 'Multiple ' : ''}Choice Field)`;
    }

    return option.name;
  };

  const handleAddBoundary = geojsonUrl => {
    dispatch(fetchBoundary(geojsonUrl))
      .then(feature => {
        addBoundaryToMap(map.current, feature);

        const bounds = new mapboxgl.LngLatBounds();

        feature.geometry.coordinates[0][0].forEach(coordinate => {
          bounds.extend(coordinate);
        });

        map.current.fitBounds(bounds, {
          padding: {
            bottom: 25,
            left: 15,
            right: 5,
            top: 10,
          },
        });
      });
  };

  const renderFilterValue = () => {
    const customField = customFieldsList.find(field => field.label === filterName);

    const { uuid } = customField || {};

    if (customFieldType === fieldTypes.multipleChoiceField) {
      return (
        <Autocomplete
          label="Choice"
          getOptionLabel={option => option}
          fullWidth
          multiple
          onChange={(_, pickedOption) => {
            setFilterValues(pickedOption);
            setCustomFieldId(uuid);
          }}
          options={customField.options}
          renderInput={params => (
            <TextField
              {...params}
              label="Choice"
              variant="standard"
            />
          )}
          value={filterValues}
        />
      );
    }

    if (customFieldType === fieldTypes.singleChoiceField) {
      return (
        <StyledTextField
          label="Choice"
          onChange={event => {
            setFilterValue(event.target.value);
            setCustomFieldId(uuid);
          }}
          select
          value={filterValue}
        >
          {customField.options.map(option => (
            <MenuItem
              key={`${option}_${uuid}`}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </StyledTextField>
      );
    }

    return (
      <TextField
        fullWidth
        label="Filter Value"
        onChange={event => {
          setFilterValue(event.target.value);
          setCustomFieldId(uuid);
        }}
        type="text"
        value={filterValue}
        variant="standard"
      />
    );
  };

  const renderListConditions = () => {
    const { conditions = [] } = listConditions.find(
      ({ attribute }) => attribute === filterName
    ) || {};

    return conditions.map(option => (
      <MenuItem
        key={option}
        value={option}
      >
        {option}
      </MenuItem>
    ));
  };

  const handleIsSubmitButtonDisabled = () => {
    if (
      filterName === isTextableListFilterType ||
      filterName === isAdvocateFilterType ||
      filterName === hasExternalIdFilterType
    ) {
      return !condition;
    }

    if (filterName === actionCenterFilterType) {
      return !pickedActionCenters.length || !condition;
    }

    if (filterName === signupSourceListFilterType) {
      return !pickedSignupsSource.length || !condition;
    }

    return !(filterValue || filterValues.length);
  };

  return (
    <Dialog
      customFieldId={customFieldId}
      disabledButton={handleIsSubmitButtonDisabled()}
      filterValue={filterValue}
      isDialogOpen={isFilterAdding}
      title={Number.isInteger(editingFilterIndex) ? 'Edit Filter' : 'Create filter'}
      handleConfirm={handleAddFilter}
      handleDialogClose={handleDialogClose}
    >
      <>
        <StyledAutocomplete
          getOptionLabel={option => handleGetOptionLabel(option)}
          isOptionEqualToValue={(option, value) => (value ? option.name === value.name : true)}
          groupBy={option => option.type}
          onChange={(_, pickedFilter) => handleChangeFilerType(pickedFilter)}
          options={autocompleteOptions}
          value={filteredByOption}
          getOptionDisabled={
            option => option.type === filterByCategories[4].type && !flags?.showTexting
          }
          renderInput={params => (
            <TextField
              {...params}
              label="Filter by"
              variant="standard"
            />
          )}
        />
        {filterName && (
          <StyledTextField
            data-test-id="conditions-select"
            fullWidth
            label="Condition"
            onChange={event => setCondition(event.target.value)}
            select
            value={condition}
            variant="standard"
          >
            {renderListConditions()}
          </StyledTextField>
        )}
        {filterName === grassTopRelationshipFilterType && (
          <OfficialsDetails
            administrativeLevels={administrativeLevels}
            category={category}
            handleCategoryChange={handleCategoryChange}
            handleNonFederalPositionChange={handleNonFederalPositionChange}
            handlePositionStateChange={handlePositionStateChange}
            isFilteredListAdding
            isTermsFetching={isTermsFetching}
            position={position}
            positionTerms={positionTerms}
            renderPositions={renderPositions}
            renderStates={renderStates}
            setFilterValue={setFilterValue}
            setPosition={setPosition}
            setState={setUsState}
            setTerm={setTerm}
            state={usState}
            term={term}
          />
        )}
        {filterName === locationFilterType && (
          <LocationDetails
            handleAddBoundary={handleAddBoundary}
            locationFilterData={locationFilterData}
            mapContainer={mapContainer}
            map={map}
            setFilterValue={setFilterValue}
            setLocationFilterData={setLocationFilterData}
          />
        )}
        {filterName === actionCenterFilterType && (
          <ActionCenterDetails
            liveActionCenters={liveActionCenters}
            pickedActionCenters={pickedActionCenters}
            setPickedActionCenters={setPickedActionCenters}
          />
        )}
        {filterName === signupSourceListFilterType && (
          <SignupDetails
            liveSignupsSource={liveSignupsSource}
            pickedSignupsSource={pickedSignupsSource}
            setPickedSignupsSource={setPickedSignupsSource}
          />
        )}
        {condition &&
          filterName !== grassTopRelationshipFilterType &&
          filterName !== locationFilterType &&
          filterName !== isTextableListFilterType &&
          filterName !== hasExternalIdFilterType &&
          filterName !== isAdvocateFilterType &&
          filterName !== actionCenterFilterType &&
          filterName !== signupSourceListFilterType &&
          renderFilterValue()
        }
      </>
    </Dialog>
  );
};

FilterModal.defaultProps = {
  customFieldId: null,
  editingFilterIndex: null,
  filteredByOption: null,
};

FilterModal.propTypes = {
  autocompleteOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  category: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  customFieldId: PropTypes.string,
  customFieldType: PropTypes.string.isRequired,
  editingFilterIndex: PropTypes.number,
  filterName: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
  filterValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  filteredByOption: PropTypes.shape({
    fieldType: PropTypes.string,
    name: PropTypes.string,
  }),
  handleAddFilter: PropTypes.func.isRequired,
  handleChangeFilerType: PropTypes.func.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  isFilterAdding: PropTypes.bool.isRequired,
  liveActionCenters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  })).isRequired,
  liveSignupsSource: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  })).isRequired,
  locationFilterData: PropTypes.shape({
    boundariesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    boundaryState: PropTypes.oneOfType([
      PropTypes.shape({
        geojsonUrl: PropTypes.string,
        name: PropTypes.string,
        uuid: PropTypes.string,
      }),
      PropTypes.string,
    ]).isRequired,
    boundaryType: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
      }),
      PropTypes.string,
    ]).isRequired,
    editedGeojsonUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  pickedActionCenters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  })).isRequired,
  pickedSignupsSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  position: PropTypes.string.isRequired,
  setCategory: PropTypes.func.isRequired,
  setCondition: PropTypes.func.isRequired,
  setCustomFieldId: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  setFilterValues: PropTypes.func.isRequired,
  setLocationFilterData: PropTypes.func.isRequired,
  setPickedActionCenters: PropTypes.func.isRequired,
  setPickedSignupsSource: PropTypes.func.isRequired,
  setPosition: PropTypes.func.isRequired,
  setTerm: PropTypes.func.isRequired,
  setUsState: PropTypes.func.isRequired,
  term: PropTypes.shape({}).isRequired,
  usState: PropTypes.string.isRequired,
};

export default FilterModal;
