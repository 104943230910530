import request from '../../utils/request';

const PREFIX = '[CRM]';

export const EXPORT_ADVOCATES_REQUEST = `${PREFIX} EXPORT_ADVOCATES_REQUEST`;
export const EXPORT_ADVOCATES_SUCCESS = `${PREFIX} EXPORT_ADVOCATES_SUCCESS`;
export const EXPORT_ADVOCATES_FAILURE = `${PREFIX} EXPORT_ADVOCATES_FAILURE`;
export const EXPORT_ADVOCATES_SUCCESS_MESSAGE = 'Your advocates export will be sent to your email shortly.';

const exportAdvocatesRequest = () => ({
  type: EXPORT_ADVOCATES_REQUEST,
});

const exportAdvocatesSuccess = payload => ({
  payload,
  type: EXPORT_ADVOCATES_SUCCESS,
});

const exportAdvocatesFailure = payload => ({
  payload,
  type: EXPORT_ADVOCATES_FAILURE,
});

export const exportAdvocates = uuid => dispatch => {
  dispatch(exportAdvocatesRequest());

  return request
  .post(`/v1/crm/${uuid}/export/advocates/`)
  .then(({ data }) => {
    dispatch(exportAdvocatesSuccess(data));

    return data;
  })
  .catch(({ response: { data } = {} }) => {
    dispatch(exportAdvocatesFailure(data));

    throw data;
  });
};

