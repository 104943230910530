import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import {
  CardField,
  PhoneView,
} from 'common/components';
import { contactListType } from 'common/typings';

const CardFieldWrapper = styled.div`
  margin-top: 30px;
`;

const StepWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormWrapper = styled.div`
  flex: 1;
`;

const PhoneViewWrapper = styled.div`
  margin: 30px 0 0 30px;
`;

const CHARACTER_LIMIT = 640;

const StepOne = ({
  lists,
  listsValue,
  nameValue,
  setFormValues,
  messageValue,
}) => {
  const handleFormValuesChange = (field, event) => {
    const { value } = event.target;

    setFormValues(prevValues => ({
      ...prevValues,
      [field]: value,
    }));
  };

  return (
    <StepWrapper>
      <FormWrapper>
        <CardFieldWrapper>
          <CardField name="Name of Broadcast">
            <TextField
              fullWidth
              label="Name of Broadcast"
              onChange={event => handleFormValuesChange('nameValue', event)}
              type="text"
              value={nameValue}
              variant="standard"
            />
          </CardField>
        </CardFieldWrapper>
        <CardFieldWrapper>
          <CardField name="Contacts">
            <Autocomplete
              getOptionLabel={option => option.name}
              isOptionEqualToValue={(option, value) => (value ? option.name === value.name : true)}
              fullWidth
              multiple
              onChange={(_, list) => setFormValues(prevValues => ({
                ...prevValues,
                listsValue: list,
              }))}
              options={lists}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Contacts"
                  variant="standard"
                />
              )}
              value={listsValue}
            />
          </CardField>
        </CardFieldWrapper>
        <CardFieldWrapper>
          <CardField name="Message">
            <TextField
              inputProps={{
                maxLength: CHARACTER_LIMIT,
              }}
              fullWidth
              onChange={event => handleFormValuesChange('messageValue', event)}
              value={messageValue}
              label="Message"
              multiline
              helperText={`${messageValue.length}/${CHARACTER_LIMIT}`}
              type="text"
              variant="standard"
            />
          </CardField>
        </CardFieldWrapper>
      </FormWrapper>
      <PhoneViewWrapper>
        <PhoneView text={messageValue} />
      </PhoneViewWrapper>
    </StepWrapper>
  );
};

StepOne.propTypes = {
  lists: PropTypes.arrayOf(contactListType).isRequired,
  listsValue: PropTypes.arrayOf(contactListType).isRequired,
  messageValue: PropTypes.string.isRequired,
  nameValue: PropTypes.string.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export default StepOne;
