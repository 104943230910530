import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ThemeProvider as ScThemeProvider } from 'styled-components';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CssBaseline from '@mui/material/CssBaseline';
import {
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { ConfirmProvider } from 'material-ui-confirm';

import Layout from 'common/containers/Layout';
import configureStore from 'store/store';
import Routes from 'routes';

import {
  muiTheme,
  theme,
} from 'themes';

export const store = configureStore();

const App = () => (
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <ScThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ConfirmProvider>
              <CssBaseline />
              <BrowserRouter>
                <Layout>
                  <Routes />
                </Layout>
              </BrowserRouter>
            </ConfirmProvider>
          </LocalizationProvider>
        </ScThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);

export default App;
