import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';

import { CircularProgressWrapper } from 'globalStyles';

const StyledPaper = styled(Paper)`
  margin-bottom: 80px;
`;

const ROLE_OWNER = 'Role: Owner';
const ROLE_USER = 'Role: User';

const OrganizationsList = ({
  isUserDataFetching,
  organizations,
}) => {
  if (isUserDataFetching) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  return (
    <StyledPaper variant="outlined">
      {organizations.map(({
        isOwner,
        uuid,
        organization,
      }) => (
        <List key={uuid}>
          <ListItem button>
            <ListItemText
              primary={organization}
              secondary={isOwner ? ROLE_OWNER : ROLE_USER}
            />
          </ListItem>
        </List>
      ))}
    </StyledPaper>
  );
};

OrganizationsList.propTypes = {
  isUserDataFetching: PropTypes.bool.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      isOwner: PropTypes.bool,
      uuid: PropTypes.string,
    })
  ).isRequired,
};

export default OrganizationsList;
