import request from '../../utils/request';

const PREFIX = '[OFFICIALS]';

export const FETCH_SEARCHED_OFFICIALS_REQUEST = `${PREFIX} FETCH_SEARCHED_OFFICIALS_REQUEST`;
export const FETCH_SEARCHED_OFFICIALS_SUCCESS = `${PREFIX} FETCH_SEARCHED_OFFICIALS_SUCCESS`;
export const FETCH_SEARCHED_OFFICIALS_FAILURE = `${PREFIX} FETCH_SEARCHED_OFFICIALS_FAILURE`;

const fetchSearchedOfficialsRequest = () => ({
  type: FETCH_SEARCHED_OFFICIALS_REQUEST,
});

const fetchSearchedOfficialsSuccess = payload => ({
  payload,
  type: FETCH_SEARCHED_OFFICIALS_SUCCESS,
});

const fetchSearchedOfficialsFailure = payload => ({
  payload,
  type: FETCH_SEARCHED_OFFICIALS_FAILURE,
});

export const fetchSearchedOfficials = (phrase, organizationUuid) => dispatch => {
  dispatch(fetchSearchedOfficialsRequest());

  return request
    .get(`/v1/officials/search/?q=${phrase}&organization=${organizationUuid}`)
    .then(({ data }) => {
      dispatch(fetchSearchedOfficialsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchSearchedOfficialsFailure(data));

      throw data;
    });
};

export const FETCH_OFFICIAL_REQUEST = `${PREFIX} FETCH_OFFICIAL_REQUEST`;
export const FETCH_OFFICIAL_SUCCESS = `${PREFIX} FETCH_OFFICIAL_SUCCESS`;
export const FETCH_OFFICIAL_FAILURE = `${PREFIX} FETCH_OFFICIAL_FAILURE`;

const fetchOfficialRequest = () => ({
  type: FETCH_OFFICIAL_REQUEST,
});

const fetchOfficialSuccess = payload => ({
  payload,
  type: FETCH_OFFICIAL_SUCCESS,
});

const fetchOfficialFailure = payload => ({
  payload,
  type: FETCH_OFFICIAL_FAILURE,
});

export const fetchOfficial = id => dispatch => {
  dispatch(fetchOfficialRequest());

  return request
    .get(`/v1/officials/officials/${id}/`)
    .then(({ data }) => {
      dispatch(fetchOfficialSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchOfficialFailure(data));

      throw data;
    });
};

export const FETCH_COMMITTEES_REQUEST = `${PREFIX} FETCH_COMMITTEES_REQUEST`;
export const FETCH_COMMITTEES_SUCCESS = `${PREFIX} FETCH_COMMITTEES_SUCCESS`;
export const FETCH_COMMITTEES_FAILURE = `${PREFIX} FETCH_COMMITTEES_FAILURE`;

const fetchCommitteesRequest = () => ({
  type: FETCH_COMMITTEES_REQUEST,
});

const fetchCommitteesSuccess = payload => ({
  payload,
  type: FETCH_COMMITTEES_SUCCESS,
});

const fetchCommitteesFailure = payload => ({
  payload,
  type: FETCH_COMMITTEES_FAILURE,
});

export const fetchCommittees = params => dispatch => {
  dispatch(fetchCommitteesRequest());

  return request
    .get('/v1/officials/committees/', { params })
    .then(({ data }) => {
      dispatch(fetchCommitteesSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchCommitteesFailure(data));

      throw data;
    });
};

export const RESET_COMMITTEES = `${PREFIX} RESET_COMMITTEES`;

export const resetCommittees = () => ({
  type: RESET_COMMITTEES,
});
