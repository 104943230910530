import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Popover as MaterialPopover,
  Typography,
} from '@mui/material';

import { colors } from 'themes';

const PopoverText = styled(Typography)`
  padding: 8px 16px;
  font-size: 14px;
  white-space: pre-line;
`;

const Popover = ({
  children,
  horizontalPosition = 'left',
  shouldDisplayPopover,
  text,
}) => {
  const [
    anchorEl,
    setAnchorEl,
  ] = useState(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div
        onMouseEnter={event => shouldDisplayPopover && handlePopoverOpen(event)}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </div>
      <MaterialPopover
        id="mouse-over-popover--send-button"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        style={{ pointerEvents: 'none' }}
        anchorOrigin={{
          horizontal: horizontalPosition,
          vertical: 'top',
        }}
        slotProps={{
          paper: {
            style: {
              backgroundColor: colors.gray70,
              color: colors.white,
            },
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <PopoverText>{text}</PopoverText>
      </MaterialPopover>
    </>
  );
};

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  horizontalPosition: PropTypes.string,
  shouldDisplayPopover: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default Popover;
