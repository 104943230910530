import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Grid,
  Typography,
} from '@mui/material';

import { colors } from 'utils/constants';
import ColorPicker from 'common/components/ColorPicker';

const ColorPickerTitle = styled(Typography)`
  padding-right: 10px;
`;

const ColorPickerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  background-color: ${colors.backgroundColor};
`;

const ButtonsColorsWrapper = ({
  buttonBackgroundColor,
  buttonTextColor,
  handleChangeTemplateButton,
}) => (
  <>
    <Grid
      alignItems="center"
      container
      justifyContent="flex-end"
    >
      <ColorPickerTitle>Choose button text color</ColorPickerTitle>
      <ColorPickerWrapper>
        <ColorPicker
          handlePickerChange={({ hex }) => handleChangeTemplateButton('text', hex)}
          pickerColor={buttonTextColor}
        />
      </ColorPickerWrapper>
    </Grid>
    <Grid
      alignItems="center"
      container
      justifyContent="flex-end"
    >
      <ColorPickerTitle>Choose button background color</ColorPickerTitle>
      <ColorPickerWrapper
        alignItems="center"
        item
        justifyContent="flex-end"
      >
        <ColorPicker
          handlePickerChange={({ hex }) => handleChangeTemplateButton('background', hex)}
          pickerColor={buttonBackgroundColor}
        />
      </ColorPickerWrapper>
    </Grid>
  </>
);

ButtonsColorsWrapper.propTypes = {
  buttonBackgroundColor: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string.isRequired,
  handleChangeTemplateButton: PropTypes.func.isRequired,
};

export default ButtonsColorsWrapper;
