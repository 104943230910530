/* stylelint-disable color-function-notation */
import {
  Link,
  useHistory,
} from 'react-router-dom';
import {
  Field,
  Form,
} from 'react-final-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  Button,
  Card,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { GroupAdd as GroupAddIcon } from '@mui/icons-material';

import { postOrganization } from 'store/actions/organizations';

import {
  Container,
  DescriptionBar,
} from 'common/components';

import {
  colors,
  usStates,
} from 'utils/constants';

const CreateOrganizationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-size: 18px;
`;

const StyledCard = styled(Card)`
  max-width: 800px;
  margin-bottom: 20px;
  padding: 12px;
`;

const StyledField = styled(Field)`
  width: 100%;
  height: 40px;
  box-shadow: rgba(224, 223, 224, 60%) 0 1px 3px 0 inset;
  border: 1px solid ${colors.mercury};
  border-radius: 5px;
  padding: 10px;
`;

const CreateOrganization = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = async values => {
    try {
      await dispatch(postOrganization(values));

      return history.push('/organizations');
    } catch (error) {
      return error;
    }
  };

  return (
    <Container>
      <Grid container>
        <DescriptionBar
          icon={GroupAddIcon}
          name="Create Organization"
        />
      </Grid>
      <CreateOrganizationWrapper>
        <StyledCard variant="outlined">
          <Form
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              submitting,
              pristine,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    alignContent="space-between"
                    container
                    item
                    xs={12}
                    md={6}
                  >
                    <Label>Name</Label>
                    <Field name="name">
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          fullWidth
                          error={(meta.error || meta.submitError) && !meta.dirtySinceLastSubmit}
                          helperText={!meta.dirtySinceLastSubmit ? (meta.error || meta.submitError) : ''}
                          type="text"
                          placeholder="Organization Name"
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={6}
                  >
                    <Label>Organization URL</Label>
                    <Typography
                      color="textSecondary"
                      display="block"
                      gutterBottom
                      variant="subtitle2"
                    >
                      Link to your organization&apos;s home page
                    </Typography>
                    <Field name="website_url">
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          fullWidth
                          error={meta.touched && (meta.error || meta.submitError)}
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          type="text"
                          placeholder="yourorganization.com"
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Label>Street Address</Label>
                    <Field name="street_address">
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          fullWidth
                          error={meta.touched && (meta.error || meta.submitError)}
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          type="text"
                          placeholder="Street Address"
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Label>Street Address Two</Label>
                    <Field name="street_address_2">
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          fullWidth
                          error={meta.touched && (meta.error || meta.submitError)}
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          type="text"
                          placeholder="Street Address Two"
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                  >
                    <Label>City</Label>
                    <Field name="city">
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          fullWidth
                          error={meta.touched && (meta.error || meta.submitError)}
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          type="text"
                          placeholder="City"
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                  >
                    <Label>State</Label>
                    <StyledField name="state">
                      {({
                        input,
                      }) => (
                        <Select
                          {...input}
                          fullWidth
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {usStates.map(state => (
                            <MenuItem
                              key={state.abbreviation}
                              value={state.abbreviation}
                            >
                              {state.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </StyledField>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                  >
                    <Label>Zip Code</Label>
                    <Field name="zip_code">
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          fullWidth
                          error={meta.touched && (meta.error || meta.submitError)}
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          type="text"
                          placeholder="Zip Code"
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Button
                      component={Link}
                      to="/organizations"
                      variant="outlined"
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid
                    container
                    item
                    justifyContent="flex-end"
                    xs={12}
                    md={6}
                  >
                    <Button
                      color="secondary"
                      disabled={submitting || pristine}
                      disableElevation
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </StyledCard>
      </CreateOrganizationWrapper>
    </Container>
  );
};

export default CreateOrganization;
