import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';

import QueryIndexTabs from 'common/components/QueryIndexTabs';
import { colors } from 'utils/constants';
import { tabsType } from 'common/typings';

const Description = styled.div`
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  padding:
    ${props => {
    if (props.$isHighBar && !props.$hasTabs) {
      return '30px 0';
    }

    if (props.$hasTabs) {
      return '30px 0 0';
    }

    return '0';
  }};
  background-color: ${colors.white};

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 1px;
    background-color: ${colors.borderColor};
  }
`;

const DescriptionBarWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledChip = styled(Chip)`
  margin-left: 15px;
  background-color: ${props => (props.$isWarning ? colors.warning : colors.secondary)};
  color: ${colors.white};
`;

const StyledButtonsGrid = styled(Grid)`
  margin-bottom: ${props => ((props.$hasBackButton) ? '30px' : '0')};
`;

const StyledGrid = styled(Grid)`
  align-items: flex-start;
`;

const StyledName = styled(Typography)`
  padding: 0 14px;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
`;

const DescriptionBar = ({
  backButtonLink,
  children,
  chipLabel,
  descriptionBarInfo,
  goBackLink,
  hasBackButton,
  hasTabs,
  name,
  icon: Icon,
  isHighBar,
  isWarningChip,
  settingsButton,
  onChipClick,
  tabs,
}) => {
  const history = useHistory();

  return (
    <DescriptionBarWrapper>
      <Description
        $hasTabs={hasTabs}
        $isHighBar={isHighBar}
      >
        <StyledButtonsGrid
          container
          item
          justifyContent="space-between"
          $hasBackButton={hasBackButton}
        >
          {hasBackButton && (
            <Button
              color="primary"
              onClick={() => history.push(backButtonLink)}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          )}
          {settingsButton()}
        </StyledButtonsGrid>
        <StyledGrid
          container
          item
          justifyContent="space-between"
        >
          <StyledGrid
            container
            item
            xs
            wrap="nowrap"
          >
            <Icon fontSize="large" />
            {name ? (
              <NameWrapper>
                <StyledName>{name}</StyledName>
                {chipLabel && (
                  <StyledChip
                    $isWarning={isWarningChip}
                    label={chipLabel}
                    size="small"
                    onClick={onChipClick}
                  />
                )}
              </NameWrapper>
            ) : <CircularProgress size={36} /> }
          </StyledGrid>
          <Grid>
            {children}
          </Grid>
          {goBackLink && (
            <Grid>
              <Button
                color="primary"
                onClick={() => history.push(goBackLink)}
                startIcon={<ArrowBackIcon />}
              >
                Go Back
              </Button>
            </Grid>
          )}
        </StyledGrid>
        {descriptionBarInfo}
        {hasTabs && (
          <StyledGrid
            container
            item
            justifyContent="space-between"
          >
            <QueryIndexTabs tabs={tabs} />
          </StyledGrid>
        )}
      </Description>
    </DescriptionBarWrapper>
  );
};

DescriptionBar.defaultProps = {
  backButtonLink: '',
  children: '',
  chipLabel: '',
  descriptionBarInfo: '',
  goBackLink: null,
  hasBackButton: false,
  hasTabs: false,
  isHighBar: false,
  isWarningChip: false,
  name: '',
  onChipClick: null,
  settingsButton: () => {},
  tabs: [],
};

DescriptionBar.propTypes = {
  backButtonLink: PropTypes.string,
  children: PropTypes.node,
  chipLabel: PropTypes.string,
  descriptionBarInfo: PropTypes.node,
  goBackLink: PropTypes.string,
  hasBackButton: PropTypes.bool,
  hasTabs: PropTypes.bool,
  icon: PropTypes.elementType.isRequired,
  isHighBar: PropTypes.bool,
  isWarningChip: PropTypes.bool,
  name: PropTypes.string,
  onChipClick: PropTypes.func,
  settingsButton: PropTypes.func,
  tabs: tabsType,
};

export default DescriptionBar;
