import {
  Link as RouterLink,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

import DropdownMenu from 'common/components/DropdownMenu';
import {
  colors,
  headerLinks,
} from 'utils/constants';
import mediaQueries from 'utils/mediaQueries';

import BroadcastDropdown from 'common/containers/Layout/NavigationDropdowns/BroadcastDropdown';
import CampaignsDropdown from 'common/containers/Layout/NavigationDropdowns/CampaignsDropdown';
import ListsDropdown from 'common/containers/Layout/NavigationDropdowns/ListsDropdown';
import SettingsDropdown from 'common/containers/Layout/NavigationDropdowns/SettingsDropdown';

const { small } = mediaQueries;

const DesktopNav = styled.nav`
  flex: 1;
  margin-left: 5%;

  @media ${small} {
    display: none;
  }
`;

const MobileNav = styled.div`
  display: none;

  @media ${small} {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  padding: 0 20px;
  color: ${colors.white};
  font-size: 16px;
  text-decoration: ${props => (props.isactualroute ? 'underline' : 'none')};
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
`;

const Navigation = () => {
  const matchOrganizationUuid = useRouteMatch({
    path: '/:organizationUuid/',
    strict: true,
  });
  const matchOrganizations = useRouteMatch({
    path: '/organizations',
    strict: true,
  });
  const matchProfile = useRouteMatch({
    path: '/account',
    strict: true,
  });
  const matchCreateOrganization = useRouteMatch({
    path: '/create-organization',
    strict: true,
  });
  const matchCreateEmailBroadcast = useRouteMatch({
    path: '/:organizationUuid/:editedBroadcastUuid?/create-email-broadcast',
    strict: true,
  });
  const matchCreateTargetGroup = useRouteMatch({
    path: '/:organizationUuid/create-target-group',
    strict: true,
  });
  const matchCreateTextBroadcast = useRouteMatch({
    path: '/:organizationUuid/:editedBroadcastUuid?/create-text-broadcast',
    strict: true,
  });
  const matchCreateTextActionAlert = useRouteMatch({
    path: '/:organizationUuid/:editedBroadcastUuid?/create-text-action-alert',
    strict: true,
  });
  const matchCreateEmailActionAlert = useRouteMatch({
    path: '/:organizationUuid/:campaignUuid?/:actionCenterUuid?/create-email-action-alert/',
    strict: true,
  });
  const matchCreateActionCenter = useRouteMatch({
    path: '/:organizationUuid/:actionCenterUuid?/create-action-center/:campaignUuid?',
    strict: true,
  });
  const matchCreateTemplate = useRouteMatch({
    path: '/:organizationUuid/create-template',
    strict: true,
  });
  const matchCreateFilteredList = useRouteMatch({
    path: '/:organizationUuid/contacts/lists/create-filtered-list',
    strict: true,
  });
  const matchCreateSignup = useRouteMatch({
    path: '/:organizationUuid/create-signup',
    strict: true,
  });
  const matchCreateCustomField = useRouteMatch({
    path: '/:organizationUuid/create-custom-fields',
    strict: true,
  });
  const matchJoin = useRouteMatch({
    path: '/join',
    strict: true,
  });
  const matchHelp = useRouteMatch({
    path: '/help',
    strict: true,
  });

  const organizationUuid = matchOrganizationUuid?.params?.organizationUuid === 'account' ? null : matchOrganizationUuid?.params?.organizationUuid;

  const renderHeaderLinks = () => {
    if (
      matchOrganizations?.path ||
      matchProfile?.path ||
      matchCreateOrganization?.path ||
      matchCreateTextBroadcast?.path ||
      matchCreateEmailBroadcast?.path ||
      matchCreateTargetGroup?.path ||
      matchCreateTextActionAlert?.path ||
      matchCreateEmailActionAlert?.path ||
      matchCreateActionCenter?.path ||
      matchCreateTemplate?.path ||
      matchCreateFilteredList?.path ||
      matchCreateSignup?.path ||
      matchCreateCustomField?.path ||
      matchJoin?.path ||
      matchHelp?.path
    ) {
      return false;
    }

    return (
      <>
        <StyledButton
          color="inherit"
          component={RouterLink}
          data-test-id="dashboard-link"
          startIcon={<HomeIcon />}
          to={`/${organizationUuid}/dashboard`}
        />
        <ListsDropdown organizationUuid={organizationUuid} />
        <CampaignsDropdown organizationUuid={organizationUuid} />
        <BroadcastDropdown organizationUuid={organizationUuid} />
        <SettingsDropdown organizationUuid={organizationUuid} />
      </>
    );
  };

  return (
    <>
      <DesktopNav>{renderHeaderLinks()}</DesktopNav>
      <MobileNav>
        <DropdownMenu
          links={headerLinks}
          organizationUuid={organizationUuid}
        />
      </MobileNav>
    </>
  );
};

export default Navigation;
