import {
  CLEAR_ACTION_ALERT_DETAILS,
  CLEAR_ACTION_CENTER_DETAILS,
  DELETE_ACTION_ALERT_FAILURE,
  DELETE_ACTION_ALERT_REQUEST,
  DELETE_ACTION_ALERT_SUCCESS,
  DELETE_ACTION_CENTER_FAILURE,
  DELETE_ACTION_CENTER_REQUEST,
  DELETE_ACTION_CENTER_SUCCESS,
  DELETE_TEXT_ACTION_ALERT_FAILURE,
  DELETE_TEXT_ACTION_ALERT_REQUEST,
  DELETE_TEXT_ACTION_ALERT_SUCCESS,
  EXPORT_ACTION_CENTER_FAILURE,
  EXPORT_ACTION_CENTER_REQUEST,
  EXPORT_ACTION_CENTER_SUCCESS,
  EXPORT_CAMPAIGN_FAILURE,
  EXPORT_CAMPAIGN_REQUEST,
  EXPORT_CAMPAIGN_SUCCESS,
  EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_FAILURE,
  EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_REQUEST,
  EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_SUCCESS,
  EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_FAILURE,
  EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_REQUEST,
  EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_SUCCESS,
  FETCH_ACTION_ALERT_FAILURE,
  FETCH_ACTION_ALERT_LINKS_FAILURE,
  FETCH_ACTION_ALERT_LINKS_REQUEST,
  FETCH_ACTION_ALERT_LINKS_SUCCESS,
  FETCH_ACTION_ALERT_RECIPIENTS_FAILURE,
  FETCH_ACTION_ALERT_RECIPIENTS_REQUEST,
  FETCH_ACTION_ALERT_RECIPIENTS_SUCCESS,
  FETCH_ACTION_ALERT_REQUEST,
  FETCH_ACTION_ALERT_SUCCESS,
  FETCH_ACTION_ALERTS_FAILURE,
  FETCH_ACTION_ALERTS_REQUEST,
  FETCH_ACTION_ALERTS_SUCCESS,
  FETCH_ACTION_CENTER_ACTIONS_FAILURE,
  FETCH_ACTION_CENTER_ACTIONS_REQUEST,
  FETCH_ACTION_CENTER_ACTIONS_SUCCESS,
  FETCH_ACTION_CENTER_FAILURE,
  FETCH_ACTION_CENTER_REQUEST,
  FETCH_ACTION_CENTER_SIGNUPS_FAILURE,
  FETCH_ACTION_CENTER_SIGNUPS_REQUEST,
  FETCH_ACTION_CENTER_SIGNUPS_SUCCESS,
  FETCH_ACTION_CENTER_SUCCESS,
  FETCH_ACTION_CENTER_TARGETS_FAILURE,
  FETCH_ACTION_CENTER_TARGETS_REQUEST,
  FETCH_ACTION_CENTER_TARGETS_SUCCESS,
  FETCH_ACTION_CENTERS_FAILURE,
  FETCH_ACTION_CENTERS_REQUEST,
  FETCH_ACTION_CENTERS_SUCCESS,
  FETCH_ADVOCATES_FAILURE,
  FETCH_ADVOCATES_REQUEST,
  FETCH_ADVOCATES_SUCCESS,
  FETCH_ALL_ACTION_ALERTS_FAILURE,
  FETCH_ALL_ACTION_ALERTS_REQUEST,
  FETCH_ALL_ACTION_ALERTS_SUCCESS,
  FETCH_CAMPAIGN_FAILURE,
  FETCH_CAMPAIGN_REQUEST,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_TARGETS_FAILURE,
  FETCH_CAMPAIGN_TARGETS_REQUEST,
  FETCH_CAMPAIGN_TARGETS_SUCCESS,
  FETCH_CAMPAIGNS_FAILURE,
  FETCH_CAMPAIGNS_REQUEST,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_MATCHED_TARGETS_FAILURE,
  FETCH_MATCHED_TARGETS_REQUEST,
  FETCH_MATCHED_TARGETS_SUCCESS,
  FETCH_TARGET_GROUP_FAILURE,
  FETCH_TARGET_GROUP_REQUEST,
  FETCH_TARGET_GROUP_SUCCESS,
  FETCH_TARGET_GROUPS_FAILURE,
  FETCH_TARGET_GROUPS_REQUEST,
  FETCH_TARGET_GROUPS_SUCCESS,
  FETCH_TEXT_ACTION_ALERT_FAILURE,
  FETCH_TEXT_ACTION_ALERT_RECIPIENTS_FAILURE,
  FETCH_TEXT_ACTION_ALERT_RECIPIENTS_REQUEST,
  FETCH_TEXT_ACTION_ALERT_RECIPIENTS_SUCCESS,
  FETCH_TEXT_ACTION_ALERT_REQUEST,
  FETCH_TEXT_ACTION_ALERT_SUCCESS,
  PATCH_ACTION_ALERT_FAILURE,
  PATCH_ACTION_ALERT_REQUEST,
  PATCH_ACTION_ALERT_SUCCESS,
  PATCH_ACTION_CENTER_ACTION_DETAIL_FAILURE,
  PATCH_ACTION_CENTER_ACTION_DETAIL_REQUEST,
  PATCH_ACTION_CENTER_ACTION_DETAIL_SUCCESS,
  PATCH_ACTION_CENTER_FAILURE,
  PATCH_ACTION_CENTER_REQUEST,
  PATCH_ACTION_CENTER_SUCCESS,
  PATCH_CAMPAIGN_FAILURE,
  PATCH_CAMPAIGN_REQUEST,
  PATCH_CAMPAIGN_SUCCESS,
  PATCH_TARGET_FAILURE,
  PATCH_TARGET_GROUP_FAILURE,
  PATCH_TARGET_GROUP_REQUEST,
  PATCH_TARGET_GROUP_SUCCESS,
  PATCH_TARGET_REQUEST,
  PATCH_TARGET_SUCCESS,
  PATCH_TEXT_ACTION_ALERT_FAILURE,
  PATCH_TEXT_ACTION_ALERT_REQUEST,
  PATCH_TEXT_ACTION_ALERT_SUCCESS,
  POST_ACTION_ALERT_FAILURE,
  POST_ACTION_ALERT_REQUEST,
  POST_ACTION_ALERT_SUCCESS,
  POST_ACTION_CENTER_FAILURE,
  POST_ACTION_CENTER_REQUEST,
  POST_ACTION_CENTER_SUCCESS,
  POST_CAMPAIGN_FAILURE,
  POST_CAMPAIGN_REQUEST,
  POST_CAMPAIGN_SUCCESS,
  POST_CIRCLE_BACK_FAILURE,
  POST_CIRCLE_BACK_REQUEST,
  POST_CIRCLE_BACK_SUCCESS,
  POST_SEND_ACTION_ALERT_FAILURE,
  POST_SEND_ACTION_ALERT_REQUEST,
  POST_SEND_ACTION_ALERT_SUCCESS,
  POST_SEND_MESSAGE_FAILURE,
  POST_SEND_MESSAGE_REQUEST,
  POST_SEND_MESSAGE_SUCCESS,
  POST_SEND_PREVIEW_ACTION_ALERT_FAILURE,
  POST_SEND_PREVIEW_ACTION_ALERT_REQUEST,
  POST_SEND_PREVIEW_ACTION_ALERT_SUCCESS,
  POST_SEND_PREVIEW_TEXT_ACTION_ALERT_FAILURE,
  POST_SEND_PREVIEW_TEXT_ACTION_ALERT_REQUEST,
  POST_SEND_PREVIEW_TEXT_ACTION_ALERT_SUCCESS,
  POST_SEND_TEXT_MESSAGE_FAILURE,
  POST_SEND_TEXT_MESSAGE_REQUEST,
  POST_SEND_TEXT_MESSAGE_SUCCESS,
  POST_TARGET_GROUP_FAILURE,
  POST_TARGET_GROUP_REQUEST,
  POST_TARGET_GROUP_SUCCESS,
  POST_TEXT_ACTION_ALERT_FAILURE,
  POST_TEXT_ACTION_ALERT_REQUEST,
  POST_TEXT_ACTION_ALERT_SUCCESS,
} from '../actions/campaigns';

const initialState = {
  actionAlertDetails: {},
  actionAlertLinks: [],
  actionAlertRecipients: [],
  actionAlertsList: {
    isFetching: false,
    results: [],
  },
  actionCenterActions: {
    isFetching: false,
    results: [],
  },
  actionCenterDetails: {},
  actionCenterSignups: {
    results: [],
  },
  actionCenterTargets: {
    results: [],
  },
  actionCentersList: {
    isFetching: false,
    results: [],
  },
  advocates: [],
  allActionAlertsList: {
    isFetching: false,
    results: [],
  },
  campaignDetails: {},
  campaignTargets: {
    results: [],
  },
  errors: null,
  isActionAlertFetching: false,
  isActionAlertLinksFetching: false,
  isActionAlertRecipientsFetching: false,
  isActionCenterFetching: false,
  isActionCenterSignupsFetching: false,
  isActionCenterTargetsFetching: false,
  isAdvocatesFetching: false,
  isCampaignFetching: false,
  isCampaignTargetsFetching: false,
  isFetching: false,
  isPending: false,
  list: {
    isFetching: false,
    results: [],
  },
  matchedTargets: [],
  targetGroupDetails: {},
  targetGroups: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ACTION_CENTER_DETAILS:
      return {
        ...state,
        actionCenterDetails: {},
      };
    case CLEAR_ACTION_ALERT_DETAILS:
      return {
        ...state,
        actionAlertDetails: {},
      };
    case DELETE_TEXT_ACTION_ALERT_REQUEST:
    case DELETE_ACTION_ALERT_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_TEXT_ACTION_ALERT_SUCCESS:
    case DELETE_ACTION_ALERT_SUCCESS:
      return {
        ...state,
        actionAlertDetails: {},
        isPending: false,
      };
    case DELETE_TEXT_ACTION_ALERT_FAILURE:
    case DELETE_ACTION_ALERT_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_CAMPAIGN_REQUEST:
      return {
        ...state,
        errors: null,
        isCampaignFetching: true,
      };
    case FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignDetails: action.payload,
        isCampaignFetching: false,
      };
    case FETCH_CAMPAIGN_FAILURE:
      return {
        ...state,
        campaignDetails: {},
        errors: action.payload,
        isCampaignFetching: false,
      };
    case FETCH_CAMPAIGN_TARGETS_REQUEST:
      return {
        ...state,
        errors: null,
        isCampaignTargetsFetching: true,
      };
    case FETCH_CAMPAIGN_TARGETS_SUCCESS:
      return {
        ...state,
        campaignTargets: action.payload,
        isCampaignTargetsFetching: false,
      };
    case FETCH_CAMPAIGN_TARGETS_FAILURE:
      return {
        ...state,
        campaignTargets: [],
        errors: action.payload,
        isCampaignTargetsFetching: false,
      };
    case FETCH_CAMPAIGNS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        errors: null,
        list: {
          ...state.list,
          isFetching: false,
          results: action.payload,
        },
      };
    case FETCH_CAMPAIGNS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        list: {
          ...state.list,
          isFetching: false,
          results: [],
        },
      };
    case POST_CAMPAIGN_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_CAMPAIGN_SUCCESS:
      return {
        ...state,
        errors: null,
        isPending: false,
      };
    case POST_CAMPAIGN_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_TEXT_ACTION_ALERT_REQUEST:
    case FETCH_ACTION_ALERT_REQUEST:
      return {
        ...state,
        errors: null,
        isActionAlertFetching: true,
      };
    case FETCH_TEXT_ACTION_ALERT_SUCCESS:
    case FETCH_ACTION_ALERT_SUCCESS:
      return {
        ...state,
        actionAlertDetails: action.payload,
        isActionAlertFetching: false,
      };
    case FETCH_TEXT_ACTION_ALERT_FAILURE:
    case FETCH_ACTION_ALERT_FAILURE:
      return {
        ...state,
        actionAlertDetails: {},
        errors: action.payload,
        isActionAlertFetching: false,
      };
    case FETCH_ACTION_ALERTS_REQUEST:
      return {
        ...state,
        actionAlertsList: {
          ...state.actionAlertsList,
          isFetching: true,
        },
      };
    case FETCH_ACTION_ALERTS_SUCCESS:
      return {
        ...state,
        actionAlertsList: {
          ...state.actionAlertsList,
          isFetching: false,
          results: action.payload,
        },
        errors: null,
      };
    case FETCH_ACTION_ALERTS_FAILURE:
      return {
        ...state,
        actionAlertsList: {
          ...state.actionAlertsList,
          isFetching: false,
          results: [],
        },
        errors: action.payload,
      };
    case POST_TEXT_ACTION_ALERT_REQUEST:
    case POST_ACTION_ALERT_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_TEXT_ACTION_ALERT_SUCCESS:
    case POST_ACTION_ALERT_SUCCESS:
      return {
        ...state,
        actionAlertDetails: action.payload,
        errors: null,
        isPending: false,
      };
    case POST_TEXT_ACTION_ALERT_FAILURE:
    case POST_ACTION_ALERT_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case PATCH_TEXT_ACTION_ALERT_REQUEST:
    case PATCH_ACTION_ALERT_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case PATCH_TEXT_ACTION_ALERT_SUCCESS:
    case PATCH_ACTION_ALERT_SUCCESS:
      return {
        ...state,
        actionAlertDetails: action.payload,
        errors: null,
        isPending: false,
      };
    case PATCH_TEXT_ACTION_ALERT_FAILURE:
    case PATCH_ACTION_ALERT_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case PATCH_TARGET_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case PATCH_TARGET_SUCCESS: {
      const targetsData = [...state.actionCenterTargets.results];
      const targetIndex = targetsData.findIndex(target => target.uuid === action.payload.uuid);

      targetsData[targetIndex] = {
        ...targetsData[targetIndex],
        ...action.payload,
      };

      return {
        ...state,
        actionCenterTargets: {
          ...state.actionCenterTargets,
          results: targetsData,
        },
        errors: null,
        isPending: false,
      };
    }
    case PATCH_TARGET_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_ACTION_CENTER_REQUEST:
      return {
        ...state,
        errors: null,
        isActionCenterFetching: true,
      };
    case FETCH_ACTION_CENTER_SUCCESS:
      return {
        ...state,
        actionCenterDetails: action.payload,
        isActionCenterFetching: false,
      };
    case FETCH_ACTION_CENTER_FAILURE:
      return {
        ...state,
        actionCenterDetails: {},
        errors: action.payload,
        isActionCenterFetching: false,
      };
    case FETCH_ACTION_CENTERS_REQUEST:
      return {
        ...state,
        actionCentersList: {
          ...state.actionCentersList,
          isFetching: true,
        },
      };
    case FETCH_ACTION_CENTERS_SUCCESS:
      return {
        ...state,
        actionCentersList: {
          ...state.actionCentersList,
          isFetching: false,
          results: action.payload,
        },
        errors: null,
      };
    case FETCH_ACTION_CENTERS_FAILURE:
      return {
        ...state,
        actionCentersList: {
          ...state.actionCentersList,
          isFetching: false,
          results: [],
        },
        errors: action.payload,
      };
    case POST_ACTION_CENTER_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_ACTION_CENTER_SUCCESS:
      return {
        ...state,
        actionCenterDetails: action.payload,
        errors: null,
        isPending: false,
      };
    case POST_ACTION_CENTER_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case PATCH_ACTION_CENTER_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case PATCH_ACTION_CENTER_SUCCESS:
      return {
        ...state,
        actionCenterDetails: {
          ...state.actionCenterDetails,
          ...action.payload,
        },
        errors: null,
        isPending: false,
      };
    case PATCH_ACTION_CENTER_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case POST_SEND_ACTION_ALERT_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_SEND_ACTION_ALERT_SUCCESS:
      return {
        ...state,
        errors: null,
        isPending: false,
      };
    case POST_SEND_ACTION_ALERT_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case POST_CIRCLE_BACK_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_CIRCLE_BACK_SUCCESS:
      return {
        ...state,
        errors: null,
        isPending: false,
      };
    case POST_CIRCLE_BACK_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_MATCHED_TARGETS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_MATCHED_TARGETS_SUCCESS:
      return {
        ...state,
        errors: null,
        isFetching: false,
        matchedTargets: action.payload,
      };
    case FETCH_MATCHED_TARGETS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
        matchedTargets: [],
      };
    case FETCH_TARGET_GROUPS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_TARGET_GROUPS_SUCCESS:
      return {
        ...state,
        errors: null,
        isFetching: false,
        targetGroups: action.payload,
      };
    case FETCH_TARGET_GROUPS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
        targetGroups: {},
      };
    case FETCH_TARGET_GROUP_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_TARGET_GROUP_SUCCESS:
      return {
        ...state,
        errors: null,
        isFetching: false,
        targetGroupDetails: action.payload,
      };
    case FETCH_TARGET_GROUP_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
        targetGroupDetails: {},
      };
    case POST_SEND_PREVIEW_TEXT_ACTION_ALERT_REQUEST:
    case POST_SEND_PREVIEW_ACTION_ALERT_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_SEND_PREVIEW_TEXT_ACTION_ALERT_SUCCESS:
    case POST_SEND_PREVIEW_ACTION_ALERT_SUCCESS:
      return {
        ...state,
        errors: null,
        isPending: false,
      };
    case POST_SEND_PREVIEW_TEXT_ACTION_ALERT_FAILURE:
    case POST_SEND_PREVIEW_ACTION_ALERT_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case POST_SEND_TEXT_MESSAGE_REQUEST:
    case POST_SEND_MESSAGE_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_SEND_TEXT_MESSAGE_SUCCESS:
    case POST_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        errors: null,
        isPending: false,
      };
    case PATCH_TARGET_GROUP_FAILURE:
    case POST_TARGET_GROUP_FAILURE:
    case POST_SEND_TEXT_MESSAGE_FAILURE:
    case POST_SEND_MESSAGE_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case PATCH_TARGET_GROUP_REQUEST:
    case POST_TARGET_GROUP_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case PATCH_TARGET_GROUP_SUCCESS:
    case POST_TARGET_GROUP_SUCCESS:
      return {
        ...state,
        errors: null,
        isPending: false,
      };
    case EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_REQUEST:
    case EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_REQUEST:
    case EXPORT_ACTION_CENTER_REQUEST:
    case EXPORT_CAMPAIGN_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_SUCCESS:
    case EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_SUCCESS:
    case EXPORT_ACTION_CENTER_SUCCESS:
    case EXPORT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case EXPORT_MESSAGES_REPORT_FOR_CAMPAIGN_FAILURE:
    case EXPORT_MESSAGES_REPORT_FOR_ACTION_CENTER_FAILURE:
    case EXPORT_ACTION_CENTER_FAILURE:
    case EXPORT_CAMPAIGN_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case PATCH_ACTION_CENTER_ACTION_DETAIL_REQUEST:
    case FETCH_ACTION_CENTER_ACTIONS_REQUEST:
      return {
        ...state,
        actionCenterActions: {
          ...state.actionCenterActions,
          isFetching: true,
        },
        errors: null,
      };
    case FETCH_ACTION_CENTER_ACTIONS_SUCCESS:
      return {
        ...state,
        actionCenterActions: {
          isFetching: false,
          results: action.payload,
        },
      };
    case PATCH_ACTION_CENTER_ACTION_DETAIL_FAILURE:
    case FETCH_ACTION_CENTER_ACTIONS_FAILURE:
      return {
        ...state,
        actionCenterActions: {
          isFetching: false,
          results: [],
        },
        errors: action.payload,
      };
    case PATCH_ACTION_CENTER_ACTION_DETAIL_SUCCESS: {
      const actionsData = [...state.actionCenterActions.results];
      const actionIndex = actionsData.findIndex(({ uuid }) => uuid === action.payload.uuid);

      actionsData[actionIndex] = {
        ...actionsData[actionIndex],
        ...action.payload,
      };

      return {
        ...state,
        actionCenterActions: {
          ...state.actionCenterActions,
          isFetching: false,
          results: actionsData,
        },
        errors: null,
      };
    }
    case FETCH_ACTION_CENTER_TARGETS_REQUEST:
      return {
        ...state,
        errors: null,
        isActionCenterTargetsFetching: true,
      };
    case FETCH_ACTION_CENTER_TARGETS_SUCCESS:
      return {
        ...state,
        actionCenterTargets: action.payload,
        isActionCenterTargetsFetching: false,
      };
    case FETCH_ACTION_CENTER_TARGETS_FAILURE:
      return {
        ...state,
        actionCenterTargets: {
          results: [],
        },
        errors: action.payload,
        isActionCenterTargetsFetching: false,
      };
    case FETCH_ACTION_CENTER_SIGNUPS_REQUEST:
      return {
        ...state,
        errors: null,
        isActionCenterSignupsFetching: true,
      };
    case FETCH_ACTION_CENTER_SIGNUPS_SUCCESS:
      return {
        ...state,
        actionCenterSignups: action.payload,
        isActionCenterSignupsFetching: false,
      };
    case FETCH_ACTION_CENTER_SIGNUPS_FAILURE:
      return {
        ...state,
        actionCenterSignups: {},
        errors: action.payload,
        isActionCenterSignupsFetching: false,
      };
    case FETCH_ADVOCATES_REQUEST:
      return {
        ...state,
        errors: null,
        isAdvocatesFetching: true,
      };
    case FETCH_ADVOCATES_SUCCESS:
      return {
        ...state,
        advocates: action.payload,
        isAdvocatesFetching: false,
      };
    case FETCH_ADVOCATES_FAILURE:
      return {
        ...state,
        advocates: [],
        errors: action.payload,
        isAdvocatesFetching: false,
      };
    case FETCH_ACTION_ALERT_RECIPIENTS_REQUEST:
    case FETCH_TEXT_ACTION_ALERT_RECIPIENTS_REQUEST:
      return {
        ...state,
        isActionAlertRecipientsFetching: true,
      };
    case FETCH_TEXT_ACTION_ALERT_RECIPIENTS_SUCCESS:
    case FETCH_ACTION_ALERT_RECIPIENTS_SUCCESS:
      return {
        ...state,
        actionAlertRecipients: action.payload,
        errors: null,
        isActionAlertRecipientsFetching: false,
      };
    case FETCH_TEXT_ACTION_ALERT_RECIPIENTS_FAILURE:
    case FETCH_ACTION_ALERT_RECIPIENTS_FAILURE:
      return {
        ...state,
        actionAlertRecipients: {
          ...state.actionAlertRecipients,
          results: [],
        },
        errors: action.payload,
        isActionAlertRecipientsFetching: false,
      };
    case FETCH_ACTION_ALERT_LINKS_REQUEST:
      return {
        ...state,
        isActionAlertLinksFetching: true,
      };
    case FETCH_ACTION_ALERT_LINKS_SUCCESS:
      return {
        ...state,
        actionAlertLinks: action.payload,
        errors: null,
        isActionAlertLinksFetching: false,
      };
    case FETCH_ACTION_ALERT_LINKS_FAILURE:
      return {
        ...state,
        actionAlertLinks: [],
        errors: action.payload,
        isActionAlertLinksFetching: false,
      };
    case PATCH_CAMPAIGN_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case PATCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignDetails: {
          ...state.campaignDetails,
          ...action.payload,
        },
        errors: null,
        isPending: false,
      };
    case PATCH_CAMPAIGN_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case DELETE_ACTION_CENTER_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_ACTION_CENTER_SUCCESS:
      return {
        ...state,
        actionCenterDetails: {},
        isPending: false,
      };
    case DELETE_ACTION_CENTER_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_ALL_ACTION_ALERTS_REQUEST:
      return {
        ...state,
        allActionAlertsList: {
          ...state.allActionAlertsList,
          isFetching: true,
        },
      };
    case FETCH_ALL_ACTION_ALERTS_SUCCESS:
      return {
        ...state,
        allActionAlertsList: {
          ...state.allActionAlertsList,
          ...action.payload,
          isFetching: false,
        },
        errors: null,
      };
    case FETCH_ALL_ACTION_ALERTS_FAILURE:
      return {
        ...state,
        allActionAlertsList: {
          ...state.allActionAlertsList,
          isFetching: false,
          results: [],
        },
        errors: action.payload,
      };
    default:
      return state;
  }
};
