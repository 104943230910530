import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Link as RouterLink,
  useParams,
} from 'react-router-dom';

import {
  Button,
  Card,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';

import { DetailsButton } from 'common/components';

const ActionsCountWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding-top: 15px 0 0 0;
`;

const StyledCard = styled(Card)`
  overflow-x: auto;
  padding: 32px 26px;

  &:not(:last-of-type) {
    border-bottom: 0;
  }
`;

const StyledGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

const StyledName = styled.div`
  display: flex;
  align-items: center;
`;

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-right: 8px;
`;

const StyledDetailsButton = styled(DetailsButton)`
  margin-right: 8px;
`;

const TargetGroupItem = ({
  handleRemoveTargetGroup,
  name,
  uuid,
}) => {
  const {
    organizationUuid,
  } = useParams();

  return (
    <StyledCard
      variant="outlined"
      square
    >
      <StyledGrid
        container
        item
        wrap="nowrap"
      >
        <StyledName>
          <Link
            component={RouterLink}
            to={`/${organizationUuid}/target-groups/${uuid}`}
          >
            <Typography variant="h5">
              {name}
            </Typography>
          </Link>
        </StyledName>
        <ActionsCountWrapper>
          <StyledButton
            onClick={() => handleRemoveTargetGroup(uuid)}
            size="small"
            startIcon={<DeleteOutlineIcon />}
            variant="outlined"
          >
            Remove Group
          </StyledButton>
        </ActionsCountWrapper>
        <DetailsWrapper>
          <StyledDetailsButton to={`/${organizationUuid}/target-groups/${uuid}`} />
        </DetailsWrapper>
      </StyledGrid>
    </StyledCard>
  );
};

TargetGroupItem.propTypes = {
  handleRemoveTargetGroup: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default TargetGroupItem;
