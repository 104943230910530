import {
  FETCH_COMMITTEES_FAILURE,
  FETCH_COMMITTEES_REQUEST,
  FETCH_COMMITTEES_SUCCESS,
  FETCH_SEARCHED_OFFICIALS_FAILURE,
  FETCH_SEARCHED_OFFICIALS_REQUEST,
  FETCH_SEARCHED_OFFICIALS_SUCCESS,
  RESET_COMMITTEES,
} from '../actions/officials';

const initialState = {
  committees: {
    results: [],
  },
  errors: null,
  isFetching: false,
  searchedOfficials: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCHED_OFFICIALS_REQUEST:
      return {
        ...state,
        errors: null,
        isFetching: true,
      };
    case FETCH_SEARCHED_OFFICIALS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        searchedOfficials: action.payload,
      };
    case FETCH_SEARCHED_OFFICIALS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
        searchedOfficials: [],
      };
    case FETCH_COMMITTEES_REQUEST:
      return {
        ...state,
        errors: null,
        isFetching: true,
      };
    case FETCH_COMMITTEES_SUCCESS:
      return {
        ...state,
        committees: action.payload,
        isFetching: false,
      };
    case FETCH_COMMITTEES_FAILURE:
      return {
        ...state,
        committees: {
          ...state.committees,
          result: [],
        },
        errors: action.payload,
        isFetching: false,
      };
    case RESET_COMMITTEES:
      return {
        ...state,
        committees: {
          ...state.committees,
          results: [],
        },
      };
    default:
      return state;
  }
};
