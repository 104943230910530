import {
  useEffect,
  useMemo,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';

import {
  Grid,
} from '@mui/material';

import {
  fetchInvitations,
  fetchTeamMembers,
  patchOrganization,
  postInviteAdmin,
} from 'store/actions/organizations';
import { organizationSelectors } from 'store/selectors/organizations';

import {
  Backdrop,
  BreadcrumbsNavigation,
  Container,
  TabPanel,
} from 'common/components';

import { useQuery } from 'utils/helpers';
import BrandingTab from 'routes/Settings/components/BrandingTab';
import SettingsTab from 'routes/Settings/components/SettingsTab';
import AdminTab from 'routes/Settings/components/AdminTab';
import IntegrationsTab from 'routes/Settings/components/IntegrationsTab';
import DomainTab from 'routes/Settings/components/DomainTab';
import SettingsTabs from 'routes/Settings/components/SettingsTabs';
import { CardWrapper } from 'globalStyles';

const {
  REACT_APP_EMAIL_SETTINGS,
  REACT_APP_FRONTEND_URL,
} = process.env;

const Settings = () => {
  const dispatch = useDispatch();
  const { organizationUuid } = useParams();
  const history = useHistory();
  const query = useQuery();
  const tabIndex = query.get('tab');

  const {
    isDataFetching,
    organization,
  } = useSelector(state => ({
    isDataFetching:
      organizationSelectors.isPending(state),
    organization: organizationSelectors.getOrganization(state),
  }));

  const {
    city,
    config,
    email,
    facebookUsername,
    flags,
    linkedinPage,
    logo,
    name,
    phone,
    state,
    streetAddress,
    streetAddress2,
    twitterUsername,
    websiteUrl,
    privacyPolicyUrl,
    zipCode,
  } = organization;

  const settingTabs = [
    {
      label: 'Account Information',
      value: 'account-information',
    },
    {
      label: 'Branding',
      value: 'branding',
    },
    {
      label: 'Team',
      value: 'team',
    },
    {
      label: 'Integrations',
      value: 'integrations',
    },
    {
      disabled: flags ?
        !flags.showBroadcasts :
        true,
      label: 'Email',
      value: 'email',
    },
  ];

  useEffect(() => {
    dispatch(fetchInvitations(organizationUuid));
    dispatch(fetchTeamMembers(organizationUuid));

    if (!tabIndex) {
      history.push({
        search: `?tab=${settingTabs[0].value}`,
      });
    }
  }, []);

  const onSubmit = async values => {
    const params = { ...values };

    try {
      await dispatch(patchOrganization(organizationUuid, params));

      return 'success';
    } catch (error) {
      return error;
    }
  };

  const handleInvite = async values => {
    const params = {
      inviteUrl: `${REACT_APP_FRONTEND_URL}/join`,
      ...values,
    };

    try {
      await dispatch(postInviteAdmin(organizationUuid, params));
      await dispatch(fetchInvitations(organizationUuid));

      return 'success';
    } catch (error) {
      return error;
    }
  };

  const initialBrandingValues = {
    facebookUsername,
    linkedinPage,
    twitterUsername,
  };

  const initialSettingsValues = {
    city,
    email,
    name,
    phone,
    privacyPolicyUrl,
    state,
    streetAddress,
    streetAddress2,
    websiteUrl,
    zipCode,
  };

  const breadcrumbs = useMemo(() => [
    {
      link: `/${organizationUuid}/settings`,
      name: 'Settings',
    },
  ], [organizationUuid]);

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container marginBottom="40">
        <Grid container>
          <CardWrapper>
            <SettingsTabs tabs={settingTabs} />
            <TabPanel
              index={settingTabs[0].value}
              value={tabIndex}
            >
              <SettingsTab
                canEditOrganization={!!flags?.canEditOrganization}
                initialValues={initialSettingsValues}
                onSubmit={onSubmit}
                onInvite={handleInvite}
              />
            </TabPanel>
            <TabPanel
              index={settingTabs[1].value}
              value={tabIndex}
            >
              <BrandingTab
                canEditOrganization={!!flags?.canEditOrganization}
                config={config}
                initialValues={initialBrandingValues}
                logo={logo}
                organizationUuid={organizationUuid}
                onSubmit={onSubmit}
              />
            </TabPanel>
            <TabPanel
              index={settingTabs[2].value}
              value={tabIndex}
            >
              <AdminTab
                logo={logo}
                onInvite={handleInvite}
              />
            </TabPanel>
            <TabPanel
              index={settingTabs[3].value}
              value={tabIndex}
            >
              <IntegrationsTab
                flags={flags}
              />
            </TabPanel>
            <TabPanel
              index={settingTabs[4].value}
              disabled
              value={tabIndex}
            >
              {REACT_APP_EMAIL_SETTINGS && (
                <DomainTab />
              )}
            </TabPanel>
          </CardWrapper>
        </Grid>

        <Backdrop isOpen={isDataFetching} />
      </Container>
    </>
  );
};

export default Settings;
