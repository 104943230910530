import {
  createElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AccordionDetails,
  AccordionSummary,
  Accordion as MaterialAccordion,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  /* stylelint-disable-next-line selector-class-pattern */
  .MuiAccordionSummary-content {
    align-items: center;
  }
`;

const Accordion = ({
  children,
  className,
  disabled,
  options,
  title,
}) => {
  const materialAccordionRef = useRef(null);

  const [
    isExpanded,
    setIsExpanded,
  ] = useState(false);

  const handleOnChange = () => {
    if (disabled) {
      setIsExpanded(false);
    } else {
      setIsExpanded(prevValue => !prevValue);
    }
  };

  const isAccordionDisabled = materialAccordionRef?.current?.classList.contains('Mui-disabled');

  useEffect(() => {
    if (materialAccordionRef?.current?.classList.contains('Mui-disabled')) {
      setIsExpanded(false);
    }
  }, [isAccordionDisabled]);

  useEffect(() => {
    if (disabled) {
      setIsExpanded(false);
    }
  }, [disabled]);

  return (
    <MaterialAccordion
      ref={materialAccordionRef}
      className={className}
      disabled={disabled}
      expanded={isExpanded}
      onChange={handleOnChange}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        data-testid="accordion-summary"
      >
        <Typography>{title}</Typography>
        <OptionsWrapper>
          {options}
        </OptionsWrapper>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {children}
      </StyledAccordionDetails>
    </MaterialAccordion>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.node,
  title: PropTypes.string.isRequired,
};

Accordion.defaultProps = {
  disabled: false,
  options: createElement('div'),
};

export default Accordion;
