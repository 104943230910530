import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
  Alert,
  Snackbar as MaterialSnackbar,
} from '@mui/material';

import { globalSelectors } from 'store/selectors/global';

const Snackbar = () => {
  const errorFirstUpdate = useRef(true);
  const successFirstUpdate = useRef(true);
  const warningFirstUpdate = useRef(true);

  const {
    snackbarError,
    snackbarSuccess,
    snackbarWarning,
  } = useSelector(state => ({
    snackbarError: globalSelectors.getSnackbarError(state),
    snackbarSuccess: globalSelectors.getSnackbarSuccess(state),
    snackbarWarning: globalSelectors.getSnackbarWarning(state),
  }));

  const initialSnackbarData = {
    message: '',
    open: false,
  };

  const [
    snackbarData,
    setSnackbarData,
  ] = useState(initialSnackbarData);

  useEffect(() => {
    if (errorFirstUpdate.current) {
      errorFirstUpdate.current = false;

      return;
    }
    setSnackbarData({
      message: snackbarError.message,
      open: true,
      severity: 'error',
    });
  }, [snackbarError]);

  useEffect(() => {
    if (successFirstUpdate.current) {
      successFirstUpdate.current = false;

      return;
    }

    setSnackbarData({
      message: snackbarSuccess.message,
      open: true,
      severity: 'success',
    });
  }, [snackbarSuccess]);

  useEffect(() => {
    if (warningFirstUpdate.current) {
      warningFirstUpdate.current = false;

      return;
    }

    setSnackbarData({
      message: snackbarWarning.message,
      open: true,
      severity: 'warning',
    });
  }, [snackbarWarning]);

  return (
    <MaterialSnackbar
      open={snackbarData.open}
      autoHideDuration={snackbarData.duration || 3000}
      onClose={() => setSnackbarData(prevValue => ({
        ...prevValue,
        open: false,
      }))}
    >
      <Alert
        onClose={() => setSnackbarData(prevValue => ({
          ...prevValue,
          open: false,
        }))}
        severity={snackbarData.severity || 'info'}
      >
        {snackbarData.message}
      </Alert>
    </MaterialSnackbar>
  );
};

export default Snackbar;
