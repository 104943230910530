/* stylelint-disable selector-class-pattern */
import styled from 'styled-components';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import Select from 'material/components/Select';

export const StyledSelect = styled(Select)``;

export const DateFilter = styled(MobileDatePicker)`
  width: 150px;
  padding: 0;

  .MuiPickersToolbar-root {
    padding: 0;
  }

  .MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 30px;
`;
