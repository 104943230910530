import request from '../../utils/request';

const PREFIX = '[CONTACTS]';

export const FETCH_CHILDREN_LISTS_REQUEST = `${PREFIX} FETCH_CHILDREN_LISTS_REQUEST`;
export const FETCH_CHILDREN_LISTS_SUCCESS = `${PREFIX} FETCH_CHILDREN_LISTS_SUCCESS`;
export const FETCH_CHILDREN_LISTS_FAILURE = `${PREFIX} FETCH_CHILDREN_LISTS_FAILURE`;

const fetchChildrenListsRequest = () => ({
  type: FETCH_CHILDREN_LISTS_REQUEST,
});

const fetchChildrenListsSuccess = payload => ({
  payload,
  type: FETCH_CHILDREN_LISTS_SUCCESS,
});

const fetchChildrenListsFailure = payload => ({
  payload,
  type: FETCH_CHILDREN_LISTS_FAILURE,
});

export const fetchChildrenLists = (listId, organizationId) => dispatch => {
  dispatch(fetchChildrenListsRequest());

  return request
    .get(`/v1/contacts/lists/${listId}/children/`)
    .then(({ data }) => {
      dispatch(fetchChildrenListsSuccess({
        data: data.filter(({ name }) => name),
        organizationId,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchChildrenListsFailure({
        data,
        organizationId,
      }));

      throw data;
    });
};

export const FETCH_ROOT_LIST_REQUEST = `${PREFIX} FETCH_ROOT_LIST_REQUEST`;
export const FETCH_ROOT_LIST_SUCCESS = `${PREFIX} FETCH_ROOT_LIST_SUCCESS`;
export const FETCH_ROOT_LIST_FAILURE = `${PREFIX} FETCH_ROOT_LIST_FAILURE`;

const fetchRootListRequest = () => ({
  type: FETCH_ROOT_LIST_REQUEST,
});

const fetchRootListSuccess = payload => ({
  payload,
  type: FETCH_ROOT_LIST_SUCCESS,
});

const fetchRootListFailure = payload => ({
  payload,
  type: FETCH_ROOT_LIST_FAILURE,
});

export const fetchRootList = organizationId => dispatch => {
  dispatch(fetchRootListRequest());

  return request
    .get(`/v1/contacts/lists/${organizationId}/organization_root_list/`)
    .then(({ data }) => {
      dispatch(fetchRootListSuccess({
        data,
        organizationId,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchRootListFailure(data));

      throw data;
    });
};

export const POST_CONTACT_REQUEST = `${PREFIX} POST_CONTACT_REQUEST`;
export const POST_CONTACT_SUCCESS = `${PREFIX} POST_CONTACT_SUCCESS`;
export const POST_CONTACT_FAILURE = `${PREFIX} POST_CONTACT_FAILURE`;

const postContactRequest = () => ({
  type: POST_CONTACT_REQUEST,
});

const postContactSuccess = payload => ({
  payload,
  type: POST_CONTACT_SUCCESS,
});

const postContactFailure = payload => ({
  payload,
  type: POST_CONTACT_FAILURE,
});

export const postContact = (id, params) => dispatch => {
  dispatch(postContactRequest());

  return request
    .post(`/v1/contacts/lists/${id}/contacts/`, params)
    .then(({ data }) => {
      dispatch(postContactSuccess(data));
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postContactFailure(data));

      throw data;
    });
};

export const FETCH_ALL_LIST_CONTACTS_REQUEST = `${PREFIX} FETCH_ALL_LIST_CONTACT_REQUEST`;
export const FETCH_ALL_LIST_CONTACTS_IN_PROGRESS = `${PREFIX} FETCH_ALL_LIST_CONTACTS_IN_PROGRESS`;
export const FETCH_ALL_LIST_CONTACTS_SUCCESS = `${PREFIX} FETCH_ALL_LIST_CONTACT_SUCCESS`;
export const FETCH_ALL_LIST_CONTACTS_FAILURE = `${PREFIX} FETCH_ALL_LIST_CONTACT_FAILURE`;

const fetchAllListContactRequest = () => ({
  type: FETCH_ALL_LIST_CONTACTS_REQUEST,
});

const fetchAllListContactInProgress = payload => ({
  payload,
  type: FETCH_ALL_LIST_CONTACTS_IN_PROGRESS,
});

const fetchAllListContactSuccess = payload => ({
  payload,
  type: FETCH_ALL_LIST_CONTACTS_SUCCESS,
});

const fetchAllListContactFailure = payload => ({
  payload,
  type: FETCH_ALL_LIST_CONTACTS_FAILURE,
});

export const fetchAllListContact = listId => dispatch => {
  dispatch(fetchAllListContactRequest());
  const contacts = [];

  const fetchAllContactsWithPagination = next => request
    .get(next || `/v1/contacts/lists/${listId}/contacts/`)
    .then(({ data }) => {
      contacts.push(...data.results);

      dispatch(fetchAllListContactInProgress({
        allCount: data.count,
        fetchedCount: data.results.length,
      }));

      if (!data.next) {
        return contacts;
      }

      return fetchAllContactsWithPagination(data.next);
    });

  return fetchAllContactsWithPagination()
    .then(data => {
      dispatch(fetchAllListContactSuccess({
        data,
        listId,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchAllListContactFailure({
        data,
        listId,
      }));

      throw data;
    });
};

export const FETCH_LIST_REQUEST = `${PREFIX} FETCH_LIST_REQUEST`;
export const FETCH_LIST_SUCCESS = `${PREFIX} FETCH_LIST_SUCCESS`;
export const FETCH_LIST_FAILURE = `${PREFIX} FETCH_LIST_FAILURE`;

const fetchListRequest = () => ({
  type: FETCH_LIST_REQUEST,
});

const fetchListSuccess = payload => ({
  payload,
  type: FETCH_LIST_SUCCESS,
});

const fetchListFailure = payload => ({
  payload,
  type: FETCH_LIST_FAILURE,
});

export const fetchList = uuid => dispatch => {
  dispatch(fetchListRequest());

  return request
    .get(`/v1/contacts/lists/${uuid}/with_filter_details/`)
    .then(({ data }) => {
      dispatch(fetchListSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchListFailure(data));

      throw data;
    });
};

export const FETCH_LIST_CONTACTS_LOCATIONS_REQUEST = `${PREFIX} FETCH_LIST_CONTACTS_LOCATIONS_REQUEST`;
export const FETCH_LIST_CONTACTS_LOCATIONS_SUCCESS = `${PREFIX} FETCH_LIST_CONTACTS_LOCATIONS_SUCCESS`;
export const FETCH_LIST_CONTACTS_LOCATIONS_FAILURE = `${PREFIX} FETCH_LIST_CONTACTS_LOCATIONS_FAILURE`;

const fetchListContactsLocationsRequest = () => ({
  type: FETCH_LIST_CONTACTS_LOCATIONS_REQUEST,
});

const fetchListContactsLocationsSuccess = payload => ({
  payload,
  type: FETCH_LIST_CONTACTS_LOCATIONS_SUCCESS,
});

const fetchListContactsLocationsFailure = payload => ({
  payload,
  type: FETCH_LIST_CONTACTS_LOCATIONS_FAILURE,
});

export const fetchListContactsLocations = (listId, southWestCoords, northEastCoords) => (
  dispatch => {
    dispatch(fetchListContactsLocationsRequest());

    const fetchListContactsLocationsWithPagination = next => request
      .get(next || `v1/contacts/lists/${listId}/contacts/locations/?sw=${southWestCoords}&ne==${northEastCoords}`)
      .then(({ data }) => {
        if (data.next) {
          fetchListContactsLocationsWithPagination(data.next);
        }

        return {
          count: data.count,
          results: data.results,
        };
      });

    return fetchListContactsLocationsWithPagination()
      .then(data => {
        dispatch(fetchListContactsLocationsSuccess(data));

        return data;
      })
      .catch(({ response: { data } = {} }) => {
        dispatch(fetchListContactsLocationsFailure({
          data,
          listId,
        }));

        throw data;
      });
  }
);

export const EXPORT_LIST_REQUEST = `${PREFIX} EXPORT_LIST_REQUEST`;
export const EXPORT_LIST_SUCCESS = `${PREFIX} EXPORT_LIST_SUCCESS`;
export const EXPORT_LIST_FAILURE = `${PREFIX} EXPORT_LIST_FAILURE`;

const exportListRequest = () => ({
  type: EXPORT_LIST_REQUEST,
});

const exportListSuccess = payload => ({
  payload,
  type: EXPORT_LIST_SUCCESS,
});

const exportListFailure = payload => ({
  payload,
  type: EXPORT_LIST_FAILURE,
});

export const exportList = uuid => dispatch => {
  dispatch(exportListRequest());

  return request
    .post(`/v1/contacts/lists/${uuid}/export/`)
    .then(({ data }) => {
      dispatch(exportListSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(exportListFailure(data));

      throw data;
    });
};

export const UPDATE_CONTACTS_LIST_REQUEST = `${PREFIX} UPDATE_CONTACTS_LIST_REQUEST`;
export const UPDATE_CONTACTS_LIST_SUCCESS = `${PREFIX} UPDATE_CONTACTS_LIST_SUCCESS`;
export const UPDATE_CONTACTS_LIST_FAILURE = `${PREFIX} UPDATE_CONTACTS_LIST_FAILURE`;

const updateContactsListRequest = () => ({
  type: UPDATE_CONTACTS_LIST_REQUEST,
});

const updateContactsListSuccess = payload => ({
  payload,
  type: UPDATE_CONTACTS_LIST_SUCCESS,
});

const updateContactsListFailure = payload => ({
  payload,
  type: UPDATE_CONTACTS_LIST_FAILURE,
});

export const updateContactsList = (uuid, organizationId, params) => dispatch => {
  dispatch(updateContactsListRequest());

  return request
    .put(`/v1/contacts/lists/${uuid}/`, params)
    .then(({ data }) => {
      dispatch(updateContactsListSuccess({
        data,
        organizationId,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(updateContactsListFailure(data));

      throw data;
    });
};

export const DELETE_LIST_CONTACT_REQUEST = `${PREFIX} DELETE_LIST_CONTACT_REQUEST`;
export const DELETE_LIST_CONTACT_SUCCESS = `${PREFIX} DELETE_LIST_CONTACT_SUCCESS`;
export const DELETE_LIST_CONTACT_FAILURE = `${PREFIX} DELETE_LIST_CONTACT_FAILURE`;

const deleteListContactRequest = () => ({
  type: DELETE_LIST_CONTACT_REQUEST,
});

const deleteListContactSuccess = payload => ({
  payload,
  type: DELETE_LIST_CONTACT_SUCCESS,
});

const deleteListContactFailure = payload => ({
  payload,
  type: DELETE_LIST_CONTACT_FAILURE,
});

export const deleteListContact = (listUuid, uuid) => dispatch => {
  dispatch(deleteListContactRequest());

  return request
    .delete(`/v1/contacts/lists/${listUuid}/contacts/${uuid}/`)
    .then(({ data }) => {
      dispatch(deleteListContactSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteListContactFailure(data));

      throw data;
    });
};

export const DELETE_ALL_LIST_CONTACTS_REQUEST = `${PREFIX} DELETE_ALL_LIST_CONTACTS_REQUEST`;
export const DELETE_ALL_LIST_CONTACTS_SUCCESS = `${PREFIX} DELETE_ALL_LIST_CONTACTS_SUCCESS`;
export const DELETE_ALL_LIST_CONTACTS_FAILURE = `${PREFIX} DELETE_ALL_LIST_CONTACTS_FAILURE`;

const deleteAllListContactsRequest = () => ({
  type: DELETE_ALL_LIST_CONTACTS_REQUEST,
});

const deleteAllListContactsSuccess = payload => ({
  payload,
  type: DELETE_ALL_LIST_CONTACTS_SUCCESS,
});

const deleteAllListContactsFailure = payload => ({
  payload,
  type: DELETE_ALL_LIST_CONTACTS_FAILURE,
});

export const deleteAllListContacts = listUuid => dispatch => {
  dispatch(deleteAllListContactsRequest());

  return request
    .delete(`/v1/contacts/lists/${listUuid}/contacts/delete_all/`)
    .then(({ data }) => {
      dispatch(deleteAllListContactsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteAllListContactsFailure(data));

      throw data;
    });
};

export const POST_LIST_REQUEST = `${PREFIX} POST_LIST_REQUEST`;
export const POST_LIST_SUCCESS = `${PREFIX} POST_LIST_SUCCESS`;
export const POST_LIST_FAILURE = `${PREFIX} POST_LIST_FAILURE`;

const postListRequest = () => ({
  type: POST_LIST_REQUEST,
});

const postListSuccess = payload => ({
  payload,
  type: POST_LIST_SUCCESS,
});

const postListFailure = payload => ({
  payload,
  type: POST_LIST_FAILURE,
});

export const postList = params => dispatch => {
  dispatch(postListRequest());

  return request
    .post('/v1/contacts/lists/', params)
    .then(({ data }) => {
      dispatch(postListSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postListFailure(data));

      throw data;
    });
};

export const FETCH_CONTACT_REQUEST = `${PREFIX} FETCH_CONTACT_REQUEST`;
export const FETCH_CONTACT_SUCCESS = `${PREFIX} FETCH_CONTACT_SUCCESS`;
export const FETCH_CONTACT_FAILURE = `${PREFIX} FETCH_CONTACT_FAILURE`;

const fetchContactRequest = () => ({
  type: FETCH_CONTACT_REQUEST,
});

const fetchContactRequestSuccess = payload => ({
  payload,
  type: FETCH_CONTACT_SUCCESS,
});

const fetchContactRequestFailure = payload => ({
  payload,
  type: FETCH_CONTACT_FAILURE,
});

export const fetchContact = (listUuid, contactUuid) => dispatch => {
  dispatch(fetchContactRequest());

  return request
    .get(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/`)
    .then(({ data }) => {
      dispatch(fetchContactRequestSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchContactRequestFailure(data));

      throw data;
    });
};

export const UPDATE_CONTACT_REQUEST = `${PREFIX} UPDATE_CONTACT_REQUEST`;
export const UPDATE_CONTACT_SUCCESS = `${PREFIX} UPDATE_CONTACT_SUCCESS`;
export const UPDATE_CONTACT_FAILURE = `${PREFIX} UPDATE_CONTACT_FAILURE`;

const updateContactRequest = () => ({
  type: UPDATE_CONTACT_REQUEST,
});

const updateContactSuccess = payload => ({
  payload,
  type: UPDATE_CONTACT_SUCCESS,
});

const updateContactFailure = payload => ({
  payload,
  type: UPDATE_CONTACT_FAILURE,
});

export const updateContact = (listUuid, contactUuid, params) => dispatch => {
  dispatch(updateContactRequest());

  return request
    .put(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/`, params)
    .then(({ data }) => {
      dispatch(updateContactSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(updateContactFailure(data));

      throw data;
    });
};

export const DELETE_LIST_REQUEST = `${PREFIX} DELETE_LIST_REQUEST`;
export const DELETE_LIST_SUCCESS = `${PREFIX} DELETE_LIST_SUCCESS`;
export const DELETE_LIST_FAILURE = `${PREFIX} DELETE_LIST_FAILURE`;

const deleteListRequest = () => ({
  type: DELETE_LIST_REQUEST,
});

const deleteListSuccess = payload => ({
  payload,
  type: DELETE_LIST_SUCCESS,
});

const deleteListFailure = payload => ({
  payload,
  type: DELETE_LIST_FAILURE,
});

export const deleteList = (listId, organizationId) => dispatch => {
  dispatch(deleteListRequest());

  return request
    .delete(`/v1/contacts/lists/${listId}/`)
    .then(({ data }) => {
      dispatch(deleteListSuccess({
        listId,
        organizationId,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteListFailure(data));

      throw data;
    });
};

export const DELETE_LIST_CONTACTS_REQUEST = `${PREFIX} DELETE_LIST_CONTACTS_REQUEST`;
export const DELETE_LIST_CONTACTS_SUCCESS = `${PREFIX} DELETE_LIST_CONTACTS_SUCCESS`;
export const DELETE_LIST_CONTACTS_FAILURE = `${PREFIX} DELETE_LIST_CONTACTS_FAILURE`;

const deleteListContactsRequest = () => ({
  type: DELETE_LIST_CONTACTS_REQUEST,
});

const deleteListContactsSuccess = payload => ({
  payload,
  type: DELETE_LIST_CONTACTS_SUCCESS,
});

const deleteListContactsFailure = payload => ({
  payload,
  type: DELETE_LIST_CONTACTS_FAILURE,
});

export const deleteListContacts = (listId, contactsList) => dispatch => {
  dispatch(deleteListContactsRequest());

  return request
    .post(`/v1/contacts/lists/${listId}/contacts/remove/`, contactsList)
    .then(({ data }) => {
      dispatch(fetchAllListContact(listId));

      return data;
    })
    .then(data => {
      dispatch(deleteListContactsSuccess());

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteListContactsFailure(data));

      throw data;
    });
};

export const ADD_CONTACTS_TO_LIST_REQUEST = `${PREFIX} ADD_CONTACTS_TO_LIST_REQUEST`;
export const ADD_CONTACTS_TO_LIST_SUCCESS = `${PREFIX} ADD_CONTACTS_TO_LIST_SUCCESS`;
export const ADD_CONTACTS_TO_LIST_FAILURE = `${PREFIX} ADD_CONTACTS_TO_LIST_FAILURE`;

const addContactsToListRequest = () => ({
  type: ADD_CONTACTS_TO_LIST_REQUEST,
});

const addContactsToListSuccess = payload => ({
  payload,
  type: ADD_CONTACTS_TO_LIST_SUCCESS,
});

const addContactsToListFailure = payload => ({
  payload,
  type: ADD_CONTACTS_TO_LIST_FAILURE,
});

export const addContactsToList = (listUuid, contactsList) => dispatch => {
  dispatch(addContactsToListRequest());

  return request
    .post(`/v1/contacts/lists/${listUuid}/contacts/bulk_add/`, contactsList)
    .then(({ data }) => {
      dispatch(addContactsToListSuccess(data));
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(addContactsToListFailure(data));

      throw data;
    });
};

export const DELETE_CONTACTS_REQUEST = `${PREFIX} DELETE_CONTACTS_REQUEST`;
export const DELETE_CONTACTS_SUCCESS = `${PREFIX} DELETE_CONTACTS_SUCCESS`;
export const DELETE_CONTACTS_FAILURE = `${PREFIX} DELETE_CONTACTS_FAILURE`;

const deleteContactsRequest = () => ({
  type: DELETE_CONTACTS_REQUEST,
});

const deleteContactsSuccess = payload => ({
  payload,
  type: DELETE_CONTACTS_SUCCESS,
});

const deleteContactsFailure = payload => ({
  payload,
  type: DELETE_CONTACTS_FAILURE,
});

export const deleteContacts = (listId, contactsList) => dispatch => {
  dispatch(deleteContactsRequest());

  return request
    .post(`/v1/contacts/lists/${listId}/contacts/bulk_delete/`, contactsList)
    .then(({ data }) => {
      dispatch(fetchAllListContact(listId));

      return data;
    })
    .then(data => {
      dispatch(deleteContactsSuccess());

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteContactsFailure(data));

      throw data;
    });
};

export const GET_CONTACTS_LIST_FILE_REQUEST = `${PREFIX} GET_CONTACTS_LIST_FILE_REQUEST`;
export const GET_CONTACTS_LIST_FILE_SUCCESS = `${PREFIX} GET_CONTACTS_LIST_FILE_SUCCESS`;
export const GET_CONTACTS_LIST_FILE_FAILURE = `${PREFIX} GET_CONTACTS_LIST_FILE_FAILURE`;

const getContactsListFileRequest = () => ({
  type: GET_CONTACTS_LIST_FILE_REQUEST,
});

const getContactsListFileSuccess = payload => ({
  payload,
  type: GET_CONTACTS_LIST_FILE_SUCCESS,
});

const getContactsListFileFailure = payload => ({
  payload,
  type: GET_CONTACTS_LIST_FILE_FAILURE,
});

export const getContactsListFile = listUuid => dispatch => {
  dispatch(getContactsListFileRequest());

  return request
    .get(`/v1/contacts/lists/${listUuid}/imports/`)
    .then(({ data }) => {
      dispatch(getContactsListFileSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(getContactsListFileFailure(data));

      throw data;
    });
};

export const GET_CONTACTS_LIST_FLAT_FILE_CONFIG_REQUEST = `${PREFIX} GET_CONTACTS_LIST_FLAT_FILE_CONFIG_REQUEST`;
export const GET_CONTACTS_LIST_FLAT_FILE_CONFIG_SUCCESS = `${PREFIX} GET_CONTACTS_LIST_FLAT_FILE_CONFIG_SUCCESS`;
export const GET_CONTACTS_LIST_FLAT_FILE_CONFIG_FAILURE = `${PREFIX} GET_CONTACTS_LIST_FLAT_FILE_CONFIG_FAILURE`;

const getContactsListFlatFileConfigRequest = () => ({
  type: GET_CONTACTS_LIST_FLAT_FILE_CONFIG_REQUEST,
});

const getContactsListFlatFileConfigSuccess = payload => ({
  payload,
  type: GET_CONTACTS_LIST_FLAT_FILE_CONFIG_SUCCESS,
});

const getContactsListFlatFileConfigFailure = payload => ({
  payload,
  type: GET_CONTACTS_LIST_FLAT_FILE_CONFIG_FAILURE,
});

export const getContactsListFlatFileConfig = listUuid => dispatch => {
  dispatch(getContactsListFlatFileConfigRequest());

  return request
    .get(`/v1/contacts/lists/${listUuid}/flatfile/`)
    .then(({ data }) => {
      dispatch(getContactsListFlatFileConfigSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(getContactsListFlatFileConfigFailure(data));

      throw data;
    });
};

export const POST_FLATFILE_BATCH_REQUEST = `${PREFIX} POST_FLATFILE_BATCH_REQUEST`;
export const POST_FLATFILE_BATCH_SUCCESS = `${PREFIX} POST_FLATFILE_BATCH_SUCCESS`;
export const POST_FLATFILE_BATCH_FAILURE = `${PREFIX} POST_FLATFILE_BATCH_FAILURE`;

const postFlatfileBatchRequest = () => ({
  type: POST_FLATFILE_BATCH_REQUEST,
});

const postFlatfileBatchSuccess = payload => ({
  payload,
  type: POST_FLATFILE_BATCH_SUCCESS,
});

const postFlatfileBatchFailure = payload => ({
  payload,
  type: POST_FLATFILE_BATCH_FAILURE,
});

export const postFlatfileBatch = (listUuid, params) => dispatch => {
  dispatch(postFlatfileBatchRequest());

  return request
    .post(`/v1/contacts/lists/${listUuid}/flatfile-batches/`, params)
    .then(({ data }) => {
      dispatch(postFlatfileBatchSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postFlatfileBatchFailure(data));

      throw data;
    });
};

export const FETCH_LIST_GROUPS_REQUEST = `${PREFIX} FETCH_LIST_GROUPS_REQUEST`;
export const FETCH_LIST_GROUPS_SUCCESS = `${PREFIX} FETCH_LIST_GROUPS_SUCCESS`;
export const FETCH_LIST_GROUPS_FAILURE = `${PREFIX} FETCH_LIST_GROUPS_FAILURE`;

const fetchListGroupsRequest = () => ({
  type: FETCH_LIST_GROUPS_REQUEST,
});

const fetchListGroupsSuccess = payload => ({
  payload,
  type: FETCH_LIST_GROUPS_SUCCESS,
});

const fetchListGroupsFailure = payload => ({
  payload,
  type: FETCH_LIST_GROUPS_FAILURE,
});

export const fetchListGroups = (rootlistUuid, organizationUuid) => dispatch => {
  dispatch(fetchListGroupsRequest());

  return request
    .get(`/v1/contacts/lists/${rootlistUuid}/list_groups/`)
    .then(({ data }) => {
      dispatch(fetchListGroupsSuccess({
        data,
        organizationUuid,
        rootListId: rootlistUuid,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchListGroupsFailure(data));

      throw data;
    });
};

export const POST_LIST_GROUP_REQUEST = `${PREFIX} POST_LIST_GROUP_REQUEST`;
export const POST_LIST_GROUP_SUCCESS = `${PREFIX} POST_LIST_GROUP_SUCCESS`;
export const POST_LIST_GROUP_FAILURE = `${PREFIX} POST_LIST_GROUP_FAILURE`;

const postListGroupRequest = () => ({
  type: POST_LIST_GROUP_REQUEST,
});

const postListGroupSuccess = payload => ({
  payload,
  type: POST_LIST_GROUP_SUCCESS,
});

const postListGroupFailure = payload => ({
  payload,
  type: POST_LIST_GROUP_FAILURE,
});

export const postListGroup = (rootlistUuid, params) => dispatch => {
  dispatch(postListGroupRequest());

  return request
    .post(`/v1/contacts/lists/${rootlistUuid}/list_groups/`, params)
    .then(({ data }) => {
      dispatch(postListGroupSuccess(data));
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postListGroupFailure(data));

      throw data;
    });
};

export const DELETE_LIST_GROUP_REQUEST = `${PREFIX} DELETE_LIST_GROUP_REQUEST`;
export const DELETE_LIST_GROUP_SUCCESS = `${PREFIX} DELETE_LIST_GROUP_SUCCESS`;
export const DELETE_LIST_GROUP_FAILURE = `${PREFIX} DELETE_LIST_GROUP_FAILURE`;

const deleteListGroupRequest = () => ({
  type: DELETE_LIST_GROUP_REQUEST,
});

const deleteListGroupSuccess = payload => ({
  payload,
  type: DELETE_LIST_GROUP_SUCCESS,
});

const deleteListGroupFailure = payload => ({
  payload,
  type: DELETE_LIST_GROUP_FAILURE,
});

export const deleteListGroup = (rootlistUuid, listGroupUuid, organizationUuid) => dispatch => {
  dispatch(deleteListGroupRequest());

  return request
    .delete(`/v1/contacts/lists/${rootlistUuid}/list_groups/${listGroupUuid}/`)
    .then(({ data }) => {
      dispatch(deleteListGroupSuccess({
        data,
        listGroupUuid,
        organizationUuid,
        rootListId: rootlistUuid,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteListGroupFailure(data));

      throw data;
    });
};

export const FETCH_CONTACTS_REQUEST = `${PREFIX} FETCH_CONTACTS_REQUEST`;
export const FETCH_CONTACTS_SUCCESS = `${PREFIX} FETCH_CONTACTS_SUCCESS`;
export const FETCH_CONTACTS_FAILURE = `${PREFIX} FETCH_CONTACTS_FAILURE`;

const fetchContactsRequest = () => ({
  type: FETCH_CONTACTS_REQUEST,
});

const fetchContactsSuccess = payload => ({
  payload,
  type: FETCH_CONTACTS_SUCCESS,
});

const fetchContactsFailure = payload => ({
  payload,
  type: FETCH_CONTACTS_FAILURE,
});

export const fetchContacts = (organizationUuid, actionCenterUuid, isMapContacts) => dispatch => {
  dispatch(fetchContactsRequest());

  return request
    .get(`/v1/crm/${organizationUuid}/contacts/?action_center=${actionCenterUuid}&map=${isMapContacts}`)
    .then(({ data }) => {
      dispatch(fetchContactsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchContactsFailure(data));

      throw data;
    });
};

export const POST_MERGE_CONTACTS_REQUEST = `${PREFIX} POST_MERGE_CONTACTS_REQUEST`;
export const POST_MERGE_CONTACTS_SUCCESS = `${PREFIX} POST_MERGE_CONTACTS_SUCCESS`;
export const POST_MERGE_CONTACTS_FAILURE = `${PREFIX} POST_MERGE_CONTACTS_FAILURE`;

const postMergeContactsRequest = () => ({
  type: POST_MERGE_CONTACTS_REQUEST,
});

const postMergeContactsSuccess = payload => ({
  payload,
  type: POST_MERGE_CONTACTS_SUCCESS,
});

const postMergeContactsFailure = payload => ({
  payload,
  type: POST_MERGE_CONTACTS_FAILURE,
});

export const postMergeContacts = (organizationUuid, listUuid, params) => dispatch => {
  dispatch(postMergeContactsRequest());

  return request
    .post(`/v1/crm/${organizationUuid}/contacts/merge/`, params)
    .then(({ data }) => {
      dispatch(postMergeContactsSuccess({
        duplicates: params.duplicates,
        listId: listUuid,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postMergeContactsFailure(data));

      throw data;
    });
};
