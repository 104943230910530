import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@mui/material';

const StyledGrid = styled(Grid)`
  flex-direction: column;
  width: 100%;
  margin-top: ${props => props.margintop}px;
  margin-bottom: ${props => props.marginbottom}px;
`;

const Container = ({
  children,
  className,
  marginBottom,
  marginTop,
}) => (
  <StyledGrid
    className={className}
    container
    item
    lg={10}
    xs={11}
    marginbottom={marginBottom}
    margintop={marginTop}
  >
    {children}
  </StyledGrid>
);

Container.defaultProps = {
  className: '',
  marginBottom: '0',
  marginTop: '0',
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
};

export default Container;
