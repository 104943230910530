import { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Field,
  Form,
} from 'react-final-form';
import {
  Link as RouterLink,
  useHistory,
} from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import { register } from 'store/actions/auth';
import { setSnackbarSuccess } from 'store/actions/global';
import { authSelectors } from 'store/selectors/auth';

import { TermsField } from 'common/components/Form';
import { colors } from 'utils/constants';
import { required } from 'utils/validators';

const LoginTypography = styled(Typography)`
  margin-top: 10px;
  color: ${colors.osloGray};
`;

const LogoMark = styled.img`
  max-height: 60px;
  margin-bottom: 15px;
`;

const MarginGrid = styled(Grid)`
  margin-top: 5px;
`;

const StyledLink = styled(Link)`
  color: ${colors.primary};
  font-weight: bold;
`;

const StyledPaper = styled(Paper)`
  padding: 50px 30px;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  width: 280px;
  height: 50px;
  margin: 30px 0 25px;
  font-weight: bold;
  text-transform: uppercase;
`;

const TitleWrapper = styled(Grid)`
  margin-bottom: 20px;
`;

const Wrapper = styled(Grid)`
  max-width: 500px;
  height: 100%;
`;

const EMAIL_LABEL = 'Email';
const HAVE_ACCOUNT_TEXT = 'Already have an account?';
const PASSWORD_LABEL = 'Password';
const REDIRECT_TIME = 5000;
const REGISTER_SUBTITLE = 'Enter your email and choose a password to get started';
const REGISTER_SUCCESS_MESSAGE = 'Successfully registered! You will be redirected to the login page';
const REGISTER_TITLE = 'Start Your Muster Account Now!';
const SIGN_IN_TEXT = 'Sign in';
const SIGN_UP_TEXT = 'Sign up';

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    isAuthenticated,
  } = useSelector(state => ({
    isAuthenticated: authSelectors.getIsAuthenticated(state),
  }));

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/organizations');
    }
  }, [isAuthenticated]);

  const onSubmit = async values => {
    try {
      await dispatch(register(values));
      dispatch(setSnackbarSuccess({ message: REGISTER_SUCCESS_MESSAGE }));

      return setTimeout(() => history.push('/login'), REDIRECT_TIME);
    } catch (error) {
      return error;
    }
  };

  return (
    <Wrapper
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
    >
      <StyledPaper variant="outlined">
        <LogoMark
          alt="Muster logomark"
          src="/muster_logomark_blue.png"
        />
        <TitleWrapper
          container
          direction="column"
          alignItems="center"
        >
          <Typography variant="h5">{REGISTER_TITLE}</Typography>
          <Typography variant="subtitle2">{REGISTER_SUBTITLE}</Typography>
        </TitleWrapper>
        <Form
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            submitting,
            pristine,
          }) => (
            <form onSubmit={handleSubmit}>
              <MarginGrid
                container
                item
                xs={12}
              >
                <Field
                  name="email"
                  validate={required}
                >
                  {({
                    input,
                    meta,
                  }) => (
                    <TextField
                      {...input}
                      error={Boolean(meta.touched && (meta.error || meta.submitError))}
                      fullWidth
                      helperText={meta.touched && (meta.error || meta.submitError)}
                      label={EMAIL_LABEL}
                      type="text"
                    />
                  )}
                </Field>
              </MarginGrid>
              <MarginGrid
                container
                item
                xs={12}
              >
                <Field
                  name="password"
                  validate={required}
                >
                  {({
                    input,
                    meta,
                  }) => (
                    <TextField
                      {...input}
                      error={Boolean(meta.touched && (meta.error || meta.submitError))}
                      fullWidth
                      helperText={meta.touched && (meta.error || meta.submitError)}
                      label={PASSWORD_LABEL}
                      type="password"
                    />
                  )}
                </Field>
              </MarginGrid>
              <TermsField />
              <Grid
                container
                item
                justifyContent="center"
                xs={12}
              >
                <SubmitButton
                  color="secondary"
                  disableElevation
                  disabled={submitting || pristine}
                  type="submit"
                  variant="contained"
                >
                  {SIGN_UP_TEXT}
                </SubmitButton>
                <LoginTypography variant="subtitle2">
                  {HAVE_ACCOUNT_TEXT}
                  {' '}
                  <StyledLink
                    component={RouterLink}
                    to="/login"
                  >
                    {SIGN_IN_TEXT}
                  </StyledLink>
                </LoginTypography>
              </Grid>
            </form>
          )}
        />
      </StyledPaper>
    </Wrapper>
  );
};

export default Register;
