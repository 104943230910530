import {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  useDispatch,
} from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Autocomplete,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import {
  addCustomField,
  updateCustomField,
} from 'store/actions/contactDetails';

import { Dialog } from 'common/components';
import { fieldTypes } from 'utils/constants';

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 15px;
`;

const ADD_CUSTOM_FIELD_DIALOG_TITLE = 'Add custom field';
const ADD_CUSTOM_FIELD_DIALOG_SUBTITLE = 'Select the appropriate custom field and adjust the value to it';
const EDIT_CUSTOM_FIELD_DIALOG_TITLE = 'Edit custom field';
const EDIT_CUSTOM_FIELD_DIALOG_SUBTITLE = 'Change value of custom field';

const CustomFieldDialog = ({
  allCustomFields,
  customFields,
  editedCustomField,
  isEditCustomField,
  isDialogOpen,
  isPending,
  setIsDialogOpen,
}) => {
  const dispatch = useDispatch();
  const filteredCustomFieldsList = allCustomFields?.filter(
    ({ uuid }) => !customFields.some(({ customField }) => uuid === customField)
  ) || [];

  const {
    contactUuid,
    listUuid,
  } = useParams();

  const [
    customField,
    setCustomField,
  ] = useState('');
  const [
    customFieldValue,
    setCustomFieldValue,
  ] = useState('');
  const [
    multipleCustomFieldValue,
    setMultipleCustomFieldValue,
  ] = useState([]);

  useEffect(() => {
    if (isEditCustomField && isDialogOpen) {
      const editedCustomFieldAllData =
        allCustomFields.find(field => field.uuid === editedCustomField.customField);

      setCustomField({
        ...editedCustomFieldAllData,
        ...editedCustomField,
      });
      setCustomFieldValue(editedCustomField.value);
      setMultipleCustomFieldValue(editedCustomField.values || []);
    }
  }, [
    isEditCustomField,
    isDialogOpen,
  ]);

  const handleDialogClose = () => {
    setCustomField('');
    setCustomFieldValue('');
    setMultipleCustomFieldValue([]);
    setIsDialogOpen(false);
  };

  const handleConfirm = async () => {
    const {
      uuid,
      label,
      fieldType,
    } = customField;
    const isMultipleCustomField = fieldType === fieldTypes.multipleChoiceField;

    try {
      if (isEditCustomField) {
        await dispatch(
          updateCustomField(listUuid, contactUuid, uuid, {
            ...(isMultipleCustomField ?
              { values: multipleCustomFieldValue } :
              { value: customFieldValue }
            ),
          })
        );
      } else {
        await dispatch(
          addCustomField(listUuid, contactUuid, label, {
            customField: uuid,
            ...(isMultipleCustomField ?
              { values: multipleCustomFieldValue } :
              { value: customFieldValue }
            ),
          })
        );
      }
      handleDialogClose();

      return null;
    } catch (error) {
      return error;
    }
  };

  return (
    <Dialog
      handleConfirm={handleConfirm}
      handleDialogClose={handleDialogClose}
      isDialogOpen={isDialogOpen}
      subtitle={!isEditCustomField ?
        ADD_CUSTOM_FIELD_DIALOG_SUBTITLE :
        EDIT_CUSTOM_FIELD_DIALOG_SUBTITLE}
      title={!isEditCustomField ?
        ADD_CUSTOM_FIELD_DIALOG_TITLE :
        EDIT_CUSTOM_FIELD_DIALOG_TITLE}
      disabledButton={isPending && !!customField && !!customFieldValue}
    >
      <>
        {isEditCustomField ? (
          <StyledSelect
            value={customField}
            disabled
          >
            <MenuItem value={customField}>{customField.label}</MenuItem>
          </StyledSelect>
        ) : (
          <StyledSelect
            onChange={event => {
              setCustomField(event.target.value);
              setCustomFieldValue('');
            }}
            value={customField}
          >
            <MenuItem value="" />
            {filteredCustomFieldsList
              .map(filteredCustomField => (
                <MenuItem
                  key={filteredCustomField.uuid}
                  value={filteredCustomField}
                >
                  {filteredCustomField.label}
                </MenuItem>
              ))}
          </StyledSelect>
        )}
        {customField?.fieldType === fieldTypes.textField && (
          <TextField
            fullWidth
            placeholder={customField.placeholder}
            onChange={event => setCustomFieldValue(event.target.value)}
            type="text"
            value={customFieldValue}
          />
        )}
        {customField?.fieldType === fieldTypes.singleChoiceField && (
          <StyledSelect
            onChange={event => setCustomFieldValue(event.target.value)}
            value={customFieldValue}
          >
            {customField?.options.map(option => (
              <MenuItem
                key={option}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </StyledSelect>
        )}
        {customField?.fieldType === fieldTypes.multipleChoiceField && (
          <Autocomplete
            label="Choice"
            getOptionLabel={option => option}
            fullWidth
            multiple
            onChange={(_, pickedOption) => setMultipleCustomFieldValue(pickedOption)}
            options={customField.options}
            renderInput={params => (
              <TextField
                {...params}
                label="Choice"
                variant="standard"
              />
            )}
            value={multipleCustomFieldValue}
          />
          )}
      </>
    </Dialog>
  );
};

CustomFieldDialog.defaultProps = {
  allCustomFields: [],
  customFields: [],
};

CustomFieldDialog.propTypes = {
  allCustomFields: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
    })
  ),
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
    })
  ),
  editedCustomField: PropTypes.shape({
    customField: PropTypes.string,
    value: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isEditCustomField: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
};

export default CustomFieldDialog;
