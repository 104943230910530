import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Field,
  Form,
} from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import styled from 'styled-components';

import {
  Button,
  Grid,
  Paper,
  TextField,
} from '@mui/material';

import { confirmPasswordReset } from 'store/actions/auth';
import { authSelectors } from 'store/selectors/auth';

import { colors } from 'utils/constants';
import { required } from 'utils/validators';
import { useQuery } from 'utils/helpers';

import SuccessDialog from 'routes/ConfirmPasswordReset/components/SuccessDialog';

const ErrorMessage = styled.p`
  color: ${colors.error};
  text-align: center;
`;

const LogoMark = styled.img`
  max-height: 60px;
  margin-bottom: 15px;
`;

const MarginGrid = styled(Grid)`
  margin-top: 5px;
`;

const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 50px 30px;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  width: 280px;
  height: 50px;
  margin: 30px 0 25px;
  font-weight: bold;
  text-transform: uppercase;
`;

const Wrapper = styled(Grid)`
  max-width: 500px;
  height: 100%;
`;

const ConfirmResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const uid = query.get('uid');
  const token = query.get('token');

  const {
    isAuthenticated,
  } = useSelector(state => ({
    isAuthenticated: authSelectors.getIsAuthenticated(state),
  }));

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/organizations');
    }
  }, [isAuthenticated]);

  const [
    isSuccessDialogOpen,
    setIsSuccessDialogOpen,
  ] = useState(false);

  const onSubmit = async values => {
    const params = {
      ...values,
      token,
      uid,
    };

    try {
      await dispatch(confirmPasswordReset(params));

      return setIsSuccessDialogOpen(true);
    } catch (error) {
      if (error?.nonFieldErrors?.length) {
        return { [FORM_ERROR]: error?.nonFieldErrors[0] };
      }

      return error;
    }
  };

  return (
    <Wrapper
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
    >
      <StyledPaper variant="outlined">
        <LogoMark
          alt="Muster logomark"
          src="/muster_logomark_blue.png"
        />
        <Form
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            submitting,
            submitError,
            pristine,
          }) => (
            <form onSubmit={handleSubmit}>
              {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
              <MarginGrid
                container
                item
                xs={12}
              >
                <Field
                  name="newPassword1"
                  validate={required}
                >
                  {({
                    input,
                    meta,
                  }) => (
                    <TextField
                      {...input}
                      error={Boolean(meta.touched && (meta.error || meta.submitError))}
                      fullWidth
                      helperText={meta.touched && (meta.error || meta.submitError)}
                      label="New password"
                      type="password"
                    />
                  )}
                </Field>
              </MarginGrid>
              <MarginGrid
                container
                item
                xs={12}
              >
                <Field
                  name="newPassword2"
                  validate={required}
                >
                  {({
                    input,
                    meta,
                  }) => (
                    <TextField
                      {...input}
                      error={Boolean(meta.touched && (meta.error || meta.submitError))}
                      fullWidth
                      helperText={meta.touched && (meta.error || meta.submitError)}
                      label="Confirm password"
                      type="password"
                    />
                  )}
                </Field>
              </MarginGrid>
              <Grid
                container
                item
                justifyContent="center"
                xs={12}
              >
                <SubmitButton
                  color="secondary"
                  disableElevation
                  disabled={submitting || pristine}
                  type="submit"
                  variant="contained"
                >
                  Change Password
                </SubmitButton>
              </Grid>
            </form>
          )}
        />
      </StyledPaper>
      <SuccessDialog isDialogOpen={isSuccessDialogOpen} />
    </Wrapper>
  );
};

export default ConfirmResetPassword;
