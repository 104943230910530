import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

import {
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import { FilterList as FilterListIcon } from '@mui/icons-material';

import {
  clearBroadcast,
  fetchTextBroadcast,
  patchTextBroadcast,
  postSendPreviewTextBroadcast,
  postSendTextBroadcast,
  postTextBroadcast,
} from 'store/actions/broadcasts';
import {
  fetchChildrenLists,
  fetchRootList,
} from 'store/actions/contacts';
import { setSnackbarSuccess } from 'store/actions/global';
import { broadcastsSelectors } from 'store/selectors/broadcasts';
import { contactSelectors } from 'store/selectors/contacts';
import { organizationSelectors } from 'store/selectors/organizations';

import {
  CancelCreatorButton,
  CardField,
  Container,
  DescriptionBar,
  Stepper,
  SuccessDialog,
} from 'common/components';
import {
  colors,
} from 'utils/constants';
import {
  formatNumber,
  handleMergeCorrectTime,
  renderErrorMessage,
  scrollToTop,
} from 'utils/helpers';
import {
  CircularProgressWrapper,
  ErrorTypography,
  StepOneContainer,
  StepTwoContainer,
} from 'globalStyles';

import StepOne from 'routes/CreateTextBroadcast/components/StepOne';
import StepTwo from 'routes/CreateTextBroadcast/components/StepTwo';
import {
  initialTimezone,
  timezones,
} from 'utils/timezones';

const ButtonCreateWrapper = styled.div`
  position: relative;
`;

const ButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const NextButtonWrapper = styled.div`
  padding-left: 10px;
`;

const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90px;
  margin-top: 50px;
  background-color: ${colors.white};
`;

const CreateTextBroadcast = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    duplicatedUuid,
    editedBroadcastUuid,
    organizationUuid,
  } = useParams();
  const {
    broadcastData,
    childrenLists,
    isBroadcastFetching,
    isPending,
    organization,
    rootList,
  } = useSelector(state => ({
    broadcastData: broadcastsSelectors.getEditingBroadcastDetails(state),
    childrenLists: contactSelectors.getChildrenLists(state)[organizationUuid] || [],
    isBroadcastFetching: broadcastsSelectors.isBroadcastFetching(state),
    isPending: broadcastsSelectors.isPending(state),
    organization: organizationSelectors.getOrganization(state),
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  const listsOptions = useMemo(() => [
    rootList,
    ...childrenLists,
  ], [
    rootList,
    childrenLists,
  ]);

  useEffect(() => {
    if (Object.keys(rootList).length === 0 || childrenLists.length === 0) {
      dispatch(fetchRootList(organizationUuid))
        .then(data => dispatch(fetchChildrenLists(data.uuid, organizationUuid)));
    }
  }, [
    dispatch,
    organizationUuid,
  ]);

  const [
    selectedDate,
    setSelectedDate,
  ] = useState(dayjs());
  const [
    selectedTime,
    setSelectedTime,
  ] = useState(dayjs());
  const [
    previewPhone,
    setPreviewPhone,
  ] = useState('');
  const [
    countRecipients,
    setCountRecipients,
  ] = useState(0);
  const [
    step,
    setStep,
  ] = useState(0);
  const [
    errorMessage,
    setErrorMessage,
  ] = useState(null);
  const [
    isScheduled,
    setIsScheduled,
  ] = useState(false);
  const [
    successDialogMessage,
    setSuccessDialogMessage,
  ] = useState('');
  const [
    selectedTimeZone,
    setSelectedTimeZone,
  ] = useState(initialTimezone(organization.timezone));
  const [
    formValues,
    setFormValues,
  ] = useState({
    listsValue: [],
    messageValue: '',
    nameValue: '',
  });

  useEffect(() => {
    dispatch(clearBroadcast());

    if (duplicatedUuid || editedBroadcastUuid) {
      dispatch(fetchTextBroadcast(duplicatedUuid || editedBroadcastUuid))
        .then(data => {
          setFormValues(prevValues => ({
            ...prevValues,
            listsValue: data.contactLists,
            messageValue: data.message,
            nameValue: data.name,
          }));
        });
    }
  }, [
    dispatch,
    duplicatedUuid,
    editedBroadcastUuid,
  ]);

  const postNewBroadcast = async () => {
    const params = {
      contactLists: formValues.listsValue?.map(list => list.uuid),
      message: formValues.messageValue,
      name: formValues.nameValue,
      organization: organizationUuid,
    };

    try {
      if (broadcastData?.uuid || editedBroadcastUuid) {
        const response = await dispatch(
          patchTextBroadcast(broadcastData?.uuid || editedBroadcastUuid, params)
        );

        setCountRecipients(response?.countRecipients);

        setErrorMessage(null);
        setStep(prevStep => prevStep + 1);
      } else {
        const response = await dispatch(postTextBroadcast(params));

        setCountRecipients(response?.countRecipients);

        setErrorMessage(null);
        setStep(prevStep => prevStep + 1);
      }
    } catch (error) {
      setErrorMessage(error);
    } finally {
      scrollToTop();
    }
  };

  const sendTextBroadacst = async () => {
    const params = {};

    if (isScheduled) {
      params.sendTime = handleMergeCorrectTime(selectedDate, selectedTime);
      params.sendAtTimezone = selectedTimeZone;
    }

    try {
      const message = await dispatch(postSendTextBroadcast(broadcastData.uuid, params));

      setSuccessDialogMessage(message);
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const handleSendPreview = async () => {
    try {
      await dispatch(postSendPreviewTextBroadcast(
        broadcastData.uuid,
        { phoneNumbers: [previewPhone] }
      ));

      dispatch(setSnackbarSuccess({ message: 'A text message will be sent to the provided number.' }));
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const steps = [
    'Setup & Write Text',
    'Send',
  ];

  const disableNextButton = () => {
    if (step === 0) {
      return !(formValues.nameValue &&
          formValues.messageValue && formValues.listsValue);
    }

    if (step === 1 && isScheduled) {
      return !(selectedTime && selectedDate);
    }

    if (isPending) {
      return true;
    }

    return false;
  };

  const handleSave = () => {
    if (step === 0) {
      postNewBroadcast();
    } else if (step === 1) {
      sendTextBroadacst();
    }
  };

  const handleCreateNew = () => {
    setSuccessDialogMessage('');
    dispatch(clearBroadcast());
    scrollToTop();
    setStep(0);
    setFormValues({
      listsValue: [],
      messageValue: '',
      nameValue: '',
    });
    history.push({
      pathname: `/${organizationUuid}/create-text-broadcast`,
    });
  };

  const handleGoBack = () => {
    setStep(prevStep => prevStep - 1);
    scrollToTop();
  };

  if (isBroadcastFetching) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  const pageName = () => {
    if (duplicatedUuid) {
      return 'Duplicate';
    }

    if (editedBroadcastUuid) {
      return 'Edit';
    }

    return 'Create New';
  };

  return (
    <>
      <Container>
        <Grid container>
          <DescriptionBar
            icon={FilterListIcon}
            name={`${pageName()} Text Broadcast`}
          >
            <CancelCreatorButton redirectUrl={
              (duplicatedUuid || editedBroadcastUuid) ?
                `/${organizationUuid}/text-broadcasts/${duplicatedUuid || editedBroadcastUuid}` :
                `/${organizationUuid}/broadcasts/`
              }
            />
          </DescriptionBar>
        </Grid>
        <Stepper
          step={step}
          steps={steps}
          neutralcolors
        />
        {errorMessage && (
          <CardField name="Error">
            <ErrorTypography>
              {renderErrorMessage(errorMessage)}
            </ErrorTypography>
          </CardField>
        )}
        <StepOneContainer step={step}>
          <StepOne
            lists={listsOptions}
            listsValue={formValues.listsValue}
            messageValue={formValues.messageValue}
            nameValue={formValues.nameValue}
            setFormValues={setFormValues}
          />
        </StepOneContainer>
        <StepTwoContainer step={step}>
          <StepTwo
            isScheduled={isScheduled}
            onSendPreview={handleSendPreview}
            previewPhone={previewPhone}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            selectedTimeZone={selectedTimeZone}
            setPreviewPhone={setPreviewPhone}
            setSelectedDate={setSelectedDate}
            setSelectedTime={setSelectedTime}
            setSelectedTimeZone={setSelectedTimeZone}
            setIsScheduled={setIsScheduled}
            timezones={timezones}
          />
        </StepTwoContainer>
      </Container>
      <StepperWrapper>
        <Grid
          container
          direction="row"
          item
          justifyContent="space-between"
          lg={10}
          xs={11}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-start"
            xs={2}
          >
            <Button
              disabled={step === 0}
              onClick={handleGoBack}
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-end"
            xs={4}
          >
            <NextButtonWrapper>
              <ButtonCreateWrapper>
                <Button
                  color="secondary"
                  disabled={disableNextButton() || isPending}
                  onClick={handleSave}
                  variant="contained"
                  type="button"
                >
                  {step !== 1 ? 'Save and Continue' : `Send to ${formatNumber(countRecipients)} recipients`}
                </Button>
                {isPending && <ButtonCircularProgress size={24} />}
              </ButtonCreateWrapper>
            </NextButtonWrapper>
          </Grid>
        </Grid>
      </StepperWrapper>
      <SuccessDialog
        isDialogOpen={!!successDialogMessage}
        name="Text Brodcast"
        onCreateNew={handleCreateNew}
        redirectTo={`/${organizationUuid}/broadcasts`}
        thankYouMessage={successDialogMessage || 'Thank you for sending this Broadcast'}
      />
    </>
  );
};

export default CreateTextBroadcast;
