import request from '../../utils/request';

const PREFIX = '[TEMPLATES]';

export const POST_TEMPLATE_REQUEST = `${PREFIX} POST_TEMPLATE_REQUEST`;
export const POST_TEMPLATE_SUCCESS = `${PREFIX} POST_TEMPLATE_SUCCESS`;
export const POST_TEMPLATE_FAILURE = `${PREFIX} POST_TEMPLATE_FAILURE`;

const postTemplateRequest = () => ({
  type: POST_TEMPLATE_REQUEST,
});

const postTemplateSuccess = payload => ({
  payload,
  type: POST_TEMPLATE_SUCCESS,
});

const postTemplateFailure = payload => ({
  payload,
  type: POST_TEMPLATE_FAILURE,
});

export const postTemplate = params => dispatch => {
  dispatch(postTemplateRequest());

  return request
    .post('/v1/broadcasts/templates/', params)
    .then(({ data }) => {
      dispatch(postTemplateSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postTemplateFailure(data));

      throw data;
    });
};

export const FETCH_ALL_TEMPLATES_REQUEST = `${PREFIX} FETCH_ALL_TEMPLATES_REQUEST`;
export const FETCH_ALL_TEMPLATES_SUCCESS = `${PREFIX} FETCH_ALL_TEMPLATES_SUCCESS`;
export const FETCH_ALL_TEMPLATES_FAILURE = `${PREFIX} FETCH_ALL_TEMPLATES_FAILURE`;

const fetchAllTemplatesRequest = () => ({
  type: FETCH_ALL_TEMPLATES_REQUEST,
});

const fetchAllTemplatesSuccess = payload => ({
  payload,
  type: FETCH_ALL_TEMPLATES_SUCCESS,
});

const fetchAllTemplatesFailure = payload => ({
  payload,
  type: FETCH_ALL_TEMPLATES_FAILURE,
});

export const fetchAllTemplates = params => dispatch => {
  dispatch(fetchAllTemplatesRequest());
  const templates = [];

  const fetchAllTemplatesWithPagination = next => request
    .get(next || '/v1/broadcasts/templates/', { params })
    .then(({ data }) => {
      templates.push(...data.results);

      if (!data.next) {
        return templates;
      }

      return fetchAllTemplatesWithPagination(data.next);
    });

  return fetchAllTemplatesWithPagination()
    .then(data => {
      dispatch(fetchAllTemplatesSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchAllTemplatesFailure(data));

      throw data;
    });
};

export const FETCH_TEMPLATES_REQUEST = `${PREFIX} FETCH_TEMPLATES_REQUEST`;
export const FETCH_TEMPLATES_SUCCESS = `${PREFIX} FETCH_TEMPLATES_SUCCESS`;
export const FETCH_TEMPLATES_FAILURE = `${PREFIX} FETCH_TEMPLATES_FAILURE`;

const fetchTemplatesRequest = () => ({
  type: FETCH_TEMPLATES_REQUEST,
});

const fetchTemplatesSuccess = payload => ({
  payload,
  type: FETCH_TEMPLATES_SUCCESS,
});

const fetchTemplatesFailure = payload => ({
  payload,
  type: FETCH_TEMPLATES_FAILURE,
});

export const fetchTemplates = params => dispatch => {
  dispatch(fetchTemplatesRequest());

  return request
    .get('/v1/broadcasts/templates/', { params })
    .then(({ data }) => {
      dispatch(fetchTemplatesSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchTemplatesFailure(data));

      throw data;
    });
};

export const DELETE_TEMPLATE_REQUEST = `${PREFIX} DELETE_TEMPLATE_REQUEST`;
export const DELETE_TEMPLATE_SUCCESS = `${PREFIX} DELETE_TEMPLATE_SUCCESS`;
export const DELETE_TEMPLATE_FAILURE = `${PREFIX} DELETE_TEMPLATE_FAILURE`;

const deleteTemplateRequest = () => ({
  type: DELETE_TEMPLATE_REQUEST,
});

const deleteTemplateSuccess = payload => ({
  payload,
  type: DELETE_TEMPLATE_SUCCESS,
});

const deleteTemplateFailure = payload => ({
  payload,
  type: DELETE_TEMPLATE_FAILURE,
});

export const deleteTemplate = templateUuid => dispatch => {
  dispatch(deleteTemplateRequest());

  return request
    .delete(`/v1/broadcasts/templates/${templateUuid}/`)
    .then(({ data }) => {
      dispatch(deleteTemplateSuccess(data));
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteTemplateFailure(data));

      throw data;
    });
};

export const FETCH_TEMPLATE_REQUEST = `${PREFIX} FETCH_TEMPLATE_REQUEST`;
export const FETCH_TEMPLATE_SUCCESS = `${PREFIX} FETCH_TEMPLATE_SUCCESS`;
export const FETCH_TEMPLATE_FAILURE = `${PREFIX} FETCH_TEMPLATE_FAILURE`;

const fetchTemplateRequest = () => ({
  type: FETCH_TEMPLATE_REQUEST,
});

const fetchTemplateSuccess = payload => ({
  payload,
  type: FETCH_TEMPLATE_SUCCESS,
});

const fetchTemplateFailure = payload => ({
  payload,
  type: FETCH_TEMPLATE_FAILURE,
});

export const fetchTemplate = templateUuid => dispatch => {
  dispatch(fetchTemplateRequest());

  return request
    .get(`/v1/broadcasts/templates/${templateUuid}/`)
    .then(({ data }) => {
      dispatch(fetchTemplateSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchTemplateFailure(data));

      throw data;
    });
};

export const PATCH_TEMPLATE_REQUEST = `${PREFIX} PATCH_TEMPLATE_REQUEST`;
export const PATCH_TEMPLATE_SUCCESS = `${PREFIX} PATCH_TEMPLATE_SUCCESS`;
export const PATCH_TEMPLATE_FAILURE = `${PREFIX} PATCH_TEMPLATE_FAILURE`;

const patchTemplateRequest = () => ({
  type: PATCH_TEMPLATE_REQUEST,
});

const patchTemplateSuccess = payload => ({
  payload,
  type: PATCH_TEMPLATE_SUCCESS,
});

const patchTemplateFailure = payload => ({
  payload,
  type: PATCH_TEMPLATE_FAILURE,
});

export const patchTemplate = (templateId, params) => dispatch => {
  dispatch(patchTemplateRequest());

  return request
    .patch(`/v1/broadcasts/templates/${templateId}/`, params)
    .then(({ data }) => {
      dispatch(patchTemplateSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchTemplateFailure(data));

      throw data;
    });
};
