import PropTypes from 'prop-types';

import MaterialTable from '@material-table/core';
import { Paper } from '@mui/material';

import {
  advocatesColumns,
  colors,
} from 'utils/constants';

const AdvocatesTable = ({
  isDataFetching,
  advocatesList,
}) => (
  <MaterialTable
    columns={advocatesColumns}
    components={{
      Container: props => (
        <Paper
          {...props}
          elevation={0}
          square
          variant="outlined"
        />
      ),
    }}
    data={advocatesList}
    isLoading={isDataFetching}
    options={{
      actionsColumnIndex: -1,
      headerStyle: {
        backgroundColor: colors.tableHeaderGray,
        color: colors.white,
      },
      selection: false,
      showTitle: false,
    }}
  />
);

AdvocatesTable.propTypes = {
  advocatesList: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  })).isRequired,
  isDataFetching: PropTypes.bool.isRequired,
};

export default AdvocatesTable;
