import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';

import {
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import { CardField } from 'common/components';
import { handleNumberFormat } from 'utils/helpers';

const CardFieldWrapper = styled.div`
  margin-top: 30px;
`;

const StyledGrid = styled(Grid)`
  flex: 0 0 100%;
`;

const StyledDatePicker = styled(DatePicker)`
  margin-right: 30px;
`;

const StyledTimePicker = styled(TimePicker)`
  margin-right: 30px;
`;

const StyledButton = styled(Button)`
  margin-left: 30px;
`;

const StepTwo = ({
  isScheduled,
  onSendPreview,
  previewPhone,
  selectedDate,
  selectedTime,
  selectedTimeZone,
  setPreviewPhone,
  setSelectedDate,
  setSelectedTime,
  setSelectedTimeZone,
  setIsScheduled,
  timezones,
}) => (
  <>
    <CardFieldWrapper>
      <CardField
        name="Preview"
        flexwrap
      >
        <Grid container>
          <Grid
            direction="column"
            container
            item
            xs={6}
            md={6}
          >
            <Typography gutterBottom>
              You can enter a single mobile phone number
              to send a preview of the Text Action Alert to.
            </Typography>
            <Grid
              alignItems="flex-end"
              container
            >
              <TextField
                inputProps={{
                  maxLength: 10,
                }}
                label="Phone number"
                onChange={event => {
                  const { value } = event.target;

                  setPreviewPhone(handleNumberFormat(value));
                }}
                placeholder="(123) 123-1231"
                value={previewPhone}
              />
              <StyledButton
                color="secondary"
                onClick={onSendPreview}
                variant="contained"
              >
                Send Preview
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </CardField>
    </CardFieldWrapper>
    <CardFieldWrapper>
      <CardField
        flexwrap
        name="Time Schedule"
      >
        <Grid container>
          <Typography gutterBottom>
            You can choose to send the Text Action Alert right away (default) or choose
            a time in the future to schedule the email. If scheduling for a time in the future,
            you will be able to choose a day, hour and timezone.
          </Typography>
          <RadioGroup>
            <FormControlLabel
              checked={!isScheduled}
              control={<Radio />}
              onChange={() => setIsScheduled(false)}
              label="Send right away"
            />
            <FormControlLabel
              checked={isScheduled}
              control={<Radio />}
              onChange={() => setIsScheduled(true)}
              label="Send at a scheduled time"
            />
          </RadioGroup>
        </Grid>
        <StyledGrid container>
          { isScheduled && (
          <>
            <StyledDatePicker
              id="date-picker-dialog"
              label="Date picker dialog"
              value={selectedDate}
              onChange={date => setSelectedDate(date)}
              minDate={dayjs()}
            />
            <StyledTimePicker
              id="time-picker"
              label="Time picker"
              value={selectedTime}
              onChange={date => setSelectedTime(date)}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
            />
            <Select
              value={selectedTimeZone}
              onChange={event => setSelectedTimeZone(event.target.value)}
            >
              {timezones.map(timezone => (
                <MenuItem
                  key={timezone}
                  value={timezone}
                >
                  {timezone}
                </MenuItem>
              ))}
            </Select>
          </>
          )}
        </StyledGrid>
      </CardField>
    </CardFieldWrapper>
  </>
);

StepTwo.propTypes = {
  isScheduled: PropTypes.bool.isRequired,
  onSendPreview: PropTypes.func.isRequired,
  previewPhone: PropTypes.string.isRequired,
  selectedDate: PropTypes.shape({
    $d: PropTypes.instanceOf(Date),
  }).isRequired,
  selectedTime: PropTypes.shape({
    $d: PropTypes.instanceOf(Date),
  }).isRequired,
  selectedTimeZone: PropTypes.string.isRequired,
  setIsScheduled: PropTypes.func.isRequired,
  setPreviewPhone: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  setSelectedTime: PropTypes.func.isRequired,
  setSelectedTimeZone: PropTypes.func.isRequired,
  timezones: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StepTwo;
