export const contactSelectors = {
  getChildrenLists: state => state.contacts.lists.children,
  getContactDetails: state => state.contacts.contactDetails,
  getContacts: state => state.contacts.contacts.results,
  getErrors: state => state.contacts.errors,
  getFlatFileConfig: state => state.contacts.flatFileConfig,
  getInProgressFetchedContactsCount: state => state.contacts.inProgressFetchedContactsCount,
  getList: state => state.contacts.list,
  getListContacts: state => state.contacts.listContacts,
  getListContactsLocations: state => state.contacts.listContactsLocations,
  getListContactsLocationsCount: state => state.contacts.listContactsLocationsCount,
  getListGroups: state => state.contacts.listGroups,
  getOrganizationRootList: state => state.contacts.lists.root,
  getTotalListContactsCount: state => state.contacts.totalListContactsCount,
  isChildrenListsFetching: state => state.contacts.isChildrenListsFetching,
  isContactFetching: state => state.contacts.isContactFetching,
  isFetching: state => state.contacts.isFetching,
  isListContactsFetching: state => state.contacts.isListContactsFetching,
  isListFetching: state => state.contacts.isListFetching,
  isPendingList: state => state.contacts.isPending,
};
