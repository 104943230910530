export const organizationSelectors = {
  getApiKeysDetails: state => state.organizations.apiKeysDetails.results,
  getEmailDomainData: state => state.organizations.emailDomainData,
  getInvitationDetails: state => state.organizations.invitationDetails,
  getInvitedMembers: state => state.organizations.invitedMembers,
  getOrganization: state => state.organizations.organization,
  getOrganizationsData: state => state.organizations.list,
  getTeamMembers: state => state.organizations.teamMembers,
  isOrganizationFetching: state => state.organizations.isOrganizationFetching,
  isPending: state => state.organizations.isPending,
};
