import request from 'utils/request';

const PREFIX = '[MEETINGS]';

const apiRoute = '/v1/meetings/';

export const FETCH_MEETINGS_REQUEST = `${PREFIX} FETCH_MEETINGS_REQUEST`;
export const FETCH_MEETINGS_SUCCESS = `${PREFIX} FETCH_MEETINGS_SUCCESS`;
export const FETCH_MEETINGS_FAILURE = `${PREFIX} FETCH_MEETINGS_FAILURE`;

const fetchMeetingsRequest = () => ({
  type: FETCH_MEETINGS_REQUEST,
});

const fetchMeetingsSuccess = payload => ({
  payload,
  type: FETCH_MEETINGS_SUCCESS,
});

const fetchMeetingsFailure = payload => ({
  payload,
  type: FETCH_MEETINGS_FAILURE,
});

export const fetchMeetings = organizationUuid => dispatch => {
  dispatch(fetchMeetingsRequest());

  return request
    .get(`${apiRoute}?organization=${organizationUuid}`)
    .then(({ data }) => {
      dispatch(fetchMeetingsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchMeetingsFailure(data));

      throw data;
    });
};

export const FETCH_ALL_MEETINGS_REQUEST = `${PREFIX} FETCH_ALL_MEETINGS_REQUEST`;
export const FETCH_ALL_LIST_CONTACTS_IN_PROGRESS = `${PREFIX} FETCH_ALL_LIST_CONTACTS_IN_PROGRESS`;
export const FETCH_ALL_MEETINGS_SUCCESS = `${PREFIX} FETCH_ALL_MEETINGS_SUCCESS`;
export const FETCH_ALL_MEETINGS_FAILURE = `${PREFIX} FETCH_ALL_MEETINGS_FAILURE`;

const fetchAllMeetingsRequest = () => ({
  type: FETCH_ALL_MEETINGS_REQUEST,
});

const fetchAllMeetingsInProgress = payload => ({
  payload,
  type: FETCH_ALL_LIST_CONTACTS_IN_PROGRESS,
});

const fetchAllMeetingsSuccess = payload => ({
  payload,
  type: FETCH_ALL_MEETINGS_SUCCESS,
});

const fetchAllMeetingsFailure = payload => ({
  payload,
  type: FETCH_ALL_MEETINGS_FAILURE,
});

export const fetchAllMeetings = organizationUuid => dispatch => {
  dispatch(fetchAllMeetingsRequest());
  const contacts = [];

  const fetchAllMeetingsWithPagination = next => request
    .get(next || `${apiRoute}?organization=${organizationUuid}`)
    .then(({ data }) => {
      contacts.push(...data.results);

      dispatch(fetchAllMeetingsInProgress({
        allCount: data.count,
        fetchedCount: data.results.length,
      }));

      if (!data.next) {
        return contacts;
      }

      return fetchAllMeetingsWithPagination(data.next);
    });

  return fetchAllMeetingsWithPagination()
    .then(data => {
      dispatch(fetchAllMeetingsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchAllMeetingsFailure(data));

      throw data;
    });
};

export const FETCH_MEETING_REQUEST = `${PREFIX} FETCH_MEETING_REQUEST`;
export const FETCH_MEETING_SUCCESS = `${PREFIX} FETCH_MEETING_SUCCESS`;
export const FETCH_MEETING_FAILURE = `${PREFIX} FETCH_MEETING_FAILURE`;

const fetchMeetingRequest = () => ({
  type: FETCH_MEETING_REQUEST,
});

const fetchMeetingSuccess = payload => ({
  payload,
  type: FETCH_MEETING_SUCCESS,
});

const fetchMeetingFailure = payload => ({
  payload,
  type: FETCH_MEETING_FAILURE,
});

export const fetchMeeting = meetingUuid => dispatch => {
  dispatch(fetchMeetingRequest());

  return request
    .get(`${apiRoute}${meetingUuid}/`)
    .then(({ data }) => {
      dispatch(fetchMeetingSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchMeetingFailure(data));

      throw data;
    });
};

export const DELETE_MEETING_REQUEST = `${PREFIX} DELETE_MEETING_REQUEST`;
export const DELETE_MEETING_SUCCESS = `${PREFIX} DELETE_MEETING_SUCCESS`;
export const DELETE_MEETING_FAILURE = `${PREFIX} DELETE_MEETING_FAILURE`;

const deleteMeetingRequest = () => ({
  type: DELETE_MEETING_REQUEST,
});

const deleteMeetingSuccess = payload => ({
  payload,
  type: DELETE_MEETING_SUCCESS,
});

const deleteMeetingFailure = payload => ({
  payload,
  type: DELETE_MEETING_FAILURE,
});

export const deleteMeeting = meetingUuid => dispatch => {
  dispatch(deleteMeetingRequest());

  return request
    .delete(`${apiRoute}${meetingUuid}/`)
    .then(({ data }) => {
      dispatch(deleteMeetingSuccess(data));
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteMeetingFailure(data));

      throw data;
    });
};

export const POST_MEETING_REQUEST = `${PREFIX} POST_MEETING_REQUEST`;
export const POST_MEETING_SUCCESS = `${PREFIX} POST_MEETING_SUCCESS`;
export const POST_MEETING_FAILURE = `${PREFIX} POST_MEETING_FAILURE`;

const postMeetingRequest = () => ({
  type: POST_MEETING_REQUEST,
});

const postMeetingSuccess = payload => ({
  payload,
  type: POST_MEETING_SUCCESS,
});

const postMeetingFailure = payload => ({
  payload,
  type: POST_MEETING_FAILURE,
});

export const postMeeting = params => dispatch => {
  dispatch(postMeetingRequest());

  return request
    .post(`${apiRoute}`, params)
    .then(({ data }) => {
      dispatch(postMeetingSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postMeetingFailure(data));

      throw data;
    });
};

export const PATCH_MEETING_REQUEST = `${PREFIX} PATCH_MEETING_REQUEST`;
export const PATCH_MEETING_SUCCESS = `${PREFIX} PATCH_MEETING_SUCCESS`;
export const PATCH_MEETING_FAILURE = `${PREFIX} PATCH_MEETING_FAILURE`;

const patchMeetingRequest = () => ({
  type: PATCH_MEETING_REQUEST,
});

const patchMeetingSuccess = payload => ({
  payload,
  type: PATCH_MEETING_SUCCESS,
});

const patchMeetingFailure = payload => ({
  payload,
  type: PATCH_MEETING_FAILURE,
});

export const patchMeeting = (meetingUuid, params) => dispatch => {
  dispatch(patchMeetingRequest());

  return request
    .patch(`${apiRoute}${meetingUuid}/`, params)
    .then(({ data }) => {
      dispatch(patchMeetingSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchMeetingFailure(data));

      throw data;
    });
};
