export const globalSelectors = {
  getBoundaries: state => state.global.getBoundaries,
  getBoundariesTop: state => state.global.boundariesTop,
  getBoundariesTree: state => state.global.boundariesTree,
  getIsGeolocated: state => state.global.isGeolocated,
  getMapCenter: state => state.global.mapCenter,
  getOrganization: state => state.global.organization,
  getSnackbarError: state => state.global.snackbarError,
  getSnackbarSuccess: state => state.global.snackbarSuccess,
  getSnackbarWarning: state => state.global.snackbarWarning,
  isBoundariesFetching: state => state.global.isBoundariesFetching,
  isBoundariesTopFetching: state => state.global.isBoundariesTopFetching,
  isBoundariesTreeFetching: state => state.global.isBoundariesTreeFetching,
};
