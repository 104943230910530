import {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import {
  Comment as CommentIcon,
} from '@mui/icons-material';

import {
  exportActionCenter,
  exportMessagesReportForActionCenter,
  fetchActionCenter,
  fetchActionCenterActions,
  fetchActionCenterSignups,
  fetchActionCenterTargets,
  fetchAllActionAlerts,
  patchTarget,
} from 'store/actions/campaigns';
import { fetchContacts } from 'store/actions/contacts';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';
import { campaignsSelectors } from 'store/selectors/campaigns';
import { contactSelectors } from 'store/selectors/contacts';

import {
  Backdrop,
  BreadcrumbsNavigation,
  Container,
  TabPanel,
  TargetsTable,
} from 'common/components';

import {
  useQuery,
} from 'utils/helpers';
import { CircularProgressWrapper } from 'globalStyles';
import { colors } from 'themes';

import ActionAlertsTable from '../components/ActionAlertsTable';
import ActionCenterSignupsTable from '../components/ActionCenterSignupsTable';
import ActionsTakenTable from '../components/ActionsTakenTable';
import ActionCenterAnalyticsTabs from '../components/ActionCenterAnalyticsTabs';
import WidgetsTab from '../components/WidgetsTab';
import TopCardWrapper from '../components/TopCardWrapper';
import ActionCenterOverview from '../components/ActionCenterOverview';

const TablesWrapper = styled.div`
  margin: 30px 0 140px;
`;

const StyledPaper = styled(Paper)`
  width: 100%;
  border-bottom: 0;
`;

const ExportButton = styled(Button)`
  margin: 50px 30px;
`;

const StyledImg = styled.img`
  width: 22px;
`;

const MessageBlock = styled.div`
  margin-top: 30px;
  white-space: pre-wrap;
`;

const ContentTitle = styled.span`
  font-weight: bold;
`;

const MessageWrapper = styled.div`
  margin: 10px 0;
  padding: 26px 95px 38px;
  background-color: ${colors.wildSand};
  font-family: Quicksand, sans-serif;
`;

const FormTitle = styled(Typography)`
  margin-top: 20px;
  border-left: 4px solid ${colors.dodgerBlue};
  padding-left: 7px;
  font-size: 16px;
  font-family: Quicksand, sans-serif;
  line-height: 20px;
`;

const MainTabPanel = styled(TabPanel)`
  width: 100%;
  border-radius: 8px;
  padding: 24px;
  background-color: ${({ theme }) => (theme.colors.white)};
`;

const ActionCenterDetails = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const tabIndex = query.get('tab');

  const {
    actionCenterUuid,
    campaignUuid,
    organizationUuid,
  } = useParams();
  const firstFetch = useRef({
    actionAlerts: false,
    signups: false,
    targets: false,
  });

  const {
    actionCenterActions,
    actionCenterDetails,
    allActionAlertsList,
    contacts,
    isActionCenterActionsFetching,
    isActionCenterFetching,
    isActionCenterSignupsFetching,
    isActionCenterTargetsFetching,
    isAllActionAlertsFetching,
    isPending,
    signupsList,
    targets,
  } = useSelector(state => ({
    actionCenterActions: campaignsSelectors.getActionCentersActionsData(state),
    actionCenterDetails: campaignsSelectors.getActionCenterDetails(state),
    allActionAlertsList: campaignsSelectors.getAllActionAlertsList(state),
    contacts: contactSelectors.getContacts(state),
    isActionCenterActionsFetching: campaignsSelectors.isActionCenterActionsFetching(state),
    isActionCenterFetching: campaignsSelectors.isActionCenterFetching(state),
    isActionCenterSignupsFetching: campaignsSelectors.isActionCenterSignupsFetching(state),
    isActionCenterTargetsFetching: campaignsSelectors.isActionCenterTargetsFetching(state),
    isAllActionAlertsFetching: campaignsSelectors.isAllActionAlertsFetching(state),
    isPending: campaignsSelectors.isPending(state),
    signupsList: campaignsSelectors.getActionCenterSignups(state),
    targets: campaignsSelectors.getActionCenterTargets(state),
  }));

  const [
    tablesTabIndex,
    setTablesTabIndex,
  ] = useState(0);
  const [
    isContactsFetched,
    setIsContactsFetched,
  ] = useState(false);

  const {
    campaign,
    messages,
    mode,
    name,
    publishStatus,
    talkingPoints,
    usesMessageApproval,
    uuid,
  } = actionCenterDetails;

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/campaigns`,
      name: 'Advocacy',
    },
    {
      link: `/${organizationUuid}/campaigns/${campaignUuid}`,
      name: campaign?.name,
    },
    {
      link: '',
      name,
    },
  ];

  useEffect(() => {
    dispatch(fetchActionCenter(actionCenterUuid));
    dispatch(fetchActionCenterActions(actionCenterUuid));
    dispatch(fetchContacts(organizationUuid, actionCenterUuid, true))
      .then(() => setIsContactsFetched(true));
  }, [
    actionCenterUuid,
    dispatch,
  ]);

  const handleChangeTablesTab = (_, newValue) => {
    setTablesTabIndex(newValue);

    if (newValue === 1 && !firstFetch.current.targets) {
      dispatch(fetchActionCenterTargets(uuid))
        .then(() => { firstFetch.current.targets = true; });
    }

    if (newValue === 2 && !firstFetch.current.signups) {
      dispatch(fetchActionCenterSignups(uuid))
        .then(() => { firstFetch.current.signups = true; });
    }

    if (newValue === 3 && !firstFetch.current.actionAlerts) {
      dispatch(fetchAllActionAlerts({ actionCenter: uuid }))
        .then(() => { firstFetch.current.actionAlerts = true; });
    }
  };

  const handleChangeTargetActive = async target => {
    const enabledTargets = targets.filter(targetItem => targetItem.isActive);

    if (target.isActive && enabledTargets.length === 1) {
      return dispatch(setSnackbarError({
        duration: 6000,
        message: 'Published action centers must have at least one active target. If you want to stop all contact with officials stop your action center.',
      }));
    }

    return dispatch(patchTarget(target.uuid, { isActive: !target.isActive }));
  };

  const handleExport = () => {
    dispatch(exportActionCenter(uuid))
      .then(({ detail }) => dispatch(setSnackbarSuccess({ message: detail })))
      .catch(() => dispatch(setSnackbarError()));
  };

  const handleMessagesExport = () => {
    dispatch(exportMessagesReportForActionCenter(uuid))
      .then(({ detail }) => dispatch(setSnackbarSuccess({ message: detail })))
      .catch(() => dispatch(setSnackbarError()));
  };

  if (isActionCenterFetching) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container>
        <Grid container>
          <TopCardWrapper />
        </Grid>
        <Grid container>
          <ActionCenterOverview />
        </Grid>
        <MainTabPanel
          index="0"
          value={tabIndex}
        >
          <ActionCenterAnalyticsTabs
            contacts={contacts}
            isContactsFetched={isContactsFetched}
          />
          <TablesWrapper>
            <StyledPaper
              square
              variant="outlined"
            >
              <Tabs
                indicatorColor="primary"
                onChange={handleChangeTablesTab}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: colors.secondary,
                  },
                }}
                value={tablesTabIndex}
              >
                <Tab label="Actions taken" />
                <Tab label="Targets" />
                <Tab label="Action Center Sign ups" />
                <Tab label="Action Alerts" />
              </Tabs>
            </StyledPaper>
            <TabPanel
              index={0}
              value={tablesTabIndex}
            >
              <ActionsTakenTable
                actionCenterActions={actionCenterActions}
                isActionCenterActionsFetching={isActionCenterActionsFetching}
                mode={mode}
                showMessageApproval={usesMessageApproval}
              />
            </TabPanel>
            <TabPanel
              index={1}
              value={tablesTabIndex}
            >
              <TargetsTable
                disableSwitch={isPending}
                enableTargets
                handleSwitcherChange={handleChangeTargetActive}
                targets={targets}
                isDataFetching={isActionCenterTargetsFetching}
              />
            </TabPanel>
            <TabPanel
              index={2}
              value={tablesTabIndex}
            >
              <ActionCenterSignupsTable
                signupsList={signupsList}
                isDataFetching={isActionCenterSignupsFetching}
              />
            </TabPanel>
            <TabPanel
              index={3}
              value={tablesTabIndex}
            >
              <ActionAlertsTable
                actionAlerts={allActionAlertsList}
                isDataFetching={isAllActionAlertsFetching}
              />
            </TabPanel>
          </TablesWrapper>
        </MainTabPanel>
        <MainTabPanel
          index="1"
          value={tabIndex}
        >
          <div>
            <Button
              color="secondary"
              disabled={isPending}
              disableElevation
              onClick={handleExport}
              startIcon={(
                <StyledImg
                  alt="excel icon"
                  src="/excel_icon.svg"
                />
              )}
              variant="contained"
            >
              Comprehensive Excel Report
            </Button>
            <ExportButton
              color="secondary"
              disabled={isPending}
              disableElevation
              onClick={handleMessagesExport}
              startIcon={<CommentIcon />}
              variant="contained"
            >
              Action Messages PDF Report
            </ExportButton>
          </div>
        </MainTabPanel>
        <MainTabPanel
          index="2"
          value={tabIndex}
        >
          <div>
            {messages?.map(({
              body,
              subject,
              uuid: messageUuid,
            }, index) => (
              <Fragment key={messageUuid}>
                { mode === 'act' && (
                  <ContentTitle>
                    Target message
                    {' '}
                    {index + 1}
                  </ContentTitle>
                )}
                <MessageWrapper>
                  <FormTitle>Subject</FormTitle>
                  <MessageBlock>
                    <div>
                      <p>
                        {subject.name}
                      </p>
                    </div>
                  </MessageBlock>
                  <FormTitle>Body</FormTitle>
                  {body?.map(messageParagraph => (
                    <MessageBlock key={messageParagraph.name}>
                      <div>
                        <p>
                          {messageParagraph.name}
                        </p>
                      </div>
                    </MessageBlock>
                  ))}
                </MessageWrapper>
              </Fragment>
            ))}
            {talkingPoints && (
              <>
                <ContentTitle>
                  Talking Points:
                </ContentTitle>
                <MessageBlock>
                  {talkingPoints}
                </MessageBlock>
              </>
            )}
          </div>
        </MainTabPanel>
        <MainTabPanel
          index="3"
          value={tabIndex}
        >
          <WidgetsTab publishStatus={publishStatus} />
        </MainTabPanel>
        <Backdrop isOpen={isPending} />
      </Container>
    </>
  );
};

export default ActionCenterDetails;
