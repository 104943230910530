import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import {
  Cancel as CancelIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  Telegram as TelegramIcon,
} from '@mui/icons-material';

import {
  deleteTextBroadcast,
  fetchTextBroadcast,
  fetchTextBroadcastRecipients,
  stopTextBroadcast,
} from 'store/actions/broadcasts';
import {
  setSnackbarError,
  setSnackbarSuccess,
  setSnackbarWarning,
} from 'store/actions/global';
import { broadcastsSelectors } from 'store/selectors/broadcasts';

import {
  Backdrop,
  BreadcrumbsNavigation,
  ButtonMenu,
  Container,
  DescriptionBar,
  DescriptionBarInfo,
  RecipientsTable,
  StatisticCard,
  TabPanel,
  Tabs,
} from 'common/components';

import {
  actionTypes,
  cancellationStatuses,
  statuses,
} from 'utils/constants';
import {
  DetailsWrapper,
  DisabledListItemText,
  StatisticsWrapper,
} from 'globalStyles';

const ActionButtonsWrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const StyledPaper = styled(Paper)`
  border-bottom: 0;
`;

const BroadcastTablesWrapper = styled.div`
  margin: 64px 0 82px;
`;

const TextBroadcastDetails = () => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const history = useHistory();
  const {
    broadcastUuid,
    organizationUuid,
  } = useParams();
  const { draft } = statuses;

  const {
    broadcastRecipients,
    isBroadcastFetching,
    isBroadcastRecipientsFetching,
    textBroadcastDetails,
  } = useSelector(state => ({
    broadcastRecipients: broadcastsSelectors.getBroadcastRecipients(state),
    isBroadcastFetching: broadcastsSelectors.isBroadcastFetching(state),
    isBroadcastRecipientsFetching: broadcastsSelectors.isBroadcastRecipientsFetching(state),
    textBroadcastDetails: broadcastsSelectors.getTextBroadcastDetails(state),
  }));

  useEffect(() => {
    dispatch(fetchTextBroadcast(broadcastUuid));
    dispatch(fetchTextBroadcastRecipients(broadcastUuid));
  }, []);

  const [
    tabIndex,
    setTabIndex,
  ] = useState('recipients');

  const {
    contactLists,
    createdBy,
    isCancellable,
    message,
    name,
    sendAt,
    status,
  } = textBroadcastDetails;

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/broadcasts`,
      name: 'Broadcasts',
    },
    {
      link: '',
      name,
    },
  ];

  const { probablyCancelled } = cancellationStatuses;

  const handleChangeTablesTab = newValue => {
    setTabIndex(newValue);
  };

  const handleDeleteBroadcast = () => {
    confirm({
      confirmationText: 'Delete',
      description: 'Deleting this broadcast will also remove any related recipient analytics.',
      title: 'Delete the Broadcast?',
    })
      .then(() => dispatch(deleteTextBroadcast(broadcastUuid)))
      .then(() => history.push(`/${organizationUuid}/broadcasts`));
  };

  const handleStopBroadcast = () => {
    dispatch(stopTextBroadcast(broadcastUuid))
      .then(({
        message: stopMessage,
        status: stopedBroadcastStatus,
      }) => {
        if (stopedBroadcastStatus === probablyCancelled) {
          dispatch(setSnackbarWarning({ message: stopMessage }));
        } else {
          dispatch(setSnackbarSuccess({ message: stopMessage }));
        }
        dispatch(fetchTextBroadcast(broadcastUuid));
      })
      .catch(({ message: stopMessage }) => dispatch(setSnackbarError({ message: stopMessage })));
  };

  const handleConfirmStopBroadcast = () => {
    confirm({
      cancellationText: 'No',
      confirmationText: 'Yes, Cancel',
      description: '',
      title: 'Cancel send the Broadcast?',
    })
      .then(() => handleStopBroadcast(broadcastUuid));
  };

  const handleEditBroadcast = () => {
    history.push(`/${organizationUuid}/${broadcastUuid}/create-text-broadcast`);
  };

  const handleDuplicateBroadcast = () => {
    history.push(`/${organizationUuid}/create-text-broadcast/${broadcastUuid}`);
  };

  const textBroadcastTabs = [
    {
      label: 'Recipients',
      value: 'recipients',
    },
  ];

  const DescriptionInfo = (
    <DescriptionBarInfo
      contactLists={contactLists}
      createdBy={createdBy}
      isButtonVisible
      message={message}
      sendAt={sendAt}
      type={actionTypes.text}
    />
  );

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container>
        <Grid container>
          <DescriptionBar
            backButtonLink={`/${organizationUuid}/broadcasts`}
            descriptionBarInfo={DescriptionInfo}
            hasBackButton
            icon={TelegramIcon}
            isHighBar
            name={name}
          >
            <ActionButtonsWrapper>
              {isCancellable && (
                <StyledButton
                  startIcon={<CancelIcon />}
                  disabled={!isCancellable}
                  onClick={handleConfirmStopBroadcast}
                  variant="outlined"
                >
                  Cancel send
                </StyledButton>
              )}
              <ButtonMenu>
                <MenuItem
                  onClick={handleEditBroadcast}
                  disabled={status !== draft}
                >
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <DisabledListItemText
                    isdisabled={status !== draft ? 1 : 0}
                    primary="Edit Broadcast"
                  />
                </MenuItem>
                <MenuItem onClick={handleDuplicateBroadcast}>
                  <ListItemIcon>
                    <FileCopyOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Duplicate" />
                </MenuItem>
                <MenuItem onClick={handleDeleteBroadcast}>
                  <ListItemIcon>
                    <DeleteOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delete" />
                </MenuItem>
              </ButtonMenu>
            </ActionButtonsWrapper>
          </DescriptionBar>
        </Grid>
        <DetailsWrapper>
          <StatisticsWrapper>
            <StatisticCard
              count={broadcastRecipients?.length || 0}
              text="Recipients"
            />
          </StatisticsWrapper>
        </DetailsWrapper>
        <BroadcastTablesWrapper>
          <StyledPaper
            square
            variant="outlined"
          >
            <Tabs
              handleSetTab={handleChangeTablesTab}
              tabs={textBroadcastTabs}
              tabIndex={tabIndex}
            />
          </StyledPaper>
          <TabPanel
            index={textBroadcastTabs[0].value}
            value={tabIndex}
          >
            <RecipientsTable
              isDataFetching={isBroadcastRecipientsFetching}
              isTextRecipients
              recipients={broadcastRecipients}
            />
          </TabPanel>
        </BroadcastTablesWrapper>
        <Backdrop isOpen={isBroadcastFetching} />
      </Container>
    </>
  );
};

export default TextBroadcastDetails;
