/* eslint-disable no-underscore-dangle */
import {
  applyMiddleware,
  compose,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

export default function configureStore(initialState) {
  const middlewares = [thunk];
  let enhancer;

  if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancer = compose(
      applyMiddleware(...middlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  } else {
    enhancer = compose(applyMiddleware(...middlewares));
  }

  const store = createStore(reducers, initialState, enhancer);

  return store;
}
