import { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import {
  DeleteOutline as DeleteOutlineIcon,
  FileCopy as FileCopyIcon,
} from '@mui/icons-material';

import { Popover } from 'common/components';
import { contentBlockType } from 'common/typings';
import { colors } from 'utils/constants';
import {
  scrollToBottom,
  scrollToTop,
} from 'utils/helpers';

const MessageDescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const FieldWrapper = styled.div`
  margin: 20px 0;
`;

const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentOptionWrapper = styled(OptionWrapper)`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
`;

const PairWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const PairContentWrapper = styled.div`
  margin: 8px 0 15px;
  padding: 27px 33px;
  background-color: ${colors.wildSand};
`;

const StyledTextField = styled(TextField)`
  margin: 10px;
`;

const StyledTypography = styled(Typography)`
  margin-top: 20px;
`;

const MESSAGE_BODY_DESCRIPTION = 'A prefilled message will be included as the first section of every message to a target. You can leave this blank to let the contact have full control over their message. All messages come with a salutation.';
const ALLOW_EDIT_SUBJECT_DESCRIPTION = 'Non-editable subject lines must be at least 6 characters long to meet email requirements.';

const SubjectMessageCreator = ({
  allowEditing,
  hideDeleteButton,
  hideDuplicateMessageButton,
  messageIndex,
  setSubjectMessagesPairData,
  showMessageIndex,
  subjectMessagesPairData,
}) => {
  const handleSubjectChange = name => {
    setSubjectMessagesPairData(prevSubjectMessagesPairData => {
      const newSubjectMessagesPairData = [...prevSubjectMessagesPairData];

      newSubjectMessagesPairData[messageIndex].subject.name = name;
      // eslint-disable-next-line max-len
      newSubjectMessagesPairData[messageIndex].subject.isAllowEdit = allowEditing && name.length < 6;

      return newSubjectMessagesPairData;
    });
  };

  const handleRemoveSubjectMessagePair = () => {
    setSubjectMessagesPairData(prevSubjectMessagesPairData => {
      const newSubjectMessagesPairData = [...prevSubjectMessagesPairData];

      newSubjectMessagesPairData.splice(messageIndex, 1);

      return newSubjectMessagesPairData;
    });
    scrollToTop();
  };

  const handleMessageTextChange = (name, blockIndex) => {
    setSubjectMessagesPairData(prevSubjectMessagesPairData => {
      const newSubjectMessagesPairData = [...prevSubjectMessagesPairData];

      newSubjectMessagesPairData[messageIndex].body[blockIndex].name = name;

      if (!name.length) {
        newSubjectMessagesPairData[messageIndex].body[blockIndex].isAllowEdit = true;
      }

      if (!allowEditing) {
        newSubjectMessagesPairData[messageIndex].body[blockIndex].isAllowEdit = false;
      }

      return newSubjectMessagesPairData;
    });
  };

  const handleEditableSubjectChange = value => {
    setSubjectMessagesPairData(prevSubjectMessagesPairData => {
      const newSubjectMessagesPairData = [...prevSubjectMessagesPairData];

      newSubjectMessagesPairData[messageIndex].subject.isAllowEdit = !value && allowEditing;

      return newSubjectMessagesPairData;
    });
  };

  const handleEditableMessageChange = (value, blockIndex) => {
    setSubjectMessagesPairData(prevSubjectMessagesPairData => {
      const newSubjectMessagesPairData = [...prevSubjectMessagesPairData];

      // eslint-disable-next-line max-len
      newSubjectMessagesPairData[messageIndex].body[blockIndex].isAllowEdit = !value && allowEditing;

      return newSubjectMessagesPairData;
    });
  };

  const handleAddContentBlock = () => {
    setSubjectMessagesPairData(prevSubjectMessagesPairData => {
      const newSubjectMessagesPairData = [...prevSubjectMessagesPairData];

      newSubjectMessagesPairData[messageIndex].body.push({
        isAllowEdit: true,
        name: '',
        uuid: uuid(),
      });

      return newSubjectMessagesPairData;
    });
  };

  const handleRemoveContentBlock = blockIndex => {
    setSubjectMessagesPairData(prevSubjectMessagesPairData => {
      const newSubjectMessagesPairData = [...prevSubjectMessagesPairData];

      newSubjectMessagesPairData[messageIndex].body.splice(blockIndex, 1);

      return newSubjectMessagesPairData;
    });
  };

  const handleSetIsAllowPersonalMessage = value => {
    setSubjectMessagesPairData(prevSubjectMessagesPairData => {
      const newSubjectMessagesPairData = [...prevSubjectMessagesPairData];

      newSubjectMessagesPairData[messageIndex].isAllowPersonalMessage = !value && allowEditing;

      return newSubjectMessagesPairData;
    });
  };

  const handleDuplicateSubjectMessagePair = () => {
    setSubjectMessagesPairData(prevSubjectMessagesPairData => {
      const newSubjectMessagesPairData = [...prevSubjectMessagesPairData];

      newSubjectMessagesPairData[newSubjectMessagesPairData.length] =
        {
          body: newSubjectMessagesPairData[messageIndex].body.map(paragraph => ({
            ...paragraph,
            uuid: uuid(),
          })),
          isAllowPersonalMessage: newSubjectMessagesPairData[messageIndex].isAllowPersonalMessage,
          subject: {
            ...newSubjectMessagesPairData[messageIndex].subject,
          },
          uuid: uuid(),
        };

      return newSubjectMessagesPairData;
    });
    scrollToBottom();
  };

  const {
    body,
    subject,
    isAllowPersonalMessage,
  } = subjectMessagesPairData;

  const isSubjectEditingDisabled = () => !allowEditing || subject.name.length < 6;

  const popOverSubjectText = () => {
    if (!allowEditing) {
      return 'This field will be non-editable for advocates.';
    }

    if (subject.name.length < 6) {
      return ALLOW_EDIT_SUBJECT_DESCRIPTION;
    }

    return '';
  };

  const isBlockEditingDisabled = contentBlock => !allowEditing || !contentBlock.name.length;

  return (
    <PairWrapper>
      {showMessageIndex && (
        <Typography>
          {`Message ${messageIndex + 1}`}
        </Typography>
      )}
      <PairContentWrapper>
        <FieldWrapper>
          <TextField
            fullWidth
            label="Subject"
            onChange={event => handleSubjectChange(event.target.value)}
            required
            type="text"
            value={subject.name}
            variant="standard"
          />
          <OptionWrapper>
            <Popover
              shouldDisplayPopover={popOverSubjectText().length > 0}
              text={popOverSubjectText()}
            >
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={subject.isAllowEdit && allowEditing}
                    disabled={isSubjectEditingDisabled()}
                    onChange={() => handleEditableSubjectChange(subject.isAllowEdit)}
                  />
                )}
                label="Allow contacts to edit the subject *"
              />
            </Popover>
          </OptionWrapper>
        </FieldWrapper>
        <FieldWrapper>
          <Typography>Body</Typography>
          <Popover
            shouldDisplayPopover
            text={MESSAGE_BODY_DESCRIPTION}
          >
            <StyledTypography>
              Dear
              {' '}
              [
              <i>Recipient Name</i>
              ]
            </StyledTypography>
          </Popover>
          {
            body.map((contentBlock, blockIndex) => (
              <Fragment key={contentBlock.uuid}>
                <StyledTextField
                  fullWidth
                  label="Content Block"
                  multiline
                  onChange={event => handleMessageTextChange(
                    event.target.value,
                    blockIndex
                  )}
                  type="text"
                  value={contentBlock.name}
                  variant="standard"
                />
                <OptionWrapper>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={contentBlock.isAllowEdit && allowEditing}
                        disabled={isBlockEditingDisabled(contentBlock)}
                        onChange={() => handleEditableMessageChange(
                          contentBlock.isAllowEdit,
                          blockIndex
                        )
                        }
                      />
                    )}
                    label="Allow contacts to edit this content block"
                  />
                  <Button
                    disabled={body.length === 1}
                    onClick={() => handleRemoveContentBlock(blockIndex)}
                    startIcon={<DeleteOutlineIcon />}
                  >
                    Remove Block
                  </Button>
                </OptionWrapper>
              </Fragment>
            ))
          }
          {allowEditing && (
          <ContentOptionWrapper>
            <Button
              color="secondary"
              onClick={() => handleAddContentBlock()}
            >
              Add new content block
            </Button>
          </ContentOptionWrapper>
          )}
        </FieldWrapper>
        {allowEditing && (
        <MessageDescriptionWrapper>
          <FormControlLabel
            control={(
              <Checkbox
                checked={isAllowPersonalMessage}
                onChange={() => handleSetIsAllowPersonalMessage(isAllowPersonalMessage)}
              />
            )}
            label="Allow contacts to add their own personal message"
          />
          {!hideDeleteButton && (
            <Button
              onClick={() => handleRemoveSubjectMessagePair()}
              startIcon={<DeleteOutlineIcon />}
            >
              Delete message
            </Button>
          )}
        </MessageDescriptionWrapper>
        )}
        {!hideDuplicateMessageButton && (
          <MessageDescriptionWrapper>
            <Button
              color="secondary"
              onClick={() => handleDuplicateSubjectMessagePair()}
              startIcon={<FileCopyIcon />}
            >
              Duplicate message
            </Button>
          </MessageDescriptionWrapper>
        )}
      </PairContentWrapper>
    </PairWrapper>
  );
};

SubjectMessageCreator.defaultProps = {
  allowEditing: true,
  hideDeleteButton: false,
  hideDuplicateMessageButton: false,
  showMessageIndex: true,
};

SubjectMessageCreator.propTypes = {
  allowEditing: PropTypes.bool,
  hideDeleteButton: PropTypes.bool,
  hideDuplicateMessageButton: PropTypes.bool,
  messageIndex: PropTypes.number.isRequired,
  setSubjectMessagesPairData: PropTypes.func.isRequired,
  showMessageIndex: PropTypes.bool,
  subjectMessagesPairData: PropTypes.shape({
    body: PropTypes.arrayOf(contentBlockType),
    isAllowPersonalMessage: PropTypes.bool,
    messages: PropTypes.arrayOf(contentBlockType),
    subject: contentBlockType,
  }).isRequired,
};

export default SubjectMessageCreator;
