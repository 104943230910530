export { colors } from 'themes';

export const headerLinks = [
  {
    href: 'contacts/lists',
    name: 'Lists',
  },
  {
    href: 'campaigns',
    name: 'Advocacy',
  },
  {
    href: 'broadcasts',
    name: 'Broadcasts',
  },
  {
    href: 'settings?tab=account-information',
    name: 'Settings',
  },
];

export const footerLinks = [
  {
    href: '/resources',
    name: 'Resources',
  },
  {
    href: '/privacy',
    name: 'Privacy',
  },
  {
    href: '/terms-of-service',
    name: 'Terms of Service',
  },
];

export const usStates = [
  {
    abbreviation: 'AL',
    name: 'Alabama',
  },
  {
    abbreviation: 'AK',
    name: 'Alaska',
  },
  {
    abbreviation: 'AZ',
    name: 'Arizona',
  },
  {
    abbreviation: 'AR',
    name: 'Arkansas',
  },
  {
    abbreviation: 'CA',
    name: 'California',
  },
  {
    abbreviation: 'CO',
    name: 'Colorado',
  },
  {
    abbreviation: 'CT',
    name: 'Connecticut',
  },
  {
    abbreviation: 'DE',
    name: 'Delaware',
  },
  {
    abbreviation: 'DC',
    name: 'District Of Columbia',
  },
  {
    abbreviation: 'FL',
    name: 'Florida',
  },
  {
    abbreviation: 'GA',
    name: 'Georgia',
  },
  {
    abbreviation: 'HI',
    name: 'Hawaii',
  },
  {
    abbreviation: 'ID',
    name: 'Idaho',
  },
  {
    abbreviation: 'IL',
    name: 'Illinois',
  },
  {
    abbreviation: 'IN',
    name: 'Indiana',
  },
  {
    abbreviation: 'IA',
    name: 'Iowa',
  },
  {
    abbreviation: 'KS',
    name: 'Kansas',
  },
  {
    abbreviation: 'KY',
    name: 'Kentucky',
  },
  {
    abbreviation: 'LA',
    name: 'Louisiana',
  },
  {
    abbreviation: 'ME',
    name: 'Maine',
  },
  {
    abbreviation: 'MD',
    name: 'Maryland',
  },
  {
    abbreviation: 'MA',
    name: 'Massachusetts',
  },
  {
    abbreviation: 'MI',
    name: 'Michigan',
  },
  {
    abbreviation: 'MN',
    name: 'Minnesota',
  },
  {
    abbreviation: 'MS',
    name: 'Mississippi',
  },
  {
    abbreviation: 'MO',
    name: 'Missouri',
  },
  {
    abbreviation: 'MT',
    name: 'Montana',
  },
  {
    abbreviation: 'NE',
    name: 'Nebraska',
  },
  {
    abbreviation: 'NV',
    name: 'Nevada',
  },
  {
    abbreviation: 'NH',
    name: 'New Hampshire',
  },
  {
    abbreviation: 'NJ',
    name: 'New Jersey',
  },
  {
    abbreviation: 'NM',
    name: 'New Mexico',
  },
  {
    abbreviation: 'NY',
    name: 'New York',
  },
  {
    abbreviation: 'NC',
    name: 'North Carolina',
  },
  {
    abbreviation: 'ND',
    name: 'North Dakota',
  },
  {
    abbreviation: 'OH',
    name: 'Ohio',
  },
  {
    abbreviation: 'OK',
    name: 'Oklahoma',
  },
  {
    abbreviation: 'OR',
    name: 'Oregon',
  },
  {
    abbreviation: 'PA',
    name: 'Pennsylvania',
  },
  {
    abbreviation: 'RI',
    name: 'Rhode Island',
  },
  {
    abbreviation: 'SC',
    name: 'South Carolina',
  },
  {
    abbreviation: 'SD',
    name: 'South Dakota',
  },
  {
    abbreviation: 'TN',
    name: 'Tennessee',
  },
  {
    abbreviation: 'TX',
    name: 'Texas',
  },
  {
    abbreviation: 'UT',
    name: 'Utah',
  },
  {
    abbreviation: 'VT',
    name: 'Vermont',
  },
  {
    abbreviation: 'VA',
    name: 'Virginia',
  },
  {
    abbreviation: 'WA',
    name: 'Washington',
  },
  {
    abbreviation: 'WV',
    name: 'West Virginia',
  },
  {
    abbreviation: 'WI',
    name: 'Wisconsin',
  },
  {
    abbreviation: 'WY',
    name: 'Wyoming',
  },
];

export const mapSettings = {
  defaultCenter: {
    lat: 37.5245782,
    lng: -78.6138756,
  },
  geolocationOptions: {
    enableHighAccuracy: false,
    maximumAge: 60000,
    timeout: 5000,
  },
  zoom: 8,
};

export const campaignActionCentersColumns = [
  {
    field: 'fullName',
    title: 'Name',
  },
  {
    field: 'office',
    title: 'Office',
  },
];

export const actionCenterSignupsColumns = [
  {
    field: 'firstName',
    title: 'First name',
  },
  {
    field: 'lastName',
    title: 'Last name',
  },
  {
    field: 'email',
    title: 'Email',
  },
  {
    field: 'createdAt',
    title: 'Date',
    type: 'date',
  },
];

export const advocatesColumns = [
  {
    field: 'firstName',
    title: 'First name',
  },
  {
    field: 'lastName',
    title: 'Last name',
  },
  {
    field: 'email',
    title: 'Email',
  },
];

export const actionAlertRecipientsColumns = [
  {
    field: 'name',
    title: 'Name',
  },
  {
    field: 'email',
    title: 'Email',
  },
  {
    field: 'opened',
    title: 'Opened',
    type: 'date',
  },
  {
    field: 'clicked',
    title: 'Clicked',
  },
  {
    field: 'actionTaken',
    title: 'Action taken',
  },
];

export const linksColumns = [
  {
    field: 'url',
    title: 'URL',
  },
  {
    field: 'clicks',
    title: 'Click count',
  },
];

export const upperAdministrativeLevels = [
  {
    key: 0,
    value: 'Federal',
  },
  {
    key: 1,
    value: 'State',
  },
];

export const administrativeLevels = [
  {
    key: 0,
    value: 'Federal',
  },
  {
    key: 1,
    value: 'State',
  },
  {
    key: 2,
    value: 'Local',
  },
];

export const boundaryTypes = [
  {
    name: 'Entire State',
    type: 'state',
  },
  {
    name: 'Congressional District',
    type: 'congressional-district',
  },
  {
    name: 'Legislative District Upper Chamber',
    type: 'legislative-district-upper-chamber',
  },
  {
    name: 'Legislative District Lower Chamber',
    type: 'legislative-district-lower-chamber',
  },
  {
    name: 'County',
    type: 'county',
  },
  {
    name: 'City',
    type: 'city',
  },
  {
    name: 'County District',
    type: 'county-district',
  },
  {
    name: 'City District',
    type: 'city-district',
  },
  {
    name: 'School District',
    type: 'school-district',
  },
];

export const targetsColumns = [
  {
    field: 'fullName',
    title: 'Name',
  },
  {
    field: 'office.title',
    title: 'Position',
  },
  {
    field: 'office.state',
    title: 'State',
  },
];

export const chambers = [
  {
    key: 0,
    name: 'Lower',
    value: 'lower',
  },
  {
    key: 1,
    name: 'Upper',
    value: 'upper',
  },
  {
    key: 2,
    name: 'Joint',
    value: 'joint',
  },
];

export const selectableColors = [
  '#000',
  '#2979FF',
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffd600',
  '#ffc107',
  '#ff9800',
  '#795548',
  '#607d8b',
];

export const generalInfoFormFields = [
  {
    isRequired: true,
    label: 'Name',
    name: 'name',
    type: 'text',
  },
  {
    isRequired: false,
    label: 'Email',
    name: 'email',
    type: 'email',
  },
  {
    isRequired: false,
    label: 'Website URL',
    name: 'websiteUrl',
    type: 'text',
  },
  {
    helperText: 'Optionally add your own privacy policy link in action centers & signup forms.',
    isRequired: false,
    label: 'Privacy Policy URL',
    name: 'privacyPolicyUrl',
    type: 'text',
  },
  {
    isRequired: false,
    label: 'Phone',
    name: 'phone',
    type: 'text',
  },
];

export const mailingAddressFormFields = [
  {
    label: 'Street',
    name: 'streetAddress',
    type: 'text',
  },
  {
    label: 'Street 2',
    name: 'streetAddress2',
    type: 'text',
  },
  {
    label: 'City',
    name: 'city',
    type: 'text',
  },
  {
    label: 'State',
    name: 'state',
    type: 'text',
  },
  {
    label: 'Zip',
    name: 'zipCode',
    type: 'text',
  },
];

export const domainEmailFormFields = [
  {
    label: 'Domain',
    name: 'domain',
    type: 'text',
  },
];

export const LIVE_PUBLISH_STATUS = 2;
export const DRAFT_PUBLISH_STATUS = 1;
export const STOPPED_PUBLISH_STATUS = 3;
export const TEST_MODE_PUBLISH_STATUS = 4;

export const multipleMusterFields = [
  'street_address',
  'street_address_2',
  'city',
  'state',
  'zip_code',
  'phone_number',
  'email',
];

export const fieldTypes = {
  multipleChoiceField: 'multiple_choice_field',
  singleChoiceField: 'single_choice_field',
  textField: 'text_field',
};

export const listTypes = {
  complexList: 'ComplexList',
  staticList: 'StaticList',
};

export const complexListTypes = {
  actionCenterList: 'ActionCenterList',
  advocateList: 'IsAdvocateList',
  fieldList: 'FieldList',
  geoList: 'GeoList',
  hasIdList: 'HasExternalIdList',
  signupsList: 'SignupSourceList',
  textableList: 'IsTextableList',
};

export const filtersTypes = {
  actionCenterFilterType: 'Advocates by Action Center',
  customFieldFilterType: 'Custom Field',
  firstNameFilterType: 'First Name',
  grassTopRelationshipFilterType: 'Grass Top Relationship',
  hasExternalIdFilterType: 'Has an External ID?',
  isAdvocateFilterType: 'Has Taken Action?',
  isTextableListFilterType: 'Can Receive SMS?',
  lastNameFilterType: 'Last Name',
  locationFilterType: 'Location',
  signupSourceListFilterType: 'Signup Source',
};

export const filterByCategories = [
  {
    name: filtersTypes.locationFilterType,
    type: 'Attribute',
  },
  {
    name: filtersTypes.grassTopRelationshipFilterType,
    type: 'Attribute',
  },
  {
    name: filtersTypes.hasExternalIdFilterType,
    type: 'Attribute',
  },
  {
    name: filtersTypes.signupSourceListFilterType,
    type: 'Attribute',
  },
  {
    name: filtersTypes.firstNameFilterType,
    type: 'Field',
  },
  {
    name: filtersTypes.lastNameFilterType,
    type: 'Field',
  },
  {
    name: filtersTypes.isTextableListFilterType,
    type: 'Text messaging',
  },
  {
    name: filtersTypes.isAdvocateFilterType,
    type: 'Advocacy',
  },
  {
    name: filtersTypes.actionCenterFilterType,
    type: 'Advocacy',
  },
];

export const positiveFilterConditions = {
  contactsWhoCanBeTexted: 'can be texted',
  contains: 'contains',
  hasARelationshipWith: 'has a relationship with',
  hasExternalId: 'has an external ID',
  haveActedInAnyOf: 'has acted in any of',
  haveTakenAction: 'has taken action',
  includesAll: 'includes all',
  includesAny: 'includes any',
  isInside: 'is inside',
  matches: 'matches',
};

export const negativeFilterConditions = {
  contactsWhoCannotBeTexted: 'cannot be texted',
  doesNotContain: 'does not contain',
  doesNotHaveARelationshipWith: 'does not have a relationship with',
  doesNotHaveExternalId: 'does not have an external ID',
  doesNotIncludeAll: 'does not include all',
  doesNotIncludeAny: 'does not include any',
  doesNotMatch: 'does not match',
  haveNotTakenAction: 'has not taken action',
  isOutside: 'is outside',
  notActedInAnyOf: 'not acted in any of',
};

export const filteredListConditions = [
  {
    attribute: filtersTypes.locationFilterType,
    conditions: [
      positiveFilterConditions.isInside,
      negativeFilterConditions.isOutside,
    ],
  },
  {
    attribute: filtersTypes.grassTopRelationshipFilterType,
    conditions: [
      positiveFilterConditions.hasARelationshipWith,
      negativeFilterConditions.doesNotHaveARelationshipWith,
    ],
  },
  {
    attribute: filtersTypes.firstNameFilterType,
    conditions: [
      positiveFilterConditions.matches,
      negativeFilterConditions.doesNotMatch,
      positiveFilterConditions.contains,
      negativeFilterConditions.doesNotContain,
    ],
  },
  {
    attribute: filtersTypes.lastNameFilterType,
    conditions: [
      positiveFilterConditions.matches,
      negativeFilterConditions.doesNotMatch,
      positiveFilterConditions.contains,
      negativeFilterConditions.doesNotContain,
    ],
  },
  {
    attribute: filtersTypes.hasExternalIdFilterType,
    conditions: [
      positiveFilterConditions.hasExternalId,
      negativeFilterConditions.doesNotHaveExternalId,
    ],
  },
  {
    attribute: filtersTypes.isTextableListFilterType,
    conditions: [
      positiveFilterConditions.contactsWhoCanBeTexted,
      negativeFilterConditions.contactsWhoCannotBeTexted,
    ],
  },
  {
    attribute: filtersTypes.isAdvocateFilterType,
    conditions: [
      positiveFilterConditions.haveTakenAction,
      negativeFilterConditions.haveNotTakenAction,
    ],
  },
  {
    attribute: filtersTypes.actionCenterFilterType,
    conditions: [
      positiveFilterConditions.haveActedInAnyOf,
      negativeFilterConditions.notActedInAnyOf,
    ],
  },
  {
    attribute: filtersTypes.signupSourceListFilterType,
    conditions: [
      positiveFilterConditions.includesAny,
      negativeFilterConditions.doesNotIncludeAny,
    ],
  },
];

export const cancellationStatuses = {
  cancelled: 'cancelled',
  notCancelled: 'not_cancelled',
  probablyCancelled: 'probably_cancelled',
};

export const statuses = {
  draft: 'D',
  published: 'P',
};

export const positionsByStates = [
  'United States Representative',
  'United States Senator',
];

export const broadcastTemplate1PathToOrgName = 'body.rows[4].columns[0].contents[0].values.text';
export const broadcastTemplate2PathToOrgData = 'body.rows[10].columns[0].contents[0].values.text';
export const template1PathToOrgName = 'body.rows[5].columns[0].contents[0].values.text';
export const template2PathToOrgData = 'body.rows[11].columns[0].contents[0].values.text';

export const broadcastTemplate1PathToSocials = 'body.rows[3].columns[0].contents[0].values.icons.icons';
export const broadcastTemplate2PathToSocials = 'body.rows[9].columns[0].contents[1].values.icons.icons';
export const template1PathToSocials = 'body.rows[4].columns[0].contents[0].values.icons.icons';
export const template2PathToSocials = 'body.rows[10].columns[0].contents[1].values.icons.icons';

export const subjectMessageEmptyPair = {
  body: [
    {
      isAllowEdit: true,
      name: '',
    },
  ],
  isAllowPersonalMessage: true,
  subject: {
    isAllowEdit: true,
    name: '',
  },
};

export const subjectMessageEmptyPetitionPair = {
  body: [
    {
      isAllowEdit: false,
      name: '',
    },
  ],
  isAllowPersonalMessage: false,
  subject: {
    isAllowEdit: false,
    name: '',
  },
};

export const actionTypes = {
  email: 'email',
  text: 'text',
};

export const unclusteredPointStyle = {
  filter: [
    '!',
    [
    'has',
    'point_count',
    ],
  ],
  id: 'unclustered-locations',
  paint: {
    'circle-color': '#11b4da',
    'circle-radius': 6,
    'circle-stroke-color': '#000',
    'circle-stroke-width': 2,
  },
  source: 'locations',
  type: 'circle',
};

export const clusteredPointsStyle = {
  filter: [
    'has',
    'point_count',
  ],
  id: 'clustered-locations',
  paint: {
    'circle-color': [
      'step',
      [
        'get',
        'point_count',
      ],
      '#008cff',
      10,
      '#ffc000',
      100,
      '#ff0100',
      1000,
      '#ff13f2',
      10000,
      '#b801ff',
    ],
    'circle-radius': [
      'step',
      [
        'get',
        'point_count',
      ],
      15,
      10,
      20,
      100,
      25,
      1000,
      40,
      10000,
      60,
    ],
    'circle-stroke-color': [
      'step',
      [
        'get',
        'point_count',
      ],
      '#008cff',
      10,
      '#ffc000',
      100,
      '#ff0100',
      1000,
      '#ff13f2',
      10000,
      '#b801ff',
    ],
    'circle-stroke-opacity': 0.2,
    'circle-stroke-width': 4,
  },
  source: 'locations',
  type: 'circle',
};

export const clusteredPointsTitleStyle = {
  filter: [
    'has',
    'point_count',
  ],
  id: 'cluster-count',
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': [
      'DIN Offc Pro Medium',
      'Arial Unicode MS Bold',
    ],
    'text-size': 12,
  },
  source: 'locations',
  type: 'symbol',
};

export const lookupTypes = {
  contains: 'contains',
  exact: 'exact',
  icontains: 'icontains',
  iexact: 'iexact',
  overlap: 'overlap',
};

export const nonAuthenticatedRoutes = {
  confirmPasswordResetRoute: '/confirm-password-reset',
  joinRoute: '/join',
  loginRoute: '/login',
  passwordResetRoute: '/password-reset',
  registerRoute: '/register',
};

// routes without a specific organization uuid
export const nonOrganizationRoutes = {
  ...nonAuthenticatedRoutes,
  accountRoute: '/account',
  createOrganizationRoute: '/create-organization',
  organizationsRoute: '/organizations',
};

export const messagesLevels = {
  debug: 'debug',
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
};

export const messagePairTypes = {
  messageMapping: 'mapping',
  messageRotation: 'rotation',
};

export const actionTakenMessageStatus = {
  approvedMessage: 'approved',
  flaggedMessage: 'flagged',
  pendingMessage: 'pending',
  quarantinedMessage: 'quarantined',
  rejectedMessage: 'rejected',
};

export const maxNonGeocodedTargets = 15;

export const globalEmailEditorOptions = {
  version: '1.5.75',
};
