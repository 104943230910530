import axios from 'axios';
import request from '../../utils/request';

const { REACT_APP_SMARTY_STREETS_API_KEY } = process.env;

const PREFIX = '[CAMPAIGNS_PUBLIC]';

export const FETCH_ACTION_CENTER_REQUEST = `${PREFIX} FETCH_ACTION_CENTER_REQUEST`;
export const FETCH_ACTION_CENTER_SUCCESS = `${PREFIX} FETCH_ACTION_CENTER_SUCCESS`;
export const FETCH_ACTION_CENTER_FAILURE = `${PREFIX} FETCH_ACTION_CENTER_FAILURE`;

const fetchActionCenterRequest = () => ({
  type: FETCH_ACTION_CENTER_REQUEST,
});

const fetchActionCenterSuccess = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_SUCCESS,
});

const fetchActionCenterFailure = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_FAILURE,
});

export const fetchActionCenter = uuid => dispatch => {
  dispatch(fetchActionCenterRequest());

  return request
    .get(`/public/act/${uuid}/`)
    .then(({ data }) => {
      dispatch(fetchActionCenterSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchActionCenterFailure(data));

      throw data;
    });
};

export const FETCH_CONTACT_INFO_REQUEST = `${PREFIX} FETCH_CONTACT_INFO_REQUEST`;
export const FETCH_CONTACT_INFO_SUCCESS = `${PREFIX} FETCH_CONTACT_INFO_SUCCESS`;
export const FETCH_CONTACT_INFO_FAILURE = `${PREFIX} FETCH_CONTACT_INFO_FAILURE`;

const fetchContactInfoRequest = () => ({
  type: FETCH_CONTACT_INFO_REQUEST,
});

const fetchContactInfoSuccess = payload => ({
  payload,
  type: FETCH_CONTACT_INFO_SUCCESS,
});

const fetchContactInfoFailure = payload => ({
  payload,
  type: FETCH_CONTACT_INFO_FAILURE,
});

export const fetchContactInfo = (uuid, contactUuid, token) => dispatch => {
  dispatch(fetchContactInfoRequest());

  return request
    .get(`/public/act/${uuid}/contact/`, {
      params: {
        token,
        uuid: contactUuid,
      },
    })
    .then(({ data }) => {
      dispatch(fetchContactInfoSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchContactInfoFailure(data));

      throw data;
    });
};

export const FETCH_MATCHED_TARGETS_REQUEST = `${PREFIX} FETCH_MATCHED_TARGETS_REQUEST`;
export const FETCH_MATCHED_TARGETS_SUCCESS = `${PREFIX} FETCH_MATCHED_TARGETS_SUCCESS`;
export const FETCH_MATCHED_TARGETS_FAILURE = `${PREFIX} FETCH_MATCHED_TARGETS_FAILURE`;

const fetchMatchedTargetsRequest = () => ({
  type: FETCH_MATCHED_TARGETS_REQUEST,
});

const fetchMatchedTargetsSuccess = payload => ({
  payload,
  type: FETCH_MATCHED_TARGETS_SUCCESS,
});

const fetchMatchedTargetsFailure = payload => ({
  payload,
  type: FETCH_MATCHED_TARGETS_FAILURE,
});

export const fetchMatchedTargets = (uuid, params) => dispatch => {
  dispatch(fetchMatchedTargetsRequest());

  return request
    .get(`/public/act/${uuid}/match_targets/`, { params })
    .then(({ data }) => {
      dispatch(fetchMatchedTargetsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchMatchedTargetsFailure(data));

      throw data;
    });
};

export const POST_SEND_MESSAGE_REQUEST = `${PREFIX} POST_SEND_MESSAGE_REQUEST`;
export const POST_SEND_MESSAGE_SUCCESS = `${PREFIX} POST_SEND_MESSAGE_SUCCESS`;
export const POST_SEND_MESSAGE_FAILURE = `${PREFIX} POST_SEND_MESSAGE_FAILURE`;

const postSendMessageRequest = () => ({
  type: POST_SEND_MESSAGE_REQUEST,
});

const postSendMessageSuccess = payload => ({
  payload,
  type: POST_SEND_MESSAGE_SUCCESS,
});

const postSendMessageFailure = payload => ({
  payload,
  type: POST_SEND_MESSAGE_FAILURE,
});

export const postSendMessage = (uuid, params) => dispatch => {
  dispatch(postSendMessageRequest());

  return request
    .post(`/public/act/${uuid}/send_message/`, params)
    .then(({ data }) => {
      dispatch(postSendMessageSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postSendMessageFailure(data));

      throw data;
    });
};

export const FETCH_ADDRESS_SUGGESTIONS_REQUEST = `${PREFIX} FETCH_ADDRESS_SUGGESTIONS_REQUEST`;
export const FETCH_ADDRESS_SUGGESTIONS_SUCCESS = `${PREFIX} FETCH_ADDRESS_SUGGESTIONS_SUCCESS`;
export const FETCH_ADDRESS_SUGGESTIONS_FAILURE = `${PREFIX} FETCH_ADDRESS_SUGGESTIONS_FAILURE`;

const fetchAddressSuggestionsRequest = () => ({
  type: FETCH_ADDRESS_SUGGESTIONS_REQUEST,
});

const fetchAddressSuggestionsSuccess = payload => ({
  payload,
  type: FETCH_ADDRESS_SUGGESTIONS_SUCCESS,
});

const fetchAddressSuggestionsFailure = payload => ({
  payload,
  type: FETCH_ADDRESS_SUGGESTIONS_FAILURE,
});

export const fetchAddressSuggestions = (search, params = {}) => dispatch => {
  if (!search) {
    return dispatch(fetchAddressSuggestionsSuccess([]));
  }

  dispatch(fetchAddressSuggestionsRequest());

  return axios
    .get(
      `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${REACT_APP_SMARTY_STREETS_API_KEY}`,
      {
        params: {
          search,
          ...params,
        },
      }
    )
    .then(({ data }) => {
      dispatch(fetchAddressSuggestionsSuccess(data?.suggestions));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchAddressSuggestionsFailure(data));

      throw data;
    });
};

export const DELETE_MATCHED_TARGETS = `${PREFIX} DELETE_MATCHED_TARGETS`;

export const deleteMatchedTargets = payload => ({
  payload,
  type: DELETE_MATCHED_TARGETS,
});
