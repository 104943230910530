import request from '../../utils/request';

const PREFIX = '[STATISTICS]';

export const FETCH_ACTION_CENTER_STATISTICS_REQUEST = `${PREFIX} FETCH_ACTION_CENTER_STATISTICS_REQUEST`;
export const FETCH_ACTION_CENTER_STATISTICS_SUCCESS = `${PREFIX} FETCH_ACTION_CENTER_STATISTICS_SUCCESS`;
export const FETCH_ACTION_CENTER_STATISTICS_FAILURE = `${PREFIX} FETCH_ACTION_CENTER_STATISTICS_FAILURE`;

const fetchActionCenterStatisticsRequest = () => ({
  type: FETCH_ACTION_CENTER_STATISTICS_REQUEST,
});

const fetchActionCenterStatisticsSuccess = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_STATISTICS_SUCCESS,
});

const fetchActionCenterStatisticsFailure = payload => ({
  payload,
  type: FETCH_ACTION_CENTER_STATISTICS_FAILURE,
});

export const fetchActionCenterStatistics = (actionCenterUuid, params = '') => dispatch => {
  dispatch(fetchActionCenterStatisticsRequest());

  return request
    .get(`/v1/campaigns/action_centers/${actionCenterUuid}/statistics/?${params}`)
    .then(({ data }) => {
      dispatch(fetchActionCenterStatisticsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchActionCenterStatisticsFailure(data));

      throw data;
    });
};

export const FETCH_CAMPAIGN_STATISTICS_REQUEST = `${PREFIX} FETCH_CAMPAIGN_STATISTICS_REQUEST`;
export const FETCH_CAMPAIGN_STATISTICS_SUCCESS = `${PREFIX} FETCH_CAMPAIGN_STATISTICS_SUCCESS`;
export const FETCH_CAMPAIGN_STATISTICS_FAILURE = `${PREFIX} FETCH_CAMPAIGN_STATISTICS_FAILURE`;

const fetchCampaignStatisticsRequest = () => ({
  type: FETCH_CAMPAIGN_STATISTICS_REQUEST,
});

const fetchCampaignStatisticsSuccess = payload => ({
  payload,
  type: FETCH_CAMPAIGN_STATISTICS_SUCCESS,
});

const fetchCampaignStatisticsFailure = payload => ({
  payload,
  type: FETCH_CAMPAIGN_STATISTICS_FAILURE,
});

export const fetchCampaignStatistics = (campaignUuid, params = '') => dispatch => {
  dispatch(fetchCampaignStatisticsRequest());

  return request
    .get(`/v1/campaigns/campaigns/${campaignUuid}/statistics/?${params}`)
    .then(({ data }) => {
      dispatch(fetchCampaignStatisticsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchCampaignStatisticsFailure(data));

      throw data;
    });
};
