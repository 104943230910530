import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const CancelDialog = ({
  isDialogOpen,
  redirectUrl,
  setIsCancelDialogOpen,
}) => {
  const history = useHistory();

  const handleLeavePage = () => {
    setIsCancelDialogOpen(false);
    history.push(redirectUrl);
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      open={isDialogOpen}
    >
      <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to leave this page?
          Changes on this page will not be saved.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => setIsCancelDialogOpen(false)}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleLeavePage}
        >
          Leave this page
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CancelDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  setIsCancelDialogOpen: PropTypes.func.isRequired,
};

export default CancelDialog;
