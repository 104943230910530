import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Card,
  Typography,
} from '@mui/material';

import { colors } from 'utils/constants';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 200px;
  background-color: ${props => (props.disabled ? colors.mercury : colors.white)};

  &:hover {
    background-color: ${colors.mercury};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

const OrganizationDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

const OrganizationTitle = styled(Typography)`
  margin-top: 10px;
`;

const OrganizationCard = ({
  onCardClick,
  organization,
}) => {
  const onClick = () => {
    if (organization.uuid) {
      onCardClick(organization);
    }
  };

  return (
    <StyledCard
      onClick={onClick}
      variant="outlined"
      disabled={!organization.uuid}
    >
      <OrganizationTitle
        align="center"
        gutterBottom
        variant="h5"
        component="h2"
      >
        {organization.name}
      </OrganizationTitle>
      <OrganizationDescription>
        <Typography
          color="textSecondary"
          gutterBottom
          variant="body2"
        >
          {organization.websiteUrl}
        </Typography>
        <Typography
          color="textSecondary"
          gutterBottom
          variant="body2"
        >
          {organization.city}
        </Typography>
        <Typography
          color="textSecondary"
          gutterBottom
          variant="body2"
        >
          {organization.state}
        </Typography>
      </OrganizationDescription>
    </StyledCard>
  );
};

OrganizationCard.propTypes = {
  onCardClick: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    city: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.string,
    uuid: PropTypes.string,
    websiteUrl: PropTypes.string,
  }).isRequired,
};

export default OrganizationCard;
