import {
  useRef,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { set } from 'lodash';
import EmailEditor from 'react-email-editor';

import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  Add as AddIcon,
} from '@mui/icons-material';

import {
  fetchAllTemplates,
  fetchTemplate,
  patchTemplate,
  postTemplate,
} from 'store/actions/templates';
import { templatesSelectors } from 'store/selectors/templates';
import { organizationSelectors } from 'store/selectors/organizations';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';

import {
  Backdrop,
  ButtonsColorsWrapper,
  CancelCreatorButton,
  CardField,
  Container,
  DescriptionBar,
  Dialog,
} from 'common/components';
import {
  colors,
  globalEmailEditorOptions,
} from 'utils/constants';
import {
  findPaths,
  handleFillInBroadcastTemplate1,
  handleFillInBroadcastTemplate2,
  handleFillInTemplate1,
  handleFillInTemplate2,
  scrollToTop,
  showFirstErrorMessage,
} from 'utils/helpers';
import broadcastTemplate1 from 'utils/broadcastTemplate1.json';
import broadcastTemplate2 from 'utils/broadcastTemplate2.json';
import actionAlertTemplate1 from 'utils/template1.json';
import actionAlertTemplate2 from 'utils/template2.json';

const CardFieldWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const StyledGrid = styled(Grid)`
  flex: 0 0 100%;
`;

const ButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90px;
  margin-top: 50px;
  background-color: ${colors.white};
`;

const ButtonCreateWrapper = styled.div`
  position: relative;
  padding-left: 10px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const StyledSelect = styled(Select)`
  min-width: 200px;
  margin: 20px 0;
`;

const CreateTemplate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    organizationUuid,
    templateUuid,
    type,
  } = useParams();
  const emailEditorRef = useRef(null);

  const {
    isPending,
    organization,
  } = useSelector(state => ({
    isPending: templatesSelectors.isPending(state),
    organization: organizationSelectors.getOrganization(state),
  }));

  const [
    template,
    setTemplate,
  ] = useState({});
  const [
    templateName,
    setTemplateName,
  ] = useState('');
  const [
    errorMessage,
    setErrorMessage,
  ] = useState(false);
  const [
    isDialogOpen,
    setIsDialogOpen,
  ] = useState(false);
  const [
    templatesList,
    setTemplatesList,
  ] = useState([]);
  const [
    isLoading,
    setIsLoading,
  ] = useState(true);
  const [
    buttonBackgroundColor,
    setButtonBackgroundColor,
  ] = useState(colors.black);
  const [
    buttonTextColor,
    setButtonTextColor,
  ] = useState(colors.white);

  const onLoad = async () => {
    let templateBody = {};

    if (templateUuid) {
      const templateData = await dispatch(fetchTemplate(templateUuid));

      templateBody = templateData.body;
      setTemplate(templateData);
      setTemplateName(templateData.name);
    } else {
      const allTemplates = await dispatch(fetchAllTemplates({
          organization: organizationUuid,
          type,
        }));

      const template1 = type === 'broadcast' ? JSON.parse(JSON.stringify(broadcastTemplate1)) : JSON.parse(JSON.stringify(actionAlertTemplate1));
      const template2 = type === 'broadcast' ? JSON.parse(JSON.stringify(broadcastTemplate2)) : JSON.parse(JSON.stringify(actionAlertTemplate2));

      if (type === 'broadcast') {
        handleFillInBroadcastTemplate1(template1, organization);
        handleFillInBroadcastTemplate2(template2, organization);
      } else {
        handleFillInTemplate1(template1, organization);
        handleFillInTemplate2(template2, organization);
      }
      templateBody = template1;
      setTemplatesList([
        {
          body: template1,
          name: 'template1',
        },
        {
          body: template2,
          name: 'template2',
        },
        ...allTemplates,
      ]);
      setTemplate({
        body: templateBody,
        name: 'template1',
      });
    }

    if (emailEditorRef.current?.editor) {
      emailEditorRef.current.editor.loadDesign(templateBody);
    }
    setIsLoading(false);
  };

  const handleSave = () => {
    emailEditorRef.current.editor.exportHtml(async data => {
      const {
        design,
        html,
      } = data;
      const params = {
        body: design,
        message: html,
        name: templateName,
        organization: organizationUuid,
        type,
      };

      try {
        await dispatch(templateUuid ? patchTemplate(templateUuid, params) : postTemplate(params));

        dispatch(setSnackbarSuccess({ message: 'Custom Template Saved' }));
        history.push(`/${organizationUuid}/templates/${type}`);
      } catch (error) {
        setIsDialogOpen(false);

        if (error) {
          scrollToTop();
          setErrorMessage(error);
        }

        if (error && !error.name) {
          dispatch(setSnackbarError({
            message: showFirstErrorMessage(error),
          }));
        }
      }
    });
  };

  const handleChangeTemplateButton = (buttonType, value) => {
    emailEditorRef.current.editor.exportHtml(async data => {
      const { design } = data;

      const [pathToHref] = findPaths(design, '--{LANDING_PAGE}--');

      if (buttonType === 'background') {
        const pathToBackground = `${pathToHref.slice(0, -16)}buttonColors.backgroundColor`;

        set(design, pathToBackground, value);

        setButtonBackgroundColor(value);
      }

      if (buttonType === 'text') {
        const pathToText = `${pathToHref.slice(0, -16)}buttonColors.color`;

        set(design, pathToText, value);

        setButtonTextColor(value);
      }

      emailEditorRef.current.editor.loadDesign(design);
    });
  };

  const handleChangeTemplate = value => {
    const newTemplate = templatesList.find(templateItem => templateItem.name === value);

    emailEditorRef.current.editor.loadDesign(newTemplate.body);
    setTemplate(newTemplate);
  };

  return (
    <Container>
      <Grid container>
        <DescriptionBar
          name={templateUuid ? 'Edit Template' : 'Create Template'}
          icon={AddIcon}
        >
          <CancelCreatorButton redirectUrl={
            templateUuid ?
              `/${organizationUuid}/${templateUuid}/templates` :
              `/${organizationUuid}/templates/${type}`
            }
          />
        </DescriptionBar>
      </Grid>
      <Grid container>
        <CardFieldWrapper>
          <CardField
            flexwrap
            name="Template Name"
            required
          >
            <StyledGrid>
              <TextField
                fullWidth
                label="Template Name"
                onChange={event => {
                  setTemplateName(event.target.value);
                  setErrorMessage(null);
                }}
                error={!!errorMessage?.name && !!errorMessage?.name[0]}
                helperText={errorMessage?.name && errorMessage?.name[0]}
                required
                type="text"
                value={templateName}
              />
            </StyledGrid>
          </CardField>
        </CardFieldWrapper>
        <CardFieldWrapper>
          <CardField
            column
            name="Template"
          >
            {!!Object.keys(organization).length && (
              <>
                {!templateUuid && (
                  <StyledSelect
                    labelId="template-select"
                    onChange={event => handleChangeTemplate(event.target.value)}
                    value={template.name || ''}
                  >
                    {templatesList.map(({ name }) => (
                      <MenuItem
                        key={name}
                        value={name}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
                <ButtonsWrapper>
                  {type === 'action_alert' && (
                    <ButtonsColorsWrapper
                      buttonBackgroundColor={buttonBackgroundColor}
                      buttonTextColor={buttonTextColor}
                      handleChangeTemplateButton={handleChangeTemplateButton}
                    />
                  )}
                </ButtonsWrapper>
                <EmailEditor
                  onLoad={onLoad}
                  ref={emailEditorRef}
                  options={globalEmailEditorOptions}
                />
              </>
            )}
          </CardField>
        </CardFieldWrapper>
        <StepperWrapper>
          <Grid
            container
            direction="row"
            item
            justifyContent="flex-end"
          >
            <div>
              <ButtonCreateWrapper>
                <Button
                  color="secondary"
                  disabled={isPending || !templateName}
                  onClick={() => setIsDialogOpen(true)}
                  variant="contained"
                  type="button"
                >
                  {`${templateUuid ? 'Update' : 'Save'} Template`}
                </Button>
                {isPending && <ButtonCircularProgress size={24} />}
              </ButtonCreateWrapper>
            </div>
          </Grid>
        </StepperWrapper>
      </Grid>
      <Dialog
        handleConfirm={handleSave}
        handleDialogClose={() => setIsDialogOpen(false)}
        isDialogOpen={isDialogOpen}
        title={`Do you really want to ${templateUuid ? 'Update' : 'Save'} Template?`}
        disabledButton={isPending}
      >
        <span>
          {templateUuid ? `Are you sure you want to update "${template.name}" as "${templateName}"?` : `Do you want to save new custom template as "${templateName}"`}
        </span>
      </Dialog>
      <Backdrop isOpen={isLoading} />
    </Container>
  );
};

export default CreateTemplate;
