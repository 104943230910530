import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Field,
  Form,
} from 'react-final-form';

import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import {
  fetchApiKeys,
  postApiKey,
} from 'store/actions/organizations';
import { organizationSelectors } from 'store/selectors/organizations';

import { useQuery } from 'utils/helpers';
import { useCopyToClipboard } from 'utils/hooks';
import {
  TabPanel,
  ZapierComponent,
} from 'common/components';
import {
  SettingsWrapper,
} from 'globalStyles';
import ApiKeysTable from '../ApiKeysTable';

const StyledButton = styled(Button)`
  margin-bottom: 40px;
`;

const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;

const StyledDivider = styled(Divider)`
  display: block;
  margin-bottom: 20px;
`;

const StyledField = styled(Field)`
  width: 50%;
  padding-top: 20px;
`;

const ZapierKeyWrapper = styled.div`
  display: flex;
  gap: 32px;
  max-width: 534px;
  margin: 12px auto;
  /* stylelint-disable-next-line color-hex-case */
  border: 1px solid #B2BABF;
  border-radius: 8px;
  padding: 24px;
`;

const ZapierComponentWrapper = styled.div`
  margin-top: 20px;
`;

const ZapierKeyValue = styled(Typography)`
  margin-bottom: 40px;
  font-weight: 500;
`;

const ZapierButton = styled(Button)`
  margin: 0 auto;
`;

const TabsWrapper = styled.div`
  display: inline-flex;
  gap: 14px;
  margin-bottom: 64px;
  border-radius: 8px;
  padding: 8px 12px;
  background-color: ${({ theme }) => (theme.colors.lightGreenBackground)};
`;

const TabButton = styled(Button)`
  padding: 8px 30px;
  background-color: ${(
    {
      $isPicked,
      theme,
    }
  ) => ($isPicked ? theme.colors.secondary : theme.colors.lightGreenBackground)};
  color: ${(
    {
      $isPicked,
      theme,
    }
  ) => ($isPicked ? theme.colors.white : theme.colors.secondary)};
`;

const IntegrationsTab = ({ flags }) => {
  const { organizationUuid } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const tabIndex = query.get('integration');
  const [copy] = useCopyToClipboard();
  const [
    oneTimeVisibleKey,
    setOneTimeVisibleKey,
  ] = useState('');

  const {
    apiKeysData,
  } = useSelector(state => ({
    apiKeysData: organizationSelectors.getApiKeysDetails(state),
  }));

  useEffect(() => {
    dispatch(fetchApiKeys(organizationUuid, `scope=${tabIndex}`));
  }, [tabIndex]);

  const handleCreateNewApiKey = async values => {
    try {
      const apiKeyData = await dispatch(postApiKey(organizationUuid, values));

      setOneTimeVisibleKey(apiKeyData.key);
      await dispatch(fetchApiKeys(organizationUuid));

      return 'success';
    } catch (error) {
      return error;
    }
  };

  const handleCopyToClipboard = () => {
    copy(oneTimeVisibleKey);
  };

  const handleSetTab = newValue => {
    history.push({
      search: `?tab=integrations&integration=${newValue}`,
    });
  };

  const integrationsTabs = [
    {
      disabled: flags ?
        !flags.showSalesforce :
        true,
      label: 'Salesforce',
      value: 'salesforce',
    },
    {
      disabled: flags ?
        !flags.showApi :
        true,
      label: 'Zapier',
      value: 'zapier',
    },
  ];

  const oneTimeWrapper = () => (
    <ZapierKeyWrapper>
      <div>
        <img
          alt="secure_icon"
          src="/secure_icon.svg"
        />
      </div>
      <div>
        <Typography variant="h5">
          Important!
        </Typography>
        <Typography
          variant="body1"
          color="grey"
        >
          This key is displayed only once. Please download or copy it now,
          as you won&apos;t be able to access it after moving to the next page.
        </Typography>
        <Typography
          variant="body1"
          color="grey"
          sx={{
            marginTop: '16px',
          }}
        >
          Created key:
        </Typography>
        <ZapierKeyValue>{oneTimeVisibleKey}</ZapierKeyValue>
        <ZapierButton
          variant="contained"
          onClick={handleCopyToClipboard}
        >
          Copy the key
        </ZapierButton>
      </div>
    </ZapierKeyWrapper>
  );

  return (
    <SettingsWrapper>
      <StyledGrid>
        <TabsWrapper>
          <TabButton
            $isPicked={tabIndex === integrationsTabs[0].value}
            disableElevation
            onClick={() => handleSetTab(integrationsTabs[0].value)}
            color="secondary"
            variant="contained"
          >
            Salesforce
          </TabButton>
          <TabButton
            $isPicked={tabIndex === integrationsTabs[1].value}
            disableElevation
            onClick={() => handleSetTab(integrationsTabs[1].value)}
            color="secondary"
            variant="contained"
          >
            Zapier
          </TabButton>
        </TabsWrapper>
        <TabPanel
          index={integrationsTabs[0].value}
          value={tabIndex || false}
        >
          <Typography
            variant="h6"
            gutterBottom
          >
            Create a new Key
          </Typography>
          <Form
            onSubmit={handleCreateNewApiKey}
            initialValues={{ scope: 'salesforce' }}
            render={({
              handleSubmit,
              pristine,
              submitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <StyledField name="name">
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          error={Boolean(meta.touched && (meta.error || meta.submitError))}
                          fullWidth
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          label="Key name"
                          type="text"
                        />
                      )}
                    </StyledField>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <StyledButton
                      color="primary"
                      disabled={submitting || pristine}
                      disableElevation
                      type="submit"
                      variant="contained"
                    >
                      Create
                    </StyledButton>
                  </Grid>
                </Grid>
              </form>
            )}
          />
          {oneTimeVisibleKey && (
            oneTimeWrapper()
          )}
          <StyledDivider />
          <Typography
            variant="h6"
            gutterBottom
          >
            API Keys
          </Typography>
          <ApiKeysTable
            apiKeysData={apiKeysData}
            organizationUuid={organizationUuid}
          />
        </TabPanel>
        <TabPanel
          index={integrationsTabs[1].value}
          value={tabIndex || false}
        >
          <Typography
            variant="h6"
            gutterBottom
          >
            Create a new Key
          </Typography>
          <Form
            onSubmit={handleCreateNewApiKey}
            initialValues={{ scope: 'zapier' }}
            render={({
              handleSubmit,
              pristine,
              submitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <StyledField name="name">
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          error={Boolean(meta.touched && (meta.error || meta.submitError))}
                          fullWidth
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          label="Key name"
                          type="text"
                        />
                      )}
                    </StyledField>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <StyledButton
                      color="secondary"
                      disabled={submitting || pristine}
                      disableElevation
                      type="submit"
                      variant="contained"
                    >
                      Create
                    </StyledButton>
                  </Grid>
                </Grid>
              </form>
            )}
          />
          {oneTimeVisibleKey && (
            oneTimeWrapper()
          )}
          <StyledDivider />
          <Typography
            variant="h6"
            gutterBottom
          >
            Zapier Keys
          </Typography>
          <ApiKeysTable
            apiKeysData={apiKeysData}
            organizationUuid={organizationUuid}
          />
          <ZapierComponentWrapper>
            <Typography
              variant="h6"
              gutterBottom
            >
              Edit and Add Zaps
            </Typography>
            <StyledDivider />
            <ZapierComponent />
          </ZapierComponentWrapper>
        </TabPanel>
      </StyledGrid>
    </SettingsWrapper>
  );
};

IntegrationsTab.defaultProps = {
  flags: null,
};

IntegrationsTab.propTypes = {
  flags: PropTypes.shape({
    showApi: PropTypes.bool,
    showSalesforce: PropTypes.bool,
  }),
};

export default IntegrationsTab;
