import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

import {
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import { FilterList as FilterListIcon } from '@mui/icons-material';

import {
  clearBroadcast,
  fetchBroadcast,
  patchBroadcast,
  postBroadcast,
  postSendBroadcast,
  postSendPreviewBroadcast,
} from 'store/actions/broadcasts';
import {
  fetchAllTemplates,
  postTemplate,
} from 'store/actions/templates';
import {
  fetchChildrenLists,
  fetchRootList,
} from 'store/actions/contacts';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';
import { broadcastsSelectors } from 'store/selectors/broadcasts';
import { contactSelectors } from 'store/selectors/contacts';
import { organizationSelectors } from 'store/selectors/organizations';
import { fetchEmailDomains } from 'store/actions/organizations';

import {
  Backdrop,
  CancelCreatorButton,
  CardField,
  Container,
  DescriptionBar,
  Dialog,
  Stepper,
  SuccessDialog,
} from 'common/components';
import {
  colors,
} from 'utils/constants';
import {
  formatNumber,
  handleFillInBroadcastTemplate1,
  handleFillInBroadcastTemplate2,
  handleMergeCorrectTime,
  isEmail,
  isGeneralError,
  renderErrorMessage,
  scrollToTop,
  showFirstErrorMessage,
} from 'utils/helpers';
import broadcastTemplate1 from 'utils/broadcastTemplate1.json';
import broadcastTemplate2 from 'utils/broadcastTemplate2.json';
import {
  ErrorTypography,
  StepOneContainer,
  StepThreeContainer,
  StepTwoContainer,
} from 'globalStyles';

import StepOne from 'routes/CreateEmailBroadcast/components/StepOne';
import StepTwo from 'routes/CreateEmailBroadcast/components/StepTwo';
import StepThree from 'routes/CreateEmailBroadcast/components/StepThree';
import {
  initialTimezone,
  timezones,
} from 'utils/timezones';

const { REACT_APP_MOUSEFLOW } = process.env;

const ButtonCreateWrapper = styled.div`
  position: relative;
`;

const ButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const NextButtonWrapper = styled.div`
  padding-left: 10px;
`;

const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90px;
  margin-top: 50px;
  background-color: ${colors.white};
`;

const CreateEmailBroadcast = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    duplicatedUuid,
    editedBroadcastUuid,
    organizationUuid,
  } = useParams();
  const {
    broadcastData,
    childrenLists,
    emailDomainData,
    isBroadcastFetching,
    isPending,
    organization,
    rootList,
  } = useSelector(state => ({
    broadcastData: broadcastsSelectors.getEditingBroadcastDetails(state),
    childrenLists: contactSelectors.getChildrenLists(state)[organizationUuid] || [],
    emailDomainData: organizationSelectors.getEmailDomainData(state),
    isBroadcastFetching: broadcastsSelectors.isBroadcastFetching(state),
    isPending: broadcastsSelectors.isPending(state),
    organization: organizationSelectors.getOrganization(state),
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  const emailEditorRef = useRef(null);
  const listsOptions = useMemo(() => [
    rootList,
    ...childrenLists,
  ], [
    rootList,
    childrenLists,
  ]);

  const [
    templatesList,
    setTemplatesList,
  ] = useState([]);

  useEffect(() => {
    if (Object.keys(rootList).length === 0 || childrenLists.length === 0) {
      dispatch(fetchRootList(organizationUuid))
        .then(data => dispatch(fetchChildrenLists(data.uuid, organizationUuid)));
    }

    dispatch(fetchEmailDomains(organizationUuid));
  }, [
    dispatch,
    organizationUuid,
  ]);

  useEffect(() => {
    if (REACT_APP_MOUSEFLOW) {
      // eslint-disable-next-line no-underscore-dangle
      window._mfq = window._mfq || [];

      const mf = document.createElement('script');

      mf.type = 'text/javascript';
      mf.defer = true;
      mf.src = `//cdn.mouseflow.com/projects/${REACT_APP_MOUSEFLOW}.js`;
      document.getElementsByTagName('head')[0].appendChild(mf);
    }
  }, []);

  const [
    selectedDate,
    setSelectedDate,
  ] = useState(dayjs());
  const [
    selectedTime,
    setSelectedTime,
  ] = useState(dayjs());
  const [
    previewEmails,
    setPreviewEmails,
  ] = useState('');
  const [
    previewEmailsError,
    setPreviewEmailsError,
  ] = useState(null);
  const [
    template,
    setTemplate,
  ] = useState({});
  const [
    emailError,
    setEmailError,
  ] = useState(false);
  const [
    countRecipients,
    setCountRecipients,
  ] = useState(0);
  const [
    step,
    setStep,
  ] = useState(0);
  const [
    errorMessage,
    setErrorMessage,
  ] = useState(null);
  const [
    isScheduled,
    setIsScheduled,
  ] = useState(false);
  const [
    isSuccessDialogOpen,
    setIsSuccessDialogOpen,
  ] = useState(false);
  const [
    selectedTimeZone,
    setSelectedTimeZone,
  ] = useState(initialTimezone(organization.timezone));
  const [
    formValues,
    setFormValues,
  ] = useState({
    listsValue: [],
    nameValue: '',
    senderEmailValue: '',
    senderNameValue: '',
    subjectValue: '',
  });
  const [
    isTemplateNameModalOpen,
    setIsTemplateNameModalOpen,
  ] = useState(false);
  const [
    templateNameValue,
    setTemplateNameValue,
  ] = useState('');
  const [
    dialogError,
    setDialogError,
  ] = useState('');
  const [
    isLoading,
    setIsLoading,
  ] = useState(true);

  const patchBroadcastByHtml = () => {
    emailEditorRef.current.editor.exportHtml(async data => {
      const {
        design,
        html,
      } = data;

      await dispatch(patchBroadcast(broadcastData.uuid, {
        config: design,
        message: html,
      }));

      setStep(prevStep => prevStep + 1);
    });
  };

  const postNewBroadcast = async () => {
    const params = {
      contactLists: formValues.listsValue?.map(list => list.uuid),
      fromEmail: formValues.senderEmailValue,
      fromName: formValues.senderNameValue,
      name: formValues.nameValue,
      organization: organizationUuid,
      subject: formValues.subjectValue,
    };

    try {
      if (broadcastData?.uuid || editedBroadcastUuid) {
        const response = await dispatch(
          patchBroadcast(broadcastData?.uuid || editedBroadcastUuid, params)
        );

        setCountRecipients(response?.countRecipients);

        setErrorMessage(null);
        setStep(prevStep => prevStep + 1);
      } else {
        const response = await dispatch(postBroadcast(params));

        setCountRecipients(response?.countRecipients);

        setErrorMessage(null);
        setStep(prevStep => prevStep + 1);
      }
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const sendBroadacst = async () => {
    const params = {};

    if (isScheduled) {
      params.sendTime = handleMergeCorrectTime(selectedDate, selectedTime);
      params.sendAtTimezone = selectedTimeZone;
    }

    try {
      await dispatch(postSendBroadcast(broadcastData.uuid, params));

      setIsSuccessDialogOpen(true);
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const handleSendPreview = async () => {
    const contactLists = previewEmails.split(', ');

    try {
      await dispatch(postSendPreviewBroadcast(broadcastData.uuid, { emails: contactLists }));

      dispatch(setSnackbarSuccess({ message: 'An email will be sent to the provided address.' }));
      setPreviewEmailsError(null);
    } catch (error) {
      dispatch(setSnackbarError());
      setPreviewEmailsError(error);
    }
  };

  const onEditorLoad = async () => {
    if (Object.keys(organization).length !== 0) {
      dispatch(clearBroadcast());
      let templateData = {};
      let hasNoTemplate = true;

      const template1 = JSON.parse(JSON.stringify(broadcastTemplate1));
      const template2 = JSON.parse(JSON.stringify(broadcastTemplate2));

        handleFillInBroadcastTemplate1(template1, organization);
        handleFillInBroadcastTemplate2(template2, organization);

        const allTemplates = await dispatch(fetchAllTemplates({
          organization: organizationUuid,
          type: 'broadcast',
        }));

      setTemplatesList([
        {
          body: template1,
          name: 'template1',
        },
        {
          body: template2,
          name: 'template2',
        },
        ...allTemplates,
      ]);

      if (duplicatedUuid || editedBroadcastUuid) {
        const {
          contactLists,
          config,
          fromEmail,
          fromName,
          subject,
          name,
        } = await dispatch(fetchBroadcast(duplicatedUuid || editedBroadcastUuid));

        setFormValues(prevValues => ({
          ...prevValues,
          listsValue: contactLists,
          nameValue: name,
          senderEmailValue: fromEmail,
          senderNameValue: fromName || '',
          subjectValue: subject,
        }));

        hasNoTemplate = Object.keys(config).length === 0;

        if (hasNoTemplate) {
          templateData = {
            body: template1,
            name: 'template1',
          };
        } else {
          templateData = {
            body: config,
            name: '',
          };
        }
      } else {
        templateData = {
          body: template1,
          name: 'template1',
        };
      }

      setTemplate(templateData);

      if (emailEditorRef.current?.editor) {
        emailEditorRef.current.editor.loadDesign(templateData.body);
      }
      setIsLoading(false);
    }
  };

  const steps = [
    'Setup',
    'Design',
    'Preview/Send',
  ];

  const handleChangeTemplate = value => {
    const newTemplate = templatesList.find(templateItem => templateItem.name === value);

    emailEditorRef.current.editor.loadDesign(newTemplate.body);
    setTemplate(newTemplate);
  };

  const handleSaveAsTemplate = () => {
    emailEditorRef.current.editor.exportHtml(async data => {
      const {
        design,
        html,
      } = data;
      const params = {
        body: design,
        message: html,
        name: templateNameValue,
        organization: organizationUuid,
        type: 'broadcast',
      };

      try {
        const newTemplate = await dispatch(postTemplate(params));

        setTemplatesList(prevValues => [
          ...prevValues,
          newTemplate,
        ]);

        setIsTemplateNameModalOpen(false);
        setTemplate(newTemplate);
        setTemplateNameValue('');
      } catch (error) {
        setDialogError(error);
      }
    });
  };

  const disableNextButton = () => {
    if (step === 0) {
      return !(formValues.nameValue && formValues.senderNameValue &&
         formValues.senderEmailValue && formValues.subjectValue && formValues.listsValue);
    }

    if (step === 1) {
      return false;
    }

    if (step === 2 && isScheduled) {
      return !(selectedTime && selectedDate);
    }

    if (isPending) return true;

    return false;
  };

  const handleSave = () => {
    if (step === 0) {
      if (!isEmail(formValues.senderEmailValue)) {
        setEmailError(true);
      } else {
        postNewBroadcast();
      }
    } else if (step === 1) {
      patchBroadcastByHtml();
    } else if (step === 2) {
      sendBroadacst();
    }
  };

  const handleCreateNew = () => {
    setIsSuccessDialogOpen(false);
    dispatch(clearBroadcast());
    scrollToTop();
    setStep(0);
    setFormValues({
      listsValue: [],
      nameValue: '',
      senderEmailValue: '',
      senderNameValue: '',
      subjectValue: '',
    });

    emailEditorRef.current.editor.loadDesign(templatesList[0].body);
    setTemplate(templatesList[0]);

    history.push({
      pathname: `/${organizationUuid}/create-email-broadcast`,
    });
  };

  const handleGoBack = () => {
    setStep(prevStep => prevStep - 1);
    scrollToTop();
  };

  const pageName = () => {
    if (duplicatedUuid) {
      return 'Duplicate';
    }

    if (editedBroadcastUuid) {
      return 'Edit';
    }

    return 'Create New';
  };

  return (
    <>
      <Container>
        <Grid container>
          <DescriptionBar
            icon={FilterListIcon}
            name={`${pageName()} Email Broadcast`}
          >
            <CancelCreatorButton redirectUrl={
              (duplicatedUuid || editedBroadcastUuid) ?
                `/${organizationUuid}/broadcasts/${duplicatedUuid || editedBroadcastUuid}` :
                `/${organizationUuid}/broadcasts/`
              }
            />
          </DescriptionBar>
        </Grid>
        <Stepper
          step={step}
          steps={steps}
          neutralcolors
        />
        {errorMessage && isGeneralError(errorMessage, ['subject']) && (
          <CardField name="Error">
            <ErrorTypography>
              {renderErrorMessage(errorMessage)}
            </ErrorTypography>
          </CardField>
        )}
        <StepOneContainer step={step}>
          <StepOne
            emailDomainData={emailDomainData?.[0]}
            emailError={emailError}
            errorMessage={errorMessage}
            lists={listsOptions}
            listsValue={formValues.listsValue}
            nameValue={formValues.nameValue}
            senderEmailValue={formValues.senderEmailValue}
            senderNameValue={formValues.senderNameValue}
            setEmailError={setEmailError}
            setFormValues={setFormValues}
            subjectValue={formValues.subjectValue}
          />
        </StepOneContainer>
        <StepTwoContainer step={step}>
          <StepTwo
            emailEditorRef={emailEditorRef}
            isDuplicated={!!duplicatedUuid}
            handleChangeTemplate={handleChangeTemplate}
            handleOpenSaveAsTemplateModal={() => setIsTemplateNameModalOpen(true)}
            isOrganizationFetched={Object.keys(organization).length !== 0}
            onLoad={onEditorLoad}
            template={template}
            templatesList={templatesList}
          />
        </StepTwoContainer>
        <StepThreeContainer step={step}>
          <StepThree
            isScheduled={isScheduled}
            message={broadcastData?.message}
            onSendPreview={handleSendPreview}
            previewEmails={previewEmails}
            previewEmailsError={previewEmailsError}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            selectedTimeZone={selectedTimeZone}
            setPreviewEmails={setPreviewEmails}
            setSelectedDate={setSelectedDate}
            setSelectedTime={setSelectedTime}
            setSelectedTimeZone={setSelectedTimeZone}
            setIsScheduled={setIsScheduled}
            timezones={timezones}
          />
        </StepThreeContainer>
      </Container>
      <StepperWrapper>
        <Grid
          container
          direction="row"
          item
          justifyContent="space-between"
          lg={10}
          xs={11}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-start"
            xs={2}
          >
            <Button
              disabled={step === 0}
              onClick={handleGoBack}
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-end"
            xs={4}
          >
            <NextButtonWrapper>
              <ButtonCreateWrapper>
                <Button
                  color="secondary"
                  disabled={disableNextButton() || isPending}
                  onClick={handleSave}
                  variant="contained"
                  type="button"
                >
                  {step !== 2 ? 'Save and Continue' : `Send to ${formatNumber(countRecipients)} recipients`}
                </Button>
                {isPending && <ButtonCircularProgress size={24} />}
              </ButtonCreateWrapper>
            </NextButtonWrapper>
          </Grid>
        </Grid>
      </StepperWrapper>
      <SuccessDialog
        isDialogOpen={isSuccessDialogOpen}
        name="Brodcast"
        onCreateNew={handleCreateNew}
        redirectTo={`/${organizationUuid}/broadcasts`}
        thankYouMessage="Thank you for sending this Broadcast"
      />
      <Dialog
        dialogError={dialogError && showFirstErrorMessage(dialogError)}
        handleConfirm={handleSaveAsTemplate}
        handleDialogClose={() => {
          setIsTemplateNameModalOpen(false);
          setTemplateNameValue('');
        }}
        isDialogOpen={isTemplateNameModalOpen}
        title="Template Name"
        disabledButton={isPending}
      >
        <TextField
          fullWidth
          placeholder="Add Template Name"
          onChange={event => setTemplateNameValue(event.target.value)}
          type="text"
          value={templateNameValue}
        />
      </Dialog>
      <Backdrop isOpen={isBroadcastFetching || isLoading} />
    </>
  );
};

export default CreateEmailBroadcast;
