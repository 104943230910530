import { useMemo } from 'react';
import PropTypes, { number } from 'prop-types';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

const options = {
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
      stacked: true,
    },
    y: {
      display: false,
      grid: {
        display: false,
      },
      min: 0,
      stacked: true,
      ticks: {
        precision: 0,
      },
    },
  },
};

const StackedChart = ({ data }) => {
  const chartData = useMemo(() => {
    if (!data) {
      return { datasets: [] };
    }

    const labels = data.results.map(({ date }) => date);

    return ({
      datasets: [
        {
          backgroundColor: 'rgb(75, 192, 192)',
          data: data.results.map(({ actions }) => actions.email),
          label: 'Emails',
        },
        {
          backgroundColor: 'rgb(255, 99, 132)',
          data: data.results.map(({ actions }) => actions.phone),
          label: 'Calls',
        },
      ],
      labels,
    });
  }, [data?.results?.length]);

  return (
    <Bar
      options={options}
      data={chartData}
      height={400}
    />
  );
};

StackedChart.defaultProps = {
  data: null,
};

StackedChart.propTypes = {
  data: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({
      actions: PropTypes.shape({
        email: PropTypes.number,
        phone: PropTypes.number,
      }),
      date: PropTypes.string,
    })),
    tzOffsetMinutes: number,
  }),
};

export default StackedChart;

