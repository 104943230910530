import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { contactDetailsSelectors } from 'store/selectors/contactDetails';

import {
  setSnackbarSuccess,
} from 'store/actions/global';
import { colors } from 'utils/constants';
import { showFirstErrorMessage } from 'utils/helpers';

const DialogErrorText = styled(DialogContentText)`
  color: ${colors.warning};
`;

const DialogUser = ({
  children,
  contactId,
  description,
  email,
  handleDataUpdate,
  handleDialogClose,
  isDialogOpen,
  isLocationDialog,
  isOfficialsDialog,
  listId,
  listName,
  phoneNumber,
  subtitle,
  title,
}) => {
  const { isAddingLocation } = useSelector(state => ({
    isAddingLocation: contactDetailsSelectors.isAddingLocation(state),
  }));
  const dispatch = useDispatch();

  const [
    dialogError,
    setDialogError,
  ] = useState('');

  const handleDialogSave = () => {
    if (contactId) {
      if (isLocationDialog) {
        document.querySelector('.location-form').dispatchEvent(new Event('submit', {
          bubbles: true,
          cancelable: true,
        }));
      } else {
        const params = email ? { email } : { phone_number: phoneNumber };

        handleDataUpdate(listId, contactId, params)
          .then(() => {
            setDialogError(false);
            handleDialogClose();
            dispatch(setSnackbarSuccess());
          })
          .catch(error => {
            setDialogError(showFirstErrorMessage(error));
          });
      }
    } else if (isOfficialsDialog) {
      handleDataUpdate()
        .then(() => {
          setDialogError(false);
          handleDialogClose();
        })
        .catch(() => setDialogError(true));
    } else {
      handleDataUpdate(listId, { name: listName })
        .then(() => {
          setDialogError(false);
          handleDialogClose();
        })
        .catch(() => setDialogError(true));
    }
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={handleDialogClose}
      fullWidth
      open={isDialogOpen}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{subtitle}</DialogContentText>
        {dialogError && <DialogErrorText>{dialogError}</DialogErrorText>}
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isAddingLocation || (isOfficialsDialog && !description)}
          onClick={handleDialogSave}
          type={isLocationDialog ? 'submit' : 'button'}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogUser.defaultProps = {
  contactId: '',
  description: '',
  email: '',
  handleDataUpdate: () => {},
  isLocationDialog: false,
  isOfficialsDialog: false,
  listId: '',
  listName: '',
  phoneNumber: '',
  subtitle: '',
};

DialogUser.propTypes = {
  children: PropTypes.element.isRequired,
  contactId: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  handleDataUpdate: PropTypes.func,
  handleDialogClose: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isLocationDialog: PropTypes.bool,
  isOfficialsDialog: PropTypes.bool,
  listId: PropTypes.string,
  listName: PropTypes.string,
  phoneNumber: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default DialogUser;
