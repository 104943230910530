import {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import {
  fetchBoundariesTop,
  fetchBoundariesTree,
} from 'store/actions/global';
import { globalSelectors } from 'store/selectors/global';

import { boundaryTypes } from 'utils/constants';
import { CircularProgressWrapper } from 'globalStyles';

const ButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const StyledProgress = styled.div`
  margin-top: 20px;
`;

const AddBoundaries = ({
  boundariesList,
  handleAddBoundary,
  handleClose,
  isBoundariesLoading,
  isDialogOpen,
  setBoundariesList,
}) => {
  const dispatch = useDispatch();
  const {
    boundariesTop,
    boundariesTree,
    isBoundariesTopFetching,
    isBoundariesTreeFetching,
  } = useSelector(state => ({
    boundariesTop: globalSelectors.getBoundariesTop(state).children,
    boundariesTree: globalSelectors.getBoundariesTree(state),
    isBoundariesTopFetching: globalSelectors.isBoundariesTopFetching(state),
    isBoundariesTreeFetching: globalSelectors.isBoundariesTreeFetching(state),
  }));

  useEffect(() => {
    if (boundariesTop.length === 0) {
      dispatch(fetchBoundariesTop());
    }
  }, [dispatch]);

  const [
    boundary,
    setBoundary,
  ] = useState('');
  const [
    state,
    setState,
  ] = useState('');

  const onClose = () => {
    setState('');
    setBoundary('');
    setBoundariesList([]);
    handleClose();
  };

  const renderBoundaryType = () => {
    if (isBoundariesTreeFetching) {
      return (
        <StyledProgress>
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        </StyledProgress>
      );
    }

    const boundariesTreeList = boundariesTree[state.uuid] || [];

    return (
      <Autocomplete
        getOptionLabel={option => option.name}
        multiple
        onChange={(_, list) => {
          if (!!list.length && list[0].name === 'All') {
            setBoundariesList(
              boundariesTreeList.filter(
                ({ boundaryType }) => boundaryType === boundary.type
              )
            );
          } else {
            setBoundariesList(list);
          }
        }}
        options={
          [
            { name: 'All' },
            ...boundariesTreeList.filter(
              ({ boundaryType }) => boundaryType === boundary.type
            ).sort((a, b) => -b.name.localeCompare(a.name, 'en', { numeric: true })),
          ]
        }
        renderInput={params => (
          <TextField
            {...params}
            label={boundary.name}
            variant="standard"
          />
        )}
        value={boundariesList}
      />
    );
  };

  return (
    <Dialog
      aria-labelledby="boundary-dialog"
      fullWidth
      onClose={onClose}
      open={isDialogOpen}
    >
      <DialogTitle id="boundary-dialog-title">Add boundary</DialogTitle>
      <DialogContent>
        {isBoundariesTopFetching ? (
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        ) : (
          <Autocomplete
            getOptionLabel={option => option.name}
            onChange={(_, pickedState) => {
              setState('');
              setBoundary('');
              setBoundariesList([]);

              if (pickedState) {
                setState(boundariesTop.find(({ uuid }) => uuid === pickedState.uuid));
              }
            }}
            options={boundariesTop.sort((a, b) => -b.name.localeCompare(a.name))}
            renderInput={params => (
              <TextField
                {...params}
                label="Select state"
                variant="standard"
              />
            )}
          />
        )}
        {state && (
          <Autocomplete
            getOptionLabel={option => option.name}
            key={state.name}
            onChange={(_, pickedBoundary) => {
              setBoundary(pickedBoundary);

              if (pickedBoundary?.type !== 'state') {
                if (!Object.keys(boundariesTree).includes(state.uuid)) {
                  dispatch(fetchBoundariesTree(state.uuid));
                }
              }

              if (pickedBoundary?.type === 'state') {
                setBoundariesList([state]);
              }
            }}
            options={boundaryTypes}
            renderInput={params => (
              <TextField
                {...params}
                label="Select boundary type"
                variant="standard"
              />
            )}
          />
        )}
        {state && boundary && boundary?.type !== 'state' && renderBoundaryType()}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isBoundariesLoading}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={boundary === '' || boundariesList.length === 0 || isBoundariesLoading}
          onClick={handleAddBoundary}
        >
          Add
          {isBoundariesLoading && <ButtonCircularProgress size={24} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddBoundaries.propTypes = {
  boundariesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleAddBoundary: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isBoundariesLoading: PropTypes.bool.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  setBoundariesList: PropTypes.func.isRequired,
};

export default AddBoundaries;
