import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';
import {
  Add as AddIcon,
  TextFields as TextFieldsIcon,
} from '@mui/icons-material';

import { fetchRootList } from 'store/actions/contacts';
import {
  fetchCustomFields,
  patchCustomField,
  postCustomField,
} from 'store/actions/customFields';
import { contactSelectors } from 'store/selectors/contacts';
import { customFieldsSelectors } from 'store/selectors/customFields';
import { setSnackbarError } from 'store/actions/global';

import {
  BreadcrumbsNavigation,
  Container,
  DescriptionBar,
  TabPanel,
} from 'common/components';
import {
  CircularProgressWrapper,
  TabsWrapper,
} from 'globalStyles';
import { fieldTypes } from 'utils/constants';

import CustomFieldCard from '../components/CustomFieldCard';
import CustomFieldDialog from '../components/CustomFieldDialog';

const AddFieldButton = styled(Button)`
  height: 48px;
`;

const ListsWrapper = styled.div`
  width: 100%;
`;

const StyledCustomFieldCard = styled(CustomFieldCard)`
  margin-top: 20px;
`;

const { REACT_APP_FEATURE_FLAG_MULTIPLE_CHOICE } = process.env;

const CustomFields = () => {
  const { organizationUuid } = useParams();
  const dispatch = useDispatch();
  const [
    tabIndex,
    setTabIndex,
  ] = useState(0);
  const defaultCustomFieldState = {
    description: '',
    fieldType: '',
    isModalOpened: false,
    label: '',
    placeholder: '',
  };
  const [
    editedCustomField,
    setEditedCustomField,
  ] = useState({ ...defaultCustomFieldState });
  const {
    customFieldsList,
    isFetchingCustomFields,
    isPendingCustomField,
    rootList,
  } = useSelector(state => ({
    customFieldsList: customFieldsSelectors.getCustomFieldsListData(state) || [],
    isFetchingCustomFields: customFieldsSelectors.isFetchingCustomFieldsListData(state),
    isPendingCustomField: customFieldsSelectors.isPendingCustomField(state),
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  const {
    multipleChoiceField,
    textField,
    singleChoiceField,
  } = fieldTypes;

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/contacts/lists`,
      name: 'Lists',
    },
    {
      link: '',
      name: 'Custom Fields',
    },
  ];

  useEffect(() => {
    if (Object.keys(rootList).length === 0) {
      dispatch(fetchRootList(organizationUuid))
        .then(data => dispatch(fetchCustomFields(data.uuid)));
    } else {
      dispatch(fetchCustomFields(rootList.uuid));
    }
  }, []);

  const handleUpdateCustomField = async () => {
    const params = {
      description: editedCustomField.description,
      fieldType: editedCustomField.fieldType,
      label: editedCustomField.label,
      ...(editedCustomField.fieldType === textField ? {
        placeholder: editedCustomField.placeholder,
      } : {}),
      ...(editedCustomField.fieldType !== textField ? {
        options: editedCustomField.options.map(option => option.value),
      } : {}),
    };

    try {
      if (!editedCustomField.uuid) {
        await dispatch(postCustomField(rootList.uuid, params));
      } else {
        await dispatch(patchCustomField(rootList.uuid, editedCustomField.uuid, params));
      }
      dispatch(fetchCustomFields(rootList.uuid));

      setEditedCustomField({ ...defaultCustomFieldState });
    } catch (error) {
      dispatch(setSnackbarError());
    }
  };

  const handleDialogClose = () => {
    setEditedCustomField({ ...defaultCustomFieldState });
  };

  const handleChange = (_, newValue) => setTabIndex(newValue);

  const renderFilteredCustomFields = listType => customFieldsList
    .filter(list => list.fieldType === listType)
    .map(customField => (
      <StyledCustomFieldCard
        customField={customField}
        key={customField.uuid}
        setEditedCustomField={setEditedCustomField}
      />
    ));

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container>
        <Grid container>
          <DescriptionBar
            icon={TextFieldsIcon}
            name="Custom Fields"
          />
        </Grid>
        <TabsWrapper>
          <Paper variant="outlined">
            <Tabs
              indicatorColor="primary"
              onChange={handleChange}
              textColor="primary"
              value={tabIndex}
            >
              <Tab label="All" />
              <Tab
                label="Text Field"
                disabled={!customFieldsList.find(
                  list => list.fieldType === textField
                )}
              />
              <Tab
                label="Choice"
                disabled={!customFieldsList.find(
                  list => list.fieldType === singleChoiceField
                )}
              />
              {REACT_APP_FEATURE_FLAG_MULTIPLE_CHOICE && (
                <Tab
                  label="Multiple Choice"
                  disabled={!customFieldsList.find(
                    list => list.fieldType === multipleChoiceField
                    )}
                />
              )}
            </Tabs>
          </Paper>
          <div>
            <AddFieldButton
              color="secondary"
              onClick={() => setEditedCustomField({
                ...defaultCustomFieldState,
                isModalOpened: true,
              })}
              startIcon={<AddIcon />}
              variant="contained"
            >
              New Field
            </AddFieldButton>
          </div>
        </TabsWrapper>
        <ListsWrapper>
          {isFetchingCustomFields ? (
            <CircularProgressWrapper>
              <CircularProgress />
            </CircularProgressWrapper>
          ) : (
            <>
              <TabPanel
                index={0}
                value={tabIndex}
              >
                {!!customFieldsList.length && customFieldsList.map(customField => (
                  <StyledCustomFieldCard
                    customField={customField}
                    key={customField.uuid}
                    setEditedCustomField={setEditedCustomField}
                  />
                ))}
              </TabPanel>
              <TabPanel
                index={1}
                value={tabIndex}
              >
                {!!customFieldsList.length && renderFilteredCustomFields(textField)}
              </TabPanel>
              <TabPanel
                index={2}
                value={tabIndex}
              >
                {!!customFieldsList.length && renderFilteredCustomFields(singleChoiceField)}
              </TabPanel>
              <TabPanel
                index={3}
                value={tabIndex}
              >
                {!!customFieldsList.length && renderFilteredCustomFields(multipleChoiceField)}
              </TabPanel>
            </>
          )}
        </ListsWrapper>
      </Container>
      <CustomFieldDialog
        editedCustomField={editedCustomField}
        handleDialogClose={handleDialogClose}
        handleUpdateCustomField={handleUpdateCustomField}
        isPendingCustomField={isPendingCustomField}
        setEditedCustomField={setEditedCustomField}
      />
    </>
  );
};

export default CustomFields;
