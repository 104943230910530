import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog as MaterialDialog,
} from '@mui/material';

import { colors } from 'utils/constants';

const DialogErrorText = styled(DialogContentText)`
  color: ${colors.warning};
`;

const Dialog = ({
  confirmationText,
  dialogError,
  disabledButton,
  children,
  handleConfirm,
  handleDialogClose,
  isDialogOpen,
  subtitle,
  title,
}) => (
  <MaterialDialog
    aria-labelledby="dialog-title"
    onClose={handleDialogClose}
    fullWidth
    open={isDialogOpen}
  >
    <DialogTitle id="dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{subtitle}</DialogContentText>
      {dialogError && <DialogErrorText>{dialogError}</DialogErrorText>}
      {children}
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        variant="text"
        onClick={handleDialogClose}
      >
        Cancel
      </Button>
      {/* Conditional used to allow removing confirmation step if, e.g. no options available. */}
      {confirmationText && (
        <Button
          data-testid="confirm-button"
          color="secondary"
          disabled={disabledButton}
          onClick={handleConfirm}
          type="button"
          variant="contained"
        >
          {confirmationText}
        </Button>
      )}
    </DialogActions>
  </MaterialDialog>
);

Dialog.defaultProps = {
  confirmationText: 'Save',
  dialogError: null,
  disabledButton: false,
  subtitle: '',
};

Dialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  confirmationText: PropTypes.string,
  dialogError: PropTypes.string,
  disabledButton: PropTypes.bool,
  handleConfirm: PropTypes.func.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Dialog;
