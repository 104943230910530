import {
  ADD_CUSTOM_FIELD_FAILURE,
  ADD_CUSTOM_FIELD_REQUEST,
  ADD_CUSTOM_FIELD_SUCCESS,
  ADD_LOCATION_FAILURE,
  ADD_LOCATION_REQUEST,
  ADD_LOCATION_SUCCESS,
  ADD_NEW_EMAIL_FAILURE,
  ADD_NEW_EMAIL_REQUEST,
  ADD_NEW_EMAIL_SUCCESS,
  ADD_OFFICIAL_FAILURE,
  ADD_OFFICIAL_REQUEST,
  ADD_OFFICIAL_SUCCESS,
  ADD_PHONE_NUMBER_FAILURE,
  ADD_PHONE_NUMBER_REQUEST,
  ADD_PHONE_NUMBER_SUCCESS,
  DELETE_CUSTOM_FIELD_FAILURE,
  DELETE_CUSTOM_FIELD_REQUEST,
  DELETE_CUSTOM_FIELD_SUCCESS,
  DELETE_EMAIL_FAILURE,
  DELETE_EMAIL_REQUEST,
  DELETE_EMAIL_SUCCESS,
  DELETE_LOCATION_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_OFFICIAL_FAILURE,
  DELETE_OFFICIAL_REQUEST,
  DELETE_OFFICIAL_SUCCESS,
  DELETE_PHONE_NUMBER_FAILURE,
  DELETE_PHONE_NUMBER_REQUEST,
  DELETE_PHONE_NUMBER_SUCCESS,
  DELETE_POSITION_TERMS,
  FETCH_CONTACT_FAILURE,
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_FEDERAL_POSITIONS_FAILURE,
  FETCH_FEDERAL_POSITIONS_REQUEST,
  FETCH_FEDERAL_POSITIONS_SUCCESS,
  FETCH_POSITION_TERMS_FAILURE,
  FETCH_POSITION_TERMS_REQUEST,
  FETCH_POSITION_TERMS_SUCCESS,
  UPDATE_CUSTOM_FIELD_FAILURE,
  UPDATE_CUSTOM_FIELD_REQUEST,
  UPDATE_CUSTOM_FIELD_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_PHONE_NUMBER_FAILURE,
  UPDATE_PHONE_NUMBER_REQUEST,
  UPDATE_PHONE_NUMBER_SUCCESS,
} from '../actions/contactDetails';

const initialState = {
  contactDetails: {
    customFields: [],
    emails: [],
    officials: [],
    phoneNumbers: [],
  },
  errors: null,
  federalPositions: [],
  isContactFetching: false,
  isFetching: false,
  isPending: false,
  isPositionsFetching: false,
  isTermsFetching: false,
  positionTerms: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_LOCATION_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          locations: state.contactDetails.locations.concat(action.payload),
        },
        isPending: false,
      };
    case ADD_LOCATION_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case UPDATE_LOCATION_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case UPDATE_LOCATION_SUCCESS: {
      const {
        locationUuid,
        data,
      } = action.payload;

      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          locations: [
            ...state.contactDetails.locations.map(location => (
              location.uuid === locationUuid ?
                {
                  ...location,
                  ...data,
                } :
                location
            )),
          ],
        },
        isPending: false,
      };
    }
    case UPDATE_LOCATION_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case ADD_NEW_EMAIL_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case ADD_NEW_EMAIL_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          emails: state.contactDetails.emails.concat(action.payload),
        },
        isPending: false,
      };
    case ADD_NEW_EMAIL_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case UPDATE_EMAIL_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case UPDATE_EMAIL_SUCCESS: {
      const {
        emailUuid,
        data,
      } = action.payload;

      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          emails: [
            ...state.contactDetails.emails.map(email => (
              email.uuid === emailUuid ?
                {
                  ...email,
                  ...data,
                } :
                email
            )),
          ],
        },
        isPending: false,
      };
    }
    case UPDATE_EMAIL_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case ADD_OFFICIAL_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case ADD_OFFICIAL_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          officials: state.contactDetails.officials.concat(action.payload),
        },
        isPending: false,
      };
    case ADD_OFFICIAL_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case ADD_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case ADD_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          phoneNumbers: state.contactDetails.phoneNumbers.concat(action.payload),
        },
        isPending: false,
      };
    case ADD_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case UPDATE_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case UPDATE_PHONE_NUMBER_SUCCESS: {
      const {
        phoneUuid,
        data,
      } = action.payload;

      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          phoneNumbers: [
            ...state.contactDetails.phoneNumbers.map(phoneNumber => (
              phoneNumber.uuid === phoneUuid ?
                {
                  ...phoneNumber,
                  ...data,
                } :
                phoneNumber
            )),
          ],
        },
        isPending: false,
      };
    }
    case UPDATE_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case DELETE_EMAIL_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_EMAIL_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          emails: state.contactDetails.emails.filter(item => item.uuid !== action.payload),
        },
        isPending: false,
      };
    case DELETE_EMAIL_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case DELETE_LOCATION_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          locations: state.contactDetails.locations.filter(item => item.uuid !== action.payload),
        },
        isPending: false,
      };
    case DELETE_LOCATION_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case DELETE_OFFICIAL_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_OFFICIAL_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          officials: state.contactDetails.officials.filter(
            item => item.uuid !== action.payload
          ),
        },
        isPending: false,
      };
    case DELETE_OFFICIAL_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case DELETE_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          phoneNumbers: state.contactDetails.phoneNumbers.filter(
            item => item.uuid !== action.payload
          ),
        },
        isPending: false,
      };
    case DELETE_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_CONTACT_REQUEST:
      return {
        ...state,
        errors: null,
        isContactFetching: true,
      };
    case FETCH_CONTACT_SUCCESS:
      return {
        ...state,
        contactDetails: action.payload,
        isContactFetching: false,
      };
    case FETCH_CONTACT_FAILURE:
      return {
        ...state,
        contactDetails: [],
        errors: action.payload,
        isContactFetching: false,
      };
    case FETCH_FEDERAL_POSITIONS_REQUEST:
      return {
        ...state,
        errors: null,
        isPositionsFetching: true,
      };
    case FETCH_FEDERAL_POSITIONS_SUCCESS:
      return {
        ...state,
        federalPositions: action.payload.results,
        isPositionsFetching: false,
      };
    case FETCH_FEDERAL_POSITIONS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        federalPositions: [],
        isPositionsFetching: false,
      };
    case FETCH_POSITION_TERMS_REQUEST:
      return {
        ...state,
        errors: null,
        isTermsFetching: true,
      };
    case FETCH_POSITION_TERMS_SUCCESS:
      return {
        ...state,
        isTermsFetching: false,
        positionTerms: action.payload.results,
      };
    case FETCH_POSITION_TERMS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isTermsFetching: false,
        positionTerms: [],
      };
    case DELETE_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          customFields: state.contactDetails.customFields.filter(
            item => item.uuid !== action.payload
          ),
        },
        isPending: false,
      };
    case DELETE_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case ADD_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case ADD_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          customFields: state.contactDetails.customFields.concat(action.payload),
        },
        isPending: false,
      };
    case ADD_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case UPDATE_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case UPDATE_CUSTOM_FIELD_SUCCESS: {
      const {
        customFieldUuid,
        data,
      } = action.payload;

      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          customFields: [
            ...state.contactDetails.customFields.map(customField => (
              customField.uuid === customFieldUuid ?
                {
                  ...customField,
                  ...data,
                } :
                customField
            )),
          ],
        },
        isPending: false,
      };
    }
    case UPDATE_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case DELETE_POSITION_TERMS:
      return {
        ...state,
        positionTerms: [],
      };
    default:
      return state;
  }
};
