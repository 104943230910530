import PropTypes from 'prop-types';
import styled from 'styled-components';

import ListCard from 'routes/Lists/components/ListCard';
import { contactListType } from 'common/typings';

const StyledListCard = styled(ListCard)`
  width: 100%;
  margin-top: 20px;
`;

const Lists = ({
  isAddToGroupButonDisabled,
  lists,
  onOpenAddToGroupModal,
  onShowImportListView,
}) => (
  lists
    .map(list => (
      <StyledListCard
        countAll={list.count}
        isAddToGroupButonDisabled={isAddToGroupButonDisabled}
        onShowImportListView={onShowImportListView}
        onOpenAddToGroupModal={onOpenAddToGroupModal}
        key={list.uuid}
        list={list}
      />
    ))
);

Lists.propTypes = {
  isAddToGroupButonDisabled: PropTypes.bool.isRequired,
  lists: PropTypes.arrayOf(contactListType).isRequired,
  onOpenAddToGroupModal: PropTypes.func.isRequired,
  onShowImportListView: PropTypes.func.isRequired,
};

export default Lists;
