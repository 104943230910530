import PropTypes from 'prop-types';

import {
  Tooltip,
} from '@mui/material';
import {
  Info as InfoIcon,
} from '@mui/icons-material';

const TooltipIcon = ({
  placement,
  title,
}) => (
  <Tooltip
    placement={placement}
    title={title}
  >
    <InfoIcon />
  </Tooltip>
);

TooltipIcon.defaultProps = {
  placement: 'top-start',
};

TooltipIcon.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default TooltipIcon;
