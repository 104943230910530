import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from '@mui/material';

const StyledBox = styled(Box)`
  padding: 0;
`;

const TabPanel = ({
  children,
  className,
  index,
  value,
}) => (
  <div
    aria-labelledby={`lists-tab-${index}`}
    className={className}
    hidden={value !== index}
    id={`lists-tabpanel-${index}`}
    role="tabpanel"
  >
    {value === index && <StyledBox p={3}>{children}</StyledBox>}
  </div>
);

TabPanel.defaultProps = {
  className: '',
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  index: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
};

export default TabPanel;
