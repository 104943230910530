import {
  useEffect,
  useState,
} from 'react';
import {
  Link as RouterLink,
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';

import {
  Breadcrumbs,
  Button,
  CircularProgress,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { Add as AddIcon } from '@mui/icons-material';

import {
  addContactsToList,
  deleteContacts,
  deleteListContacts,
  fetchChildrenLists,
  fetchRootList,
} from 'store/actions/contacts';
import {
  addEmail,
  addPhoneNumber,
  fetchContact,
  updateEmail,
  updatePhoneNumber,
} from 'store/actions/contactDetails';
import { contactSelectors } from 'store/selectors/contacts';
import { contactDetailsSelectors } from 'store/selectors/contactDetails';

import {
  BreadcrumbsNavigation,
  Container,
  Dialog,
  UserDialog,
} from 'common/components';
import { listTypes } from 'utils/constants';
import {
  formatPhoneNumber,
  handleNumberFirstDigitsFormat,
} from 'utils/helpers';
import { CircularProgressWrapper } from 'globalStyles';

import CustomFieldDialog from '../components/CustomFieldDialog';
import LocationsForm from '../components/LocationsForm';
import EmailsTable from '../components/EmailsTable';
import LocationsTable from '../components/LocationsTable';
import PhonesTable from '../components/PhonesTable';
import OfficialsForm from '../components/OfficialsForm';
import OfficialsTable from '../components/OfficialsTable';
import MembershipsTable from '../components/MembershipsTable';
import CustomFieldsTable from '../components/CustomFieldsTable';
import ContactMap from '../components/ContactMap';

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
  font-weight: bold;
  font-size: 30px;
`;

const PaperWrapper = styled(Paper)`
  width: 100%;
  padding: 10px;
`;

const SectionWrapper = styled.div`
  padding-bottom: 70px;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: flex;
  justify-content: center;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 15px;
`;

const ExternalIdWrapper = styled(Typography)`
  width: 100%;
  margin: 0 0 15px;
  text-align: center;
`;

const ExternalId = styled.span`
  color: var(--fbc-blue-60);
  font-weight: bold;
`;

const ADD_TO_ANOTHER_LIST_DIALOG_SUBTITLE = 'To add contacts to another list, select one from the list below';
const ADD_TO_ANOTHER_LIST_DIALOG_TITLE = 'Add contacts to another list';
const REMOVE_CONTACT = 'This action will remove selected contacts from entire database';
const REMOVE_CONTACT_FROM_LIST_MESSAGE = 'This action will remove contact from current list';

const ContactDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    contactUuid,
    listUuid,
    organizationUuid,
  } = useParams();

  const {
    childrenLists,
    contactDetails,
    isContactFetching,
    isPending,
    lists,
    rootList,
  } = useSelector(state => ({
    childrenLists: contactSelectors.getChildrenLists(state)[organizationUuid] || [],
    contactDetails: contactDetailsSelectors.getContactDetails(state),
    isContactFetching: contactDetailsSelectors.isContactFetching(state),
    isPending: contactSelectors.isPendingList(state),
    lists: contactSelectors.getChildrenLists(state)[organizationUuid] || [],
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  useEffect(() => {
    if (Object.keys(rootList)) {
      dispatch(fetchRootList(organizationUuid)).then(data => {
        if (childrenLists.length === 0) {
          dispatch(fetchChildrenLists(data.uuid, organizationUuid));
        }
      });
    }

    dispatch(fetchContact(listUuid, contactUuid));
  }, []);

  const [
    isLocationDialogOpen,
    setLocationDialogOpen,
  ] = useState(false);
  const [
    isOfficialsDialogOpen,
    setOfficialsDialogOpen,
  ] = useState(false);
  const [
    isMergeDialogOpen,
    setIsMergeDialogOpen,
  ] = useState(false);
  const [
    addToList,
    setAddToList,
  ] = useState('');
  const [
    editedLocation,
    setEditedLocation,
  ] = useState({});
  const [
    customFieldData,
    setCustomFieldData,
  ] = useState({
    editedCustomField: {},
    isDialogOpen: false,
  });
  const [
    emailData,
    setEmailData,
  ] = useState({
    editedEmail: {},
    isDialogOpen: false,
    text: '',
  });
  const [
    phoneData,
    setPhoneData,
  ] = useState({
    editedPhoneNumber: {},
    isDialogOpen: false,
    text: '',
  });

  const {
    allCustomFields,
    customFields,
    emails,
    externalId,
    firstName,
    lastName,
    locations,
    memberships,
    officials,
    phoneNumbers,
  } = contactDetails.uuid === contactUuid ? contactDetails : {};

  const { staticList } = listTypes;

  const confirm = useConfirm();

  const [currentList] = childrenLists.filter(({ uuid }) => uuid === listUuid);

  const listName = rootList.uuid === listUuid ? rootList.name : currentList?.name;

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/contacts/lists`,
      name: 'Lists',
    },
    {
      link: `/${organizationUuid}/contacts/lists/${listUuid}`,
      name: listName,
    },
    {
      link: '',
      name: `${firstName} ${lastName}`,
    },
  ];

  const handleEditEmail = (listId, contactId, params) => dispatch(
    updateEmail(listId, contactId, emailData.editedEmail?.uuid, params)
  );

  const handleAddEmail = (listId, contactId, params) => dispatch(
    addEmail(listId, contactId, params)
  );

  const handleEditPhoneNumber = (listId, contactId, params) => dispatch(
    updatePhoneNumber(listId, contactId, phoneData.editedPhoneNumber?.uuid, params)
  );

  const handleAddPhoneNumber = (listId, contactId, params) => dispatch(
    addPhoneNumber(listId, contactId, params)
  );

  const removeNonNumberFirstDigits = number => {
    const numberArray = number.split('') || [];
    const filteredNumber = numberArray.reduce(
      (numberAcc, digit) => {
        let newDigit = digit;

        if (numberAcc.length < 9) {
          newDigit = newDigit.replace(/[^0-9]/g, '');
        }

        return `${numberAcc}${newDigit}`;
      },
      ''
    );

    return filteredNumber;
  };

  const handleNumberFormat = value => {
    setPhoneData(prevState => ({
      ...prevState,
      text: handleNumberFirstDigitsFormat(value),
    }));
  };

  const handleEmailDialogClose = () => {
    setEmailData({
      editedEmail: {},
      isDialogOpen: false,
      text: '',
    });
  };

  const handlePhoneDialogClose = () => {
    setPhoneData({
      editedPhoneNumber: {},
      isDialogOpen: false,
      text: '',
    });
  };

  const handleRemoveFromList = () => {
    confirm({
      confirmationText: 'Remove',
      description: REMOVE_CONTACT_FROM_LIST_MESSAGE,
      title: 'Remove contact?',
    })
      .then(() => dispatch(deleteListContacts(
        listUuid,
        { contactUuids: [contactUuid] }
      )))
      .then(() => history.push(`/${organizationUuid}/contacts/lists/${listUuid}`));
  };

  const handleAddToAnotherList = () => {
    dispatch(addContactsToList(
      addToList,
      { contactUuids: [contactUuid] }
    ))
      .then(() => setIsMergeDialogOpen(false));
  };

  const handleRemoveContact = () => {
    confirm({
      confirmationText: 'Remove',
      description: REMOVE_CONTACT,
      title: 'Remove the contact completely?',
    })
      .then(() => dispatch(deleteContacts(
        listUuid,
        { contactUuids: [contactUuid] }
      )))
      .then(() => history.push(`/${organizationUuid}/contacts/lists/${listUuid}`));
  };

  const handleLocationEdit = location => {
    setEditedLocation(location);

    setLocationDialogOpen(true);
  };

  const handleEmailEdit = emailToEdit => {
    setEmailData({
      editedEmail: emailToEdit,
      isDialogOpen: true,
      text: emailToEdit?.email || '',
    });
  };

  const handlePhoneEdit = phoneToEdit => {
    setPhoneData({
      editedPhoneNumber: phoneToEdit,
      isDialogOpen: true,
      text: formatPhoneNumber(phoneToEdit?.phoneNumber) || '',
    });
  };

  const handleCustomFieldEdit = customFieldToEdit => {
    setCustomFieldData({
      editedCustomField: customFieldToEdit,
      isDialogOpen: true,
    });
  };

  const handleEmailChange = event => {
    const inputValue = event.target.value;

    setEmailData(prevState => ({
      ...prevState,
      text: inputValue,
    }));
  };

  const firstGeocodedLocationIndex = locations?.findIndex(location => !!location.point);

  return (
    <>
      <BreadcrumbsNavigation
        isLoading={isContactFetching}
        links={breadcrumbs}
      />
      <Container
        marginBottom="40"
        marginTop="40"
      >
        {isContactFetching ? (
          <CircularProgressWrapper>
            <CircularProgress size={60} />
          </CircularProgressWrapper>
        ) : (
          <PaperWrapper variant="outlined">
            {firstGeocodedLocationIndex >= 0 && (
              <ContactMap
                coordinates={locations[firstGeocodedLocationIndex].point.coordinates}
              />
            )}
            <div>
              <NameWrapper>
                {firstName}
                &nbsp;
                {lastName}
              </NameWrapper>
              {externalId && (
                <ExternalIdWrapper variant="body1">
                  External ID:
                  {' '}
                  <ExternalId>
                    {externalId}
                  </ExternalId>
                </ExternalIdWrapper>
              )}
              {(childrenLists.length > 0 || rootList.uuid === listUuid) ? (
                <StyledBreadcrumbs aria-label="breadcrumb">
                  <Link
                    component={RouterLink}
                    color="inherit"
                    to={`/${organizationUuid}/contacts/lists/`}
                  >
                    Lists
                  </Link>
                  {rootList.uuid !== listUuid && (
                    <Link
                      component={RouterLink}
                      color="inherit"
                      to={`/${organizationUuid}/contacts/lists/${listUuid}`}
                    >
                      {currentList.name}
                    </Link>
                  )}
                </StyledBreadcrumbs>
              ) : (
                <CircularProgressWrapper>
                  <CircularProgress />
                </CircularProgressWrapper>
              )}
            </div>
            <ActionButtonsWrapper>
              {currentList && currentList.type === staticList && (
                <Button onClick={handleRemoveFromList}>Remove from list</Button>
              )}
              <Button
                component={RouterLink}
                to={`/${organizationUuid}/contacts/lists/${listUuid}/contact/${contactUuid}/edit`}
              >
                Edit contact
              </Button>
              <Button onClick={handleRemoveContact}>Delete contact</Button>
            </ActionButtonsWrapper>
            <SectionWrapper>
              <Typography variant="h5">
                Locations
                <IconButton
                  aria-label="add"
                  onClick={() => {
                    setEditedLocation({});
                    setLocationDialogOpen(true);
                  }}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              </Typography>
              <LocationsForm
                contactId={contactUuid}
                handleDialogClose={() => setLocationDialogOpen(false)}
                isDialogOpen={isLocationDialogOpen}
                listId={listUuid}
                title="Adding a new location"
                editedLocation={editedLocation}
              />
              {locations && (
                <LocationsTable
                  contactId={contactUuid}
                  listId={listUuid}
                  locations={locations}
                  onEditClick={handleLocationEdit}
                />
              )}
            </SectionWrapper>
            <SectionWrapper>
              <Typography variant="h5">
                Grass Tops Info
                <IconButton
                  aria-label="add"
                  onClick={() => setOfficialsDialogOpen(true)}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              </Typography>
              <OfficialsForm
                contactId={contactUuid}
                handleDialogClose={() => setOfficialsDialogOpen(false)}
                isDialogOpen={isOfficialsDialogOpen}
                listId={listUuid}
                title="Officials information"
              />
              {officials && (
                <OfficialsTable
                  contactId={contactUuid}
                  listId={listUuid}
                  officials={officials}
                />
              )}
            </SectionWrapper>
            <SectionWrapper>
              <Typography variant="h5">
                Emails
                <IconButton
                  aria-label="add"
                  onClick={() => setEmailData({
                    editedEmail: {},
                    isDialogOpen: true,
                    text: '',
                  })}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              </Typography>
              <UserDialog
                contactId={contactUuid}
                email={emailData.text}
                handleDataUpdate={emailData.editedEmail?.uuid ? handleEditEmail : handleAddEmail}
                handleDialogClose={handleEmailDialogClose}
                isDialogOpen={emailData.isDialogOpen}
                listId={listUuid}
                subtitle={
                  emailData.editedEmail?.uuid ?
                  'Type a new value for the e-mail address' :
                  'Type an e-mail address you want to add'
                }
                title={emailData.editedEmail?.uuid ? 'Editing an e-mail' : 'Adding a new e-mail'}
              >
                <TextField
                  autoFocus
                  value={emailData.text}
                  fullWidth
                  label="Email"
                  onChange={event => handleEmailChange(event)}
                  type="email"
                />
              </UserDialog>
              {emails && (
                <EmailsTable
                  contactId={contactUuid}
                  emails={emails}
                  listId={listUuid}
                  onEditClick={handleEmailEdit}
                />
              )}
            </SectionWrapper>
            <SectionWrapper>
              <Typography variant="h5">
                Phones
                <IconButton
                  aria-label="add"
                  onClick={() => setPhoneData({
                    editedPhoneNumber: {},
                    isDialogOpen: true,
                    text: '',
                  })}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              </Typography>
              <UserDialog
                contactId={contactUuid}
                phoneNumber={removeNonNumberFirstDigits(phoneData.text)}
                handleDataUpdate={phoneData.editedPhoneNumber?.uuid ?
                  handleEditPhoneNumber :
                  handleAddPhoneNumber
                }
                handleDialogClose={handlePhoneDialogClose}
                isDialogOpen={phoneData.isDialogOpen}
                listId={listUuid}
                subtitle={
                  phoneData.editedPhoneNumber?.uuid ?
                  'Type a new value for the phone number' :
                  'Type a phone number you want to add'
                }
                title={phoneData.editedPhoneNumber?.uuid ? 'Editing a phone number' : 'Adding a phone number'}
              >
                <TextField
                  autoFocus
                  value={phoneData.text}
                  fullWidth
                  label="Phone number"
                  onChange={event => handleNumberFormat(event.target.value)}
                  placeholder="(123) 123-1231"
                />
              </UserDialog>
              {phoneNumbers && (
                <PhonesTable
                  contactId={contactUuid}
                  listId={listUuid}
                  phoneNumbers={phoneNumbers}
                  onEditClick={handlePhoneEdit}
                />
              )}
            </SectionWrapper>
            {!!allCustomFields?.length && (
              <SectionWrapper>
                <Typography variant="h5">
                  Custom fields
                  <IconButton
                    aria-label="add"
                    onClick={() => setCustomFieldData({
                      editedCustomField: {},
                      isDialogOpen: true,
                    })}
                    size="large"
                  >
                    <AddIcon />
                  </IconButton>
                </Typography>
                {customFields && customFields.length === 0 ? (
                  <p>
                    Click the &quot;add&quot; button above to add custom field data.
                  </p>
                ) : (
                  <CustomFieldsTable
                    customFields={customFields}
                    contactId={contactUuid}
                    onEditClick={handleCustomFieldEdit}
                    listId={listUuid}
                  />
                )}
              </SectionWrapper>
            )}
            <SectionWrapper>
              <Typography variant="h5">
                List memberships
                <IconButton
                  aria-label="add"
                  onClick={() => setIsMergeDialogOpen(true)}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              </Typography>
              <MembershipsTable
                childrenLists={childrenLists}
                memberships={memberships}
              />
            </SectionWrapper>
          </PaperWrapper>
        )}
        <Dialog
          handleConfirm={handleAddToAnotherList}
          handleDialogClose={() => setIsMergeDialogOpen(false)}
          isDialogOpen={isMergeDialogOpen}
          subtitle={ADD_TO_ANOTHER_LIST_DIALOG_SUBTITLE}
          title={ADD_TO_ANOTHER_LIST_DIALOG_TITLE}
          disabledButton={isPending}
        >
          <StyledSelect
            onChange={event => setAddToList(event.target.value)}
            value={addToList}
          >
            {lists
              .filter(list => list.type === 'StaticList')
              .map(list => (
                <MenuItem
                  key={list.uuid}
                  value={list.uuid}
                >
                  {list.name}
                </MenuItem>
              ))}
          </StyledSelect>
        </Dialog>
        <CustomFieldDialog
          customFields={customFields}
          editedCustomField={customFieldData.editedCustomField}
          isDialogOpen={customFieldData.isDialogOpen}
          isPending={isPending}
          isEditCustomField={Object.keys(customFieldData.editedCustomField).length !== 0}
          allCustomFields={allCustomFields}
          setIsDialogOpen={value => setCustomFieldData(prevValues => ({
            ...prevValues,
            isDialogOpen: value,
          }))}
        />
      </Container>
    </>
  );
};

export default ContactDetails;
