import PropTypes from 'prop-types';
import EmailEditor from 'react-email-editor';
import styled from 'styled-components';
import { ButtonsColorsWrapper } from 'common/components';

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import { templateType } from 'common/typings';
import { globalEmailEditorOptions } from 'utils/constants';

const StyledFormControl = styled(FormControl)`
  width: 300px;
`;

const StyledGrid = styled(Grid)`
  margin: 30px 0;
`;

const StyledButton = styled(Button)`
  margin-top: 14px;
  margin-left: 16px;
`;

const StepTwo = ({
  buttonBackgroundColor,
  buttonTextColor,
  templatesList,
  emailEditorRef,
  handleChangeTemplate,
  handleChangeTemplateButton,
  handleOpenSaveAsTemplateModal,
  isDuplicated,
  isOrganizationFetched,
  onLoad,
  template,
}) => (
  <>
    <Grid
      alignItems="center"
      container
      justifyContent="space-between"
    >
      <StyledGrid
        item
        xs={6}
      >
        <StyledFormControl>
          <InputLabel id="your-file-header">Choose a Template</InputLabel>
          {Object.keys(template).length !== 0 && (
            <Select
              labelId="your-file-header"
              disabled={isDuplicated}
              onChange={event => handleChangeTemplate(event.target.value)}
              value={template.name}
            >
              {templatesList.map(({ name }) => (
                <MenuItem
                  key={name}
                  value={name}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          )}
        </StyledFormControl>
        <StyledButton
          color="secondary"
          onClick={handleOpenSaveAsTemplateModal}
        >
          Save as template
        </StyledButton>
      </StyledGrid>
      <Grid
        item
        xs={6}
      >
        <ButtonsColorsWrapper
          buttonBackgroundColor={buttonBackgroundColor}
          buttonTextColor={buttonTextColor}
          handleChangeTemplateButton={handleChangeTemplateButton}
        />
      </Grid>
    </Grid>
    {isOrganizationFetched && (
      <EmailEditor
        onLoad={onLoad}
        ref={emailEditorRef}
        options={globalEmailEditorOptions}
      />
    )}
  </>
);

StepTwo.propTypes = {
  buttonBackgroundColor: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string.isRequired,
  emailEditorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  handleChangeTemplate: PropTypes.func.isRequired,
  handleChangeTemplateButton: PropTypes.func.isRequired,
  handleOpenSaveAsTemplateModal: PropTypes.func.isRequired,
  isDuplicated: PropTypes.bool.isRequired,
  isOrganizationFetched: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
  template: templateType.isRequired,
  templatesList: PropTypes.arrayOf(PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    body: PropTypes.object,
    name: PropTypes.string,
  })).isRequired,
};

export default StepTwo;
