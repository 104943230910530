import PropTypes from 'prop-types';

import MaterialTable, { MTableCell } from '@material-table/core';
import {
  Paper,
  Switch,
} from '@mui/material';

import {
  campaignActionCentersColumns,
  colors,
} from 'utils/constants';

const TargetsTable = ({
  disableSwitch,
  enableTargets,
  handleSwitcherChange,
  hasStatistics,
  isDataFetching,
  targets,
}) => {
  const formattedTargets = targets?.map(target => {
    const targetCopy = { ...target };

    if (targetCopy.term && typeof instance === 'object') {
      targetCopy.fullName = `
      ${targetCopy.term?.official.firstName}
      ${targetCopy.term?.official.middleName}
      ${targetCopy.term?.official.lastName}
    `;
    } else {
      targetCopy.fullName = `
      ${targetCopy.firstName}
      ${targetCopy.middleName || ''}
      ${targetCopy.lastName}
    `;
    }

    if (!targetCopy.office) {
      if (targetCopy.officeTitle) {
        targetCopy.office = targetCopy.officeTitle;
      } else if (targetCopy.term?.office) {
        targetCopy.office = targetCopy.term.office.title;
      }
    }

    return targetCopy;
  });

  const columns = [...campaignActionCentersColumns];

  if (hasStatistics) {
    columns.push(
      {
        field: 'messageCount',
        title: 'Emails',
      },
      {
        field: 'phoneCallCount',
        title: 'Phone calls',
      }
    );
  }

  if (enableTargets) {
    columns.push({
      field: 'url',
      render: rowData => (
        <Switch
          checked={rowData.isActive}
          color="secondary"
          disabled={disableSwitch}
          name="enable"
          onChange={() => handleSwitcherChange(rowData)}
        />
      ),
      title: 'Enable',
    });
  }

  return (
    <MaterialTable
      columns={columns}
      components={{
        Cell: props => {
          // eslint-disable-next-line react/prop-types
          const { value } = props;

          return (
            <MTableCell
              {...props}
              value={value || 0}
            />
          );
        },
        Container: props => (
          <Paper
            {...props}
            elevation={0}
            square
            variant="outlined"
          />
        ),
      }}
      data={formattedTargets}
      isLoading={isDataFetching}
      options={{
        actionsColumnIndex: -1,
        headerStyle: {
          backgroundColor: colors.tableHeaderGray,
          color: colors.white,
        },
        selection: false,
        showTitle: false,
      }}
    />
  );
};

TargetsTable.defaultProps = {
  disableSwitch: false,
  enableTargets: false,
  handleSwitcherChange: () => {},
  hasStatistics: true,
  isDataFetching: false,
  targets: [],
};

TargetsTable.propTypes = {
  disableSwitch: PropTypes.bool,
  enableTargets: PropTypes.bool,
  handleSwitcherChange: PropTypes.func,
  hasStatistics: PropTypes.bool,
  isDataFetching: PropTypes.bool,
  targets: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      messageCount: PropTypes.number,
      officeTitle: PropTypes.string,
      totalActionsCount: PropTypes.number,
    })
  ),
};

export default TargetsTable;
