import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IconButton } from '@mui/material';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';

import { colors } from 'utils/constants';
import { Accordion } from 'common/components';
import Lists from '../Lists';

const StyledAccordion = styled(Accordion)`
  margin-top: 20px;
  background-color: ${colors.hawkesBlue};
`;

const GroupsWrapper = styled.div`
  margin-top: 20px;
`;

const StyledButton = styled(IconButton)`
  pointer-events: auto;
`;

const Groups = ({
  groups,
  isAddToGroupButonDisabled,
  listType,
  onDeleteGroup,
  onOpenAddToGroupModal,
  onShowImportListView,
  searchPhrase,
}) => (
  <GroupsWrapper>
    {Object.entries(groups)
      .map(([
        groupUuid,
        groupData,
      ]) => {
        const filteredLists = groupData.fields.filter(
          list => (list.type === listType || !listType) &&
          list?.name?.toLowerCase().includes((searchPhrase || '').toLowerCase())
        );

        return (
          <StyledAccordion
            disabled={!filteredLists?.length}
            key={groupUuid}
            options={(
              <div>
                <StyledButton
                  aria-label="delete"
                  onClick={() => onDeleteGroup(groupUuid, groupData)}
                  size="small"
                >
                  <DeleteOutlineIcon />
                </StyledButton>
              </div>
            )}
            title={`${groupData.name} (${filteredLists.length} lists)`}
          >
            <Lists
              isAddToGroupButonDisabled={isAddToGroupButonDisabled}
              lists={filteredLists}
              onOpenAddToGroupModal={onOpenAddToGroupModal}
              onShowImportListView={onShowImportListView}
            />
          </StyledAccordion>
        );
      })
  }
  </GroupsWrapper>
);

Groups.defaultProps = {
  listType: '',
};

Groups.propTypes = {
  groups: PropTypes.objectOf(PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    })),
    name: PropTypes.string,
  })).isRequired,
  isAddToGroupButonDisabled: PropTypes.bool.isRequired,
  listType: PropTypes.string,
  onDeleteGroup: PropTypes.func.isRequired,
  onOpenAddToGroupModal: PropTypes.func.isRequired,
  onShowImportListView: PropTypes.func.isRequired,
  searchPhrase: PropTypes.string.isRequired,
};

export default Groups;
