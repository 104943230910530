import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@mui/material';

const LinkText = ({
  className,
  disabled,
  name,
  to,
}) => (
  <Link
    component={RouterLink}
    className={className}
    disabled={disabled}
    to={to}
  >
    {name}
  </Link>
);

LinkText.defaultProps = {
  className: '',
  disabled: false,
};

LinkText.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default LinkText;
