import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  Card,
  CardContent,
} from '@mui/material';
import {
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import { deleteCustomField } from 'store/actions/customFields';

import {
  colors,
  fieldTypes,
} from 'utils/constants';
import mediaQueries from 'utils/mediaQueries';

const { small } = mediaQueries;

const CardActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 60%;
`;

const CardButton = styled(Button)`
  @media ${small} {
    width: 100%;
  }
`;

const RemoveButton = styled(CardButton)`
  background-color: ${colors.warning};
  color: ${colors.white};

  &:hover {
    opacity: 0.9;
    background-color: ${colors.warning};
  }
`;

const CardInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 40%;
`;

const ListCardElement = styled.div`
  display: flex;
  flex: 1 1 1px;
  align-items: center;
  font-weight: ${props => (props.$bold ? 'bold' : 'unset')};

  @media ${small} {
    flex: unset;
    justify-content: center;
    width: 100%;
  }
`;

const ConfirmationText = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  padding: 24px;
`;

const CustomFieldCard = ({
  className,
  customField,
  setEditedCustomField,
}) => {
  const {
    label,
    fieldType,
    staticList,
    uuid,
  } = customField;

  const [
    showConfirmDelete,
    setShowConfirmDelete,
  ] = useState(false);

  const dispatch = useDispatch();

  const handleDeleteCustomField = () => {
    dispatch(deleteCustomField(staticList, uuid));
  };

  const handleRenderType = type => {
    switch (type) {
      case fieldTypes.textField:
        return 'Text Field';
      case fieldTypes.singleChoiceField:
        return 'Choice';
      case fieldTypes.multipleChoiceField:
        return 'Multiple Choice';
      default:
        return '';
    }
  };

  return (
    <Card
      className={className}
      variant="outlined"
    >
      <StyledCardContent>
        <CardInfo>
          <ListCardElement $bold>{label}</ListCardElement>
          <ListCardElement>{handleRenderType(fieldType)}</ListCardElement>
        </CardInfo>
        <CardActions>
          {
            !showConfirmDelete ? (
              <>
                <CardButton
                  onClick={() => setShowConfirmDelete(true)}
                  startIcon={<DeleteOutlineIcon />}
                >
                  Delete
                </CardButton>
                <CardButton
                  onClick={() => setEditedCustomField({
                    ...customField,
                    isModalOpened: true,
                    options: customField.options.map(option => ({
                      label: option,
                      value: option,
                    })),
                  })}
                  startIcon={<EditIcon />}
                >
                  Edit
                </CardButton>
              </>
            ) : (
              <>
                <ConfirmationText>Are you sure?</ConfirmationText>
                <RemoveButton
                  onClick={handleDeleteCustomField}
                  startIcon={<DeleteOutlineIcon />}
                  variant="contained"
                >
                  Delete Field
                </RemoveButton>
                <Button onClick={() => setShowConfirmDelete(false)}>
                  Cancel
                </Button>
              </>
            )
          }
        </CardActions>
      </StyledCardContent>
    </Card>
  );
};

CustomFieldCard.propTypes = {
  className: PropTypes.string.isRequired,
  customField: PropTypes.shape({
    fieldType: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    staticList: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  setEditedCustomField: PropTypes.func.isRequired,
};

export default CustomFieldCard;
