import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import {
  BreadcrumbsNavigation,
  Container,
} from 'common/components';

import CampaignsTable from '../components/CampaignsTable';
import CampaignsTopCard from '../components/CampaignsTopCard';

const Campaigns = () => {
  const { organizationUuid } = useParams();

  const breadcrumbs = useMemo(() => [
    {
      link: `/${organizationUuid}/campaigns`,
      name: 'Advocacy',
    },
  ], [organizationUuid]);

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container>
        <Grid container>
          <CampaignsTopCard />
        </Grid>
        <Grid container>
          <CampaignsTable />
        </Grid>
      </Container>
    </>
  );
};

export default Campaigns;
