import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import {
  fetchChildrenLists,
  fetchRootList,
} from 'store/actions/contacts';
import { contactSelectors } from 'store/selectors/contacts';
import { colors } from 'utils/constants';

const StyledTypography = styled.ul`
  padding-left: 0;
`;

const InfoWrapper = styled.div`
  margin-bottom: 5px;
  padding: 5px;
  background-color: ${colors.backgroundColor};
`;

const ListInfoDialog = ({
  isListInfoOpen,
  listDetails: {
    listFilters,
    newListsOperator,
    staticLists = [],
    staticListsOperator,
  },
  organizationUuid,
  setListInfoOpen,
}) => {
  const dispatch = useDispatch();

  const {
    childrenLists,
    rootList,
  } = useSelector(state => ({
    childrenLists: contactSelectors.getChildrenLists(state)[organizationUuid] || [],
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  useEffect(() => {
    if (Object.keys(rootList)) {
      dispatch(fetchRootList(organizationUuid)).then(data => {
        if (childrenLists.length === 0) {
          dispatch(fetchChildrenLists(data.uuid, organizationUuid));
        }
      });
    }
  }, []);

  return (
    <Dialog
      fullWidth
      open={isListInfoOpen}
      onClose={() => setListInfoOpen(false)}
    >
      <DialogTitle>Filters Information</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Source Contact Lists</Typography>
        <Typography variant="subtitle2">
          {staticListsOperator === 'AND' ?
            'Contacts must be members of ALL of these lists:' :
            'Contacts must be members of at least ONE of these lists:'
          }
        </Typography>
        <>
          {staticLists.map(staticList => {
            const {
              name = '',
              uuid,
            } = staticList;

            return (
              <InfoWrapper key={uuid}>
                <StyledTypography variant="subtitle1">
                  {name}
                </StyledTypography>
              </InfoWrapper>
            );
          })}
        </>
        <Typography variant="h6">Filter Criteria</Typography>
        <Typography variant="subtitle2">
          {newListsOperator === 'AND' ?
            'Contacts must match ALL of these filters:' :
            'Contacts must match at least ONE of these filters:'
          }
        </Typography>
        {listFilters && listFilters.map(filter => (
          <InfoWrapper key={filter}>
            <p>
              {filter}
            </p>
          </InfoWrapper>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => setListInfoOpen(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ListInfoDialog.propTypes = {
  isListInfoOpen: PropTypes.bool.isRequired,
  listDetails: PropTypes.shape({
    listFilters: PropTypes.arrayOf(PropTypes.string),
    newListsOperator: PropTypes.string,
    staticLists: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      uuid: PropTypes.string,
    })),
    staticListsOperator: PropTypes.string,
  }).isRequired,
  organizationUuid: PropTypes.string.isRequired,
  setListInfoOpen: PropTypes.func.isRequired,
};

export default ListInfoDialog;
