import request from '../../utils/request';

const PREFIX = '[CONTACT_DETAILS]';

export const FETCH_CONTACT_REQUEST = `${PREFIX} FETCH_CONTACT_REQUEST`;
export const FETCH_CONTACT_SUCCESS = `${PREFIX} FETCH_CONTACT_SUCCESS`;
export const FETCH_CONTACT_FAILURE = `${PREFIX} FETCH_CONTACT_FAILURE`;

const fetchContactRequest = () => ({
  type: FETCH_CONTACT_REQUEST,
});

const fetchContactRequestSuccess = payload => ({
  payload,
  type: FETCH_CONTACT_SUCCESS,
});

const fetchContactRequestFailure = payload => ({
  payload,
  type: FETCH_CONTACT_FAILURE,
});

export const fetchContact = (listUuid, contactUuid) => dispatch => {
  dispatch(fetchContactRequest());

  return request
    .get(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/`)
    .then(({ data }) => {
      dispatch(fetchContactRequestSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchContactRequestFailure(data));

      throw data;
    });
};

export const ADD_NEW_EMAIL_REQUEST = `${PREFIX} ADD_NEW_EMAIL_REQUEST`;
export const ADD_NEW_EMAIL_SUCCESS = `${PREFIX} ADD_NEW_EMAIL_SUCCESS`;
export const ADD_NEW_EMAIL_FAILURE = `${PREFIX} ADD_NEW_EMAIL_FAILURE`;

const addEmailRequest = () => ({
  type: ADD_NEW_EMAIL_REQUEST,
});

const addEmailSuccess = payload => ({
  payload,
  type: ADD_NEW_EMAIL_SUCCESS,
});

const addEmailFailure = payload => ({
  payload,
  type: ADD_NEW_EMAIL_FAILURE,
});

export const addEmail = (listUuid, contactUuid, params) => dispatch => {
  dispatch(addEmailRequest());

  return request
    .post(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/emails/`, params)
    .then(({ data }) => {
      dispatch(addEmailSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(addEmailFailure(data));

      throw data;
    });
};

export const UPDATE_EMAIL_REQUEST = `${PREFIX} UPDATE_EMAIL_REQUEST`;
export const UPDATE_EMAIL_SUCCESS = `${PREFIX} UPDATE_EMAIL_SUCCESS`;
export const UPDATE_EMAIL_FAILURE = `${PREFIX} UPDATE_EMAIL_FAILURE`;

const updateEmailRequest = () => ({
  type: UPDATE_EMAIL_REQUEST,
});

const updateEmailSuccess = payload => ({
  payload,
  type: UPDATE_EMAIL_SUCCESS,
});

const updateEmailFailure = payload => ({
  payload,
  type: UPDATE_EMAIL_FAILURE,
});

export const updateEmail = (listUuid, contactUuid, emailUuid, params) => dispatch => {
  dispatch(updateEmailRequest);

  return request
    .put(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/emails/${emailUuid}/`, params)
    .then(({ data }) => {
      dispatch(updateEmailSuccess({
        data,
        emailUuid,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(updateEmailFailure(data));

      throw data;
    });
};

export const DELETE_EMAIL_REQUEST = `${PREFIX} DELETE_EMAIL_REQUEST`;
export const DELETE_EMAIL_SUCCESS = `${PREFIX} DELETE_EMAIL_SUCCESS`;
export const DELETE_EMAIL_FAILURE = `${PREFIX} DELETE_EMAIL_FAILURE`;

const deleteEmailRequest = () => ({
  type: DELETE_EMAIL_REQUEST,
});

const deleteEmailSuccess = payload => ({
  payload,
  type: DELETE_EMAIL_SUCCESS,
});

const deleteEmailFailure = payload => ({
  payload,
  type: DELETE_EMAIL_FAILURE,
});

export const deleteEmail = (listUuid, contactUuid, emailUuid) => dispatch => {
  dispatch(deleteEmailRequest());

  return request
    .delete(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/emails/${emailUuid}/`)
    .then(({ data }) => {
      dispatch(deleteEmailSuccess(emailUuid));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteEmailFailure(data));

      throw data;
    });
};

export const ADD_PHONE_NUMBER_REQUEST = `${PREFIX} ADD_PHONE_NUMBER_REQUEST`;
export const ADD_PHONE_NUMBER_SUCCESS = `${PREFIX} ADD_PHONE_NUMBER_SUCCESS`;
export const ADD_PHONE_NUMBER_FAILURE = `${PREFIX} ADD_PHONE_NUMBER_FAILURE`;

const addPhoneNumberRequest = () => ({
  type: ADD_PHONE_NUMBER_REQUEST,
});

const addPhoneNumberSuccess = payload => ({
  payload,
  type: ADD_PHONE_NUMBER_SUCCESS,
});

const addPhoneNumberFailure = payload => ({
  payload,
  type: ADD_PHONE_NUMBER_FAILURE,
});

export const addPhoneNumber = (listUuid, contactUuid, params) => dispatch => {
  dispatch(addPhoneNumberRequest);

  return request
    .post(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/phone_numbers/`, params)
    .then(({ data }) => {
      dispatch(addPhoneNumberSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(addPhoneNumberFailure(data));

      throw data;
    });
};

export const UPDATE_PHONE_NUMBER_REQUEST = `${PREFIX} UPDATE_PHONE_NUMBER_REQUEST`;
export const UPDATE_PHONE_NUMBER_SUCCESS = `${PREFIX} UPDATE_PHONE_NUMBER_SUCCESS`;
export const UPDATE_PHONE_NUMBER_FAILURE = `${PREFIX} UPDATE_PHONE_NUMBER_FAILURE`;

const updatePhoneNumberRequest = () => ({
  type: UPDATE_PHONE_NUMBER_REQUEST,
});

const updatePhoneNumberSuccess = payload => ({
  payload,
  type: UPDATE_PHONE_NUMBER_SUCCESS,
});

const updatePhoneNumberFailure = payload => ({
  payload,
  type: UPDATE_PHONE_NUMBER_FAILURE,
});

export const updatePhoneNumber = (listUuid, contactUuid, phoneUuid, params) => dispatch => {
  dispatch(updatePhoneNumberRequest);

  return request
    .put(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/phone_numbers/${phoneUuid}/`, params)
    .then(({ data }) => {
      dispatch(updatePhoneNumberSuccess({
        data,
        phoneUuid,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(updatePhoneNumberFailure(data));

      throw data;
    });
};

export const DELETE_PHONE_NUMBER_REQUEST = `${PREFIX} DELETE_PHONE_NUMBER_REQUEST`;
export const DELETE_PHONE_NUMBER_SUCCESS = `${PREFIX} DELETE_PHONE_NUMBER_SUCCESS`;
export const DELETE_PHONE_NUMBER_FAILURE = `${PREFIX} DELETE_PHONE_NUMBER_FAILURE`;

const deletePhoneNumberRequest = () => ({
  type: DELETE_PHONE_NUMBER_REQUEST,
});

const deletePhoneNumberSuccess = payload => ({
  payload,
  type: DELETE_PHONE_NUMBER_SUCCESS,
});

const deletePhoneNumberFailure = payload => ({
  payload,
  type: DELETE_PHONE_NUMBER_FAILURE,
});

export const deletePhoneNumber = (listUuid, contactUuid, phoneUuid) => dispatch => {
  dispatch(deletePhoneNumberRequest());

  return request
    .delete(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/phone_numbers/${phoneUuid}/`)
    .then(({ data }) => {
      dispatch(deletePhoneNumberSuccess(phoneUuid));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deletePhoneNumberFailure(data));

      throw data;
    });
};

export const ADD_LOCATION_REQUEST = `${PREFIX} ADD_LOCATION_REQUEST`;
export const ADD_LOCATION_SUCCESS = `${PREFIX} ADD_LOCATION_SUCCESS`;
export const ADD_LOCATION_FAILURE = `${PREFIX} ADD_LOCATION_FAILURE`;

const addLocationRequest = () => ({
  type: ADD_LOCATION_REQUEST,
});

const addLocationSuccess = payload => ({
  payload,
  type: ADD_LOCATION_SUCCESS,
});

const addLocationFailure = payload => ({
  payload,
  type: ADD_LOCATION_FAILURE,
});

export const addLocation = (listUuid, contactUuid, params) => dispatch => {
  dispatch(addLocationRequest);

  return request
    .post(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/locations/`, params)
    .then(({ data }) => {
      dispatch(addLocationSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(addLocationFailure(data));

      throw data;
    });
};

export const UPDATE_LOCATION_REQUEST = `${PREFIX} UPDATE_LOCATION_REQUEST`;
export const UPDATE_LOCATION_SUCCESS = `${PREFIX} UPDATE_LOCATION_SUCCESS`;
export const UPDATE_LOCATION_FAILURE = `${PREFIX} UPDATE_LOCATION_FAILURE`;

const updateLocationRequest = () => ({
  type: UPDATE_LOCATION_REQUEST,
});

const updateLocationSuccess = payload => ({
  payload,
  type: UPDATE_LOCATION_SUCCESS,
});

const updateLocationFailure = payload => ({
  payload,
  type: UPDATE_LOCATION_FAILURE,
});

export const updateLocation = (listUuid, contactUuid, locationUuid, params) => dispatch => {
  dispatch(updateLocationRequest);

  return request
    .put(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/locations/${locationUuid}/`, params)
    .then(({ data }) => {
      dispatch(updateLocationSuccess({
        data,
        locationUuid,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(updateLocationFailure(data));

      throw data;
    });
};

export const DELETE_LOCATION_REQUEST = `${PREFIX} DELETE_LOCATION_REQUEST`;
export const DELETE_LOCATION_SUCCESS = `${PREFIX} DELETE_LOCATION_SUCCESS`;
export const DELETE_LOCATION_FAILURE = `${PREFIX} DELETE_LOCATION_FAILURE`;

const deleteLocationRequest = () => ({
  type: DELETE_LOCATION_REQUEST,
});

const deleteLocationSuccess = payload => ({
  payload,
  type: DELETE_LOCATION_SUCCESS,
});

const deleteLocationFailure = payload => ({
  payload,
  type: DELETE_LOCATION_FAILURE,
});

export const deleteLocation = (listUuid, contactUuid, locationUuid) => dispatch => {
  dispatch(deleteLocationRequest());

  return request
    .delete(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/locations/${locationUuid}/`)
    .then(({ data }) => {
      dispatch(deleteLocationSuccess(locationUuid));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteLocationFailure(data));

      throw data;
    });
};

export const FETCH_FEDERAL_POSITIONS_REQUEST = `${PREFIX} FETCH_FEDERAL_POSITIONS_REQUEST`;
export const FETCH_FEDERAL_POSITIONS_SUCCESS = `${PREFIX} FETCH_FEDERAL_POSITIONS_SUCCESS`;
export const FETCH_FEDERAL_POSITIONS_FAILURE = `${PREFIX} FETCH_FEDERAL_POSITIONS_FAILURE`;

const fetchFederalPositionsRequest = () => ({
  type: FETCH_FEDERAL_POSITIONS_REQUEST,
});

const fetchFederalPositionsSuccess = payload => ({
  payload,
  type: FETCH_FEDERAL_POSITIONS_SUCCESS,
});

const fetchFederalPositionsFailure = payload => ({
  payload,
  type: FETCH_FEDERAL_POSITIONS_FAILURE,
});

export const fetchFederalPositions = params => dispatch => {
  let requestUrl = null;

  if (Object.keys(params).length > 1) {
    requestUrl = `/v1/officials/positions/?category=${params.category}&state=${params.state}`;
  } else {
    requestUrl = `/v1/officials/positions/?category=${params.category}`;
  }

  dispatch(fetchFederalPositionsRequest());

  return request
    .get(requestUrl)
    .then(({ data }) => {
      dispatch(fetchFederalPositionsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchFederalPositionsFailure(data));

      throw data;
    });
};

export const FETCH_POSITION_TERMS_REQUEST = `${PREFIX} FETCH_POSITION_TERMS_REQUEST`;
export const FETCH_POSITION_TERMS_SUCCESS = `${PREFIX} FETCH_POSITION_TERMS_SUCCESS`;
export const FETCH_POSITION_TERMS_FAILURE = `${PREFIX} FETCH_POSITION_TERMS_FAILURE`;

const fetchPositionTermsRequest = () => ({
  type: FETCH_POSITION_TERMS_REQUEST,
});

const fetchPositionTermsSuccess = payload => ({
  payload,
  type: FETCH_POSITION_TERMS_SUCCESS,
});

const fetchPositionTermsFailure = payload => ({
  payload,
  type: FETCH_POSITION_TERMS_FAILURE,
});

export const fetchPositionTerms = params => dispatch => {
  let requestUrl = null;

  if (Object.keys(params).length > 1) {
    requestUrl = `/v1/officials/terms/?position=${params.position}&state=${params.state}`;
  } else {
    requestUrl = `/v1/officials/terms/?position=${params.position}`;
  }

  dispatch(fetchPositionTermsRequest());

  return request
    .get(requestUrl)
    .then(({ data }) => {
      dispatch(fetchPositionTermsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchPositionTermsFailure(data));

      throw data;
    });
};

export const ADD_OFFICIAL_REQUEST = `${PREFIX} ADD_OFFICIAL_REQUEST`;
export const ADD_OFFICIAL_SUCCESS = `${PREFIX} ADD_OFFICIAL_SUCCESS`;
export const ADD_OFFICIAL_FAILURE = `${PREFIX} ADD_OFFICIAL_FAILURE`;

const addOfficialRequest = () => ({
  type: ADD_OFFICIAL_REQUEST,
});

const addOfficialSuccess = payload => ({
  payload,
  type: ADD_OFFICIAL_SUCCESS,
});

const addOfficialFailure = payload => ({
  payload,
  type: ADD_OFFICIAL_FAILURE,
});

export const addOfficial = (listId, contactId, params) => dispatch => {
  dispatch(addOfficialRequest());

  return request
    .post(`/v1/contacts/lists/${listId}/contacts/${contactId}/officials/`, params)
    .then(({ data }) => {
      dispatch(addOfficialSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(addOfficialFailure(data));

      throw data;
    });
};

export const DELETE_OFFICIAL_REQUEST = `${PREFIX} DELETE_OFFICIAL_REQUEST`;
export const DELETE_OFFICIAL_SUCCESS = `${PREFIX} DELETE_OFFICIAL_SUCCESS`;
export const DELETE_OFFICIAL_FAILURE = `${PREFIX} DELETE_OFFICIAL_FAILURE`;

const deleteOfficialRequest = () => ({
  type: DELETE_OFFICIAL_REQUEST,
});

const deleteOfficialSuccess = payload => ({
  payload,
  type: DELETE_OFFICIAL_SUCCESS,
});

const deleteOfficialFailure = payload => ({
  payload,
  type: DELETE_OFFICIAL_FAILURE,
});

export const deleteOfficial = (listId, contactId, officialId) => dispatch => {
  dispatch(deleteOfficialRequest());

  return request
    .delete(`/v1/contacts/lists/${listId}/contacts/${contactId}/officials/${officialId}/`)
    .then(({ data }) => {
      dispatch(deleteOfficialSuccess(officialId));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteOfficialFailure(data));

      throw data;
    });
};

export const ADD_CUSTOM_FIELD_REQUEST = `${PREFIX} ADD_CUSTOM_FIELD_REQUEST`;
export const ADD_CUSTOM_FIELD_SUCCESS = `${PREFIX} ADD_CUSTOM_FIELD_SUCCESS`;
export const ADD_CUSTOM_FIELD_FAILURE = `${PREFIX} ADD_CUSTOM_FIELD_FAILURE`;

const addCustomFieldRequest = () => ({
  type: ADD_CUSTOM_FIELD_REQUEST,
});

const addCustomFieldSuccess = payload => ({
  payload,
  type: ADD_CUSTOM_FIELD_SUCCESS,
});

const addCustomFieldFailure = payload => ({
  payload,
  type: ADD_CUSTOM_FIELD_FAILURE,
});

export const addCustomField = (listUuid, contactUuid, fieldLabel, params) => dispatch => {
  dispatch(addCustomFieldRequest);

  return request
    .post(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/contact_custom_fields/`, params)
    .then(({ data }) => {
      dispatch(addCustomFieldSuccess({
        ...data,
        label: fieldLabel,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(addCustomFieldFailure(data));

      throw data;
    });
};

export const DELETE_CUSTOM_FIELD_REQUEST = `${PREFIX} DELETE_CUSTOM_FIELD_REQUEST`;
export const DELETE_CUSTOM_FIELD_SUCCESS = `${PREFIX} DELETE_CUSTOM_FIELD_SUCCESS`;
export const DELETE_CUSTOM_FIELD_FAILURE = `${PREFIX} DELETE_CUSTOM_FIELD_FAILURE`;

const deleteCustomFieldRequest = () => ({
  type: DELETE_CUSTOM_FIELD_REQUEST,
});

const deleteCustomFieldSuccess = payload => ({
  payload,
  type: DELETE_CUSTOM_FIELD_SUCCESS,
});

const deleteCustomFieldFailure = payload => ({
  payload,
  type: DELETE_CUSTOM_FIELD_FAILURE,
});

export const deleteCustomField = (listUuid, contactUuid, customFieldUuid) => dispatch => {
  dispatch(deleteCustomFieldRequest());

  return request
    .delete(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/contact_custom_fields/${customFieldUuid}/`)
    .then(({ data }) => {
      dispatch(deleteCustomFieldSuccess(customFieldUuid));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteCustomFieldFailure(data));

      throw data;
    });
};

export const UPDATE_CUSTOM_FIELD_REQUEST = `${PREFIX} UPDATE_CUSTOM_FIELD_REQUEST`;
export const UPDATE_CUSTOM_FIELD_SUCCESS = `${PREFIX} UPDATE_CUSTOM_FIELD_SUCCESS`;
export const UPDATE_CUSTOM_FIELD_FAILURE = `${PREFIX} UPDATE_CUSTOM_FIELD_FAILURE`;

const updateCustomFieldRequest = () => ({
  type: UPDATE_CUSTOM_FIELD_REQUEST,
});

const updateCustomFieldSuccess = payload => ({
  payload,
  type: UPDATE_CUSTOM_FIELD_SUCCESS,
});

const updateCustomFieldFailure = payload => ({
  payload,
  type: UPDATE_CUSTOM_FIELD_FAILURE,
});

export const updateCustomField = (listUuid, contactUuid, customFieldUuid, params) => dispatch => {
  dispatch(updateCustomFieldRequest);

  return request
    .put(`/v1/contacts/lists/${listUuid}/contacts/${contactUuid}/contact_custom_fields/${customFieldUuid}/`, params)
    .then(({ data }) => {
      dispatch(updateCustomFieldSuccess({
        customFieldUuid,
        data,
      }));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(updateCustomFieldFailure(data));

      throw data;
    });
};

export const DELETE_POSITION_TERMS = `${PREFIX} DELETE_POSITION_TERMS`;

export const clearPostionTerms = payload => ({
  payload,
  type: DELETE_POSITION_TERMS,
});
