export const broadcastsSelectors = {
  getAllBroadcastsListData: state => state.broadcasts.allBroadcasts.results,
  getBroadcastDetails: state => state.broadcasts.broadcastDetails,
  getBroadcastLinks: state => state.broadcasts.broadcastLinks,
  getBroadcastRecipients: state => state.broadcasts.broadcastRecipients,
  getBroadcastsListData: state => state.broadcasts.broadcasts.results,
  getEditingBroadcastDetails: state => state.broadcasts.editingBroadcastDetails,
  getTextBroadcastDetails: state => state.broadcasts.textBroadcastDetails,
  isBroadcastFetching: state => state.broadcasts.isBroadcastFetching,
  isBroadcastLinksFetching: state => state.broadcasts.isBroadcastLinksFetching,
  isBroadcastRecipientsFetching: state => state.broadcasts.isBroadcastRecipientsFetching,
  isBroadcastsFetching: state => state.broadcasts.isBroadcastsFetching,
  isPending: state => state.broadcasts.isPending,
};
