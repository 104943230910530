import PropTypes from 'prop-types';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import {
  Email as EmailIcon,
  MailOutline as MailOutlineIcon,
  Sms as SmsIcon,
} from '@mui/icons-material';

import { organizationSelectors } from 'store/selectors/organizations';

import ButtonMenu from 'common/components/ButtonMenu';

const ActionAlertMenuButton = ({
  actionCenterUuid,
  campaignUuid,
  isDisabled = false,
}) => {
  const { organizationUuid } = useParams();
  const history = useHistory();

  const {
    organization: {
      flags,
    },
  } = useSelector(state => ({
    organization: organizationSelectors.getOrganization(state),
  }));

  const handleNavigateToWizard = type => {
    if (!isDisabled && flags?.showActionAlerts) {
      history.push(`/${organizationUuid}/${campaignUuid}/${actionCenterUuid}/create-${type}-action-alert/`);
    }
  };

  return (
    <ButtonMenu
      color="primary"
      disabled={isDisabled}
      startIcon={<MailOutlineIcon />}
      variant="contained"
      name="New Action Alert"
    >
      <MenuItem
        disabled={!flags?.showActionAlerts}
        onClick={() => handleNavigateToWizard('email')}
      >
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="Create Email Action Alert" />
      </MenuItem>
      <MenuItem
        disabled={!flags?.showActionAlerts || !flags?.showTexting}
        onClick={() => handleNavigateToWizard('text')}
      >
        <ListItemIcon>
          <SmsIcon />
        </ListItemIcon>
        <ListItemText primary="Create Text Action Alert" />
      </MenuItem>
    </ButtonMenu>
  );
};

ActionAlertMenuButton.propTypes = {
  actionCenterUuid: PropTypes.string.isRequired,
  campaignUuid: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default ActionAlertMenuButton;
