import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Card,
  CardContent,
  Typography,
} from '@mui/material';

const Percentage = styled.span`
  bottom: 8px;
  left: 5px;
  font-size: 12px;
`;

const Tiles = styled(Card)`
  width: max-content;
`;

const Tile = styled.div`
  padding-right: 24px;
  color: ${({ theme }) => (theme.colors.lightBlack)};

  &:not(:first-child) {
    padding-left: 24px;
  }
`;

const TileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  color: ${({ theme }) => (theme.colors.text)};
`;

const IconWrapper = styled.div`
  border-radius: 4px;
  padding: 8px 8px 0;
  background-color: ${({ $backgroundColor }) => ($backgroundColor || '#F5F5F6')};
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  padding: 24px;
  text-align: center;

  & > div:not(:last-child) {
    border-right: 1px solid ${({ theme }) => (theme.colors.borderColor2)};
  }
`;

const StyledCount = styled(Typography)`
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  letter-spacing: 0;
`;

const StatisticTiles = ({
  tiles,
}) => (
  <Tiles
    variant="outlined"
    sx={{
      borderRadius: 2,
    }}
  >
    <StyledCardContent>
      {tiles?.map(tile => (
        <Tile key={tile.name}>
          <TileHeader>
            {tile.icon && (
              <IconWrapper $backgroundColor={tile.iconColor}>
                {tile.icon}
              </IconWrapper>
            )}
            <Typography variant="subtitle1">{tile.name}</Typography>
          </TileHeader>
          <div>
            <StyledCount>{tile.count}</StyledCount>
            {tile.percentage && (
              <Percentage>
                {tile.percentage.toFixed(1)}
                %
              </Percentage>
            )}
          </div>
        </Tile>
      ))}
    </StyledCardContent>
  </Tiles>
);

StatisticTiles.propTypes = {
  tiles: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number,
    icon: PropTypes.node,
    iconColor: PropTypes.string,
    name: PropTypes.string,
    percentage: PropTypes.number,
  })),
};

export default StatisticTiles;
