import PropTypes from 'prop-types';
import React from 'react';
import Button from '@mui/material/Button';

import {
  CheckCircleOutline as ApprovedIcon,
  CommentOutlined as CommentIcon,
  DangerousOutlined as DangerIcon,
  EmojiFlagsOutlined as FlaggedIcon,
} from '@mui/icons-material';

import styled from 'styled-components';
import { actionTakenMessageStatus } from 'utils/constants';
import { colors } from 'themes';

const {
  flaggedMessage,
  pendingMessage,
  quarantinedMessage,
  rejectedMessage,
} = actionTakenMessageStatus;

const ActionRequiredButton = styled(Button)`
  background-color: ${colors.yellowWarning};

  &:hover {
    background-color: rgb(132 105 1);
  }
`;

const RejectedButton = styled(Button)`
  background-color: ${colors.warning};

  &:hover {
    background-color: rgb(132 105 1);
  }
`;

const ApprovedButton = styled(Button)`
  background-color: ${colors.secondary};

  &:hover {
    background-color: rgb(72 225 22);
  }
`;

const ActionDetailButton = ({
  rowData,
  setAction,
  showMessageApproval,
}) => {
  const handleClick = () => {
    setAction({
      messageStatus: rowData.messageStatus,
      type: rowData.type,
      uuid: rowData.uuid,
    });
  };

  const isActionRequired = [
    pendingMessage,
    flaggedMessage,
  ].some(status => rowData.messageStatus === status);

  const isActionRejected = [
    quarantinedMessage,
    rejectedMessage,
  ].some(status => rowData.messageStatus === status);

  let ButtonComponent = Button;
  let IconComponent = CommentIcon;
  let buttonText = 'Details';

  if (showMessageApproval) {
    if (isActionRequired) {
      ButtonComponent = ActionRequiredButton;
      IconComponent = FlaggedIcon;
      buttonText = 'Pending';
    } else if (isActionRejected) {
      ButtonComponent = RejectedButton;
      IconComponent = DangerIcon;
      buttonText = 'Rejected';
    } else {
      ButtonComponent = ApprovedButton;
      IconComponent = ApprovedIcon;
      buttonText = 'Approved';
    }
  }

  return (
    <ButtonComponent
      onClick={handleClick}
      disableElevation
      startIcon={<IconComponent />}
      variant="contained"
    >
      {buttonText}
    </ButtonComponent>
  );
};

ActionDetailButton.propTypes = {
  rowData: PropTypes.shape({
    messageStatus: PropTypes.string,
    type: PropTypes.string,
    uuid: PropTypes.string,
  }),
  setAction: PropTypes.func.isRequired,
  showMessageApproval: PropTypes.bool.isRequired,
};

export default ActionDetailButton;
