import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Link as RouterLink,
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { PeopleAlt as PeopleAltIcon } from '@mui/icons-material';

import {
  deleteSignup,
  fetchSignup,
  patchSignup,
} from 'store/actions/signups';
import { signupsSelectors } from 'store/selectors/signups';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';

import { STOPPED_PUBLISH_STATUS } from 'utils/constants';
import {
  nameOfPublishStatus,
  showFirstErrorMessage,
  useQuery,
} from 'utils/helpers';
import { useCopyToClipboard } from 'utils/hooks';
import {
  Backdrop,
  BreadcrumbsNavigation,
  Container,
  DescriptionBar,
  TabPanel,
} from 'common/components';
import { StyledCode } from 'globalStyles';

import SettingsButton from '../components/SettingsButton';

const {
  REACT_APP_LANDING_PAGE_URL,
  REACT_APP_SIGNUP_WIDGET_URL,
} = process.env;

const LinksWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const StyledButton = styled(Button)`
  margin: 10px 10px 0 0;
`;

const signupTabs = [
  {
    label: 'Overview',
    value: '0',
  },
  {
    label: 'Widget',
    value: '1',
  },
];

const SignupDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();
  const [copy] = useCopyToClipboard();
  const query = useQuery();
  const tabIndex = query.get('tab');

  const {
    isFetching,
  } = useSelector(state => ({
    isFetching: signupsSelectors.isFetching(state),
  }));

  const {
    organizationUuid,
    signupUuid,
  } = useParams();
  const [
    signupData,
    setSignupData,
  ] = useState({});

  const {
    name,
    staticList,
  } = signupData;

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/contacts/lists`,
      name: 'Lists',
    },
    {
      link: `/${organizationUuid}/signups`,
      name: 'Contact Forms',
    },
    {
      link: '',
      name,
    },
  ];

  useEffect(() => {
    dispatch(fetchSignup(signupUuid))
      .then(data => {
        setSignupData(data);
      });

    if (!tabIndex) {
      history.push({
        search: `?tab=${signupTabs[0].value}`,
      });
    }
  }, []);

  const widgetCode = `
      <div
        data-sign-up-id="${signupData.uuid}"
        data-domain="${REACT_APP_LANDING_PAGE_URL}/signup-widget"
        data-height="1000"
        data-width="455"
        id="muster-signup"
      >
        <script src="${REACT_APP_SIGNUP_WIDGET_URL}"></script>
      </div>
  `;

  const handleDeleteSignup = () => {
    confirm({
      confirmationText: 'Remove',
      title: 'Remove Contact Form?',
    })
      .then(() => dispatch(deleteSignup(signupUuid)))
      .then(() => dispatch(setSnackbarSuccess()))
      .then(() => history.push(`/${organizationUuid}/signups`))
      .catch(error => {
        if (error) {
          dispatch(setSnackbarError({
            message: showFirstErrorMessage(error),
          }));
        }
      });
  };

  const handleStopSignup = () => {
    confirm({
      confirmationText: 'Stop',
      title: 'Do you want to stop this Contact Form?',
    })
      .then(() => dispatch(patchSignup(
        signupUuid,
        { publishStatus: STOPPED_PUBLISH_STATUS }
      )))
      .then(() => dispatch(fetchSignup(signupUuid)))
      .then(data => setSignupData(data))
      .catch(error => {
        if (error) {
          dispatch(setSnackbarError({
            message: showFirstErrorMessage(error),
          }));
        }
      });
  };

  const settingsButton = () => {
    if (!Object.keys(signupData).length) {
      return null;
    }

    return (
      <SettingsButton
        handleEditSignup={() => history.push(`/${organizationUuid}/create-signup/${signupUuid}`)}
        handleStopSignup={handleStopSignup}
        handleDeleteSignup={handleDeleteSignup}
        publishStatus={signupData.publishStatus}
      />
    );
  };

  return (
    <>
      <BreadcrumbsNavigation
        isLoading={isFetching}
        links={breadcrumbs}
      />
      <Container marginBottom="40">
        <Grid container>
          <DescriptionBar
            backButtonLink={`/${organizationUuid}/signups`}
            chipLabel={nameOfPublishStatus(signupData.publishStatus)}
            hasBackButton
            hasTabs
            tabs={signupTabs}
            isWarningChip={signupData.publishStatus !== 2}
            isHighBar
            icon={PeopleAltIcon}
            name={name}
            settingsButton={settingsButton}
          />
          <TabPanel
            index="0"
            value={tabIndex}
          >
            {signupData.publishStatus === 2 && (
              <LinksWrapper container>
                <StyledButton
                  color="secondary"
                  disableElevation
                  variant="contained"
                  onClick={() => copy(`${REACT_APP_LANDING_PAGE_URL}/signup/${signupData.uuid}`)}
                >
                  Copy Sign Up Link
                </StyledButton>
                Or
                &nbsp;
                <a
                  href={`${REACT_APP_LANDING_PAGE_URL}/signup/${signupData.uuid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to Contact Form
                </a>
              </LinksWrapper>
            )}
            {!!staticList && (
              <Typography>
                <span>Go to the contact list for this Contact Form: </span>
                <Link
                  component={RouterLink}
                  color="inherit"
                  to={`/${organizationUuid}/contacts/lists/${staticList}`}
                >
                  List
                </Link>
              </Typography>
            )}
          </TabPanel>
          <TabPanel
            index="1"
            value={tabIndex}
          >
            <div>
              <div>
                <Typography>Widget Code Snippet</Typography>
              </div>
              <StyledCode>
                {widgetCode}
              </StyledCode>
              <StyledButton
                color="secondary"
                disableElevation
                variant="contained"
                onClick={() => copy(widgetCode, 'textarea', 'Code')}
              >
                Copy Code
              </StyledButton>
            </div>
          </TabPanel>
        </Grid>
      </Container>
      <Backdrop isOpen={isFetching} />
    </>
  );
};

export default SignupDetails;
