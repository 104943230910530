import PropTypes from 'prop-types';
import styled from 'styled-components';

import MaterialTable, { MTableCell } from '@material-table/core';
import {
  Checkbox,
  Paper,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { TableIcon } from 'common/components';
import {
  targetsColumns as baseTargetsColumns,
  colors,
} from 'utils/constants';

const StyledPaper = styled(Paper)`
  width: 100%;

  /* stylelint-disable-next-line selector-class-pattern */
  .MuiTableSortLabel-icon {
    opacity: 1;
  }
`;

const TargetsSelectionTable = ({
  handleClickActionIcon,
  handleSelectAllTargets,
  hasDeleteButton,
  hasSaveTargetsButton,
  mappedTargetsUuid,
  mappedMessageTabIndex,
  phoneActionCenter,
  targets,
}) => {
  const targetsColumns = [
      ...(mappedTargetsUuid ? [
        {
          field: 'mappedMessage',
          render: rowData => (
            <Checkbox
              checked={
                rowData.mappedMessage === mappedTargetsUuid ||
                (mappedMessageTabIndex === 0 && !rowData.mappedMessage)
              }
              onChange={
                event => handleClickActionIcon(event.target.checked, rowData.tableData.index)
              }
            />
          ),
          title: '',
        },
      ] : []),
      ...baseTargetsColumns,
      ...(phoneActionCenter ? [
        {
          field: 'phone',
          render: rowData => (
            <TableIcon
              boolean={!!rowData.phone}
            />
          ),
          title: 'Phone',
        },
      ] : []),
    ];

  const formattedTargets = targets && targets.map(target => {
    const targetCopy = { ...target };

    if (targetCopy.official) {
      targetCopy.fullName = `
      ${targetCopy.official.firstName}
      ${targetCopy.official.middleName}
      ${targetCopy.official.lastName}
    `;
    } else {
      targetCopy.fullName = `
      ${targetCopy.firstName}
      ${targetCopy.middleName || ''}
      ${targetCopy.lastName}
    `;
    }

    if (!targetCopy.office) {
      targetCopy.office = targetCopy.officeTitle;
    }

    targetCopy.id = targetCopy.term || targetCopy.uuid;

    return targetCopy;
  });

  const actions = [];

  if (hasDeleteButton) {
    actions.push({
      icon: 'delete',
      onClick: (_, data) => handleClickActionIcon(data, 'remove'),
      tooltip: 'Remove All Selected Targets',
    });
  }

  if (hasSaveTargetsButton) {
    actions.push({
      icon: 'save',
      onClick: (_, data) => handleClickActionIcon(data, 'save'),
      tooltip: 'Save All Selected Targets to Target Group',
    });
  }

  if (mappedTargetsUuid) {
    actions.push({
      icon: () => <AddIcon />,
      isFreeAction: true,
      onClick: () => handleSelectAllTargets(mappedTargetsUuid),
      tooltip: 'Select All',
    });
  }

  const checkedTargets = targets.filter(
    target => target.mappedMessage === mappedTargetsUuid ||
    (mappedMessageTabIndex === 0 && !target.mappedMessage)
  );

  return (
    <MaterialTable
      actions={actions}
      columns={targetsColumns}
      components={{
        Cell: props => {
          // eslint-disable-next-line react/prop-types
          const { value } = props;

          return (
            <MTableCell
              {...props}
              value={value || 'N/A'}
            />
          );
        },
        Container: props => (
          <StyledPaper
            {...props}
            square
            variant="outlined"
          />
        ),
      }}
      data={formattedTargets}
      title={mappedTargetsUuid ?
        `Message ${mappedMessageTabIndex + 1} targets: ${checkedTargets?.length} (of ${targets?.length})` :
        `Total targets: ${targets?.length}`
      }
      options={{
        actionsColumnIndex: -1,
        headerStyle: {
          backgroundColor: colors.tableHeaderGray,
          color: colors.white,
        },
        pageSize: 10,
        selection: !mappedTargetsUuid,
      }}
    />
  );
};

TargetsSelectionTable.defaultProps = {
  handleClickActionIcon: () => {},
  handleSelectAllTargets: () => {},
  hasDeleteButton: true,
  mappedMessageTabIndex: null,
  mappedTargetsUuid: null,
  phoneActionCenter: false,
  targets: [],
};

TargetsSelectionTable.propTypes = {
  handleClickActionIcon: PropTypes.func,
  handleSelectAllTargets: PropTypes.func,
  hasDeleteButton: PropTypes.bool,
  hasSaveTargetsButton: PropTypes.bool.isRequired,
  mappedMessageTabIndex: PropTypes.oneOf([
    0,
    1,
  ]),
  mappedTargetsUuid: PropTypes.string,
  phoneActionCenter: PropTypes.bool,
  targets: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      middleName: PropTypes.string,
    })
  ),
};

export default TargetsSelectionTable;
