import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Button } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';

const DetailsButton = ({
  className,
  disabled,
  name,
  to,
}) => {
  const history = useHistory();

  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={() => history.push(to)}
      size="small"
      startIcon={<SettingsIcon />}
      variant="outlined"
    >
      {name}
    </Button>
  );
};

DetailsButton.defaultProps = {
  className: '',
  disabled: false,
  name: 'View Details',
};

DetailsButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default DetailsButton;
