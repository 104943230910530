import {
  useParams,
} from 'react-router-dom';

import {
  Grid,
} from '@mui/material';

import {
  BreadcrumbsNavigation,
  Container,
} from 'common/components';

import MessagesTable from '../components/MessagesTable';
import MessagesTopCard from '../components/MessagesTopCard';

const Messages = () => {
  const { organizationUuid } = useParams();

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/broadcasts`,
      name: 'Broadcasts - All messages',
    },
  ];

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container>
        <Grid container>
          <MessagesTopCard />
        </Grid>
        <Grid container>
          <MessagesTable />
        </Grid>
      </Container>
    </>
  );
};

export default Messages;

