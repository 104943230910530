import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';
import {
  Add as AddIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

const LinkButton = ({
  className = '',
  color = 'secondary',
  disabled = false,
  name,
  to,
  variant = 'default',
}) => (
  <Button
    color={color}
    className={className}
    component={Link}
    disabled={disabled}
    disableElevation
    to={to}
    startIcon={variant === 'newItem' ? <AddIcon /> : <SettingsIcon />}
    variant={variant === 'newItem' ? 'contained' : 'outlined'}
  >
    {name}
  </Button>
);

LinkButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    'default',
    'newItem',
  ]),
};

export default LinkButton;
