import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { DropzoneArea } from 'react-mui-dropzone';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import { setSnackbarError } from 'store/actions/global';

import { colors } from 'utils/constants';
import {
  ColorPicker,
  CustomFieldsSelect,
  EditorWysiwyg,
  ImageUploader,
  PreviewLandingPageFields,
} from 'common/components';
import CardField from 'common/components/CardField';
import {
  customFieldType,
  imageType,
} from 'common/typings';

const InterfaceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 770px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  max-width: 770px;
  padding-bottom: 15px;
`;

const HeadlineTextField = styled(TextField)`
  width: 100%;
  max-width: 570px;
  padding-bottom: 15px;
`;

const ColorPickersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ColorPickerTitle = styled(Typography)`
  margin-bottom: 10px;
  padding-right: 10px;
`;

const ContentTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 51px 0 80px;
  background-color: ${colors.blackSqueeze};
`;

const DropzoneAreaWrapper = styled.div`
  width: 100%;
  margin: 40px 0 10px;
`;

const CheckBoxOptionsWrapper = styled.div`
  width: 570px;
  margin: 40px 0;
  padding: 20px 34px;
  background-color: ${colors.wildSand};
`;

const ImageUploaderWrapper = styled.div`
  margin-bottom: 25px;
`;

const EditorWrapper = styled.div`
  width: 100%;
  max-width: 770px;
  margin-bottom: 30px;
  background-color: ${colors.white};
`;

const StepTwo = ({
  backgroundColor,
  buttonColor,
  customFieldsList,
  customFields,
  descriptionBodyState,
  descriptionHeadlineText,
  defaultLandingPageImage,
  defaultLogo,
  googleAnalyticsId,
  handleAddCustomField,
  handleRemoveCustomField,
  headlineText,
  isFacebookPixel,
  isGoogleAnalytics,
  pixelId,
  logoImage,
  setImages,
  setLogoImage,
  secondHeadlineText,
  setDescriptionBodyState,
  handleSetColor,
  handleSetFormValues,
  handleSetBooleanFormValues,
  socialMedia,
  textColor,
  videoUrl,
  vimeoCode,
  youtubeId,
}) => {
  const dispatch = useDispatch();
  const initialFiles = defaultLandingPageImage ? [defaultLandingPageImage] : [];

  const handleEditorError = () => {
    dispatch(setSnackbarError({
      message: 'Something was wrong with your description field. Please overwrite the field again',
    }));

    setDescriptionBodyState(null);
  };

  return (
    <CardField
      column
      center
      wrap
    >
      <InterfaceWrapper>
        <ImageUploaderWrapper>
          <ImageUploader
            defaultImageScr={defaultLogo}
            images={logoImage}
            setImages={setLogoImage}
            showSaveButton={false}
          />
        </ImageUploaderWrapper>
        <ColorPickersWrapper>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
          >
            <ColorPickerTitle>Choose text color</ColorPickerTitle>
            <ColorPicker
              handlePickerChange={({ hex }) => handleSetColor(hex, 'textColor')}
              pickerColor={textColor}
            />
          </Grid>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
          >
            <ColorPickerTitle>Choose background color</ColorPickerTitle>
            <ColorPicker
              handlePickerChange={({ hex }) => handleSetColor(hex, 'backgroundColor')}
              pickerColor={backgroundColor}
            />
          </Grid>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
          >
            <ColorPickerTitle>Choose button color</ColorPickerTitle>
            <ColorPicker
              handlePickerChange={({ hex }) => handleSetColor(hex, 'buttonColor')}
              pickerColor={buttonColor}
            />
          </Grid>
        </ColorPickersWrapper>
        <HeadlineTextField
          fullWidth
          label="Headline Text"
          name="headlineText"
          onChange={handleSetFormValues}
          required
          type="text"
          value={headlineText}
        />
        <StyledTextField
          fullWidth
          label="Second Headline Text"
          name="secondHeadlineText"
          onChange={handleSetFormValues}
          type="text"
          value={secondHeadlineText}
        />
        <DropzoneAreaWrapper>
          <DropzoneArea
            onChange={file => setImages(file)}
            acceptedFiles={['image/*']}
            dropzoneText="Drag and drop Image file or click to browse."
            filesLimit={1}
            initialFiles={initialFiles}
          />
        </DropzoneAreaWrapper>
        <StyledTextField
          fullWidth
          label="Or add youtube link here"
          name="videoUrl"
          onChange={handleSetFormValues}
          type="text"
          value={videoUrl}
        />
        {youtubeId && (
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            width="829"
            height="460"
            src={`https://www.youtube.com/embed/${youtubeId}`}
            title={youtubeId}
          />
        )}
        <StyledTextField
          fullWidth
          label="Or add Vimeo embed code"
          name="vimeoCode"
          onChange={handleSetFormValues}
          type="text"
          multiline
          value={vimeoCode}
        />
        <Typography>
          When copying the code from the vimeo page, make sure you select the Fixed option.
          The code must start with the &#60; iframe &#62; tag.
          If you do not see your video below after pasting the code,
          then the code was probably pasted wrong.
        </Typography>
        {vimeoCode && (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: vimeoCode,
            }}
          />
        )}
      </InterfaceWrapper>
      <ContentTextWrapper>
        <StyledTextField
          fullWidth
          label="Description Headline"
          name="descriptionHeadlineText"
          onChange={handleSetFormValues}
          type="text"
          value={descriptionHeadlineText}
        />
        <EditorWrapper>
          <EditorWysiwyg
            editorState={descriptionBodyState}
            setEditorStateChange={setDescriptionBodyState}
            handleEditorError={handleEditorError}
            options={[
              'inline',
              'blockType',
              'list',
            ]}
            inlineOptions={[
              'bold',
              'italic',
            ]}
            listOptions={['unordered']}
            placeholder="Sign up description..."
          />
        </EditorWrapper>
      </ContentTextWrapper>
      <InterfaceWrapper>
        <PreviewLandingPageFields
          backgroundColor={backgroundColor}
          buttonColor={buttonColor}
          buttonName="Submit"
          customFields={customFields}
          handleRemoveCustomField={handleRemoveCustomField}
          headlineText={headlineText}
          textColor={textColor}
        />
        <span>This is just a preview</span>
        {!!customFieldsList?.length && (
          <CustomFieldsSelect
            handleAddCustomField={handleAddCustomField}
            customFieldsList={customFieldsList}
          />
        )}
      </InterfaceWrapper>
      <CheckBoxOptionsWrapper>
        <FormControl>
          <FormControlLabel
            control={(
              <Checkbox
                checked={socialMedia}
                onChange={handleSetBooleanFormValues}
              />
            )}
            label="Add social media share buttons"
            name="socialMedia"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={isGoogleAnalytics}
                onChange={handleSetBooleanFormValues}
              />
            )}
            label="Add Google Analytics tracking code"
            name="isGoogleAnalytics"
          />
          {isGoogleAnalytics && (
            <StyledTextField
              fullWidth
              label="Add Google Analytics ID"
              onChange={handleSetFormValues}
              name="googleAnalyticsId"
              type="text"
              value={googleAnalyticsId}
              inputProps={{ maxLength: 24 }}
            />
          )}
          <FormControlLabel
            control={(
              <Checkbox
                checked={isFacebookPixel}
                onChange={handleSetBooleanFormValues}
              />
            )}
            label="Add Facebook pixel"
            name="isFacebookPixel"
          />
          {isFacebookPixel && (
            <StyledTextField
              fullWidth
              label="Add Facebook Pixel ID"
              onChange={handleSetFormValues}
              name="pixelId"
              type="text"
              value={pixelId}
              inputProps={{ maxLength: 24 }}
            />
          )}
        </FormControl>
      </CheckBoxOptionsWrapper>
    </CardField>
  );
};

StepTwo.defaultProps = {
  defaultLandingPageImage: null,
  defaultLogo: null,
  descriptionBodyState: null,
};

StepTwo.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  customFields: PropTypes.arrayOf(customFieldType).isRequired,
  customFieldsList: PropTypes.arrayOf(customFieldType).isRequired,
  defaultLandingPageImage: PropTypes.string,
  defaultLogo: PropTypes.string,
  descriptionBodyState: PropTypes.shape({
    getCurrentContent: PropTypes.func,
  }),
  descriptionHeadlineText: PropTypes.string.isRequired,
  googleAnalyticsId: PropTypes.string.isRequired,
  handleAddCustomField: PropTypes.func.isRequired,
  handleRemoveCustomField: PropTypes.func.isRequired,
  handleSetBooleanFormValues: PropTypes.func.isRequired,
  handleSetColor: PropTypes.func.isRequired,
  handleSetFormValues: PropTypes.func.isRequired,
  headlineText: PropTypes.string.isRequired,
  isFacebookPixel: PropTypes.bool.isRequired,
  isGoogleAnalytics: PropTypes.bool.isRequired,
  logoImage: PropTypes.arrayOf(imageType).isRequired,
  pixelId: PropTypes.string.isRequired,
  secondHeadlineText: PropTypes.string.isRequired,
  setDescriptionBodyState: PropTypes.func.isRequired,
  setImages: PropTypes.func.isRequired,
  setLogoImage: PropTypes.func.isRequired,
  socialMedia: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  vimeoCode: PropTypes.string.isRequired,
  youtubeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
};

export default StepTwo;
