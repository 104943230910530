import {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { debounce } from 'lodash';

import Autocomplete from '@mui/material/Autocomplete';
import {
  Button,
  CircularProgress,
  ListSubheader,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import {
  fetchCommittees,
  fetchOfficial,
  fetchSearchedOfficials,
  resetCommittees,
} from 'store/actions/officials';
import {
  clearPostionTerms,
  fetchFederalPositions,
  fetchPositionTerms,
} from 'store/actions/contactDetails';
import {
  fetchTargetGroupTargets,
} from 'store/actions/campaigns';
import {
  setSnackbarWarning,
} from 'store/actions/global';
import { campaignsSelectors } from 'store/selectors/campaigns';
import { contactDetailsSelectors } from 'store/selectors/contactDetails';
import { officialsSelectors } from 'store/selectors/officials';

import {
  chambers,
  maxNonGeocodedTargets,
  positionsByStates,
  upperAdministrativeLevels,
  usStates,
} from 'utils/constants';
import { CircularProgressWrapper } from 'globalStyles';

import TabPanel from 'common/components/TabPanel';
import OfficialsDetails from 'common/components/OfficialsDetails';
import TargetsSelectionTable from 'common/components/TargetsSelectionTable';
import { TooltipIcon } from 'common/components';

import { targetsType } from 'common/typings';

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const TargetsWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const AddButton = styled(Button)`
  margin: 20px 0;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  padding-bottom: 15px;
`;

const StateTextField = styled(StyledTextField)`
  margin-left: 10px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
`;

const StateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TargetsSelection = ({
  handleSaveSelectedTargetsToTargetList,
  hasSaveTargetsButton,
  isCongressGroupSensitive,
  organizationUuid,
  phoneActionCenter,
  setTargets,
  targets,
}) => {
  const dispatch = useDispatch();

  const {
    committees,
    federalPositions,
    isPositionsFetching,
    isTargetsFetching,
    isTermsFetching,
    positionTerms,
    searchedTargets,
    targetGroups,
  } = useSelector(state => ({
    committees: officialsSelectors.getCommittees(state),
    federalPositions: contactDetailsSelectors.getFederalPositions(state),
    isPositionsFetching: contactDetailsSelectors.isPositionsFetching(state),
    isTargetsFetching: officialsSelectors.isFetching(state),
    isTermsFetching: contactDetailsSelectors.isTermsFetching(state),
    positionTerms: contactDetailsSelectors.getPositionTerms(state),
    searchedTargets: officialsSelectors.getSearchedOfficials(state),
    targetGroups: campaignsSelectors.getTargetGroups(state),
  }));

  const [
    position,
    setPosition,
  ] = useState('');
  const [
    tabIndex,
    setTabIndex,
  ] = useState(0);
  const [
    state,
    setState,
  ] = useState('');
  const [
    searchedPhrase,
    setSearchedPhrase,
  ] = useState('');
  const [
    selectedIndividual,
    setSelectedIndividual,
  ] = useState(null);
  const [
    selectedCommittee,
    setSelectedCommittee,
  ] = useState('');
  const [
    committeeCategory,
    setCommitteeCategory,
  ] = useState('');
  const [
    chamber,
    setChamber,
  ] = useState('');
  const [
    category,
    setCategory,
  ] = useState('');
  const [
    targetsGroup,
    setTargetsGroup,
  ] = useState('');

  const handleTabChange = (_, newValue) => setTabIndex(newValue);

  const handleFetchCommittees = event => {
    setChamber(event.target.value);
    setSelectedCommittee('');

    if (committeeCategory === 'federal') {
      dispatch(fetchCommittees({
        category: committeeCategory,
        chamber: event.target.value,
      }));
    } else {
      dispatch(fetchCommittees({
        category: 'state',
        chamber: event.target.value,
        state: committeeCategory,
      }));
    }
  };

  const handleNonFederalPositionChange = event => {
    dispatch(fetchPositionTerms({
      position: event.target.value,
      state,
    }));
    setPosition(event.target.value);
  };

  const handleCategoryChange = event => {
    setCategory(event.target.value);

    if (event.target.value === 'Federal') {
      dispatch(fetchFederalPositions({ category: 'federal' }));
    }
  };

  const debouncedFetchSearchedOfficials = useCallback(
    debounce(newValue => {
      dispatch(fetchSearchedOfficials(newValue, organizationUuid));
    }, 500),
    []
  );

  const handlePositionStateChange = event => {
    if (category === 'Federal') {
      if (event.target.value !== position) {
        dispatch(fetchPositionTerms({ position: event.target.value }));

        setPosition(event.target.value);
      }

      const isFederalSenatorsOrRepresentative = federalPositions.find(
        federalPostion => federalPostion.uuid === event.target.value &&
        (federalPostion.prefix === 'Representative' || federalPostion.prefix === 'Senator')
      );

      if (isCongressGroupSensitive && isFederalSenatorsOrRepresentative) {
        dispatch(setSnackbarWarning({ message: 'Non-geotargeted campaigns may not include Members of Congress.' }));
      }
    } else {
      dispatch(fetchFederalPositions({
        category: category === 'Local' ? 'local' : 'state',
        state: event.target.value,
      }));

      setState(event.target.value);
    }
  };

  const handleAddTargets = newTargets => {
    const filteredNewTargets = newTargets.filter(
      newTarget => !targets.find(
        currentTarget => (
          currentTarget.term || currentTarget.uuid
        ) === (newTarget.uuid || newTarget.term)
      )
    );

    setTargets(prevTargets => [
      ...prevTargets,
      ...filteredNewTargets,
    ]);
  };

  const handleAddTargetsFromSelectedGroup = async () => {
    const newTargets = await dispatch(fetchTargetGroupTargets(targetsGroup.uuid))
      .then(data => data?.map(target => ({
        ...target,
        office: {
          state: target.state,
          title: target.office,
        },
      })));

    handleAddTargets(newTargets);
    setTargetsGroup('');
  };

  const handleRemoveSelectedTargets = selectedTargets => {
    const filteredTargets = targets.filter(
      currentTarget => !selectedTargets.find(
        selectedTarget => {
          if (selectedTarget.term) {
            return selectedTarget.term === currentTarget.term;
          }

          return selectedTarget.uuid === currentTarget.uuid;
        }
      )
    );

    setTargets(filteredTargets);
  };

  const handleClickActionIcon = (selectedTargets, actionType) => {
    if (actionType === 'remove') {
      handleRemoveSelectedTargets(selectedTargets);
    } else if (actionType === 'save') {
      handleSaveSelectedTargetsToTargetList(selectedTargets);
    }
  };

  const showStateSelect = () => {
    if (!position) {
      return false;
    }

    const positionTitle =
      federalPositions.find(federalPosition => federalPosition.uuid === position)?.title;

    if (positionTitle && positionsByStates.includes(positionTitle)) {
      return true;
    }

    return false;
  };

  const renderStates = () => (
    usStates.map(({
      abbreviation,
      name,
    }) => (
      <MenuItem
        key={abbreviation}
        value={abbreviation}
      >
        {name}
      </MenuItem>
    ))
  );

  const renderPositions = () => (
    isPositionsFetching ? (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    ) : (
      federalPositions.map(({
        title,
        uuid,
      }) => (
        <MenuItem
          key={uuid}
          value={uuid}
        >
          {title}
        </MenuItem>
      ))
    )
  );

  return (
    <>
      <StyledTabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="Targets tabs"
      >
        <Tab label="Position" />
        <Tab label="Individual" />
        <Tab label="Committee" />
        <Tab label="Target Groups" />
      </StyledTabs>
      <TargetsWrapper>
        <TabPanel
          index={0}
          value={tabIndex}
        >
          <OfficialsDetails
            administrativeLevels={upperAdministrativeLevels}
            category={category}
            handleCategoryChange={handleCategoryChange}
            handleNonFederalPositionChange={handleNonFederalPositionChange}
            handlePositionStateChange={handlePositionStateChange}
            isTermsFetching={isTermsFetching}
            position={position}
            positionTerms={positionTerms}
            renderPositions={renderPositions}
            renderStates={renderStates}
            setPosition={setPosition}
            setState={setState}
            state={state}
            onlyPositions
          />
          {category === 'Federal' &&
            showStateSelect() &&
            (
              <StateWrapper>
                <TooltipIcon title="You can specify state, if not, we will add all" />
                <StateTextField
                  label="State (optional)"
                  onChange={event => {
                    setState(event.target.value);

                    dispatch(fetchPositionTerms({
                      position,
                      state: event.target.value,
                    }));
                  }}
                  select
                  value={state}
                  variant="standard"
                >
                  <ListSubheader>States</ListSubheader>
                  {renderStates()}
                </StateTextField>
              </StateWrapper>
            )}
          <AddButton
            color="secondary"
            disabled={!positionTerms.length}
            disableElevation
            onClick={() => {
              handleAddTargets(positionTerms.map(positionTerm => ({
                ...positionTerm,
                term: positionTerm.uuid,
              })));
              setCategory('');
              setState('');
              setPosition('');
              dispatch(clearPostionTerms());
            }}
            variant="contained"
          >
            Add
          </AddButton>
        </TabPanel>
        <TabPanel
          index={1}
          value={tabIndex}
        >
          <Autocomplete
            fullWidth
            getOptionLabel={option => `${option.firstName} ${option.lastName}, ${option.office || option.position}`}
            // https://github.com/mui/material-ui/issues/20068
            filterOptions={x => x}
            inputValue={searchedPhrase}
            onInputChange={(_, newValue) => {
              if (newValue.length > 2) {
                debouncedFetchSearchedOfficials(newValue, organizationUuid);
              }

              setSearchedPhrase(newValue);
            }}
            onChange={(_, newValue) => setSelectedIndividual(newValue)}
            options={searchedTargets}
            renderInput={params => (
              <TextField
                {...params}
                label="Search officials"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isTargetsFetching ? (
                        <CircularProgress
                          color="inherit"
                          size={20}
                        />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            value={selectedIndividual}
          />
          <AddButton
            color="secondary"
            disabled={!selectedIndividual}
            disableElevation
            onClick={() => {
              dispatch(fetchOfficial(selectedIndividual.uuid))
                .then(data => handleAddTargets([
                  {
                    ...data.currentTerms[0],
                    ...data,
                    term: data.currentTerms[0].uuid,
                  },
                ]));
              setSelectedIndividual(null);
            }}
            variant="contained"
          >
            Add
          </AddButton>
        </TabPanel>
        <TabPanel
          index={2}
          value={tabIndex}
        >
          <StyledTextField
            label="Category"
            onChange={event => {
              setChamber('');
              setSelectedCommittee('');
              setCommitteeCategory(event.target.value);
            }}
            select
            value={committeeCategory}
            variant="standard"
          >
            <ListSubheader>Federal</ListSubheader>
            <MenuItem value="federal">United States</MenuItem>
            {/*
              Hide after Alex feedback, probably we need to show it on later phase
              <ListSubheader>States</ListSubheader>
              {usStates.map(element => (
                <MenuItem
                  key={element.name}
                  value={element.abbreviation}
                >
                  {element.name}
                </MenuItem>
              ))} */}
          </StyledTextField>
          {
            committeeCategory && (
              <StyledTextField
                label="Chamber"
                onChange={event => {
                  handleFetchCommittees(event);
                }}
                select
                value={chamber}
                variant="standard"
              >
                {chambers.map(element => (
                  <MenuItem
                    key={element.key}
                    value={element.value}
                  >
                    {element.name}
                  </MenuItem>
                ))}
              </StyledTextField>
            )
          }
          {
            !!committees.length && !isTargetsFetching && (
              <StyledTextField
                label="Committees"
                onChange={event => {
                  setSelectedCommittee(event.target.value);
                }}
                select
                value={selectedCommittee}
                variant="standard"
              >
                {committees.map(element => (
                  <MenuItem
                    key={element.uuid}
                    value={element}
                  >
                    {element.name}
                  </MenuItem>
                ))}
              </StyledTextField>
            )
          }
          {
            !committees.length && chamber && !isTargetsFetching && (
              <span>No Committees, please change options</span>
            )
          }
          {
            selectedCommittee && !selectedCommittee.currentMembers.length && (
              <span>No Current Members in selected Committee, please change options</span>
            )
          }
          {isTargetsFetching && (
            <CircularProgress
              color="inherit"
              size={20}
            />
          )}
          <ButtonWrapper>
            <AddButton
              color="secondary"
              disabled={!(selectedCommittee && selectedCommittee.currentMembers?.length)}
              disableElevation
              variant="contained"
              onClick={() => {
                handleAddTargets(selectedCommittee.currentMembers.map(currentMember => ({
                  ...currentMember,
                  term: currentMember.uuid,
                })));
                dispatch(resetCommittees());
                setCommitteeCategory('');
                setChamber('');
                setSelectedCommittee('');
              }}
            >
              Add
            </AddButton>
          </ButtonWrapper>
        </TabPanel>
        <TabPanel
          index={3}
          value={tabIndex}
        >
          {targetGroups?.length ? (
            <StyledTextField
              label="Target Group"
              onChange={event => setTargetsGroup(event.target.value)}
              select
              value={targetsGroup}
              variant="standard"
            >
              {targetGroups?.map(element => (
                <MenuItem
                  key={element.uuid}
                  value={element}
                >
                  {element.name}
                </MenuItem>
                    ))}
            </StyledTextField>
          ) : (
            <span>You have no target group saved</span>
          )}
          <ButtonWrapper>
            <AddButton
              color="secondary"
              disabled={!targetsGroup}
              disableElevation
              variant="contained"
              onClick={handleAddTargetsFromSelectedGroup}
            >
              Add
            </AddButton>
          </ButtonWrapper>
        </TabPanel>
      </TargetsWrapper>
      {
        !!targets?.length && (
          <>
            {(targets.length > maxNonGeocodedTargets) && isCongressGroupSensitive && (
              <Typography color="error">
                You are limited to
                {' '}
                {maxNonGeocodedTargets}
                {' '}
                targets when creating a
                non-geotargeted action center.
              </Typography>
            )}
            <TargetsSelectionTable
              handleClickActionIcon={handleClickActionIcon}
              hasSaveTargetsButton={hasSaveTargetsButton}
              targets={targets}
              phoneActionCenter={phoneActionCenter}
            />
          </>
        )
      }
    </>
  );
};

TargetsSelection.defaultProps = {
  handleSaveSelectedTargetsToTargetList: () => {},
  hasSaveTargetsButton: true,
  isCongressGroupSensitive: false,
  phoneActionCenter: false,
};

TargetsSelection.propTypes = {
  handleSaveSelectedTargetsToTargetList: PropTypes.func,
  hasSaveTargetsButton: PropTypes.bool,
  isCongressGroupSensitive: PropTypes.bool,
  organizationUuid: PropTypes.string.isRequired,
  phoneActionCenter: PropTypes.bool,
  setTargets: PropTypes.func.isRequired,
  targets: targetsType.isRequired,
};

export default TargetsSelection;
