import { useState } from 'react';
import {
  Field,
  Form,
} from 'react-final-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';

import { setSnackbarSuccess } from 'store/actions/global';

import {
  generalInfoFormFields,
  mailingAddressFormFields,
  usStates,
} from 'utils/constants';
import { required } from 'utils/validators';
import {
  CircularProgressWrapper,
  FormWrapper,
  SettingsWrapper,
} from 'globalStyles';

const StyledButton = styled(Button)`
  margin-bottom: 40px;
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 20px;
`;

const StyledField = styled(Field)`
  width: 50%;
  padding-top: 20px;
`;

const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;

const GENERAL_INFO_TITLE = 'General information';
const GENERAL_INFO_UPDATE_SUCCESS_MESSAGE = 'General information were changed succesfully';
const MAILING_ADDRESS_TITLE = 'Mailing address';
const MAILING_ADDRESS_UPDATE_SUCCESS_MESSAGE = 'Mailing address was changed succesfully';
const UPDATE_BUTTON_TEXT = 'Update';

const SettingsTab = ({
  canEditOrganization,
  initialValues,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const [
    isAddressSnackbarOpen,
    setIsAddressSnackbarOpen,
  ] = useState(false);

  if (Object.values(initialValues).includes(undefined)) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  return (
    <SettingsWrapper>
      <Grid container>
        <FormWrapper>
          <Typography variant="h6">{GENERAL_INFO_TITLE}</Typography>
          <StyledDivider />
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              pristine,
              submitting,
            }) => (
              <form onSubmit={async event => {
                const response = await handleSubmit(event);

                if (response === 'success') {
                  dispatch(setSnackbarSuccess({ message: GENERAL_INFO_UPDATE_SUCCESS_MESSAGE }));
                }
              }}
              >
                <Grid
                  container
                  spacing={3}
                >
                  {generalInfoFormFields.map(({
                    helperText,
                    isRequired,
                    label,
                    name,
                    type,
                  }) => (
                    <Grid
                      alignContent="space-between"
                      container
                      item
                      key={label}
                      xs={12}
                    >
                      <StyledField
                        name={name}
                        validate={isRequired ? required : null}
                      >
                        {({
                          input,
                          meta,
                        }) => (
                          <TextField
                            {...input}
                            disabled={!canEditOrganization && (!meta.modified && !!input.value)}
                            error={Boolean(meta.touched && (meta.error || meta.submitError))}
                            fullWidth
                            helperText={
                            (meta.touched && (meta.error || meta.submitError)) ?
                              meta.touched && (meta.error || meta.submitError) :
                                helperText
                          }
                            label={label}
                            type={type}
                            variant="standard"
                          />
                        )}
                      </StyledField>
                    </Grid>
                  ))}
                </Grid>
                <StyledGrid
                  container
                  item
                  justifyContent="flex-end"
                  xs={12}
                >
                  <StyledButton
                    color="secondary"
                    disabled={submitting || pristine}
                    disableElevation
                    type="submit"
                    variant="contained"
                  >
                    {UPDATE_BUTTON_TEXT}
                  </StyledButton>
                </StyledGrid>
              </form>
            )}
          />
        </FormWrapper>
        <FormWrapper>
          <Typography variant="h6">{MAILING_ADDRESS_TITLE}</Typography>
          <StyledDivider />
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              pristine,
              submitting,
            }) => (
              <form onSubmit={async event => {
                const response = await handleSubmit(event);

                if (response === 'success') {
                  setIsAddressSnackbarOpen(true);
                }
              }}
              >
                <Grid
                  container
                  spacing={3}
                >
                  {mailingAddressFormFields.map(({
                    label,
                    name,
                    type,
                  }) => (
                    <Grid
                      alignContent="space-between"
                      container
                      item
                      key={label}
                      xs={(name === 'state' || name === 'zipCode') ? 6 : 12}
                    >
                      <StyledField name={name}>
                        {({
                          input,
                          input: {
                            onChange,
                            value,
                          },
                          meta,
                        }) => (name === 'state' ? (
                          <Autocomplete
                            fullWidth
                            getOptionLabel={option => option.name || ''}
                            onChange={(_, option) => onChange(option ? option.name : null)}
                            options={usStates}
                            renderInput={params => (
                              <TextField
                                {...input}
                                {...params}
                                disabled={!canEditOrganization && (!meta.modified && !!input.value)}
                                error={Boolean(meta.touched && (meta.error || meta.submitError))}
                                fullWidth
                                helperText={meta.touched && (meta.error || meta.submitError)}
                                label={label}
                                variant="standard"
                              />
                            )}
                            value={usStates.find(option => option.name === value) || ''}
                          />
                        ) : (
                          <TextField
                            {...input}
                            disabled={!canEditOrganization && (!meta.modified && !!input.value)}
                            error={Boolean(meta.touched && (meta.error || meta.submitError))}
                            fullWidth
                            helperText={meta.touched && (meta.error || meta.submitError)}
                            label={label}
                            type={type}
                            variant="standard"
                          />
                        ))}
                      </StyledField>
                    </Grid>
                  ))}
                </Grid>
                <StyledGrid
                  container
                  item
                  justifyContent="flex-end"
                  xs={12}
                >
                  <StyledButton
                    color="secondary"
                    disabled={submitting || pristine}
                    disableElevation
                    type="submit"
                    variant="contained"
                  >
                    {UPDATE_BUTTON_TEXT}
                  </StyledButton>
                </StyledGrid>
                <Snackbar
                  open={isAddressSnackbarOpen}
                  autoHideDuration={3000}
                  onClose={() => setIsAddressSnackbarOpen(false)}
                >
                  <Alert
                    onClose={() => setIsAddressSnackbarOpen(false)}
                    severity="success"
                  >
                    {MAILING_ADDRESS_UPDATE_SUCCESS_MESSAGE}
                  </Alert>
                </Snackbar>
              </form>
            )}
          />
        </FormWrapper>
      </Grid>
    </SettingsWrapper>
  );
};

SettingsTab.propTypes = {
  canEditOrganization: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
    websiteUrl: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SettingsTab;
