import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { useConfirm } from 'material-ui-confirm';
import {
  Button,
  Card,
  CardContent,
  Snackbar,
} from '@mui/material';
import {
  CreateNewFolder as CreateNewFolderIcon,
  DeleteOutline as DeleteOutlineIcon,
  DynamicFeed as DynamicFeedIcon,
  FilterList as FilterListIcon,
  FormatListBulleted as FormatListBulletedIcon,
  GetApp as GetAppIcon,
  GroupAdd as GroupAddIcon,
  Publish as PublishIcon,
  StarBorder as StarBorderIcon,
} from '@mui/icons-material';

import {
  deleteList,
  exportList,
  updateContactsList,
} from 'store/actions/contacts';
import { contactSelectors } from 'store/selectors/contacts';

import mediaQueries from 'utils/mediaQueries';
import { formatNumber } from 'utils/helpers';
import { listTypes } from 'utils/constants';
import { contactListType } from 'common/typings';

const { small } = mediaQueries;

const CardActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 60%;
`;

const CardButton = styled(Button)`
  visibility: ${props => props.visibility};

  @media ${small} {
    display: ${props => (props.visibility === 'visible' ? 'flex' : 'none')};
    width: 100%;
  }
`;

const CardInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 40%;
`;

const IconText = styled.p`
  margin-left: 8px;
`;

const ListCardElement = styled.div`
  display: flex;
  flex: 1 1 1px;
  align-items: center;
  font-weight: ${props => (props.$bold ? 'bold' : 'unset')};

  @media ${small} {
    flex: unset;
    justify-content: center;
    width: 100%;
  }
`;

const LogoMark = styled.img`
  width: 21px;
  height: 21px;
`;

const StyledCard = styled(Card)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  width: 100%;
  padding: 24px;
`;

const { REACT_APP_HIDE_CONTACTS_IMPORT } = process.env;

const ListCard = ({
  className,
  countAll,
  isAddToGroupButonDisabled,
  list: {
    count,
    listGroups = [],
    name,
    parent,
    type,
    uuid,
  },
  onShowImportListView,
  onOpenAddToGroupModal,
}) => {
  const dispatch = useDispatch();
  const { organizationUuid } = useParams();
  const history = useHistory();
  const confirm = useConfirm();
  const {
    complexList,
    staticList,
  } = listTypes;

  const { isPending } = useSelector(state => ({
    isPending: contactSelectors.isPendingList(state),
  }));

  const [
    isSnackbarOpen,
    setSnackbarOpen,
  ] = useState(false);

  const handleExport = event => {
    event.stopPropagation();

    dispatch(exportList(uuid))
      .then(() => setSnackbarOpen(true));
  };

  const handleDeleteList = event => {
    event.stopPropagation();

    confirm({
      confirmationText: 'Delete',
      description: 'This will not delete the contacts',
      title: 'Delete selected list?',
    })
      .then(() => dispatch(deleteList(uuid, organizationUuid)));
  };

  const handleDeleteFromGroup = event => {
    event.stopPropagation();

    confirm({
      confirmationText: 'Delete',
      description: 'This action will delete selected list from Group. You will not delete the entire list.',
      title: 'Delete list?',
    })
      .then(() => dispatch(updateContactsList(
        uuid,
        organizationUuid,
        { listGroups: [] }
      )));
  };

  const handleShowDetailsView = () => {
    history.push(`/${organizationUuid}/contacts/lists/${uuid}`);
  };

  const handleShowAddContactView = event => {
    event.stopPropagation();

    history.push(`/${organizationUuid}/contacts/lists/${uuid}/create-contact`);
  };

  const handleCloneFilteredList = event => {
    event.stopPropagation();

    history.push(`/${organizationUuid}/contacts/lists/create-filtered-list/${uuid}`);
  };

  const handleOpenModal = event => {
    event.stopPropagation();

    onOpenAddToGroupModal(uuid, listGroups);
  };

  const handleShowImportListView = event => {
    event.stopPropagation();

    onShowImportListView(uuid);
  };

  const renderListIcon = () => {
    if (type === complexList) {
      return (
        <>
          <FilterListIcon />
          <IconText>Filtered List</IconText>
        </>
      );
    }

    if (type === staticList) {
      if (parent) {
        return (
          <>
            <FormatListBulletedIcon />
            <IconText>List</IconText>
          </>
        );
      }

      return (
        <>
          <StarBorderIcon />
          <IconText>All Contacts</IconText>
        </>
      );
    }

    return (
      <>
        <LogoMark
          alt="Muster logomark"
          src="/muster_logomark_black.png"
        />
        <IconText>Muster List</IconText>
      </>
    );
  };

  return (
    <StyledCard
      className={className}
      onClick={handleShowDetailsView}
      variant="outlined"
    >
      <StyledCardContent>
        <CardInfo>
          <ListCardElement
            $bold
            data-test-id={`list-card-${name}`}
          >
            {name}
          </ListCardElement>
          <ListCardElement>
            {(parent && (type === staticList || type === complexList)) ?
              formatNumber(count) :
              formatNumber(countAll)
            }
            &nbsp;
            contacts
          </ListCardElement>
          <ListCardElement>{renderListIcon()}</ListCardElement>
        </CardInfo>
        <CardActions data-test-id={`list-${name}-actions`}>
          <CardButton
            disabled={isPending}
            onClick={handleShowAddContactView}
            startIcon={<GroupAddIcon />}
            visibility={(parent && type === staticList) ? 'visible' : 'hidden'}
          >
            Add
          </CardButton>
          <CardButton
            disabled={!!REACT_APP_HIDE_CONTACTS_IMPORT || isPending}
            onClick={handleShowImportListView}
            startIcon={<GetAppIcon />}
            visibility={(parent && type === staticList) ? 'visible' : 'hidden'}
          >
            Import
          </CardButton>
          <CardButton
            disabled={isPending}
            onClick={handleCloneFilteredList}
            startIcon={<DynamicFeedIcon />}
            visibility={(parent && type === complexList) ? 'visible' : 'hidden'}
          >
            Clone
          </CardButton>
          <CardButton
            disabled={isPending}
            onClick={handleExport}
            startIcon={<PublishIcon />}
            visibility="visible"
          >
            Export
          </CardButton>
          <CardButton
            disabled={isPending}
            onClick={handleDeleteList}
            startIcon={<DeleteOutlineIcon />}
            visibility={(parent && (type === staticList || type === complexList)) ?
              'visible' :
              'hidden'
            }
          >
            Delete
          </CardButton>
          <CardButton
            disabled={isAddToGroupButonDisabled || isPending}
            onClick={handleOpenModal}
            startIcon={<CreateNewFolderIcon />}
            visibility={(parent && (type === staticList || type === complexList)) ?
              'visible' :
              'hidden'
            }
          >
            {listGroups.length ? 'Change group' : 'Add to Group'}
          </CardButton>
          {!!listGroups.length && (
            <CardButton
              disabled={isPending}
              onClick={handleDeleteFromGroup}
              startIcon={<DeleteOutlineIcon />}
              visibility={(parent && (type === staticList || type === complexList)) ?
                'visible' :
                'hidden'
            }
            >
              Delete from Group
            </CardButton>
          )}
        </CardActions>
      </StyledCardContent>
      <Snackbar
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        autoHideDuration={4000}
        color
        message="The contacts export will be sent to your email shortly."
        onClose={() => setSnackbarOpen(false)}
        open={isSnackbarOpen}
      />
    </StyledCard>
  );
};

ListCard.defaultProps = {
  countAll: 0,
  isAddToGroupButonDisabled: false,
  onOpenAddToGroupModal: () => {},
};

ListCard.propTypes = {
  className: PropTypes.string.isRequired,
  countAll: PropTypes.number,
  isAddToGroupButonDisabled: PropTypes.bool,
  list: contactListType.isRequired,
  onOpenAddToGroupModal: PropTypes.func,
  onShowImportListView: PropTypes.func.isRequired,
};

export default ListCard;
