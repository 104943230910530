import {
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { differenceBy } from 'lodash';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { v4 as uuid } from 'uuid';

import {
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import {
  clearActionCenter,
  fetchActionCenter,
  fetchActionCenterTargets,
  fetchCampaign,
  fetchTargetGroups,
  patchActionCenter,
  postActionCenter,
  postCampaign,
} from 'store/actions/campaigns';
import { fetchRootList } from 'store/actions/contacts';
import { fetchCustomFields } from 'store/actions/customFields';
import { customFieldsSelectors } from 'store/selectors/customFields';
import { campaignsSelectors } from 'store/selectors/campaigns';
import { contactSelectors } from 'store/selectors/contacts';
import { organizationSelectors } from 'store/selectors/organizations';
import { setSnackbarError } from 'store/actions/global';

import {
  ActionAlertMenuButton,
  Backdrop,
  CancelCreatorButton,
  CardField,
  Container,
  DescriptionBar,
  Stepper,
  SuccessDialog,
} from 'common/components';
import {
  colors,
  maxNonGeocodedTargets,
  messagePairTypes,
  subjectMessageEmptyPair,
} from 'utils/constants';
import {
  isValidURL,
  renderErrorMessage,
  scrollToTop,
  youtubeParser,
} from 'utils/helpers';
import { usePrevious } from 'utils/hooks';
import {
  ErrorTypography,
  StepFourContainer,
  StepOneContainer,
  StepThreeContainer,
  StepTwoContainer,
} from 'globalStyles';

import StepOne from 'routes/CreateActionCenter/components/StepOne';
import StepTwo from 'routes/CreateActionCenter/components/StepTwo';
import StepThree from 'routes/CreateActionCenter/components/StepThree';
import StepFour from 'routes/CreateActionCenter/components/StepFour';

const ButtonCreateWrapper = styled.div`
  position: relative;
  padding-left: 10px;
`;

const ButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const NextButtonWrapper = styled.div`
  display: flex;
  padding-left: 10px;
`;

const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90px;
  margin-top: 50px;
  background-color: ${colors.white};
`;

const { REACT_APP_MOUSEFLOW } = process.env;

const CreateActionCenter = () => {
  const dispatch = useDispatch();
  const {
    actionCenterUuid,
    campaignUuid,
    organizationUuid,
  } = useParams();

  const {
    actionCenterData,
    campaignDetails,
    customFieldsList,
    isActionCenterFetching,
    isPending,
    organization,
    rootList,
  } = useSelector(state => ({
    actionCenterData: campaignsSelectors.getActionCenterDetails(state),
    campaignDetails: campaignsSelectors.getCampaignDetails(state),
    customFieldsList: customFieldsSelectors.getCustomFieldsListData(state),
    isActionCenterFetching: campaignsSelectors.isActionCenterFetching(state),
    isPending: campaignsSelectors.isPending(state),
    organization: organizationSelectors.getOrganization(state),
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  useEffect(() => {
    if (campaignUuid) {
      dispatch(fetchCampaign(campaignUuid));
    }

    dispatch(fetchTargetGroups({ organization: organizationUuid }));

    if (
      (Object.keys(actionCenterData).length !== 0 && actionCenterUuid !== actionCenterData?.uuid) ||
      !actionCenterUuid
    ) {
      dispatch(clearActionCenter());
    }

    if (actionCenterUuid && actionCenterUuid !== actionCenterData?.uuid) {
      dispatch(fetchActionCenter(actionCenterUuid));
    }

    if (Object.keys(rootList).length === 0) {
      dispatch(fetchRootList(organizationUuid))
        .then(data => dispatch(fetchCustomFields(data.uuid)));
    } else {
      dispatch(fetchCustomFields(rootList.uuid));
    }
  }, []);

  const [
    nameValue,
    setNameValue,
  ] = useState('');
  const [
    step,
    setStep,
  ] = useState(0);
  const [
    errorMessage,
    setErrorMessage,
  ] = useState(null);
  const [
    isSuccessDialogOpen,
    setIsSuccessDialogOpen,
  ] = useState(false);
  const [
    campaignValue,
    setCampaignValue,
  ] = useState('');
  const [
    actionMode,
    setActionMode,
  ] = useState('act');
  const [
    usesMessageApproval,
    setUsesMessageApproval,
  ] = useState(false);
  const [
    isGeocoded,
    setIsGeocoded,
  ] = useState(true);
  const [
    targets,
    setTargets,
  ] = useState([]);
  const [
    images,
    setImages,
  ] = useState([]);
  const [
    textColor,
    setTextColor,
  ] = useState(colors.black);
  const [
    backgroundColor,
    setBackgroundColor,
  ] = useState(colors.dodgerBlue);
  const [
    buttonColor,
    setButtonColor,
  ] = useState(colors.dodgerBlue);
  const [
    headlineText,
    setHeadlineText,
  ] = useState('');
  const [
    secondHeadlineText,
    setSecondHeadlineText,
  ] = useState('');
  const [
    logoImage,
    setLogoImage,
  ] = useState([]);
  const [
    customFields,
    setCustomFields,
  ] = useState([]);
  const [
    videoUrl,
    setVideoUrl,
  ] = useState('');
  const [
    vimeoCode,
    setVimeoCode,
  ] = useState('');
  const [
    descriptionHeadlineText,
    setDescriptionHeadlineText,
  ] = useState('');
  const [
    socialMedia,
    setSocialMedia,
  ] = useState(true);
  const [
    subjectMessagesRotationPairData,
    setSubjectMessagesRotationPairData,
  ] = useState([
    {
      body: [
        {
          ...subjectMessageEmptyPair.body[0],
          uuid: uuid(),
        },
      ],
      isAllowPersonalMessage: subjectMessageEmptyPair.isAllowPersonalMessage,
      subject: {
        ...subjectMessageEmptyPair.subject,
      },
      uuid: uuid(),
    },
  ]);
  const [
    subjectMessagesMappingPairData,
    setSubjectMessagesMappingPairData,
  ] = useState([
    {
      body: [
        {
          ...subjectMessageEmptyPair.body[0],
          uuid: uuid(),
        },
      ],
      isAllowPersonalMessage: subjectMessageEmptyPair.isAllowPersonalMessage,
      subject: {
        ...subjectMessageEmptyPair.subject,
      },
      targets: [],
      uuid: uuid(),
    },
    {
      body: [
        {
          ...subjectMessageEmptyPair.body[0],
          uuid: uuid(),
        },
      ],
      isAllowPersonalMessage: subjectMessageEmptyPair.isAllowPersonalMessage,
      subject: {
        ...subjectMessageEmptyPair.subject,
      },
      targets: [],
      uuid: uuid(),
    },
  ]);
  const [
    communicationChannel,
    setCommunicationChannel,
  ] = useState({
    email: true,
    phone: false,
    twitter: false,
    usmail: false,
  });

  const sampleThankYouText = actionMode === 'petition' ? 'Thank you for adding your name!' : 'Thank you for taking action!';

  const sampleMarkup =
  `<p><p>${sampleThankYouText}</p></p><br/ ><br />` +
  '<p>Use these buttons to share this Action Center!</p>';

  const [
    editorState,
    setEditorStateChange,
  ] = useState(EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML(sampleMarkup)
    )
  ));
  const [
    descriptionBodyState,
    setDescriptionBodyState,
  ] = useState(null);
  const [
    action,
    setAction,
  ] = useState('');
  const [
    isDonateButton,
    setIsDonateButton,
  ] = useState(false);
  const [
    linkDonate,
    setLinkDonate,
  ] = useState('');
  const [
    isUrl,
    setIsUrl,
  ] = useState(true);
  const [
    isFacebookPixel,
    setIsFacebookPixel,
  ] = useState(false);
  const [
    isGoogleAnalytics,
    setIsGoogleAnalytics,
  ] = useState(false);
  const [
    pixelId,
    setPixelId,
  ] = useState('');
  const [
    googleAnalyticsId,
    setGoogleAnalyticsId,
  ] = useState('');
  const [
    newCampaignUuid,
    setNewCampaignUuid,
  ] = useState(null);
  const [
    talkingPoints,
    setTalkingPoints,
  ] = useState('');
  const {
    messageRotation,
    messageMapping,
  } = messagePairTypes;
  const [
    messageTabIndex,
    setMessageTabIndex,
  ] = useState(messageRotation);

  const showDialog = actionTaken => {
    setAction(actionTaken);
    setIsSuccessDialogOpen(true);
  };

  const showMessageApproval = (
    organization?.flags?.showMessageApproval &&
    communicationChannel.email &&
    actionMode === 'act'
  );

  const handleEditorError = () => {
    dispatch(setSnackbarError({
      message: 'Something was wrong with your thank you message. Please overwrite the thank you again',
    }));

    setEditorStateChange(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(sampleMarkup)
        )
      )
    );
  };

  const handleAddCustomField = selectedCustomField => {
    setCustomFields(prevCustomFields => [
      ...prevCustomFields,
      selectedCustomField,
    ]);
  };

  const handleRemoveCustomField = customFieldUuid => {
    setCustomFields(
      prevCustomFields => prevCustomFields.filter(field => field.uuid !== customFieldUuid)
    );
  };

  useEffect(() => {
    setErrorMessage(null);
    scrollToTop();
  }, [step]);

  useEffect(() => {
    if (REACT_APP_MOUSEFLOW) {
      // eslint-disable-next-line no-underscore-dangle
      window._mfq = window._mfq || [];

      const mf = document.createElement('script');

      mf.type = 'text/javascript';
      mf.defer = true;
      mf.src = `//cdn.mouseflow.com/projects/${REACT_APP_MOUSEFLOW}.js`;
      document.getElementsByTagName('head')[0].appendChild(mf);
    }
  }, []);

  useEffect(() => {
    if (campaignUuid && campaignDetails?.name) {
      setCampaignValue(campaignDetails?.name);
    }
  }, [
    campaignDetails,
    campaignUuid,
  ]);

  useEffect(() => {
    const { config } = organization;

    if (!config) {
      return;
    }

    if (config.backgroundColor) {
      setBackgroundColor(config?.backgroundColor);
    }

    if (config.buttonColor) {
      setButtonColor(config?.buttonColor);
    }

    if (config.textColor) {
      setTextColor(config?.textColor);
    }
  }, [organization]);

  const prevActionCenterData = usePrevious(actionCenterData);

  useEffect(() => {
    if (
      actionCenterUuid &&
      Object.keys(actionCenterData).length &&
      !Object.keys(prevActionCenterData || {}).length &&
      actionCenterUuid === actionCenterData.uuid
    ) {
      setActionMode(actionCenterData.mode);
      setCampaignValue(actionCenterData.campaign?.name);
      setNameValue(actionCenterData.name);
      setIsGeocoded(actionCenterData.isGeocoded);
      // Always set message approval to false if the flag is off
      setUsesMessageApproval(
        actionCenterData.usesMessageApproval && organization?.flags?.showMessageApproval
      );
      setCommunicationChannel({
        email: actionCenterData.emailChannel,
        phone: actionCenterData.phoneChannel,
        twitter: false,
        usmail: false,
      });
      setTalkingPoints(actionCenterData.talkingPoints || '');
      dispatch(fetchActionCenterTargets(actionCenterUuid))
        .then(data => setTargets(data.results?.map(target => ({
          ...target,
          isSavedTarget: true,
          office: {
            state: target.state,
            title: target.officeTitle,
          },
        })) || []));

      if (actionCenterData.headlineConfig) {
        setTextColor(actionCenterData.headlineConfig.textColor || colors.black);
        setBackgroundColor(actionCenterData.headlineConfig.backgroundColor || colors.dodgerBlue);
        setButtonColor(actionCenterData.headlineConfig.buttonColor || colors.dodgerBlue);
        setHeadlineText(actionCenterData.headlineConfig.headlineText || '');
        setSecondHeadlineText(actionCenterData.headlineConfig.secondHeadlineText || '');
        setDescriptionHeadlineText(actionCenterData.headlineConfig.descriptionHeadlineText || '');
        const youtubeVideoId = actionCenterData.headlineConfig.youtubeId ? `https://www.youtube.com/watch?v=${actionCenterData.headlineConfig.youtubeId}` : '';

        setVideoUrl(youtubeVideoId);
        setVimeoCode(actionCenterData.headlineConfig.vimeoCode || '');

        if (actionCenterData.headlineConfig.descriptionBody) {
          const blocksFromHtml = htmlToDraft(actionCenterData.headlineConfig.descriptionBody);
          const {
            contentBlocks,
            entityMap,
          } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

          setDescriptionBodyState(EditorState.createWithContent(contentState));
        }
      }
      setSocialMedia(actionCenterData.socialShareButtonEnabled);
      setCustomFields(actionCenterData.customFields);

      if (actionCenterData.messages?.length) {
        if (actionCenterData.messagingType === messageRotation) {
          setSubjectMessagesRotationPairData(() => actionCenterData.messages.map(message => ({
            ...message,
            body: [
              ...message.body.map(messageBody => ({
                ...messageBody,
                uuid: messageBody.uuid || uuid(),
              })),
            ],
            uuid: message.uuid || uuid(),
          })));
        } else {
          setSubjectMessagesMappingPairData(() => actionCenterData.messages.map(message => ({
            ...message,
            body: [
              ...message.body.map(messageBody => ({
                ...messageBody,
                uuid: messageBody.uuid || uuid(),
              })),
            ],
            uuid: message.uuid || uuid(),
          })));
        }
      }
      setMessageTabIndex(actionCenterData.messagingType);
      setIsDonateButton(actionCenterData.isDonateButton);
      setLinkDonate(actionCenterData.linkDonate || '');
      setIsGoogleAnalytics(actionCenterData.googleAnalyticsEnabled);
      setGoogleAnalyticsId(actionCenterData.googleAnalyticsId);
      setIsFacebookPixel(actionCenterData.facebookPixelEnabled);
      setPixelId(actionCenterData.facebookPixelId);
      setImages(actionCenterData.landingPageImage ? [actionCenterData.landingPageImage] : []);

      if (actionCenterData.thankYouMessage) {
        let thankYouHtml = actionCenterData.thankYouMessage;
        // Checking if the wrong html is saved, the problem described here:: https://github.com/jpuri/react-draft-wysiwyg/issues/609#issuecomment-1282192676
        const regExp = /^<img/;
        const isInvalidThankYouMessage = actionCenterData.thankYouMessage.match(regExp);

        if (isInvalidThankYouMessage) {
          thankYouHtml = `<p>${thankYouHtml.replace(/\/>/, '/></p>')}`;
        }

        const blocksFromHtml = htmlToDraft(thankYouHtml);
        const {
          contentBlocks,
          entityMap,
        } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        setEditorStateChange(EditorState.createWithContent(contentState));
      }
    }
  }, [
    actionCenterUuid,
    actionCenterData,
  ]);

  const handleSaveActionCenter = async () => {
    let targetsList = targets.map(target => ({
      term: target.term,
      ...(target.isSavedTarget ? { uuid: target.uuid } : {}),
    }));

    if (!isGeocoded && actionMode !== 'petition') {
      targetsList = targetsList.slice(0, maxNonGeocodedTargets);
    }

    const params = {
      emailChannel: communicationChannel.email,
      isGeocoded,
      mailChannel: communicationChannel.usmail,
      mode: actionMode,
      name: nameValue,
      phoneChannel: communicationChannel.phone,
      slug: nameValue,
      targets: targetsList,
      twitterChannel: communicationChannel.twitter,
    };

    let actionCenterDataUuid = actionCenterData?.uuid;

    try {
      if (!actionCenterDataUuid) {
        let campaign = campaignUuid;

        if (!campaignUuid) {
          const campaignParams = {
            name: campaignValue,
            organization: organizationUuid,
          };

          const campaignData = await dispatch(postCampaign(campaignParams));

          campaign = campaignData.uuid;
          setNewCampaignUuid(campaign);
        }

        const data = await dispatch(postActionCenter({
          ...params,
          campaign,
        }));

        actionCenterDataUuid = data.uuid;

        setStep(prevStep => prevStep + 1);
      } else {
        await dispatch(patchActionCenter(actionCenterDataUuid, params));

        setStep(prevStep => prevStep + 1);
      }

      dispatch(fetchActionCenterTargets(actionCenterDataUuid))
        .then(data => setTargets(data.results?.map(target => ({
          ...target,
          isSavedTarget: true,
          office: {
            state: target.state,
            title: target.officeTitle,
          },
        })) || []));
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const youtubeId = youtubeParser(videoUrl);

  const handleSaveLandingPageContent = async () => {
    const headlineConfig = {
      backgroundColor,
      buttonColor,
      descriptionBody: descriptionBodyState ? draftToHtml(convertToRaw(descriptionBodyState.getCurrentContent())) : '',
      descriptionHeadlineText,
      headlineText,
      secondHeadlineText,
      textColor,
      vimeoCode,
      youtubeId,
    };

    const logo = new FormData();
    const landingPageImage = new FormData();

    logo.append('logo', logoImage[0]?.file);
    landingPageImage.append('landing_page_image', images[0]);

    const params = {
      customFields: customFields.map(customField => customField.uuid),
      headlineConfig,
      socialShareButtonEnabled: socialMedia,
    };

    params.facebookPixelEnabled = isFacebookPixel;

    if (pixelId && isFacebookPixel) {
      params.facebookPixelId = pixelId;
    }

    params.googleAnalyticsEnabled = isGoogleAnalytics;

    if (googleAnalyticsId && isGoogleAnalytics) {
      params.googleAnalyticsId = googleAnalyticsId;
    }

    try {
      await dispatch(patchActionCenter(actionCenterData.uuid, params));

      if (logoImage[0]) {
        await dispatch(patchActionCenter(actionCenterData.uuid, logo));
      }

      if (images[0]) {
        if (images[0].name && images[0].name !== 'defaultImage' && images[0].type !== 'text/plain') {
          await dispatch(patchActionCenter(actionCenterData.uuid, landingPageImage));
        }
      } else {
        await dispatch(patchActionCenter(actionCenterData.uuid, { landing_page_image: null }));
      }

      setStep(prevStep => prevStep + 1);
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const handleSaveMessageToTargets = async () => {
    const params = {
      messagingType: messageTabIndex,
      talkingPoints,
      usesMessageApproval,
    };

    if (messageTabIndex === messageMapping) {
      params.messageMapping = [
        {
          targets: targets
            .filter(target => target.mappedMessage !== subjectMessagesMappingPairData[1].uuid)
            .map(target => target.uuid),
          uuid: subjectMessagesMappingPairData[0].uuid,
        },
        {
          targets: targets
            .filter(target => target.mappedMessage === subjectMessagesMappingPairData[1].uuid)
            .map(target => target.uuid),
          uuid: subjectMessagesMappingPairData[1].uuid,
        },
      ];
      params.messages = subjectMessagesMappingPairData;
    } else {
      params.messages = subjectMessagesRotationPairData;
    }

    try {
      await dispatch(patchActionCenter(actionCenterData.uuid, params));

      setStep(prevStep => prevStep + 1);
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const isValidDonate = () => {
    if (isDonateButton) {
      if (!isValidURL(linkDonate)) {
        setIsUrl(false);

        return false;
      }
    }

    return true;
  };

  const saveLastStep = async publishStatus => {
    const params = {
      isDonateButton,
      linkDonate: isDonateButton ? linkDonate : null,
      publishStatus,
      thankYouMessage: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    };

    try {
      await dispatch(patchActionCenter(actionCenterData.uuid, params));

      switch (publishStatus) {
        case 2:
          showDialog('published');
          break;
        case 4:
          showDialog('saved in test mode');
          break;
        default:
          showDialog('saved');
      }
    } catch (error) {
      setErrorMessage(error);
      scrollToTop();
    }
  };

  const handlePublishActionCenter = async () => {
    if (!isValidDonate()) return;

    saveLastStep(2);
  };

  const handleSaveAsDraft = () => {
    if (!isValidDonate()) return;

    saveLastStep(1);
  };

  const handleSaveAsTestMode = () => {
    if (!isValidDonate()) return;

    saveLastStep(4);
  };

  const steps = [
    'Configure',
    'Action Center Interface',
    'Targets Messages/Talking Points',
    'Advanced Settings/Publish',
  ];

  const disableNextButton = () => {
    if (step === 0) {
      return !(nameValue && campaignValue && targets.length);
    }

    if (step === 1) {
      return !headlineText;
    }

    if (step === 3) {
      if (isDonateButton && !isUrl) return true;
    }

    if (isPending) return true;

    return false;
  };

  const handleSave = () => {
    if (step === 0) {
      handleSaveActionCenter();
    } else if (step === 1) {
      handleSaveLandingPageContent();
    } else if (step === 2) {
      handleSaveMessageToTargets();
    } else if (step === 3) {
      handlePublishActionCenter();
    }
  };

  const filteredCustomFieldsList = differenceBy(customFieldsList, customFields, 'uuid');

  const isCampaign = !!campaignUuid;

  const pageName = () => {
    if (isActionCenterFetching) {
      return '';
    }

    return actionCenterUuid ? `Edit ${actionCenterData?.name}` : `Create New Action Center ${isCampaign ? `for ${campaignValue}` : ''}`;
  };

  const buttonText = () => {
    if (step === 3) {
      return (actionCenterData?.publishStatus && actionCenterData.publishStatus === 2) ? 'Save changes' : 'Publish';
    }

    return 'Save and Continue';
  };

  const showMessageMapping = organization?.flags?.showMessageMapping && actionMode !== 'petition';

  return (
    <>
      <Container>
        <Grid container>
          <DescriptionBar
            icon={FilterListIcon}
            name={pageName()}
          >
            <CancelCreatorButton redirectUrl={`/${organizationUuid}/${campaignUuid ? `campaigns/${campaignUuid}/${actionCenterUuid ? `action-centers/${actionCenterUuid}/?tab=0` : ''}` : 'campaigns'}`} />
          </DescriptionBar>
        </Grid>
        <Stepper
          step={step}
          steps={steps}
          neutralcolors
        />
        {errorMessage && !(step === 0 && errorMessage.slug) && (
          <CardField name="Error">
            <ErrorTypography>
              {renderErrorMessage(errorMessage)}
            </ErrorTypography>
          </CardField>
        )}
        <StepOneContainer step={step}>
          <StepOne
            actionMode={actionMode}
            campaignValue={campaignValue}
            communicationChannel={communicationChannel}
            errorMessage={errorMessage}
            isCampaign={isCampaign}
            isCurrentStep={step === 0}
            isGeocoded={isGeocoded}
            nameValue={nameValue}
            organizationUuid={organizationUuid}
            setActionMode={setActionMode}
            setCampaignValue={setCampaignValue}
            setCommunicationChannel={setCommunicationChannel}
            setErrorMessage={setErrorMessage}
            setIsGeocoded={setIsGeocoded}
            setMessageTabIndex={setMessageTabIndex}
            setNameValue={setNameValue}
            setTargets={setTargets}
            showGeocodingSelection={organization?.flags?.showActionGeocoding}
            showPetitions={organization?.flags?.showPetitions}
            showPhoneActionCenter={organization?.flags?.showPhoneActionCenter}
            targets={targets}
          />
        </StepOneContainer>
        <StepTwoContainer step={step}>
          <StepTwo
            actionMode={actionMode}
            backgroundColor={backgroundColor}
            buttonColor={buttonColor}
            customFields={customFields}
            customFieldsList={filteredCustomFieldsList}
            defaultLandingPageImage={actionCenterData?.landingPageImage}
            defaultLogo={actionCenterData?.logo}
            descriptionBodyState={descriptionBodyState}
            descriptionHeadlineText={descriptionHeadlineText}
            googleAnalyticsId={googleAnalyticsId}
            handleAddCustomField={handleAddCustomField}
            handleRemoveCustomField={handleRemoveCustomField}
            headlineText={headlineText}
            isFacebookPixel={isFacebookPixel}
            isGoogleAnalytics={isGoogleAnalytics}
            logoImage={logoImage}
            pixelId={pixelId}
            secondHeadlineText={secondHeadlineText}
            setBackgroundColor={setBackgroundColor}
            setButtonColor={setButtonColor}
            setDescriptionHeadlineText={setDescriptionHeadlineText}
            setDescriptionBodyState={setDescriptionBodyState}
            setHeadlineText={setHeadlineText}
            setImages={setImages}
            setIsFacebookPixel={setIsFacebookPixel}
            setIsGoogleAnalytics={setIsGoogleAnalytics}
            setGoogleAnalyticsId={setGoogleAnalyticsId}
            setLogoImage={setLogoImage}
            setPixelId={setPixelId}
            setSecondHeadlineText={setSecondHeadlineText}
            setSocialMedia={setSocialMedia}
            setTextColor={setTextColor}
            setVideoUrl={setVideoUrl}
            setVimeoCode={setVimeoCode}
            socialMedia={socialMedia}
            textColor={textColor}
            videoUrl={videoUrl}
            vimeoCode={vimeoCode}
            youtubeId={youtubeId}
          />
        </StepTwoContainer>
        <StepThreeContainer step={step}>
          <StepThree
            actionMode={actionMode}
            communicationChannel={communicationChannel}
            isCurrentStep={step === 2}
            messageTabIndex={messageTabIndex}
            setMessageTabIndex={setMessageTabIndex}
            setSubjectMessagesRotationPairData={setSubjectMessagesRotationPairData}
            setSubjectMessagesMappingPairData={setSubjectMessagesMappingPairData}
            setTalkingPoints={setTalkingPoints}
            setTargets={setTargets}
            setUsesMessageApproval={setUsesMessageApproval}
            showMessageApproval={showMessageApproval}
            showMessageMapping={showMessageMapping}
            subjectMessagesMappingPairData={subjectMessagesMappingPairData}
            subjectMessagesRotationPairData={subjectMessagesRotationPairData}
            talkingPoints={talkingPoints}
            targets={targets}
            usesMessageApproval={usesMessageApproval}
          />
        </StepThreeContainer>
        <StepFourContainer step={step}>
          <StepFour
            editorState={editorState}
            handleEditorError={handleEditorError}
            isDonateButton={isDonateButton}
            isUrl={isUrl}
            linkDonate={linkDonate}
            setEditorStateChange={setEditorStateChange}
            setIsDonateButton={setIsDonateButton}
            setIsUrl={setIsUrl}
            setLinkDonate={setLinkDonate}
          />
        </StepFourContainer>
      </Container>
      <StepperWrapper>
        <Grid
          container
          direction="row"
          item
          justifyContent="space-between"
          lg={10}
          xs={11}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-start"
            xs={2}
          >
            <Button
              disabled={step === 0}
              onClick={() => setStep(prevStep => prevStep - 1)}
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-end"
            xs={4}
          >
            <NextButtonWrapper>
              {
                step === 3 &&
                (
                  (actionCenterData?.publishStatus && actionCenterData.publishStatus !== 2) ||
                  !actionCenterUuid
                ) &&
                (
                  <ButtonCreateWrapper>
                    <Button
                      disabled={disableNextButton() || isPending}
                      onClick={handleSaveAsDraft}
                      variant="contained"
                      type="button"
                    >
                      Save as Draft
                    </Button>
                    {isPending && <ButtonCircularProgress size={24} />}
                  </ButtonCreateWrapper>
                )
              }
              {
                step === 3 &&
                (
                  (actionCenterData?.publishStatus && actionCenterData.publishStatus !== 2) ||
                  !actionCenterUuid
                ) &&
                (
                  <ButtonCreateWrapper>
                    <Button
                      disabled={disableNextButton() || isPending}
                      onClick={handleSaveAsTestMode}
                      variant="contained"
                      type="button"
                    >
                      Save and Test
                    </Button>
                    {isPending && <ButtonCircularProgress size={24} />}
                  </ButtonCreateWrapper>
                )
              }
              <ButtonCreateWrapper>
                <Button
                  color="secondary"
                  disabled={disableNextButton() || isPending}
                  onClick={handleSave}
                  variant="contained"
                  type="button"
                >
                  {buttonText()}
                </Button>
                {isPending && <ButtonCircularProgress size={24} />}
              </ButtonCreateWrapper>
            </NextButtonWrapper>
          </Grid>
        </Grid>
      </StepperWrapper>
      <SuccessDialog
        customButton={action === 'published' ? (
          <ActionAlertMenuButton
            actionCenterUuid={actionCenterData.uuid}
            campaignUuid={campaignUuid || newCampaignUuid}
          />
        ) : null
        }
        isDialogOpen={isSuccessDialogOpen}
        thankYouMessage={`Your action center has been ${action}.`}
        redirectTo={`/${organizationUuid}/campaigns/${campaignUuid || newCampaignUuid}/action-centers/${actionCenterData.uuid}/?tab=0`}
      />
      <Backdrop isOpen={isPending || isActionCenterFetching} />
    </>
  );
};

export default CreateActionCenter;
