import {
  CLEAR_BROADCAST_EDIT,
  DELETE_BROADCAST_FAILURE,
  DELETE_BROADCAST_REQUEST,
  DELETE_BROADCAST_SUCCESS,
  DELETE_TEXT_BROADCAST_FAILURE,
  DELETE_TEXT_BROADCAST_REQUEST,
  DELETE_TEXT_BROADCAST_SUCCESS,
  FETCH_ALL_BROADCASTS_FAILURE,
  FETCH_ALL_BROADCASTS_REQUEST,
  FETCH_ALL_BROADCASTS_SUCCESS,
  FETCH_BROADCAST_FAILURE,
  FETCH_BROADCAST_LINKS_FAILURE,
  FETCH_BROADCAST_LINKS_REQUEST,
  FETCH_BROADCAST_LINKS_SUCCESS,
  FETCH_BROADCAST_RECIPIENTS_FAILURE,
  FETCH_BROADCAST_RECIPIENTS_REQUEST,
  FETCH_BROADCAST_RECIPIENTS_SUCCESS,
  FETCH_BROADCAST_REQUEST,
  FETCH_BROADCAST_SUCCESS,
  FETCH_BROADCASTS_FAILURE,
  FETCH_BROADCASTS_REQUEST,
  FETCH_BROADCASTS_SUCCESS,
  FETCH_TEXT_BROADCAST_FAILURE,
  FETCH_TEXT_BROADCAST_RECIPIENTS_FAILURE,
  FETCH_TEXT_BROADCAST_RECIPIENTS_REQUEST,
  FETCH_TEXT_BROADCAST_RECIPIENTS_SUCCESS,
  FETCH_TEXT_BROADCAST_REQUEST,
  FETCH_TEXT_BROADCAST_SUCCESS,
  PATCH_BROADCAST_FAILURE,
  PATCH_BROADCAST_REQUEST,
  PATCH_BROADCAST_SUCCESS,
  PATCH_TEXT_BROADCAST_FAILURE,
  PATCH_TEXT_BROADCAST_REQUEST,
  PATCH_TEXT_BROADCAST_SUCCESS,
  POST_BROADCAST_FAILURE,
  POST_BROADCAST_REQUEST,
  POST_BROADCAST_SUCCESS,
  POST_SEND_BROADCAST_FAILURE,
  POST_SEND_BROADCAST_REQUEST,
  POST_SEND_BROADCAST_SUCCESS,
  POST_SEND_PREVIEW_BROADCAST_FAILURE,
  POST_SEND_PREVIEW_BROADCAST_REQUEST,
  POST_SEND_PREVIEW_BROADCAST_SUCCESS,
  POST_SEND_PREVIEW_TEXT_BROADCAST_FAILURE,
  POST_SEND_PREVIEW_TEXT_BROADCAST_REQUEST,
  POST_SEND_PREVIEW_TEXT_BROADCAST_SUCCESS,
  POST_SEND_TEXT_BROADCAST_FAILURE,
  POST_SEND_TEXT_BROADCAST_REQUEST,
  POST_SEND_TEXT_BROADCAST_SUCCESS,
  POST_TEXT_BROADCAST_FAILURE,
  POST_TEXT_BROADCAST_REQUEST,
  POST_TEXT_BROADCAST_SUCCESS,
} from '../actions/broadcasts';

const initialState = {
  allBroadcasts: {
    results: [],
  },
  broadcastDetails: {},
  broadcastLinks: [],
  broadcastRecipients: [],
  broadcasts: {
    results: [],
  },
  editingBroadcastDetails: null,
  errors: null,
  isBroadcastFetching: false,
  isBroadcastLinksFetching: false,
  isBroadcastRecipientsFetching: false,
  isBroadcastsFetching: false,
  isPending: false,
  textBroadcastDetails: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_BROADCAST_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_BROADCAST_SUCCESS:
      return {
        ...state,
        broadcastDetails: {},
        isPending: false,
      };
    case DELETE_BROADCAST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_BROADCAST_REQUEST:
      return {
        ...state,
        errors: null,
        isBroadcastFetching: true,
      };
    case FETCH_BROADCAST_SUCCESS:
      return {
        ...state,
        broadcastDetails: action.payload,
        isBroadcastFetching: false,
      };
    case FETCH_BROADCAST_FAILURE:
      return {
        ...state,
        broadcastDetails: {},
        errors: action.payload,
        isBroadcastFetching: false,
      };
    case FETCH_BROADCASTS_REQUEST:
      return {
        ...state,
        isBroadcastsFetching: true,
      };
    case FETCH_BROADCASTS_SUCCESS:
      return {
        ...state,
        broadcasts: action.payload,
        errors: null,
        isBroadcastsFetching: false,
      };
    case FETCH_BROADCASTS_FAILURE:
      return {
        ...state,
        broadcasts: {
          ...state.broadcasts,
          results: [],
        },
        errors: action.payload,
        isBroadcastsFetching: false,
      };
    case POST_BROADCAST_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_BROADCAST_SUCCESS:
      return {
        ...state,
        editingBroadcastDetails: action.payload,
        errors: null,
        isPending: false,
      };
    case POST_BROADCAST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case PATCH_BROADCAST_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case PATCH_BROADCAST_SUCCESS:
      return {
        ...state,
        editingBroadcastDetails: action.payload,
        errors: null,
        isPending: false,
      };
    case PATCH_BROADCAST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case POST_SEND_BROADCAST_REQUEST:
    case POST_SEND_TEXT_BROADCAST_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_SEND_BROADCAST_SUCCESS:
    case POST_SEND_TEXT_BROADCAST_SUCCESS:
      return {
        ...state,
        errors: null,
        isPending: false,
      };
    case POST_SEND_BROADCAST_FAILURE:
    case POST_SEND_TEXT_BROADCAST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case POST_SEND_PREVIEW_BROADCAST_REQUEST:
    case POST_SEND_PREVIEW_TEXT_BROADCAST_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_SEND_PREVIEW_BROADCAST_SUCCESS:
    case POST_SEND_PREVIEW_TEXT_BROADCAST_SUCCESS:
      return {
        ...state,
        errors: null,
        isPending: false,
      };
    case POST_SEND_PREVIEW_BROADCAST_FAILURE:
    case POST_SEND_PREVIEW_TEXT_BROADCAST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case CLEAR_BROADCAST_EDIT:
      return {
        ...state,
        editingBroadcastDetails: null,
      };
    case FETCH_BROADCAST_LINKS_REQUEST:
      return {
        ...state,
        isBroadcastLinksFetching: true,
      };
    case FETCH_BROADCAST_LINKS_SUCCESS:
      return {
        ...state,
        broadcastLinks: action.payload,
        errors: null,
        isBroadcastLinksFetching: false,
      };
    case FETCH_BROADCAST_LINKS_FAILURE:
      return {
        ...state,
        broadcastLinks: [],
        errors: action.payload,
        isBroadcastLinksFetching: false,
      };
    case FETCH_BROADCAST_RECIPIENTS_REQUEST:
      return {
        ...state,
        isBroadcastRecipientsFetching: true,
      };
    case FETCH_BROADCAST_RECIPIENTS_SUCCESS:
      return {
        ...state,
        broadcastRecipients: action.payload,
        errors: null,
        isBroadcastRecipientsFetching: false,
      };
    case FETCH_BROADCAST_RECIPIENTS_FAILURE:
      return {
        ...state,
        broadcastRecipients: {
          ...state.broadcastRecipients,
          results: [],
        },
        errors: action.payload,
        isBroadcastRecipientsFetching: false,
      };
    case POST_TEXT_BROADCAST_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_TEXT_BROADCAST_SUCCESS:
      return {
        ...state,
        editingBroadcastDetails: action.payload,
        errors: null,
        isPending: false,
      };
    case POST_TEXT_BROADCAST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case PATCH_TEXT_BROADCAST_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case PATCH_TEXT_BROADCAST_SUCCESS:
      return {
        ...state,
        editingBroadcastDetails: action.payload,
        errors: null,
        isPending: false,
      };
    case PATCH_TEXT_BROADCAST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_TEXT_BROADCAST_REQUEST:
      return {
        ...state,
        errors: null,
        isBroadcastFetching: true,
      };
    case FETCH_TEXT_BROADCAST_SUCCESS:
      return {
        ...state,
        isBroadcastFetching: false,
        textBroadcastDetails: action.payload,
      };
    case FETCH_TEXT_BROADCAST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isBroadcastFetching: false,
        textBroadcastDetails: {},
      };
    case FETCH_TEXT_BROADCAST_RECIPIENTS_REQUEST:
      return {
        ...state,
        isBroadcastRecipientsFetching: true,
      };
    case FETCH_TEXT_BROADCAST_RECIPIENTS_SUCCESS:
      return {
        ...state,
        broadcastRecipients: action.payload,
        errors: null,
        isBroadcastRecipientsFetching: false,
      };
    case FETCH_TEXT_BROADCAST_RECIPIENTS_FAILURE:
      return {
        ...state,
        broadcastRecipients: {
          ...state.broadcastRecipients,
          results: [],
        },
        errors: action.payload,
        isBroadcastRecipientsFetching: false,
      };
    case DELETE_TEXT_BROADCAST_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_TEXT_BROADCAST_SUCCESS:
      return {
        ...state,
        isPending: false,
        textBroadcastDetails: {},
      };
    case DELETE_TEXT_BROADCAST_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_ALL_BROADCASTS_REQUEST:
      return {
        ...state,
        isBroadcastsFetching: true,
      };
    case FETCH_ALL_BROADCASTS_SUCCESS:
      return {
        ...state,
        allBroadcasts: action.payload,
        errors: null,
        isBroadcastsFetching: false,
      };
    case FETCH_ALL_BROADCASTS_FAILURE:
      return {
        ...state,
        allBroadcasts: {
          ...state.broadcasts,
          results: [],
        },
        errors: action.payload,
        isBroadcastsFetching: false,
      };
    default:
      return state;
  }
};
