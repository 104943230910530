import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Button,
  MenuItem,
  Select,
} from '@mui/material';
import { MergeType as MergeTypeIcon } from '@mui/icons-material';

import { setSnackbarSuccess } from 'store/actions/global';
import { addContactsToList } from 'store/actions/contacts';
import { contactSelectors } from 'store/selectors/contacts';

import { Dialog } from 'common/components';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const ADD_TO_ANOTHER_LIST_DIALOG_SUBTITLE = 'To add contacts to another list, select one from the list below';
const ADD_TO_ANOTHER_LIST_DIALOG_TITLE = 'Add contacts to another list';

const AddToAnotherListDialog = ({
  selectedRows,
}) => {
  const dispatch = useDispatch();
  const { organizationUuid } = useParams();

  const [
    isMergeToListDialogOpen,
    setMergeToListDialogOpen,
  ] = useState(false);
  const [
    addToList,
    setAddToList,
  ] = useState('');

  const {
    isPending,
    lists,
  } = useSelector(state => ({
    isPending: contactSelectors.isPendingList(state),
    lists: contactSelectors.getChildrenLists(state)[organizationUuid] || [],
  }));

  const handleMergeToAnotherList = () => {
    dispatch(addContactsToList(
      addToList,
      { contactUuids: selectedRows.map(contact => contact.uuid) }
    ))
      .then(() => {
        setMergeToListDialogOpen(false);
        dispatch(setSnackbarSuccess());
      });
  };

  return (
    <>
      <Button
        onClick={() => setMergeToListDialogOpen(true)}
        startIcon={<MergeTypeIcon />}
      >
        Add to another list
      </Button>
      <Dialog
        handleConfirm={handleMergeToAnotherList}
        handleDialogClose={() => setMergeToListDialogOpen(false)}
        isDialogOpen={isMergeToListDialogOpen}
        subtitle={ADD_TO_ANOTHER_LIST_DIALOG_SUBTITLE}
        title={ADD_TO_ANOTHER_LIST_DIALOG_TITLE}
        disabledButton={isPending}
      >
        <StyledSelect
          onChange={event => setAddToList(event.target.value)}
          value={addToList}
        >
          {lists
            .filter(list => list.type === 'StaticList')
            .map(list => (
              <MenuItem
                key={list.uuid}
                value={list.uuid}
              >
                {list.name}
              </MenuItem>
            ))}
        </StyledSelect>
      </Dialog>
    </>
  );
};

AddToAnotherListDialog.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string,
  })).isRequired,
};

export default AddToAnotherListDialog;
