import {
  useMemo,
  useRef,
 } from 'react';
import {
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import MaterialTable from '@material-table/core';
import PropTypes from 'prop-types';

import {
  Button,
  Chip,
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  DeleteOutline as DeleteOutlineIcon,
  NotificationsActive as NotificationsActiveIcon,
} from '@mui/icons-material';

import { colors } from 'themes';

import { CardWrapper } from 'globalStyles';

const MusterIcon = styled.img`
  max-height: 40px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${({ $backgroundColor }) => ($backgroundColor || '#F5F5F6')};
`;

const isEven = number => number % 2 === 0;

const TeamTable = ({
  handleRemindInvitation,
  handleRemoveInvitation,
  members,
  logo,
}) => {
  const tableRef = useRef(null);

  const {
    organizationUuid,
  } = useParams();

  const membersColumns = useMemo(() => [
      {
        cellStyle: {
          fontWeight: '500',
        },
        field: 'user.firstName',
        render: rowData => (rowData?.isInvited ? '-' : `${rowData?.user?.firstName} ${rowData?.user?.lastName}`),
        title: 'Name',
        width: '25%',
      },
      {
        field: 'email',
        render: rowData => rowData?.email || rowData?.user.email,
        title: 'Email',
      },
      {
        field: '',
        render: rowData => (
          <IconWrapper
            $backgroundColor={rowData?.isAdmin ? colors.greenBackground : colors.errorBackground}
          >
            {rowData?.isAdmin ? (
              <CheckIcon
                color="green"
                fontSize="small"
              />
            ) : (
              <CloseIcon
                color="error"
                fontSize="small"
              />
            )}
          </IconWrapper>
        ),
        title: 'Admin role',
      },
      {
        field: 'organization',
        render: rowData => (rowData?.isMuster ? <MusterIcon src="/apple-touch-icon.png" /> : <MusterIcon src={logo} />),
        title: 'Organization',
      },
      {
        field: 'status',
        render: rowData => (
          <Chip
            label={rowData?.isInvited ? 'Invited' : 'Live'}
            size="medium"
            color={rowData?.isInvited ? 'yellow' : 'green'}
          />
        ),
        title: 'Status',
      },
      {
        field: 'isInvited',
        render: rowData => (rowData?.isInvited ? (
          <Button
            disableElevation
            onClick={() => handleRemindInvitation(rowData)}
            endIcon={<NotificationsActiveIcon />}
          >
            Remind
          </Button>
        ) : ''),
        title: '',
      },
      {
        align: 'right',
        field: '',
        render: rowData => (rowData?.isInvited ? (
          <Button
            aria-label="delete"
            onClick={() => handleRemoveInvitation(rowData)}
            size="large"
            endIcon={<DeleteOutlineIcon />}
            color="grey"
          >
            Remove
          </Button>
        ) : ''),
        title: '',
      },
    ], [organizationUuid]);

  return (
    <CardWrapper id="campaign-table-wrapper">
      <MaterialTable
        tableRef={tableRef}
        key="key"
        columns={membersColumns}
        data={members}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            color: colors.osloGray,
          },
          maxColumnSort: 0,
          pageSize: 10,
          pageSizeOptions: [
            5,
            10,
            20,
          ],
          rowStyle: (_, rowNumber) => ({
            backgroundColor: isEven(rowNumber) ? '#F8F8F8' : '#FFF',
            fontSize: '14px',
          }),
          search: false,
          showFirstLastPageButtons: false,
          showTitle: false,
        }}
        style={{
          boxShadow: 'none',
        }}
      />
    </CardWrapper>
  );
};

TeamTable.propTypes = {
  handleRemindInvitation: PropTypes.func.isRequired,
  handleRemoveInvitation: PropTypes.func.isRequired,
  logo: PropTypes.string,
  members: PropTypes.arrayOf(PropTypes.shape({
    isInvited: PropTypes.bool,
    user: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  })),
};

export default TeamTable;
