import fetchAllPagedData from 'utils/dataFetching';
import request from '../../utils/request';

const PREFIX = '[BROADCASTS]';

export const FETCH_BROADCASTS_REQUEST = `${PREFIX} FETCH_BROADCASTS_REQUEST`;
export const FETCH_BROADCASTS_SUCCESS = `${PREFIX} FETCH_BROADCASTS_SUCCESS`;
export const FETCH_BROADCASTS_FAILURE = `${PREFIX} FETCH_BROADCASTS_FAILURE`;

const fetchBroadcastsRequest = () => ({
  type: FETCH_BROADCASTS_REQUEST,
});

const fetchBroadcastsSuccess = payload => ({
  payload,
  type: FETCH_BROADCASTS_SUCCESS,
});

const fetchBroadcastsFailure = payload => ({
  payload,
  type: FETCH_BROADCASTS_FAILURE,
});

export const fetchBroadcasts = params => dispatch => {
  dispatch(fetchBroadcastsRequest());

  return request
    .get('/v1/broadcasts/broadcasts/', { params })
    .then(({ data }) => {
      dispatch(fetchBroadcastsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchBroadcastsFailure(data));

      throw data;
    });
};

export const POST_BROADCAST_REQUEST = `${PREFIX} POST_BROADCAST_REQUEST`;
export const POST_BROADCAST_SUCCESS = `${PREFIX} POST_BROADCAST_SUCCESS`;
export const POST_BROADCAST_FAILURE = `${PREFIX} POST_BROADCAST_FAILURE`;

const postBroadcastRequest = () => ({
  type: POST_BROADCAST_REQUEST,
});

const postBroadcastSuccess = payload => ({
  payload,
  type: POST_BROADCAST_SUCCESS,
});

const postBroadcastFailure = payload => ({
  payload,
  type: POST_BROADCAST_FAILURE,
});

export const postBroadcast = params => dispatch => {
  dispatch(postBroadcastRequest());

  return request
    .post('/v1/broadcasts/broadcasts/', params)
    .then(({ data }) => {
      dispatch(postBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postBroadcastFailure(data));

      throw data;
    });
};

export const PATCH_BROADCAST_REQUEST = `${PREFIX} PATCH_BROADCAST_REQUEST`;
export const PATCH_BROADCAST_SUCCESS = `${PREFIX} PATCH_BROADCAST_SUCCESS`;
export const PATCH_BROADCAST_FAILURE = `${PREFIX} PATCH_BROADCAST_FAILURE`;

const patchBroadcastRequest = () => ({
  type: PATCH_BROADCAST_REQUEST,
});

const patchBroadcastSuccess = payload => ({
  payload,
  type: PATCH_BROADCAST_SUCCESS,
});

const patchBroadcastFailure = payload => ({
  payload,
  type: PATCH_BROADCAST_FAILURE,
});

export const patchBroadcast = (uuid, params) => dispatch => {
  dispatch(patchBroadcastRequest());

  return request
    .patch(`/v1/broadcasts/broadcasts/${uuid}/`, params)
    .then(({ data }) => {
      dispatch(patchBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchBroadcastFailure(data));

      throw data;
    });
};

export const FETCH_BROADCAST_REQUEST = `${PREFIX} FETCH_BROADCAST_REQUEST`;
export const FETCH_BROADCAST_SUCCESS = `${PREFIX} FETCH_BROADCAST_SUCCESS`;
export const FETCH_BROADCAST_FAILURE = `${PREFIX} FETCH_BROADCAST_FAILURE`;

const fetchBroadcastRequest = () => ({
  type: FETCH_BROADCAST_REQUEST,
});

const fetchBroadcastSuccess = payload => ({
  payload,
  type: FETCH_BROADCAST_SUCCESS,
});

const fetchBroadcastFailure = payload => ({
  payload,
  type: FETCH_BROADCAST_FAILURE,
});

export const fetchBroadcast = uuid => dispatch => {
  dispatch(fetchBroadcastRequest());

  return request
    .get(`/v1/broadcasts/broadcasts/${uuid}/`)
    .then(({ data }) => {
      dispatch(fetchBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchBroadcastFailure(data));

      throw data;
    });
};

export const POST_SEND_BROADCAST_REQUEST = `${PREFIX} POST_SEND_BROADCAST_REQUEST`;
export const POST_SEND_BROADCAST_SUCCESS = `${PREFIX} POST_SEND_BROADCAST_SUCCESS`;
export const POST_SEND_BROADCAST_FAILURE = `${PREFIX} POST_SEND_BROADCAST_FAILURE`;

const postSendBroadcastRequest = () => ({
  type: POST_SEND_BROADCAST_REQUEST,
});

const postSendBroadcastSuccess = payload => ({
  payload,
  type: POST_SEND_BROADCAST_SUCCESS,
});

const postSendBroadcastFailure = payload => ({
  payload,
  type: POST_SEND_BROADCAST_FAILURE,
});

export const postSendBroadcast = (uuid, params) => dispatch => {
  dispatch(postSendBroadcastRequest());

  return request
    .post(`/v1/broadcasts/broadcasts/${uuid}/send/`, params)
    .then(({ data }) => {
      dispatch(postSendBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postSendBroadcastFailure(data));

      throw data;
    });
};

export const POST_SEND_PREVIEW_BROADCAST_REQUEST = `${PREFIX} POST_SEND_PREVIEW_BROADCAST_REQUEST`;
export const POST_SEND_PREVIEW_BROADCAST_SUCCESS = `${PREFIX} POST_SEND_PREVIEW_BROADCAST_SUCCESS`;
export const POST_SEND_PREVIEW_BROADCAST_FAILURE = `${PREFIX} POST_SEND_PREVIEW_BROADCAST_FAILURE`;

const postSendPreviewBroadcastRequest = () => ({
  type: POST_SEND_PREVIEW_BROADCAST_REQUEST,
});

const postSendPreviewBroadcastSuccess = payload => ({
  payload,
  type: POST_SEND_PREVIEW_BROADCAST_SUCCESS,
});

const postSendPreviewBroadcastFailure = payload => ({
  payload,
  type: POST_SEND_PREVIEW_BROADCAST_FAILURE,
});

export const postSendPreviewBroadcast = (uuid, params) => dispatch => {
  dispatch(postSendPreviewBroadcastRequest());

  return request
    .post(`/v1/broadcasts/broadcasts/${uuid}/send_test/`, params)
    .then(({ data }) => {
      dispatch(postSendPreviewBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postSendPreviewBroadcastFailure(data));

      throw data;
    });
};

export const CLEAR_BROADCAST_EDIT = `${PREFIX} CLEAR_BROADCAST_EDIT`;

export const clearBroadcast = () => ({
  type: CLEAR_BROADCAST_EDIT,
});

export const DELETE_BROADCAST_REQUEST = `${PREFIX} DELETE_BROADCAST_REQUEST`;
export const DELETE_BROADCAST_SUCCESS = `${PREFIX} DELETE_BROADCAST_SUCCESS`;
export const DELETE_BROADCAST_FAILURE = `${PREFIX} DELETE_BROADCAST_FAILURE`;

const deleteBroadcastRequest = () => ({
  type: DELETE_BROADCAST_REQUEST,
});

const deleteBroadcastSuccess = payload => ({
  payload,
  type: DELETE_BROADCAST_SUCCESS,
});

const deleteBroadcastFailure = payload => ({
  payload,
  type: DELETE_BROADCAST_FAILURE,
});

export const deleteBroadcast = uuid => dispatch => {
  dispatch(deleteBroadcastRequest());

  return request
    .delete(`/v1/broadcasts/broadcasts/${uuid}/`)
    .then(({ data }) => {
      dispatch(deleteBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteBroadcastFailure(data));

      throw data;
    });
};

export const STOP_BROADCAST_REQUEST = `${PREFIX} STOP_BROADCAST_REQUEST`;
export const STOP_BROADCAST_SUCCESS = `${PREFIX} STOP_BROADCAST_SUCCESS`;
export const STOP_BROADCAST_FAILURE = `${PREFIX} STOP_BROADCAST_FAILURE`;

const stopBroadcastRequest = () => ({
  type: STOP_BROADCAST_REQUEST,
});

const stopBroadcastSuccess = payload => ({
  payload,
  type: STOP_BROADCAST_SUCCESS,
});

const stopBroadcastFailure = payload => ({
  payload,
  type: STOP_BROADCAST_FAILURE,
});

export const stopBroadcast = uuid => dispatch => {
  dispatch(stopBroadcastRequest());

  return request
    .post(`/v1/broadcasts/broadcasts/${uuid}/cancel/`)
    .then(({ data }) => {
      dispatch(stopBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(stopBroadcastFailure(data));

      throw data;
    });
};

export const FETCH_BROADCAST_LINKS_REQUEST = `${PREFIX} FETCH_BROADCAST_LINKS_REQUEST`;
export const FETCH_BROADCAST_LINKS_SUCCESS = `${PREFIX} FETCH_BROADCAST_LINKS_SUCCESS`;
export const FETCH_BROADCAST_LINKS_FAILURE = `${PREFIX} FETCH_BROADCAST_LINKS_FAILURE`;

const fetchBroadcastLinksRequest = () => ({
  type: FETCH_BROADCAST_LINKS_REQUEST,
});

const fetchBroadcastLinksSuccess = payload => ({
  payload,
  type: FETCH_BROADCAST_LINKS_SUCCESS,
});

const fetchBroadcastLinksFailure = payload => ({
  payload,
  type: FETCH_BROADCAST_LINKS_FAILURE,
});

export const fetchBroadcastLinks = broadcastuUuid => dispatch => {
  dispatch(fetchBroadcastLinksRequest());

  return request
    .get(`/v1/broadcasts/broadcasts/${broadcastuUuid}/link_clicks/`)
    .then(({ data }) => {
      dispatch(fetchBroadcastLinksSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchBroadcastLinksFailure(data));

      throw data;
    });
};

export const FETCH_BROADCAST_RECIPIENTS_REQUEST = `${PREFIX} FETCH_BROADCAST_RECIPIENTS_REQUEST`;
export const FETCH_BROADCAST_RECIPIENTS_SUCCESS = `${PREFIX} FETCH_BROADCAST_RECIPIENTS_SUCCESS`;
export const FETCH_BROADCAST_RECIPIENTS_FAILURE = `${PREFIX} FETCH_BROADCAST_RECIPIENTS_FAILURE`;

const fetchBroadcastRecipientsRequest = () => ({
  type: FETCH_BROADCAST_RECIPIENTS_REQUEST,
});

const fetchBroadcastRecipientsSuccess = payload => ({
  payload,
  type: FETCH_BROADCAST_RECIPIENTS_SUCCESS,
});

const fetchBroadcastRecipientsFailure = payload => ({
  payload,
  type: FETCH_BROADCAST_RECIPIENTS_FAILURE,
});

export const fetchBroadcastRecipients = broadcastUuid => fetchAllPagedData(
  `/v1/broadcasts/broadcasts/${broadcastUuid}/recipients/`,
  fetchBroadcastRecipientsRequest,
  fetchBroadcastRecipientsSuccess,
  fetchBroadcastRecipientsFailure
);

export const POST_TEXT_BROADCAST_REQUEST = `${PREFIX} POST_TEXT_BROADCAST_REQUEST`;
export const POST_TEXT_BROADCAST_SUCCESS = `${PREFIX} POST_TEXT_BROADCAST_SUCCESS`;
export const POST_TEXT_BROADCAST_FAILURE = `${PREFIX} POST_TEXT_BROADCAST_FAILURE`;

const postTextBroadcastRequest = () => ({
  type: POST_TEXT_BROADCAST_REQUEST,
});

const postTextBroadcastSuccess = payload => ({
  payload,
  type: POST_TEXT_BROADCAST_SUCCESS,
});

const postTextBroadcastFailure = payload => ({
  payload,
  type: POST_TEXT_BROADCAST_FAILURE,
});

export const postTextBroadcast = params => dispatch => {
  dispatch(postTextBroadcastRequest());

  return request
    .post('/v1/broadcasts/text-broadcasts/', params)
    .then(({ data }) => {
      dispatch(postTextBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postTextBroadcastFailure(data));

      throw data;
    });
};

export const PATCH_TEXT_BROADCAST_REQUEST = `${PREFIX} PATCH_TEXT_BROADCAST_REQUEST`;
export const PATCH_TEXT_BROADCAST_SUCCESS = `${PREFIX} PATCH_TEXT_BROADCAST_SUCCESS`;
export const PATCH_TEXT_BROADCAST_FAILURE = `${PREFIX} PATCH_TEXT_BROADCAST_FAILURE`;

const patchTextBroadcastRequest = () => ({
  type: PATCH_TEXT_BROADCAST_REQUEST,
});

const patchTextBroadcastSuccess = payload => ({
  payload,
  type: PATCH_TEXT_BROADCAST_SUCCESS,
});

const patchTextBroadcastFailure = payload => ({
  payload,
  type: PATCH_TEXT_BROADCAST_FAILURE,
});

export const patchTextBroadcast = (uuid, params) => dispatch => {
  dispatch(patchTextBroadcastRequest());

  return request
    .patch(`/v1/broadcasts/text-broadcasts/${uuid}/`, params)
    .then(({ data }) => {
      dispatch(patchTextBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(patchTextBroadcastFailure(data));

      throw data;
    });
};

export const POST_SEND_PREVIEW_TEXT_BROADCAST_REQUEST = `${PREFIX} POST_SEND_PREVIEW_TEXT_BROADCAST_REQUEST`;
export const POST_SEND_PREVIEW_TEXT_BROADCAST_SUCCESS = `${PREFIX} POST_SEND_PREVIEW_TEXT_BROADCAST_SUCCESS`;
export const POST_SEND_PREVIEW_TEXT_BROADCAST_FAILURE = `${PREFIX} POST_SEND_PREVIEW_TEXT_BROADCAST_FAILURE`;

const postSendPreviewTextBroadcastRequest = () => ({
  type: POST_SEND_PREVIEW_TEXT_BROADCAST_REQUEST,
});

const postSendPreviewTextBroadcastSuccess = payload => ({
  payload,
  type: POST_SEND_PREVIEW_TEXT_BROADCAST_SUCCESS,
});

const postSendPreviewTextBroadcastFailure = payload => ({
  payload,
  type: POST_SEND_PREVIEW_TEXT_BROADCAST_FAILURE,
});

export const postSendPreviewTextBroadcast = (uuid, params) => dispatch => {
  dispatch(postSendPreviewTextBroadcastRequest());

  return request
    .post(`/v1/broadcasts/text-broadcasts/${uuid}/send_test/`, params)
    .then(({ data }) => {
      dispatch(postSendPreviewTextBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postSendPreviewTextBroadcastFailure(data));

      throw data;
    });
};

export const POST_SEND_TEXT_BROADCAST_REQUEST = `${PREFIX} POST_SEND_TEXT_BROADCAST_REQUEST`;
export const POST_SEND_TEXT_BROADCAST_SUCCESS = `${PREFIX} POST_SEND_TEXT_BROADCAST_SUCCESS`;
export const POST_SEND_TEXT_BROADCAST_FAILURE = `${PREFIX} POST_SEND_TEXT_BROADCAST_FAILURE`;

const postSendTextBroadcastRequest = () => ({
  type: POST_SEND_TEXT_BROADCAST_REQUEST,
});

const postSendTextBroadcastSuccess = payload => ({
  payload,
  type: POST_SEND_TEXT_BROADCAST_SUCCESS,
});

const postSendTextBroadcastFailure = payload => ({
  payload,
  type: POST_SEND_TEXT_BROADCAST_FAILURE,
});

export const postSendTextBroadcast = (uuid, params) => dispatch => {
  dispatch(postSendTextBroadcastRequest());

  return request
    .post(`/v1/broadcasts/text-broadcasts/${uuid}/send/`, params)
    .then(({ data }) => {
      dispatch(postSendTextBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(postSendTextBroadcastFailure(data));

      throw data;
    });
};

export const FETCH_TEXT_BROADCAST_REQUEST = `${PREFIX} FETCH_TEXT_BROADCAST_REQUEST`;
export const FETCH_TEXT_BROADCAST_SUCCESS = `${PREFIX} FETCH_TEXT_BROADCAST_SUCCESS`;
export const FETCH_TEXT_BROADCAST_FAILURE = `${PREFIX} FETCH_TEXT_BROADCAST_FAILURE`;

const fetchTextBroadcastRequest = () => ({
  type: FETCH_TEXT_BROADCAST_REQUEST,
});

const fetchTextBroadcastSuccess = payload => ({
  payload,
  type: FETCH_TEXT_BROADCAST_SUCCESS,
});

const fetchTextBroadcastFailure = payload => ({
  payload,
  type: FETCH_TEXT_BROADCAST_FAILURE,
});

export const fetchTextBroadcast = uuid => dispatch => {
  dispatch(fetchTextBroadcastRequest());

  return request
    .get(`/v1/broadcasts/text-broadcasts/${uuid}/`)
    .then(({ data }) => {
      dispatch(fetchTextBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchTextBroadcastFailure(data));

      throw data;
    });
};

export const STOP_TEXT_BROADCAST_REQUEST = `${PREFIX} STOP_TEXT_BROADCAST_REQUEST`;
export const STOP_TEXT_BROADCAST_SUCCESS = `${PREFIX} STOP_TEXT_BROADCAST_SUCCESS`;
export const STOP_TEXT_BROADCAST_FAILURE = `${PREFIX} STOP_TEXT_BROADCAST_FAILURE`;

const stopTextBroadcastRequest = () => ({
  type: STOP_TEXT_BROADCAST_REQUEST,
});

const stopTextBroadcastSuccess = payload => ({
  payload,
  type: STOP_TEXT_BROADCAST_SUCCESS,
});

const stopTextBroadcastFailure = payload => ({
  payload,
  type: STOP_TEXT_BROADCAST_FAILURE,
});

export const stopTextBroadcast = uuid => dispatch => {
  dispatch(stopTextBroadcastRequest());

  return request
    .post(`/v1/broadcasts/text-broadcasts/${uuid}/cancel/`)
    .then(({ data }) => {
      dispatch(stopTextBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(stopTextBroadcastFailure(data));

      throw data;
    });
};

export const FETCH_TEXT_BROADCAST_RECIPIENTS_REQUEST = `${PREFIX} FETCH_BROADCAST_RECIPIENTS_REQUEST`;
export const FETCH_TEXT_BROADCAST_RECIPIENTS_SUCCESS = `${PREFIX} FETCH_BROADCAST_RECIPIENTS_SUCCESS`;
export const FETCH_TEXT_BROADCAST_RECIPIENTS_FAILURE = `${PREFIX} FETCH_BROADCAST_RECIPIENTS_FAILURE`;

const fetchTextBroadcastRecipientsRequest = () => ({
  type: FETCH_TEXT_BROADCAST_RECIPIENTS_REQUEST,
});

const fetchTextBroadcastRecipientsSuccess = payload => ({
  payload,
  type: FETCH_TEXT_BROADCAST_RECIPIENTS_SUCCESS,
});

const fetchTextBroadcastRecipientsFailure = payload => ({
  payload,
  type: FETCH_TEXT_BROADCAST_RECIPIENTS_FAILURE,
});

export const fetchTextBroadcastRecipients = broadcastUuid => fetchAllPagedData(
  `/v1/broadcasts/text-broadcasts/${broadcastUuid}/recipients/`,
  fetchTextBroadcastRecipientsRequest,
  fetchTextBroadcastRecipientsSuccess,
  fetchTextBroadcastRecipientsFailure
);

export const DELETE_TEXT_BROADCAST_REQUEST = `${PREFIX} DELETE_TEXT_BROADCAST_REQUEST`;
export const DELETE_TEXT_BROADCAST_SUCCESS = `${PREFIX} DELETE_TEXT_BROADCAST_SUCCESS`;
export const DELETE_TEXT_BROADCAST_FAILURE = `${PREFIX} DELETE_TEXT_BROADCAST_FAILURE`;

const deleteTextBroadcastRequest = () => ({
  type: DELETE_TEXT_BROADCAST_REQUEST,
});

const deleteTextBroadcastSuccess = payload => ({
  payload,
  type: DELETE_TEXT_BROADCAST_SUCCESS,
});

const deleteTextBroadcastFailure = payload => ({
  payload,
  type: DELETE_TEXT_BROADCAST_FAILURE,
});

export const deleteTextBroadcast = uuid => dispatch => {
  dispatch(deleteTextBroadcastRequest());

  return request
    .delete(`/v1/broadcasts/text-broadcasts/${uuid}/`)
    .then(({ data }) => {
      dispatch(deleteTextBroadcastSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(deleteTextBroadcastFailure(data));

      throw data;
    });
};

export const FETCH_ALL_BROADCASTS_REQUEST = `${PREFIX} FETCH_ALL_BROADCASTS_REQUEST`;
export const FETCH_ALL_BROADCASTS_SUCCESS = `${PREFIX} FETCH_ALL_BROADCASTS_SUCCESS`;
export const FETCH_ALL_BROADCASTS_FAILURE = `${PREFIX} FETCH_ALL_BROADCASTS_FAILURE`;

const fetchAllBroadcastsRequest = () => ({
  type: FETCH_ALL_BROADCASTS_REQUEST,
});

const fetchAllBroadcastsSuccess = payload => ({
  payload,
  type: FETCH_ALL_BROADCASTS_SUCCESS,
});

const fetchAllBroadcastsFailure = payload => ({
  payload,
  type: FETCH_ALL_BROADCASTS_FAILURE,
});

export const fetchAllBroadcasts = params => dispatch => {
  dispatch(fetchAllBroadcastsRequest());

  return request
    .get('/v1/broadcasts/broadcasts/all/', { params })
    .then(({ data }) => {
      dispatch(fetchAllBroadcastsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchAllBroadcastsFailure(data));

      throw data;
    });
};
