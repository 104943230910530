import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

const StyledTableRow = styled(TableRow)`
  height: 60px;
`;

const StyledTableCell = styled(TableCell)`
  width: 50%;
  padding-left: 0;
`;

const MembershipsTable = ({
  childrenLists,
  memberships,
}) => {
  const renderListRow = (membershipUuid, staticList) => {
    const list = childrenLists.find(({ uuid }) => uuid === staticList);

    return list ? (
      <StyledTableRow key={membershipUuid}>
        <StyledTableCell>
          {list.name}
        </StyledTableCell>
      </StyledTableRow>
    ) : null;
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>List</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>
              All contacts
            </StyledTableCell>
          </StyledTableRow>
          {memberships.map(membership => (
            renderListRow(membership.uuid, membership.staticList)
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

MembershipsTable.defaultProps = {
  childrenLists: [],
  memberships: [],
};

MembershipsTable.propTypes = {
  childrenLists: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
    })
  ),
  memberships: PropTypes.arrayOf(
    PropTypes.shape({
      staticList: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
};

export default MembershipsTable;
