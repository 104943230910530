export const timezones = [
  'US/Eastern',
  'US/Central',
  'US/Mountain',
  'US/Arizona',
  'US/Pacific',
  'US/Alaska',
  'US/Hawaii',
];
// eslint-disable-next-line max-len
export const initialTimezone = defaultTimezone => (timezones.indexOf(defaultTimezone) >= 0 ? defaultTimezone : timezones[0]);
