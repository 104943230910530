import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { fetchTemplate } from 'store/actions/templates';
import { templatesSelectors } from 'store/selectors/templates';

import {
  Button,
  Grid,
} from '@mui/material';
import {
  PeopleAlt as PeopleAltIcon,
} from '@mui/icons-material';

import {
  Backdrop,
  BreadcrumbsNavigation,
  Container,
  DescriptionBar,
} from 'common/components';

const TemplateDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    organizationUuid,
    templateUuid,
  } = useParams();

  const [
    templateData,
    setTemplateData,
  ] = useState({});

  const {
    isFetching,
  } = useSelector(state => ({
    isFetching: templatesSelectors.isFetching(state),
  }));

  const breadcrumbs = [
    {
      link: templateData?.type === 'broadcast' ? `/${organizationUuid}/broadcasts` : `/${organizationUuid}/campaigns`,
      name: templateData?.type === 'broadcast' ? 'Broadcasts' : 'Advocacy',
    },
    {
      link: `/${organizationUuid}/templates/${templateData?.type === 'broadcast' ? 'broadcast' : 'action_alert'}`,
      name: 'Email Templates',
    },
    {
      link: '',
      name: templateData.name,
    },
  ];

  useEffect(() => {
    dispatch(fetchTemplate(templateUuid))
      .then(data => setTemplateData(data));
  }, []);

  return (
    <>
      <BreadcrumbsNavigation
        links={breadcrumbs}
        isLoading={isFetching}
      />
      <Container marginBottom="40">
        <Grid container>
          <DescriptionBar
            name={templateData.name}
            icon={PeopleAltIcon}
            goBackLink={`/${organizationUuid}/templates/${templateData.type}`}
          >
            <Button onClick={() => history.push(`/${organizationUuid}/create-template/${templateData.type}/${templateUuid}`)}>Edit</Button>
          </DescriptionBar>
        </Grid>
        <Grid>
          {templateData.message && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: templateData.message,
              }}
            />
          )}
        </Grid>
      </Container>
      <Backdrop isOpen={isFetching} />
    </>
  );
};

TemplateDetails.propTypes = {
  location: PropTypes.shape({
    listProps: PropTypes.shape({
      listId: PropTypes.string,
    }),
  }).isRequired,
};

export default TemplateDetails;
