import {
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import {
  Field,
  Form,
} from 'react-final-form';

import { useConfirm } from 'material-ui-confirm';
import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';

import {
  deleteEmailDomain,
  fetchEmailDomains,
  postEmailDomains,
} from 'store/actions/organizations';
import { organizationSelectors } from 'store/selectors/organizations';
import { setSnackbarSuccess } from 'store/actions/global';
import {
  domainEmailFormFields,
} from 'utils/constants';
import {
  Bold,
  FormWrapper,
  Mono,
  SettingsWrapper,
  StyledHighlightOffIcon,
} from 'globalStyles';

const RemoveButton = styled(Button)`
  margin-bottom: 40px;
`;

const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;

const StyledField = styled(Field)`
  width: 50%;
  padding-top: 20px;
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 20px;
`;

const DNSDataList = styled.ul`
  margin: 0;
  padding: 20px 0;
`;

const DNSDataPoint = styled.li`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  list-style-type: none;
`;

const ADD_BUTTON_TEXT = 'Add';
const DELETE_BUTTON_TEXT = 'Delete';
const EMAIL_DOMAIN_TITLE = 'Sending domain';
const HELP_CENTER_ARTICLE = 'https://help.muster.com/en/articles/7170321-authenticating-your-email-sending-domain';

const DomainTab = () => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { organizationUuid } = useParams();

  const {
    emailDomainData,
  } = useSelector(state => ({
    emailDomainData: organizationSelectors.getEmailDomainData(state),
  }));

  useEffect(() => {
    dispatch(fetchEmailDomains(organizationUuid));
  }, []);

  const handleDeleteDomain = uuid => {
    confirm({
      confirmationText: 'Delete',
      description: 'This will remove the domain from your account and disable DKIM email authentication from Muster',
      title: 'Delete sending domain',
    })
      .then(() => dispatch(deleteEmailDomain(uuid)))
      .then(() => dispatch(fetchEmailDomains(organizationUuid)));
  };

  const handleDomainSubmit = async values => {
    const params = {
      ...values,
      organization: organizationUuid,
     };

    try {
      await dispatch(postEmailDomains(params));
      dispatch(fetchEmailDomains(organizationUuid));
      dispatch(setSnackbarSuccess({ message: 'Correctly added Domain' }));

      return 'success';
    } catch (error) {
      return error;
    }
  };

  const sendingDomain = emailDomainData[0]?.domain;
  const hasDomain = !!emailDomainData[0]?.domain;
  const dnsConfiguration = emailDomainData[0]?.dns;
  const spfValid = emailDomainData[0]?.spfValid;
  const spfDomainFound = emailDomainData[0]?.spfDomainFound;
  const spfDomainTarget = emailDomainData[0]?.spfDomainTarget;

  return (
    <SettingsWrapper>
      <FormWrapper>
        <Typography variant="h6">{EMAIL_DOMAIN_TITLE}</Typography>
        {!hasDomain && (
        <div>
          <p>
            Your sending domain is the domain in your email address. E.g.
            if your email address is
            {' '}
            <strong>john.doe@example.com</strong>
            {' '}
            then your sending domain is
            {' '}
            <strong>example.com</strong>
          </p>
          <p>
            Muster will generate custom DNS records you can use to authenticate your domain
            for sending email from Muster.
            See our
            {' '}
            <a href={HELP_CENTER_ARTICLE}>detailed help article</a>
            {' '}
            for complete instructions.
          </p>
        </div>
          )
        }
        <StyledDivider />
        <Form
          initialValues={emailDomainData[0]}
          onSubmit={handleDomainSubmit}
          render={({
            handleSubmit,
            pristine,
            submitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
              >
                {domainEmailFormFields.map(({
                  label,
                  name,
                  type,
                }) => (
                  <Grid
                    alignContent="space-between"
                    container
                    item
                    key={label}
                    xs={12}
                  >
                    <StyledField name={name}>
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          disabled={hasDomain}
                          error={Boolean(meta.touched && (meta.error || meta.submitError))}
                          fullWidth
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          label={label}
                          type={type}
                        />
                      )}
                    </StyledField>
                  </Grid>
                ))}
              </Grid>
              {dnsConfiguration && (
                <>
                  <p>
                    Use the DNS records below to authenticate your domain
                    for sending email from Muster.
                    See our
                    {' '}
                    <a href={HELP_CENTER_ARTICLE}>detailed help article</a>
                    {' '}
                    for complete instructions. If you need to make changes to
                    the domain, delete it and then add a replacement.
                  </p>
                  <p>
                    ⚠️ The records below include the full domain name, including
                    the root domain. Many DNS providers will require only
                    the subdomain portion of the name, e.g.
                    {' '}
                    <Mono>dkim._domainkey</Mono>
                    {' '}
                    and not
                    {' '}
                    <Mono>{`dkim._domainkey.${emailDomainData[0].domain}`}</Mono>
                    .
                  </p>
                  <h3>DKIM Configuration</h3>
                  {dnsConfiguration?.map(({
                    label,
                    name,
                    valid,
                    value,
                  }) => (
                    <DNSDataList key={label}>
                      <DNSDataPoint>
                        Host name:
                        &nbsp;
                        <Mono>{name}</Mono>
                      </DNSDataPoint>
                      <DNSDataPoint>
                        Record type:
                        &nbsp;
                        <Mono>CNAME</Mono>
                      </DNSDataPoint>
                      <DNSDataPoint>
                        Value:
                        &nbsp;
                        <Mono>{value}</Mono>
                      </DNSDataPoint>
                      <DNSDataPoint>
                        {'Valid: '}
                        {valid ? (<CheckCircleOutlineIcon color="secondary" />) : (
                          <StyledHighlightOffIcon />)}
                      </DNSDataPoint>
                    </DNSDataList>
                  ))}
                  <h3>SPF Configuration</h3>
                  <DNSDataList key="spf">
                    <DNSDataPoint>
                      Host name:
                      &nbsp;
                      <Mono>{sendingDomain}</Mono>
                    </DNSDataPoint>
                    <DNSDataPoint>
                      Record type:
                      &nbsp;
                      <Mono>TXT</Mono>
                    </DNSDataPoint>
                    <DNSDataPoint>
                      Found value:
                      &nbsp;
                      <Mono>
                        {spfDomainFound}
                      </Mono>
                    </DNSDataPoint>
                    <DNSDataPoint>
                      Target alue:
                      &nbsp;
                      <Mono>
                        {spfValid ?
                          spfDomainFound :
                          spfDomainTarget
                        }
                      </Mono>
                    </DNSDataPoint>
                    <DNSDataPoint>
                      {'Valid: '}
                      {spfValid ? (<CheckCircleOutlineIcon color="secondary" />) : (
                        <StyledHighlightOffIcon />)}
                    </DNSDataPoint>
                  </DNSDataList>
                    {!spfValid &&
                    (
                      <>
                        <p>
                          Please note, if you just added your sending domain, the initial
                          verification check could still be pending.
                        </p>
                        <p>
                          Otherwise, ensure your existing SPF record includes the stanza
                          {' '}
                          <Mono><Bold>include:_spf.muster.com</Bold></Mono>
                        </p>
                      </>
                    )
                    }
                </>
              )}
              <StyledGrid
                container
                item
                justifyContent="flex-end"
                xs={12}
              >
                {emailDomainData[0] ? (
                  <RemoveButton
                    color="inherit"
                    disabled={submitting}
                    disableElevation
                    startIcon={<DeleteOutlineIcon />}
                    onClick={() => handleDeleteDomain(emailDomainData[0].uuid)}
                    type="button"
                    variant="contained"
                  >
                    {DELETE_BUTTON_TEXT}
                  </RemoveButton>
                ) : (
                  <Button
                    color="secondary"
                    disabled={submitting || pristine}
                    disableElevation
                    type="submit"
                    variant="contained"
                  >
                    {ADD_BUTTON_TEXT}
                  </Button>
                )}
              </StyledGrid>
            </form>
            )}
        />
      </FormWrapper>
    </SettingsWrapper>
  );
};

DomainTab.propTypes = {};

export default DomainTab;
