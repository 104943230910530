import {
  useEffect,
  useMemo,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';

import {
  EmailOutlined as EmailOutlinedIcon,
  LocalPhoneOutlined as LocalPhoneOutlinedIcon,
  OpenInBrowserOutlined as OpenInBrowserOutlinedIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
  TaskOutlined as TaskOutlinedIcon,
} from '@mui/icons-material';

import { campaignsSelectors } from 'store/selectors/campaigns';

import {
  QueryIndexTabs,
  StatisticTiles,
} from 'common/components';

import { colors } from 'utils/constants';
import {
  useQuery,
} from 'utils/helpers';
import { CardWrapper } from 'globalStyles';

const actionCenterTabs = [
  {
    label: 'Overview',
    value: '0',
  },
  {
    label: 'Analytics',
    value: '1',
  },
  {
    label: 'Message Preview',
    value: '2',
  },
  {
    label: 'Widgets',
    value: '3',
  },
];

const StatisticWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 40px;
`;

const ActionCenterOverview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const tabIndex = query.get('tab');

  const {
    actionCenterUuid,
  } = useParams();

  useEffect(() => {
    if (!tabIndex) {
      history.push({
        search: `?tab=${actionCenterTabs[0].value}`,
      });
    }
  }, [
    actionCenterUuid,
    dispatch,
  ]);

  const {
    actionCenterDetails,
  } = useSelector(state => ({
    actionCenterDetails: campaignsSelectors.getActionCenterDetails(state),
  }));

  const {
    mode,
    statistics,
  } = actionCenterDetails;

  const tiles = useMemo(() => {
    const options = [
      {
        count: statistics?.advocates?.totalCount,
        icon: (
          <PeopleAltOutlinedIcon
            color="blue"
            sx={{
              fontSize: 16,
            }}
          />
        ),
        iconColor: colors.people,
        name: 'Advocates',
      },
      {
        count: statistics?.actions?.totalCount,
        icon: (
          <TaskOutlinedIcon
            color="error"
            sx={{
              fontSize: 16,
            }}
          />
        ),
        iconColor: colors.errorBackground,
        name: mode === 'act' ? 'Actions taken' : 'Signatures',
      },
    ];

    if (mode !== 'petition') {
      options.push(
        {
          count: statistics?.phoneCalls?.totalCount,
          icon: (
            <LocalPhoneOutlinedIcon
              color="yellow"
              sx={{
                fontSize: 16,
              }}
            />
          ),
          iconColor: colors.yellowBackground,
          name: 'Phone Calls',
        },
        {
          count: statistics?.emails?.totalCount,
          icon: (
            <EmailOutlinedIcon
              color="green"
              sx={{
                fontSize: 16,
              }}
            />
          ),
          iconColor: colors.greenBackground,
          name: 'Emails',
        }
      );
    }

    return options;
  }, [
    statistics,
    mode,
  ]);

  const actionAlertTile = useMemo(() => [
    {
      count: statistics?.actionAlerts?.totalCount,
      icon: (
        <OpenInBrowserOutlinedIcon
          color="grey"
          sx={{
            fontSize: 16,
          }}
        />
      ),
      iconColor: colors.greyBackground,
      name: 'Action Alerts sent',
    },
  ], [statistics]);

  return (
    <CardWrapper>
      <QueryIndexTabs
        tabs={actionCenterTabs}
      />
      <StatisticWrapper>
        <StatisticTiles tiles={tiles} />
        <StatisticTiles tiles={actionAlertTile} />
      </StatisticWrapper>
    </CardWrapper>
  );
};

export default ActionCenterOverview;
