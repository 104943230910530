import {
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';

export function usePrevious(value) {
  const ref = useRef(null);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useCopyToClipboard = () => {
  const dispatch = useDispatch();
  const copy = (text, element = 'input', name = 'Link') => {
    if (!text) {
      return;
    }

    const tempInput = document.createElement(element);

    tempInput.value = text;

    try {
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');

      dispatch(setSnackbarSuccess({
        message: `${name} successfully copied`,
      }));
    } catch (err) {
      dispatch(setSnackbarError({
        message: 'Something went wrong, Link was not copied',
      }));
    }

    document.body.removeChild(tempInput);
  };

  return [copy];
};
