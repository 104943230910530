import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import {
  Grid,
  Link,
  Typography,
} from '@mui/material';
import {
  Email as EmailIcon,
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon,
  LocationOn as LocationOnIcon,
  Phone as PhoneIcon,
  Twitter as TwitterIcon,
} from '@mui/icons-material';

import {
  colors,
  footerLinks,
} from 'utils/constants';
import mediaQueries from 'utils/mediaQueries';

const {
  small,
  xsmall,
} = mediaQueries;

const ContactIcon = styled.svg`
  margin-right: 10px;
  font-size: 22px;
`;

const ContactsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CopyrightsSection = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 30px;
  background-color: ${colors.black};
  color: ${colors.white};
`;

const FooterLink = styled(Link)`
  margin-right: 54px;
  color: ${colors.white};

  &:hover {
    text-decoration: none;
  }
`;

const FooterSectionWrapper = styled.div`
  width: 50%;
  padding-top: 32px;
  font-size: 16px;

  @media ${small} {
    width: 100%;
  }
`;

const IconsWrapper = styled.div`
  margin-left: -3px;
  padding-top: 12px;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Paragraph = styled.p`
  margin-top: 0;
`;

const SocialIcon = styled.svg`
  margin-right: 10px;
  font-size: 32px;
`;

const SingleContact = styled.div`
  display: flex;

  &:nth-child(even) {
    width: 60%;
  }

  &:nth-child(odd) {
    width: 40%;
  }

  @media ${xsmall} {
    &:nth-child(even) {
      width: 100%;
    }

    &:nth-child(odd) {
      width: 100%;
    }
  }
`;

const StyledFooter = styled.footer`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  min-height: 160px;
  background-color: ${colors.footerBackground};
  color: ${colors.white};
`;

const Footer = () => (
  <StyledFooter>
    <Grid
      container
      item
      lg={10}
      xs={11}
    >
      <FooterSectionWrapper>
        <LinksWrapper>
          {
              footerLinks.map(({
                href,
                name,
              }) => (
                <FooterLink
                  component={RouterLink}
                  key={name}
                  to={href}
                >
                  {name}
                </FooterLink>
              ))
            }
        </LinksWrapper>
        <IconsWrapper>
          <SocialIcon as={FacebookIcon} />
          <SocialIcon as={TwitterIcon} />
          <SocialIcon as={LinkedInIcon} />
        </IconsWrapper>
      </FooterSectionWrapper>
      <FooterSectionWrapper>
        <Paragraph>Support</Paragraph>
        <ContactsWrapper>
          <SingleContact>
            <ContactIcon as={PhoneIcon} />
            <Paragraph>804.349.3727</Paragraph>
          </SingleContact>
          <SingleContact>
            <ContactIcon as={LocationOnIcon} />
            <Paragraph>3100 Clarendon Blvd. #200, Arlington VA</Paragraph>
          </SingleContact>
          <SingleContact>
            <ContactIcon as={EmailIcon} />
            <Paragraph>info@muster.com</Paragraph>
          </SingleContact>
          <SingleContact>
            <ContactIcon as={LocationOnIcon} />
            <Paragraph>10 S. 23rd Street, Richmond VA</Paragraph>
          </SingleContact>
        </ContactsWrapper>
      </FooterSectionWrapper>
    </Grid>
    <CopyrightsSection variant="subtitle2">
      <Grid
        container
        item
        lg={10}
        xs={11}
      >
        &#9400;
        2017 Muster. All rights reserved.
      </Grid>
    </CopyrightsSection>
  </StyledFooter>
);

export default Footer;
