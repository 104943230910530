import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';

import { useConfirm } from 'material-ui-confirm';
import {
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';
import {
  Announcement as AnnouncementIcon,
  Cancel as CancelIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  Restore as RestoreIcon,
} from '@mui/icons-material';

import {
  deleteActionAlert,
  fetchActionAlert,
  fetchActionAlertLinks,
  fetchActionAlertRecipients,
  fetchActionCenter,
  stopActionAlert,
} from 'store/actions/campaigns';
import { campaignsSelectors } from 'store/selectors/campaigns';
import { organizationSelectors } from 'store/selectors/organizations';
import {
  setSnackbarError,
  setSnackbarSuccess,
  setSnackbarWarning,
} from 'store/actions/global';

import {
  Backdrop,
  BreadcrumbsNavigation,
  ButtonMenu,
  Container,
  DescriptionBar,
  DescriptionBarInfo,
  LinksTable,
  RecipientsMap,
  RecipientsTable,
  StatisticCard,
  TabPanel,
} from 'common/components';
import {
  cancellationStatuses,
  colors,
  LIVE_PUBLISH_STATUS,
  statuses,
} from 'utils/constants';
import { useQuery } from 'utils/helpers';

import {
  StatisticsWrapper,
  TabsWrapper,
} from 'globalStyles';

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const StyledPaper = styled(Paper)`
  width: 100%;
`;

const StyledTabsWrapper = styled(TabsWrapper)`
  margin-bottom: 60px;
`;

const ActionAlertDetails = () => {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    actionAlertUuid,
    actionCenterUuid,
    campaignUuid,
    organizationUuid,
  } = useParams();
  const backUrl = useQuery().get('backUrl');
  const {
    draft,
    published,
  } = statuses;

  const {
    actionAlertDetails,
    actionAlertLinks,
    actionAlertRecipients,
    actionCenterDetails,
    isActionAlertFetching,
    isActionAlertLinksFetching,
    isActionAlertRecipientsFetching,
    organization: {
      flags,
    },
  } = useSelector(state => ({
    actionAlertDetails: campaignsSelectors.getActionAlertDetails(state),
    actionAlertLinks: campaignsSelectors.getActionAlertLinks(state),
    actionAlertRecipients: campaignsSelectors.getActionAlertRecipients(state),
    actionCenterDetails: campaignsSelectors.getActionCenterDetails(state),
    isActionAlertFetching: campaignsSelectors.isActionAlertFetching(state),
    isActionAlertLinksFetching: campaignsSelectors.isActionAlertLinksFetching(state),
    isActionAlertRecipientsFetching: campaignsSelectors.isActionAlertRecipientsFetching(state),
    organization: organizationSelectors.getOrganization(state),
  }));

  useEffect(() => {
    if (Object.keys(actionCenterDetails).length === 0 ||
      actionCenterUuid !== actionCenterDetails.uuid
    ) {
      dispatch(fetchActionCenter(actionCenterUuid));
    }

    dispatch(fetchActionAlert(actionAlertUuid));
    dispatch(fetchActionAlertLinks(actionAlertUuid));
  }, [actionAlertUuid]);

  const [
    tabIndex,
    setTabIndex,
  ] = useState(0);
  const [
    tablesTabIndex,
    setTablesTabIndex,
  ] = useState(0);

  const {
    canCreateCircleBack,
    campaign,
    createdBy,
    isCancellable,
    message,
    name,
    parent,
    sendAt,
    statistics,
    status,
    subject,
    type,
  } = actionAlertDetails;

  const { probablyCancelled } = cancellationStatuses;

  const firstFetch = useRef({
    recipients: false,
  });

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/campaigns`,
      name: 'Advocacy',
    },
    {
      link: `/${organizationUuid}/campaigns/${campaignUuid}`,
      name: campaign?.name,
    },
    {
      link: `/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}?tab=0`,
      name: actionCenterDetails?.name,
    },
    {
      link: '',
      name,
    },
  ];

  const getBackUrl = () => {
    if (backUrl) {
      return backUrl;
    }

    return `/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}?tab=0`;
  };

  const handleActionAlertDelete = () => {
    confirm({
      confirmationText: 'Delete',
      description: 'You won`t be able to undo that',
      title: 'Delete the Action Alert?',
    })
      .then(() => dispatch(deleteActionAlert(actionAlertUuid)))
      .then(() => history.push(`/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}?tab=0`));
  };

  const handleStopActionAlert = () => {
    dispatch(stopActionAlert(actionAlertUuid))
      .then(({
        message: responseMessage,
        status: stopedActionAlertStatus,
      }) => {
        if (stopedActionAlertStatus === probablyCancelled) {
          dispatch(setSnackbarWarning({ message: responseMessage }));
        } else {
          dispatch(setSnackbarSuccess({ message: responseMessage }));
        }
        dispatch(fetchActionAlert(actionAlertUuid));
      })
      .catch(({ message: responseMessage }) => dispatch(
        setSnackbarError({ message: responseMessage })
      ));
  };

  const handleConfirmStopActionAlert = () => {
    confirm({
      cancellationText: 'No',
      confirmationText: 'Yes, Cancel',
      description: '',
      title: 'Cancel Send the Action Alert?',
    })
      .then(() => handleStopActionAlert(actionAlertUuid));
  };

  const handleChangeTablesTab = newValue => {
    setTablesTabIndex(newValue);

    if (newValue === 1 && !firstFetch.current.recipients) {
      dispatch(fetchActionAlertRecipients(actionAlertUuid))
        .then(() => { firstFetch.current.recipients = true; });
    }
  };

  const handleNavigateToWizard = wizzardType => {
    history.push(`/${organizationUuid}/${campaignUuid}/${actionCenterUuid}/create-email-action-alert/${wizzardType}/${actionAlertUuid}${parent ? '?circle-back=true' : ''}`);
  };
  const handleOpenParentActionAlert = () => {
    setTablesTabIndex(0);
    history.push(`/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}/action-alerts/${parent}`);
    firstFetch.current.recipients = false;
  };

  const handleChangeTab = (_, newValue) => setTabIndex(newValue);

  const DescriptionInfo = (
    <DescriptionBarInfo
      createdBy={createdBy}
      isButtonVisible
      message={message}
      subject={subject}
      sendAt={sendAt}
    />
  );

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container>
        <Grid container>
          <DescriptionBar
            backButtonLink={getBackUrl()}
            chipLabel={parent ? 'Circle Back' : ''}
            descriptionBarInfo={DescriptionInfo}
            hasBackButton
            icon={AnnouncementIcon}
            isHighBar
            name="Action Alert"
            onChipClick={handleOpenParentActionAlert}
          >
            <ActionButtonsWrapper>
              {isCancellable && (
                <StyledButton
                  startIcon={<CancelIcon />}
                  disabled={!isCancellable}
                  onClick={handleConfirmStopActionAlert}
                  variant="outlined"
                >
                  Cancel send Action Alert
                </StyledButton>
              )}
              <StyledButton
                component={Link}
                disableElevation
                disabled={
                  type !== 'email' ||
                  status !== published ||
                  !(flags?.emailCircleBacks) ||
                  !canCreateCircleBack ||
                  actionCenterDetails?.publishStatus !== LIVE_PUBLISH_STATUS
                }
                to={`/${organizationUuid}/${campaignUuid}/${actionCenterUuid}/create-email-action-alert/circle-back/${actionAlertUuid}`}
                color="secondary"
                startIcon={<RestoreIcon />}
                variant="contained"
              >
                Circle Back
              </StyledButton>
              <ButtonMenu>
                <MenuItem
                  onClick={() => handleNavigateToWizard('edit')}
                  disabled={status !== draft}
                >
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText primary="Edit Action Alert" />
                </MenuItem>
                <MenuItem
                  onClick={() => handleNavigateToWizard('duplicate')}
                  disabled={actionCenterDetails?.publishStatus !== LIVE_PUBLISH_STATUS}
                >
                  <ListItemIcon>
                    <FileCopyOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Duplicate" />
                </MenuItem>
                <MenuItem onClick={handleActionAlertDelete}>
                  <ListItemIcon>
                    <DeleteOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delete" />
                </MenuItem>
              </ButtonMenu>
            </ActionButtonsWrapper>
          </DescriptionBar>
        </Grid>
        <StatisticsWrapper>
          <StatisticCard
            count={statistics?.recipients}
            text="Recipients"
          />
          <StatisticCard
            count={statistics?.opened}
            hasPercentageBar
            maxCount={statistics?.recipients}
            text="Opened"
          />
          <StatisticCard
            count={statistics?.clicked}
            hasPercentageBar
            maxCount={statistics?.recipients}
            text="Clicked"
          />
          <StatisticCard
            count={statistics?.bounced}
            hasPercentageBar
            maxCount={statistics?.recipients}
            text="Bounced"
          />
          <StatisticCard
            count={statistics?.unsubscribed}
            hasPercentageBar
            maxCount={statistics?.recipients}
            text="Unsubscribed"
          />
          {/* <StatisticCard */}
          {/*     count={statistics?.advocates} */}
          {/*     hasPercentageBar */}
          {/*     maxCount={statistics?.recipients} */}
          {/*     text="Took Action" */}
          {/* /> */}
        </StatisticsWrapper>
        <TabsWrapper>
          <StyledPaper
            square
            variant="outlined"
          >
            <Tabs
              indicatorColor="primary"
              onChange={handleChangeTab}
              TabIndicatorProps={{
                style: {
                  backgroundColor: colors.secondary,
                },
              }}
              value={tabIndex}
            >
              <Tab label="Contacts" />
              <Tab
                disabled
                label="Analytics"
              />
            </Tabs>
            <TabPanel
              index={0}
              value={tabIndex}
            >
              <RecipientsMap alertType="email" />
            </TabPanel>
          </StyledPaper>
        </TabsWrapper>
        <StyledTabsWrapper>
          <StyledPaper
            square
            variant="outlined"
          >
            <Tabs
              indicatorColor="primary"
              onChange={(_, newValue) => handleChangeTablesTab(newValue)}
              TabIndicatorProps={{
                style: {
                  backgroundColor: colors.secondary,
                },
              }}
              value={tablesTabIndex}
            >
              <Tab label="Links" />
              <Tab label="Recipients" />
            </Tabs>
            <TabPanel
              index={0}
              value={tablesTabIndex}
            >
              <LinksTable
                links={actionAlertLinks}
                isDataFetching={isActionAlertLinksFetching}
              />
            </TabPanel>
            <TabPanel
              index={1}
              value={tablesTabIndex}
            >
              <RecipientsTable
                isDataFetching={isActionAlertRecipientsFetching}
                recipients={actionAlertRecipients}
              />
            </TabPanel>
          </StyledPaper>
        </StyledTabsWrapper>
        <Backdrop isOpen={isActionAlertFetching} />
      </Container>
    </>
  );
};

export default ActionAlertDetails;
