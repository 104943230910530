import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Link as RouterLink,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';

import {
  AppBar,
  Grid,
  Link,
  Toolbar,
} from '@mui/material';

import { fetchOrganization } from 'store/actions/organizations';
import { fetchAuthStatus } from 'store/actions/auth';
import { organizationSelectors } from 'store/selectors/organizations';
import { authSelectors } from 'store/selectors/auth';

import { Backdrop } from 'common/components';
import {
  nonAuthenticatedRoutes,
  nonOrganizationRoutes,
} from 'utils/constants';
import mediaQueries from 'utils/mediaQueries';
import Snackbar from './Snackbar';

import ScrollToTop from './ScrollToTop';
import Navigation from './Navigation';
import UserDropdown from './UserDropdown';

const { small } = mediaQueries;

const LogoMark = styled.img`
  max-height: 40px;
  padding: 4px 10px 0 0;
`;

const LogoType = styled.img`
  max-height: 40px;
  padding: 9px 0 5px;
`;

// TEMP: waiting for new design for header
// const Logo = styled.img`
//   max-height: 50px;
//   margin-left: 58px;

//   @media ${small} {
//     max-height: 40px;
//     margin-left: 0;
//   }
// `;

const LogoLink = styled(Link)`
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  @media ${small} {
    flex: 1;
  }
`;

const StyledAppBar = styled(AppBar)`
  justify-content: center;
  align-items: center;
  height: 80px;
  box-shadow: none;
  background-color: ${({ theme }) => (theme.colors.headerBackground)};
`;

const StyledMain = styled.main`
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  background-color: ${({
    $hasGreyBackground,
    theme,
  }) => ($hasGreyBackground ? theme.colors.pageBackground : theme.colors.white)};
`;

const StyledToolBar = styled(Toolbar)`
  flex-grow: 1;
`;

const { REACT_APP_INTERCOM_ID } = process.env;

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [
    isLoaded,
    setIsLoaded,
  ] = useState(false);

  const {
    isAuthenticated,
    organization,
   } = useSelector(state => ({
    isAuthenticated: authSelectors.getIsAuthenticated(state),
    organization: organizationSelectors.getOrganization(state),
  }));

  const matchOrganizationUuid = useRouteMatch({
    path: '/:organizationUuid',
    strict: true,
  });
  const matchOrganizations = useRouteMatch({
    path: '/organizations',
    strict: true,
  });
  const matchActionCenterDetails = useRouteMatch({
    exact: true,
    path: '/:organizationUuid/campaigns/:campaignUuid/action-centers/:actionCenterUuid',
    strict: true,
  });
  const matchCampaignsDetails = useRouteMatch({
    exact: true,
    path: '/:organizationUuid/campaigns',
    strict: true,
  });
  const matchBroadcast = useRouteMatch({
    exact: true,
    path: '/:organizationUuid/broadcasts',
  });
  const matchSettingsDetails = useRouteMatch({
    path: '/:organizationUuid/settings',
    strict: true,
  });

  let organizationUuid = null;

  if (
    matchOrganizationUuid?.params?.organizationUuid &&
    !Object.values(nonOrganizationRoutes).some(
      nonOrganizationRoute => pathname.includes(nonOrganizationRoute)
    )
  ) {
    organizationUuid = matchOrganizationUuid?.params?.organizationUuid;
  }

  useEffect(() => {
    const fetchData = async () => {
      const authStatus = await dispatch(fetchAuthStatus());

      if (!authStatus.authenticated && !Object.values(nonAuthenticatedRoutes).includes(pathname)) {
        window.location = '/login';

        return;
      }

      if (authStatus.authenticated && window.location.pathname === '/login') {
        window.location = '/organizations';

        return;
      }

      if (Object.keys(organization) && organizationUuid) {
        await dispatch(fetchOrganization(organizationUuid))
          .catch(status => {
            if (status === 404) {
              window.location = '/organizations';
            }
          });
      }

      setIsLoaded(true);
    };

    fetchData();
  }, [
    dispatch,
    organizationUuid,
  ]);

  useEffect(() => {
    /* eslint-disable func-names */
    const w = window; const ic = w.Intercom;

    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;

      const i = function (...args) {
        i.c(args);
      };

      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');

        s.type = 'text/javascript'; s.async = true;
        s.src = `https://widget.intercom.io/widget/${REACT_APP_INTERCOM_ID}/`;
        const x = d.getElementsByTagName('script')[0];

        x.parentNode.insertBefore(s, x);
      };

      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  }, []);

  const handleHasGreyBackground = useMemo(() => {
    if (
      matchActionCenterDetails?.path ||
      matchCampaignsDetails?.path ||
      matchBroadcast?.path ||
      matchSettingsDetails?.path
    ) {
      return true;
    }

    return false;
  }, [
    matchActionCenterDetails?.path,
    matchCampaignsDetails?.path,
    matchBroadcast?.path,
    matchSettingsDetails?.path,
  ]);

  return (
    <>
      {isAuthenticated && (
        <StyledAppBar position="relative">
          <ScrollToTop />
          <Grid
            container
            item
            lg={10}
            xs={11}
          >
            <StyledToolBar disableGutters>
              <LogoLink
                component={RouterLink}
                disabled={matchOrganizations?.path}
                to="/organizations"
              >
                <LogoMark
                  alt="Muster logomark"
                  src="/muster_logomark.png"
                />
                <LogoType
                  alt="Muster logotype"
                  src="/muster_logotype.png"
                />
              </LogoLink>
              <Navigation />
              <UserDropdown />
            </StyledToolBar>
          </Grid>
          <Snackbar />
        </StyledAppBar>
      )}
      {isLoaded && (
        <StyledMain $hasGreyBackground={handleHasGreyBackground}>{children}</StyledMain>
      )}
      <Backdrop isOpen={!isLoaded} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
