/* eslint-disable sort-keys */
const mediaQueries = {
  xlarge: '1920px',
  large: '1600px',
  medium: '1280px',
  small: '960px',
  xsmall: '600px',
  xxsmall: '350px',
};

const {
  xlarge,
  large,
  medium,
  small,
  xsmall,
  xxsmall,
} = mediaQueries;

const queries = {
  xlarge: `(max-width: ${xlarge})`,
  large: `(max-width: ${large})`,
  medium: `(max-width: ${medium})`,
  small: `(max-width: ${small})`,
  xsmall: `(max-width: ${xsmall})`,
  xxsmall: `(max-width: ${xxsmall})`,
  touch: '(pointer: coarse)',
};

export default queries;
