import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Field,
  Form,
} from 'react-final-form';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MaterialDialog,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

const StyledDialogContent = styled(DialogContent)`
  display: flex;
`;

const StyledSelect = styled(Select)`
  margin-left: 20px;
`;

const ApiKeysFormModal = ({
  data,
  handleDialogClose,
  onSubmit,
}) => (
  <MaterialDialog
    aria-labelledby="dialog-title"
    onClose={handleDialogClose}
    fullWidth
    open={data.isDialogOpen}
  >
    <DialogTitle id="dialog-title">Update your API Key</DialogTitle>
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <StyledDialogContent>
            <Field name="name">
              {({
              input,
              meta,
            }) => (
              <TextField
                {...input}
                error={Boolean(meta.touched && (meta.error || meta.submitError))}
                helperText={meta.touched && (meta.error || meta.submitError)}
                label="name"
                type="text"
              />
            )}
            </Field>
            <Field name="isActive">
              {({
              input,
            }) => (
              <StyledSelect {...input}>
                <MenuItem value>
                  Active
                </MenuItem>
                <MenuItem
                  value={false}
                >
                  Inactive
                </MenuItem>
              </StyledSelect>
            )}
            </Field>
          </StyledDialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={submitting}
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              disabled={submitting}
              disableElevation
              type="submit"
              variant="contained"
            >
              Update
            </Button>
          </DialogActions>
        </form>
      )}
    />
  </MaterialDialog>
);

ApiKeysFormModal.propTypes = {
  data: PropTypes.shape({
    isActive: PropTypes.bool,
    isDialogOpen: PropTypes.bool,
    name: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ApiKeysFormModal;
