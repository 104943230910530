import { useDispatch } from 'react-redux';
import {
  Field,
  Form,
} from 'react-final-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  Grid,
  TextField,
} from '@mui/material';

import { setSnackbarSuccess } from 'store/actions/global';

import { required } from 'utils/validators';

const StyledField = styled(Field)`
  width: 50%;
  padding-top: 20px;
`;

const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;

const CONFIRM_PASSWORD_LABEL = 'Confirm';
const NEW_PASSWORD_LABEL = 'New';
const OLD_PASSWORD_LABEL = 'Old';
const PASSWORD_CHANGE_SUCCESS_MESSAGE = 'Password was changed successfully';
const PASSWORD_NOT_MATCH = 'New password and confirmed password don\'t match';
const UPDATE_BUTTON_TEXT = 'Update';

const UpdatePasswordForm = ({ onSubmit }) => {
  const dispatch = useDispatch();

  return (
    <Form
      onSubmit={onSubmit}
      validate={({
        confirmedPassword,
        newPassword,
      }) => {
        const errors = {};

        if (newPassword !== confirmedPassword) {
          errors.newPassword = PASSWORD_NOT_MATCH;
          errors.confirmedPassword = PASSWORD_NOT_MATCH;
        }

        return errors;
      }}
      render={({
        form,
        handleSubmit,
        pristine,
        submitting,
      }) => (
        <form onSubmit={async event => {
          const response = await handleSubmit(event);

          if (response === 'success') {
            dispatch(setSnackbarSuccess({ message: PASSWORD_CHANGE_SUCCESS_MESSAGE }));
            form.restart();
          }
        }}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              alignContent="space-between"
              container
              item
              xs={12}
            >
              <StyledField
                name="oldPassword"
                validate={required}
              >
                {({
                  input,
                  meta,
                }) => (
                  <TextField
                    {...input}
                    error={meta.touched && (meta.error || meta.submitError)}
                    fullWidth
                    helperText={meta.touched && (meta.error || meta.submitError)}
                    label={OLD_PASSWORD_LABEL}
                    type="password"
                  />
                )}
              </StyledField>
            </Grid>
            <Grid
              alignContent="space-between"
              container
              item
              xs={12}
            >
              <StyledField
                name="newPassword"
                validate={required}
              >
                {({
                  input,
                  meta,
                }) => (
                  <TextField
                    {...input}
                    error={meta.touched && (meta.error || meta.submitError)}
                    fullWidth
                    helperText={meta.touched && (meta.error || meta.submitError)}
                    label={NEW_PASSWORD_LABEL}
                    type="password"
                  />
                )}
              </StyledField>
            </Grid>
            <Grid
              alignContent="space-between"
              container
              item
              xs={12}
            >
              <StyledField
                name="confirmedPassword"
                validate={required}
              >
                {({
                  input,
                  meta,
                }) => (
                  <TextField
                    {...input}
                    error={meta.touched && (meta.error || meta.submitError)}
                    fullWidth
                    helperText={meta.touched && (meta.error || meta.submitError)}
                    label={CONFIRM_PASSWORD_LABEL}
                    type="password"
                  />
                )}
              </StyledField>
            </Grid>
          </Grid>
          <StyledGrid
            container
            item
            justifyContent="flex-end"
            xs={12}
          >
            <Button
              color="secondary"
              disabled={submitting || pristine}
              disableElevation
              type="submit"
              variant="contained"
            >
              {UPDATE_BUTTON_TEXT}
            </Button>
          </StyledGrid>
        </form>
      )}
    />
  );
};

UpdatePasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default UpdatePasswordForm;
