import {
  FETCH_ALL_TEMPLATES_FAILURE,
  FETCH_ALL_TEMPLATES_REQUEST,
  FETCH_ALL_TEMPLATES_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  FETCH_TEMPLATE_REQUEST,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATES_FAILURE,
  FETCH_TEMPLATES_REQUEST,
  FETCH_TEMPLATES_SUCCESS,
  PATCH_TEMPLATE_FAILURE,
  PATCH_TEMPLATE_REQUEST,
  PATCH_TEMPLATE_SUCCESS,
  POST_TEMPLATE_FAILURE,
  POST_TEMPLATE_REQUEST,
  POST_TEMPLATE_SUCCESS,
} from '../actions/templates';

const initialState = {
  errors: null,
  isFetching: false,
  isPending: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_TEMPLATES_REQUEST:
    case FETCH_TEMPLATE_REQUEST:
    case FETCH_TEMPLATES_REQUEST:
      return {
        ...state,
        errors: null,
        isFetching: true,
      };
    case FETCH_ALL_TEMPLATES_SUCCESS:
    case FETCH_TEMPLATE_SUCCESS:
    case FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_ALL_TEMPLATES_FAILURE:
    case FETCH_TEMPLATE_FAILURE:
    case FETCH_TEMPLATES_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };
    case PATCH_TEMPLATE_REQUEST:
    case POST_TEMPLATE_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case PATCH_TEMPLATE_SUCCESS:
    case POST_TEMPLATE_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case PATCH_TEMPLATE_FAILURE:
    case POST_TEMPLATE_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    default:
      return state;
  }
};
