import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useConfirm } from 'material-ui-confirm';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import { deleteCustomField } from 'store/actions/contactDetails';

const StyledTableCell = styled(TableCell)`
  padding-left: 0;
`;

const CustomFieldsTable = ({
  contactId,
  listId,
  customFields,
  onEditClick,
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const handlePhoneNumberDelete = customFieldId => {
    confirm({
      confirmationText: 'Delete',
      description: 'This action will delete custom field from contact',
      title: 'Delete the custom field?',
    })
      .then(() => dispatch(deleteCustomField(listId, contactId, customFieldId)));
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Value</StyledTableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customFields.map(customField => (
            <TableRow key={customField.uuid}>
              <StyledTableCell>{customField.label}</StyledTableCell>
              <StyledTableCell>
                {customField.value || customField.values?.join(', ')}
              </StyledTableCell>
              <TableCell>
                <IconButton
                  aria-label="edit"
                  onClick={() => onEditClick(customField)}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handlePhoneNumberDelete(customField.uuid)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CustomFieldsTable.defaultProps = {
  customFields: [],
};

CustomFieldsTable.propTypes = {
  contactId: PropTypes.string.isRequired,
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      uuid: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  listId: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default CustomFieldsTable;
