import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';

import {
  Fab,
} from '@mui/material';

import CancelDialog from 'common/components/CancelDialog';
import { colors } from 'utils/constants';

const FabWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

const StyledFab = styled(Fab)`
  box-shadow: none;
  margin: 10px 0;
  background-color: ${colors.headerBackground};
`;

const CancelCreatorButton = ({ redirectUrl }) => {
  const [
    isCancelDialogOpen,
    setIsCancelDialogOpen,
  ] = useState(false);
  const {
    organizationUuid,
  } = useParams();

  return (
    <>
      <FabWrapper>
        <StyledFab
          onClick={() => setIsCancelDialogOpen(true)}
          color="primary"
          size="medium"
          aria-label="cancel creator"
        >
          <CloseIcon />
        </StyledFab>
      </FabWrapper>
      <CancelDialog
        isDialogOpen={isCancelDialogOpen}
        organizationUuid={organizationUuid}
        redirectUrl={redirectUrl || `/${organizationUuid}/dashboard`}
        setIsCancelDialogOpen={setIsCancelDialogOpen}
      />
    </>
  );
};

CancelCreatorButton.defaultProps = {
  redirectUrl: null,
};

CancelCreatorButton.propTypes = {
  redirectUrl: PropTypes.string,
};

export default CancelCreatorButton;
