import PropTypes from 'prop-types';
import { FlatfileButton } from '@flatfile/react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';

import { Button } from '@mui/material';
import { Map as MapIcon } from '@mui/icons-material';

import { postFlatfileBatch } from 'store/actions/contacts';
import { contactSelectors } from 'store/selectors/contacts';

import { colors } from 'utils/constants';

const prepareFields = (fields = []) => fields
  .filter(field => field.key)
  .map(({
    allowMultipleColumns,
    alternates,
    description,
    key,
    label,
    options,
    validators,
    type,
  }) => ({
    allowMultipleColumns: allowMultipleColumns || false,
    alternates: alternates || [],
    description: description || '',
    key,
    label,
    options: options || [],
    validators: validators?.map(validator => ({
      ...validator,
      error: validator.error || '',
    })) || [],
    ...(type ? { type } : {}),
  }));

const StyledButton = styled(Button)`
  visibility: ${props => (props.hide ? 'hidden' : 'visible')};
`;

const FlatFileButton = ({
  isLoading,
  flatfileRef,
  hide,
  listUuid,
  onExit,
}) => {
  const dispatch = useDispatch();
  const {
    flatFileConfig,
  } = useSelector(state => ({
    flatFileConfig: contactSelectors.getFlatFileConfig(state),
  }));

  const primaryButton = {
    ':hover': {
      backgroundColor: 'rgb(0, 116, 56)',
    },
    backgroundColor: colors.secondary,
    border: '0',
    borderRadius: '4px',
    color: '#fff',
  };

  const secondaryButton = {
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    backgroundColor: 'transparent',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
  };

  if (
    flatFileConfig.customer &&
    flatFileConfig.fields &&
    !isLoading
  ) {
      return (
        <FlatfileButton
          licenseKey="404125c6-e2e9-4661-aab4-73447d19a3db"
          mountUrl="https://muster.us.flatfile.io/?key=:key"
          customer={{
            companyId: flatFileConfig?.customer?.company,
            userId: flatFileConfig?.customer?.user,
          }}
          settings={{
            allowCustom: true,
            devMode: false,
            fields: prepareFields(flatFileConfig.fields),
            managed: true,
            theme: {
              buttons: {
                headerMatchNo: {
                  ...secondaryButton,
                  padding: '6px 40px',
                },
                headerMatchYes: {
                  ...primaryButton,
                  padding: '6px 40px',
                },
                primary: primaryButton,
                secondary: secondaryButton,
                success: primaryButton,
                tertiary: secondaryButton,
              },
              global: {
                fontFamily: [
                  '"Helvetica Neue"',
                  'sans-serif',
                ].join(','),
                successColor: colors.secondary,
              },
            },
            type: 'Contact',
          }}
          onData={async results => {
            const params = {
              status: 'complete',
              uuid: results.$meta.batchID,
            };

            await dispatch(postFlatfileBatch(listUuid, params));
            onExit();

            return 'Your upload is being processed! Please allow a few minutes for contacts to appear in your lists.';
          }}
          onCancel={onExit}
          render={(_importer, launch) => (
            <StyledButton
              hide={hide ? 1 : 0}
              onClick={launch}
              ref={flatfileRef}
              startIcon={<MapIcon />}
            >
              Import List
            </StyledButton>
          )}
        />
      );
    }

    if (!hide) {
      return (
        <Button
          disabled
          startIcon={<MapIcon />}
        >
          Import List
        </Button>
      );
    }

    return null;
};

FlatFileButton.defaultProps = {
  flatfileRef: null,
  hide: false,
  listUuid: null,
  onExit: () => {},
};

FlatFileButton.propTypes = {
  flatfileRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  hide: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  listUuid: PropTypes.string,
  onExit: PropTypes.func,
};

export default FlatFileButton;
