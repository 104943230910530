import { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';

import {
  Typography,
} from '@mui/material';

import { fetchUser } from 'store/actions/auth';
import { authSelectors } from 'store/selectors/auth';

import DropdownMenu from 'common/components/DropdownMenu';
import mediaQueries from 'utils/mediaQueries';
import { colors } from 'utils/constants';

const { small } = mediaQueries;

const StyledName = styled(Typography)`
  margin: 0 14px 0 11px;
  color: ${colors.white};
  font-weight: bold;
  font-size: 14px;
`;

const Wrapper = styled.span`
  display: flex;
  align-items: center;

  @media ${small} {
    display: none;
  }
`;

const { REACT_APP_INTERCOM_ID } = process.env;

const UserDropdown = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => ({
    user: authSelectors.getUser(state),
  }));

  useEffect(() => {
    if (!Object.keys(user).length) {
      dispatch(fetchUser())
        .then(data => {
          window.Intercom('boot', {
            app_id: REACT_APP_INTERCOM_ID,
            created_at: Date.now(),
            email: data.email,
            name: `${data.firstName} ${data.lastName}`,
            user_hash: data.intercomHash,
            user_id: data.uuid,
          });
        });
    }
  }, []);

  const {
    firstName = '',
    email = '',
  } = user;

  return (
    <Wrapper>
      <StyledName>
        Hi,
        &nbsp;
        {firstName || email}
        !
      </StyledName>
      <div>
        <DropdownMenu />
      </div>
    </Wrapper>
  );
};

export default UserDropdown;
