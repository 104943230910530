import {
  useHistory,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MaterialTable, { MTableCell } from '@material-table/core';
import {
  Chip,
  Paper,
} from '@mui/material';

import { DetailsButton } from 'common/components';

import { colors } from 'utils/constants';
import { renderChip } from 'utils/helpers';

const StyledDetailsButton = styled(DetailsButton)`
  width: 130px;
`;

const StyledChip = styled(Chip)`
  margin-left: 14px;
`;

const actionAlertsColumns = [
  {
    field: 'name',
    render: rowData => (
      <div>
        {rowData.name}
        {rowData.parent && (
          <StyledChip
            color="secondary"
            label="Circle Back"
          />
        )}
      </div>
    ),
    title: 'Name',
  },
  {
    field: 'type',
    title: 'Action Alert Type',
  },
  {
    field: 'status',
    render: rowData => renderChip(rowData.status, rowData.sendAtUtc),
    title: 'Status',
  },
  {
    field: 'createdAt',
    title: 'Date created',
    type: 'date',
  },
];

const ActionAlertsTable = ({
  actionAlerts,
  isDataFetching,
}) => {
  const history = useHistory();
  const {
    actionCenterUuid,
    campaignUuid,
    organizationUuid,
  } = useParams();

  return (
    <MaterialTable
      actions={[
        {
          icon: 'pageview',
          onClick: (_, rowData) => history.push(`/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}/action-alerts/${rowData.uuid}`),
        },
      ]}
      columns={actionAlertsColumns}
      components={{
        /* eslint-disable react/prop-types */
        Action: ({
          data: {
            type,
            uuid,
          },
        }) => (
          <StyledDetailsButton
            to={`/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}/${type === 'text' ? 'text-' : ''}action-alerts/${uuid}`}
          />
        ),
        Cell: props => {
          const {
            columnDef: {
              field,
            },
            value,
          } = props;

          if (field === 'date') {
            return (
              <MTableCell
                {...props}
                value={new Date(value).toLocaleDateString('us-US')}
              />
            );
          }

          return (
            <MTableCell
              {...props}
              value={value || 'N/A'}
            />
          );
          /* eslint-enable react/prop-types */
        },
        Container: props => (
          <Paper
            {...props}
            elevation={0}
            square
            variant="outlined"
          />
        ),
      }}
      data={actionAlerts}
      isLoading={isDataFetching}
      options={{
        actionsColumnIndex: -1,
        headerStyle: {
          backgroundColor: colors.tableHeaderGray,
          color: colors.white,
        },
        selection: false,
        showTitle: false,
      }}
    />
  );
};

ActionAlertsTable.defaultProps = {
  actionAlerts: [],
};

ActionAlertsTable.propTypes = {
  actionAlerts: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      fromName: PropTypes.string,
    })
  ),
  isDataFetching: PropTypes.bool.isRequired,
};

export default ActionAlertsTable;
