import {
  useEffect,
  useMemo,
  useRef,
  useState,
 } from 'react';
 import {
  useDispatch,
} from 'react-redux';
import {
  Link as RouterLink,
  useHistory,
  useParams,
} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import dayjs from 'dayjs';
import {
  Box,
  CircularProgress,
  Link,
} from '@mui/material';
import {
  ArrowForward as ArrowForwardIcon,
  Email as EmailIcon,
  Sms as SmsIcon,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

import { fetchSsrMessages } from 'store/actions/messages';

import { CardWrapper } from 'globalStyles';
import {
  isEven,
} from 'utils/helpers';
import {
  actionTypes,
  statuses,
} from 'utils/constants';
import Chip from 'material/components/Chip';
import MenuItem from 'material/components/MenuItem';
import FormControl from 'material/components/FormControl';
import { colors } from 'themes';

import {
  alertUrl,
  broadcastUrl,
} from '../../helpers/helpers';

import {
  DateFilter,
  SelectWrapper,
  StyledSelect,
 } from './MessagesTable.styles';

const renderMessageTitle = (organizationUuid, rowData) => {
  const messageUrl = rowData.messageType === 'alert' ? alertUrl(
    rowData.type,
    organizationUuid,
    rowData.campaign,
    rowData.actionCenter,
    rowData.uuid
    ) : broadcastUrl(
      rowData.type,
      organizationUuid,
      rowData.uuid
    );

  return (
    <>
      <Link
        color="inherit"
        component={RouterLink}
        to={messageUrl}
        style={{ fontWeight: '500' }}
      >
        {rowData.name}
      </Link>
      <p>{rowData.subject ? `"${rowData.subject}"` : ''}</p>
    </>
  );
};

const renderMessageType = (type, messageType) => {
  const MessageIcon = type === 'email' ? EmailIcon : SmsIcon;

  return <MessageIcon titleAccess={`${messageType} ${type}`} />;
};

const renderChip = (status, sendAtUtc, messageType) => {
  const {
    draft,
    published,
  } = statuses;

  const chipData = () => {
    switch (status) {
      case published: {
        const sent = (new Date().getTime() - new Date(sendAtUtc).getTime()) > 0;

        return sent ? {
          color: 'secondary',
          label: `Sent ${messageType}`,
         } : {
          color: 'blue',
          label: `Scheduled ${messageType}`,
        };
      }
      case draft:
        return {
          color: 'grey',
          label: `Draft ${messageType}`,
        };
      default:
        return null;
    }
  };

  return (
    <Chip {...chipData()} />
  );
};

const MessagesTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef(null);

  const {
    organizationUuid,
  } = useParams();
  const {
    email,
    text,
  } = actionTypes;

  const [
    messagesData,
    setMessagesData,
  ] = useState({
    next: '',
    page: 0,
    previous: '',
    results: [],
  });
  const [
    type,
    setType,
  ] = useState(0);
  const [
    status,
    setStatus,
  ] = useState(0);
  const [
    purpose,
    setPurpose,
  ] = useState(0);
  const [
    startDate,
    setStartDate,
  ] = useState(null);
  const [
    endDate,
    setEndDate,
  ] = useState(null);

  const [
    isMessagesFetching,
    setIsMessagesFetching,
  ] = useState(true);

  const renderLoader = () => (
    <Box
      position="relative"
      display="inline-flex"
    >
      <CircularProgress size={56} />
    </Box>
  );

  const handleDataFetch = async tableData => {
    try {
      const {
        pageSize,
        page: tablePage,
      } = tableData;
      let page = 0;
      const messageType = purpose ? `&message_type=${purpose}` : '';
      const channel = type ? `&channel=${type}` : '';
      const statusParam = status ? `&status=${status}` : '';
      const startDateParam = startDate ? `&created_after=${dayjs(startDate).format('YYYY-MM-DD')}` : '';
      const endDateParam = endDate ? `&created_before=${dayjs(endDate).format('YYYY-MM-DD')}` : '';
      let queryParams = `?organization=${organizationUuid}&page_size=${pageSize}${channel}${messageType}${statusParam}${startDateParam}${endDateParam}`;

      // user is going forward or backward
      if (
        (messagesData.page === tablePage - 1 || messagesData.page === tablePage + 1) &&
        tablePage !== 0
      ) {
        const direction = messagesData.page === tablePage - 1 ? 'next' : 'previous';

        queryParams = `?${messagesData[direction].split('?')[1]}`;
        // page number attribute changes only when user goes forward or backward
        page = tablePage;
      }

      const messages = await dispatch(fetchSsrMessages(queryParams));

      setMessagesData(prevValues => ({
        ...prevValues,
        ...messages,
        page,
      }));
      setIsMessagesFetching(false);

      return ({
        data: messages.results,
        page,
        totalCount: messages.count,
      });
    } catch (error) {
      return ({
        data: [],
        page: 0,
        totalCount: 0,
      });
    }
  };

  const messagesColumns = useMemo(() => [
      {
        field: 'status',
        render: ({
                   messageType,
                   status: elementStatus,
                   sendAtUtc,
                 }) => renderChip(elementStatus, sendAtUtc, messageType),
        title: 'Status',
        width: '1%',
      },
      {
        field: 'type',
        render: ({
                   type: channel, messageType,
                 }) => renderMessageType(channel, messageType),
        title: 'Channel',
        width: '1%',
      },
      {
        field: 'name',
        render: rowData => renderMessageTitle(organizationUuid, rowData),
        title: 'Name',
        width: '45%',
      },
      {
        field: 'totalRecipientsCount',
        title: 'Recipients',
      },
      {
        field: 'totalOpenedCount',
        title: 'Opened',
      },
      {
        field: 'totalClicksCount',
        title: 'Clicked',
      },
      {
        field: 'totalBouncedCount',
        title: 'Bounced',
      },
      {
        field: 'totalUnsubscribedCount',
        title: 'Unsubscribed',
      },
      {
        align: 'right',
        field: '',
        render: rowData => (
          <IconButton
            onClick={() => {
              if (rowData.messageType === 'alert') {
                return history.push(alertUrl(
                  rowData.type,
                  organizationUuid,
                  rowData.campaign,
                  rowData.actionCenter,
                  rowData.uuid
                ));
              }

              return history.push(broadcastUrl(
                rowData.type,
                organizationUuid,
                rowData.uuid
              ));
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        ),
        title: '',
      },
    ], [organizationUuid]);

  const isMountingRef = useRef(true);

  useEffect(() => {
    // Disable the initial fetch twice
    if (tableRef.current && !isMountingRef.current) {
      tableRef.current.onQueryChange(handleDataFetch);
    } else {
      isMountingRef.current = false;
    }
  }, [
    purpose,
    status,
    type,
    startDate,
    endDate,
  ]);

  return (
    <CardWrapper>
      <MaterialTable
        tableRef={tableRef}
        key="key"
        components={{
          Toolbar: () => (
            <div>
              <SelectWrapper>
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 120,
                  }}
                  size="small"
                >
                  <StyledSelect
                    onChange={event => setPurpose(event.target.value)}
                    value={purpose}
                    variant="outlined"
                  >
                    <MenuItem value={0}>All messages</MenuItem>
                    <MenuItem value="broadcast">Broadcasts</MenuItem>
                    <MenuItem value="alert">Action alerts</MenuItem>
                  </StyledSelect>
                </FormControl>
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 120,
                  }}
                  size="small"
                >
                  <StyledSelect
                    onChange={event => setStatus(event.target.value)}
                    value={status}
                    variant="outlined"
                  >
                    <MenuItem value={0}>Any status</MenuItem>
                    <MenuItem value="sent">Sent</MenuItem>
                    <MenuItem value="scheduled">Scheduled</MenuItem>
                    <MenuItem value="draft">Draft</MenuItem>
                  </StyledSelect>
                </FormControl>

                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 120,
                  }}
                  size="small"
                >
                  <StyledSelect
                    onChange={event => setType(event.target.value)}
                    value={type}
                    variant="outlined"
                  >
                    <MenuItem value={0}>Email & text</MenuItem>
                    <MenuItem value={email}>Email</MenuItem>
                    <MenuItem value={text}>Text</MenuItem>
                  </StyledSelect>
                </FormControl>

                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 120,
                  }}
                  size="small"
                >
                  <DateFilter
                    clearable
                    onChange={date => setStartDate(date ? dayjs(date).startOf('day')
                      .toDate() : null)
                    }
                    label="Start Date"
                    value={startDate ? dayjs(startDate) : null}
                    suffixIcon={null}
                    slotProps={{
                      textField: {
                        size: 'small',
                        variant: 'outlined',
                      },
                    }}
                  />
                </FormControl>

                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 120,
                  }}
                  size="small"
                >
                  <DateFilter
                    clearable
                    label="End Date"
                    onChange={date => setEndDate(date ? dayjs(date).endOf('day')
                      .toDate() : null)
                    }
                    value={endDate ? dayjs(endDate) : null}
                    slotProps={{
                      textField: {
                        size: 'small',
                        variant: 'outlined',
                      },
                    }}
                  />
                </FormControl>

              </SelectWrapper>
            </div>
          ),
        }}
        columns={messagesColumns}
        data={handleDataFetch}
        localization={{
          body: {
            emptyDataSourceMessage: (isMessagesFetching) ?
              renderLoader() :
              'No records to display',
          },
        }}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            color: colors.osloGray,
          },
          maxColumnSort: 0,
          pageSize: 20,
          pageSizeOptions: [
            5,
            20,
            50,
            100,
          ],
          rowStyle: (_, rowNumber) => ({
            backgroundColor: isEven(rowNumber) ? '#F8F8F8' : '#FFF',
            fontSize: '14px',
          }),
          search: false,
          showFirstLastPageButtons: false,
          showTitle: false,
        }}
        style={{
          boxShadow: 'none',
        }}
      />
    </CardWrapper>
  );
};

export default MessagesTable;
