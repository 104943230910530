import {
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import { FilterList as FilterListIcon } from '@mui/icons-material';

import {
  fetchChildrenLists,
  fetchList,
  fetchRootList,
  postList,
} from 'store/actions/contacts';
import { fetchActionCenters } from 'store/actions/campaigns';
import { fetchAllSignups } from 'store/actions/signups';
import { contactSelectors } from 'store/selectors/contacts';
import { customFieldsSelectors } from 'store/selectors/customFields';

import {
  CancelCreatorButton,
  Container,
  DescriptionBar,
  Stepper,
} from 'common/components';
import {
  colors,
  complexListTypes,
  fieldTypes,
  filtersTypes,
  lookupTypes,
  negativeFilterConditions,
  positiveFilterConditions,
} from 'utils/constants';
import {
  fullLocationsName,
  fullOfficialName,
} from 'utils/helpers';
import {
  StepFourContainer,
  StepOneContainer,
  StepThreeContainer,
  StepTwoContainer,
} from 'globalStyles';

import StepFour from 'routes/CreateFilteredList/components/StepFour';
import StepOne from 'routes/CreateFilteredList/components/StepOne';
import StepTwo from 'routes/CreateFilteredList/components/StepTwo';
import StepThree from 'routes/CreateFilteredList/components/StepThree';
import { signupTypes } from 'routes/CreateFilteredList/components/helpers';

const ButtonCreateWrapper = styled.div`
  position: relative;
`;

const ButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const CancelButton = styled(Button)`
  border-color: #fff;
  color: #fff;
`;

const NextButtonWrapper = styled.div`
  padding-left: 10px;
`;

const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  background-color: ${colors.codGray};
`;

const CreateFilteredList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    organizationUuid,
    filteredListUuid,
  } = useParams();
  const {
    childrenLists,
    customFieldsList,
    isPendingList,
    rootList,
  } = useSelector(state => ({
    childrenLists: contactSelectors.getChildrenLists(state)[organizationUuid] || [],
    customFieldsList: customFieldsSelectors.getCustomFieldsListData(state),
    isPendingList: contactSelectors.isPendingList(state),
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  const staticLists = [
    ...childrenLists.filter(({ type }) => type === 'StaticList' || type === 'TextableList'),
    rootList,
  ];

  const [
    liveActionCenters,
    setLiveActionCenters,
  ] = useState([]);
  const [
    liveSignupsSource,
    setLiveSignupsSource,
  ] = useState([]);
  const [
    condition,
    setCondition,
  ] = useState('');
  const [
    customFieldId,
    setCustomFieldId,
  ] = useState(null);
  const [
    filters,
    setFilters,
  ] = useState([]);
  const [
    filterIncludes,
    setFilterIncludes,
  ] = useState('AND');
  const [
    filterName,
    setFilterName,
  ] = useState('');
  const [
    filterType,
    setFilterType,
  ] = useState('OR');
  const [
    filterValue,
    setFilterValue,
  ] = useState('');
  const [
    filterValues,
    setFilterValues,
  ] = useState([]);
  const [
    pickedActionCenters,
    setPickedActionCenters,
  ] = useState([]);
  const [
    pickedSignupsSource,
    setPickedSignupsSource,
  ] = useState([]);
  const [
    lists,
    setLists,
  ] = useState([]);
  const [
    listName,
    setListName,
  ] = useState('');
  const [
    step,
    setStep,
  ] = useState(0);

  const {
    actionCenterList,
    advocateList,
    geoList,
    fieldList,
    hasIdList,
    signupsList,
    textableList,
  } = complexListTypes;
  const {
    icontains,
    overlap,
  } = lookupTypes;
  const {
    actionCenterFilterType,
    customFieldFilterType,
    hasExternalIdFilterType,
    isAdvocateFilterType,
    isTextableListFilterType,
    firstNameFilterType,
    grassTopRelationshipFilterType,
    locationFilterType,
    lastNameFilterType,
    signupSourceListFilterType,
  } = filtersTypes;
  const {
    contactsWhoCanBeTexted,
    contains,
    hasARelationshipWith,
    hasExternalId,
    haveActedInAnyOf,
    haveTakenAction,
    includesAll,
    includesAny,
    isInside,
    matches,
  } = positiveFilterConditions;
  const {
    contactsWhoCannotBeTexted,
    doesNotContain,
    doesNotHaveARelationshipWith,
    doesNotHaveExternalId,
    doesNotMatch,
    doesNotIncludeAll,
    doesNotIncludeAny,
    haveNotTakenAction,
    isOutside,
    notActedInAnyOf,
  } = negativeFilterConditions;
  const {
    multipleChoiceField,
    textField,
  } = fieldTypes;

  const handleFilterCondition = (type, {
    inclusive,
    lookupType,
    values,
  }) => {
    switch (type) {
      case locationFilterType:
        return inclusive ? isInside : isOutside;
      case grassTopRelationshipFilterType:
        return inclusive ? hasARelationshipWith : doesNotHaveARelationshipWith;
      case hasExternalIdFilterType:
        return inclusive ? hasExternalId : doesNotHaveExternalId;
      case isTextableListFilterType:
        return inclusive ? contactsWhoCanBeTexted : contactsWhoCannotBeTexted;
      case isAdvocateFilterType:
        return inclusive ? haveTakenAction : haveNotTakenAction;
      case actionCenterFilterType:
        return inclusive ? haveActedInAnyOf : notActedInAnyOf;
      case signupSourceListFilterType:
        return inclusive ? includesAny : doesNotIncludeAny;
      default: {
        if (values?.length) {
          if (lookupType === overlap) {
            return inclusive ? includesAny : doesNotIncludeAny;
          }

          return inclusive ? includesAll : doesNotIncludeAll;
        }

        if (inclusive) {
          return lookupType === icontains ?
            contains :
            matches;
        }

        return lookupType === icontains ? doesNotContain : doesNotMatch;
      }
    }
  };

  const handleFindType = (type, fieldName) => {
    switch (type) {
      case geoList:
        return locationFilterType;
      case fieldList: {
        switch (fieldName) {
          case 'official':
            return grassTopRelationshipFilterType;
          case 'first_name':
            return firstNameFilterType;
          case 'last_name':
            return lastNameFilterType;
          default:
            return customFieldFilterType;
        }
      }
      case textableList:
        return isTextableListFilterType;
      case hasIdList:
        return hasExternalIdFilterType;
      case advocateList:
        return isAdvocateFilterType;
      case actionCenterList:
        return actionCenterFilterType;
      case signupsList:
        return signupSourceListFilterType;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (Object.keys(rootList)) {
      dispatch(fetchRootList(organizationUuid)).then(data => {
        if (childrenLists.length === 0) {
          dispatch(fetchChildrenLists(data.uuid, organizationUuid));
        }
      });
    }

    const getPageData = async () => {
      try {
        const actionCentersData = await dispatch(fetchActionCenters({
          organization: organizationUuid,
          statusExcludes: 'draft',
        }));

        if (actionCentersData?.results) {
          setLiveActionCenters(actionCentersData?.results);
        }

        const signupsData = await dispatch(fetchAllSignups(organizationUuid));

        setLiveSignupsSource([
          ...actionCentersData?.results?.map(actionCenter => ({
            ...actionCenter,
            type: signupTypes.actionCenter,
          })) || [],
          ...signupsData?.map(signup => ({
            ...signup,
            type: signupTypes.signup,
          })) || [],
        ]);

        if (filteredListUuid) {
          dispatch(fetchList(filteredListUuid))
            .then(data => {
              setLists(data.staticLists);
              setListName(`[clone] ${data.name}`);
              setFilterType(data.staticListsOperator);
              const baseFilters = data.filterLists.map(baseFilter => {
                const baseFilterType = handleFindType(baseFilter.type, baseFilter.fieldName);

                return ({
                  ...baseFilter,
                  actionCenters: (baseFilterType === actionCenterFilterType) ?
                    baseFilter.actionCenters.map(actionCenterUuid => ({
                      name: actionCentersData?.results
                        .find(({ uuid }) => uuid === actionCenterUuid)?.name,
                      uuid: actionCenterUuid,
                    })) : [],
                  boundaries: baseFilterType === locationFilterType ? baseFilter.filterDetails : [],
                  filterCondition: handleFilterCondition(baseFilterType, baseFilter),
                  isChoiceField: baseFilterType === customFieldFilterType,
                  isLocationField: baseFilterType === locationFilterType,
                  isOfficialField: baseFilterType === grassTopRelationshipFilterType,
                  name: baseFilterType === customFieldFilterType ?
                    baseFilter.label :
                    baseFilterType,
                  officialName: baseFilterType === grassTopRelationshipFilterType ?
                    fullOfficialName(
                      baseFilter.filterDetails.official,
                      baseFilter.filterDetails.position.name
                    ) :
                    '',
                  signups: (baseFilterType === signupSourceListFilterType) ?
                    [
                      ...baseFilter.actionCenters.map(actionCenterUuid => ({
                        name: actionCentersData?.results
                          .find(({ uuid }) => uuid === actionCenterUuid)?.name,
                        type: signupTypes.actionCenter,
                        uuid: actionCenterUuid,
                      })),
                      ...baseFilter.contactForms.map(signupUuid => ({
                        name: signupsData?.find(({ uuid }) => uuid === signupUuid)?.name,
                        type: signupTypes.signup,
                        uuid: signupUuid,
                      })),
                    ] : [],
                  value: baseFilterType === locationFilterType ?
                    fullLocationsName(baseFilter.filterDetails) :
                    baseFilter.value,
                });
              });

              setFilters(baseFilters);
              setFilterIncludes(data.newListsOperator);
            });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    getPageData();
  }, []);

  const steps = [
    'Name Filtered List',
    'Select List',
    'Apply Filters',
    'Review',
  ];

  const disableNextButton = () => {
    if (step === 0) {
      return !listName;
    }

    if (step === 1 && lists.length === 0) {
      return true;
    }

    return false;
  };

  const handleFilterIncludes = event => {
    event.preventDefault();
    setFilterIncludes(event.currentTarget.dataset.field);
  };

  const handlePickField = event => {
    event.preventDefault();
    setFilterType(event.currentTarget.dataset.field);
  };

  const handleFieldName = filter => {
    if (filter.isChoiceField) {
      return filter.fieldName;
    }

    if (filter.isOfficialField) {
      return 'official';
    }

    return filter.name.toLowerCase().replace(/ /g, '_');
  };

  const handleAddLookupType = filter => {
    if (filter.isOfficialField) {
      return {};
    }

    if (filter.type === fieldList) {
      if (filter.fieldName) {
        const customFieldOption = customFieldsList.find(
          customField => customField.uuid === handleFieldName(filter)
        );

        if (!customFieldOption) {
          return {
            lookupType: filter.filterCondition === doesNotContain || filter.filterCondition === contains ? 'icontains' : 'iexact',
          };
        }

        if (customFieldOption.fieldType === multipleChoiceField) {
          return {
            lookupType: filter.filterCondition === doesNotIncludeAll || filter.filterCondition === includesAll ? 'contains' : overlap,
          };
        }

        if (customFieldOption.fieldType === textField) {
          return {
            lookupType: filter.filterCondition === doesNotContain || filter.filterCondition === contains ? 'icontains' : 'iexact',
          };
        }

        return {};
      }

      return {
        lookupType: filter.filterCondition === doesNotContain || filter.filterCondition === contains ? 'icontains' : 'iexact',
      };
    }

    return {};
  };

  const handleReturnValue = (value, values) => {
    if (values.length) {
      return {
        values,
      };
    }

    return {
      value,
    };
  };

  const handleNewListPayload = filter => ({
    ...(filter.isLocationField && { boundaries: filter.boundaries.map(boundary => boundary.uuid) }),
    inclusive: Object.values(positiveFilterConditions).some(
      value => value === filter.filterCondition
    ),
    ...handleAddLookupType(filter),
    type: filter.type,
    ...(filter.type === actionCenterList && {
      actionCenters: filter.actionCenters.map(({ uuid }) => uuid),
    }),
    ...(filter.type === signupsList && {
      actionCenters: filter.signups
        .filter(({ type }) => type === signupTypes.actionCenter)
        .map(({ uuid }) => uuid),
      contactForms: filter.signups
        .filter(({ type }) => type === signupTypes.signup)
        .map(({ uuid }) => uuid),
    }),
    ...(!filter.isLocationField &&
      filter.type !== textableList &&
      filter.type !== hasIdList &&
      filter.type !== advocateList &&
      filter.type !== actionCenterList &&
      filter.type !== signupsList &&
      {
        fieldName: handleFieldName(filter),
        ...handleReturnValue(filter.value, filter.values),
      }),
  });

  const handleCreateList = () => {
    const params = {
      name: listName,
      newLists: filters.map(filter => handleNewListPayload(filter)),
      newListsOperator: filterIncludes,
      parent: rootList.uuid,
      staticLists: lists.map(({ uuid }) => uuid),
      staticListsOperator: filterType,
      type: 'ComplexList',
    };

    dispatch(postList(params))
      .then(data => history.push(`/${organizationUuid}/contacts/lists/${data.uuid}`))
      .then(() => {
        dispatch(fetchChildrenLists(rootList.uuid, organizationUuid));
      });
  };

  return (
    <>
      <Container>
        <Grid container>
          <DescriptionBar
            icon={FilterListIcon}
            name={`${filteredListUuid ? 'Clone' : 'Add'} Filtered List`}
          >
            <CancelCreatorButton redirectUrl={`/${organizationUuid}/contacts/lists`} />
          </DescriptionBar>
        </Grid>
        <StepOneContainer step={step}>
          <StepOne
            listName={listName}
            setListName={setListName}
          />
        </StepOneContainer>
        <StepTwoContainer step={step}>
          <StepTwo
            filterType={filterType}
            handlePickField={handlePickField}
            lists={lists}
            setLists={setLists}
            staticLists={staticLists}
          />
        </StepTwoContainer>
        <StepThreeContainer step={step}>
          <StepThree
            condition={condition}
            customFieldId={customFieldId}
            filterIncludes={filterIncludes}
            filterName={filterName}
            filterValue={filterValue}
            filterValues={filterValues}
            filters={filters}
            handleFilterIncludes={handleFilterIncludes}
            liveActionCenters={liveActionCenters}
            liveSignupsSource={liveSignupsSource}
            pickedActionCenters={pickedActionCenters}
            pickedSignupsSource={pickedSignupsSource}
            rootList={rootList}
            setCondition={setCondition}
            setCustomFieldId={setCustomFieldId}
            setFilterName={setFilterName}
            setFilterValue={setFilterValue}
            setFilterValues={setFilterValues}
            setFilters={setFilters}
            setLiveActionCenters={setLiveActionCenters}
            setLiveSignupsSource={setLiveSignupsSource}
            setPickedActionCenters={setPickedActionCenters}
            setPickedSignupsSource={setPickedSignupsSource}
          />
        </StepThreeContainer>
        <StepFourContainer step={step}>
          <StepFour
            filterIncludes={filterIncludes}
            filterType={filterType}
            filters={filters}
            lists={lists}
          />
        </StepFourContainer>
      </Container>
      <StepperWrapper>
        <Grid
          container
          direction="row"
          item
          justifyContent="space-between"
          lg={10}
          xs={11}
        >
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-start"
            xs={2}
          >
            <CancelButton
              component={Link}
              to={`/${organizationUuid}/contacts/lists`}
              variant="outlined"
            >
              Cancel
            </CancelButton>
          </Grid>
          <Grid
            item
            xs={8}
          >
            <Stepper
              step={step}
              steps={steps}
            />
          </Grid>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-end"
            xs={2}
          >
            <div>
              <Button
                color="secondary"
                disabled={step === 0}
                onClick={() => setStep(prevStep => prevStep - 1)}
                variant="contained"
              >
                Back
              </Button>
            </div>
            <NextButtonWrapper>
              {step === 3 ? (
                <ButtonCreateWrapper>
                  <Button
                    color="secondary"
                    onClick={handleCreateList}
                    variant="contained"
                  >
                    Create
                  </Button>
                  {isPendingList && <ButtonCircularProgress size={24} />}
                </ButtonCreateWrapper>
              ) : (
                <Button
                  color="secondary"
                  disabled={disableNextButton()}
                  onClick={() => setStep(prevStep => prevStep + 1)}
                  variant="contained"
                >
                  Next
                </Button>
              )}
            </NextButtonWrapper>
          </Grid>
        </Grid>
      </StepperWrapper>
    </>
  );
};

export default CreateFilteredList;
