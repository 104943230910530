import {
  DELETE_SIGNUP_FAILURE,
  DELETE_SIGNUP_REQUEST,
  DELETE_SIGNUP_SUCCESS,
  FETCH_ALL_SIGNUPS_FAILURE,
  FETCH_ALL_SIGNUPS_REQUEST,
  FETCH_ALL_SIGNUPS_SUCCESS,
  FETCH_SIGNUP_FAILURE,
  FETCH_SIGNUP_REQUEST,
  FETCH_SIGNUP_SUCCESS,
  FETCH_SIGNUPS_FAILURE,
  FETCH_SIGNUPS_REQUEST,
  FETCH_SIGNUPS_SUCCESS,
  PATCH_SIGNUP_FAILURE,
  PATCH_SIGNUP_REQUEST,
  PATCH_SIGNUP_SUCCESS,
  POST_SIGNUP_FAILURE,
  POST_SIGNUP_REQUEST,
  POST_SIGNUP_SUCCESS,
} from '../actions/signups';

const initialState = {
  errors: null,
  isFetching: false,
  isPending: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_SIGNUPS_REQUEST:
    case FETCH_SIGNUP_REQUEST:
    case FETCH_SIGNUPS_REQUEST:
      return {
        ...state,
        errors: null,
        isFetching: true,
      };
    case FETCH_ALL_SIGNUPS_SUCCESS:
    case FETCH_SIGNUP_SUCCESS:
    case FETCH_SIGNUPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_ALL_SIGNUPS_FAILURE:
    case FETCH_SIGNUP_FAILURE:
    case FETCH_SIGNUPS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      };
    case DELETE_SIGNUP_REQUEST:
    case PATCH_SIGNUP_REQUEST:
    case POST_SIGNUP_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case DELETE_SIGNUP_SUCCESS:
    case PATCH_SIGNUP_SUCCESS:
    case POST_SIGNUP_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case DELETE_SIGNUP_FAILURE:
    case PATCH_SIGNUP_FAILURE:
    case POST_SIGNUP_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    default:
      return state;
  }
};
