import request from '../../utils/request';

const PREFIX = '[LOCATIONS]';

export const FETCH_LOCATIONS_REQUEST = `${PREFIX} FETCH_LOCATIONS_REQUEST`;
export const FETCH_LOCATIONS_SUCCESS = `${PREFIX} FETCH_LOCATIONS_SUCCESS`;
export const FETCH_LOCATIONS_FAILURE = `${PREFIX} FETCH_LOCATIONS_FAILURE`;

const fetchLocationsRequest = () => ({
  type: FETCH_LOCATIONS_REQUEST,
});

const fetchLocationsSuccess = payload => ({
  payload,
  type: FETCH_LOCATIONS_SUCCESS,
});

const fetchLocationsFailure = payload => ({
  payload,
  type: FETCH_LOCATIONS_FAILURE,
});

export const fetchLocations = (organizationUuid, params = '') => dispatch => {
  dispatch(fetchLocationsRequest());

  return request
    .get(`v1/crm/${organizationUuid}/locations/?${params}`)
    .then(({ data }) => {
      dispatch(fetchLocationsSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchLocationsFailure(data));

      throw data;
    });
};
