export const campaignsSelectors = {
  getActionAlertDetails: state => state.campaigns.actionAlertDetails,
  getActionAlertLinks: state => state.campaigns.actionAlertLinks,
  getActionAlertRecipients: state => state.campaigns.actionAlertRecipients,
  getActionAlertsList: state => state.campaigns.actionAlertsList.results,
  getActionCenterDetails: state => state.campaigns.actionCenterDetails,
  getActionCenterSignups: state => state.campaigns.actionCenterSignups.results,
  getActionCenterTargets: state => state.campaigns.actionCenterTargets.results,
  getActionCentersActionsData: state => state.campaigns.actionCenterActions.results,
  getActionCentersListData: state => state.campaigns.actionCentersList.results,
  getAdvocates: state => state.campaigns.advocates,
  getAllActionAlertsList: state => state.campaigns.allActionAlertsList.results,
  getCampaignDetails: state => state.campaigns.campaignDetails,
  getCampaignTargets: state => state.campaigns.campaignTargets.results,
  getCampaignsListData: state => state.campaigns.list.results,
  getMatchedTargets: state => state.campaigns.matchedTargets,
  getTargetGroupDetails: state => state.campaigns.targetGroupDetails,
  getTargetGroups: state => state.campaigns.targetGroups.results,
  isActionAlertFetching: state => state.campaigns.isActionAlertFetching,
  isActionAlertLinksFetching: state => state.campaigns.isActionAlertLinksFetching,
  isActionAlertRecipientsFetching: state => state.campaigns.isActionAlertRecipientsFetching,
  isActionCenterActionsFetching: state => state.campaigns.actionCenterActions.isFetching,
  isActionCenterFetching: state => state.campaigns.isActionCenterFetching,
  isActionCenterSignupsFetching: state => state.campaigns.isActionCenterSignupsFetching,
  isActionCenterTargetsFetching: state => state.campaigns.isActionCenterTargetsFetching,
  isAdvocatesFetching: state => state.campaigns.isAdvocatesFetching,
  isAllActionAlertsFetching: state => state.campaigns.allActionAlertsList.isFetching,
  isCampaignFetching: state => state.campaigns.isCampaignFetching,
  isCampaignTargetsFetching: state => state.campaigns.isCampaignTargetsFetching,
  isDataFetching: state => state.campaigns.isFetching,
  isFetching: state => state.campaigns.list.isFetching,
  isPending: state => state.campaigns.isPending,
};
