import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const SuccessDialog = ({
  customButton,
  isCreateNewDisabled,
  isDialogOpen,
  onCreateNew,
  name,
  redirectTo,
  successHeadline,
  thankYouMessage,
}) => (
  <Dialog
    aria-labelledby="form-dialog-title"
    fullWidth
    open={isDialogOpen}
  >
    <DialogTitle id="form-dialog-title">{successHeadline}</DialogTitle>
    <DialogContent>
      <DialogContentText>{thankYouMessage}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        color="secondary"
        component={Link}
        variant="outlined"
        to={redirectTo}
      >
        Close
      </Button>
      {customButton}
      {name && !isCreateNewDisabled && (
        <Button
          color="secondary"
          variant="outlined"
          onClick={onCreateNew}
        >
          Create new
          {' '}
          {name}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

SuccessDialog.defaultProps = {
  customButton: null,
  isCreateNewDisabled: false,
  name: '',
  onCreateNew: () => {},
  redirectTo: '/',
  successHeadline: 'Success',
  thankYouMessage: '',
};

SuccessDialog.propTypes = {
  customButton: PropTypes.element,
  isCreateNewDisabled: PropTypes.bool,
  isDialogOpen: PropTypes.bool.isRequired,
  name: PropTypes.string,
  onCreateNew: PropTypes.func,
  redirectTo: PropTypes.string,
  successHeadline: PropTypes.string,
  thankYouMessage: PropTypes.string,
};

export default SuccessDialog;
