import {
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import {
  Add as AddIcon,
  Dashboard as DashboardIcon,
} from '@mui/icons-material';

import { campaignsSelectors } from 'store/selectors/campaigns';
import { organizationSelectors } from 'store/selectors/organizations';

import {
  ActionCenterSelectDialog,
  Container,
  CreateMessageButton,
  DescriptionBar,
  DescriptionBarInfo,
} from 'common/components';
import {
  CircularProgressWrapper,
} from 'globalStyles';
import StatisticCards from 'routes/Dashboard/components/StatisticCards';

// import DashboardTabs from 'routes/Dashboard/components/DashboardTabs';

const DashboardWrapper = styled.div`
  padding: 100px 0 80px;
`;

const StyledButton = styled(Button)`
  margin-left: 20px;
`;

const DESCRIPTION_BAR_SUBTITLE = 'Take a look at how your advocacy campaigns are working and mobilize your advocates.';
const NEW_CAMPAIGN_BUTTON_TEXT = 'New Campaign';

const Dashboard = () => {
  const { organizationUuid } = useParams();
  const history = useHistory();

  const {
    isCampaignsFetching,
    organization,
  } = useSelector(state => ({
    isCampaignsFetching: campaignsSelectors.isFetching(state),
    organization: organizationSelectors.getOrganization(state),
  }));

  const [
    isAlertDialogOpen,
    setIsAlertDialogOpen,
  ] = useState(false);
  const [
    newAlertType,
    setNewAlertType,
  ] = useState('email');

  const handleOpenAlerts = (alertType = 'email') => {
    setNewAlertType(alertType);
    setIsAlertDialogOpen(true);
  };

  const {
    statistics,
    flags,
  } = organization;

  useEffect(() => {
    if (flags) {
      if (
        !flags.showBroadcasts &&
        !flags.showCampaigns &&
        flags.showLists
      ) {
        history.push(`/${organizationUuid}/contacts/lists`);
      }
    }
  }, [flags]);

  const descriptionBarInfo = (
    <DescriptionBarInfo>
      {DESCRIPTION_BAR_SUBTITLE}
    </DescriptionBarInfo>
  );

  if (isCampaignsFetching || !Object.keys(organization).length) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  return (
    <Container>
      <Grid container>
        <DescriptionBar
          descriptionBarInfo={descriptionBarInfo}
          icon={DashboardIcon}
          isHighBar
          name={organization.name}
        >
          <CreateMessageButton
            alertDialogHandler={handleOpenAlerts}
          />
          <StyledButton
            color="secondary"
            component={Link}
            disableElevation
            disabled={!organization.flags?.showCampaigns}
            startIcon={<AddIcon />}
            to={`/${organizationUuid}/create-action-center`}
            variant="contained"
          >
            {NEW_CAMPAIGN_BUTTON_TEXT}
          </StyledButton>
        </DescriptionBar>
      </Grid>
      <DashboardWrapper>
        <StatisticCards
          actionCenterCount={statistics?.actionCenter?.totalCount}
          advocacyAlertsCount={statistics?.advocacyAlerts?.totalCount}
          advocatesCount={statistics?.advocates?.totalCount}
          broadcastCount={statistics?.broadcast?.totalCount}
          campaignCount={statistics?.campaign?.totalCount}
        />
        {/*
          Temporarily hidden
          <DashboardTabs />
        */}
      </DashboardWrapper>
      <ActionCenterSelectDialog
        organizationUuid={organizationUuid}
        newAlertType={newAlertType}
        isAlertDialogOpen={isAlertDialogOpen}
        setIsAlertDialogOpen={setIsAlertDialogOpen}
      />
    </Container>
  );
};

export default Dashboard;
