import {
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  Dialog,
} from 'common/components';
import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';
import { fetchActionCenters } from 'store/actions/campaigns';
import { campaignsSelectors } from 'store/selectors/campaigns';

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 15px;
`;

const getNewAlertUrl = (organizationUuid, campaignUuid, actionCenterUuid, alertType) => (
    `/${organizationUuid}/${campaignUuid}/${actionCenterUuid}/create-${alertType}-action-alert/`
);

const ActionCenterSelectDialog = ({
  organizationUuid,
  isAlertDialogOpen,
  setIsAlertDialogOpen,
  newAlertType,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    actionCentersList,
  } = useSelector(state => ({
    actionCentersList: campaignsSelectors.getActionCentersListData(state).results || [],
  }));

  const [
    alertActionCenter,
    setAlertActionCenter,
  ] = useState('');

  const handleCreateAlert = () => {
    history.push(alertActionCenter);
  };

  useEffect(() => {
    dispatch(fetchActionCenters({
      organization: organizationUuid,
      status: 'live',
    }));
  }, [
    organizationUuid,
    dispatch,
  ]);

  return (
    <Dialog
      confirmationText={actionCentersList.length > 0 ? 'Create Alert' : ''}
      handleConfirm={handleCreateAlert}
      handleDialogClose={() => setIsAlertDialogOpen(false)}
      isDialogOpen={isAlertDialogOpen}
      subtitle={actionCentersList.length > 0 ? `The ${newAlertType} alert must be sent from a live action center` : ''}
      title="Choose the action center"
      disabledButton={false}
    >
      {actionCentersList.length === 0 ? (
        <Typography>You do not have any live action centers!</Typography>
      ) : (
        <StyledSelect
          onChange={event => setAlertActionCenter(event.target.value)}
          value={alertActionCenter}
        >
          {actionCentersList
            .map(actionCenter => (
              <MenuItem
                key={actionCenter.uuid}
                value={getNewAlertUrl(
                  organizationUuid,
                  actionCenter.campaignUuid,
                  actionCenter.uuid,
                  newAlertType
                )}
              >
                {actionCenter.name}
              </MenuItem>
            ))}
        </StyledSelect>
    )}
    </Dialog>
  );
};

ActionCenterSelectDialog.propTypes = {
  isAlertDialogOpen: PropTypes.bool.isRequired,
  newAlertType: PropTypes.string.isRequired,
  organizationUuid: PropTypes.string.isRequired,
  setIsAlertDialogOpen: PropTypes.func.isRequired,
};

export default ActionCenterSelectDialog;
