import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { ErrorBoundary } from 'react-error-boundary';

const EditorWysiwyg = ({
  editorState,
  handleEditorError,
  setEditorStateChange,
  inlineOptions,
  listOptions,
  options,
  placeholder,
}) => (
  <ErrorBoundary
    FallbackComponent={() => (<>An error occurred</>)}
    onError={handleEditorError}
    resetKeys={[editorState]}
  >
    <Editor
      editorClassName="wysiwyg-editor"
      editorState={editorState}
      editorStyle={{ padding: '0 1rem 0' }}
      wrapperClassName="wysiwyg-wrapper"
      textAlignment="left"
      onEditorStateChange={state => setEditorStateChange(state)}
      placeholder={placeholder}
      toolbar={{
        inline: {
          options: inlineOptions,
        },
        list: {
          options: listOptions,
        },
        options,
      }}
    />
  </ErrorBoundary>
);

EditorWysiwyg.defaultProps = {
  inlineOptions: [
    'bold',
    'italic',
    'underline',
  ],
  listOptions: [
    'unordered',
    'ordered',
  ],
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'embedded',
    'emoji',
    'image',
    'remove',
    'history',
  ],
};

EditorWysiwyg.defaultProps = {
  editorState: null,
  placeholder: null,
};

EditorWysiwyg.propTypes = {
  editorState: PropTypes.shape({
    getCurrentContent: PropTypes.func,
  }),
  handleEditorError: PropTypes.func.isRequired,
  inlineOptions: PropTypes.arrayOf(PropTypes.string),
  listOptions: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  setEditorStateChange: PropTypes.func.isRequired,
};

export default EditorWysiwyg;
