import {
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  ListItemIcon,
  ListSubheader,
  Menu,
  MenuItem,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  ArrowBack as ArrowBackIcon,
  Check as CheckIcon,
} from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { CardWrapper } from 'globalStyles';
import { Popover } from 'common/components';
import Button from 'material/components/Button';

import { truncate } from 'utils/helpers';

import {
  ActionButtons,
  CardDateInfo,
  CardHeader,
  CardTitle,
  CardTitleWrapper,
  CardTypes,
  DetailsList,
  StyledChip,
} from './TopCard.styles';

export const chipTypes = {
  warning: 'warning',
};

const TopCard = ({
  actionButtons,
  additionalActionButtons,
  backButtonLink,
  chipData = {
    chipLabel: '',
    chipOptions: [],
    chipType: false,
    onChipClick: () => {},
  },
  children = null,
  dateInfo,
  entityTypes = [],
  details,
  restActions = null,
  title,
}) => {
  const history = useHistory();

  const [
    anchorEl,
    setAnchorEl,
  ] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (onSelect, value) => {
    if (onSelect) {
      onSelect(value);
    }

    handleClose();
  };

  return (
    <CardWrapper>
      <CardHeader>
        <CardTitleWrapper>
          {backButtonLink && (
            <IconButton
              aria-label="go back"
              onClick={() => history.push(backButtonLink)}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <CardTitle>
            {title}
          </CardTitle>
          {chipData.chipLabel && (
            <StyledChip
              label={chipData.chipLabel}
              size="medium"
              {...(!!chipData.chipOptions?.length && {
                deleteIcon: <KeyboardArrowDownIcon />,
                onClick: handleClick,
                onDelete: handleClick,
              })}
              color={chipData.chipColor || 'primary'}
            />
          )}
          <div>
            {!!chipData.chipOptions?.length && (
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {chipData.chipOptionsHeader && (
                  <ListSubheader>{chipData.chipOptionsHeader}</ListSubheader>
                )}
                {chipData.chipOptions.map(option => (
                  <Popover
                    key={option.value}
                    shouldDisplayPopover={!!option.tooltipText}
                    horizontalPosition="center"
                    text={option.tooltipText || ''}
                  >
                    <MenuItem
                      disabled={option.disabled}
                      onClick={() => handleSelect(chipData.handleSelectChipValue, option.value)}
                    >
                      {option.label}
                      {option.label === chipData.chipLabel && (
                        <ListItemIcon>
                          <CheckIcon />
                        </ListItemIcon>
                      )}
                    </MenuItem>
                  </Popover>
                ))}
              </Menu>
            )}
          </div>
        </CardTitleWrapper>

        <ActionButtons>
          {actionButtons?.map(({
            name,
            tooltipText,
            ...button
          }) => (
            <Popover
              key={name}
              shouldDisplayPopover={!!tooltipText}
              horizontalPosition="center"
              text={tooltipText || ''}
            >
              <Button
                key={name}
                {...button}
              >
                {name}
              </Button>
            </Popover>
          ))}
          {restActions}
        </ActionButtons>

      </CardHeader>

      {!!entityTypes?.length && (
        <CardTypes>
          {entityTypes?.filter(type => type > '').map(type => (<li key={type}>{type}</li>))}
        </CardTypes>
      )}

      {dateInfo && <CardDateInfo>{dateInfo}</CardDateInfo>}

      {!!details?.length && (
        <DetailsList>
          {details.map(detail => (
            <li key={detail.label}>
              <span>{`${detail.label}:`}</span>
              <Popover
                shouldDisplayPopover={detail.value?.length > 50}
                horizontalPosition="center"
                text={detail.value}
              >
                {truncate(detail.value, 50)}
              </Popover>
            </li>
          ))}
        </DetailsList>
      )}

      <ActionButtons>
        {additionalActionButtons?.map(({
          name,
          tooltipText,
          ...button
        }) => (
          <Popover
            key={name}
            shouldDisplayPopover={!!tooltipText}
            horizontalPosition="center"
            text={tooltipText || ''}
          >
            <Button
              key={name}
              {...button}
            >
              {name}
            </Button>
          </Popover>
        ))}
      </ActionButtons>

      {children}
    </CardWrapper>
  );
};

TopCard.propTypes = {
  actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
  additionalActionButtons: PropTypes.arrayOf(PropTypes.shape({})),
  backButtonLink: PropTypes.string,
  children: PropTypes.node,
  chipData: PropTypes.shape({
    chipColor: PropTypes.string,
    chipLabel: PropTypes.string,
    chipOptions: PropTypes.arrayOf(PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string,
      tooltipText: PropTypes.string,
      value: PropTypes.number,
    })),
    onChipClick: PropTypes.func,
  }),
  dateInfo: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  entityTypes: PropTypes.arrayOf(PropTypes.string),
  restActions: PropTypes.node,
  title: PropTypes.string.isRequired,
};

export default TopCard;
