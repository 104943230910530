import {
  FETCH_ALL_MESSAGES_FAILURE,
  FETCH_ALL_MESSAGES_REQUEST,
  FETCH_ALL_MESSAGES_SUCCESS,
} from '../actions/messages';

const initialState = {
  allMessages: {
    results: [],
  },
  errors: null,
  isMessagesFetching: false,
  messages: {
    results: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_MESSAGES_REQUEST:
      return {
        ...state,
        isMessagesFetching: true,
      };
    case FETCH_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        allMessages: action.payload,
        errors: null,
        isMessagesFetching: false,
      };
    case FETCH_ALL_MESSAGES_FAILURE:
      return {
        ...state,
        allMessages: {
          ...state.messages,
          results: [],
        },
        errors: action.payload,
        isMessagesFetching: false,
      };
    default:
      return state;
  }
};
