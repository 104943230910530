import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Grid } from '@mui/material';
import { Group as GroupIcon } from '@mui/icons-material';

import { setOrganization } from 'store/actions/global';
import { fetchOrganizations } from 'store/actions/organizations';
import { organizationSelectors } from 'store/selectors/organizations';

import {
  Container,
  DescriptionBar,
  TabPanel,
} from 'common/components';
import { useQuery } from 'utils/helpers';
import OrganizationCard from '../components/OrganizationCard';

const OrganizationsWrapper = styled(Grid)`
  margin-bottom: 30px;
`;

const Organizations = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organizationsData = useSelector(state => organizationSelectors.getOrganizationsData(state));
  const query = useQuery();
  const tabIndex = query.get('tab') || '0';
  const organizationTabs = [
    {
      label: 'Demo',
      value: '0',
    },
    {
      label: 'Customer',
      value: '1',
    },
  ];

  const [
    shouldFilterOrganization,
    setShouldFilterOrganization,
  ] = useState(false);

  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);

  useEffect(() => {
    if (organizationsData.length) {
      const isAtLeastOneDemoOrganization = organizationsData.some(
        organization => organization.isDemo
      );

      if (isAtLeastOneDemoOrganization) {
        history.push({
          search: `?tab=${organizationTabs[0].value}`,
        });
      }
      setShouldFilterOrganization(isAtLeastOneDemoOrganization);
    }
  }, [organizationsData]);

  const handleCardClick = organization => {
    dispatch(setOrganization(organization));
    history.push(`/${organization.uuid}/dashboard`);
  };

  const renderOrganizationCard = organization => (
    <Grid
      item
      key={organization.uuid}
      md={4}
      sm={6}
      xs={12}
    >
      <OrganizationCard
        onCardClick={handleCardClick}
        organization={organization}
      />
    </Grid>
  );

  return (
    <Container>
      <Grid container>
        <DescriptionBar
          name="Organizations"
          hasTabs={shouldFilterOrganization}
          tabs={organizationTabs}
          icon={GroupIcon}
        />
      </Grid>
      <TabPanel
        index="0"
        value={tabIndex}
      >
        <OrganizationsWrapper
          container
          spacing={3}
        >
          {!!organizationsData.length && organizationsData.map(organization => {
            if (organization && !organization.isDemo) {
              return null;
            }

            return renderOrganizationCard(organization);
          })}
        </OrganizationsWrapper>
      </TabPanel>
      <TabPanel
        index="1"
        value={tabIndex}
      >
        <OrganizationsWrapper
          container
          spacing={3}
        >
          {!!organizationsData.length && organizationsData.map(organization => {
            if (organization && organization.isDemo) {
              return null;
            }

            return renderOrganizationCard(organization);
          })}
        </OrganizationsWrapper>
      </TabPanel>
      <OrganizationsWrapper
        container
        spacing={3}
      >
        {
          !shouldFilterOrganization &&
          !!organizationsData.length &&
          organizationsData.map(organization => renderOrganizationCard(organization))
        }
      </OrganizationsWrapper>
    </Container>
  );
};

export default Organizations;
