import {
  renderListWithCommaSeparator,
  renderPartialListWithCommaSeparator,
} from 'utils/helpers';

export const renderFilterCardValue = (value, multipleValues, actionCenters, signups) => {
  if (multipleValues?.length) {
    return renderListWithCommaSeparator(multipleValues);
  }

  if (actionCenters?.length) {
    return renderPartialListWithCommaSeparator(actionCenters);
  }

  if (signups?.length) {
    return renderPartialListWithCommaSeparator(signups);
  }

  return value;
};

export const signupTypes = {
  actionCenter: 'actionCenter',
  signup: 'signup',
};

export const typeOptions = [
  {
    name: 'Action Center',
    value: signupTypes.actionCenter,
  },
  {
    name: 'Contact Form',
    value: signupTypes.signup,
  },
];
