import PropTypes from 'prop-types';

import MaterialTable from '@material-table/core';
import { Paper } from '@mui/material';

import {
  colors,
  linksColumns,
} from 'utils/constants';

const LinksTable = ({
  isDataFetching,
  links,
}) => (
  <MaterialTable
    columns={linksColumns}
    components={{
      Container: props => (
        <Paper
          {...props}
          elevation={0}
          square
          variant="outlined"
        />
      ),
    }}
    data={links}
    isLoading={isDataFetching}
    options={{
      actionsColumnIndex: -1,
      headerStyle: {
        backgroundColor: colors.tableHeaderGray,
        color: colors.white,
      },
      selection: false,
      showTitle: false,
    }}
  />
);

LinksTable.propTypes = {
  isDataFetching: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    clicks: PropTypes.number,
    url: PropTypes.string,
  })).isRequired,
};

export default LinksTable;
