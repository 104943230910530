import { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Link,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { campaignsSelectors } from 'store/selectors/campaigns';
import {
  deleteTargetGroup,
  fetchTargetGroups,
} from 'store/actions/campaigns';
import {
  setSnackbarSuccess,
} from 'store/actions/global';

import { useConfirm } from 'material-ui-confirm';
import {
  Button,
  Grid,
} from '@mui/material';
import {
  Add as AddIcon,
  PeopleAlt as PeopleAltIcon,
} from '@mui/icons-material';

import {
  Backdrop,
  BreadcrumbsNavigation,
  Container,
  DescriptionBar,
} from 'common/components';
import TargetGroupItem from '../components/TargetGroupItem';

const TargetGroupsList = () => {
  const dispatch = useDispatch();

  const {
    organizationUuid,
  } = useParams();

  const {
    isDataFetching,
    targetGroups,
  } = useSelector(state => ({
    isDataFetching: campaignsSelectors.isDataFetching(state),
    targetGroups: campaignsSelectors.getTargetGroups(state),
  }));

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/campaigns`,
      name: 'Advocacy',
    },
    {
      link: '',
      name: 'Target Groups',
    },
  ];

  useEffect(() => {
    dispatch(fetchTargetGroups({ organization: organizationUuid }));
  }, []);

  const confirm = useConfirm();

  const handleRemoveTargetGroup = targetGroupUuid => {
    confirm({
      confirmationText: 'Remove',
      title: 'Remove target group?',
    })
      .then(() => dispatch(deleteTargetGroup(targetGroupUuid)))
      .then(() => dispatch(fetchTargetGroups({ organization: organizationUuid })))
      .then(() => dispatch(setSnackbarSuccess()));
  };

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container marginBottom="40">
        <Grid container>
          <DescriptionBar
            name="Target Groups"
            icon={PeopleAltIcon}
          >
            <Button
              disableElevation
              color="secondary"
              component={Link}
              startIcon={<AddIcon />}
              to={`/${organizationUuid}/create-target-group`}
              variant="contained"
            >
              New Target Group
            </Button>
          </DescriptionBar>
        </Grid>
        {targetGroups?.map(targetGroup => (
          <TargetGroupItem
            handleRemoveTargetGroup={handleRemoveTargetGroup}
            name={targetGroup.name}
            uuid={targetGroup.uuid}
            key={targetGroup.uuid}
          />
          ))
        }
      </Container>
      <Backdrop isOpen={isDataFetching} />
    </>
  );
};

TargetGroupsList.propTypes = {
  location: PropTypes.shape({
    listProps: PropTypes.shape({
      listId: PropTypes.string,
    }),
  }).isRequired,
};

export default TargetGroupsList;
