import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  CircularProgress,
  Backdrop as MaterialBackdrop,
} from '@mui/material';

const StyledBackdrop = styled(MaterialBackdrop)`
  z-index: 1000;
`;

const Backdrop = ({ isOpen }) => (
  <StyledBackdrop open={isOpen}>
    <CircularProgress color="inherit" />
  </StyledBackdrop>
);

Backdrop.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default Backdrop;
