import request from 'utils/request';

/*
 * Exhaustively fetches data from a paged API listing
 * endpoint. Use with care!
 */
export const fetchAllPagedData = (
  dataUrl,
  fetchSignal,
  successSignal,
  failureSignal
) => dispatch => {
  dispatch(fetchSignal());
  const fullDataList = [];

  const innerPaginatedFetch = next => request
  .get(next || dataUrl)
  .then(({ data }) => {
    fullDataList.push(...data.results);

    if (!data.next) {
      return fullDataList;
    }

    return innerPaginatedFetch(data.next);
  });

  return innerPaginatedFetch()
  .then(data => {
    dispatch(successSignal(data));

    return data;
  })
  .catch(({ response: { data } = {} }) => {
    dispatch(failureSignal(data));

    throw data;
  });
};

export default fetchAllPagedData;
