import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography } from '@mui/material';
import { FilterList as FilterListIcon } from '@mui/icons-material';

import { colors } from 'utils/constants';

import {
  Bold,
  OptionImage,
} from 'globalStyles';
import { renderFilterCardValue } from '../helpers';

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  margin-right: 30px;
  margin-bottom: 30px;
  border: solid 1px ${colors.mercury};
  border-radius: 5px;
  cursor: pointer;
`;

const FilterContent = styled.p`
  padding: 0 10px;
  text-align: center;
`;

const FilterInformation = styled.div`
  display: flex;
  text-align: center;

  span {
    padding-left: 5px;
    font-size: 18px;
    line-height: 24px;
  }
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 10px;
`;

const StepFour = ({
  filterIncludes,
  filterType,
  filters,
  lists,
}) => {
  const renderSelectedLists = () => lists.map(({
    count = 0,
    name,
    uuid,
  }) => <li key={uuid}>{`${name}: ${count} contacts`}</li>);

  return (
    <>
      <StyledTypography variant="h5">Review List</StyledTypography>
      <StyledTypography variant="h6">Selected Lists</StyledTypography>
      <ul>{renderSelectedLists()}</ul>
      <StyledTypography variant="h6">List Conditions</StyledTypography>
      <FilterContainer>
        <OptionImage
          alt={filterType === 'OR' ? 'All contacts' : 'Overlapped contacts'}
          src={filterType === 'OR' ? '/all_contacts_icon.svg' : '/overlapped_contacts_icon.svg'}
        />
        <p>{filterType === 'OR' ? 'All Contacts' : 'Overlapped contacts'}</p>
      </FilterContainer>
      <StyledTypography variant="h6">Filters</StyledTypography>
      {filters.length > 0 ? (
        <FiltersWrapper>
          {filters.map(({
            actionCenters,
            filterCondition,
            isOfficialField,
            name,
            officialName,
            signups,
            value,
            values,
            uuid,
          }) => (
            <FilterContainer key={uuid || `${name}-${value || filterCondition}`}>
              <FilterInformation>
                <span>{name}</span>
              </FilterInformation>
              <FilterContent>
                {isOfficialField ? 'Contact' : 'Contact\'s'}
                {' '}
                {isOfficialField ? '' : name}
                {' '}
                {filterCondition}
                {' '}
                <Bold>
                  {isOfficialField ?
                    officialName :
                    renderFilterCardValue(value, values, actionCenters, signups)
                  }
                </Bold>
              </FilterContent>
            </FilterContainer>
          ))}
        </FiltersWrapper>
      ) : (
        <StyledTypography variant="subtitle1">There are no filters applied</StyledTypography>
      )}
      <StyledTypography variant="h6">Filter Conditions</StyledTypography>
      <FilterContainer>
        <FilterListIcon fontSize="large" />
        <p>{filterIncludes === 'AND' ? 'All Filters' : 'At Least One Filter'}</p>
      </FilterContainer>
    </>
  );
};

StepFour.propTypes = {
  filterIncludes: PropTypes.string.isRequired,
  filterType: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default StepFour;
