import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  CircularProgress,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';

import { fetchActionCenterStatistics } from 'store/actions/statistics';

import {
  StackedChart,
  TabPanel,
} from 'common/components';
import MapBox from 'common/containers/MapBox';

import {
  clusteredPointsStyle,
  clusteredPointsTitleStyle,
  colors,
  unclusteredPointStyle,
} from 'utils/constants';
import {
  getLocations,
  getPointsGeojson,
} from 'utils/helpers';
import {
  CircularProgressWrapper,
  TabsWrapper,
} from 'globalStyles';
import { fitMapBoundsToPoints } from 'utils/mapping';

const StyledMapBox = styled(MapBox)`
  width: 100%;
  height: 400px;
`;

const StyledPaper = styled(Paper)`
  width: 100%;
`;

const ActionCenterAnalyticsTabs = ({
  isContactsFetched,
  contacts,
}) => {
  const [
    analyticsTabIndex,
    setAnalyticsTabIndex,
  ] = useState(0);
  const [
    chartStatistics,
    setChartStatistics,
  ] = useState(null);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const dispatch = useDispatch();
  const {
    actionCenterUuid,
  } = useParams();

  const firstFetch = useRef({
    statistics: false,
  });

  const handleChangeAnalyticsTab = (_, newValue) => {
    setAnalyticsTabIndex(newValue);

    if (newValue === 1 && !firstFetch.current.statistics) {
      const today = new Date();
      const endDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

      dispatch(fetchActionCenterStatistics(actionCenterUuid, `total_days=14&end_date=${endDate}&tz_offset_minutes=${new Date().getTimezoneOffset()}`))
        .then(data => {
          setChartStatistics(data);
          firstFetch.current.statistics = true;
        });
    }
  };

  useEffect(() => {
    if (map.current && isContactsFetched && contacts) {
      const features = getLocations(contacts);
      const geojson = getPointsGeojson(features);

      map.current.on('load', () => {
        if (map.current.getSource('locations')) {
          return;
        }
        map.current.addSource('locations', geojson);

        map.current.addLayer({
          ...unclusteredPointStyle,
        });

        map.current.addLayer({
          ...clusteredPointsStyle,
        });

        map.current.addLayer({
          ...clusteredPointsTitleStyle,
        });

        fitMapBoundsToPoints(map.current, geojson.data.features);
      });
    }
  }, [
    analyticsTabIndex,
    contacts,
    isContactsFetched,
  ]);

  return (
    <TabsWrapper>
      <StyledPaper
        square
        variant="outlined"
      >
        <Tabs
          indicatorColor="primary"
          onChange={handleChangeAnalyticsTab}
          TabIndicatorProps={{
            style: {
              backgroundColor: colors.secondary,
            },
          }}
          value={analyticsTabIndex}
        >
          <Tab label="Advocates 🌎" />
          <Tab label="Actions 📊" />
        </Tabs>
        <TabPanel
          index={0}
          value={analyticsTabIndex}
        >
          {!isContactsFetched ? (
            <CircularProgressWrapper>
              <CircularProgress />
            </CircularProgressWrapper>
          ) : (
            <StyledMapBox
              mapContainer={mapContainer}
              map={map}
            />
          )}
        </TabPanel>
        <TabPanel
          index={1}
          value={analyticsTabIndex}
        >
          <StackedChart data={chartStatistics} />
        </TabPanel>
      </StyledPaper>
    </TabsWrapper>
  );
};

ActionCenterAnalyticsTabs.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({
      city: PropTypes.string,
      point: PropTypes.shape({
        coordinates: PropTypes.arrayOf(PropTypes.number),
        type: PropTypes.string,
      }),
      state: PropTypes.string,
      uuid: PropTypes.string,
      zipCode: PropTypes.string,
    })),
  })).isRequired,
  isContactsFetched: PropTypes.bool.isRequired,
};

export default ActionCenterAnalyticsTabs;
