import PropTypes from 'prop-types';

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Stop as StopIcon,
} from '@mui/icons-material';

import {
  ButtonMenu,
  Popover,
} from 'common/components';

const SettingsButton = ({
  handleEditSignup,
  handleStopSignup,
  handleDeleteSignup,
  publishStatus,
}) => (
  <ButtonMenu>
    <MenuItem onClick={handleEditSignup}>
      <ListItemIcon>
        <EditIcon />
      </ListItemIcon>
      <ListItemText
        primary="Edit Contact Form Design"
      />
    </MenuItem>
    {publishStatus === 2 && (
      <MenuItem onClick={handleStopSignup}>
        <ListItemIcon>
          <StopIcon />
        </ListItemIcon>
        <ListItemText primary="Stop Signup" />
      </MenuItem>
    )}
    <Popover
      shouldDisplayPopover={publishStatus === 2}
      text="You cannot delete the Contact Form if it is live"
    >
      <MenuItem
        disabled={publishStatus === 2}
        onClick={handleDeleteSignup}
      >
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText primary="Delete Contact Form" />
      </MenuItem>
    </Popover>
  </ButtonMenu>
);

SettingsButton.propTypes = {
  handleDeleteSignup: PropTypes.func.isRequired,
  handleEditSignup: PropTypes.func.isRequired,
  handleStopSignup: PropTypes.func.isRequired,
  publishStatus: PropTypes.number.isRequired,
};

export default SettingsButton;
