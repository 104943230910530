import PropTypes from 'prop-types';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MaterialDialog,
} from '@mui/material';

const WidgetDialog = ({
  children,
  handleDialogClose,
  isDialogOpen,
  title,
  handleCopyToClipboard,
}) => (
  <MaterialDialog
    aria-labelledby="dialog-title"
    disableEnforceFocus
    fullWidth
    onClose={handleDialogClose}
    open={isDialogOpen}
  >
    <DialogTitle id="dialog-title">{title}</DialogTitle>
    <DialogContent>
      {children}
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        onClick={handleDialogClose}
      >
        Close
      </Button>
      <Button
        color="secondary"
        disableElevation
        variant="contained"
        onClick={handleCopyToClipboard}
      >
        Copy Code
      </Button>
    </DialogActions>
  </MaterialDialog>
);

WidgetDialog.propTypes = {
  children: PropTypes.element.isRequired,
  handleCopyToClipboard: PropTypes.func.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default WidgetDialog;
