import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  QueryIndexTabs,
} from 'common/components';

const TabsWrapper = styled.div`
  margin-bottom: 40px;
`;

const SettingsTabs = ({
  tabs,
}) => (
  <TabsWrapper>
    <QueryIndexTabs
      tabs={tabs}
    />
  </TabsWrapper>
);

SettingsTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

export default SettingsTabs;
