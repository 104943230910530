import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import debounce from 'lodash.debounce';

import MaterialTable, { MTableToolbar } from '@material-table/core';
import { useConfirm } from 'material-ui-confirm';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {
  DeleteOutline as DeleteOutlineIcon,
  DeleteSweep as DeleteSweepIcon,
  GroupAdd as GroupAddIcon,
  Info as InfoIcon,
  ListAlt as ListAltIcon,
  Map as MapIcon,
  Publish as PublishIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  TextFields as TextFieldsIcon,
} from '@mui/icons-material';

import {
  deleteAllListContacts,
  deleteContacts,
  deleteListContacts,
  exportList,
  fetchAllListContact,
  fetchChildrenLists,
  fetchList,
  fetchRootList,
  getContactsListFlatFileConfig,
  updateContactsList,
} from 'store/actions/contacts';
import { setSnackbarSuccess } from 'store/actions/global';
import { contactSelectors } from 'store/selectors/contacts';

import {
  BreadcrumbsNavigation,
  Container,
  DescriptionBar,
  FlatFileButton,
  TableIcon,
  UserDialog,
} from 'common/components';
import {
  colors,
  listTypes,
} from 'utils/constants';
import {
  handleZipcodeFormat,
  useQuery,
} from 'utils/helpers';

import ListInfoDialog from '../components/ListInfoDialog';
import MergeContactsDialog from '../components/MergeContactsDialog';
import AddToAnotherListDialog from '../components/AddToAnotherListDialog';

const TableActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const TableButtonsWrapper = styled.div`
  padding: 20px 20px 20px 6px;
`;

const StyledTypography = styled(Typography)`
  color: ${colors.placeholder};
`;

const DELETE_ALL_CONTACTS_MESSAGE = 'This action will delete all the contacts in this list';
const EXPORT_LIST_SUCCESS_MESSAGE = 'The contacts export will be sent to your email shortly.';
const NO_RECORDS_MESSAGE = 'No records to display';
const REMOVE_CONTACT = 'This action will remove selected contacts from entire database';
const REMOVE_CONTACT_FROM_LIST_MESSAGE = 'This action will remove contact from current list';
const RENAME_LIST_DIALOG_SUBTITLE = 'Type a new name for the list below';
const RENAME_LIST_DIALOG_TITLE = 'Rename the list';

const { REACT_APP_HIDE_CONTACTS_IMPORT } = process.env;

export const listContactsColumns = [
  {
    field: 'firstName',
    title: 'First name',
  },
  {
    field: 'lastName',
    title: 'Last name',
  },
  {
    customSort: (a, b) => {
      const aIsGeocoded = !!a.locations?.length && a.locations.some(location => location.point);
      const bIsGeocoded = !!b.locations?.length && b.locations.some(location => location.point);

      if (aIsGeocoded) {
        return -1;
      }

      if (bIsGeocoded) {
        return 1;
      }

      return 0;
    },
    field: 'geotarget',
    render: rowData => (
      <TableIcon
        boolean={
          !!rowData.locations?.length &&
          rowData.locations.some(location => location.point)
        }
      />
    ),
    title: 'Geotarget',
  },
  {
    field: 'locations[0].streetAddress',
    title: 'Street address',
  },
  {
    field: 'locations[0].city',
    title: 'City',
  },
  {
    field: 'locations[0].state',
    title: 'State',
  },
  {
    field: 'locations[0].zipCode',
    render: rowData => (rowData.locations ? handleZipcodeFormat(rowData.locations[0].zipCode) : ''),
    title: 'Zip code',
  },
];

const ListDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const searchText = query.get('searchValue');
  const tableRef = useRef(null);

  const {
    organizationUuid,
    listUuid,
  } = useParams();

  const {
    childrenLists,
    inProgressFetchedContactsCount,
    isListContactsFetching,
    isListFetching,
    listContacts,
    listDetails,
    rootList,
    totalListContactsCount,
  } = useSelector(state => ({
    childrenLists: contactSelectors.getChildrenLists(state)[organizationUuid] || [],
    inProgressFetchedContactsCount: contactSelectors.getInProgressFetchedContactsCount(state),
    isListContactsFetching: contactSelectors.isListContactsFetching(state),
    isListFetching: contactSelectors.isListFetching(state),
    listContacts: contactSelectors.getListContacts(state)[listUuid] || [],
    listDetails: contactSelectors.getList(state),
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
    totalListContactsCount: contactSelectors.getTotalListContactsCount(state),
  }));

  const {
    name,
    type,
    parent,
    uuid: listDetailsUuid,
  } = listDetails;
  const { staticList } = listTypes;

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/contacts/lists`,
      name: 'Lists',
    },
    {
      link: '',
      name,
    },
  ];

  useEffect(() => {
    if (
      (!Object.keys(listContacts).length || listDetailsUuid !== listUuid) &&
      !isListContactsFetching
    ) {
      dispatch(fetchList(listUuid)).then(data => {
        dispatch(fetchAllListContact(listUuid));

        if (data.type === staticList && data.parent) {
          dispatch(getContactsListFlatFileConfig(listUuid));
        }
      });

      if (!Object.keys(rootList).length) {
        dispatch(fetchRootList(organizationUuid)).then(data => {
          if (!childrenLists.length) {
            dispatch(fetchChildrenLists(data.uuid, organizationUuid));
          }
        });
      }
    }
  }, []);

  const [
    isListInfoOpen,
    setListInfoOpen,
  ] = useState(false);
  const [
    selectedRows,
    setSelectedRows,
  ] = useState([]);
  const [
    isRenameDialogOpen,
    setRenameDialogOpen,
  ] = useState(false);
  const [
    listName,
    setListName,
  ] = useState('');

  const confirm = useConfirm();

  const handleDeleteAllContacts = () => {
    confirm({
      confirmationText: 'Delete',
      description: DELETE_ALL_CONTACTS_MESSAGE,
      title: 'Delete contacts?',
    })
      .then(() => dispatch(deleteAllListContacts(listUuid)));
  };

  const handleListUpdate = (uuid, params) => dispatch(
    updateContactsList(uuid, organizationUuid, params)
  );

  const handleRemoveFromList = () => {
    confirm({
      confirmationText: 'Remove',
      description: '',
      title: REMOVE_CONTACT_FROM_LIST_MESSAGE,
    })
      .then(() => dispatch(deleteListContacts(
        listUuid,
        { contactUuids: selectedRows.map(contact => contact.uuid) }
      )));
  };

  const handleSnackbarOpen = () => {
    dispatch(exportList(listUuid))
      .then(() => dispatch(setSnackbarSuccess({ message: EXPORT_LIST_SUCCESS_MESSAGE })));
  };

  const handleRemoveContact = () => {
    confirm({
      confirmationText: 'Remove',
      description: '',
      title: REMOVE_CONTACT,
    })
      .then(() => dispatch(deleteContacts(
        listUuid,
        { contactUuids: selectedRows.map(contact => contact.uuid) }
      )));
  };

  const uncheckAllContacts = () => {
    tableRef.current.onAllSelected(false);
    setSelectedRows([]);
  };

  const renderLoader = () => (
    <>
      <Box
        position="relative"
        display="inline-flex"
      >
        <CircularProgress size={56} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StyledTypography
            variant="h6"
            component="div"
          >
            {`${Math.round(
              totalListContactsCount && isListContactsFetching ?
                (inProgressFetchedContactsCount / totalListContactsCount) * 100 :
                0
            )}%`}
          </StyledTypography>
        </Box>
      </Box>
      <StyledTypography
        variant="h5"
        component="div"
        color="textSecondary"
      >
        {inProgressFetchedContactsCount && isListContactsFetching ? `Loaded ${inProgressFetchedContactsCount} contacts` : 'fetching contacts..'}
      </StyledTypography>
    </>
  );

  const SEARCH_INPUT_PARENT = 'search_input_parent';

  const handleParamChange = async searchValue => {
    history.replace({ search: `?${new URLSearchParams({ searchValue }).toString()}` });

    // Hack for keep focus on material search input
    setTimeout(() => {
      const searchBar = document.querySelector(`#${SEARCH_INPUT_PARENT} input`);

      if (!searchBar) return;

      searchBar.focus();
    }, 0);
  };

  const debounceHandleParamChange = debounce(handleParamChange, 1000);

  return (
    <>
      <BreadcrumbsNavigation
        isLoading={isListFetching}
        links={breadcrumbs}
      />
      <Container marginBottom="40">
        <Grid container>
          <DescriptionBar
            name={listDetailsUuid === listUuid ? name : ''}
            icon={ListAltIcon}
          />
        </Grid>
        <TableActionsWrapper>
          {
            type === staticList &&
            parent &&
            !REACT_APP_HIDE_CONTACTS_IMPORT &&
              (
                <FlatFileButton
                  isLoading={isListFetching}
                  listUuid={listUuid}
                />
              )
          }
          {type === staticList && parent && (
          <Button
            onClick={() => history.push({
              pathname: `/${organizationUuid}/contacts/lists/${listUuid}/create-contact`,
              state: {
                goBackLink: `/${organizationUuid}/contacts/lists/${listUuid}`,
              },
            })}
            startIcon={<GroupAddIcon />}
          >
            Add Contact
          </Button>
          )}
          <Button
            onClick={() => history.push(`/${organizationUuid}/contacts/lists/${listUuid}/map-list`)}
            startIcon={<MapIcon />}
            disabled={isListContactsFetching || isListFetching}
          >
            Map view
          </Button>
          <Button
            onClick={handleSnackbarOpen}
            startIcon={<PublishIcon />}
          >
            Export list
          </Button>
          <Button
            onClick={() => setRenameDialogOpen(true)}
            startIcon={<TextFieldsIcon />}
          >
            Rename list
          </Button>
          <UserDialog
            handleDataUpdate={handleListUpdate}
            handleDialogClose={() => setRenameDialogOpen(false)}
            isDialogOpen={isRenameDialogOpen}
            listId={listUuid}
            listName={listName}
            subtitle={RENAME_LIST_DIALOG_SUBTITLE}
            title={RENAME_LIST_DIALOG_TITLE}
          >
            <TextField
              autoFocus
              defaultValue={listName}
              fullWidth
              label="List name"
              onBlur={event => setListName(event.target.value)}
            />
          </UserDialog>
          {/*
          Temporarily hidden
          <Button
            component={Link}
            to={`/${organizationUuid}/contacts/lists/${listUuid}/duplicates`}
            startIcon={<PeopleAltIcon />}
          >
            Remove duplicates
          </Button> */}
          {!!Object.keys(listDetails) && listDetails.type === 'ComplexList' && (
            <>
              <Button
                onClick={() => setListInfoOpen(true)}
                startIcon={<InfoIcon />}
              >
                Filter Info
              </Button>
              <ListInfoDialog
                listDetails={listDetails}
                isListInfoOpen={isListInfoOpen}
                organizationUuid={organizationUuid}
                setListInfoOpen={setListInfoOpen}
              />
            </>
          )}
          <Button
            onClick={handleDeleteAllContacts}
            startIcon={<DeleteSweepIcon />}
          >
            Delete all contacts
          </Button>
        </TableActionsWrapper>
        <MaterialTable
          tableRef={tableRef}
          key={`key: ${isListContactsFetching}`}
          columns={listContactsColumns}
          onSearchChange={async searchValue => debounceHandleParamChange(searchValue)}
          components={{
            Container: props => (
              <Paper
                {...props}
                elevation={0}
                variant="outlined"
              />
            ),
            Toolbar: props => (
              <div>
                <div id={SEARCH_INPUT_PARENT}>
                  <MTableToolbar {...props} />
                </div>
                {!!selectedRows.length && (
                  <TableButtonsWrapper>
                    <AddToAnotherListDialog selectedRows={selectedRows} />
                    <MergeContactsDialog
                      selectedRows={selectedRows}
                      uncheckAllContacts={uncheckAllContacts}
                    />
                    {type === staticList && (
                      <Button
                        onClick={handleRemoveFromList}
                        startIcon={<RemoveCircleOutlineIcon />}
                      >
                        Remove from list
                      </Button>
                    )}
                    <Button
                      onClick={handleRemoveContact}
                      startIcon={<DeleteOutlineIcon />}
                    >
                      Delete
                    </Button>
                  </TableButtonsWrapper>
                )}
              </div>
            ),
          }}
          data={listContacts}
          localization={{
            body: {
              emptyDataSourceMessage: (isListContactsFetching || isListFetching) ?
                renderLoader() :
                NO_RECORDS_MESSAGE,
            },
          }}
          onRowClick={(_, rowData) => history.push(
            `/${organizationUuid}/contacts/lists/${listUuid}/contact/${rowData.uuid}`
          )}
          onSelectionChange={rows => setSelectedRows(rows)}
          options={{
            actionsColumnIndex: -1,
            pageSize: (isListContactsFetching || !listContacts.length) ? 5 : 20,
            pageSizeOptions: [
              5,
              20,
              50,
              100,
              200,
            ],
            searchText,
            selection: true,
            showTitle: false,
          }}
        />
      </Container>
    </>
  );
};

ListDetails.propTypes = {
  location: PropTypes.shape({
    listProps: PropTypes.shape({
      listId: PropTypes.string,
    }),
  }).isRequired,
};

export default ListDetails;
