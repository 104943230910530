import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  Field,
  Form,
} from 'react-final-form';

import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@mui/material';

import {
  fetchChildrenLists,
  postList,
} from 'store/actions/contacts';

const CardActions = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  justify-content: center;
  padding: 24px;
`;

const NewList = ({
  className,
  list: {
    uuid,
  },
  onClose,
  organizationUuid,
}) => {
  const dispatch = useDispatch();
  const onSubmit = async values => {
    const params = {
      ...values,
      parent: uuid,
      type: 'StaticList',
    };

    try {
      await dispatch(postList(params));
      await dispatch(fetchChildrenLists(uuid, organizationUuid));

      return onClose(true);
    } catch (error) {
      return error;
    }
  };

  return (
    <Card
      className={className}
      variant="outlined"
    >
      <StyledCardContent>
        <CardActions>
          <Form
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              pristine,
              submitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Field name="name">
                    {({
                      input,
                      meta,
                    }) => (
                      <TextField
                        {...input}
                        error={(meta.error || meta.submitError) && !meta.dirtySinceLastSubmit}
                        helperText={!meta.dirtySinceLastSubmit ? (meta.error || meta.submitError) : ''}
                        type="text"
                        placeholder="Enter List Name"
                      />
                    )}
                  </Field>
                  <StyledButton
                    color="secondary"
                    disabled={submitting || pristine}
                    disableElevation
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </StyledButton>
                </Grid>
              </form>
            )}
          />
          <StyledButton
            color="secondary"
            disableElevation
            type="button"
            variant="contained"
            onClick={() => onClose(false)}
          >
            Cancel
          </StyledButton>
        </CardActions>
      </StyledCardContent>
    </Card>
  );
};

NewList.propTypes = {
  className: PropTypes.string.isRequired,
  list: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  organizationUuid: PropTypes.string.isRequired,
};

export default NewList;
