import {
  FETCH_AUTH_STATUS_FAILURE,
  FETCH_AUTH_STATUS_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_ORGANIZATIONS_FAILURE,
  FETCH_USER_ORGANIZATIONS_REQUSET,
  FETCH_USER_ORGANIZATIONS_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PATCH_USER_FAILURE,
  PATCH_USER_REQUEST,
  PATCH_USER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
} from '../actions/auth';

const initialState = {
  isAuthenticated: false,
  isFetching: false,
  isPending: false,
  user: {},
  userOrganizations: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUTH_STATUS_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.payload.authenticated,
      };
    case FETCH_AUTH_STATUS_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
      };
    case FETCH_USER_REQUEST:
      return {
        ...state,
        errors: null,
        isFetching: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
        user: {},
      };
    case FETCH_USER_ORGANIZATIONS_REQUSET:
      return {
        ...state,
        errors: null,
        isFetching: true,
      };
    case FETCH_USER_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userOrganizations: action.payload,
      };
    case FETCH_USER_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
        userOrganizations: [],
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOGIN_SUCCESS:
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case PASSWORD_RESET_SUCCESS:
    case PASSWORD_RESET_FAILURE:
      return {
        ...state,
        isPending: false,
      };
    case PATCH_USER_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case PATCH_USER_SUCCESS:
      return {
        ...state,
        isPending: false,
        user: action.payload,
      };
    case PATCH_USER_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case REGISTER_SUCCESS:
    case REGISTER_FAILURE:
      return {
        ...state,
        isPending: false,
      };
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        errors: null,
        isPending: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPending: false,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    default:
      return state;
  }
};
