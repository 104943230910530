import {
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fetchLocations } from 'store/actions/locations';

import MapBox from 'common/containers/MapBox';
import {
  clusteredPointsStyle,
  clusteredPointsTitleStyle,
  unclusteredPointStyle,
} from 'utils/constants';
import { getPointsGeojson } from 'utils/helpers';

const RecipientsMap = ({ alertType }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const dispatch = useDispatch();
  const {
    actionAlertUuid,
    organizationUuid,
  } = useParams();

useEffect(() => {
  if (map.current && alertType) {
    dispatch(fetchLocations(organizationUuid, `${alertType}_alert=${actionAlertUuid}&map=1`))
      .then(data => {
        if (data.count === 0) {
          return;
        }

        const geojson = getPointsGeojson(data.results.features);

        const onMapLoad = () => {
          if (map.current.getSource('locations')) {
            return;
          }
          map.current.addSource('locations', geojson);

          map.current.addLayer({
            ...unclusteredPointStyle,
          });

          map.current.addLayer({
            ...clusteredPointsStyle,
          });

          map.current.addLayer({
            ...clusteredPointsTitleStyle,
          });
        };

        // Workaround a known problem: https://github.com/mapbox/mapbox-gl-js/issues/6707#issue-325222553
        if (map.current.loaded()) {
          onMapLoad();
        } else {
          map.current.on('load', () => {
            onMapLoad();
          });
        }
      });
  }
}, [alertType]);

  return (
    <MapBox
      mapContainer={mapContainer}
      map={map}
    />
  );
};

RecipientsMap.defaultProps = {
  alertType: null,
};

RecipientsMap.propTypes = {
  alertType: PropTypes.oneOf([
    'email',
    'text',
  ]),
};

export default RecipientsMap;
