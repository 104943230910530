import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Field,
  Form,
} from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import styled from 'styled-components';

import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import {
  fieldTypes,
  usStates,
} from 'utils/constants';
import { required } from 'utils/validators';
import {
  handleNumberFirstDigitsFormat,
  handleZipcodeFormat,
} from 'utils/helpers';
import { Asterisk } from 'globalStyles';
import {
  customFieldType,
  locationType,
} from 'common/typings';

const Label = styled.label`
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-size: 18px;
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const Condition = ({
  children,
  when,
}) => (
  <Field
    name={when}
    subscription={{ value: true }}
  >
    {({ input: { value } }) => (value ? children : null)}
  </Field>
);

Condition.propTypes = {
  children: PropTypes.node.isRequired,
  when: PropTypes.string.isRequired,
};

const ContactForm = ({
  cancelHref,
  customFieldsList,
  initialValues,
  onSubmit,
}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    keepDirtyOnReinitialize
    mutators={{
      ...arrayMutators,
    }}
    render={({
      handleSubmit,
      submitting,
      pristine,
    }) => (
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            alignContent="space-between"
            container
            item
            xs={12}
            md={6}
          >
            <Label>
              First Name
              <Asterisk />
            </Label>
            <StyledField
              name="firstName"
              validate={required}
            >
              {({
                input,
                meta,
              }) => (
                <TextField
                  {...input}
                  fullWidth
                  error={meta.touched && !!(meta.error || meta.submitError)}
                  helperText={meta.touched && (meta.error || meta.submitError)}
                  type="text"
                  placeholder="First Name"
                />
              )}
            </StyledField>
          </Grid>
          <Grid
            alignContent="space-between"
            container
            item
            xs={12}
            md={6}
          >
            <Label>
              Last name
              <Asterisk />
            </Label>
            <StyledField
              name="lastName"
              validate={required}
            >
              {({
                input,
                meta,
              }) => (
                <TextField
                  {...input}
                  fullWidth
                  error={meta.touched && !!(meta.error || meta.submitError)}
                  helperText={meta.touched && (meta.error || meta.submitError)}
                  type="text"
                  placeholder="Last Name"
                />
              )}
            </StyledField>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
          >
            <Label>
              Email
              <Asterisk />
            </Label>
            <FieldArray name="emails">
              {() => (
                <StyledField
                  name="emails[0].email"
                  validate={required}
                >
                  {({
                    input,
                    meta,
                  }) => (
                    <TextField
                      {...input}
                      fullWidth
                      error={meta.touched && !!(meta.error || meta.submitError)}
                      helperText={meta.touched && (meta.error || meta.submitError)}
                      type="email"
                      placeholder="Email"
                    />
                  )}
                </StyledField>
              )}
            </FieldArray>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Label>Phone Number</Label>
            <FieldArray name="phoneNumbers">
              {() => (
                <StyledField name="phoneNumbers[0].phoneNumber">
                  {({
                    input,
                    meta,
                  }) => (
                    <TextField
                      {...input}
                      fullWidth
                      error={(meta.error || meta.submitError) && !meta.dirtySinceLastSubmit}
                      helperText={
                          !meta.dirtySinceLastSubmit && (meta.error || meta.submitError)}
                      type="tel"
                      placeholder="(555) 555-5555"
                      onChange={event => {
                          const { value } = event.target;

                          input.onChange(handleNumberFirstDigitsFormat(value));
                        }}
                    />
                  )}
                </StyledField>
              )}
            </FieldArray>
          </Grid>
          <FieldArray name="locations">
            {() => (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Label>Street Address</Label>
                  <StyledField name="locations[0].streetAddress">
                    {({
                      input,
                      meta,
                    }) => (
                      <TextField
                        {...input}
                        fullWidth
                        error={meta.touched && !!(meta.error || meta.submitError)}
                        helperText={meta.touched && (meta.error || meta.submitError)}
                        type="text"
                        placeholder="Street Address"
                      />
                    )}
                  </StyledField>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Label>Street Address Two</Label>
                  <StyledField name="locations[0].streetAddress2">
                    {({
                      input,
                      meta,
                    }) => (
                      <TextField
                        {...input}
                        fullWidth
                        error={meta.touched && !!(meta.error || meta.submitError)}
                        helperText={meta.touched && (meta.error || meta.submitError)}
                        type="text"
                        placeholder="Street Address Two"
                      />
                    )}
                  </StyledField>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                >
                  <Label>City</Label>
                  <StyledField name="locations[0].city">
                    {({
                      input,
                      meta,
                    }) => (
                      <TextField
                        {...input}
                        fullWidth
                        error={meta.touched && !!(meta.error || meta.submitError)}
                        helperText={meta.touched && (meta.error || meta.submitError)}
                        type="text"
                        placeholder="City"
                      />
                    )}
                  </StyledField>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                >
                  <Label>State</Label>
                  <StyledField name="locations[0].state">
                    {({
                      input,
                    }) => (
                      <Select
                        {...input}
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {usStates.map(state => (
                          <MenuItem
                            key={state.abbreviation}
                            value={state.abbreviation}
                          >
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </StyledField>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                >
                  <Label>Zip Code</Label>
                  <StyledField
                    name="locations[0].zipCode"
                    parse={handleZipcodeFormat}
                  >
                    {({
                      input,
                      meta,
                    }) => (
                      <TextField
                        {...input}
                        fullWidth
                        error={meta.touched && !!(meta.error || meta.submitError)}
                        helperText={meta.touched && (meta.error || meta.submitError)}
                        type="text"
                        placeholder="Zip Code"
                      />
                    )}
                  </StyledField>
                </Grid>
              </>
            )}
          </FieldArray>
          <FieldArray name="customFields">
            {() => customFieldsList.map(customField => {
              if (customField.fieldType === fieldTypes.textField) {
                return (
                  <Grid
                    item
                    xs={12}
                    key={customField.uuid}
                  >
                    <Label key={customField.label}>{customField.label}</Label>
                    <Field name={`customFields[${customField.uuid}]`}>
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          error={meta.touched && (meta.error || meta.submitError)}
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          placeholder={customField.placeholder}
                          type="text"
                        />
                      )}
                    </Field>
                  </Grid>
                );
              }

              return (
                <Grid
                  item
                  xs={12}
                  key={customField.uuid}
                >
                  <Label>{customField.label}</Label>
                  {
                    customField.options.map(option => (
                      <div key={option}>
                        <Field
                          component="input"
                          name={`customFields[${customField.uuid}]`}
                          type="radio"
                          value={option}
                        />
                        <Typography
                          color="textSecondary"
                          display="block"
                          gutterBottom
                          variant="subtitle2"
                        >
                          {option}
                        </Typography>
                      </div>
                    ))
                  }
                </Grid>
              );
            })}
          </FieldArray>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Button
              component={Link}
              to={cancelHref}
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
          <Grid
            container
            item
            justifyContent="flex-end"
            xs={12}
            md={6}
          >
            <Button
              color="secondary"
              disabled={submitting || pristine}
              disableElevation
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    )}
  />
);

ContactForm.defaultProps = {
  initialValues: {},
};

ContactForm.propTypes = {
  cancelHref: PropTypes.string.isRequired,
  customFieldsList: PropTypes.arrayOf(customFieldType).isRequired,
  initialValues: PropTypes.shape({
    customFields: PropTypes.objectOf(PropTypes.string),
    emails: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string })),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    locations: PropTypes.arrayOf(locationType),
    phoneNumbers: PropTypes.arrayOf(PropTypes.shape({ phoneNumber: PropTypes.string })),
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default ContactForm;
