import { combineReducers } from 'redux';
import authReducer from './auth';
import broadcastsReducer from './broadcasts';
import campaignsReducer from './campaigns';
import campaignsPublicReducer from './campaignsPublic';
import contactDetailsReducer from './contactDetails';
import contactsReducer from './contacts';
import customFieldsReducer from './customFields';
import globalReducer from './global';
import meetingsReducer from './meetings';
import messageReducer from './messages';
import officialsReducer from './officials';
import organizationsReducer from './organizations';
import signupsReducer from './signups';
import templatesReducer from './templates';

const rootReducer = combineReducers({
  auth: authReducer,
  broadcasts: broadcastsReducer,
  campaigns: campaignsReducer,
  campaignsPublic: campaignsPublicReducer,
  contactDetails: contactDetailsReducer,
  contacts: contactsReducer,
  customFields: customFieldsReducer,
  global: globalReducer,
  meetings: meetingsReducer,
  messages: messageReducer,
  officials: officialsReducer,
  organizations: organizationsReducer,
  signups: signupsReducer,
  templates: templatesReducer,
});

export default rootReducer;
