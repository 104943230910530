import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';
import { debounce } from 'lodash';

import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Slider,
  Switch,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  CenterFocusStrong as CenterFocusStrongIcon,
  Delete as DeleteIcon,
  SaveAlt as SaveAltIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
} from '@mui/icons-material';

import {
  fetchAllListContact,
  fetchContact,
} from 'store/actions/contacts';
import {
  fetchBoundary,
  getCurrentPosition,
} from 'store/actions/global';
import { globalSelectors } from 'store/selectors/global';
import { contactSelectors } from 'store/selectors/contacts';

import {
  addBoundaryToMap,
  addFullScreenMapButton,
  getLocations,
  getPointsGeojson,
  saveFile,
} from 'utils/helpers';

import {
  clusteredPointsStyle,
  clusteredPointsTitleStyle,
  mapSettings,
  unclusteredPointStyle,
} from 'utils/constants';

import { fitMapBoundsToPoints } from 'utils/mapping';

import { Backdrop } from 'common/components';
import MapBoxContainer from 'common/containers/MapBox/MapBoxContainer';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import AddBoundaries from '../components/AddBoundaries';
import ContactDialog from '../components/ContactDialog';

const { REACT_APP_MAPBOX_API_KEY } = process.env;

mapboxgl.accessToken = REACT_APP_MAPBOX_API_KEY;

const MapPage = styled(Grid)`
  display: flex;
  width: 100%;
  height: 100%;
`;

const BoundariesHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const HeatmapSlidersWrapper = styled.span`
  display: ${props => props.display};
`;

const LocationsActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Sidebar = styled.div`
  left: 0;
  overflow-y: auto;
  width: 25%;
  height: 100%;
  padding: 20px;
`;

const StyledControlLabel = styled(FormControlLabel)`
  display: ${props => props.display};
`;

const StyledListItem = styled(ListItem)`
  padding-left: 3px;
`;

const StyledTypography = styled(Typography)`
  padding-top: 20px;
`;

const MapListContainer = () => {
  const dispatch = useDispatch();
  const { listUuid } = useParams();

  const [
    boundariesList,
    setBoundariesList,
  ] = useState([]);
  const [
    isBoundariesDialogOpen,
    setIsBoundariesDialogOpen,
  ] = useState(false);
  const [
    mapBoundaries,
    setMapBoundaries,
  ] = useState([]);
  const [
    markers,
    setMarkers,
  ] = useState([]);
  const [
    opacity,
    setOpacity,
  ] = useState(0.75);
  const [
    spread,
    setSpread,
  ] = useState(70);
  const [
    isContactsFetched,
    setIsContactsFetched,
  ] = useState(false);
  const [
    isBoundariesLoading,
    setIsBoundariesLoading,
  ] = useState(false);
  const [
    switchers,
    setSwitchers,
  ] = useState({
    clustering: true,
    heatmap: false,
    markers: true,
  });
  const [
    isContactDialogOpen,
    setIsContactDialogOpen,
  ] = useState(false);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const isLoaded = useRef(false);

  const mapCenter = useSelector(globalSelectors.getMapCenter);
  const isGeolocated = useSelector(globalSelectors.getIsGeolocated);

  const {
    contactDetails,
    contacts,
    errors,
    isContactFetching,
  } = useSelector(state => ({
    contactDetails: contactSelectors.getContactDetails(state),
    contacts: (contactSelectors.getListContacts(state)[listUuid] || [])
      .filter(contact => !!contact.locations?.length && contact.locations[0].point),
    errors: contactSelectors.getErrors(state),
    isContactFetching: contactSelectors.isContactFetching(state),
  }));

  useEffect(() => {
    if (mapContainer?.current && !map.current && isContactsFetched) {
      map.current = new mapboxgl.Map({
        center: [
          mapCenter.lng,
          mapCenter.lat,
        ],
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        zoom: mapSettings.zoom,
      });
    }
  }, [
    map,
    mapContainer,
    isContactsFetched,
  ]);

  useEffect(() => {
    if (contacts.length === 0) {
      dispatch(fetchAllListContact(listUuid))
        .then(() => setIsContactsFetched(true));
    } else {
      setIsContactsFetched(true);
    }
  }, []);

  const contactLength = contacts.length;

  useEffect(() => {
    if (map.current && contacts.length && !isLoaded.current && isContactsFetched) {
      isLoaded.current = true;

      if (!isGeolocated) {
        dispatch(getCurrentPosition(map.current));
      }

      const features = getLocations(contacts);

      setMarkers(features);
      const geojson = getPointsGeojson(features);

      map.current.on('load', () => {
        map.current.addSource('locations', geojson);

        map.current.addLayer({
          ...unclusteredPointStyle,
        });

        map.current.addLayer({
          ...clusteredPointsStyle,
        });

        map.current.addLayer({
          ...clusteredPointsTitleStyle,
        });

        fitMapBoundsToPoints(map.current, geojson.data.features);

        map.current.on('click', 'clustered-locations', e => {
          const clusters = map.current.queryRenderedFeatures(e.point, {
            layers: ['clustered-locations'],
          });

          const clusterId = clusters[0].properties.cluster_id;

          map.current.getSource('locations').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            map.current.easeTo({
              center: clusters[0].geometry.coordinates,
              zoom,
            });
          }
          );
        });

        map.current.on('click', 'unclustered-locations', e => {
          dispatch(fetchContact(listUuid, e.features[0].properties?.contactUuid));
          setIsContactDialogOpen(true);
        });

        map.current.on('mouseenter', 'unclustered-locations', () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });
        map.current.on('mouseleave', 'unclustered-locations', () => {
          map.current.getCanvas().style.cursor = '';
        });
        map.current.on('mouseenter', 'clustered-locations', () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });
        map.current.on('mouseleave', 'clustered-locations', () => {
          map.current.getCanvas().style.cursor = '';
        });

        addFullScreenMapButton(map.current);
      });
    }
  }, [
   contactLength,
   map.current,
   isContactsFetched,
  ]);

  const showMarkers = () => {
    map.current.setLayoutProperty('clustered-locations', 'visibility', 'visible');
    map.current.setLayoutProperty('cluster-count', 'visibility', 'visible');
    map.current.setLayoutProperty('unclustered-locations', 'visibility', 'visible');
  };
  const hideMarkers = () => {
    map.current.setLayoutProperty('clustered-locations', 'visibility', 'none');
    map.current.setLayoutProperty('cluster-count', 'visibility', 'none');
    map.current.setLayoutProperty('unclustered-locations', 'visibility', 'none');
  };

  const setCluster = isVisable => {
    const style = map.current.getStyle();

    style.sources.locations.cluster = isVisable;

    map.current.setStyle(style);
  };

  const handleFitBounds = () => {
    const bounds = new mapboxgl.LngLatBounds();

    markers.forEach(feature => {
      bounds.extend(feature.geometry.coordinates);
    });

    map.current.fitBounds(bounds, {
      padding: {
        bottom: 25,
        left: 15,
        right: 5,
        top: 10,
      },
    });
  };

  const handleZoomIn = () => map.current.setZoom(map.current.getZoom() + 1);
  const handleZoomOut = () => map.current.setZoom(map.current.getZoom() - 1);

  const toggleHeatmap = isHeatmapEnabled => {
    if (map.current.getLayer('heatmap-heat')) {
      if (isHeatmapEnabled) {
        map.current.setLayoutProperty('heatmap-heat', 'visibility', 'visible');
      } else {
        map.current.setLayoutProperty('heatmap-heat', 'visibility', 'none');
      }
    } else {
      map.current.addLayer(
        {
          id: 'heatmap-heat',
          paint: {
            // increase intensity as zoom level increases
            'heatmap-intensity': {
              stops: [
                [
                  11,
                  1,
                ],
                [
                  15,
                  3,
                ],
              ],
            },
            'heatmap-opacity': opacity,
            'heatmap-radius': spread,
            // increase weight as diameter breast height increases
            'heatmap-weight': {
              property: 'dbh',
              stops: [
                [
                  1,
                  0,
                ],
                [
                  62,
                  1,
                ],
              ],
              type: 'exponential',
            },
          },
          source: 'locations',
          type: 'heatmap',
        },
        'waterway-label'
        );
    }
  };

  const handleSpreadChange = (_, newValue) => {
    setSpread(newValue);
    map.current.setPaintProperty('heatmap-heat', 'heatmap-radius', newValue);
  };
  const handleOpacityChangeDebounced = useCallback(
    debounce(newValue => {
      map.current.setPaintProperty('heatmap-heat', 'heatmap-opacity', newValue);
    }, 50),
    []
  );
  const handleOpacityChange = (_, newValue) => {
    setOpacity(newValue);
    handleOpacityChangeDebounced(newValue);
  };

  const handleSwitcherChange = event => {
    setSwitchers({
      ...switchers,
      [event.target.name]: event.target.checked,
    });

    if (event.target.name === 'clustering') {
      return setCluster(event.target.checked);
    }

    if (event.target.name === 'heatmap') {
      return toggleHeatmap(event.target.checked);
    }

    return event.target.checked ?
      showMarkers() :
      hideMarkers();
  };

  const handleAddBoundary = async () => {
    setIsBoundariesLoading(true);

    Promise.all(boundariesList.map(({ geojsonUrl }) => dispatch(fetchBoundary(geojsonUrl))))
      .then(data => {
        data.forEach(feature => {
          addBoundaryToMap(map.current, feature);

          return setMapBoundaries(prevMarker => [
            ...prevMarker,
            feature,
          ]);
        });

        const bounds = new mapboxgl.LngLatBounds();

        data.forEach(feature => {
          feature.geometry.coordinates[0][0].forEach(coordinate => {
            bounds.extend(coordinate);
          });
        });

        map.current.fitBounds(bounds, {
          padding: {
            bottom: 25,
            left: 15,
            right: 5,
            top: 10,
          },
        });
        setIsBoundariesDialogOpen(false);
        setIsBoundariesLoading(false);
      });
  };

  const handleSaveImage = () => {
    new Promise((resolve => {
      map.current.once('render', () => {
        resolve(map.current.getCanvas().toDataURL());
      });
      /* trigger render */
      map.current.setBearing(map.current.getBearing());
    })).then(data => {
      saveFile(data, 'Muster-map.png');
    });
  };

  const handleRemoveAllBoundaries = () => {
    map.current.getStyle().layers.forEach(layer => {
      if (layer.id?.startsWith('boundary-')) {
        map.current.removeLayer(layer.id);
      }
    });

    Object.keys(map.current.getStyle().sources).forEach(propertyName => {
      if (propertyName.startsWith('boundary-')) {
        map.current.removeSource(propertyName);
      }
    });

    setMapBoundaries([]);
  };

  return (
    <MapPage container>
      <Sidebar>
        <Typography variant="h5">
          {markers.length}
          &nbsp;
          Geotargeted Locations
        </Typography>
        <LocationsActions>
          <Button
            disabled={!map?.current}
            endIcon={<ZoomInIcon />}
            onClick={handleZoomIn}
            size="small"
          >
            Zoom In
          </Button>
          <Button
            disabled={!map?.current}
            endIcon={<ZoomOutIcon />}
            onClick={handleZoomOut}
            size="small"
          >
            Zoom Out
          </Button>
          <Button
            disabled={!map?.current}
            endIcon={<CenterFocusStrongIcon />}
            onClick={handleFitBounds}
            size="small"
          >
            Show All
          </Button>
          <Button
            disabled={!map?.current}
            endIcon={<SaveAltIcon />}
            onClick={handleSaveImage}
            size="small"
          >
            Take Screenshot
          </Button>
        </LocationsActions>
        <StyledTypography variant="h5">Visualize Data</StyledTypography>
        <FormGroup>
          <FormControlLabel
            control={(
              <Switch
                checked={switchers.markers}
                color="primary"
                disabled={contacts.length === 0 || !map?.current}
                name="markers"
                onChange={handleSwitcherChange}
              />
            )}
            label="Markers"
          />
          <StyledControlLabel
            control={(
              <Switch
                checked={switchers.clustering}
                color="primary"
                disabled={contacts.length === 0 || !map?.current}
                name="clustering"
                onChange={handleSwitcherChange}
              />
            )}
            display={switchers.markers ? 'block' : 'none'}
            label="Cluster Markers"
          />
          <FormControlLabel
            control={(
              <Switch
                checked={switchers.heatmap}
                color="primary"
                disabled={contacts.length === 0 || !map?.current}
                name="heatmap"
                onChange={handleSwitcherChange}
              />
            )}
            label="Heatmap"
          />
        </FormGroup>
        <HeatmapSlidersWrapper display={switchers.heatmap ? 'block' : 'none'}>
          <Typography
            gutterBottom
            id="heatmap-spread-slider"
          >
            Spread
          </Typography>
          <Slider
            aria-labelledby="heatmap-spread-slider"
            min={1}
            onChange={handleSpreadChange}
            value={spread}
          />
          <Typography
            gutterBottom
            id="opacity-spread-slider"
          >
            Opacity
          </Typography>
          <Slider
            aria-labelledby="opacity-spread-slider"
            max={1}
            min={0}
            onChange={handleOpacityChange}
            step={0.01}
            value={opacity}
          />
        </HeatmapSlidersWrapper>
        <StyledTypography variant="h5">Boundaries</StyledTypography>
        <BoundariesHeader>
          <Button
            endIcon={<AddIcon />}
            onClick={() => setIsBoundariesDialogOpen(true)}
            size="small"
          >
            Add
          </Button>
          <Button
            disabled={mapBoundaries.length === 0}
            endIcon={<DeleteIcon />}
            onClick={handleRemoveAllBoundaries}
            size="small"
          >
            Delete all
          </Button>
        </BoundariesHeader>
        {isBoundariesDialogOpen && (
          <AddBoundaries
            boundariesList={boundariesList}
            handleAddBoundary={handleAddBoundary}
            handleClose={() => setIsBoundariesDialogOpen(false)}
            isBoundariesLoading={isBoundariesLoading}
            isDialogOpen={isBoundariesDialogOpen}
            setBoundariesList={setBoundariesList}
          />
        )}
        <List>
          {mapBoundaries.map(boundary => (
            <StyledListItem key={boundary.properties.uuid}>
              <ListItemText primary={boundary.properties.name} />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="delete"
                  edge="end"
                  onClick={() => {
                    map.current.removeLayer(`boundary-${boundary.properties.uuid}-fill`);
                    map.current.removeLayer(`boundary-${boundary.properties.uuid}-outline`);
                    map.current.removeSource(`boundary-${boundary.properties.uuid}`);
                    setMapBoundaries(
                      mapBoundaries.filter(
                        mapBoundary => mapBoundary.properties.uuid !== boundary.properties.uuid
                      )
                    );
                  }}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </StyledListItem>
          ))}
        </List>
      </Sidebar>
      <MapBoxContainer
        isFullPage
        mapContainer={mapContainer}
      />
      <ContactDialog
        isDialogOpen={isContactDialogOpen}
        handleDialogClose={() => setIsContactDialogOpen(false)}
        contactDetails={contactDetails}
        isFetching={isContactFetching}
        isError={!!errors}
      />
      <Backdrop isOpen={!isLoaded} />
    </MapPage>
  );
};

export default MapListContainer;
