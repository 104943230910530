import { useSelector } from 'react-redux';
import {
  Link as RouterLink,
  useHistory,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { MenuItem } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';

import { organizationSelectors } from 'store/selectors/organizations';

import {
  DropdownButton,
  NavigationDropdown,
} from '../NavigationDropdowns.styles';

const SettingsDropdown = ({ organizationUuid }) => {
  const { location } = useHistory();

  const {
    organization: {
      currentUser,
    },
  } = useSelector(state => ({
    organization: organizationSelectors.getOrganization(state),
  }));

  return (
    <NavigationDropdown
      name="Settings"
      isactualroute={location?.pathname?.includes('settings')}
      disabled={!currentUser?.isAdmin}
      color="inherit"
      startIcon={null}
      endIcon={<KeyboardArrowDownIcon />}
      variant="text"
    >
      {handleSettingsClose => (
        <>
          <DropdownButton
            component={RouterLink}
            to={`/${organizationUuid}/settings?tab=account-information`}
          >
            <MenuItem onClick={handleSettingsClose}>Account</MenuItem>
          </DropdownButton>
          <DropdownButton
            component={RouterLink}
            to={`/${organizationUuid}/settings?tab=branding`}
          >
            <MenuItem onClick={handleSettingsClose}>Branding</MenuItem>
          </DropdownButton>
          <DropdownButton
            component={RouterLink}
            to={`/${organizationUuid}/settings?tab=team`}
          >
            <MenuItem onClick={handleSettingsClose}>Team</MenuItem>
          </DropdownButton>
          <DropdownButton
            component={RouterLink}
            to={`/${organizationUuid}/settings?tab=integrations`}
          >
            <MenuItem onClick={handleSettingsClose}>Integrations</MenuItem>
          </DropdownButton>
        </>
      )}
    </NavigationDropdown>
  );
};

SettingsDropdown.propTypes = {
  organizationUuid: PropTypes.string.isRequired,
};

export default SettingsDropdown;
