import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Autocomplete,
  TextField,
  Typography,
} from '@mui/material';

import {
  OptionImage,
  StyledFieldButton,
} from 'globalStyles';

const ButtonsWrapper = styled.div`
  display: flex;
`;

const StyledAutocomplete = styled(Autocomplete)`
  width: 300px;
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 10px;
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;

const StepTwo = ({
  filterType,
  handlePickField,
  lists,
  setLists,
  staticLists,
}) => {
  const getOptionLabel = ({
    count,
    name,
  }) => `${name} (${count} contacts)`;

  const renderSelectedList = () => lists.map(({
    count = 0,
    name,
    uuid,
  }) => <li key={uuid}>{`${name}: ${count} contacts`}</li>);

  return (
    <>
      <StyledTypography variant="h5">Pick Lists</StyledTypography>
      <StyledAutocomplete
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => (value ? option.uuid === value.uuid : true)}
        multiple
        onChange={(_, pickedLists) => setLists(pickedLists)}
        options={staticLists}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label="Pick some lists to start with"
          />
        )}
        value={lists}
      />
      {!!lists.length && (
        <Wrapper>
          <Typography variant="h5">Selected Lists</Typography>
          <ul>{renderSelectedList()}</ul>
        </Wrapper>
      )}
      {lists.length > 1 && (
        <Wrapper>
          <StyledTypography variant="h5">Select Option</StyledTypography>
          <ButtonsWrapper>
            <StyledFieldButton
              checked={filterType === 'OR'}
              data-field="OR"
              onClick={handlePickField}
              type="button"
            >
              <OptionImage
                alt="All contacts"
                src="/all_contacts_icon.svg"
              />
              All Contacts
            </StyledFieldButton>
            <StyledFieldButton
              checked={filterType === 'AND'}
              data-field="AND"
              onClick={handlePickField}
              type="button"
            >
              <OptionImage
                alt="Only overlapped contacts"
                src="/overlapped_contacts_icon.svg"
              />
              Overlapped Contacts
            </StyledFieldButton>
          </ButtonsWrapper>
        </Wrapper>
      )}
    </>
  );
};

StepTwo.propTypes = {
  filterType: PropTypes.string.isRequired,
  handlePickField: PropTypes.func.isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setLists: PropTypes.func.isRequired,
  staticLists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default StepTwo;
