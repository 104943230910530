import request from 'utils/request';
import { mapSettings } from 'utils/constants';

const PREFIX = '[GLOBAL]';

export const SET_ORGANIZATION = `${PREFIX} SET_ORGANIZATION`;

export const setOrganization = payload => ({
  payload,
  type: SET_ORGANIZATION,
});

export const FETCH_BOUNDARIES_TOP_REQUEST = `${PREFIX} FETCH_BOUNDARIES_TOP_REQUEST`;
export const FETCH_BOUNDARIES_TOP_SUCCESS = `${PREFIX} FETCH_BOUNDARIES_TOP_SUCCESS`;
export const FETCH_BOUNDARIES_TOP_FAILURE = `${PREFIX} FETCH_BOUNDARIES_TOP_FAILURE`;

const fetchBoundariesTopRequest = () => ({
  type: FETCH_BOUNDARIES_TOP_REQUEST,
});

const fetchBoundariesTopSuccess = payload => ({
  payload,
  type: FETCH_BOUNDARIES_TOP_SUCCESS,
});

const fetchBoundariesTopFailure = payload => ({
  payload,
  type: FETCH_BOUNDARIES_TOP_FAILURE,
});

export const fetchBoundariesTop = () => dispatch => {
  dispatch(fetchBoundariesTopRequest());

  return request
    .get('/v1/boundaries/tree_top/?depth=1')
    .then(({ data }) => {
      dispatch(fetchBoundariesTopSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchBoundariesTopFailure(data));

      throw data;
    });
};

export const FETCH_BOUNDARIES_TREE_REQUEST = `${PREFIX} FETCH_BOUNDARIES_TREE_REQUEST`;
export const FETCH_BOUNDARIES_TREE_SUCCESS = `${PREFIX} FETCH_BOUNDARIES_TREE_SUCCESS`;
export const FETCH_BOUNDARIES_TREE_FAILURE = `${PREFIX} FETCH_BOUNDARIES_TREE_FAILURE`;

const fetchBoundariesTreeRequest = () => ({
  type: FETCH_BOUNDARIES_TREE_REQUEST,
});

const fetchBoundariesTreeSuccess = payload => ({
  payload,
  type: FETCH_BOUNDARIES_TREE_SUCCESS,
});

const fetchBoundariesTeeFailure = payload => ({
  payload,
  type: FETCH_BOUNDARIES_TREE_FAILURE,
});

export const fetchBoundariesTree = uuid => dispatch => {
  dispatch(fetchBoundariesTreeRequest());

  return request
    .get(`/v1/boundaries/${uuid}/descendants/`)
    .then(({ data }) => {
      dispatch(fetchBoundariesTreeSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchBoundariesTeeFailure(data));

      throw data;
    });
};

export const FETCH_BOUNDARY_REQUEST = `${PREFIX} FETCH_BOUNDARY_REQUEST`;
export const FETCH_BOUNDARY_SUCCESS = `${PREFIX} FETCH_BOUNDARY_SUCCESS`;
export const FETCH_BOUNDARY_FAILURE = `${PREFIX} FETCH_BOUNDARY_FAILURE`;

const fetchBoundaryRequest = () => ({
  type: FETCH_BOUNDARY_REQUEST,
});

const fetchBoundarySuccess = payload => ({
  payload,
  type: FETCH_BOUNDARY_SUCCESS,
});

const fetchBoundaryFailure = payload => ({
  payload,
  type: FETCH_BOUNDARY_FAILURE,
});

export const fetchBoundary = geojsonUrl => dispatch => {
  dispatch(fetchBoundaryRequest());

  return request
    .get('', { baseURL: geojsonUrl })
    .then(({ data }) => {
      dispatch(fetchBoundarySuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchBoundaryFailure(data));

      throw data;
    });
};

export const GET_CURRENT_POSITION_REQUEST = `${PREFIX} GET_CURRENT_POSITION_REQUEST`;
export const GET_CURRENT_POSITION_SUCCESS = `${PREFIX} GET_CURRENT_POSITION_SUCCESS`;
export const GET_CURRENT_POSITION_FAILURE = `${PREFIX} GET_CURRENT_POSITION_FAILURE`;

const getCurrentPositionRequest = () => ({
  type: GET_CURRENT_POSITION_REQUEST,
});

const getCurrentPositionSuccess = payload => ({
  payload,
  type: GET_CURRENT_POSITION_SUCCESS,
});

const getCurrentPositionFailure = payload => ({
  payload,
  type: GET_CURRENT_POSITION_FAILURE,
});

export const getCurrentPosition = map => dispatch => {
  dispatch(getCurrentPositionRequest());

  if (!navigator?.geolocation) {
    dispatch(getCurrentPositionFailure());
  }

  navigator.geolocation.getCurrentPosition(
    position => {
      dispatch(getCurrentPositionSuccess({
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
      }));

      if (map) {
        map.setCenter([
          position?.coords?.longitude,
          position?.coords?.latitude,
        ]);
      }
    },
    error => dispatch(getCurrentPositionFailure(error.message || error)),
    mapSettings.geolocationOptions
  );
};

export const SET_SNACKBAR_ERROR = `${PREFIX} SET_SNACKBAR_ERROR`;

export const setSnackbarError = payload => ({
  payload,
  type: SET_SNACKBAR_ERROR,
});

export const FETCH_BOUNDARY_DETAIL_REQUEST = `${PREFIX} FETCH_BOUNDARY_DETAIL_REQUEST`;
export const FETCH_BOUNDARY_DETAIL_SUCCESS = `${PREFIX} FETCH_BOUNDARY_DETAIL_SUCCESS`;
export const FETCH_BOUNDARY_DETAIL_FAILURE = `${PREFIX} FETCH_BOUNDARY_DETAIL_FAILURE`;

const fetchBoundaryDetailRequest = () => ({
  type: FETCH_BOUNDARY_DETAIL_REQUEST,
});

const fetchBoundaryDetailSuccess = payload => ({
  payload,
  type: FETCH_BOUNDARY_DETAIL_SUCCESS,
});

const fetchBoundaryDetailFailure = payload => ({
  payload,
  type: FETCH_BOUNDARY_DETAIL_FAILURE,
});

export const fetchBoundaryDetail = uuid => dispatch => {
  dispatch(fetchBoundaryDetailRequest());

  return request
    .get(`/v1/boundaries/${uuid}/`)
    .then(({ data }) => {
      dispatch(fetchBoundaryDetailSuccess(data));

      return data;
    })
    .catch(({ response: { data } = {} }) => {
      dispatch(fetchBoundaryDetailFailure(data));

      throw data;
    });
};

export const SET_SNACKBAR_SUCCESS = `${PREFIX} SET_SNACKBAR_SUCCESS`;

export const setSnackbarSuccess = payload => ({
  payload,
  type: SET_SNACKBAR_SUCCESS,
});

export const SET_SNACKBAR_WARNING = `${PREFIX} SET_SNACKBAR_WARNING`;

export const setSnackbarWarning = payload => ({
  payload,
  type: SET_SNACKBAR_WARNING,
});
