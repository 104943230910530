import PropTypes from 'prop-types';

import MaterialTable from '@material-table/core';
import { Paper } from '@mui/material';

import TableIcon from 'common/components/TableIcon';
import { colors } from 'utils/constants';
import { WarningCheckCircleOutlineIcon } from 'globalStyles';

const recipientsDetailsColumns = [
  {
    field: 'firstName',
    title: 'First name',
  },
  {
    field: 'lastName',
    title: 'Last name',
  },
  {
    field: 'email',
    title: 'Email',
  },
  {
    field: 'bounced',
    render: rowData => (
      <TableIcon
        boolean={!!rowData.bounced}
        negativeIcon={null}
        positiveIcon={<WarningCheckCircleOutlineIcon />}
      />
    ),
    title: 'Bounced',
  },
  {
    field: 'spam',
    render: rowData => (
      <TableIcon
        boolean={!!rowData.spam}
        negativeIcon={null}
        positiveIcon={<WarningCheckCircleOutlineIcon />}
      />
    ),
    title: 'Spam',
  },
  {
    field: 'opened',
    render: rowData => (
      <TableIcon
        boolean={!!rowData.opened}
        negativeIcon={null}
      />
    ),
    title: 'Opened',
  },
  {
    field: 'openedAt',
    render: rowData => (rowData.openedAt ? new Date(rowData.openedAt).toLocaleDateString('us-US') : ''),
    title: 'Opened At',
  },
  {
    field: 'clicks',
    title: 'Clicks',
  },
];

const textRecipientsDetailsColumns = [
  {
    field: 'firstName',
    title: 'First name',
  },
  {
    field: 'lastName',
    title: 'Last name',
  },
  {
    field: 'phoneNumber',
    title: 'Phone number',
  },
  {
    field: 'bounced',
    render: rowData => (
      <TableIcon
        boolean={!!rowData.bounced}
        negativeIcon={null}
        positiveIcon={<WarningCheckCircleOutlineIcon />}
      />
    ),
    title: 'Bounced',
  },
];

const RecipientsTable = ({
  isDataFetching,
  isTextRecipients,
  recipients,
}) => (
  <MaterialTable
    columns={isTextRecipients ? textRecipientsDetailsColumns : recipientsDetailsColumns}
    components={{
      Container: props => (
        <Paper
          {...props}
          elevation={0}
          square
          variant="outlined"
        />
      ),
    }}
    data={recipients}
    isLoading={isDataFetching}
    options={{
      actionsColumnIndex: -1,
      headerStyle: {
        backgroundColor: colors.tableHeaderGray,
        color: colors.white,
      },
      selection: false,
      showTitle: false,
    }}
  />
);

RecipientsTable.defaultProps = {
  isTextRecipients: false,
};

RecipientsTable.propTypes = {
  isDataFetching: PropTypes.bool.isRequired,
  isTextRecipients: PropTypes.bool,
  recipients: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  })).isRequired,
};

export default RecipientsTable;
