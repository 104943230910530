export const isEmail = value => (
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    undefined :
    'This is not a valid email'
);

export const required = value => (value ? undefined : 'This Field is Required');

export const minLengthValidator = (length, minLength, field = 'Field') => (
  length < minLength ? `${field} must be longer than ${minLength - 1} characters.` : ''
);

export const requiredLinkedin = value => {
  if (!value) {
    return undefined;
  }

  if (value.includes('in/') || value.includes('company/') || value.includes('school/')) {
    return undefined;
  }

  return 'This is not a valid address. A valid address must contain the text /in or /company, e.g. https://www.linkedin.com/company/mycompany/';
};
