import {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import {
  Add as AddIcon,
} from '@mui/icons-material';

import {
  fetchTargetGroup,
  fetchTargetGroups,
  patchTargetGroup,
  postTargetGroup,
} from 'store/actions/campaigns';
import { campaignsSelectors } from 'store/selectors/campaigns';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';

import TargetsSelection from 'common/containers/TargetsSelection';
import {
  Backdrop,
  CancelCreatorButton,
  CardField,
  Container,
  DescriptionBar,
} from 'common/components';
import { colors } from 'utils/constants';
import {
  scrollToTop,
  showFirstErrorMessage,
} from 'utils/helpers';

const CardFieldWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const StyledGrid = styled(Grid)`
  flex: 0 0 100%;
`;

const ButtonCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90px;
  margin-top: 50px;
  background-color: ${colors.white};
`;

const ButtonCreateWrapper = styled.div`
  position: relative;
  padding-left: 10px;
`;

const CreateTargetGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    organizationUuid,
    targetGroupUuid,
  } = useParams();

  const {
    isDataFetching,
    isPending,
  } = useSelector(state => ({
    isDataFetching: campaignsSelectors.isDataFetching(state),
    isPending: campaignsSelectors.isPending(state),
  }));

  const [
    targets,
    setTargets,
  ] = useState([]);
  const [
    targetListName,
    setTargetListName,
  ] = useState('');
  const [
    errorMessage,
    setErrorMessage,
  ] = useState(false);

  useEffect(() => {
    dispatch(fetchTargetGroups({ organization: organizationUuid }));

    if (targetGroupUuid) {
      dispatch(fetchTargetGroup(targetGroupUuid))
        .then(data => {
          setTargets(data.targets?.map(target => ({
            ...target,
            isSavedTarget: true,
            office: {
              state: target.state,
              title: target.office,
            },
          })));

          setTargetListName(data.name);
        });
    }
  }, [targetGroupUuid]);

  const handleSave = () => {
    const params = {
      name: targetListName,
      organization: organizationUuid,
      targets: targets.map(target => ({ term: target.term || target.uuid })),
    };

    dispatch(targetGroupUuid ? patchTargetGroup(targetGroupUuid, params) : postTargetGroup(params))
      .then(() => {
        dispatch(fetchTargetGroups({ organization: organizationUuid }));
        dispatch(setSnackbarSuccess({ message: 'Targets Group Saved' }));
        history.push(`/${organizationUuid}/target-groups`);
      })
      .catch(error => {
        if (error) {
          scrollToTop();
          setErrorMessage(error);
        }

        if (error && !error.name) {
          dispatch(setSnackbarError({
            message: showFirstErrorMessage(error),
          }));
        }
      });
  };

  return (
    <Container>
      <Grid container>
        <DescriptionBar
          name={targetGroupUuid ? 'Edit Target Group' : 'Create Target Group'}
          icon={AddIcon}
        >
          <CancelCreatorButton redirectUrl={
            targetGroupUuid ?
              `/${organizationUuid}/target-groups/${targetGroupUuid}` :
              `/${organizationUuid}/target-groups/`
            }
          />
        </DescriptionBar>
      </Grid>
      <Grid container>
        <CardFieldWrapper>
          <CardField
            flexwrap
            name="Target Group Name"
            required
          >
            <StyledGrid>
              <TextField
                fullWidth
                label="Target Group Name"
                onChange={event => {
                  setTargetListName(event.target.value);
                  setErrorMessage(null);
                }}
                error={!!errorMessage?.name && !!errorMessage?.name[0]}
                helperText={errorMessage?.name && errorMessage?.name[0]}
                required
                type="text"
                value={targetListName}
              />
            </StyledGrid>
          </CardField>
        </CardFieldWrapper>
        <CardFieldWrapper>
          <CardField
            name="Targets"
            flexwrap
            required
          >
            <TargetsSelection
              setTargets={setTargets}
              targets={targets}
              isCongressGroupSensitive={false}
              hasSaveTargetsButton={false}
              organizationUuid={organizationUuid}
            />
          </CardField>
        </CardFieldWrapper>
        <StepperWrapper>
          <Grid
            container
            direction="row"
            item
            justifyContent="flex-end"
          >
            <div>
              <ButtonCreateWrapper>
                <Button
                  color="secondary"
                  disabled={isPending || !targets.length}
                  onClick={handleSave}
                  variant="contained"
                  type="button"
                >
                  {`${targetGroupUuid ? 'Update' : 'Save'} Target Group`}
                </Button>
                {isPending && <ButtonCircularProgress size={24} />}
              </ButtonCreateWrapper>
            </div>
          </Grid>
        </StepperWrapper>
      </Grid>
      <Backdrop isOpen={isDataFetching} />
    </Container>
  );
};

export default CreateTargetGroup;
