import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import {
  CardField,
  EditorWysiwyg,
} from 'common/components';
import { isValidURL } from 'utils/helpers';

const CardFieldWrapper = styled.div`
  margin-top: 30px;
`;

const StyledTextField = styled(TextField)`
  margin-top: 20px;
`;

const DonateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepFour = ({
  editorState,
  handleEditorError,
  isDonateButton,
  isUrl,
  linkDonate,
  setEditorStateChange,
  setIsDonateButton,
  setIsUrl,
  setLinkDonate,
}) => {
  const handleBlur = url => {
    if (!isValidURL(url)) {
      setIsUrl(false);
    } else {
      setIsUrl(true);
    }
  };

  return (
    <>
      <CardFieldWrapper>
        <CardField
          flexwrap
          name="Thank You Message"
        >
          <EditorWysiwyg
            editorState={editorState}
            setEditorStateChange={setEditorStateChange}
            handleEditorError={handleEditorError}
          />
        </CardField>
      </CardFieldWrapper>
      <CardFieldWrapper>
        <CardField
          flexwrap
          name="Donate Button"
        >
          <DonateWrapper>
            <Typography variant="subtitle2">Do you want to add Donate button?</Typography>
            <RadioGroup row>
              <FormControlLabel
                checked={isDonateButton}
                control={<Radio />}
                onChange={() => setIsDonateButton(true)}
                label="Yes"
              />
              <FormControlLabel
                checked={!isDonateButton}
                control={<Radio />}
                onChange={() => setIsDonateButton(false)}
                label="No"
              />
            </RadioGroup>
            {isDonateButton && (
              <>
                <StyledTextField
                  fullWidth
                  label="Link for donate"
                  onChange={event => setLinkDonate(event.target.value)}
                  type="text"
                  value={linkDonate}
                  error={!isUrl}
                  onBlur={event => handleBlur(event.target.value)}
                  helperText={!isUrl && 'Incorrect url.'}
                />
                <Typography variant="subtitle2">Add Link for Donate Button</Typography>
              </>
            )}
          </DonateWrapper>
        </CardField>
      </CardFieldWrapper>
    </>
  );
};

StepFour.propTypes = {
  editorState: PropTypes.shape({
    getCurrentContent: PropTypes.func,
  }).isRequired,
  handleEditorError: PropTypes.func.isRequired,
  isDonateButton: PropTypes.bool.isRequired,
  isUrl: PropTypes.bool.isRequired,
  linkDonate: PropTypes.string.isRequired,
  setEditorStateChange: PropTypes.func.isRequired,
  setIsDonateButton: PropTypes.func.isRequired,
  setIsUrl: PropTypes.func.isRequired,
  setLinkDonate: PropTypes.func.isRequired,
};

export default StepFour;
