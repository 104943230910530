import PropTypes from 'prop-types';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import { useSelector } from 'react-redux';

import {
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { customFieldsSelectors } from 'store/selectors/customFields';

import {
  CircularProgress,
  Dialog,
} from 'common/components';

import { fieldTypes } from 'utils/constants';
import { showFirstErrorMessage } from 'utils/helpers';
import { ErrorTypography } from 'globalStyles';

const StyledCreatableSelect = styled(CreatableSelect)`
  width: 100%;
  margin: 10px 0;

  /* stylelint-disable-next-line selector-class-pattern */
  .react-select__menu {
    position: static;
  }
`;

const CustomTextField = styled(TextField)`
  width: 100%;
  margin: 10px 0;
`;

const Label = styled(Typography)`
  margin-top: 10px;
`;

const { REACT_APP_FEATURE_FLAG_MULTIPLE_CHOICE } = process.env;

const CustomFieldDialog = ({
  editedCustomField,
  handleDialogClose,
  handleUpdateCustomField,
  isPendingCustomField,
  setEditedCustomField,
}) => {
  const {
    customFieldError,
  } = useSelector(state => ({
    customFieldError: customFieldsSelectors.customFieldError(state),
  }));

  const {
    isModalOpened,
    fieldType,
    uuid,
  } = editedCustomField;

  return (
    <Dialog
      handleConfirm={handleUpdateCustomField}
      handleDialogClose={handleDialogClose}
      disabledButton={isPendingCustomField || !fieldType || !editedCustomField?.label}
      isDialogOpen={isModalOpened}
      title={`${uuid ? 'Edit' : 'Add'} Custom Field`}
    >
      {isModalOpened ? (
        <>
          <CustomTextField
            label="Custom Field Type"
            fullWidth
            onChange={event => setEditedCustomField(prevValue => ({
              ...prevValue,
              fieldType: event.target.value,
            }))}
            select
            value={fieldType}
          >
            <MenuItem value="text_field">
              Text Entry
            </MenuItem>
            <MenuItem value="single_choice_field">
              Choice
            </MenuItem>
            {REACT_APP_FEATURE_FLAG_MULTIPLE_CHOICE && (
              <MenuItem value="multiple_choice_field">
                Multiple Choice
              </MenuItem>
            )}
          </CustomTextField>
          <CustomTextField
            fullWidth
            placeholder="Rename Field Label"
            onChange={event => setEditedCustomField(prevValue => ({
              ...prevValue,
              label: event.target.value,
            }))}
            label="Field Label"
            type="text"
            required
            value={editedCustomField?.label}
          />
          <CustomTextField
            fullWidth
            placeholder="Field Description"
            label="Field Description"
            onChange={event => setEditedCustomField(prevValue => ({
              ...prevValue,
              description: event.target.value,
            }))}
            type="text"
            value={editedCustomField?.description}
          />
          {fieldType === fieldTypes.textField ? (
            <CustomTextField
              fullWidth
              placeholder="Field Placeholder"
              label="Field Placeholder"
              onChange={event => setEditedCustomField(prevValue => ({
                ...prevValue,
                placeholder: event.target.value,
              }))}
              type="text"
              value={editedCustomField?.placeholder}
            />
          ) : (
            <>
              <Label>Options</Label>
              <StyledCreatableSelect
                classNamePrefix="react-select"
                defaultValue={editedCustomField?.options}
                isMulti
                onChange={options => setEditedCustomField(prevValue => ({
                  ...prevValue,
                  options,
                }))}
              />
            </>
          )}
          {customFieldError && (
            <ErrorTypography>{showFirstErrorMessage(customFieldError)}</ErrorTypography>
          )}
        </>
      ) : (
        <CircularProgress />
      )}
    </Dialog>
  );
};

CustomFieldDialog.propTypes = {
  editedCustomField: PropTypes.shape({
    description: PropTypes.string,
    fieldType: PropTypes.oneOf([
      'text_field',
      'single_choice_field',
      'multiple_choice_field',
      '',
    ]),
    isModalOpened: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })),
    placeholder: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  handleUpdateCustomField: PropTypes.func.isRequired,
  isPendingCustomField: PropTypes.bool.isRequired,
  setEditedCustomField: PropTypes.func.isRequired,
};

export default CustomFieldDialog;
