import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import {
  Cancel as CancelIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  Telegram as TelegramIcon,
} from '@mui/icons-material';

import {
  deleteBroadcast,
  fetchBroadcast,
  fetchBroadcastLinks,
  fetchBroadcastRecipients,
  stopBroadcast,
} from 'store/actions/broadcasts';
import {
  setSnackbarError,
  setSnackbarSuccess,
  setSnackbarWarning,
} from 'store/actions/global';
import { broadcastsSelectors } from 'store/selectors/broadcasts';

import {
  Backdrop,
  BreadcrumbsNavigation,
  ButtonMenu,
  Container,
  DescriptionBar,
  DescriptionBarInfo,
  LinksTable,
  RecipientsTable,
  StatisticCard,
  TabPanel,
  Tabs,
} from 'common/components';

import {
  cancellationStatuses,
  statuses,
} from 'utils/constants';
import {
  DetailsWrapper,
  DisabledListItemText,
  StatisticsWrapper,
} from 'globalStyles';

const ActionButtonsWrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const StyledPaper = styled(Paper)`
  border-bottom: 0;
`;

const BroadcastTableWrapper = styled.div`
  margin: 64px 0 82px;
`;

const BroadcastDetails = () => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const history = useHistory();
  const {
    broadcastUuid,
    organizationUuid,
  } = useParams();
  const { draft } = statuses;

  const {
    broadcastDetails,
    broadcastLinks,
    broadcastRecipients,
    isBroadcastFetching,
    isBroadcastLinksFetching,
    isBroadcastRecipientsFetching,
  } = useSelector(state => ({
    broadcastDetails: broadcastsSelectors.getBroadcastDetails(state),
    broadcastLinks: broadcastsSelectors.getBroadcastLinks(state),
    broadcastRecipients: broadcastsSelectors.getBroadcastRecipients(state),
    isBroadcastFetching: broadcastsSelectors.isBroadcastFetching(state),
    isBroadcastLinksFetching: broadcastsSelectors.isBroadcastLinksFetching(state),
    isBroadcastRecipientsFetching: broadcastsSelectors.isBroadcastRecipientsFetching(state),
  }));

  useEffect(() => {
    dispatch(fetchBroadcast(broadcastUuid));
    dispatch(fetchBroadcastLinks(broadcastUuid));
  }, []);

  const [
    tabIndex,
    setTabIndex,
  ] = useState('links');

  const {
    contactLists,
    createdBy,
    isCancellable,
    name,
    sendAt,
    message,
    subject,
    status,
    totalBouncedCount,
    totalClickedCount,
    totalRecipientsCount,
    totalOpenedCount,
    totalUnsubscribedCount,
  } = broadcastDetails;

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/broadcasts`,
      name: 'Broadcasts',
    },
    {
      link: '',
      name,
    },
  ];

  const { probablyCancelled } = cancellationStatuses;

  const firstFetch = useRef({
    recipients: false,
  });

  const handleChangeTablesTab = newValue => {
    setTabIndex(newValue);

    if (newValue === 'recipients' && !firstFetch.current.recipients) {
      dispatch(fetchBroadcastRecipients(broadcastUuid))
        .then(() => { firstFetch.current.recipients = true; });
    }
  };

  const handleDeleteBroadcast = () => {
    confirm({
      confirmationText: 'Delete',
      description: 'Deleting this broadcast will also remove any related recipient analytics.',
      title: 'Delete the Broadcast?',
    })
      .then(() => dispatch(deleteBroadcast(broadcastUuid)))
      .then(() => history.push(`/${organizationUuid}/broadcasts`));
  };

  const handleStopBroadcast = () => {
    dispatch(stopBroadcast(broadcastUuid))
      .then(({
        message: stopedBroadcastMessage,
        status: stopedBroadcastStatus,
      }) => {
        if (stopedBroadcastStatus === probablyCancelled) {
          dispatch(setSnackbarWarning({ message: stopedBroadcastMessage }));
        } else {
          dispatch(setSnackbarSuccess({ message: stopedBroadcastMessage }));
        }
        dispatch(fetchBroadcast(broadcastUuid));
      })
      .catch(({ message: errorMessage }) => dispatch(setSnackbarError({ message: errorMessage })));
  };

  const handleConfirmStopBroadcast = () => {
    confirm({
      cancellationText: 'No',
      confirmationText: 'Yes, Cancel',
      description: '',
      title: 'Cancel send the Broadcast?',
    })
      .then(() => handleStopBroadcast(broadcastUuid));
  };

  const handleEditBroadcast = () => {
    history.push(`/${organizationUuid}/${broadcastUuid}/create-email-broadcast`);
  };

  const handleDuplicateBroadcast = () => {
    history.push(`/${organizationUuid}/create-email-broadcast/${broadcastUuid}`);
  };

  const broadcastTabs = [
    {
      label: 'Links',
      value: 'links',
    },
    {
      label: 'Recipients',
      value: 'recipients',
    },
  ];

  const DescriptionInfo = (
    <DescriptionBarInfo
      contactLists={contactLists}
      createdBy={createdBy}
      isButtonVisible
      message={message}
      subject={subject}
      sendAt={sendAt}
    />
  );

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container>
        <Grid container>
          <DescriptionBar
            backButtonLink={`/${organizationUuid}/broadcasts`}
            descriptionBarInfo={DescriptionInfo}
            hasBackButton
            icon={TelegramIcon}
            isHighBar
            name={name}
          >
            <ActionButtonsWrapper>
              {isCancellable && (
                <StyledButton
                  startIcon={<CancelIcon />}
                  disabled={!isCancellable}
                  onClick={handleConfirmStopBroadcast}
                  variant="outlined"
                >
                  Cancel send
                </StyledButton>
              )}
              <ButtonMenu>
                <MenuItem
                  onClick={handleEditBroadcast}
                  disabled={status !== draft}
                >
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <DisabledListItemText
                    isdisabled={status !== draft ? 1 : 0}
                    primary="Edit Broadcast"
                  />
                </MenuItem>
                <MenuItem onClick={handleDuplicateBroadcast}>
                  <ListItemIcon>
                    <FileCopyOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Duplicate" />
                </MenuItem>
                <MenuItem onClick={handleDeleteBroadcast}>
                  <ListItemIcon>
                    <DeleteOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delete" />
                </MenuItem>
              </ButtonMenu>
            </ActionButtonsWrapper>
          </DescriptionBar>
        </Grid>
        <DetailsWrapper>
          <StatisticsWrapper>
            <StatisticCard
              count={totalRecipientsCount}
              text="Recipients"
            />
            <StatisticCard
              count={totalOpenedCount}
              hasPercentageBar
              maxCount={totalRecipientsCount}
              text="Opened"
            />
            <StatisticCard
              count={totalClickedCount}
              hasPercentageBar
              maxCount={totalRecipientsCount}
              text="Clicked"
            />
            <StatisticCard
              count={totalBouncedCount}
              hasPercentageBar
              maxCount={totalRecipientsCount}
              text="Bounced"
            />
            <StatisticCard
              count={totalUnsubscribedCount}
              hasPercentageBar
              maxCount={totalRecipientsCount}
              text="Unsubscribed"
            />
          </StatisticsWrapper>
        </DetailsWrapper>
        <BroadcastTableWrapper>
          <StyledPaper
            square
            variant="outlined"
          >
            <Tabs
              handleSetTab={handleChangeTablesTab}
              tabs={broadcastTabs}
              tabIndex={tabIndex}
            />
          </StyledPaper>
          <TabPanel
            index={broadcastTabs[0].value}
            value={tabIndex}
          >
            <LinksTable
              links={broadcastLinks}
              isDataFetching={isBroadcastLinksFetching}
            />
          </TabPanel>
          <TabPanel
            index={broadcastTabs[1].value}
            value={tabIndex}
          >
            <RecipientsTable
              recipients={broadcastRecipients}
              isDataFetching={isBroadcastRecipientsFetching}
            />
          </TabPanel>
        </BroadcastTableWrapper>
        <Backdrop isOpen={isBroadcastFetching} />
      </Container>
    </>
  );
};

export default BroadcastDetails;
