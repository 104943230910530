import {
  DELETE_CUSTOM_FIELD_FAILURE,
  DELETE_CUSTOM_FIELD_REQUEST,
  DELETE_CUSTOM_FIELD_SUCCESS,
  FETCH_CUSTOM_FIELDS_FAILURE,
  FETCH_CUSTOM_FIELDS_REQUEST,
  FETCH_CUSTOM_FIELDS_SUCCESS,
  PATCH_CUSTOM_FIELD_FAILURE,
  PATCH_CUSTOM_FIELD_REQUEST,
  PATCH_CUSTOM_FIELD_SUCCESS,
  POST_CUSTOM_FIELD_FAILURE,
  POST_CUSTOM_FIELD_REQUEST,
  POST_CUSTOM_FIELD_SUCCESS,
} from '../actions/customFields';

const initialState = {
  list: {
    errors: null,
    isFetching: false,
    results: [],
  },
  single: {
    data: null,
    errors: null,
    isPending: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PATCH_CUSTOM_FIELD_REQUEST:
    case POST_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          isPending: true,
        },
      };
    case POST_CUSTOM_FIELD_SUCCESS:
    case PATCH_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        single: {
          ...state.single,
          data: action.payload,
          errors: null,
          isPending: false,
        },
      };
    case PATCH_CUSTOM_FIELD_FAILURE:
    case POST_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        single: {
          ...state.single,
          data: null,
          errors: action.payload,
          isPending: false,
        },
      };
    case FETCH_CUSTOM_FIELDS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case FETCH_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          errors: null,
          isFetching: false,
          results: action.payload,
        },
      };
    case FETCH_CUSTOM_FIELDS_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          errors: action.payload,
          isFetching: false,
          results: [],
        },
      };
    case DELETE_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case DELETE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          results: state.list.results.filter(result => result.uuid !== action.payload),
        },
      };
    case DELETE_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};
