import {
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import MapBox from 'common/containers/MapBox';
import { unclusteredPointStyle } from 'utils/constants';
import { getOnePointGeojson } from 'utils/helpers';

const ContactMap = ({ coordinates }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current && coordinates) {
      const geojson = getOnePointGeojson(coordinates);

      map.current.on('load', () => {
        if (map.current.getSource('locations')) {
          return;
        }
        map.current.addSource('locations', geojson);

        map.current.addLayer({
          ...unclusteredPointStyle,
        });
      });
    }
  }, [coordinates]);

  return (
    <MapBox
      coordinates={coordinates}
      mapContainer={mapContainer}
      map={map}
    />
  );
};

ContactMap.defaultProps = {
  coordinates: [],
};

ContactMap.propTypes = {
  coordinates: PropTypes.arrayOf(PropTypes.number),
};

export default ContactMap;
