const ZapierComponent = () => (
  <div>
    <zapier-zap-manager
      client-id="2Db4AYHlkiz4ujMiZOhcHQg7lnunvvIhx0igXTg1"
      link-target="new-tab"
      theme="light"
    />
  </div>
  );

export default ZapierComponent;
