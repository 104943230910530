import {
  Field,
  Form,
} from 'react-final-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';

import { setSnackbarSuccess } from 'store/actions/global';

import { required } from 'utils/validators';
import { CircularProgressWrapper } from 'globalStyles';

const StyledField = styled(Field)`
  width: 50%;
  padding-top: 20px;
`;

const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;

const EMAIL_LABEL = 'Email';
const FIRST_NAME_LABEL = 'First Name';
const LAST_NAME_LABEL = 'Last Name';
const UPDATE_BUTTON_TEXT = 'Update';
const INFORMATIONS_UPDATE_SUCCESS_MESSAGE = 'User information has been successfully changed';

const ContactInfoForm = ({
  initialValues,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  if (Object.values(initialValues).includes(undefined)) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({
        handleSubmit,
        submitting,
        pristine,
      }) => (
        <form onSubmit={async event => {
          const response = await handleSubmit(event);

          if (response === 'success') {
            dispatch(setSnackbarSuccess({ message: INFORMATIONS_UPDATE_SUCCESS_MESSAGE }));
          }
        }}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              alignContent="space-between"
              container
              item
              xs={12}
            >
              <StyledField
                name="firstName"
                validate={required}
              >
                {({
                  input,
                  meta,
                }) => (
                  <TextField
                    {...input}
                    error={meta.touched && (meta.error || meta.submitError)}
                    fullWidth
                    helperText={meta.touched && (meta.error || meta.submitError)}
                    label={FIRST_NAME_LABEL}
                    type="text"
                  />
                )}
              </StyledField>
            </Grid>
            <Grid
              alignContent="space-between"
              container
              item
              xs={12}
            >
              <StyledField
                name="lastName"
                validate={required}
              >
                {({
                  input,
                  meta,
                }) => (
                  <TextField
                    {...input}
                    error={meta.touched && (meta.error || meta.submitError)}
                    fullWidth
                    helperText={meta.touched && (meta.error || meta.submitError)}
                    label={LAST_NAME_LABEL}
                    type="text"
                  />
                )}
              </StyledField>
            </Grid>
            <Grid
              alignContent="space-between"
              container
              item
              xs={12}
            >
              <StyledField
                name="email"
                validate={required}
              >
                {({
                  input,
                  meta,
                }) => (
                  <TextField
                    {...input}
                    error={meta.touched && (meta.error || meta.submitError)}
                    fullWidth
                    helperText={meta.touched && (meta.error || meta.submitError)}
                    label={EMAIL_LABEL}
                    type="email"
                  />
                )}
              </StyledField>
            </Grid>
          </Grid>
          <StyledGrid
            container
            item
            justifyContent="flex-end"
            xs={12}
          >
            <Button
              color="secondary"
              disabled={submitting || pristine}
              disableElevation
              type="submit"
              variant="contained"
            >
              {UPDATE_BUTTON_TEXT}
            </Button>
          </StyledGrid>
        </form>
      )}
    />
  );
};

ContactInfoForm.propTypes = {
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ContactInfoForm;
