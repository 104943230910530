import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';

import {
  Autocomplete,
  CircularProgress,
  TextField,
} from '@mui/material';

import {
  fetchBoundariesTop,
  fetchBoundariesTree,
  fetchBoundary,
} from 'store/actions/global';
import { globalSelectors } from 'store/selectors/global';

import { boundaryTypes } from 'utils/constants';
import {
  addBoundaryToMap,
  fullLocationsName,
} from 'utils/helpers';
import { CircularProgressWrapper } from 'globalStyles';
import MapBox from 'common/containers/MapBox';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

const StyledMapBox = styled(MapBox)`
  width: 100%;
  max-width: 800px;
  height: 300px;
  margin-top: 20px;
`;

const StyledProgress = styled.div`
  margin-top: 20px;
`;

const LocationDetails = ({
  handleAddBoundary,
  locationFilterData,
  map,
  mapContainer,
  setFilterValue,
  setLocationFilterData,
}) => {
  const dispatch = useDispatch();
  const {
    boundariesTop,
    boundariesTree,
    isBoundariesTopFetching,
    isBoundariesTreeFetching,
  } = useSelector(state => ({
    boundariesTop: globalSelectors.getBoundariesTop(state).children,
    boundariesTree: globalSelectors.getBoundariesTree(state),
    isBoundariesTopFetching: globalSelectors.isBoundariesTopFetching(state),
    isBoundariesTreeFetching: globalSelectors.isBoundariesTreeFetching(state),
  }));

  const {
    boundariesList,
    boundaryState,
    boundaryType,
    editedGeojsonUrls,
  } = locationFilterData;

  useEffect(() => {
    if (boundariesTop.length === 0) {
      dispatch(fetchBoundariesTop());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!!editedGeojsonUrls.length && map?.current) {
      Promise.all(editedGeojsonUrls.map(editedGeojsonUrl => dispatch(
        fetchBoundary(editedGeojsonUrl)
      )))
        .then(data => data.forEach(feature => {
          addBoundaryToMap(map.current, feature);

          const bounds = new mapboxgl.LngLatBounds();

          data.forEach(boundary => {
            boundary.geometry.coordinates[0][0].forEach(coordinate => {
              bounds.extend(coordinate);
            });
          });

          map.current.fitBounds(bounds, {
            padding: {
              bottom: 25,
              left: 15,
              right: 5,
              top: 10,
            },
          });
        }));

      setLocationFilterData(prevValue => ({
        ...prevValue,
        editedGeojsonUrls: [],
      }));
    }
  }, [
    dispatch,
    editedGeojsonUrls,
    setLocationFilterData,
  ]);

  const boundariesTreeList = boundariesTree[boundaryState?.uuid] || [];

  const renderBoundaryList = () => {
    if (isBoundariesTreeFetching) {
      return (
        <StyledProgress>
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        </StyledProgress>
      );
    }

    return (
      <Autocomplete
        getOptionLabel={option => option.name || ''}
        isOptionEqualToValue={(option, value) => (value ? option.uuid === value.uuid : true)}
        multiple
        onChange={(event, list, reason, detail) => {
          if (reason === 'removeOption') {
            map.current.removeLayer(`boundary-${detail.option.uuid}-fill`);
            map.current.removeLayer(`boundary-${detail.option.uuid}-outline`);
            map.current.removeSource(`boundary-${detail.option.uuid}`);
          }
          setLocationFilterData(prevValue => ({
            ...prevValue,
            boundariesList: list,
          }));

          if (list.length) {
            handleAddBoundary(list[list.length - 1].geojsonUrl);

            const filterName = fullLocationsName(list);

            setFilterValue(filterName);
          } else {
            setFilterValue('');

            map.current.getStyle().layers.forEach(layer => {
              if (layer.id?.startsWith('boundary-')) {
                map.current.removeLayer(layer.id);
              }
            });

            Object.keys(map.current.getStyle().sources).forEach(propertyName => {
              if (propertyName.startsWith('boundary-')) {
                map.current.removeSource(propertyName);
              }
            });
          }
        }}
        options={
          boundariesTreeList.filter(
            boundaryTreeList => boundaryTreeList.boundaryType === boundaryType.type
          ).sort((a, b) => -b.name.localeCompare(a.name, 'en', { numeric: true }))
        }
        renderInput={params => (
          <TextField
            {...params}
            label={boundaryType.name}
            variant="standard"
          />
        )}
        value={boundariesList}
      />
    );
  };

  return (
    <div>
      {isBoundariesTopFetching ? (
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      ) : (
        <Autocomplete
          disableClearable
          getOptionLabel={option => (option?.name || '')}
          isOptionEqualToValue={(option, value) => (value ? option.uuid === value.uuid : true)}
          onChange={(_, pickedState) => {
            if (pickedState) {
              const feature = boundariesTop.find(({ uuid }) => uuid === pickedState.uuid);

              setLocationFilterData(prevValue => ({
                ...prevValue,
                boundaryState: feature,
                boundaryType: '',
              }));
            } else {
              setLocationFilterData(prevValue => ({
                ...prevValue,
                boundaryState: '',
                boundaryType: '',
              }));
            }
          }}
          options={boundariesTop.sort((a, b) => -b.name.localeCompare(a.name))}
          value={boundaryState}
          renderInput={params => (
            <TextField
              {...params}
              data-test-id="Select state"
              label="Select state"
              variant="standard"
            />
          )}
        />
      )}
      {boundaryState && (
        <Autocomplete
          getOptionLabel={option => option.name || ''}
          isOptionEqualToValue={(option, value) => (value ? option === value : true)}
          key={boundaryState.name}
          onChange={(_, pickedBoundary) => {
            setLocationFilterData(prevValue => ({
              ...prevValue,
              boundaryType: pickedBoundary,
            }));

            if (pickedBoundary?.type !== 'state') {
              if (!Object.keys(boundariesTree).includes(boundaryState.uuid)) {
                dispatch(fetchBoundariesTree(boundaryState.uuid));
              }
            }

            if (pickedBoundary?.type === 'state' && !boundariesList.find(boundaryItem => boundaryItem.uuid === boundaryState.uuid)) {
              const filterName = fullLocationsName([
                boundaryState,
                ...boundariesList,
              ]);

              setFilterValue(filterName);
              setLocationFilterData(prevValue => ({
                ...prevValue,
                boundariesList: [
                  ...prevValue.boundariesList,
                  boundaryState,
                ],
              }));
              handleAddBoundary(boundaryState.geojsonUrl);
            }
          }}
          options={boundaryTypes}
          value={boundaryType}
          renderInput={params => (
            <TextField
              {...params}
              data-test-id="Select boundary type"
              label="Select boundary type"
              variant="standard"
            />
          )}
        />
      )}
      {boundaryState &&
        (boundaryType || !!boundariesList.length) &&
        renderBoundaryList()
      }
      {boundaryState && (
        <StyledMapBox
          mapContainer={mapContainer}
          map={map}
        />
      )}
    </div>
  );
};

LocationDetails.defaultProps = {
  map: null,
  mapContainer: null,
};

LocationDetails.propTypes = {
  handleAddBoundary: PropTypes.func.isRequired,
  locationFilterData: PropTypes.shape({
    boundariesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    boundaryState: PropTypes.oneOfType([
      PropTypes.shape({
        geojsonUrl: PropTypes.string,
        name: PropTypes.string,
        uuid: PropTypes.string,
      }),
      PropTypes.string,
    ]).isRequired,
    boundaryType: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
      }),
      PropTypes.string,
    ]).isRequired,
    editedGeojsonUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  map: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  mapContainer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  setFilterValue: PropTypes.func.isRequired,
  setLocationFilterData: PropTypes.func.isRequired,
};

export default LocationDetails;
