import {
  useEffect,
  useState,
} from 'react';
import {
  Link as RouterLink,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { useConfirm } from 'material-ui-confirm';
import {
  Button,
  Grid,
  Link,
} from '@mui/material';
import {
  Group as AssignmentIcon,
  Event as EventIcon,
} from '@mui/icons-material';

import {
  deleteMeeting,
  fetchAllMeetings,
} from 'store/actions/meetings';
import { meetingsSelectors } from 'store/selectors/meetings';

import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'store/actions/global';

import {
  Backdrop,
  BreadcrumbsNavigation,
  Container,
  DescriptionBar,
} from 'common/components';
import MeetingDialog from '../components/MeetingDialog';
import MeetingItem from '../components/MeetingItem';
import {
  fetchMeetings,
  patchMeeting,
  postMeeting,
} from '../../../store/actions/meetings';

const Meetings = () => {
  const dispatch = useDispatch();

  const {
    organizationUuid,
  } = useParams();

  const {
    isFetching,
    isPending,
  } = useSelector(state => ({
    isFetching: meetingsSelectors.isFetching(state),
  }));

  const defaultMeetingState = {
    description: '',
    isModalOpened: false,
    title: 'hello!',
  };

  const [
    editedMeeting,
    setEditedMeeting,
  ] = useState({ ...defaultMeetingState });

  const [
    meetings,
    setMeetings,
  ] = useState([]);

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/campaigns`,
      name: 'Advocacy',
    },
    {
      link: '',
      name: 'Meetings',
    },
  ];

  useEffect(() => {
    dispatch(fetchAllMeetings(organizationUuid))
      .then(data => {
        setMeetings(data || []);
      });
  }, []);
  const handleUpdateMeeting = async () => {
    const params = {
      description: editedMeeting.description,
      organization: organizationUuid,
      targets: editedMeeting.targets,
      title: editedMeeting.title,
    };

    try {
      if (!editedMeeting.uuid) {
        await dispatch(postMeeting(params));
      } else {
        await dispatch(patchMeeting(organizationUuid, editedMeeting.uuid, params));
      }
      dispatch(fetchMeetings(organizationUuid));

      setEditedMeeting({ ...defaultMeetingState });
    } catch (error) {
      dispatch(setSnackbarError());
    }
  };

  const handleDialogClose = () => {
    setEditedMeeting({ ...defaultMeetingState });
  };

  const confirm = useConfirm();

  const handleRemoveMeeting = meetingUuid => {
    confirm({
      confirmationText: 'Remove',
      title: 'Remove Meeting?',
    })
      .then(() => dispatch(deleteMeeting(meetingUuid)))
      .then(() => dispatch(fetchAllMeetings(organizationUuid)))
      .then(data => setMeetings(data || []))
      .then(() => dispatch(setSnackbarSuccess()));
  };

  return (
    <>
      <BreadcrumbsNavigation
        isLoading={isFetching}
        links={breadcrumbs}
      />
      <Container marginBottom="40">
        <Grid container>
          <DescriptionBar
            name="Meetings"
            icon={AssignmentIcon}
          >
            <Button
              disableElevation
              color="secondary"
              component={RouterLink}
              startIcon={<EventIcon />}
              to={`/${organizationUuid}/meetings/new`}
              variant="contained"
            >
              Log Meeting
            </Button>
            {/* <AddFieldButton */}
            {/*   color="secondary" */}
            {/*   onClick={() => setEditedMeeting({ */}
            {/*       ...defaultMeetingState, */}
            {/*       isModalOpened: true, */}
            {/*     })} */}
            {/*   startIcon={<AddIcon />} */}
            {/*   variant="contained" */}
            {/* > */}
            {/*   Log Meeting */}
            {/* </AddFieldButton> */}
          </DescriptionBar>
        </Grid>
        {!isFetching &&
        !meetings?.length ? (
          <Link
            component={RouterLink}
            color="inherit"
            to={`/${organizationUuid}/create-meeting`}
          >
            <span>Create your first Meeting</span>
          </Link>
        ) : meetings?.map(meetingData => (
          <MeetingItem
            handleRemoveMeeting={handleRemoveMeeting}
            key={meetingData.uuid}
            occurredOn={meetingData.occurredOn}
            targets={meetingData.targets}
            title={meetingData.title}
            uuid={meetingData.uuid}
          />
          ))
        }
      </Container>
      <MeetingDialog
        editedMeeting={editedMeeting}
        handleDialogClose={handleDialogClose}
        handleUpdateMeeting={handleUpdateMeeting}
        isPendingMeeting={isPending}
        setEditedMeeting={setEditedMeeting}
      />
      <Backdrop isOpen={isFetching} />
    </>
  );
};

export default Meetings;
