import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Field,
  Form,
} from 'react-final-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import { passwordReset } from 'store/actions/auth';
import { authSelectors } from 'store/selectors/auth';

import { required } from 'utils/validators';

const LogoMark = styled.img`
  max-height: 60px;
  margin-bottom: 15px;
`;

const MarginGrid = styled(Grid)`
  margin-top: 5px;
`;

const StyledPaper = styled(Paper)`
  padding: 50px 30px;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  width: 280px;
  height: 50px;
  margin: 30px 0 0;
  font-weight: bold;
  text-transform: uppercase;
`;

const TitleWrapper = styled(Grid)`
  margin-bottom: 20px;
`;

const Wrapper = styled(Grid)`
  max-width: 500px;
  height: 100%;
`;

const EMAIL_LABEL = 'Email';
const FORGOT_PASSWORD_SUCCESS = 'We have emailed you instructions for resetting your password to the email address you submitted. You should be receiving it shortly.';
const FORGOT_PASSWORD_SUBTITLE = 'Enter your e-mail address below and we will send you instructions on how to reset your password';
const RESET_PASSWORD_TEXT = 'Reset password';
const RETURN_BUTTON_TEXT = 'Return to log in';

const { REACT_APP_FRONTEND_URL } = process.env;

const PasswordReset = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [
    isRequestSuccessful,
    setRequestSuccessful,
  ] = useState(false);

  const {
    isAuthenticated,
  } = useSelector(state => ({
    isAuthenticated: authSelectors.getIsAuthenticated(state),
  }));

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/organizations');
    }
  }, [isAuthenticated]);

  const handleReturnToLogin = () => history.push('/login');

  const onSubmit = async values => {
    const params = {
      resetUrl: `${REACT_APP_FRONTEND_URL}/confirm-password-reset`,
      ...values,
    };

    try {
      await dispatch(passwordReset(params));

      return setRequestSuccessful(true);
    } catch (error) {
      return error;
    }
  };

  return (
    <Wrapper
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
    >
      <StyledPaper variant="outlined">
        <LogoMark
          alt="Muster logomark"
          src="/muster_logomark_blue.png"
        />
        {isRequestSuccessful ? (
          <>
            <Typography variant="subtitle2">{FORGOT_PASSWORD_SUCCESS}</Typography>
            <Grid
              container
              item
              justifyContent="center"
              xs={12}
            >
              <SubmitButton
                color="secondary"
                disableElevation
                onClick={handleReturnToLogin}
                type="submit"
                variant="contained"
              >
                {RETURN_BUTTON_TEXT}
              </SubmitButton>
            </Grid>
          </>
        ) : (
          <>
            <TitleWrapper
              container
              direction="column"
              alignItems="center"
            >
              <Typography variant="subtitle1">{FORGOT_PASSWORD_SUBTITLE}</Typography>
            </TitleWrapper>
            <Form
              onSubmit={onSubmit}
              render={({
                handleSubmit,
                submitting,
                pristine,
              }) => (
                <form onSubmit={handleSubmit}>
                  <MarginGrid
                    container
                    item
                    xs={12}
                  >
                    <Field
                      name="email"
                      validate={required}
                    >
                      {({
                        input,
                        meta,
                      }) => (
                        <TextField
                          {...input}
                          error={Boolean(meta.touched && (meta.error || meta.submitError))}
                          fullWidth
                          helperText={meta.touched && (meta.error || meta.submitError)}
                          label={EMAIL_LABEL}
                          type="text"
                        />
                      )}
                    </Field>
                  </MarginGrid>
                  <Grid
                    container
                    item
                    justifyContent="center"
                    xs={12}
                  >
                    <SubmitButton
                      color="secondary"
                      disableElevation
                      disabled={submitting || pristine}
                      type="submit"
                      variant="contained"
                    >
                      {RESET_PASSWORD_TEXT}
                    </SubmitButton>
                  </Grid>
                </form>
              )}
            />
          </>
        )}
      </StyledPaper>
    </Wrapper>
  );
};

export default PasswordReset;
