import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  Typography,
} from '@mui/material';

import { organizationSelectors } from 'store/selectors/organizations';

import { useCopyToClipboard } from 'utils/hooks';
import { colors } from 'utils/constants';
import { StyledCode } from 'globalStyles';
import { Popover } from 'common/components';
import WidgetDialog from '../WidgetDialog';

const ImageWrapper = styled.figure`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid;
  border-color: ${({ $draftStatus }) => ($draftStatus ? colors.warning : colors.borderTransparentGray)};
  cursor: pointer;

  &:hover {
    background-color: ${colors.borderTransparentGray};
  }
`;

const WidgetWrappers = styled.section`
  display: grid;
  gap: 0 16px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
`;

const WidgetImage = styled.img`
  height: 300px;
`;

const Instructions = styled.article`
  margin: 40px 0;
`;

const DraftMessage = styled.p`
  color: ${({ $draftStatus }) => ($draftStatus ? colors.warning : colors.black)};
`;

const WidgetHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const DRAFT_ACTION_CENTER_MESSAGE = 'Widget forms will show an error if embedded until the action center is published.';

const WidgetsTab = ({ publishStatus }) => {
  const { actionCenterUuid } = useParams();
  const [copy] = useCopyToClipboard();

  const {
    organization: {
      flags,
    },
  } = useSelector(state => ({
    organization: organizationSelectors.getOrganization(state),
  }));

  const [
    dialogData,
    setDialogData,
  ] = useState({
    isDialogOpen: false,
    widgetCode: '',
  });

  const {
    REACT_APP_LANDING_PAGE_URL,
    REACT_APP_WIDGET_URL,
    REACT_APP_WIDGET_V2_URL,
  } = process.env;

  const widgetCodeV1 = `
    <div
      data-action-center-id="${actionCenterUuid}"
      data-domain="${REACT_APP_LANDING_PAGE_URL}"
      data-height="1000"
      id="muster-action-center"
    >
      <script src="${REACT_APP_WIDGET_URL}"></script>
    </div>
  `;

  const widgetCodeV2 = `
    <div
      data-action-center-id="${actionCenterUuid}"
      data-domain="${REACT_APP_LANDING_PAGE_URL}"
      data-height="1000"
      data-width="455"
      data-height-full="true"
      data-css=""
      id="muster-widget-v2-action-center-${actionCenterUuid}"
    >
      <script
        src="${REACT_APP_WIDGET_V2_URL}"
        widget-id="muster-widget-v2-action-center-${actionCenterUuid}"
      ></script>
    </div>
  `;

  const handleOpenModal = widgetCode => {
    setDialogData({
      isDialogOpen: true,
      widgetCode,
    });
  };

  const draftStatus = publishStatus === 1;

  const handleCopyToClipboard = () => {
    copy(dialogData.widgetCode, 'textarea', 'Code');
    setDialogData({
      isDialogOpen: false,
      widgetCode: '',
    });
  };

  return (
    <>
      <WidgetWrappers aria-label="Widgets Code Snippets">
        {REACT_APP_WIDGET_V2_URL && (
          <Popover
            shouldDisplayPopover={draftStatus}
            text={DRAFT_ACTION_CENTER_MESSAGE}
          >
            <div>
              <ImageWrapper
                $draftStatus={draftStatus}
                onClick={() => handleOpenModal(widgetCodeV2)}
              >
                <WidgetImage
                  src="/advocacy/widget-preview-minima.png"
                  alt="Minima widget preview"
                />
              </ImageWrapper>
              <WidgetHeader>
                <Typography variant="subtitle1">
                  <strong>Minima</strong>
                  {' '}
                  ✉️ ☎️ (flex sizing)
                </Typography>
                <Button
                  color="secondary"
                  disableElevation
                  variant="contained"
                  onClick={() => copy(widgetCodeV2, 'textarea', 'Code')}
                >
                  Copy Code
                </Button>
              </WidgetHeader>
            </div>
          </Popover>
        )}
        {REACT_APP_WIDGET_URL && !flags.showMessageMapping && (
          <Popover
            shouldDisplayPopover={draftStatus}
            text={DRAFT_ACTION_CENTER_MESSAGE}
          >
            <div>
              <ImageWrapper
                $draftStatus={draftStatus}
                onClick={() => handleOpenModal(widgetCodeV1)}
              >
                <WidgetImage
                  src="/advocacy/widget-preview-aesthete.png"
                  alt="Aesthete widget preview"
                />
              </ImageWrapper>
              <WidgetHeader>
                <Typography variant="subtitle1">
                  <strong>Aesthete</strong>
                  {' '}
                  ✉️ (fixed width)
                </Typography>
                <Button
                  color="secondary"
                  disableElevation
                  variant="contained"
                  onClick={() => copy(widgetCodeV1, 'textarea', 'Code')}
                >
                  Copy Code
                </Button>
              </WidgetHeader>
            </div>
          </Popover>
        )}
      </WidgetWrappers>
      <Instructions>
        <Typography variant="subtitle1">Embedding Instructions</Typography>
        <p>
          The widgets allow you to embed an action form directly in your own website.
          Click on the widget preview to see the HTML snippet,
          or just click the &#34;copy&#34; button to copy the code.
          If you have access to the website where you will be
          embedding the code you paste this into the page exactly where you want the form.
          Otherwise you can share the code with your developer or web content manager.
        </p>
        <p>
          Hint: the height of the widget can be controlled by setting
          the
          {' '}
          <code>data-height</code>
          {' '}
          attribute of the
          {' '}
          <code>&#60;div&#62;</code>
          {' '}
          element.
        </p>
        {REACT_APP_WIDGET_V2_URL && (
          <>
            <p>
              Hint: the width of the widget
              {' ('}
              <strong>Minima only</strong>
              {') '}
              can be controlled using the
              {' '}
              <code>data-width</code>
              {' '}
              attribute of the
              {' '}
              <code>&#60;div&#62;</code>
              {' '}
              element.
            </p>
            <p>
              Hint: you can achieve full page height without scrolling
              {' ('}
              <strong>Minima only</strong>
              {') '}
              by setting the following attribute:
              {' '}
              <code>data-height-full=&quot;true&quot;</code>
              {'. '}
            </p>
            <p>
              Hint: external CSS stylesheet can be added
              {' ('}
              <strong>Minima only</strong>
              {') '}
              to the widget by setting the
              {' '}
              <code>data-css</code>
              {' '}
              attribute of the
              {' '}
              <code>&#60;div&#62;</code>
              {' '}
              element. The value should be a URL to the CSS file.
            </p>
          </>
        )}
        <DraftMessage $draftStatus={draftStatus}>
          {draftStatus ? `${DRAFT_ACTION_CENTER_MESSAGE}` : ''}
        </DraftMessage>
      </Instructions>
      <WidgetDialog
        isDialogOpen={dialogData.isDialogOpen}
        handleDialogClose={() => setDialogData({ isDialogOpen: false })}
        title="Widget Code"
        handleCopyToClipboard={handleCopyToClipboard}
      >
        <StyledCode>
          {dialogData.widgetCode}
        </StyledCode>
      </WidgetDialog>
    </>
  );
};

WidgetsTab.defaultProps = {
  publishStatus: null,
};

WidgetsTab.propTypes = {
  publishStatus: PropTypes.number,
};

export default WidgetsTab;
