/* stylelint-disable selector-class-pattern */
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Stepper as MaterialStepper,
  Step,
  StepLabel,
} from '@mui/material';

import { colors } from 'utils/constants';

const StyledMaterialStepper = styled(MaterialStepper)`
  padding: 24px;
  background-color: ${props => (props.neutralcolors ? colors.backgroundColor : colors.codGray)};
`;

const StyledStep = styled(Step)`
  .MuiStepIcon-root.MuiStepIcon-active,
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: ${props => (props.neutralcolors ? colors.secondary : colors.osloGray)};
  }
`;

const StyledStepLabel = styled(StepLabel)`
  .MuiStepLabel-label {
    color: ${props => (props.neutralcolors ? colors.black : colors.white)};
  }
`;

const Stepper = ({
  neutralcolors,
  step,
  steps,
}) => (
  <div>
    <StyledMaterialStepper
      activeStep={step}
      alternativeLabel
      neutralcolors={neutralcolors ? 1 : 0}
    >
      {steps.map(label => (
        <StyledStep
          key={label}
          neutralcolors={neutralcolors ? 1 : 0}
        >
          <StyledStepLabel neutralcolors={neutralcolors ? 1 : 0}>{label}</StyledStepLabel>
        </StyledStep>
      ))}
    </StyledMaterialStepper>
  </div>
);

Stepper.defaultProps = {
  neutralcolors: undefined,
};

Stepper.propTypes = {
  neutralcolors: PropTypes.bool,
  step: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Stepper;
