import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Grid,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  LandingPageInput,
  LandingPageSelect,
} from 'common/components/LandingPageInputs';

import {
  colors,
  fieldTypes,
} from 'utils/constants';
import { SendMessageButton } from 'globalStyles';
import { customFieldType } from 'common/typings';

const ContactWrapper = styled.div`
  border: 2px solid ${colors.wildSand};
  padding: 3px;
`;

const FieldsWrapper = styled.div`
  display: grid;
  gap: 0 16px;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 32px;
`;

const SendMessageButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

const BackgroundLandingPage = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 50px;
  background-color: ${props => (props.$backgroundColor)};
`;

const HeadlinePreviewText = styled.p`
  color: ${props => (props.$color)};
  text-align: center;
`;

const RemoveFieldWrapper = styled.div`
  position: relative;
`;

const RemoveButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: -22px;
  z-index: 1;
  color: #888;
`;

const PreviewLandingPageFields = ({
  backgroundColor,
  buttonColor,
  buttonName,
  customFields,
  handleRemoveCustomField,
  headlineText,
  textColor,
 }) => (
   <ContactWrapper>
     <BackgroundLandingPage $backgroundColor={backgroundColor}>
       <HeadlinePreviewText $color={textColor}>{headlineText || 'Some Headline Text on Landing Page'}</HeadlinePreviewText>
     </BackgroundLandingPage>
     <FieldsWrapper>
       <LandingPageSelect name="Prefix" />
       <LandingPageInput name="First Name" />
       <LandingPageInput name="Last Name" />
       <LandingPageInput name="Street Address" />
       <LandingPageInput name="Zip Code" />
       <LandingPageInput name="Email" />
       <LandingPageInput name="Phone Number" />
       {!!customFields?.length && customFields.map(customField => (
        customField.fieldType === fieldTypes.singleChoiceField ? (
          <RemoveFieldWrapper key={customField.label}>
            <LandingPageSelect name={customField.label} />
            <RemoveButton
              aria-label="remove field"
              onClick={() => handleRemoveCustomField(customField.uuid)}
            >
              <DeleteIcon />
            </RemoveButton>
          </RemoveFieldWrapper>
        ) :
        (
          <RemoveFieldWrapper key={customField.label}>
            <LandingPageInput name={customField.label} />
            <RemoveButton
              aria-label="remove field"
              onClick={() => handleRemoveCustomField(customField.uuid)}
            >
              <DeleteIcon />
            </RemoveButton>
          </RemoveFieldWrapper>
        )
      ))}
     </FieldsWrapper>
     <SendMessageButtonWrapper
       item
       xs={12}
     >
       <SendMessageButton
         buttoncolor={buttonColor}
         textcolor={textColor}
       >
         {buttonName}
       </SendMessageButton>
     </SendMessageButtonWrapper>
   </ContactWrapper>
);

PreviewLandingPageFields.defaultProps = {
  buttonName: 'Send Message',
};

PreviewLandingPageFields.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  customFields: PropTypes.arrayOf(customFieldType).isRequired,
  handleRemoveCustomField: PropTypes.func.isRequired,
  headlineText: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default PreviewLandingPageFields;
