import {
  FETCH_API_KEYS_FAILURE,
  FETCH_API_KEYS_REQUEST,
  FETCH_API_KEYS_SUCCESS,
  FETCH_EMAIL_DOMAINS_FAILURE,
  FETCH_EMAIL_DOMAINS_REQUEST,
  FETCH_EMAIL_DOMAINS_SUCCESS,
  FETCH_INVITATION_DETAILS_FAILURE,
  FETCH_INVITATION_DETAILS_REQUEST,
  FETCH_INVITATION_DETAILS_SUCCESS,
  FETCH_INVITATIONS_FAILURE,
  FETCH_INVITATIONS_REQUEST,
  FETCH_INVITATIONS_SUCCESS,
  FETCH_ORGANIZATION_FAILURE,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATIONS_REQUEST,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_TEAM_MEMBERS_FAILURE,
  FETCH_TEAM_MEMBERS_REQUEST,
  FETCH_TEAM_MEMBERS_SUCCESS,
  PATCH_ORGANIZATION_FAILURE,
  PATCH_ORGANIZATION_REQUEST,
  PATCH_ORGANIZATION_SUCCESS,
  POST_ORGANIZATION_FAILURE,
  POST_ORGANIZATION_REQUEST,
  POST_ORGANIZATION_SUCCESS,
  PUT_ORGANIZATION_FAILURE,
  PUT_ORGANIZATION_REQUEST,
  PUT_ORGANIZATION_SUCCESS,
} from '../actions/organizations';

const initialState = {
  apiKeysDetails: {
    isFetching: false,
    results: [],
  },
  emailDomainData: [],
  invitationDetails: {},
  invitedMembers: {},
  isOrganizationFetching: false,
  isPending: false,
  list: {
    errors: null,
    isFetching: false,
    results: [],
  },
  organization: {},
  teamMembers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_REQUEST:
      return {
        ...state,
        errors: null,
        isOrganizationFetching: true,
      };
    case FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isOrganizationFetching: false,
        organization: action.payload,
      };
    case FETCH_ORGANIZATION_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isOrganizationFetching: false,
        organization: {},
      };
    case FETCH_INVITATION_DETAILS_REQUEST:
      return {
        ...state,
        errors: null,
        isOrganizationFetching: true,
      };
    case FETCH_INVITATION_DETAILS_SUCCESS:
      return {
        ...state,
        invitationDetails: action.payload,
        isOrganizationFetching: false,
      };
    case FETCH_INVITATION_DETAILS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        invitationDetails: {},
        isOrganizationFetching: false,
      };
    case FETCH_INVITATIONS_REQUEST:
      return {
        ...state,
        errors: null,
        isOrganizationFetching: true,
      };
    case FETCH_INVITATIONS_SUCCESS:
      return {
        ...state,
        invitedMembers: action.payload,
        isOrganizationFetching: false,
      };
    case FETCH_INVITATIONS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        invitedMembers: {},
        isOrganizationFetching: false,
      };
    case FETCH_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        errors: {},
        isFetching: false,
        list: action.payload,
      };
    case FETCH_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
        list: [],
      };
    case FETCH_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        errors: null,
        isOrganizationFetching: true,
      };
    case FETCH_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        isOrganizationFetching: false,
        teamMembers: action.payload,
      };
    case FETCH_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isOrganizationFetching: false,
        teamMembers: [],
      };
    case PATCH_ORGANIZATION_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case PATCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isPending: false,
        organization: action.payload,
      };
    case PATCH_ORGANIZATION_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    case POST_ORGANIZATION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case POST_ORGANIZATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        errors: {},
        isFetching: false,
      };
    case POST_ORGANIZATION_FAILURE:
      return {
        ...state,
        data: {},
        errors: action.payload,
        isFetching: false,
      };
    case PUT_ORGANIZATION_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case PUT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isPending: false,
        organization: action.payload,
      };
    case PUT_ORGANIZATION_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    case FETCH_API_KEYS_REQUEST:
      return {
        ...state,
      };
    case FETCH_API_KEYS_SUCCESS:
      return {
        ...state,
        apiKeysDetails: action.payload,
      };
    case FETCH_API_KEYS_FAILURE:
      return {
        ...state,
        apiKeysDetails: {
          results: [],
        },
      };
    case FETCH_EMAIL_DOMAINS_REQUEST:
      return {
        ...state,
        errors: null,
        isOrganizationFetching: true,
      };
    case FETCH_EMAIL_DOMAINS_SUCCESS:
      return {
        ...state,
        emailDomainData: action.payload,
        isOrganizationFetching: false,
      };
    case FETCH_EMAIL_DOMAINS_FAILURE:
      return {
        ...state,
        emailDomainData: [],
        errors: action.payload,
        isOrganizationFetching: false,
      };
    default:
      return state;
  }
};
