import { useEffect } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';

import {
  Card,
  Grid,
} from '@mui/material';
import { GroupAdd as GroupAddIcon } from '@mui/icons-material';

import {
  fetchAllListContact,
  fetchContact,
  fetchRootList,
  updateContact,
} from 'store/actions/contacts';
import { fetchCustomFields } from 'store/actions/customFields';
import { contactSelectors } from 'store/selectors/contacts';
import { customFieldsSelectors } from 'store/selectors/customFields';

import {
  Backdrop,
  BreadcrumbsNavigation,
  ContactForm,
  Container,
  DescriptionBar,
} from 'common/components';

import { handleOnlyNumbers } from 'utils/helpers';

import ContactMap from '../components/ContactMap';

const EditContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
`;

const StyledCard = styled(Card)`
  max-width: 800px;
  margin-bottom: 20px;
  padding: 12px;
`;

const EditContact = () => {
  const dispatch = useDispatch();
  const {
    contactUuid,
    listUuid,
    organizationUuid,
  } = useParams();
  const history = useHistory();
  const {
    contactDetails,
    customFieldsList,
    isFetchingCustomFields,
    rootList,
  } = useSelector(state => ({
    contactDetails: contactSelectors.getContactDetails(state),
    customFieldsList: customFieldsSelectors.getCustomFieldsListData(state) || [],
    isFetchingCustomFields: customFieldsSelectors.isFetchingCustomFieldsListData(state),
    rootList: contactSelectors.getOrganizationRootList(state)[organizationUuid] || {},
  }));

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/contacts/lists`,
      name: 'Lists',
    },
    {
      link: `/${organizationUuid}/contacts/lists/${listUuid}`,
      name: contactDetails?.listName || 'Contacts List',
    },
    {
      link: '',
      name: `${contactDetails?.firstName} ${contactDetails?.lastName}`,
    },
  ];

  useEffect(() => {
    if (Object.keys(rootList).length === 0) {
      dispatch(fetchRootList(organizationUuid))
        .then(data => dispatch(fetchCustomFields(data.uuid)));
    } else {
      dispatch(fetchCustomFields(rootList.uuid));
    }

    dispatch(fetchContact(listUuid, contactUuid));
  }, []);

  const onSubmit = async values => {
    const params = { ...values };

    if (values.customFields && Object.keys(values.customFields).length !== 0) {
      params.customFields = Object.entries(values.customFields).map(customField => ({
        customField: customField[0],
        value: customField[1],
      }));
    }

    if (params.locations && params.locations[0]?.zip_code) {
      params.locations[0].zip_code = handleOnlyNumbers(params.locations[0].zip_code);
    }

    try {
      await dispatch(updateContact(listUuid, contactUuid, params));
      await dispatch(fetchAllListContact(listUuid));

      return history.push(`/${organizationUuid}/contacts/lists/${listUuid}/contact/${contactUuid}`);
    } catch (error) {
      return error;
    }
  };

  const initialValues = {};

  if (contactDetails.firstName) {
    initialValues.firstName = contactDetails.firstName;
  }

  if (contactDetails.lastName) {
    initialValues.lastName = contactDetails.lastName;
  }

  if (contactDetails.customFields?.length) {
    initialValues.customFields = contactDetails.customFields.reduce(
      (convertedAcc, customFieldAnswer) => ({
        ...convertedAcc,
        [customFieldAnswer.customField]: customFieldAnswer.value,
      }),
      {}
    );
  }

  if (contactDetails.emails?.length) {
    initialValues.emails = contactDetails.emails.map(item => ({
      email: item.email,
    }));
  }

  if (contactDetails.locations?.length) {
    initialValues.locations = contactDetails.locations.map(item => ({
      city: item.city,
      state: item.state,
      streetAddress: item.streetAddress,
      streetAddress2: item.streetAddress2,
      zipCode: item.zipCode,
    }));
  }

  if (contactDetails.phoneNumbers?.length) {
    initialValues.phoneNumbers = contactDetails.phoneNumbers.map(item => ({
      phoneNumber: item.phoneNumber,
    }));
  }

  const isAnyLocationGeocoded = contactDetails.locations?.some(location => !!location.point);

  return (
    <>
      <BreadcrumbsNavigation
        isLoading={isFetchingCustomFields}
        links={breadcrumbs}
      />
      <Container>
        <Grid container>
          <DescriptionBar
            icon={GroupAddIcon}
            name="Edit Contact"
          />
        </Grid>
        <EditContactWrapper>
          {isAnyLocationGeocoded && (
            <ContactMap
              coordinates={contactDetails?.locations[0].point.coordinates}
            />
          )}
          <StyledCard variant="outlined">
            <ContactForm
              cancelHref={`/${organizationUuid}/contacts/lists/${listUuid}/contact/${contactUuid}`}
              customFieldsList={customFieldsList}
              onSubmit={onSubmit}
              initialValues={initialValues}
            />
          </StyledCard>
        </EditContactWrapper>
      </Container>
      <Backdrop isOpen={isFetchingCustomFields} />
    </>
  );
};

export default EditContact;
