import PropTypes from 'prop-types';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import {
  Autocomplete,
  TextField,
} from '@mui/material';

import {
  signupTypes,
  typeOptions,
} from 'routes/CreateFilteredList/components/helpers';

const StyledAutocomplete = styled(Autocomplete)`
  padding-bottom: 10px;
`;

const SignupDetails = ({
  liveSignupsSource,
  pickedSignupsSource = [],
  setPickedSignupsSource,
}) => {
  const [
    type,
    setType,
  ] = useState(null);
  const [
    campaigns,
    setCampaigns,
  ] = useState([]);
  const [
    pickedCampaign,
    setPickedCampaign,
  ] = useState(null);

  useEffect(() => {
    if (liveSignupsSource.length) {
      setCampaigns(() => {
        const uniqueCampaigns = [];

        liveSignupsSource.forEach(item => {
          if (!uniqueCampaigns.some(campaign => campaign.campaignUuid === item.campaignUuid)) {
            uniqueCampaigns.push({
              campaignUuid: item.campaignUuid,
              name: item.campaignName,
            });
          }
        });

        return uniqueCampaigns;
      });
    }
  }, [liveSignupsSource]);

  const options = useCallback(
    () => {
      switch (type?.value) {
        case signupTypes.actionCenter:
          return liveSignupsSource
            .filter(signup => signup.type === signupTypes.actionCenter)
            .filter(signup => (pickedCampaign ?
              signup.campaignUuid === pickedCampaign.campaignUuid : true
            ));
        case signupTypes.signup:
          return liveSignupsSource.filter(signup => signup.type === signupTypes.signup);
        default:
          return liveSignupsSource;
      }
    },
    [
      type,
      pickedCampaign,
    ]
  );

  return (
    <>
      <StyledAutocomplete
        label="Type"
        getOptionLabel={option => option.name}
        fullWidth
        onChange={(_, pickedOption) => setType(pickedOption)}
        options={typeOptions}
        renderInput={params => (
          <TextField
            {...params}
            label="Type"
            variant="standard"
          />
        )}
        value={type}
      />

      {type?.value === signupTypes.actionCenter && (
        <StyledAutocomplete
          label="Campaigns"
          getOptionLabel={option => option.name}
          fullWidth
          onChange={(_, pickedOption) => setPickedCampaign(pickedOption)}
          options={campaigns}
          renderInput={params => (
            <TextField
              {...params}
              label="Campaigns (optional)"
              variant="standard"
            />
          )}
          value={pickedCampaign}
        />
      )}

      <StyledAutocomplete
        label=""
        getOptionLabel={option => option.name}
        fullWidth
        multiple
        onChange={(_, pickedOption) => {
          setPickedSignupsSource(pickedOption);
        }}
        options={options()}
        renderInput={params => (
          <TextField
            {...params}
            label=""
            variant="standard"
          />
        )}
        value={pickedSignupsSource}
      />
    </>
  );
};

SignupDetails.propTypes = {
  liveSignupsSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pickedSignupsSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setPickedSignupsSource: PropTypes.func.isRequired,
};

export default SignupDetails;
