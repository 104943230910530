import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import {
  Tab,
  Tabs,
} from '@mui/material';

import { tabsType } from 'common/typings';
import { useQuery } from 'utils/helpers';
import {
  colors,
} from 'utils/constants';

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${({ theme }) => (theme.colors.alto)};
  padding-top: 15px;
  color: ${colors.tableHeaderGray};
`;

const QueryIndexTabs = ({ tabs }) => {
  const history = useHistory();
  const query = useQuery();

  const tabIndex = query.get('tab');

  const handleSetTab = newValue => {
    history.push({
      search: `?tab=${newValue}`,
    });
  };

  return (
    <StyledTabs
      onChange={(_, newValue) => handleSetTab(newValue)}
      TabIndicatorProps={{
        style: {
          backgroundColor: colors.secondary,
        },
      }}
      textColor="inherit"
      value={tabIndex}
    >
      {tabs.map(tab => (
        <Tab
          disabled={tab.disabled}
          label={tab.label}
          key={tab.value}
          value={tab.value}
        />
      ))}
    </StyledTabs>
  );
};

QueryIndexTabs.propTypes = {
  tabs: tabsType.isRequired,
};

export default QueryIndexTabs;

