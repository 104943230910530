import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  ListItemText,
  MenuItem,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { organizationSelectors } from 'store/selectors/organizations';

import ButtonMenu from 'common/components/ButtonMenu';

const BroadcastMenuButton = () => {
  const { organizationUuid } = useParams();
  const history = useHistory();
  const {
    organization: {
      flags,
    },
  } = useSelector(state => ({
    organization: organizationSelectors.getOrganization(state),
  }));

  const handleCreateBroadcast = type => {
    history.push(`/${organizationUuid}/create-${type}-broadcast/`);
  };

  return (
    <ButtonMenu
      name="New Message"
      disabled={!flags?.showBroadcasts}
      color="secondary"
      startIcon={<AddIcon />}
      variant="contained"
    >
      <MenuItem onClick={() => handleCreateBroadcast('email')}>
        <ListItemText primary="Email Broadcast" />
      </MenuItem>
      <MenuItem
        disabled={!flags?.showTexting}
        onClick={() => handleCreateBroadcast('text')}
      >
        <ListItemText primary="Text Broadcast" />
      </MenuItem>
    </ButtonMenu>
  );
};

export default BroadcastMenuButton;
