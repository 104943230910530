import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import {
  useSelector,
} from 'react-redux';

import {
  Breadcrumbs,
  Grid,
  Link,
} from '@mui/material';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import { organizationSelectors } from 'store/selectors/organizations';

import { Popover } from 'common/components';

import Container from 'common/components/Container';

import { truncate } from 'utils/helpers';

const BreadcrumbsWrapper = styled(Grid)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0 2px;
  background-color: ${({ theme }) => theme.colors.pageBackground};
`;

const BreadcrumbsLink = styled(Link)`
  color: ${({
    theme,
    $current,
  }) => ($current ? theme.colors.primary : theme.colors.gray70)};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
`;

const Breadcrumb = ({
  as,
  current = false,
  text = '',
  to,
}) => (
  <Popover
    shouldDisplayPopover
    horizontalPosition="center"
    text={text}
  >
    <BreadcrumbsLink
      {...(as && { as })}
      {...(to && { component: RouterLink })}
      to={to}
      $current={current}
    >
      {truncate(text)}
    </BreadcrumbsLink>
  </Popover>
);

Breadcrumb.propTypes = {
  as: PropTypes.string,
  current: PropTypes.bool,
  text: PropTypes.string,
  to: PropTypes.string,
};

const BreadcrumbsNavigation = ({
  isLoading,
  links,
}) => {
  const {
    organization,
    organization: {
      name: organizationName,
    },
  } = useSelector(state => ({
    organization: organizationSelectors.getOrganization(state),
  }));

  return (
    <BreadcrumbsWrapper>
      <Container>
        <Breadcrumbs
          aria-label="breadcrumb"
        >
          <BreadcrumbsLink
            component={RouterLink}
            to="/"
          >
            <HomeOutlinedIcon sx={{ fontSize: 16 }} />
          </BreadcrumbsLink>
          {organization.uuid && (
            <Breadcrumb
              text={organizationName}
              to={`/${organization.uuid}/dashboard`}
            />
          )}
          {!isLoading && links.slice(0, -1).map(({
            link,
            name,
          }) => (
            <Breadcrumb
              key={`${name}-${link}`}
              text={name}
              to={link}
            />
          ))}
          {!isLoading && (
            <Breadcrumb
              as="p"
              current
              text={links[links.length - 1].name}
            />
          )}
        </Breadcrumbs>
      </Container>
    </BreadcrumbsWrapper>
  );
};

BreadcrumbsNavigation.defaultProps = {
  isLoading: false,
  links: [],
};

BreadcrumbsNavigation.propTypes = {
  isLoading: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    name: PropTypes.string,
  })),
};

export default BreadcrumbsNavigation;
