import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import {
  Cancel as CancelIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  Telegram as TelegramIcon,
} from '@mui/icons-material';

import {
  deleteTextActionAlert,
  fetchActionCenter,
  fetchTextActionAlert,
  fetchTextActionAlertRecipients,
  stopTextActionAlert,
} from 'store/actions/campaigns';
import {
  setSnackbarError,
  setSnackbarSuccess,
  setSnackbarWarning,
} from 'store/actions/global';
import { campaignsSelectors } from 'store/selectors/campaigns';

import {
  Backdrop,
  BreadcrumbsNavigation,
  ButtonMenu,
  Container,
  DescriptionBar,
  DescriptionBarInfo,
  RecipientsMap,
  RecipientsTable,
  StatisticCard,
  TabPanel,
  Tabs,
} from 'common/components';

import {
  actionTypes,
  cancellationStatuses,
  statuses,
} from 'utils/constants';
import { useQuery } from 'utils/helpers';
import {
  DetailsWrapper,
  DisabledListItemText,
  StatisticsWrapper,
  TabsWrapper,
} from 'globalStyles';

const ActionButtonsWrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const StyledPaper = styled(Paper)`
  width: 100%;
  border-bottom: 0;
`;

const ActionAlertTablesWrapper = styled.div`
  margin: 64px 0 82px;
`;

const {
  REACT_APP_LANDING_PAGE_URL,
} = process.env;

const TextActionAlertDetails = () => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const history = useHistory();
  const {
    actionAlertUuid,
    actionCenterUuid,
    campaignUuid,
    organizationUuid,
  } = useParams();
  const backUrl = useQuery().get('backUrl');
  const { draft } = statuses;

  const {
    actionAlertDetails,
    actionAlertRecipients,
    actionCenterDetails,
    isActionAlertFetching,
    isActionAlertRecipientsFetching,
  } = useSelector(state => ({
    actionAlertDetails: campaignsSelectors.getActionAlertDetails(state),
    actionAlertRecipients: campaignsSelectors.getActionAlertRecipients(state),
    actionCenterDetails: campaignsSelectors.getActionCenterDetails(state),
    isActionAlertFetching: campaignsSelectors.isActionAlertFetching(state),
    isActionAlertRecipientsFetching: campaignsSelectors.isActionAlertRecipientsFetching(state),
  }));

  useEffect(() => {
    if (Object.keys(actionCenterDetails).length === 0 ||
        actionCenterUuid !== actionCenterDetails.uuid
    ) {
      dispatch(fetchActionCenter(actionCenterUuid));
    }

    dispatch(fetchTextActionAlert(actionAlertUuid));
    dispatch(fetchTextActionAlertRecipients(actionAlertUuid));
  }, []);

  const [
    tabIndex,
    setTabIndex,
  ] = useState('recipients');

  const {
    campaign,
    contactLists,
    createdBy,
    isCancellable,
    message,
    name,
    sendAt,
    status,
  } = actionAlertDetails;

  const { probablyCancelled } = cancellationStatuses;

  const handleChangeTablesTab = newValue => {
    setTabIndex(newValue);
  };

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/campaigns`,
      name: 'Advocacy',
    },
    {
      link: `/${organizationUuid}/campaigns/${campaignUuid}`,
      name: campaign?.name,
    },
    {
      link: `/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}?tab=0`,
      name: actionCenterDetails?.name,
    },
    {
      link: '',
      name,
    },
  ];

  const getBackUrl = () => {
    if (backUrl) {
      return backUrl;
    }

    return `/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}?tab=0`;
  };

  const handleDeleteActionAlert = () => {
    confirm({
      confirmationText: 'Delete',
      description: 'Deleting this Action Alert will also remove any related recipient analytics.',
      title: 'Delete the Action Alert?',
    })
      .then(() => dispatch(deleteTextActionAlert(actionAlertUuid)))
      .then(() => history.push(`/${organizationUuid}/campaigns/${campaignUuid}/action-centers/${actionCenterUuid}?tab=0`));
  };

  const handleStopActionAlert = () => {
    dispatch(stopTextActionAlert(actionAlertUuid))
      .then(({
        message: stopMessage,
        status: stopedActionAlertStatus,
      }) => {
        if (stopedActionAlertStatus === probablyCancelled) {
          dispatch(setSnackbarWarning({ message: stopMessage }));
        } else {
          dispatch(setSnackbarSuccess({ message: stopMessage }));
        }
        dispatch(fetchTextActionAlert(actionAlertUuid));
      })
      .catch(({ message: stopMessage }) => dispatch(setSnackbarError({ message: stopMessage })));
  };

  const handleConfirmStopActionAlert = () => {
    confirm({
      cancellationText: 'No',
      confirmationText: 'Yes, Cancel',
      description: '',
      title: 'Cancel send the Action Alert?',
    })
      .then(() => handleStopActionAlert(actionAlertUuid));
  };

  const handleNavigateToWizard = type => {
    history.push(`/${organizationUuid}/${campaignUuid}/${actionCenterUuid}/create-text-action-alert/${type}/${actionAlertUuid}`);
  };

  const textActionAlertTabs = [
    {
      label: 'Recipients',
      value: 'recipients',
    },
  ];

  const DescriptionInfo = (
    <DescriptionBarInfo
      contactLists={contactLists}
      createdBy={createdBy}
      isButtonVisible
      link={`${REACT_APP_LANDING_PAGE_URL}/${actionCenterUuid}`}
      message={message}
      sendAt={sendAt}
      type={actionTypes.text}
    />
  );

  return (
    <>
      <BreadcrumbsNavigation links={breadcrumbs} />
      <Container>
        <Grid container>
          <DescriptionBar
            backButtonLink={getBackUrl()}
            descriptionBarInfo={DescriptionInfo}
            hasBackButton
            icon={TelegramIcon}
            isHighBar
            name={name}
          >
            <ActionButtonsWrapper>
              {isCancellable && (
                <StyledButton
                  startIcon={<CancelIcon />}
                  disabled={!isCancellable}
                  onClick={handleConfirmStopActionAlert}
                  variant="outlined"
                >
                  Cancel send
                </StyledButton>
              )}
              <ButtonMenu>
                <MenuItem
                  onClick={() => handleNavigateToWizard('edit')}
                  disabled={status !== draft}
                >
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <DisabledListItemText
                    isdisabled={status !== draft ? 1 : 0}
                    primary="Edit Action Alert"
                  />
                </MenuItem>
                <MenuItem onClick={() => handleNavigateToWizard('duplicate')}>
                  <ListItemIcon>
                    <FileCopyOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Duplicate" />
                </MenuItem>
                <MenuItem onClick={handleDeleteActionAlert}>
                  <ListItemIcon>
                    <DeleteOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delete" />
                </MenuItem>
              </ButtonMenu>
            </ActionButtonsWrapper>
          </DescriptionBar>
        </Grid>
        <DetailsWrapper>
          <StatisticsWrapper>
            <StatisticCard
              count={actionAlertRecipients?.length || 0}
              text="Recipients"
            />
          </StatisticsWrapper>
        </DetailsWrapper>
        <TabsWrapper>
          <StyledPaper
            square
            variant="outlined"
          >
            <Tabs
              handleSetTab={() => null}
              tabs={[
                {
                  label: 'Contacts',
                  value: 0,
                },
              ]}
              tabIndex={0}
            />
            <TabPanel
              index={0}
              value={0}
            >
              <RecipientsMap alertType="text" />
            </TabPanel>
          </StyledPaper>
        </TabsWrapper>
        <ActionAlertTablesWrapper>
          <StyledPaper
            square
            variant="outlined"
          >
            <Tabs
              handleSetTab={handleChangeTablesTab}
              tabs={textActionAlertTabs}
              tabIndex={tabIndex}
            />
          </StyledPaper>
          <TabPanel
            index={textActionAlertTabs[0].value}
            value={tabIndex}
          >
            <RecipientsTable
              isDataFetching={isActionAlertRecipientsFetching}
              isTextRecipients
              recipients={actionAlertRecipients}
            />
          </TabPanel>
        </ActionAlertTablesWrapper>
        <Backdrop isOpen={isActionAlertFetching} />
      </Container>
    </>
  );
};

export default TextActionAlertDetails;
