import { mapSettings } from 'utils/constants';
import {
  FETCH_BOUNDARIES_TOP_FAILURE,
  FETCH_BOUNDARIES_TOP_REQUEST,
  FETCH_BOUNDARIES_TOP_SUCCESS,
  FETCH_BOUNDARIES_TREE_FAILURE,
  FETCH_BOUNDARIES_TREE_REQUEST,
  FETCH_BOUNDARIES_TREE_SUCCESS,
  FETCH_BOUNDARY_FAILURE,
  FETCH_BOUNDARY_REQUEST,
  FETCH_BOUNDARY_SUCCESS,
  GET_CURRENT_POSITION_FAILURE,
  GET_CURRENT_POSITION_REQUEST,
  GET_CURRENT_POSITION_SUCCESS,
  SET_ORGANIZATION,
  SET_SNACKBAR_ERROR,
  SET_SNACKBAR_SUCCESS,
  SET_SNACKBAR_WARNING,
} from '../actions/global';

const initialState = {
  boundaries: [],
  boundariesTop: {
    children: [],
  },
  boundariesTree: {},
  errors: null,
  isBoundariesFetching: false,
  isBoundariesTopFetching: false,
  isBoundariesTreeFetching: false,
  isGeolocated: false,
  mapCenter: mapSettings.defaultCenter,
  organization: null,
  snackbarError: {
    message: 'Something went wrong',
  },
  snackbarSuccess: {
    message: 'Success!',
  },
  snackbarWarning: {
    message: 'Something might have gone wrong',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BOUNDARIES_TOP_REQUEST:
      return {
        ...state,
        errors: null,
        isBoundariesTopFetching: true,
      };
    case FETCH_BOUNDARIES_TOP_SUCCESS:
      return {
        ...state,
        boundariesTop: action.payload,
        isBoundariesTopFetching: false,
      };
    case FETCH_BOUNDARIES_TOP_FAILURE:
      return {
        ...state,
        boundariesTop: {
          children: [],
        },
        errors: action.payload,
        isBoundariesTopFetching: false,
      };
    case FETCH_BOUNDARIES_TREE_REQUEST:
      return {
        ...state,
        errors: null,
        isBoundariesTreeFetching: true,
      };
    case FETCH_BOUNDARIES_TREE_SUCCESS:
      return {
        ...state,
        boundariesTree: {
          ...state.boundariesTree,
          [action.payload.uuid]: action.payload.children,
        },
        isBoundariesTreeFetching: false,
      };
    case FETCH_BOUNDARIES_TREE_FAILURE:
      return {
        ...state,
        boundariesTree: {},
        errors: action.payload,
        isBoundariesTreeFetching: false,
      };
    case SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case FETCH_BOUNDARY_REQUEST:
      return {
        ...state,
        errors: null,
        isBoundariesFetching: true,
      };
    case FETCH_BOUNDARY_SUCCESS:
      return {
        ...state,
        boundaries: state.boundaries.concat(action.payload),
        isBoundariesFetching: false,
      };
    case FETCH_BOUNDARY_FAILURE:
      return {
        ...state,
        boundaries: [],
        errors: action.payload,
        isBoundariesFetching: false,
      };
    case GET_CURRENT_POSITION_REQUEST:
      return {
        ...state,
        errors: null,
      };
    case GET_CURRENT_POSITION_SUCCESS:
      return {
        ...state,
        isGeolocated: true,
        mapCenter: action.payload,
      };
    case GET_CURRENT_POSITION_FAILURE:
      return {
        ...state,
        errors: action.payload,
        mapCenter: initialState.mapCenter,
      };
    case SET_SNACKBAR_ERROR:
      return {
        ...state,
        snackbarError: action.payload || { message: 'Something went wrong' },
      };
    case SET_SNACKBAR_SUCCESS:
      return {
        ...state,
        snackbarSuccess: action.payload || { message: 'Success!' },
      };
    case SET_SNACKBAR_WARNING:
      return {
        ...state,
        snackbarWarning: action.payload || { message: 'Something might have gone wrong!' },
      };
    default:
      return state;
  }
};
