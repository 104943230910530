import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Dialog as MaterialDialog,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { CircularProgressWrapper } from 'globalStyles';
import { formatPhoneNumber } from 'utils/helpers';
import { colors } from 'utils/constants';

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  color: #888;
`;

const DialogErrorText = styled(DialogContentText)`
  color: ${colors.warning};
`;

const DetailsItem = styled.div`
  margin-bottom: 16px;
`;

const ContactDialog = ({
  isError,
  handleDialogClose,
  isDialogOpen,
  isFetching,
  contactDetails,
}) => (
  <MaterialDialog
    aria-labelledby="dialog-title"
    onClose={handleDialogClose}
    fullWidth
    open={isDialogOpen}
  >
    <DialogTitle>
      <Typography variant="h6">Contact details</Typography>
      <CloseButton
        aria-label="close"
        onClick={handleDialogClose}
      >
        <CloseIcon />
      </CloseButton>
    </DialogTitle>
    <DialogContent dividers>
      {isError && (
        <DialogErrorText>
          An error occured while fetching the contact details.
          Please try again or contact the technical support team.
        </DialogErrorText>
      )}
      {isFetching ?
        (
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        ) :
        (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {contactDetails && (
              <div>
                {(contactDetails.firstName || contactDetails.lastName) && (
                  <DetailsItem>
                    <Typography variant="h6">Name</Typography>
                    <Typography>
                      {`${contactDetails.firstName} ${contactDetails.lastName}`}
                    </Typography>
                  </DetailsItem>
                )}
                {!!contactDetails.locations?.length && (
                  <DetailsItem>
                    <Typography variant="h6">Locations</Typography>
                    {contactDetails.locations?.map(location => (
                      <Typography key={location.uuid}>
                        {`${location.streetAddress} ${location.streetAddress2} ${location.city} ${location.state} ${location.zipCode}`}
                      </Typography>
                    ))}
                  </DetailsItem>
                )}
                {!!contactDetails.phoneNumbers?.length && (
                  <DetailsItem>
                    <Typography variant="h6">Phones</Typography>
                    {contactDetails.phoneNumbers?.map(phoneNumber => (
                      <Typography key={phoneNumber.uuid}>
                        {formatPhoneNumber(phoneNumber.phoneNumber)}
                      </Typography>
                    ))}
                  </DetailsItem>
                )}
                {!!contactDetails.emails?.length && (
                  <DetailsItem>
                    <Typography variant="h6">Emails</Typography>
                    {contactDetails.emails?.map(email => (
                      <Typography key={email.uuid}>
                        {email.email}
                      </Typography>
                    ))}
                  </DetailsItem>
                )}
                {!!contactDetails.customFields?.length && (
                  <DetailsItem>
                    <Typography variant="h6">Custom fields</Typography>
                    {contactDetails.customFields?.map(customField => (
                      <Typography key={customField.uuid}>
                        <strong>{`${customField.label}: `}</strong>
                        {customField.value}
                      </Typography>
                    ))}
                  </DetailsItem>
                )}
              </div>
            )}
          </>
        )
      }
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        onClick={handleDialogClose}
        type="button"
      >
        Close
      </Button>
    </DialogActions>
  </MaterialDialog>
);

ContactDialog.defaultProps = {
  isError: false,
};

ContactDialog.propTypes = {
  contactDetails: PropTypes.shape({
    customFields: PropTypes.arrayOf(PropTypes.shape({})),
    emails: PropTypes.arrayOf(PropTypes.shape({})),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({})),
    phoneNumbers: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
};

export default ContactDialog;
