/* stylelint-disable color-function-notation */
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import arrayMutators from 'final-form-arrays';
import {
  Field,
  Form,
} from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Grid } from '@mui/material';

import {
  addLocation,
  updateLocation,
} from 'store/actions/contactDetails';

import { UserDialog } from 'common/components';
import {
  colors,
  usStates,
} from 'utils/constants';
import {
  handlefinalFormParseIdentity,
  handleOnlyNumbers,
  handleZipcodeFormat,
} from 'utils/helpers';

const StyledField = styled(Field)`
  width: 100%;
  height: 40px;
  box-shadow: rgba(224, 223, 224, 60%) 0 1px 3px 0 inset;
  border: 1px solid ${colors.mercury};
  border-radius: 5px;
  padding: 10px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  box-shadow: rgba(224, 223, 224, 60%) 0 1px 3px 0 inset;
  border: 1px solid ${colors.mercury};
  border-radius: 5px;
  padding: 10px;
`;

const ErrorMessage = styled.span`
  color: ${colors.error};
`;

const Label = styled.label`
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-size: 18px;
`;

const LocationsForm = ({
  contactId,
  handleDialogClose,
  isDialogOpen,
  listId,
  editedLocation,
}) => {
  const dispatch = useDispatch();

  const onSubmit = values => {
    const params = { ...values };
    const { locations = [] } = params;

    try {
      if (editedLocation?.uuid) {
        return dispatch(updateLocation(listId, contactId, editedLocation.uuid, {
          ...locations[0],
          zipCode: handleOnlyNumbers(locations[0].zip_code),
        }))
          .then(() => {
            handleDialogClose();

            return true;
          });
      }

      return dispatch(addLocation(listId, contactId, locations[0]))
        .then(() => {
          handleDialogClose();

          return true;
        });
    } catch (error) {
      return error;
    }
  };

  const {
    streetAddress,
    streetAddress2,
    zipCode,
    city,
    state,
  } = editedLocation || {};

  const initialValues = {
    locations: [
      {
        city,
        state,
        street_address: streetAddress,
        street_address_2: streetAddress2,
        zip_code: handleZipcodeFormat(zipCode),
      },
    ],
  };

  return (
    <UserDialog
      contactId={contactId}
      handleDialogClose={handleDialogClose}
      isDialogOpen={isDialogOpen}
      isLocationDialog
      listId={listId}
      title={editedLocation?.uuid ? 'Editing a location' : 'Adding a new location'}
    >
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form
            className="location-form"
            onSubmit={handleSubmit}
          >
            <Grid
              container
              spacing={3}
            >
              <FieldArray name="locations">
                {() => (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={6}
                    >
                      <Label>Street Address</Label>
                      <Field
                        name="locations[0].street_address"
                        parse={handlefinalFormParseIdentity}
                      >
                        {({
                          input,
                          meta,
                        }) => (
                          <>
                            {(meta.error || meta.submitError) &&
                              meta.touched &&
                              <ErrorMessage>{meta.error || meta.submitError}</ErrorMessage>}
                            <StyledInput
                              {...input}
                              type="text"
                              placeholder="Street Address"
                            />
                          </>
                        )}
                      </Field>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                    >
                      <Label>Street Address Two</Label>
                      <Field
                        name="locations[0].street_address_2"
                        parse={handlefinalFormParseIdentity}
                      >
                        {({
                          input,
                          meta,
                        }) => (
                          <>
                            {(meta.error || meta.submitError) &&
                              meta.touched &&
                              <ErrorMessage>{meta.error || meta.submitError}</ErrorMessage>}
                            <StyledInput
                              {...input}
                              type="text"
                              placeholder="Street Address Two"
                            />
                          </>
                        )}
                      </Field>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                    >
                      <Label>City</Label>
                      <Field name="locations[0].city">
                        {({
                          input,
                          meta,
                        }) => (
                          <>
                            {(meta.error || meta.submitError) &&
                          meta.touched &&
                          <ErrorMessage>{meta.error || meta.submitError}</ErrorMessage>}
                            <StyledInput
                              {...input}
                              type="text"
                              placeholder="City"
                            />
                          </>
                        )}
                      </Field>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                    >
                      <Label>State</Label>
                      <StyledField
                        name="locations[0].state"
                        component="select"
                      >
                        <option>&nbsp;</option>
                        {usStates.map(({
                          abbreviation,
                          name,
                        }) => (
                          <option
                            key={abbreviation}
                            value={abbreviation}
                          >
                            {name}
                          </option>
                        ))}
                      </StyledField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                    >
                      <Label>Zip Code</Label>
                      <Field
                        name="locations[0].zip_code"
                        parse={handleZipcodeFormat}
                      >
                        {({
                          input,
                          meta,
                        }) => (
                          <>
                            {(meta.error || meta.submitError) && meta.touched &&
                            <ErrorMessage>{meta.error || meta.submitError}</ErrorMessage>}
                            <StyledInput
                              {...input}
                              type="text"
                              placeholder="Zip Code"
                            />
                          </>
                        )}
                      </Field>
                    </Grid>
                  </>
                )}
              </FieldArray>
            </Grid>
          </form>
        )}
      />
    </UserDialog>
  );
};

LocationsForm.defaultProps = {
  contactId: '',
  handleDataUpdate: () => {},
  listId: '',
  listName: '',
};

LocationsForm.propTypes = {
  contactId: PropTypes.string,
  editedLocation: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    streetAddress: PropTypes.string,
    streetAddress2: PropTypes.string,
    uuid: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  handleDataUpdate: PropTypes.func,
  handleDialogClose: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  listId: PropTypes.string,
  listName: PropTypes.string,
};

export default LocationsForm;
