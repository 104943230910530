import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Link as RouterLink,
  useParams,
} from 'react-router-dom';

import {
  Button,
  Card,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';

import { DetailsButton } from 'common/components';

const ActionsCountWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  padding-top: 15px 0 0 0;

  & > * {
    margin-right: 8px;
  }
`;

const StyledCard = styled(Card)`
  overflow-x: auto;
  padding: 32px 26px;

  &:not(:last-of-type) {
    border-bottom: 0;
  }
`;

const StyledGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

const StyledName = styled.div`
  display: flex;
  align-items: center;
`;

const { REACT_APP_LANDING_PAGE_URL } = process.env;

const SignupItem = ({
  handleRemoveSignup,
  name,
  publishStatus,
  uuid,
}) => {
  const {
    organizationUuid,
  } = useParams();

  return (
    <StyledCard
      variant="outlined"
      square
    >
      <StyledGrid
        container
        item
        wrap="nowrap"
      >
        <StyledName>
          <Link
            component={RouterLink}
            to={`/${organizationUuid}/signups/${uuid}?tab=0`}
          >
            <Typography variant="h5">{name}</Typography>
          </Link>
        </StyledName>
        <ActionsCountWrapper>
          {publishStatus === 2 && (
            <a
              href={`${REACT_APP_LANDING_PAGE_URL}/signup/${uuid}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View live
            </a>
          )}
          <DetailsButton to={`/${organizationUuid}/signups/${uuid}?tab=0`} />
          <Button
            onClick={() => handleRemoveSignup(uuid)}
            size="small"
            startIcon={<DeleteOutlineIcon />}
            variant="outlined"
          >
            Delete
          </Button>
        </ActionsCountWrapper>
      </StyledGrid>
    </StyledCard>
  );
};

SignupItem.propTypes = {
  handleRemoveSignup: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  publishStatus: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default SignupItem;
