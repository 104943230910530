import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useConfirm } from 'material-ui-confirm';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import { deletePhoneNumber } from 'store/actions/contactDetails';

import { formatPhoneNumber } from 'utils/helpers';

const StyledTableCell = styled(TableCell)`
  width: 50%;
  padding-left: 0;
`;

const PhonesTable = ({
  contactId,
  listId,
  phoneNumbers,
  onEditClick,
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const handlePhoneNumberDelete = phoneNumberId => {
    confirm({
      confirmationText: 'Delete',
      description: 'This action will delete selected phone number',
      title: 'Delete phone number?',
    })
      .then(() => dispatch(deletePhoneNumber(listId, contactId, phoneNumberId)));
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Phone number</StyledTableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {phoneNumbers.map(number => (
            <TableRow key={number.uuid}>
              <StyledTableCell>{formatPhoneNumber(number.phoneNumber)}</StyledTableCell>
              <TableCell>
                <IconButton
                  aria-label="edit"
                  onClick={() => onEditClick(number)}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handlePhoneNumberDelete(number.uuid)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PhonesTable.defaultProps = {
  phoneNumbers: [],
};

PhonesTable.propTypes = {
  contactId: PropTypes.string.isRequired,
  listId: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired,
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
};

export default PhonesTable;
