import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';

import { getCurrentPosition } from 'store/actions/global';
import { globalSelectors } from 'store/selectors/global';
import { mapSettings } from 'utils/constants';
import { addFullScreenMapButton } from 'utils/helpers';

import MapBoxContainer from './MapBoxContainer';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

const { REACT_APP_MAPBOX_API_KEY } = process.env;

mapboxgl.accessToken = REACT_APP_MAPBOX_API_KEY;

const MapWrapper = styled.div`
  width: 100%;
  height: 300px;
`;

const MapBox = ({
  className,
  coordinates,
  isFullPage,
  mapContainer,
  map,
}) => {
  const {
    mapCenter,
  } = useSelector(state => ({
    mapCenter: globalSelectors.getMapCenter(state),
  }));
  const dispatch = useDispatch();
  const isGeolocated = useSelector(globalSelectors.getIsGeolocated);

  useEffect(() => {
    if (mapContainer?.current && (!map.current || mapContainer.current.innerHTML === '')) {
      // eslint-disable-next-line no-param-reassign
      map.current = new mapboxgl.Map({
        center: [
          coordinates[0] || mapCenter.lng,
          coordinates[1] || mapCenter.lat,
        ],
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        zoom: mapSettings.zoom,
      });

      addFullScreenMapButton(map.current);

      if (!isGeolocated) {
        dispatch(getCurrentPosition(map.current));
      }
    }
  }, [
    map,
    mapContainer,
  ]);

  return (
    <MapWrapper className={className}>
      <MapBoxContainer
        isFullPage={isFullPage}
        mapContainer={mapContainer}
      />
    </MapWrapper>
  );
};

MapBox.defaultProps = {
  className: '',
  coordinates: [],
  isFullPage: false,
  map: null,
  mapContainer: null,
};

MapBox.propTypes = {
  className: PropTypes.string,
  coordinates: PropTypes.arrayOf(PropTypes.number),
  isFullPage: PropTypes.bool,
  map: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  mapContainer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default MapBox;
