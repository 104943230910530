import {
  DELETE_MATCHED_TARGETS,
  FETCH_ACTION_CENTER_FAILURE,
  FETCH_ACTION_CENTER_REQUEST,
  FETCH_ACTION_CENTER_SUCCESS,
  FETCH_ADDRESS_SUGGESTIONS_FAILURE,
  FETCH_ADDRESS_SUGGESTIONS_REQUEST,
  FETCH_ADDRESS_SUGGESTIONS_SUCCESS,
  FETCH_CONTACT_INFO_FAILURE,
  FETCH_CONTACT_INFO_REQUEST,
  FETCH_CONTACT_INFO_SUCCESS,
  FETCH_MATCHED_TARGETS_FAILURE,
  FETCH_MATCHED_TARGETS_REQUEST,
  FETCH_MATCHED_TARGETS_SUCCESS,
  POST_SEND_MESSAGE_FAILURE,
  POST_SEND_MESSAGE_REQUEST,
  POST_SEND_MESSAGE_SUCCESS,
} from '../actions/campaignsPublic';

const initialState = {
  actionCenterDetails: {},
  addressSuggestions: [],
  contactInfo: {
    email: '',
    firstName: '',
    isOptInEmail: false,
    isOptInText: false,
    lastName: '',
    phoneNumber: '',
    prefix: '',
    streetAddress: '',
    streetAddress2: '',
    uuid: '',
    zipCode: '',
  },
  errors: null,
  isActionCenterFetching: false,
  isAutocompletePending: false,
  isContactDataFetching: false,
  isPending: false,
  isTargetsFetching: false,
  matchedTargets: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTION_CENTER_REQUEST:
      return {
        ...state,
        errors: null,
        isActionCenterFetching: true,
      };
    case FETCH_ACTION_CENTER_SUCCESS:
      return {
        ...state,
        actionCenterDetails: action.payload,
        isActionCenterFetching: false,
      };
    case FETCH_ACTION_CENTER_FAILURE:
      return {
        ...state,
        actionCenterDetails: {},
        errors: action.payload,
        isActionCenterFetching: false,
      };
    case FETCH_MATCHED_TARGETS_REQUEST:
      return {
        ...state,
        isTargetsFetching: true,
      };
    case FETCH_MATCHED_TARGETS_SUCCESS:
      return {
        ...state,
        errors: null,
        isTargetsFetching: false,
        matchedTargets: action.payload,
      };
    case FETCH_MATCHED_TARGETS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isTargetsFetching: false,
        matchedTargets: [],
      };
    case POST_SEND_MESSAGE_REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case POST_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        errors: null,
        isPending: false,
      };
    case POST_SEND_MESSAGE_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isPending: false,
      };
    case FETCH_CONTACT_INFO_REQUEST:
      return {
        ...state,
        isContactDataFetching: true,
      };
    case FETCH_CONTACT_INFO_SUCCESS:
      return {
        ...state,
        contactInfo: action.payload,
        errors: null,
        isContactDataFetching: false,
      };
    case FETCH_CONTACT_INFO_FAILURE:
      return {
        ...state,
        contactInfo: {},
        errors: action.payload,
        isContactDataFetching: false,
      };
    case FETCH_ADDRESS_SUGGESTIONS_REQUEST:
      return {
        ...state,
        isAutocompletePending: true,
      };
    case FETCH_ADDRESS_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        addressSuggestions: action.payload || [],
        errors: null,
        isAutocompletePending: false,
      };
    case FETCH_ADDRESS_SUGGESTIONS_FAILURE:
      return {
        ...state,
        addressSuggestions: [],
        errors: action.payload,
        isAutocompletePending: false,
      };
    case DELETE_MATCHED_TARGETS:
      return {
        ...state,
        matchedTargets: [],
      };
    default:
      return state;
  }
};

