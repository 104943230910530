import PropTypes from 'prop-types';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  ListItemText,
  MenuItem,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { Add as AddIcon } from '@mui/icons-material';

import { organizationSelectors } from 'store/selectors/organizations';

import ButtonMenu from 'common/components/ButtonMenu';

const CreateMessageButton = ({ alertDialogHandler }) => {
  const { organizationUuid } = useParams();
  const history = useHistory();
  const {
    organization: {
      flags,
    },
  } = useSelector(state => ({
    organization: organizationSelectors.getOrganization(state),
  }));

  const handleCreateBroadcast = type => {
    history.push(`/${organizationUuid}/create-${type}-broadcast/`);
  };

  return (
    <ButtonMenu
      name="New Message"
      disabled={!flags?.showBroadcasts}
      color="primary"
      startIcon={<AddIcon />}
      variant="contained"
    >
      <MenuItem onClick={() => handleCreateBroadcast('email')}>
        <ListItemText primary="Email Broadcast" />
      </MenuItem>
      <MenuItem
        disabled={!flags?.showTexting}
        onClick={() => handleCreateBroadcast('text')}
      >
        <ListItemText primary="Text Broadcast" />
      </MenuItem>
      <Divider />
      <MenuItem
        disabled={!flags?.showActionAlerts}
        onClick={() => alertDialogHandler('email')}
      >
        <ListItemText primary="Email Action Alert" />
      </MenuItem>
      <MenuItem
        disabled={!flags?.showActionAlerts || !flags?.showTexting}
        onClick={() => alertDialogHandler('text')}
      >
        <ListItemText primary="Text Action Alert" />
      </MenuItem>
    </ButtonMenu>
  );
};

CreateMessageButton.propTypes = {
  alertDialogHandler: PropTypes.func.isRequired,
};

export default CreateMessageButton;
