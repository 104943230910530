import {
  array,
  arrayOf,
  bool,
  number,
  object,
  objectOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';

export const templateType = shape({
  body: shape({
    body: objectOf(oneOfType([
      object,
      array,
      string,
    ])),
    counters: objectOf(number),
    schemaVersion: number,
  }),
  name: string,
});

export const contentBlockType = shape({
  isAllowEdit: bool,
  name: string,
});

export const tabsType = arrayOf(
  shape({
    label: string,
    value: oneOfType([
      string,
      number,
    ]),
  })
);

export const targetsType = arrayOf(
  shape({
    firstName: string,
    lastName: string,
  })
);

export const contactListType = shape({
  count: number,
  listGroups: arrayOf(string),
  name: string,
  parent: oneOfType([
    string,
    number,
  ]),
  type: string,
  uuid: string,
});

export const imageType = shape({
  data_url: string,
  file: shape({
    name: string,
  }),
});

export const locationType = shape({
  city: string,
  state: string,
  streetAddress: string,
  streetAddress2: string,
  zipCode: string,
});

export const customFieldType = shape({
  fieldType: string,
  label: string,
  options: arrayOf(string),
  placeholder: string,
  uuid: string,
});
