import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Menu,
} from '@mui/material';
import {
  Settings as SettingsIcon,
} from '@mui/icons-material';

const ButtonMenu = ({
  children,
  className,
  color,
  disabled,
  endIcon,
  name,
  variant,
  startIcon,
}) => {
  const [
    anchorSettingsElement,
    setAnchorSettingsElement,
  ] = useState(null);

  const handleSettingsClick = event => setAnchorSettingsElement(event.currentTarget);
  const handleSettingsClose = () => setAnchorSettingsElement(null);

  return (
    <>
      <Button
        aria-controls={name}
        aria-haspopup="true"
        className={className}
        color={color}
        disableElevation
        disabled={disabled}
        endIcon={endIcon}
        onClick={handleSettingsClick}
        startIcon={startIcon}
        variant={variant}
      >
        {name}
      </Button>
      <Menu
        anchorEl={anchorSettingsElement}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        id={name}
        keepMounted
        onClose={handleSettingsClose}
        open={Boolean(anchorSettingsElement)}
      >
        <div>
          {typeof (children) === 'function' ? children(handleSettingsClose) : children}
        </div>
      </Menu>
    </>
  );
};

ButtonMenu.defaultProps = {
  className: '',
  color: 'inherit',
  disabled: false,
  endIcon: null,
  name: 'Settings',
  startIcon: <SettingsIcon />,
  variant: 'outlined',
};

ButtonMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  name: PropTypes.string,
  startIcon: PropTypes.node,
  variant: PropTypes.string,
};

export default ButtonMenu;
