import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useDispatch,
} from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

import {
  patchCampaign,
} from 'store/actions/campaigns';

import { Dialog } from 'common/components';

const RenameCampaignDialog = ({
  campaignName,
  handleSettingsClose,
  isPending,
}) => {
  const dispatch = useDispatch();

  const {
    campaignUuid,
  } = useParams();
  const [
    campaignNameValue,
    setCampaignNameValue,
  ] = useState('');
  const [
    isRenameDialogOpen,
    setIsRenameDialogOpen,
  ] = useState(false);

  const handleConfirm = async () => {
    await dispatch(
      patchCampaign(campaignUuid, {
        name: campaignNameValue,
      })
    );

    setCampaignNameValue('');
    setIsRenameDialogOpen(false);
  };

  const handleDialogClose = () => {
    setCampaignNameValue('');
    setIsRenameDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setIsRenameDialogOpen(true);
    handleSettingsClose();
  };

  return (
    <MenuItem onClick={handleDialogOpen}>
      <ListItemIcon>
        <EditIcon />
      </ListItemIcon>
      <ListItemText primary="Rename Campaign" />
      <Dialog
        handleConfirm={handleConfirm}
        handleDialogClose={handleDialogClose}
        isDialogOpen={isRenameDialogOpen}
        title="Rename Campaign"
        disabledButton={isPending}
      >
        <TextField
          fullWidth
          placeholder="Rename Campaign"
          onChange={event => setCampaignNameValue(event.target.value)}
          type="text"
          defaultValue={campaignName}
        />
      </Dialog>
    </MenuItem>
  );
};

RenameCampaignDialog.defaultProps = {
  campaignName: '',
};

RenameCampaignDialog.propTypes = {
  campaignName: PropTypes.string,
  handleSettingsClose: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default RenameCampaignDialog;
