import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  TextField,
  Typography,
} from '@mui/material';

const StyledTextField = styled(TextField)`
  width: 300px;
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 10px;
`;

const StepOne = ({
  listName,
  setListName,
}) => (
  <>
    <StyledTypography variant="h5">Type List Name</StyledTypography>
    <StyledTextField
      value={listName}
      label="Filtered List Name"
      onChange={event => setListName(event.target.value)}
      variant="standard"
    />
  </>
);

StepOne.propTypes = {
  listName: PropTypes.string.isRequired,
  setListName: PropTypes.func.isRequired,
};

export default StepOne;
