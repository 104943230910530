import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';

import { Asterisk } from 'globalStyles';

const StyledCard = styled(Card)`
  padding: 40px 30px;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  min-height: 68px;
  padding: 0;
`;

const StyledTypography = styled(Typography)`
  padding-right: 15px;
  white-space: nowrap;
`;

const DescriptionText = styled(Typography)`
  padding-top: 10px;
`;

const StyledCardActions = styled(CardActions)`
  flex-flow: ${props => `${props.column ? 'column' : 'row'} ${props.flexwrap ? 'wrap' : 'nowrap'}`};
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  align-items: ${props => (props.topalign ? 'flex-start' : 'center')};
  padding: 0;
`;

const CardField = ({
  center,
  children,
  column,
  descriptionText,
  flexwrap,
  name,
  required,
  topAlign,
}) => (
  <StyledCard variant="outlined">
    { name && (
      <StyledCardContent>
        <StyledTypography
          variant="h5"
          gutterBottom
        >
          {name}
          {required && <Asterisk />}
        </StyledTypography>
        <DescriptionText
          variant="body2"
          color="textSecondary"
        >
          {descriptionText}
        </DescriptionText>
      </StyledCardContent>
    )}
    <StyledCardActions
      center={center ? 1 : 0}
      column={column ? 1 : 0}
      flexwrap={flexwrap ? 1 : 0}
      topalign={topAlign ? 1 : 0}
    >
      {children}
    </StyledCardActions>
  </StyledCard>
);

CardField.defaultProps = {
  center: undefined,
  column: false,
  descriptionText: null,
  flexwrap: undefined,
  name: null,
  required: false,
  topAlign: false,
};

CardField.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
  column: PropTypes.bool,
  descriptionText: PropTypes.string,
  flexwrap: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  topAlign: PropTypes.bool,
};

export default CardField;
