import styled from 'styled-components';

import { Typography } from '@mui/material';

import mediaQueries from 'utils/mediaQueries';
import { Chip } from 'common/components';

const { small } = mediaQueries;

export const CardHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 50%;

  @media ${small} {
    max-width: 100%;
  }
`;

export const CardTitle = styled(Typography)`
  font-weight: 400;
  font-size: 24px;
`;

export const StyledChip = styled(Chip)`
  margin-left: 15px;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
`;

export const CardDateInfo = styled.p`
  color: ${({ theme }) => theme.colors.osloGray};
`;

export const DetailsList = styled.ul`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 1fr;
  row-gap: 8px;
  column-gap: 16px;
  padding: 0;
  list-style-type: none;
  color: ${({ theme }) => theme.colors.osloGray};

  li {
    display: contents;

    div {
      color: ${({ theme }) => theme.colors.gray70};
    }
  }
`;

export const CardTypes = styled.ul`
  display: flex;
  padding: 0;
  list-style-type: none;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  font-size: 14px;

  li {
    &:not(:last-child)::after {
      content: "|";
      margin: 0 8px;
      color: ${({ theme }) => theme.colors.osloGray};
    }
  }
`;
