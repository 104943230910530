import PropTypes from 'prop-types';
import styled from 'styled-components';

const InputWrapperStyles = styled.div`
  position: relative;

  label {
    overflow-x: hidden;
    display: flex;
    flex-direction: column-reverse;
    font-size: 12px;
  }

  input,
  textarea {
    margin-top: 4px;
  }

  input,
  textarea,
  select {
    box-sizing: border-box;
    outline: 0;
    border: 1px solid rgba(24, 24, 24, 0.1);
    border-radius: 4px;
    padding: 14px 16px;
    font-size: 16px;
    line-height: 28px;

    &:disabled {
      background: #fff;
      color: rgba(24, 24, 24, 0.4);
    }
  }

  select:disabled {
    margin-top: 21px;
    appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.5898 0.589798L5.99976 5.1698L1.40976 0.589798L-0.000244141 1.9998L5.99976 7.9998L11.9998 1.9998L10.5898 0.589798Z' fill='black'/></svg>");
    background-position: top 25px right 22px;
    background-repeat: no-repeat;
  }

  span {
    transition: all 0.2s;
    transform-origin: top left;
  }

  input[placeholder=" "]:not(:focus):placeholder-shown + span {
    opacity: 0.5;
    transform: translateY(41px) translateX(12px) scale(1.33);
    pointer-events: none;
  }
`;

const InputWrapper = ({
  children,
  name,
}) => (
  <InputWrapperStyles>
    <label htmlFor={name}>
      {children}
    </label>
  </InputWrapperStyles>
);

InputWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export const LandingPageSelect = ({
  name,
}) => (
  <InputWrapper name={name}>
    <select
      disabled
      name={name}
      value=""
    >
      <option
        value=""
        disabled
        hidden
      >
        {name}
      </option>
    </select>
  </InputWrapper>
);

LandingPageSelect.propTypes = {
  name: PropTypes.string.isRequired,
};

export const LandingPageInput = ({
  name,
  type,
}) => (
  <InputWrapper name={name}>
    <input
      disabled
      type={type}
      name={name}
      placeholder=" "
    />
    <span>{name}</span>
  </InputWrapper>
);

LandingPageInput.defaultProps = {
  type: 'text',
};

LandingPageInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
};
