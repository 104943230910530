import {
  useEffect,
  useState,
} from 'react';
import {
  Link as RouterLink,
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { useConfirm } from 'material-ui-confirm';
import {
  Button,
  Grid,
  Link,
} from '@mui/material';
import {
  Assignment as AssignmentIcon,
  Event as EventIcon,
} from '@mui/icons-material';

import {
  deleteSignup,
  fetchAllSignups,
} from 'store/actions/signups';
import { signupsSelectors } from 'store/selectors/signups';
import { setSnackbarSuccess } from 'store/actions/global';

import {
  Backdrop,
  BreadcrumbsNavigation,
  Container,
  DescriptionBar,
} from 'common/components';
import SignupItem from '../components/SignupItem';

const Signups = () => {
  const dispatch = useDispatch();

  const {
    organizationUuid,
  } = useParams();

  const {
    isFetching,
  } = useSelector(state => ({
    isFetching: signupsSelectors.isFetching(state),
  }));

  const [
    signups,
    setSignups,
  ] = useState([]);

  const breadcrumbs = [
    {
      link: `/${organizationUuid}/contacts/lists`,
      name: 'Lists',
    },
    {
      link: '',
      name: 'Contact Forms',
    },
  ];

  useEffect(() => {
    dispatch(fetchAllSignups(organizationUuid))
      .then(data => {
        setSignups(data || []);
      });
  }, []);

  const confirm = useConfirm();

  const handleRemoveSignup = signupUuid => {
    confirm({
      confirmationText: 'Remove',
      title: 'Remove Contact Form?',
    })
      .then(() => dispatch(deleteSignup(signupUuid)))
      .then(() => dispatch(fetchAllSignups(organizationUuid)))
      .then(data => setSignups(data || []))
      .then(() => dispatch(setSnackbarSuccess()));
  };

  return (
    <>
      <BreadcrumbsNavigation
        isLoading={isFetching}
        links={breadcrumbs}
      />
      <Container marginBottom="40">
        <Grid container>
          <DescriptionBar
            name="Contact Forms"
            icon={AssignmentIcon}
          >
            <Button
              disableElevation
              color="secondary"
              component={RouterLink}
              startIcon={<EventIcon />}
              to={`/${organizationUuid}/create-signup`}
              variant="contained"
            >
              Create New Contact Form
            </Button>
          </DescriptionBar>
        </Grid>
        {!isFetching &&
        !signups?.length ? (
          <Link
            component={RouterLink}
            color="inherit"
            to={`/${organizationUuid}/create-signup`}
          >
            <span>Create your first Contact Form</span>
          </Link>
        ) : signups?.map(signupData => (
          <SignupItem
            handleRemoveSignup={handleRemoveSignup}
            name={signupData.name}
            key={signupData.uuid}
            publishStatus={signupData.publishStatus}
            uuid={signupData.uuid}
          />
          ))
        }
      </Container>
      <Backdrop isOpen={isFetching} />
    </>
  );
};

export default Signups;
