import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  TextField,
} from '@mui/material';

import { meetingsSelectors } from 'store/selectors/meetings';

import {
  CircularProgress,
  Dialog,
} from 'common/components';

import { showFirstErrorMessage } from 'utils/helpers';
import { ErrorTypography } from 'globalStyles';

const CustomTextField = styled(TextField)`
  width: 100%;
  margin: 10px 0;
`;

const MeetingDialog = ({
  editedMeeting,
  handleDialogClose,
  handleUpdateMeeting,
  isPendingMeeting,
  setEditedMeeting,
}) => {
  const {
    meetingError,
  } = useSelector(state => ({
    meetingError: meetingsSelectors.meetingError(state),
  }));

  const {
    isModalOpened,
    uuid,
  } = editedMeeting;

  return (
    <Dialog
      handleConfirm={handleUpdateMeeting}
      handleDialogClose={handleDialogClose}
      disabledButton={isPendingMeeting || !editedMeeting?.title}
      isDialogOpen={isModalOpened}
      title={`${uuid ? 'Edit' : 'Add'} Meeting`}
    >
      {isModalOpened ? (
        <>
          <CustomTextField
            fullWidth
            placeholder="Meeting title"
            onChange={event => setEditedMeeting(prevValue => ({
              ...prevValue,
              label: event.target.value,
            }))}
            label="Meeting title"
            type="text"
            required
            value={editedMeeting?.title}
          />
          <CustomTextField
            fullWidth
            placeholder="Meeting notes"
            label="Meeting notes"
            onChange={event => setEditedMeeting(prevValue => ({
              ...prevValue,
              description: event.target.value,
            }))}
            type="text"
            value={editedMeeting?.description}
          />
          {meetingError && (
            <ErrorTypography>{showFirstErrorMessage(meetingError)}</ErrorTypography>
          )}
        </>
      ) : (
        <CircularProgress />
      )}
    </Dialog>
  );
};

MeetingDialog.propTypes = {
  editedMeeting: PropTypes.shape({
    description: PropTypes.string,
    isModalOpened: PropTypes.bool,
    title: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  handleUpdateMeeting: PropTypes.func.isRequired,
  isPendingMeeting: PropTypes.bool.isRequired,
  setEditedMeeting: PropTypes.func.isRequired,
};

export default MeetingDialog;
